import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; 

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Utilities from '../../helpers/Utilities';
import ChatUtilities from '../../helpers/ChatUtilities';

import TimePicker from '../../components/TimePicker';


const DepartmentForms = ( props ) => {

    const passConversID = useSelector( state => state.passConversID);
    const passConversationMessage = useSelector( state => state.passConversationMessage);

    const dateFormatted = Utilities.currentDateFormatted();
    const timeFormatted = Utilities.returnTimeFormat();
    const maxWords = Utilities.appointmentReservationCharLimit;

    let defaultFormValues = {
        name : '',
        date : dateFormatted,
        time : '',
        no_people : '',
        remarks : '',
    }
    
    const [ pageName, setPageName ] = useState('search');
    const [ departmentDetail, setDepartmentDetail ] = useState({});
    const [ detailValues , setDetailValues ] = useState({});
    const [ detailViewValues , setDetailViewValues ] = useState({});

    const [ requestDate, setRequestDate ] = useState(new Date());
    const [ requestDateHold, setRequestDateHold ] = useState('');

    const [ currentValues, setCurrentValues ] = useState({});
    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ loginDetail, setLoginDetail ] = useState({});

    const [ requestTime , setRequestTime ] = useState(timeFormatted);
    const [ requestTimeHold , setRequestTimeHold ] = useState('');

    const [ formAction , setFormAction ] = useState('edit');
    const [ formMainAction , setFormMainAction ] = useState('create');
    const [ allowFormContent , setAllowFormContent ] = useState(true);
    
    const [ complaintReply , setComplaintReply ] = useState('');
    const [ allowActions, setAllowActions ] = useState(true);

    const [ conversationDetail , setConversationDetail] = useState({});

    const [ showComplaintPicker , setShowComplaintPicker] = useState(false);

    const [ showFormReplyPopup , setShowFormReplyPopup ] = useState(false);
    const handleCloseFormReplyPopUp = () => setShowFormReplyPopup(false);

    const [ typeHereLabel , setTypeHereLabel ] = useState('Type here');


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;
        let  value = e.target.value;

        if ( fieldName==='name'){
            formValuesTemp = { ...formValuesTemp, ...{ name : value } };

        }else if ( fieldName==='no_people'){
            formValuesTemp = { ...formValuesTemp, ...{ no_people : value } };

        }else if ( fieldName==='subject'){
            formValuesTemp = { ...formValuesTemp, ...{ subject : value } };
            
        }else if ( fieldName==='remarks'){

            const charactersCounter = Utilities.charactersCounter(value);
            if ( parseInt(charactersCounter)>maxWords ){
                Utilities.messagePopup('error', `Only ${maxWords} characters allowed!` );
                return false;
            }
            formValuesTemp = { ...formValuesTemp, ...{ remarks : value } };
        }

        setFormValues(formValuesTemp);
        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        
    }

    const handleDateChange = ( date ) => {
        const dateRequestFormatted = Utilities.currentDateFormatted(date);
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ date : dateRequestFormatted } };
        setFormValues(formValuesTemp);
        
        setRequestDate(date);
    }
    
    const handleHoldDateTime = () => {
        setRequestTimeHold(requestTime);
        setRequestDateHold(requestDate);
        setShowComplaintPicker(true)
    }

    const handleReturnDateTime = () => {
        setRequestTime(requestTimeHold);
        handleDateChange(requestDateHold);
        setShowComplaintPicker(false);
    }


    const handleSubmit = ( action = 0 ) => {
        action = parseInt(action);
        
        if ( action===0 ){
            props.handeFormSubmit({});
            
        }else{

            let errorCounter = 0;
          
            let formValuesTemp = formValues;
            let requestTimeTemp = requestTime;
    
            jQuery('.form-group-time').removeClass('error');
            if ( requestTime===null || Utilities.isEmpty(requestTime) ){
                jQuery('.form-group-time').addClass('error');
                errorCounter++;
            }else{
                requestTimeTemp = Utilities.convertTo24Hour(requestTime);
            }
            
            formValuesTemp = { ...formValuesTemp, ...{ time : requestTimeTemp, action : action } };


            let fieldsChecker = [];
            let form_status = departmentDetail.hasOwnProperty('form_status') ? parseInt(departmentDetail.form_status) : 1;

            if ( form_status===1 ){
                fieldsChecker = ['date'];
                

            }else if ( form_status===2 ){
                fieldsChecker = ['date', 'no_people'];
                
            }else if ( form_status===3 ){
                fieldsChecker = ['date', 'subject', 'remarks'];
            }
            
            if ( Object.entries(currentValues).length===0 ){
                Object.entries(formValues).map( ([ key, value] ) => { 
                    if ( fieldsChecker.includes(key) ){

                        if ( Utilities.isEmpty(value)){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }
                    }
                });
            }
            
            if ( errorCounter>0 ){
                return false;
            }

            if ( action===3 ){
                confirmAlert({
                    title: '',
                    message: `are you sure to wish to cancel ${Utilities.viewFormLabel(form_status) }?`,
                    overlayClassName : '',
                    buttons: [
                        { label: 'No',  onClick: () => {} },
                        { label: 'yes',  onClick: () => { props.handeFormSubmit(formValuesTemp, currentValues); } }
                    ]
                }); 
            }else{
                props.handeFormSubmit(formValuesTemp, currentValues);
            }
            //setFormAction('edit');
            //setAllowFormContent(false);
            //props.handeFormSubmit(formValuesTemp);
        }
        
    }

    const handleFormReplyPopup = () => {
        setShowFormReplyPopup(true);
    }

    const handleFormReplySubmit = () => {
        
        const charactersCounter = Utilities.charactersCounter(complaintReply);
        if ( parseInt(charactersCounter)>maxWords ){
            Utilities.messagePopup('error', `Only ${maxWords} characters allowed!` );
            return false;
        }

        const form_status = parseInt(departmentDetail.form_status);

        handleCloseFormReplyPopUp();
        props.handeComplaintReplySubmit({ message : complaintReply.toString(), form_status : form_status });
    };


    const changeTypeHereLabel = ( action = '' ) => {

        if ( action==='click' ){
            setTypeHereLabel('Description');
            
        }else if ( action==='blur' ){
           
            let requestLabel = 'Type here';
            const message = jQuery('textarea#input_remarks').val();
            if ( ! Utilities.isEmpty(message) ){
                requestLabel = 'Description';
            }
            setTypeHereLabel(requestLabel);
        }
    }


    /********************************************** VIEW CONTENT ***********************************************************************/

    const viewFormContent = ( formType = 1 ) => {
        formType = parseInt(formType ?? 0);
        let returnHtml = '';

        let statusHtml = '', dateAmended = '', timeAmended = '', noPeopleAmended = '', remarksAmended = '';

        const currentDetail = props.formDetail;
        if ( typeof currentDetail!=='undefined' && Object.entries(currentDetail).length>0 && currentDetail.hasOwnProperty('status') ){
            const formStatus = parseInt(currentDetail.status);
            
            const showStatusOption = [0,2,3];
            if ( showStatusOption.includes( formStatus ) ){
                statusHtml = <div className="mt-3"><div className="form-status-label">{ ( formStatus===0 || formStatus===2 ) ? 'pending' : 'cancelled' }</div></div>
            }

            dateAmended = currentDetail.hasOwnProperty('amendment_date') && ! Utilities.isEmpty(currentDetail.amendment_date) ?  Utilities.currentDateFormatted( currentDetail.amendment_date) : '';
            timeAmended = currentDetail.hasOwnProperty('amendment_time') && ! Utilities.isEmpty(currentDetail.amendment_time) ? Utilities.returnTimeFormat( currentDetail.date+' '+currentDetail.amendment_time ) : '';
            noPeopleAmended = currentDetail.hasOwnProperty('amendment_no_people') &&  ! Utilities.isEmpty(currentDetail.amendment_no_people) ? currentDetail.amendment_no_people : '';
            remarksAmended = currentDetail.hasOwnProperty('amendment_remarks') &&  ! Utilities.isEmpty(currentDetail.amendment_remarks) ? currentDetail.amendment_remarks : '';
            
        }
        
        const feedbackMessage = detailViewValues.hasOwnProperty('feedback_message') &&  ! Utilities.isEmpty(detailViewValues.feedback_message) ? detailViewValues.feedback_message : '';

        if ( formType===1 ){

            returnHtml = <Fragment>
                            <div className="form-group active">
                                <div className="form-control">{ formValues.name }</div>
                                <label className="form-label">Name</label>
                            </div>
                            
                            <div className="form-col-3-4">
                                <div className="form-group active">
                                    <div className="form-control d-flex align-items-center justify-content-between">
                                        { 
                                            ! Utilities.isEmpty(dateAmended) ?
                                                <Fragment>
                                                    <div>{ dateAmended }</div>
                                                    <div className="text-color-pink">{ Utilities.currentDateFormatted(detailViewValues.date) }</div>
                                                </Fragment>
                                            :
                                                <div>{ detailViewValues.date }</div>
                                        }
                                    </div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control d-flex align-items-center justify-content-between">
                                        { 
                                            ! Utilities.isEmpty(timeAmended) ?
                                                <Fragment>
                                                    <div>{ timeAmended }</div>
                                                    <div  className="text-color-pink">{ Utilities.returnTimeFormat( detailViewValues.date+' '+detailViewValues.time ) }</div>
                                                </Fragment>
                                            :
                                                <div>{ Utilities.returnTimeFormat( detailViewValues.date+' '+detailViewValues.time ) }</div>
                                        }
                                    </div>
                                    <label className="form-label">Time</label>
                                </div>

                            </div>

                            <div className="form-group active pb-2">
                                <div className="form-control form-control-inherit form-control-inherit">
                                    { 
                                        ! Utilities.isEmpty(remarksAmended) ?
                                            <Fragment>
                                                <div>{ remarksAmended }</div>
                                                <div className="text-color-pink">{ detailViewValues.remarks }</div>
                                            </Fragment>
                                        :
                                            <div>{ detailViewValues.remarks }</div>
                                    }
                                </div>
                                <label className="form-label">Special Request</label>
                            </div>

                            <div>{ statusHtml}</div>

                        </Fragment>

        }else if ( formType===2 ){

            returnHtml = <Fragment>
                            <div className="form-group active">
                                <div className="form-control">{ formValues.name }</div>
                                <label className="form-label">Name</label>
                            </div>
                            
                            <div className="form-col-3-4">
                                <div className="form-group active">
                                    <div className="form-control d-flex align-items-center justify-content-between">
                                        {
                                            ! Utilities.isEmpty(dateAmended) ?
                                                <Fragment>
                                                    <div>{ dateAmended }</div>
                                                    <div className="text-color-pink">{ Utilities.currentDateFormatted(detailViewValues.date) }</div>
                                                </Fragment>
                                            :
                                                <div>{ detailViewValues.date }</div>
                                        }
                                    </div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control d-flex align-items-center justify-content-between">
                                        
                                        {
                                            ! Utilities.isEmpty(timeAmended) ?
                                                <Fragment>
                                                    <div>{ timeAmended }</div>
                                                    <div className="text-color-pink">{ Utilities.returnTimeFormat( detailViewValues.date+' '+detailViewValues.time ) }</div>
                                                </Fragment>
                                            :
                                                <div>{ Utilities.returnTimeFormat( detailViewValues.date+' '+detailViewValues.time ) }</div>
                                        }
                                    </div>
                                    <label className="form-label">Time</label>
                                </div>

                            </div>

                            <div className="form-col-1-4">
                                <div className="form-group active">
                                    <div className="form-control  d-flex align-items-center justify-content-between">
                                        
                                        {
                                            ! Utilities.isEmpty(noPeopleAmended) ?
                                                <Fragment>
                                                    <div>{ noPeopleAmended }</div>
                                                    <div  className="text-color-pink">{ detailViewValues.no_people }</div>
                                                </Fragment>
                                            :
                                                <div>{ detailViewValues.no_people }</div>
                                        }
                                    </div>
                                    <label className="form-label">Number of People</label>
                                </div>
                            </div>

                            <div className="form-group active pb-2 text-tranform-initial">
                                <div className="form-control form-control-inherit">
                                    {
                                        ! Utilities.isEmpty(remarksAmended) ?
                                            <Fragment>
                                                <div>{ remarksAmended }</div>
                                                <div className="text-color-pink">{ detailViewValues.remarks }</div>
                                            </Fragment>
                                        :
                                            <div>{ detailViewValues.remarks }</div>
                                    }
                                </div>
                                <label className="form-label">Special Request</label>
                            </div>

                            { statusHtml }

                        </Fragment>
                        

        } else if ( formType===3 ){

            returnHtml = <Fragment>
                            <div className="form-col-2">
                                <div className="form-group active">
                                    <div className="form-control">{ Utilities.currentDateFormatted(detailValues.date) }</div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control">{ Utilities.returnTimeFormat( detailValues.date+' '+detailValues.time ) }</div>
                                    <label className="form-label">Time</label>
                                </div>

                            </div>

                            <div className="form-group">
                                <div className="form-control">{ detailValues.subject }</div>
                                <label className="form-label">Subject</label>
                            </div>

                            <div className="form-group active">
                                <div className="form-control form-control-inherit pb-2 text-tranform-initial">
                                    <p>{ detailValues.remarks }</p>
                                </div>
                                <label className="form-label">Description</label>
                            </div>

                            {
                                ! Utilities.isEmpty(feedbackMessage) &&
                                <div className="form-group active">
                                    <div className="form-control form-control-inherit pb-2">
                                        <p className="text-color-pink text-tranform-initial">{ feedbackMessage }</p>
                                    </div>
                                    <label className="form-label">Reply</label>
                                </div>
                            }

                        </Fragment>

        }

        return returnHtml;
    }

    /*********************************************** FORM EDITABLE **********************************************************************/

    const viewFormEditableContent = ( formType = 1 ) => {
        formType = parseInt(formType);
        let returnHtml = '';

        if ( formType===1 ){
            returnHtml = <div className="column-2">
                            <div>
                                <div className="form-theme-datepicker">
                                    <DatePicker inline className="form-control" dateFormat="yyyy-MM-dd" selected={requestDate} onChange={(date) => handleDateChange(date)} />
                                </div>

                                <div className="form-group border-bottom-0 mt-4 active">
                                    <div className="form-input  px-0 pt-3"><TimePicker setRequestTime={setRequestTime}  /></div>
                                    <label className="form-label  px-0">Time</label>
                                </div>

                            </div>
                            <div>
                                <div className="form-group">
                                    <input type="text" name="name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.name }  onChange={ (e) => pushValue( e, 'name') } readOnly ></input>
                                    <label className="form-label">Name</label>
                                </div>
                                
                                <div className="form-group">
                                    <div className="form-control">{ formValues.date }</div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control">{ requestTime }</div>
                                    <label className="form-label">Time</label>
                                </div>

                                <div className="form-group pb-2">
                                    <textarea name="remarks" className="form-control text-tranform-initial" placeholder=" " rows="8" value={formValues.remarks} onChange={ (e) => pushValue(e, 'remarks') }></textarea>
                                    <label className="form-label">Special Request</label>
                                </div>
                            </div>
                        </div>

        }else  if ( formType===2 ){

            returnHtml = <div className="column-2">
                            <div>
                                <div className="form-theme-datepicker">
                                    <DatePicker inline className="form-control" dateFormat="yyyy-MM-dd" selected={requestDate} onChange={(date) => handleDateChange(date)} />
                                </div>

                                <div className="form-group border-bottom-0 mt-4 active">
                                    <div className="form-input  px-0 pt-3"><TimePicker setRequestTime={setRequestTime}  /></div>
                                    <label className="form-label  px-0">Time</label>
                                </div>

                            </div>
                            <div>
                                <div className="form-group">
                                    <input type="text" name="name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.name }  onChange={ (e) => pushValue( e, 'name') } readOnly ></input>
                                    <label className="form-label">Name</label>
                                </div>
                                
                                <div className="form-group">
                                    <div className="form-control">{ formValues.date }</div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control">{ requestTime }</div>
                                    <label className="form-label">Time</label>
                                </div>

                                <div className="form-group">
                                    <input type="number" name="no_people" className="form-control hide-arrow" placeholder=" " autoComplete="off" value={formValues.no_people} onChange={ (e) => pushValue(e, 'no_people') }></input>
                                    <label className="form-label">Number of People</label>
                                </div>

                                <div className="form-group pb-2">
                                    <textarea name="remarks" className="form-control text-tranform-initial" placeholder=" " rows="8" value={formValues.remarks} onChange={ (e) => pushValue(e, 'remarks') }></textarea>
                                    <label className="form-label">Special Request</label>
                                </div>
                            </div>
                        </div>

        }else  if ( formType===3 ){

            returnHtml = <Fragment>
                            <div className="form-group active">
                                <div className="form-control cursor-pointer" onClick={ () => handleHoldDateTime() }>{formValues.date}<span className="ml-3">{requestTime}</span></div>
                                <label className="form-label">Date & Time</label>
                            </div>

                            <div className="form-group">
                                <input type="text" name="subject" className="form-control" placeholder=" " autoComplete="off" value={formValues.subject} onChange={ (e) => pushValue(e, 'subject') }></input>
                                <label className="form-label">Subject</label>
                            </div>

                            <div className="form-group">
                                <textarea id="input_remarks" name="remarks"  className="form-control text-tranform-initial" placeholder=" " rows="9" value={formValues.remarks} onClick={ () => changeTypeHereLabel('click') } onBlur={ () => changeTypeHereLabel('blur') } onChange={ (e) => pushValue(e, 'remarks') }></textarea>
                                <label className="form-label">{typeHereLabel}</label>
                            </div>

                            {
                                showComplaintPicker===true && 
                                <div className="forms-date-time-wrap">
                                    <div className="forms-date-time-backdrop"></div>
                                    <div>
                                        <div className="form-theme-datepicker">
                                            <DatePicker inline className="form-control" dateFormat="yyyy-MM-dd" selected={requestDate} onChange={(date) => handleDateChange(date)} />
                                        </div>

                                        <div className="form-group border-bottom-0 mt-4 active">
                                            <div className="form-input  px-0 pt-3"><TimePicker setRequestTime={setRequestTime}  /></div>
                                            <label className="form-label  px-0 text-left">Time</label>
                                        </div>

                                        <div className="forms-date-time-buttons button-group-boolean mt-5 mx-n5 position-relative">
                                            <button type="button" className="btn-theme-black" onClick={ () => handleReturnDateTime() }>cancel</button>
                                            <button type="button" className="btn-theme-black" onClick={ () => setShowComplaintPicker(false) }>continue</button>
                                        </div>
                                    </div>

                                </div>
                            }
                        </Fragment>

        }

        return returnHtml;
    }


    /*********************************************** FORM EDITABLE **********************************************************************/

    const viewFormLabel = ( formType = 1 ) => {
        formType = parseInt(formType);
        let returnLabel = Utilities.viewFormLabel(formType);

        if ( typeof passConversationMessage!=='undefined' && Object.entries(passConversationMessage).length>0 ){

            let request_number = passConversationMessage.request_no.toString();
            returnLabel = `${returnLabel} ${request_number}`;
        }

        return returnLabel;
    }

    /*********************************************************************************************************************/

    useEffect( () => {
        
        let fromActionName = typeof props.action!=='undefined' ? props.action : 'create';
        fromActionName = fromActionName.toLowerCase();
        
        let formValuesTemp = formValues;

        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);
            setLoginDetail(loginBasicInfo);

            formValuesTemp = { ...formValuesTemp, ...{ name : loginBasicInfo.name } };
        }


        setShowComplaintPicker(false);

        //const departmentDetailTemp = props.departmentDetail;
        //if ( typeof departmentDetailTemp!=='undefined' && parseInt(Object.keys(departmentDetailTemp).length)>0 ){

            if ( fromActionName==='create'){
                
                const departmentDetailTemp = props.departmentDetail;
                if ( typeof departmentDetailTemp!=='undefined' && parseInt(Object.keys(departmentDetailTemp).length)>0 ){
                    setDepartmentDetail( departmentDetailTemp);

                    if ( parseInt(departmentDetailTemp.form_status)===3 ){
                        
                        formValuesTemp = {
                            ...formValuesTemp,
                            date : dateFormatted,
                            time : '',
                            subject : '',
                            remarks : ''
                        };

                    }else{
                        formValuesTemp = {
                            ...formValuesTemp,
                            date : dateFormatted,
                            time : '',
                            no_people : '',
                            remarks : ''
                        };
                    }


                    /* setTypeHereLabel('Type here');
                    setFormMainAction('create');
                    
                    setAllowFormContent(true);
                    setFormValues(formValuesTemp);

                    setRequestDate(new Date());
                    setRequestTime( Utilities.returnTimeFormat() );

                    setDetailValues({});
                    setCurrentValues({}); */
                    
                }else{
                    formValuesTemp = {
                        ...formValuesTemp,
                        date : dateFormatted,
                        time : '',
                        no_people : '',
                        remarks : ''
                    };
                }

                setTypeHereLabel('Type here');
                setFormMainAction('create');
                
                setAllowFormContent(true);
                setFormValues(formValuesTemp);

                setRequestDate(new Date());
                setRequestTime( Utilities.returnTimeFormat() );

                setDetailValues({});
                setCurrentValues({});

            }else{

                let formDetail = props.formDetail;

                if ( typeof formDetail!=='undefined' && Object.keys(formDetail).length>0 ){
                    const formDetailCopy = formDetail;
                    setDepartmentDetail({ form_status : formDetail.type });

                    // APPIED AMEND UPDATES
                    let dateAmended = formDetail.hasOwnProperty('amendment_date') && ! Utilities.isEmpty(formDetail.amendment_date) ?  formDetail.amendment_date : formDetail.date;
                    let timeAmended = formDetail.hasOwnProperty('amendment_time') && ! Utilities.isEmpty(formDetail.amendment_time) ?    formDetail.amendment_time : formDetail.time;
                    //let noPeopleAmended = formDetail.hasOwnProperty('amendment_no_people') &&  ! Utilities.isEmpty(formDetail.amendment_no_people) ? formDetail.amendment_no_people : formDetail.no_people;
                    let remarksAmended = formDetail.hasOwnProperty('amendment_remarks') &&  ! Utilities.isEmpty(formDetail.amendment_remarks) ? formDetail.amendment_remarks : formDetail.remarks;
                    
                    setDetailViewValues(formDetail);
                    

                    formDetail = {
                        ...formDetail,
                        date : dateAmended,
                        time : timeAmended,
                        remarks : remarksAmended,
                    }

                    if ( formDetail.hasOwnProperty('amendment_no_people') ){
                        let noPeopleAmended = ! Utilities.isEmpty(formDetail.amendment_no_people) ? formDetail.amendment_no_people : formDetail.no_people;
                        formDetail = { ...formDetail, no_people : noPeopleAmended };
                    }

                    setDetailValues(formDetail);
                    
                   
                    setAllowFormContent(true); // false
                    setFormAction('update'); // edit
                    
                    setFormMainAction('edit');

                    let dateRequested = formDetail.date;
                    dateRequested = Utilities.currentDateFormatted(dateRequested);

                    let timeRequested = formDetail.date+' '+formDetail.time;
                    timeRequested = Utilities.returnTimeFormat(timeRequested);


                    setRequestDate(new Date(formDetail.date));
                    setRequestTime(timeRequested);

                    let no_people = 0;

                    if ( parseInt(formDetail.type)===1 || parseInt(formDetail.type)===2 ){

                        no_people = formDetail.hasOwnProperty('no_people') ? formDetail.no_people ?? '0' : '0';

                        if ( parseInt(formDetail.type)===2 && Utilities.isEmpty(no_people) ){
                            no_people = '0';
                        }
                        
                        
                        formValuesTemp = {
                            ...formDetail,
                            name : formDetail.name.toString(),
                            date : dateRequested,
                            time : '',
                            no_people : no_people.toString(),
                            remarks : formDetail.remarks.toString()
                        }

                        setFormValues(formValuesTemp); 
                        //setCurrentValues({...formValuesTemp, time : Utilities.convertTo24Hour(formDetailCopy.time) });
                        setCurrentValues(
                                {...formValuesTemp,
                                    name : formDetailCopy.name.toString(),
                                    date :  Utilities.currentDateFormatted(formDetailCopy.date),
                                    time : Utilities.convertTo24Hour(formDetailCopy.time),
                                    no_people : formDetailCopy.hasOwnProperty('no_people') ? formDetailCopy.no_people ?? '0' : '0',
                                    remarks : formDetailCopy.remarks.toString()
                                }
                            );

                    }else{

                        formValuesTemp = {
                            ...formDetail,
                            date : dateRequested,
                            time : '',
                            subject : formDetail.subject.toString(),
                            remarks : formDetail.remarks.toString()
                        };

                        if ( ! Utilities.isEmpty(formDetail.remarks) ){
                            setTypeHereLabel('Description');
                        }

                        setFormValues(formValuesTemp);
                        //setCurrentValues({...formValuesTemp, time : Utilities.convertTo24Hour(formDetailCopy.time) });
                        setCurrentValues(
                            {...formValuesTemp,
                                name : formDetailCopy.name.toString(),
                                date :  Utilities.currentDateFormatted(formDetailCopy.date),
                                time : Utilities.convertTo24Hour(formDetailCopy.time),
                                remarks : formDetailCopy.remarks.toString()
                            }
                        );
                    }

                }
   
            }
        
        //}

        return () => {
            setCurrentValues({});
        }

    }, [props.timeStamp] );


    useEffect( () => {
        
        const pageNameTemp = props.pageName;
        if ( typeof pageNameTemp!=='undefined' ){
            setPageName(pageNameTemp);
        }

    }, [props.pageName] );


    useEffect( () => {
        if ( parseInt(passConversID)>0 ){
            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( passConversID );

            const access_status = parseInt(currentConversationDetailTemp.access_status);

            let isAllowEdit = true;
            if ( access_status===0 ){
                isAllowEdit = false;
            }else{

                let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
                if ( loginProfileInfo!==null ){
                    loginProfileInfo = JSON.parse(loginProfileInfo);
                    if ( Object.keys(loginProfileInfo).length>0 ){
                        
                        let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);

                        // RESTRICT NEW CHAT / REMOVE
                        if ( profileLoggedinTemp.hasOwnProperty('flag_handle_status') ){
                            if ( parseInt(profileLoggedinTemp.flag_handle_status)===0 ){
                                isAllowEdit = false;
                            }else{
                                // setAllowCompose(true);
                            }
                        }else{
                             // setAllowCompose(true);
                        }
                    }
                }
                
            }
            
            setAllowActions(isAllowEdit);
            setConversationDetail( currentConversationDetailTemp );
        }
    }, [passConversID] );


    return (
        
        <Fragment>

            <Modal show={ props.showFormPopUp } onHide={ props.handleCloseFormPopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered={ formMainAction==='create' ? true : true}  dialogClassName={`modal-dialog-theme ${ pageName==='chat' && Object.entries(detailValues).length>0 ? 'modal-page-chat' : '' }`} size="md" backdropClassName={ formMainAction==='create' ? '' : 'bg-transparent' } contentClassName={ formMainAction==='create' ? '' : ' modal-dialog-forms- border-0' }>
                    <Modal.Header closeButton className="border-bottom-0">
                        <h2 className="text-center font-gotham-light-22 w-100 mt-3 text-lowercase">{ viewFormLabel(departmentDetail.form_status) }</h2>
                    </Modal.Header>
                    <Modal.Body className="px-3 pb-5">

                            <div className="text-lowercase form-theme form-theme-medium px-5">
                                <div className={`${ allowFormContent===false ? 'content-disabled' : '' }`}>
                                {   
                                    formMainAction!=='create' && formAction==='edit' ?
                                        viewFormContent( departmentDetail.form_status )
                                    :
                                        <Fragment>
                                            { viewFormEditableContent(departmentDetail.form_status) }
                                            <div className="text-right font-gotham-book-12 text-color-gray mt-n2">{maxWords} characters</div>
                                        </Fragment>
                                }  
                                </div>

                                {
                                    pageName==='search' ?
                                        <div className="button-group-boolean mt-5">
                                            <button type="button" className="btn-theme-black text-lowercase" onClick={ () => handleSubmit(0) }>skip</button>
                                            <button id="btn-submit-form" type="button" className="btn-theme-black button-has-loader"  onClick={ () => handleSubmit(1) }>submit</button>
                                        </div>
                                    :
    
                                        allowActions===true ?
                                            <div className="mt-5">
                                                {   
                                                    formMainAction==='create' ?
                                                        <button id="btn-submit-form" type="button" className="btn-theme-black button-has-loader"  onClick={ () => handleSubmit(1) }>submit</button>
                                                    :   

                                                        parseInt(detailValues.status)!==3 ?
                                                            formAction==='edit' ?
 
                                                                parseInt(detailValues.button_status)>0 ?
                                                                    parseInt(departmentDetail.form_status)===1 || parseInt(departmentDetail.form_status)===2 ? 

                                                                        parseInt(detailValues.button_status)===2 ?
                                                                            <div className="button-group-boolean">
                                                                                <button id="btn-submit-reply"  type="button" className="btn-theme-black  button-has-loader" onClick={ () => handleSubmit(4) }>accept</button>
                                                                                <button id="btn-submit-form-amend" type="button" className="btn-theme-black button-has-loader" onClick={ () => { setFormAction('update'); setAllowFormContent(true); }}>amend</button>
                                                                            </div>
                                                                        :
                                                                            <button id="btn-submit-form-accept" type="button" className="btn-theme-black button-has-loader" onClick={ () => { setFormAction('update'); setAllowFormContent(true); }}>amend</button>
                                                                            
                                                                    :
                                                                        parseInt(detailValues.status)===0 &&
                                                                            <button  id="btn-submit-reply"  type="button" className="btn-theme-black button-has-loader" onClick={ () => handleFormReplyPopup() }>reply</button>
                                                                :
                                                                    ''
                                                            :
                                                                <div className="button-group-boolean">
                                                                    <button id="btn-submit-form-cancel"  type="button" className="btn-theme-black button-has-loader"  onClick={ () =>  props.handleCloseFormPopUp() }>close</button>
                                                                    <button id="btn-submit-form" type="button" className="btn-theme-black button-has-loader"  onClick={ () => handleSubmit(1) }>amend</button>
                                                                </div>
                                                        : ''
                                                }
                                            
                                            </div>

                                        : ''
                                }
                                
                            </div>

                    </Modal.Body>
                </Modal>

                
                <Modal show={ showFormReplyPopup } onHide={ handleCloseFormReplyPopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md"  backdropClassName="modal-content-index" className="modal-content-index" dialogClassName="modal-dialog-theme">
                    <Modal.Header className="border-bottom-0">
                        <h2 className="text-center font-gotham-light-22 w-100 mt-3 text-lowercase">{ parseInt(departmentDetail.form_status)===1 ? 'Reason for decline' : 'Reply' }</h2>
                    </Modal.Header>
                    <Modal.Body className="px-5 pb-5">
                            
                        <div className="text-lowercase form-theme form-theme-medium px-5">
                                
                            <div className="form-group">
                                <textarea name="remarks"  className="form-control" placeholder=" " rows="5" value={complaintReply} onChange={ (e) => setComplaintReply(e.target.value) }></textarea>
                                <label className="form-label">Description</label>
                            </div>
                            
                            <div className="text-right font-gotham-book-12 text-color-gray mt-n2 pb-3">{maxWords} characters</div>

                            
                            <div className="button-group-boolean mt-5">
                                <button type="button" className="btn-theme-black" onClick={ () => handleCloseFormReplyPopUp () }>cancel</button>
                                <button type="button" className="btn-theme-black button-has-loader" onClick={ () => handleFormReplySubmit() }>submit</button>
                            </div>
                        </div>
                        
                    </Modal.Body>
                </Modal>


        </Fragment>
    )
}

export default DepartmentForms;
