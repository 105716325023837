import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import { FacebookProvider, LoginButton } from "react-facebook";
import jQuery from 'jquery';

import { checkEmailSocialAccount } from '../helpers/QueryLogin';
import Utilities from '../helpers/Utilities';

//import { showLoader } from '../actions';

const LoginConnect = ( props ) => {

    const dispatch = useDispatch();

    const btn_name = 'btn-login-user';
    const imgSize = props.size ?? 32;
    const classSocialWrap = `mx-2 cursor-pointer position-relative overflow-hidden`;
    const imgFolder = process.env.REACT_APP_img_folder; 

    const social_gogle_client_id = process.env.REACT_APP_social_google_client_id; 
    const social_gogle_plugin_name = process.env.REACT_APP_social_google_plugin_name; 

    const social_facebook_app_id = process.env.REACT_APP_social_facebook_app_id; 

    // 1: GOOGLE, 2: APPLE, 3 : FACEBOOK, 4 : INSTAGRAM
    /// GOOGLE ///////////////////////////////////////////
    const google_onSuccess = (response) => {
        //console.log('Success:', response);
        getSocialAccountCredential(response.profileObj.email ?? '', 1);
    };

    const google_onFailure = (response) => {
        //console.log('Failed:', response);
    };

   
    /// FACEBOOK //////////////////////////////////////////////////////////////////////////////////////
    const facebook_onSuccess = (response) => {
        //console.log('Success:', response);
        getSocialAccountCredential( response.profile.email ?? '', 3);
    }

    const facebook_onFailure = (response) => {
        //console.log('Failed:', response);
    };



//////////////////////////////////////////////////////////////////////////////////////
    const getSocialAccountCredential = async ( emailProvided = '', app_type = 0 ) => {

        if( ! Utilities.isEmpty(emailProvided) ){
            
            //dispatch( showLoader(1) );
            Utilities.elementDisabledStatus(btn_name, true );
            const socialCredentialResponse = await checkEmailSocialAccount({ email : emailProvided, app_type :  app_type });
            Utilities.elementDisabledStatus(btn_name, false );
            //dispatch( showLoader(0) );
            ///console.log(socialCredentialResponse);

            if ( Object.keys(socialCredentialResponse).length===0 || parseInt(socialCredentialResponse.status ?? 0)===0 ){
                //Utilities.messagePopup('error', `No record found!`);
                props.loginSocialDetail({});
                return false;
            }

            props.loginSocialDetail(socialCredentialResponse.account_detail, socialCredentialResponse.status ?? 0 );
        }
    }

//////////////////////////////////////////////////////////////////////////////////////



    useEffect( () => {
     
    }, []);


    return (
       <>
            <GoogleLogin
                clientId={social_gogle_client_id}
                buttonText="Login"
                render={renderProps => (
                    <div className={classSocialWrap} onClick={renderProps.onClick} disabled={renderProps.disabled} ><img src={`${imgFolder}/icons/social/outline-google.svg`} width={imgSize} /></div>
                )}
                onSuccess={google_onSuccess}
                onFailure={google_onFailure}
                cookiePolicy={"single_host_origin"}
            />

             <FacebookProvider appId={social_facebook_app_id}>
                <LoginButton
                    className="bg-transparent border-0 px-0"
                    scope="email"
                    onCompleted={facebook_onSuccess}
                    onError={facebook_onFailure}
                    >
                    <div className={classSocialWrap}><img src={`${imgFolder}/icons/social/outline-facebook.svg`} width={imgSize} /></div>
                </LoginButton>
            </FacebookProvider>

       </>
    )
}

export default LoginConnect;