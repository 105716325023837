import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SessionUtilities from '../helpers/SessionUtilities';
import TitlesLabels from '../helpers/TitlesLabels';
import Utilities from '../helpers/Utilities';

import { showHeader, showFooter, pageTitle } from '../actions';


const DepartmentsChatViewOnly = ({match}) => {

    const dispatch = useDispatch();

    const [ listDepartments, setListDepartments ] = useState([]);
    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ moreBoxes, setMoreBoxes ] = useState(0);
    const [ showEmpty, setShowEmpty ] = useState(true);

    useEffect( () => {
        
        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);
            
            if ( parseInt(reqID)>0 ){
                const profile = SessionUtilities.getProfileDetail( reqID );
                setProfileDetail(profile);
               
                if ( profile.hasOwnProperty('departments') ){
                    if ( Object.entries(profile.departments ?? {}).length>0 ){

                        let listDepartmentsTemp = [];
                        Object.entries(profile.departments).map( ([key, row] ) => {
                            if ( parseInt(row.flag)===5 && parseInt(row.flag_handle_status || 0)===0 ){
                                listDepartmentsTemp.push(row);
                            }
                        });

                        setListDepartments(listDepartmentsTemp);

                        const total_departments = parseInt(listDepartmentsTemp.length);
                        const show_empty = total_departments>0 ? false : true;
                        setShowEmpty(show_empty);

                        const base_counter = 5;
                        let box_counter = 0;
                        if ( total_departments<base_counter ){
                            box_counter = base_counter - total_departments;
                        }else{
                            
                            let box_rem = total_departments%base_counter;
                            box_rem = parseInt(box_rem);

                            if ( box_rem>0 ){
                                box_counter = base_counter - box_rem;
                            }
                        }
                        
                        setMoreBoxes(box_counter);
                    }
                }
            }
        }

    }, [match]);

    useEffect( () => {
        dispatch( pageTitle(`${ TitlesLabels.siteTitles.departments } | ${ TitlesLabels.siteTitles.viewChats }`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
    }, []);

    return (
        <div className="departments-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.navigations.profile }</div>
                            <div className="current">{ TitlesLabels.navigations.viewChats }</div>
                         </li>
                         <li className="btn-option">

                         </li>
                     </ul>
                </div>
            </div>

            <div className="main-content">
                <div className="container department-section">

                        <div className="upload-avatar avatar-wrap">
                            <div>
                                <div className={`avatar-logo rounded-circle mx-auto ${ Utilities.classHideInitialBg( profileDetail.hasOwnProperty('company_detail') ? profileDetail.company_detail.logo_url : '' ) }`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage( profileDetail.hasOwnProperty('company_detail') ? profileDetail.company_detail.logo_url : '') })`  }}>{ Utilities.showHideInitial( (profileDetail.hasOwnProperty('company_detail') ? profileDetail.company_detail.logo_url : '') , (profileDetail.hasOwnProperty('company_detail') ? profileDetail.company_detail.company_name : '') ) }</div>
                            </div>
                        </div>

                        { 
                            ( Object.entries(listDepartments).length>0 ) &&
                                <ul id="listing-departments" className="listing-departments mt-5">
                                {
                                    Object.entries(listDepartments).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.department_name} className="cursor-pointer">
                                                <div className="department-name">{row.department_name}</div>
                                        </li>
                                    )
                                }
                                {
                                    [...Array(moreBoxes).keys()].map( (key) =>  <li key={key}></li> )
                                }
                                </ul>
                        }
                        <div className={`text-center text-lowercase no-record-label ${ !showEmpty && 'd-none' }`} >{ Utilities.labelDepartmentAvailable }</div> 

                </div>
            </div>


       </div>
    )
}

export default DepartmentsChatViewOnly;