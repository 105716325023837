import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';

import {  showHeader, showFooter, pageTitle, showMainSearch, shareQRCode, showMainSettings as showMainSettingsReducer, showDepartmentSettings as showDepartmentSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer  } from '../../actions';


const ShareQrcode = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [ qrDetail, setQrDetail ] = useState({});
    const [ loadingStatus, setLoadingStatus ] = useState(true);
    const [ showButtonOption, setShowButtonOption ] = useState(false);

    
    const shareQRCodeInternal = ( actionType = 1) => {
        dispatch( showMainSearch(1) );
        dispatch( shareQRCode( parseInt(actionType) ) );
    }

    const triggerSetting = () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if ( sesstionLoginType !== null ){
            sesstionLoginType = parseInt(sesstionLoginType);
            
            if ( sesstionLoginType===2 ){
                dispatch( showDepartmentSettingsReducer( new Date() ));
            }else{
                dispatch( showMainSettingsReducer( new Date() ));
            }
            
        }else{
            dispatch( showMainSettingsReducer( new Date() ));
        }

    }


    const loaderHtml = <div className="col-6 global-preloader">
                        <div className="logo-size-120 mx-auto bg-gray border-0"></div>
                        <div className="text-center col-8 mx-auto"> 
                            <div className="mt-3 bg-gray col-9 pt-4 mx-auto"></div>
                            <div className="mt-2 bg-gray col-8 pt-3 mx-auto"></div>
                            <div className="mt-2 bg-gray col-7 pt-4 mx-auto"></div>
                        </div>
                        
                        <div className="mt-5 bg-gray col-6 pt-4 mx-auto" style={{ height: '200px'}}></div>
                        
                        <div className="button-wrap text-lowercase text-center mb-5 pt-5 form-col-3 px-4">
                            <button type="button" className="btn-theme-black bg-gray-theme"></button>
                            <button type="button" className="btn-theme-black bg-gray-theme"></button>
                            <button type="button" className="btn-theme-black bg-gray-theme"></button>
                        </div>
                    </div>

    const htmlQRCode = ( profileDetail = {}, id = 'qrcode', actionType = 1 ) => {
        if ( Object.keys(profileDetail).length===0 ){ return false; }

        return <>
                <div className="logo-size-120 mx-auto" style={{ backgroundImage : `url(${ profileDetail.logo || DEFAUL_AVATAR })`}}></div>
                <div className="text-center col-8 mx-auto">   
                    <div className="mt-3 font-gotham-medium-20 ellipsis-2">{profileDetail.name}</div>
                    <div className="font-gotham-book-16 text-gray">{profileDetail.category}</div>
                </div>

                <div className="qrcode-wrap">
                    <div>
                        <canvas id={id}></canvas> 
                    </div>
                </div>

                {
                    showButtonOption && 
                        <div className="qr-share-option text-lowercase text-center mb-5 pt-4 d-flex align-items-center justify-content-center">
                            <a href={profileDetail.qr_code} target="_blank" download={`${profileDetail.name}-qrcode.jpg`} className="btn-qr-download btn-qr">download</a>
                            <div id="btn-qr-send" className="btn-qr-send btn-qr" onClick={ () => shareQRCodeInternal(actionType) }>send</div>
                            <a id="btn-qr-share" href={`mailto:?subject=${profileDetail.name} - qr code&body=${profileDetail.file_path}`} className="btn-qr-share btn-qr">share</a>
                        </div>
                }
            </>
    }



    useEffect( () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginType);
        
        //let displayDetail = { logo_url : '', name : '', category : ''};

        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginPersonalInfo!==null ){
            loginPersonalInfo = JSON.parse(loginPersonalInfo);
 
            let account_id_request = 0;
            console.log(loginPersonalInfo);
            if ( sesstionLoginType===1 ){
                account_id_request = loginPersonalInfo.account_id;
                /* displayDetail = { ...displayDetail, logo_url : loginPersonalInfo.logo_url, name : loginPersonalInfo.name, category : Utilities.accountTypeLabels( loginPersonalInfo.account_type) };
               
                if ( loginPersonalInfo.hasOwnProperty('team_position') ){
                    displayDetail = { ...displayDetail, category : loginPersonalInfo.team_position };
                } */

                if ( loginPersonalInfo.hasOwnProperty('company_detail') ){
                    if ( loginPersonalInfo.hasOwnProperty('group_id') ){
                        account_id_request = loginPersonalInfo.company_detail.account_id;
                    }
                }

            }else if ( sesstionLoginType===2 ){

                let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
                if ( companyDetail!==null ){
                    companyDetail = JSON.parse(companyDetail);
                    account_id_request = companyDetail.account_id;
                }
            }


            if ( parseInt(account_id_request)===0 ){
                return false;
            }
            
            const formData = {
                api_token : TOKEN.toString(),
                account_id : account_id_request.toString(),
            }


            axios
            .post( ApiUrls.profileGetProfileQrCode ,  formData )
            .then( response => {

                setLoadingStatus(false);
     
                if ( parseInt(response.status)===201 ){
                    if ( parseInt(response.data.success)===1 ){
                        
                        setShowButtonOption(true);

                        const qrcode_detail = response.data.result.qr_account_details;
                        setQrDetail(qrcode_detail);
                        //setDisplayProfile(qrcode_detail);

                        const canvas = document.getElementById("qrcode");
                        const ctx = canvas.getContext("2d");

                        const image = new Image();
                        image.onload = function() {
                            ctx.drawImage(image, 0, 0,  260, 260);
                        };
                        image.src = qrcode_detail.qr_code;
                        canvas.width  = 260;
                        canvas.height = 260;

                        if ( Object.keys(qrcode_detail.company_details ?? {}).length>0 ){
                            const canvas_comp = document.getElementById("qrcode_company");
                            const ctx_comp = canvas_comp.getContext("2d");
                            
                            const image_comp = new Image();
                            image_comp.onload = function() {
                                ctx_comp.drawImage(image_comp, 0, 0,  260, 260);
                            };
                            image_comp.src = qrcode_detail.company_details.qr_code;
                            canvas_comp.width  = 260;
                            canvas_comp.height = 260;
                        }

                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage ); 
                    }

                }else{
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGenerateQrCode );
  
                }
                
            })  
            .catch( (err) => {
                setLoadingStatus(false);
                Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGenerateQrCode );
            }); 
        }

    }, [] );


    useEffect( () => {

        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.shareQrCode }`) );

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if ( sesstionLoginType !== null ){
            sesstionLoginType = parseInt(sesstionLoginType);
           
            if ( sesstionLoginType===2 ){
                dispatch( showHeader(5) );
            }else{
                dispatch( showHeader(1) );
            }

        }else{
            dispatch( showHeader(1) );
        }
        
        dispatch( showFooter(1) );
        
    }, []);

    return (
        <div className="share-qrcode-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div className="current">{ TitlesLabels.navigations.qrCode }</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container">
                    
                    <div className="d-flex justify-content-center  pt-1 form-theme text-lowercase col-md-10 mx-auto">
                    {
                            loadingStatus ?
                                loaderHtml
                            :  
                            <>  
                                {
                                    Object.keys(qrDetail.company_details ?? {}).length>0 && 
                                        <div className="col-6">
                                            { htmlQRCode( qrDetail.company_details, 'qrcode_company', 2 ) }
                                        </div>
                                
                                }
                                
                                <div className="col-6">
                                    { htmlQRCode( qrDetail, 'qrcode', 1) }
                                </div>
                            </> 
                            
                    }
                    </div>

                </div>
            </div>
        </div>
        
    )
}

export default ShareQrcode;