import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import TitlesLabels from '../helpers/TitlesLabels';
import PagesAuthentication from '../helpers/PagesAuthentication';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer } from '../actions';

import { Link } from 'react-router-dom';


const LinkProfiles = () => {

    const dispatch = useDispatch();
  
    const [ listAccounts, setListAccounts ] = useState([]);
    const [ hasAssignee, setHasAssignee ] = useState([]);
    const [ activeSlide, setActiveSlide ] = useState(0);
    const [ sliderCounterArr, setSliderCounterArr ] = useState([]);

    const sliderNext = () => {
        let num = activeSlide+1;
        if ( num>0 && sliderCounterArr.includes(num) ){
            setActiveSlide(num);
        }
    }

    const sliderPrev = () => {
        let num = activeSlide-1;
        if ( num>=0 && sliderCounterArr.includes(num) ){
            setActiveSlide(num);
        }
    }
    
    const getAccounts = () => {

         // ASSOCIATED ACCOUNTS
         let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
         if ( accountsList!==null ){
             accountsList = JSON.parse(accountsList);
 
             if ( Object.keys(accountsList).length>0 ){
                let accountAll = [];
                let accountIDs = [];

                let accountListTemp = Utilities.sortAccounts(accountsList);
                
                accountListTemp.forEach( acctdetail => {
                     accountAll = [ ...accountAll, Utilities.formatProfileDetail(acctdetail) ];

                    if ( parseInt(acctdetail.account_type)===4 ){
                        accountIDs.push(acctdetail.account_id);
                    }
                 });
                
                 accountAll = Utilities.sliceIntoChunks(accountAll, 4);
                 const sliderCounterAppTemp = [...Array(Object.entries(accountAll).length).keys()];
                 setSliderCounterArr( sliderCounterAppTemp );

                 setListAccounts(accountAll);

                 if ( accountIDs.length>0 ){
                    axios
                    .post( ApiUrls.linkProfilesAssignedStatusByIDs , { account_ids : accountIDs.join(',').toString() } )
                    .then( response => {

                        if ( response.status===200 ){
                            const dataResponse = response.data;
                            const dataStatus = parseInt(dataResponse.status);

                            if ( dataStatus===1 ){
                                setHasAssignee(dataResponse.ids);
                            }
                        }
                    })
                    .catch( (err) => {
                        
                    });
                }
             }
         }
    }

    const nameByAccountType = ( profile ) =>{
        let name = profile.name;
        let logo  = profile.img;

        if ( parseInt(profile.account_type)===4 ){
            if ( profile.hasOwnProperty('company_detail') ){
                name = profile.company_detail.company_name ?? '';
                logo = Utilities.formatProfileDetail(profile, 1).img;
            }
        }
        return { name : name, logo : logo };
    }


    const teamAssigned = ( profile ) => {
        let returns = { indicator : false, assign  : false };

        if ( profile.hasOwnProperty('account_type') ){
            const account_type = parseInt(profile.account_type || 0);
            
            if ( account_type===4){
                const account_flag = parseInt(profile.flag || 0);
                let indicator = false;

                if (account_flag===4){
                    if ( hasAssignee.includes(profile.account_id.toString()) ){
                        indicator = true;
                    }
                    returns = { ...returns, indicator : indicator , assign  : true };
                }
            }
        }

        return returns;
    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    const subProfiles = ( profile ) => {
        let subProfilesTemp = [];
        if ( parseInt(profile.account_type)===4){            

            if ( profile.hasOwnProperty('departments') ){
                subProfilesTemp = [...subProfilesTemp, profile ];

                Object.entries(profile.departments).map( ([key, row ]) => {
                    if ( parseInt(row.group_id || 0)>0 && parseInt(row.flag || 0)===5 ){
                       
                        let departmentTemp = {
                            ...row,
                            logo : nameByAccountType(profile).logo,
                            name : row.department_name,
                            type : 3
                        }

                        subProfilesTemp = [...subProfilesTemp, departmentTemp ];
                    }
                });
            }   
        }

        return subProfilesTemp;
    }

    useEffect( () => {
        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.linkedChats }`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();
        getAccounts();

    }, [] );

    return (
        <div className="link-profiles-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div className="current">{ TitlesLabels.navigations.linkedChats }</div>
                          </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div>
                         </li>
                     </ul>
                </div>


            </div>

            <div className="main-content">
                <div className="container">

                        <div className="px-5 text-center list-profile-linked-content overflow-hidden">

                            {
                                 Object.entries(listAccounts).map( ([keyAcct, listAccountPage] ) =>
                        
                                    <ul  key={keyAcct} className={`list-profile-linked fade-in ${ parseInt(activeSlide)===parseInt(keyAcct) ? 'active' : '' }`}>
                                        {
                                            Object.entries(listAccountPage).map( ([key, row] ) =>
                                                <li  key={key}>
                                                        <div className="dropdown-linked-profile cursor-auto">
                                                            <div className="avatar-image mb-3" style={{ backgroundImage:  `url(${  nameByAccountType(row).logo })`  }}>
                                                                {
                                                                    row.hasOwnProperty('departments') &&
                                                                        <div className="counter"><em>{ parseInt(row.departments_count || 0) + 1 }</em></div>
                                                                }
                                                                
                                                            </div>
                                                            <div className="profile-name">{ nameByAccountType(row).name.toString().split(' ')[0] }</div>
                                                            <div className={ `user-label user-label-${ row.type }` }>{ Utilities.accountTypeCustomLabels(row) }</div>
                                                        
                                                            {
                                                                parseInt(row.account_type)!==1 &&
                                                                <div className="list-subprofile-linked mt-4">
                                                                    {
                                                                        Object.entries( subProfiles(row) ).map( ([key, row] ) =>
                                                                            <div key={key}>
                                                                                <div className="profile-content">
                                                                                    <div className="profile-name">{ parseInt(row.type)===3 ? row.name : Utilities.accountTypeLabels(row.type) }</div>
                                                                                    { teamAssigned(row).assign===true && <Link to={`/settings/linked-profiles/assign/${ btoa(row.account_id) }`} className="btn-linked-assign"></Link> } 
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }  
                                                                    
                                                                </div>
                                                            }
                                                        
                                                        </div>  
                                                </li>
                                            )
                                        }

                                    </ul>

                                 )
                            }


                        </div>

                        
                        {
                            parseInt( Object.keys(listAccounts).length )> 1 &&
                            <div className="theme-slider-arrows">
                                <div className="slide-arrow">
                                    <div className={`slide-arrow-left ${ parseInt(activeSlide)===0 ? 'opacity-05' : '' }` } onClick={ () => sliderPrev() }></div>
                                    <div className={`slide-arrow-right ${ parseInt(sliderCounterArr.slice(-1)[0])===parseInt(activeSlide) ? 'opacity-05' : '' } `} onClick={ () => sliderNext() }></div>
                                </div>
                            </div>
                        }
                        

                </div>
            </div>
       </div>
    )
}

export default LinkProfiles;