import axios from 'axios';
import ApiUrls from './ApiUrls';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import Utilities from './Utilities';

export const updateAccountFCM = async ( fcm_token = '') =>{

    fcm_token = fcm_token.toString();
    
    if ( fcm_token!=='' ){
        //console.log( fcm_token);

        let accountsConcat = [];

        let logintype = 1;

        let logintypeSession = localStorage.getItem(process.env.REACT_APP_session_logintype);
        if ( logintypeSession!==null ){
            logintype = JSON.parse(logintypeSession);
        }

        logintype = parseInt(logintype);

        if ( logintype===1 ){

             // ASSOCIATED ACCOUNTS
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if ( accountsList!==null ){
                    accountsList = JSON.parse(accountsList);

                    if ( Object.keys(accountsList).length>0 ){
                        Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                            accountsConcat.push( acctdetail.account_id.toString()+':1');
                        });
                        
                    }
                }

        }else  if ( logintype===2 ){

             // DEPARTMENT LOGIN
            let departmentActiveDetail = localStorage.getItem(process.env.REACT_APP_session_department_active);
            if ( departmentActiveDetail!==null ){
                departmentActiveDetail = JSON.parse(departmentActiveDetail);
                accountsConcat.push( departmentActiveDetail.group_id.toString()+':2');
            }
        }


        //console.log(accountsConcat);

        if ( accountsConcat.length>0 ){
            //console.log( accountsConcat.join(',') );

            const timeUTC = moment.utc();
            const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString();  //utc_date
            const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex); // brower_id

            //console.log(dateFormatted, browserUniqueID);
            
            try {

                const passval = {
                    fcm_token : fcm_token,
                    accountids_logintypes : accountsConcat.join(','),
                    brower_id : browserUniqueID,
                    utc_date : dateFormatted
                }

                const responseURL = await axios.post(  ApiUrls.userUpdateLoginHistory, passval );
                if ( parseInt(responseURL.data.status)===1 ){
                    //console.log(responseURL.data);
                    //responseDetail = responseURL.data;
                }


            } catch (e) {
                
            }
        }

    }

}
