import axios from 'axios';
import ApiUrls from './ApiUrls';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

export const sendAppReports = async ( formData = {} ) =>{
    let responseDetail = {}

    try {
        const responseURL = await axios.post(  ApiUrls.sendingEmailAppReports, formData );
        if ( parseInt(responseURL.data.status)===1 ){
            responseDetail = { ...responseDetail, ...responseURL.data};
        }

    } catch (e) {
        
    }

    return responseDetail;
}


export const sendVerificationCode = async ( formData = {} ) =>{
    let responseDetail = {}

    try {
        const responseURL = await axios.post(  ApiUrls.sendingProfileSignupVerification, formData );
        if ( parseInt(responseURL.status)===201 ){
            responseDetail =  responseURL.data;
        }

    } catch (e) {
        
    }

    return responseDetail;
}

export const sendEmailGeneral = async ( formData = {} ) =>{
    let responseDetail = {}

    try {
        const responseURL = await axios.post(  ApiUrls.sendingEmailGeneral, formData );
        if ( parseInt(responseURL.status)===201 ){
            responseDetail =  responseURL.data;
        }

    } catch (e) {
        
    }

    return responseDetail;
}



export const sendContact = async ( passval = {} ) =>{
    let responseDetail = {};
   
    if ( Object.entries(passval).length>0 ){
        try {
            passval = { ...passval, ...{ api_token : API_PLUS_TOKEN.toString() } };
            console.log(passval);
            const responseURL = await axios.post( ApiUrls.sendingEmailGeneral , passval );
          
            if ( parseInt(responseURL.status)===201 ){
                responseDetail =  responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}