import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';

import { viewMenuPricePopup as viewMenuPricePopupReducer } from '../../actions';


const MenuPricePreview = () => {
    
    const dispatch = useDispatch();

    const viewMenuPricePopup = useSelector( state => state.viewMenuPricePopup);

    const [ showPopup, setShowPopup ] = useState(false);
    const [ menuDetail, setMenuDetail ] = useState({});
    
    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( viewMenuPricePopupReducer({}) );
    }

    useEffect( () => {
        
        if ( Object.entries(viewMenuPricePopup).length>0 ){

            if ( viewMenuPricePopup.hasOwnProperty('file_url') ){
                setMenuDetail(viewMenuPricePopup);
                setShowPopup(true);
            } 
        }

    }, [viewMenuPricePopup] );


    return (

       <Modal id="popup-menu-price-preview"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme"  keyboard={ true } contentClassName="border-0">
            <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline pt-4">
                {  Object.entries(viewMenuPricePopup).length>0 ? Utilities.menuProductOptionLabel[parseInt(viewMenuPricePopup.type)].toString() : Utilities.menuProductOptionLabel[1].toString() }
            </Modal.Header>
            <Modal.Body className="p-0 line-height-0">
                {
                    ( Object.entries(viewMenuPricePopup).length>0 && viewMenuPricePopup.hasOwnProperty('file_url') ) &&
                        <iframe height="650" width="100%"  src={`${menuDetail.file_url}#toolbar=0&view=Fill`}></iframe>
                }
               
            </Modal.Body>
        </Modal>
    )
}

export default MenuPricePreview;
