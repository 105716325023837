import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; 

import { showMainSearch, passDocumentDetail, showLoader as showLoaderReducer, viewMenuPricePopup as viewMenuPricePopupReducer, passChatTeamProfileRestrictionDetail as passChatTeamProfileRestrictionDetailReducer  } from '../../actions';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import ChatUtilities from '../../helpers/ChatUtilities';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';
import TitlesLabels from '../../helpers/TitlesLabels';

const MainSearchProfile = ( props ) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN_CHAT = process.env.REACT_APP_chat_api_token;

    const passProfile = useSelector( state => state.passProfile);
    const passDocumentId = useSelector( state => state.passDocumentId);

    let  countryList= {};

    const [ profileLoggedin, setProfileLoggedin ] = useState({});
    const [ profileSelected, setProfileSelected ] = useState({});
    const [ expandAboutSection, setExpandAboutSection ] = useState(false);
    const [ toggleDepartmentConnect, setToggleDepartmentConnect ] = useState(false);
    const [ showContactProfileOption, setShowContactProfileOption ] = useState(false);
    const [ isProfileLinked, setIsProfileLinked ] = useState(false);
    const [ isConnect, setIsConnect ] = useState(false);
    const [ teamRestrictAccessStatus , setTeamRestrictAccessStatus ] = useState(0);
    const [ loginType , setLoginType ] = useState(0);

    const [ followingStatus, setFollowingStatus ] = useState(0);

    const handleCloseProfilePopup = () => {
        setShowContactProfileOption(false);
        setExpandAboutSection(false);
        setToggleDepartmentConnect(false);
        setIsProfileLinked(false);
        setIsConnect(false);
        setTeamRestrictAccessStatus(0);
        setLoginType(0);
        setFollowingStatus(0);

        setProfileSelected({});
        props.handleCloseProfilePopup();
    }

    const setupProfileDetail = async ( profile = {} ) => {
        //setProfileSelected(profile);

        let profileTemp = profile;
        dispatch( showLoaderReducer(1) );

        let isAccountBelongs = 0;
        if ( parseInt(profileLoggedin.account_type)===4 ){
            if ( parseInt(profileLoggedin.company_detail.account_id)===parseInt(profile.account_id) ){
                isAccountBelongs = 1;
            }
        }

        setIsProfileLinked( parseInt(isAccountBelongs)===1 ? true : false );

        if ( props.showStatus===2){ // 2 - form search + adding team member
            dispatch( showLoaderReducer(0) );
            dispatch( showMainSearch(0) );

            if ( passProfile.hasOwnProperty('account_id') ){
                history.push(`/${UrlSlugs.settings_team_add}/${btoa(parseInt(passProfile.account_id))}/${btoa(profile.account_id)}`);
            }


        //  3 - is contact search, show bizcomplus option
        }else{

            if ( parseInt(profile.account_type)!==3 ){
                if ( parseInt(profile.account_type)===4 ){

                    let team_restrict_access_status = 0;
                    if ( profile.hasOwnProperty('team_restrict_access_status') ){
                        team_restrict_access_status = profile.team_restrict_access_status || 0;
                    }

                    setTeamRestrictAccessStatus( team_restrict_access_status );
                    dispatch( showLoaderReducer(0) );

                    await axios
                            .post( ApiUrls.profilegetByID , { id : profile.team_company_id  }  )
                            .then( response => {
                                dispatch( showLoaderReducer(0) );

                                if ( response.status===200 ){
                                    if ( response.data.status===1 ){
                                        
                                        const compData = response.data.detail;
                                        setProfileSelected({...profile, company_detail :  compData});
                                    }
                                }
                            })
                            .catch( (err) => {
                                dispatch( showLoaderReducer(0) );
                                setProfileSelected( profile );
                            });

                }else{
                    dispatch( showLoaderReducer(0) );
                    setProfileSelected( profile );
                }
                
   
            }else{

                const passVal = {
                                    company_id : profile.account_id,
                                    account_id : profileLoggedin.account_id,
                                    search_type : props.showStatus,
                                    types : 'member-external-count,department-count,menu-price-detail,social-accounts,following_status,company-groups-count'
                                };
                
                //console.log(profile);            
                //console.log(passVal, props.showStatus);
                await axios
                        .post( ApiUrls.companyOthers , passVal  )
                        .then( response => {

                            dispatch( showLoaderReducer(0) );
                            if ( response.status===200 ){
                                
                                const companyOthers = response.data;
                                const department_count = parseInt(companyOthers.department_count || 0);
                                const member_count = parseInt(companyOthers.member_count || 0);
                                const menu_detail = companyOthers.menu_price_detail ?? {};
                                const social_accounts = companyOthers.social_accounts ?? {};
                                const following_status = parseInt(companyOthers.following_status ?? 0);
                                const company_groups_count = parseInt(companyOthers.company_groups_count ?? 0);

                                setFollowingStatus(following_status);

                                let isConnectTemp = 0;
                                if ( companyOthers.hasOwnProperty('department_count') && department_count>1 ){
                                    isConnectTemp = 1;
                                }

                                if ( companyOthers.hasOwnProperty('member_count') && member_count>0 ){
                                    isConnectTemp = 1;
                                }

                                if ( parseInt(isConnectTemp)===0 ){
                                    setIsConnect(true);
                                }else{
                                    setIsConnect(false);
                                }


                                    let contact_number_landline = '', contact_number_mobile = '', contact_prefix = '', phone_code = '', has_contact = 0 ;

                                    if ( profile.hasOwnProperty('mobile_number_status') && parseInt(profile.mobile_number_status)===1 ){
                                        contact_number_mobile = profile.company_phone.replace(' ', '').trim();
                                        if ( profile.hasOwnProperty('company_phone_code') ){
                                            phone_code = profile.company_phone_code.trim();

                                            if ( parseInt( phone_code )>0 ){
                                                contact_prefix = '+'+countryList[phone_code]['phonecode'];
                                                contact_number_mobile = contact_prefix+contact_number_mobile;
                                                has_contact++;
                                            }
                                        }else{
                                            contact_number_mobile = '';
                                        }
                                    }
                                    
                                    if ( profile.hasOwnProperty('landline_number_status') && parseInt(profile.landline_number_status)===1  ){
                                        contact_number_landline = profile.company_landline.replace(' ', '').trim()
                                        if ( profile.hasOwnProperty('company_landline_code') ){
                                            phone_code = profile.company_landline_code.trim();

                                            if ( parseInt( phone_code )>0 ){
                                                contact_prefix = '+'+countryList[phone_code]['phonecode'];
                                                contact_number_landline = contact_prefix+contact_number_landline;
                                                has_contact++;
                                            }
                                        }else{
                                            contact_number_landline = '';
                                        }
                                    }
            

                                    if ( profileTemp.hasOwnProperty('company_website') && ! Utilities.isEmpty(profileTemp.company_website) ){
                                        
                                        let companyWebsite = profileTemp.company_website;
                                        let websiteDisplay = companyWebsite.split('/')[2];

                                        if ( typeof websiteDisplay==='undefined' ){
                                            websiteDisplay = companyWebsite;
                                            companyWebsite = 'http://'+websiteDisplay;
                                        }
                                        
                                        profileTemp = { ...profileTemp, company_website : companyWebsite, website_display : websiteDisplay };
                                    }
                            

                                profileTemp = {
                                    ...profileTemp,
                                    member_count : member_count,
                                    department_count : department_count,
                                    company_groups_count : company_groups_count,
                                    
                                    has_contact : has_contact,
                                    contact_number_mobile : contact_number_mobile,
                                    contact_number_landline : contact_number_landline,

                                    menu_detail : menu_detail,
                                    social_accounts : social_accounts
                                };

                                setProfileSelected( profileTemp );
                            }
                        })
                        .catch( (err) => {
                            dispatch( showLoaderReducer(0) );
                            console.log(err);
                        });
            }
        }
    }

    const toggleProfileOptionNav = () => {
        if ( isConnect===true ){
            handleGeneralDepartment();

        }else{
           setShowContactProfileOption( showContactProfileOption===false ? true : false );
        }
    }


    const handleGeneralDepartment = () => {

        closeSearchBox();

        if ( ! Utilities.isEmpty(passDocumentId) && passDocumentId.toString()!=='0' ){
            const passValues = {
                account_id : parseInt(profileSelected.account_id),
                convers_detail : {},
                document_id : parseInt(passDocumentId),
                department_id : '0' // general
            }

            dispatch( passDocumentDetail( passValues ) );
            return false;
        }

        let chatUrl = `/${ UrlSlugs.chat }/${ btoa( profileSelected.account_id ) }/3`;
        history.push(chatUrl);
        
    }


    const handleTeamProfile = async () => {
        
        if ( parseInt(teamRestrictAccessStatus)===1 ){
           
            const button_name = 'btn-confirm-chat';
            Utilities.elementDisabledStatus(button_name, true );

            const passValuesRes = {
                api_token : TOKEN_CHAT.toString(),
                account_id : profileLoggedin.account_id.toString(),
                account_type : profileLoggedin.account_type.toString(),
                account_department_id : profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0',
                company_id : profileSelected.hasOwnProperty('company_detail') ? profileSelected.company_detail.account_id.toString() : '0',
                receiver_account_id : profileSelected.account_id.toString(),
                restrict_status : teamRestrictAccessStatus.toString()
            };
    
            const responseContactTeam = await QueryMasterSearchUtlities.contactRequestTeamDetail(passValuesRes);
            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseContactTeam).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

            if ( parseInt(responseContactTeam.success)===0 ){ 
                Utilities.messagePopup('error', responseContactTeam.errorMessage );
                return false; 
            }

            const conversationStatus = parseInt(responseContactTeam.result.bizchat_team_conversation.conversation_status || 0);

            if ( conversationStatus===0 ){
                dispatch( passChatTeamProfileRestrictionDetailReducer(profileSelected) );
                handleCloseProfilePopup();
                return false;

            }else{

                const conversation = responseContactTeam.result.bizchat_team_conversation.conversation_list;
                let chatPassValue = ChatUtilities.encryptChatDetail(conversation); 

                ChatUtilities.saveAccountsConversationDetail(conversation );
                history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(conversation.account_id) )}/${chatPassValue}`);

                closeSearchBox();
            }
           
            return false;
        }
        

        closeSearchBox();

        if ( ! Utilities.isEmpty(passDocumentId) && passDocumentId.toString()!=='0' ){
            const passValues = {
                account_id : parseInt(profileSelected.account_id),
                convers_detail : {},
                document_id : parseInt(passDocumentId)
            }

            dispatch( passDocumentDetail( passValues ) );

            return false;
        }

        let chatUrl = `/${ UrlSlugs.chat }/${ btoa( profileSelected.account_id ) }`;
        history.push(chatUrl);

    }


    const viewMenuProduct = () => {
        console.log(profileSelected.menu_detail);
        const menuDetail = profileSelected.menu_detail ?? {};

        if ( Object.keys(menuDetail).length>0 && menuDetail.hasOwnProperty('file_url') ){
            window.open(menuDetail.file_url, '_blank');
        }

        // Temporary Disbaled cuz PDF is directly downloaded and no use of popup
        //closeSearchBox();
        //dispatch( viewMenuPricePopupReducer(profileSelected.menu_detail ?? {}) ); 
    }


    const htmlProfileNavigation = ( type = 1) => {

        let isAccountBelongs = 0;
        if ( parseInt(profileLoggedin.account_type)===4 ){
            if ( parseInt(profileLoggedin.company_detail.account_id || 0)===parseInt(profileSelected.account_id) ){
                isAccountBelongs = 1;
            }
        }

        const encodedAccountId = btoa( profileSelected.account_id );

        let chatUrl = `/${ UrlSlugs.chat }/${encodedAccountId}/3`;
        let departmentUrl = `/${ UrlSlugs.departments }/${encodedAccountId}`;
        let teamUrl = `/${ UrlSlugs.teamMembers }/${encodedAccountId}`;
        let groupsUrl = `/${ UrlSlugs.companyGroups }/${encodedAccountId}`;
        
        if ( ! Utilities.isEmpty(passDocumentId) && passDocumentId.toString()!=='0' ){
            chatUrl = "#";
            departmentUrl = departmentUrl.toString()+'/'+btoa(passDocumentId);
            teamUrl = teamUrl.toString()+'/'+btoa(passDocumentId);
        }

        return (
                <ul className={`profile-departments${ type===1 ? '-buttons' : '' } col-8 mx-auto`}>

                    {
                        parseInt(isAccountBelongs)===0 ?
                            <>
                                {
                                    ( profileSelected.hasOwnProperty('menu_detail')  && Object.entries(profileSelected.menu_detail).length>0  && parseInt(profileSelected.menu_detail.status || 0)===1 ) &&
                                        <li>
                                            <Link to="#" onClick={ () => viewMenuProduct() } >
                                                {  Utilities.menuProductOptionLabel[parseInt(profileSelected.menu_detail.type ?? 1)].toString() }
                                            </Link>
                                        </li>
                                }
                                { 
                                    profileSelected.hasOwnProperty('department_count') && parseInt(profileSelected.department_count || 0)>1 ?
                                        <li><Link to={departmentUrl} onClick={ () => closeSearchBox() } >departments</Link></li>
                                    :
                                        <li><Link to={chatUrl} onClick={ () => handleGeneralDepartment() } >general</Link></li>
                                }

                                { 
                                    profileSelected.hasOwnProperty('company_groups_count') && parseInt(profileSelected.company_groups_count || 0)>0 &&
                                        <li><Link to={groupsUrl} onClick={ () => closeSearchBox() } >groups</Link></li>
                                }

                                { 
                                    profileSelected.hasOwnProperty('member_count') && parseInt(profileSelected.member_count || 0)>0 &&
                                        <li><Link to={teamUrl} onClick={ () => closeSearchBox() } >team member/s</Link></li>
                                }


                                
                            </>
                        :

                            <>
                                {
                                    ( profileSelected.hasOwnProperty('menu_detail')  && Object.entries(profileSelected.menu_detail).length>0  && parseInt(profileSelected.menu_detail.status || 0)===1 ) &&
                                        <li><Link to="#" className="opacity-075">{  Utilities.menuProductOptionLabel[parseInt(profileSelected.menu_detail.type)].toString() }</Link></li>
                                }
                                { 
                                    ( profileSelected.hasOwnProperty('department_count') && parseInt(profileSelected.department_count || 0)>1 ) ?
                                        <li><Link to="#" className="opacity-075">departments</Link></li>
                                    :
                                        <li><Link to="#" className="opacity-075">general</Link></li>
                                }
                                { 
                                    profileSelected.hasOwnProperty('company_groups_count') && parseInt(profileSelected.company_groups_count || 0)>0 &&
                                        <li><Link to="#" className="opacity-075">groups</Link></li>
                                }
                                { 
                                    ( profileSelected.hasOwnProperty('member_count') && parseInt(profileSelected.member_count || 0)>0 ) &&
                                        <li><Link to="#" className="opacity-075">team member/s</Link></li>
                                }
                                
                            </>
                    }
                    
                </ul>
        )

    }

    const closeSearchBox = () => {
        handleCloseProfilePopup();
        
        if (typeof props.closeSearchBox !== 'undefined' ){
            props.closeSearchBox();
        }
    }

    const getCountries = () => {

        axios
        .get( ApiUrls.country, {} )
        .then( response => {
            if ( response.status===200 ){
                const countriesList = response.data;
                
                let country_list = {};
                Object.entries(countriesList).map( ([key, row] ) => {
                    country_list[row.id] = row;
                });
                countryList = country_list;
            }
        })
        .catch( (err) => {
           
        });
    }

    const handleClickFollowingUpdateStatus = async ( hideConfirmation = 1 ) => {

        const button_name = `btn-submit-following-status`;
        const followingStatusRequest = followingStatus===0 ? 1 : 0;

        const passval = {
            account_id : profileLoggedin.account_id.toString(),
            company_id : profileSelected.account_id.toString(),
            status : followingStatusRequest.toString()
        };

        if ( parseInt(hideConfirmation)===0 ){
            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishToUnfollow,
                overlayClassName : 'normal-font-style warning',
                buttons: [
                    { label: 'close' },
                    { label: 'continue',  onClick: () => handleClickFollowingUpdateStatus()  }
                ],
            }); 

            return false;
        }

        Utilities.elementDisabledStatus(button_name, true );
        const followingStatusUpdateDb = await QueryMasterSearchUtlities.followingStatusUpdate(passval);
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(followingStatusUpdateDb).length===0 || parseInt(followingStatusUpdateDb.status ?? 0)===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(followingStatusUpdateDb.status ?? 0)===1 ){
            Utilities.messagePopup('success', followingStatusRequest===1 ? TitlesLabels.alertMessages.successfullyFollowed :  TitlesLabels.alertMessages.successfullyUnFollowed );
            setFollowingStatus(followingStatusRequest);
        }
  
    }


    useEffect( () => {

        const profileSelectedTemp = props.profileSelected ?? {};
        if ( Object.keys(profileSelectedTemp).length>0 ){
            getCountries();
            setupProfileDetail(profileSelectedTemp);
        }
        
       
        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);
            }
        }


         // CHECK LOGIN TYPE
         const loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype);
         const sesstionLoginType = JSON.parse(loginTypeStorage);
     
         if ( sesstionLoginType !== null ){
             setLoginType( parseInt(sesstionLoginType) );
         }

    }, [props.profileSelected]);


     /* useEffect( () => {
        setRequestDocumentId(passDocumentId.toString());
    }, [passDocumentId] ); */

    return (
            Object.keys(profileSelected).length>0 &&

                <Modal id="main-search-profle-popup"  show={ props.handleShowProfilePopup } onHide={ handleCloseProfilePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="lg" >
                        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
                        <Modal.Body>
                                
                            <div className="profile-selected-assoc text-center text-lowercase">
                                <div className="col-9 mx-auto">
                                 {
                                        parseInt(profileSelected.account_type)===4 ?
                                            <>
                                                <div className="pb-3 text-center mx-auto col-8 line-height-1-2">
                                                    <div className={`logo-size-75 profile-team-logo mx-auto fs-22-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url)  }}>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }</div>

                                                    <div className="font-gotham-book-16 notranslate mt-2">{profileSelected.name}</div>
                                                    { ( profileSelected.hasOwnProperty('category') && profileSelected.category!=='' ) && <div className="profile-label">{profileSelected.category}</div> }
                                                </div>

                                                <div className="profile-header-company text-left pt-3 pb-2">
                                                    <div className="logo-size-60" style={{ backgroundImage:  `url(${profileSelected.company_detail.logo_url})`  }}></div>
                                                    <div className="line-height-1-2">
                                                        <div className="font-gotham-medium-16 notranslate">{profileSelected.company_detail.name}</div>
                                                        { ( profileSelected.company_detail.hasOwnProperty('category_name') && profileSelected.company_detail.category_name!=='' ) && <div className="profile-label">{profileSelected.company_detail.category_name}</div> }
                                                    </div>
                                                </div>

                                                {
                                                    ( parseInt(props.showStatus)!==1  && profileSelected.hasOwnProperty('team_about') &&  ! Utilities.isEmpty(profileSelected.team_about) ) &&
                                                        <div className="py-2">
                                                            <div className="text-color-blue"><span className="cursor-pointer font-gotham-book-16" onClick={ () => setExpandAboutSection( expandAboutSection===true ? false : true) }>view profile</span></div>
                                                            {
                                                                expandAboutSection===true &&
                                                                    <div>
                                                                        <div id="popup-profile-about" className="white-space mt-4 mb-3 section-about" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(profileSelected.team_about) }}></div>
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="about-navigation">
                                                                                <div className="btn-arrow-up" onClick={ () => Utilities.scrollTopMore('popup-profile-about') }></div>
                                                                                <div className="btn-arrow-down" onClick={ () => Utilities.scrollDownMore('popup-profile-about') }></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        
                                                        </div>
                                                }
                                                
                                            </>
                                        : 
                                        <>
                                            <div className="profile-header text-left  mb-4">
                                                <div className={`profile-image fs-22-important`}  style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) }`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url)  }}>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }</div>
                                                <div>
                                                    <div className="profile-name notranslate">{profileSelected.name}</div>
                                                    { ( profileSelected.hasOwnProperty('category') && profileSelected.category!=='' ) && <div className="profile-label">{profileSelected.category}</div> }
                                                </div>
                                            </div>


                                            <div className="profile-address col-8 mx-auto pb-3">
                                                {
                                                    profileSelected.hasOwnProperty('address_visibility_status') ?
                                                        <>
                                                           {
                                                            parseInt(profileSelected.address_visibility_status)===1 ?
                                                                 profileSelected.hasOwnProperty('address_1') &&
                                                                 <>
                                                                    <div>{profileSelected.address_1}</div>
                                                                    <div>{profileSelected.state_name}, {profileSelected.country_name}</div>
                                                                </>
                                                            :
                                                                profileSelected.hasOwnProperty('address_2') && <div>{profileSelected.address_2}</div>
                                                           }
                                                        </>
                                                    :
                                                         profileSelected.hasOwnProperty('address_2') && <div>{profileSelected.address_2}</div>
                                                }
                                            </div>
                                            
                                        </>
                                    }
                                    
                                    
                                    {
                                        (  profileSelected.hasOwnProperty('has_contact') && parseInt(profileSelected.has_contact)>=1 ) &&
                                            <div className="d-flex align-items-center  justify-content-center  profile-contact pb-3">
                                                { profileSelected.hasOwnProperty('contact_number_mobile') && <div className="mx-4">{profileSelected.contact_number_mobile}</div> }
                                                { profileSelected.hasOwnProperty('contact_number_landline') && <div  className="mx-4">{profileSelected.contact_number_landline}</div> }
                                            </div>
                                    }
                                    

                                    {
                                        parseInt(props.showStatus)!==1 &&
                                            <>
                                                <ul className="list-company-detail-option">
                                                    {
                                                        ( ! Utilities.isEmpty(profileSelected.company_about ?? '') ) &&
                                                         <li><div className="btn-company-detail-icon info-icon"  onClick={ () => setExpandAboutSection( expandAboutSection===true ? false : true) }></div></li>
                                                    }
                                                   
                                                    {
                                                        ( ! Utilities.isEmpty(profileSelected.company_website ?? '') && Utilities.isEmpty(profileSelected.company_about ?? '')  ) &&
                                                            <li><a href={profileSelected.company_website} target="_blank" className="btn-company-detail-icon  weblink-icon"></a></li>
                                                    }

                                                    {/* {
                                                        ( profileSelected.hasOwnProperty('social_accounts') &&  ! Utilities.isEmpty(profileSelected.social_accounts.youtube) )  &&
                                                            <li><a href={ Utilities.socialLink('youtube', profileSelected.social_accounts.youtube )} target="_blank" className="btn-company-detail-icon youtube-icon"></a></li>
                                                    } */}

                                                    {
                                                        ( profileSelected.hasOwnProperty('social_accounts') &&  ! Utilities.isEmpty(profileSelected.social_accounts.twitter) )  &&
                                                            <li><a href={ Utilities.socialLink('twitter', profileSelected.social_accounts.twitter )} target="_blank" className="btn-company-detail-icon twitter-icon"></a></li>
                                                    }
                                                    
                                                    {
                                                        ( profileSelected.hasOwnProperty('social_accounts') &&  ! Utilities.isEmpty(profileSelected.social_accounts.instagram) )  &&
                                                            <li><a href={ Utilities.socialLink('instagram', profileSelected.social_accounts.instagram )} target="_blank" className="btn-company-detail-icon instagram-icon"></a></li>
                                                    }

                                                    {
                                                        ( profileSelected.hasOwnProperty('social_accounts') &&  ! Utilities.isEmpty(profileSelected.social_accounts.facebook) )  &&
                                                            <li><a href={ Utilities.socialLink('facebook', profileSelected.social_accounts.facebook )} target="_blank" className="btn-company-detail-icon facebook-icon"></a></li>
                                                    }

                                                    {
                                                        ( profileSelected.hasOwnProperty('social_accounts') &&  ! Utilities.isEmpty(profileSelected.social_accounts.tiktok) )  &&
                                                            <li><a href={ Utilities.socialLink('tiktok', profileSelected.social_accounts.tiktok )} target="_blank" className="btn-company-detail-icon tiktok-icon"></a></li>
                                                    }
                                                </ul>

                                                {
                                                    [3].includes( parseInt(props.showStatus) ) &&
                                                        <div id="btn-submit-following-status" className={`${followingStatus==0 ? 'btn-theme-red' : 'btn-theme-green-2' } my-4 button-has-loader col-4 mx-auto`} onClick={ () => handleClickFollowingUpdateStatus( followingStatus===0 ? 1 : 0) }>{ followingStatus==0 ? TitlesLabels.general.follow : TitlesLabels.general.following }</div>
                                                }

                                                {
                                                    ( profileSelected.hasOwnProperty('company_about') &&  ! Utilities.isEmpty(profileSelected.company_about) ) &&
                                                        <div className="pb-3">
                                                            {
                                                                expandAboutSection===true &&
                                                                <>
                                                                    {
                                                                        ( ! Utilities.isEmpty(profileSelected.company_website ?? '') ) &&
                                                                            <a href={ Utilities.formattedURL(profileSelected.company_website) } target="_blank" className="text-black">{Utilities.formattedURL(profileSelected.company_website) }</a>
                                                                    }
                                                                    <div>
                                                                        <div id="popup-profile-about" className="white-space mt-4 mb-3 section-about" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(profileSelected.company_about) }}></div>
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="about-navigation">
                                                                                <div className="btn-arrow-up" onClick={ () => Utilities.scrollTopMore('popup-profile-about') }></div>
                                                                                <div className="btn-arrow-down" onClick={ () => Utilities.scrollDownMore('popup-profile-about') }></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        
                                                        </div>
                                                }
                                            </>
                                    }

                                    <div className="mb-5 mt-4 ">
                                    {
                                        parseInt(profileSelected.account_type)===3 ?

                                                parseInt(props.showStatus)===1 ?

                                                    isConnect===false ?
                                                        htmlProfileNavigation(1)
                                                    :
                                                        //  general department
                                                        <Link to={`/chat/${ btoa( profileSelected.account_id ) }/3`} onClick={ () => closeSearchBox() }  className="btn-theme-black" >connect</Link>

                                                : 
                                                    <>
                                                        <ul className={`list-contact-profile-option ${ toggleDepartmentConnect===true ? 'd-block' : '' }`}>

                                                            {
                                                                isProfileLinked===false ?
                                                                    <li className={`${ toggleDepartmentConnect===true ? 'd-block' : '' }`}>
                                                                        {
                                                                            toggleDepartmentConnect===false ?
                                                                                <div className="btn-nav-chat btn-chat" onClick={ () => setToggleDepartmentConnect(true) }>chat</div>
                                                                            :
                                                                                <div className="btn-theme-black mb-4"  onClick={ () => toggleProfileOptionNav() }>connect</div>
                                                                        }
                                                                    </li>
                                                                :
                                                                    <li><div className="btn-nav-chat btn-chat opacity-075 cursor-notallow">chat</div></li>
                                                            }
                                                            
                                                            {
                                                                ( ! Utilities.isEmpty(profileSelected.latitude) && ! Utilities.isEmpty(profileSelected.longitude) ) && 
                                                                <li>
                                                                    <a href={`https://maps.google.com/?q=${profileSelected.latitude},${profileSelected.longitude}`} target="_blank" className="btn-nav-chat btn-location"><span className="opacity-0">map</span></a>
                                                                </li>
                                                            }
                                                            
                                                        </ul>

                                                        { showContactProfileOption===true && <div className="mt-4">{ htmlProfileNavigation(2) }</div> }

                                                        {
                                                            ( parseInt(profileSelected.plus_status)===1 && parseInt(loginType)===1 ) &&
                                                            <div className="mt-4 pt-2">
                                                                <div  onClick={ () => handleCloseProfilePopup() } className="cursor-pointer btn-theme-black" >company profile</div>
                                                            </div>
                                                        }

                                                    </>

                                        :
                                        
                                            <button id="btn-confirm-chat" type="button" onClick={ () => handleTeamProfile() } className="btn-theme-black button-has-loader" >
                                                {
                                                    parseInt(teamRestrictAccessStatus)===1 ?
                                                        <div className="position-relative line-height-1">
                                                            <div className="btn-icon-restricted"></div>
                                                            <div className="text-color-pink">restricted access</div>
                                                            <div>continue</div>
                                                        </div>
                                                    :
                                                        `confirm`
                                                }
                                                
                                            </button>
                                        
                                    }
                                    </div>


                                </div>
                            </div>

                    </Modal.Body>
                </Modal>

       
    )
}

export default MainSearchProfile;
