import axios from 'axios'; 
import ApiUrls from './ApiUrls';


class QueryMenuPriceUtilities {

    manageDetail = async ( passValue = {}  ) => {
        let response = {};
        if ( Object.entries(passValue).length>0 ){
            try {
                const urlRequest = ApiUrls.profileManageCatalogDetails ;
                const detailRes = await axios.post( urlRequest, passValue);
                response = detailRes.data;
                
            } catch (e) {
                response = {};
            }
        }

        return response;
    }

    uploadFile = async ( formData  ) => {

        let returnResponse = {};    
        try {
            const urlRequest = ApiUrls.profileUploadCatalogFile;
            const documentRes = await axios.post( urlRequest, formData);
            returnResponse = documentRes.data;

        } catch (e) {
            returnResponse = {};
        }

        return returnResponse;

    }
    
}

export default new QueryMenuPriceUtilities();

