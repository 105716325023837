import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle,  showMainSettings as showMainSettingsReducer } from '../../../actions';
import { getNotificationList } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';


const NotificationList = () => {

    const dispatch = useDispatch();
   
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;

    const [ selectedDetail , setSelectedDetail ] = useState({});
    const [ noticeListing , setNoticeListing ] = useState([]);
    const [ showProcessing , setShowProcessing ] = useState(true);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getNotifications = async ( profile = {} ) => {
        const passval = {
            api_token : API_TOKEN.toString(),
            master_account_id  : profile.account_id.toString()
        };
 
        setShowProcessing(true);
        const noticeListResponse = await getNotificationList(passval);
        setShowProcessing(false);
        
        if ( Object.keys(noticeListResponse).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(noticeListResponse.success)===1 ){

        }

        if ( parseInt(noticeListResponse.success)===1 ){
            setNoticeListing(noticeListResponse.result.home_notification_list);
        }else{
            Utilities.messagePopup('error', noticeListResponse.errorMessage );
        }


    }

    const handleClickSelected = ( detail = {} ) => {
        setSelectedDetail(detail);
    }

    const listingHtml = () => {
        return <>
                    {
                        showProcessing && 
                            <ul className="dashboard-list-preloader">
                                {
                                    [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                            </ul>
                    }

                    {
                        ! showProcessing && 
                        <>
                            {
                                Object.keys(noticeListing ?? []).length===0 ?
                                    <div className="text-center text-lowercase no-record-label">{ TitlesLabels.general.noRecordAvailable }</div>
                                :   
                                    <>
                                        <div className="profile-search-wrap d-none">
                                            <div className="profile-search-content">
                                                <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                                <div></div>
                                                <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                                <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                                            </div>
                                        </div>
                                        
                                        <ul id="listing-profiles"  className="list-profile-messages" >
                                            {
                                                Object.entries(noticeListing ?? []).map( ([key, row] ) =>
                                                    <li key={key} onClick={ () => handleClickSelected(row) }  data-keyword={row.company_name} >
                                                        <div><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.company_logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.company_logo)  }} onClick={ () => previewProfileImage(row.company_logo) }>{ Utilities.showHideInitial(row.logo, row.company_name) }</div></div>
                                                        <div>
                                                            <div className="profile-name notranslate" data-name={row.company_name}>{row.company_name}</div>
                                                            <div className="profile-label text-gray" data-title={row.company_name}><div className=" ellipsis-1 col-6 px-0">{row.title}</div></div>
                                                        </div>
                                                        <div className="time">{ Utilities.formatDateList(row.create_date) }</div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </>
                            }
                        </>
                    }
            </>
    }

    const selectedDetailHtml = () => {
        
        if ( Object.keys(selectedDetail).length===0 ){ return false; }
        
        return  <div className="col-md-6 mx-auto form-theme text-lowercase pb-5 mb-5">

                    <div className="upload-avatar avatar-wrap mb-4">
                        <div className="w-100">
                            <div className="upload-box-preview" style={{ backgroundImage:  `url(${selectedDetail.image_path})` }}></div>
                        </div>
                    </div>
                    
                    <div className="font-gotham-book fs-18 mb-2">{selectedDetail.title}</div>
                    <div>{selectedDetail.description}</div>

            </div>
    }

    useEffect( async () => {
        
        PagesAuthentication.getUserLoggedin();

        const userAccountDetailTemp = PagesAuthentication.getMasterAccountDetail();
        await getNotifications(userAccountDetailTemp);

        dispatch( pageTitle(`${TitlesLabels.navigations.notifications}`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );

       
    }, [] );

    return (
        <div className="notification-management dashboad-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <div className={`${Object.keys(selectedDetail).length===0 ? 'current' : 'cursor-pointer'}`} onClick={ () => setSelectedDetail({}) }>{ TitlesLabels.navigations.notifications }</div>
                            { Object.keys(selectedDetail).length>0 && <div className="current">{ selectedDetail.company_name }</div> }
                           
                          </li>
                         <li className="btn-option">
                            {
                                Object.keys(noticeListing).length>0 && <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() } title={ TitlesLabels.general.search }></div>
                            }
                            <Link to={`/${UrlSlugs.settings_notificationsFollowSettings}`} className="btn-icon btn-comments-plus" title={ TitlesLabels.general.followSettings } ></Link>
                            <div className="btn-icon btn-gear" onClick={ () => dispatch( showMainSettingsReducer( new Date() ) ) } title={ TitlesLabels.settings } ></div>
                         </li>
                     </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container">

                        { Object.keys(selectedDetail).length>0 ? selectedDetailHtml() : listingHtml() }


                </div>
            </div>
       </div>
    )
}

export default NotificationList;