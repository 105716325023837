import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import TitlesLabels from '../../helpers/TitlesLabels';


const FollowConfirmation = (props) => {

    const handleClosePopUp = ( followStatus = 0) => {
        props.handleClosePopUp( followStatus );
    }

    
    useEffect( () => {

    }, [] );

    return (

        <Modal id="popup-follow-confirmation"  show={ props.showPopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header className="border-0 text-center d-block font-gotham-medium fs-22">
                <div className="pt-2">{ TitlesLabels.general.noticesAndNotification }</div>
            </Modal.Header>
            <Modal.Body className="px-5 pb-5 text-center">
                <div className="fs-18 text-gray-2" dangerouslySetInnerHTML={{ __html: TitlesLabels.confirmMessages.followFeatures  }}></div>


                <div className="button-group-boolean pt-5">
                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => handleClosePopUp(1) } >activate</button>
                    <button type="button" className=" btn-theme-black text-lowercase button-has-loader" onClick={ () => handleClosePopUp(0) } >ignore</button>
                </div>

            </Modal.Body>   
        </Modal>
    )
}

export default FollowConfirmation;
