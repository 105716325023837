import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';

import PagesAuthentication from '../../helpers/PagesAuthentication';
import UrlSlugs from '../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSearch, passProfile, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';


const ManageTeam = ({match}) => {

    const dispatch = useDispatch();

    const [ listProfiles, setListProfiles ] = useState([]);
    const [ companyProfile, setCompanyProfile ] = useState([]);
    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getMembers = ( accountID ) => {
  
        if ( parseInt(accountID)>0 ){
            axios
            .post( ApiUrls.teamList , { company_id : accountID } )
            .then( response => {

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);

                    if ( dataStatus===1 ){
                        if ( Utilities.isEmpty(dataResponse.list) ){
                            setShowEmpty(true);
                        }else{
                            setShowEmpty(false);

                            let memberList = [];
                            Object.entries(dataResponse.list).map( ( [id, memberdetail] ) => {
                                memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];
                            });

                            setListProfiles(memberList);
                        }
                    }else{
                        setShowEmpty(true);
                    }
                }

                setContentLoaderClass('');
            })
            .catch( (err) => {
                setShowEmpty(true);
                setContentLoaderClass('');
            }); 
        }

    }

    const setupProfileForMainSearch = () => {
        dispatch( passProfile(profileDetail) );
        dispatch( showMainSearch(2) );
    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    useEffect( () => {
        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.teamMembers }`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();

    },[])

    useEffect( () => {

        let reqAccountID = match.params.mid;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if ( reqAccountID>0 ){

                // ASSOCIATED ACCOUNTS
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if ( accountsList!==null ){
                    accountsList = JSON.parse(accountsList);

                    if ( Object.keys(accountsList).length>0 ){

                        Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                            const account_id = parseInt(acctdetail.account_id);
                            if (account_id===reqAccountID){

                                let accountProfile = Utilities.formatProfileDetail(acctdetail);
                                setProfileDetail(accountProfile);

                                let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                                setCompanyProfile(profileCompanyTemp);

                                getMembers(profileCompanyTemp.account_id);
                            }
                        });
                        
                    }

                }
                
            }
        }

    }, [match.params.mid] );

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.settings }</div>
                            { companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div> }
                            <div className="current">{ TitlesLabels.navigations.teamMembers }</div>
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            <div className="btn-icon btn-plus" onClick={ () => setupProfileForMainSearch() }></div>
                            <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() }></div>
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() }></div>

                         </li>
                     </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container">

                        { 
                            ! Utilities.isEmpty(contentLoaderClass) &&
                                <ul className="listing-profiles-preloader mt-4">
                                {
                                    [...Array(12).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                                </ul>
                        }

                        <div className="profile-search-wrap d-none">
                            <div className="profile-search-content">
                                <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                <div></div>
                                <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                            </div>
                        </div>


                        <ul id="listing-profiles" className={`listing-profiles mt-4 ${ Object.keys(listProfiles).length===0 ? 'd-none' : '' } `}>
                        {
                            Object.entries(listProfiles).map( ([key, row] ) =>
                                <li key={key} data-keyword={row.name} >
                                    <Link to={ `/${ UrlSlugs.settings_team_edit}/${btoa(profileDetail.account_id)}/${btoa(row.account_id)}`} className="profile-wrap">
                                        <div className="profile-image-wrap"><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url) }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div></div>
                                        <div className="profile-name notranslate" data-name={row.name}>{row.name } </div>
                                        <div className="profile-label">{row.team_position }</div>
                                    </Link>
                                </li>
                            )
                        }
                        </ul>

                        { showEmpty && <div className="text-center text-lowercase no-record-label">{ Utilities.labelTeamAvailable }</div>  }
            

                </div>
            </div>
       </div>
    )
}

export default ManageTeam;