import axios from 'axios';
import ApiUrls from './ApiUrls';

const API_TOKEN = process.env.REACT_APP_chat_api_token;

export const notificationDescriptionLimit = 2000;

export const updateCompanyFollowStatus = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.profileUpdateCompanyFollowStatus, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const getNoticeList = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.followersNoticeList, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const getCompanyFollowers = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.noticeCompanyFollowers, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const submitSendNotification = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.noticeSendNotification, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const uploadNoticeFile = async ( formData  ) => {

    let returnResponse = {};    
    try {
        const uploadRes = await axios.post( ApiUrls.noticeUploadFile, formData);
        returnResponse = uploadRes.data;

    } catch (e) {

    }

    return returnResponse;
}


export const updateCompanyFollowers = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.noticeUpdateCompanyFollowers, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const getNotificationList = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.followersNotificationList, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const getFollowingList = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.followersFollowingList, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const unfollowProfiles = async ( passval = {} ) =>{
    let responseDetail = {}

    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_TOKEN.toString() };
        try {
            const responseURL = await axios.post(  ApiUrls.followersUnfollowCompany, passval );
            responseDetail = { ...responseDetail, ...responseURL.data};

        } catch (e) {
            
        }
    }

    return responseDetail;
}
