import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import Utilities from '../../helpers/Utilities';

const DepartmentFormSamples = ( props ) => {

    const currentYear = new Date().getFullYear();
    const maxWords = 200;

    const [ formStatus, setFormStatus ] = useState(1);
    const [ loginType , setLoginType ] = useState(0);
    const [ loginDetail, setLoginDetail ] = useState({});

    useEffect( () => {
        const formTypeDetail = props.formTypeDetail;
        
        if ( typeof formTypeDetail!=='undefined'){
            setFormStatus( parseInt(formTypeDetail.value) );
        }

        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);
            setLoginDetail(loginBasicInfo);
        }

        // CHECK LOGIN TYPE
        const loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginTypeStorage);
    
        if ( sesstionLoginType !== null ){
            setLoginType( parseInt(sesstionLoginType) );
        }

        return () => {
            setLoginType(0);
        }

    }, [props.formTypeDetail] );


    const formHtmlSample = ( formType = 1 ) => {
        let htmlReturn = '';
        let formTitle = Utilities.viewFormLabel(formType);

        if ( formType===2 ){
            htmlReturn = <Fragment>
                            <div className="form-group active">
                                <div className="form-control">Andrew jake bagsit</div>
                                <label className="form-label">Name</label>
                            </div>
                            
                            <div className="form-col-2">
                                <div className="form-group active">
                                    <div className="form-control">22 june { currentYear }</div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control">6:30pm</div>
                                    <label className="form-label">Time</label>
                                </div>

                            </div>

                            <div className="form-col-2">
                                <div className="form-group active">
                                    <div className="form-control">6</div>
                                    <label className="form-label">Number of People</label>
                                </div>
                            </div>

                            <div className="form-group active pb-2">
                                <div className="form-control form-control-inherit">please get me a seat for 6 with a balcony view. thanks !</div>
                                <label className="form-label">Special Request</label>
                            </div>
                        </Fragment>

        }else if ( formType===3 ){
            htmlReturn = <Fragment>
                            <div className="form-col-2">
                                <div className="form-group active">
                                    <div className="form-control">22 june { currentYear }</div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control">6:30pm</div>
                                    <label className="form-label">Time</label>
                                </div>

                            </div>

                            <div className="form-group">
                                <div className="form-control">Complain Title</div>
                                <label className="form-label">Subject</label>
                            </div>

                            <div className="form-group active">
                                <div className="form-control form-control-inherit pb-2">
                                    <p>I wish to lodge a formal complaint against the services that was rendered by your staff to me on 22 june { currentYear }.</p>
                                </div>
                                <label className="form-label">Type Here</label>
                            </div>
                        </Fragment>
                        
        } else if ( formType===1 ){
            htmlReturn = <Fragment>
                            <div className="form-group active">
                                <div className="form-control">Andrew jake bagsit</div>
                                <label className="form-label">Name</label>
                            </div>
                            
                            <div className="form-col-2">
                                <div className="form-group active">
                                    <div className="form-control">22 june { currentYear }</div>
                                    <label className="form-label">Date</label>
                                </div>

                                <div className="form-group active">
                                    <div className="form-control">6:30pm</div>
                                    <label className="form-label">Time</label>
                                </div>

                            </div>

                            <div className="form-group active pb-2">
                                <div className="form-control form-control-inherit">please get me a seat for 6 with a balcony view. thanks !</div>
                                <label className="form-label">Special Request</label>
                            </div>
                        </Fragment>
        }

        return { htmlReturn : htmlReturn, formTitle : formTitle } ;
    }

   
    return (

       <Modal show={ props.showFormPopUp } onHide={ props.handleCloseFormPopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  dialogClassName="modal-dialog-theme" size="md" >
            <Modal.Header closeButton className="border-bottom-0">
                <h2 className="text-center font-gotham-light-22 w-100 mt-3 text-lowercase">{ formHtmlSample(formStatus)['formTitle'] }</h2>
            </Modal.Header>
            <Modal.Body className="px-5 pb-5">

                    <div className="text-lowercase form-theme form-theme-medium px-5 sample-form">

                        { formHtmlSample(formStatus)['htmlReturn'] }

                        <div className="text-right font-gotham-book-12 text-color-gray mt-n2 pb-5">{maxWords} characters</div>

                        {
                            ( ( [1,2].includes(parseInt(loginDetail.team_is_admin)) ||  parseInt(loginDetail.account_type)===1 ) && loginType===1 ) &&
                                <button type="button" className="btn-theme-black text-lowercase mt-5" onClick={ () => props.setupFormType() }>Choose</button>
                        }
                        

                    </div>

            </Modal.Body>
        </Modal>
    )
}

export default DepartmentFormSamples;
