import React from 'react';
import { social_links } from '../helpers/Constants';
import UrlSlugs from '../helpers/UrlSlugs';

const Footer = () => {

    const siteTitle = process.env.REACT_APP_site_title; 

    return (
        <footer className="footer text-lowercase">
           <div className="container py-4 my-1">

               <div className="row mt-1">
                    
                    <div className="col-3">
                        <h3>Explore</h3>
                        <ul className="footer-nav">
                            <li><a href={`${ UrlSlugs.page_helpCenter }`} target="_blank">Help Center</a></li>
                            <li><a href={`${ UrlSlugs.page_termsConditions }`} target="_blank">Terms & Conditions</a></li>
                            <li><a href={`${ UrlSlugs.page_privacyPolicy }`} target="_blank">Privacy Policy</a></li>
                            {/* <li><a href={`${ UrlSlugs.page_siteMap }`} target="_blank">Site Map</a></li> */}
                        </ul>
                    </div>

                    <div className="col-3">
                        <h3>Download</h3>
                        <ul className="footer-nav">
                            <li><a href={ UrlSlugs.link_appStore }  target="_blank" >iphone</a></li>
                            <li><a href={ UrlSlugs.link_playStore }  target="_blank" >android</a></li>
                        </ul>
                    </div>

                    <div className="col-3">
                        <h3>company</h3>
                        <ul className="footer-nav">
                            <li><a href={`${ UrlSlugs.page_beonchat }`}>{siteTitle}.com</a></li>
                            <li><a href={`${ UrlSlugs.page_bizcom_about }`}>about</a></li>
                            <li><a href={`${ UrlSlugs.page_bizcom_getInTouch }`}>get in touch</a></li>
                        </ul>
                    </div>

                    <div className="col-3">
                        <div style={{ backgroundImage: `url(${process.env.REACT_APP_img_folder}/logo/logo.svg)` }} className="footer-logo"></div>
                        <div className="mt-2">copyright &copy; {  new Date().getFullYear() }</div>

                        <div className=" mt-3 mb-2">follow us on</div>
                        <ul className="footer-social">
                            <li><a href={ social_links.facebook }  rel="noreferrer" alt="facebook" title="facebook" target="_blank" className="facebook"></a></li>
                            {/* <li><a href="https://www.linkedin.com"  rel="noreferrer" alt="linkedin" title="linkedin" target="_blank" className="linkedin"></a></li> */}
                            <li><a href={ social_links.instagram }  rel="noreferrer" alt="instagram" title="instagram" target="_blank" className="instagram"></a></li>
                            <li><a href={ social_links.twitter }  rel="noreferrer" alt="twitter" title="twitter" target="_blank" className="twitter"></a></li>
                            <li><a href={ social_links.youtube }  rel="noreferrer" alt="youtube" title="youtube" target="_blank" className="youtube"></a></li>
                        </ul>
                        
                    </div>

               </div>
           </div>
        </footer>
    )
}

export default Footer;