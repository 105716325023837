import React, { useEffect, useState, } from 'react';
import {  useHistory  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { showHeader as dispatchShowHeader, showFooter as dispatchShowFooter } from '../actions';
import Utilities from '../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';
import QueryUtilities from '../helpers/QueryUtilities';


const DirectAccess = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
    if ( loginType!==null ){
        loginType = JSON.parse(loginType);
        loginType = parseInt(loginType);
    }else{
        loginType = 1;
    }

    const redirectDashboard = () => {
        let urlDashboard = loginType===2 ? `/${UrlSlugs.departmentDashboard}` : `/${UrlSlugs.dashboard}`;
        history.push( urlDashboard );
    }


    const getCompanyDetailByTeamAccountId = async ( account_id = 0 ) => {
        account_id = parseInt(account_id);
        
        if (account_id>0 ){
            dispatch( dispatchShowLoader(1) );
            const responseProfileDetail = await QueryUtilities.getProfileDetail(account_id, 1);
            dispatch( dispatchShowLoader(0) );
            
            if ( Object.keys(responseProfileDetail).length===0 ){
                redirectDashboard();
                return false;
            }

            if ( responseProfileDetail.hasOwnProperty('company_detail') ){
                history.push(`/${UrlSlugs.teamMembers}/${btoa(responseProfileDetail.company_detail.account_id)}/${btoa(account_id)}`); // companyid/teamid

            }else{
                redirectDashboard();
            }

        }else{
            redirectDashboard();
        }
    }


    useEffect( async () => {
        
        dispatch( dispatchShowHeader(0) );
        dispatch( dispatchShowFooter(0) );

        PagesAuthentication.getUserLoggedin();


        const segment = Utilities.getUrlSegments();
        const segmentName = segment[0].toLowerCase(), segmentEncrypted = (segment[1]??'').toString();
        let segmentDecrypted = [], decryptedSlug = '';

    
        if ( segmentName!=='direct-access' ){
            redirectDashboard();
            return false;
        }

        if ( !Utilities.isEmpty((segmentEncrypted??'')) ){
           
            segmentDecrypted = atob(segmentEncrypted).toString().split('/');
            decryptedSlug = segmentDecrypted[0].toLowerCase();
            //console.log(decryptedSlug, segmentDecrypted);

            if ( decryptedSlug==='comp_group_poup' ){
                history.push(`/${UrlSlugs.companyGroups}/${btoa(segmentDecrypted[1])}/${btoa(segmentDecrypted[2])}`); // companyid/groupid

            }else if (decryptedSlug==='team_connect_poup' ){
                await getCompanyDetailByTeamAccountId(segmentDecrypted[1]);

            }else if (decryptedSlug==='comp_connect_poup' ){

                // http://localhost:3002/direct-access/Y29tcF9jb25uZWN0X3BvdXAvMTA2/cornerhouse
                
                let urlDashboard = loginType===2 ? `/${UrlSlugs.departmentDashboardDirectConnect}` : `/${UrlSlugs.dashboardDirectConnect}`;
                history.push(`${urlDashboard}/${btoa(segmentDecrypted[1])}`);
            }
           
        }else{
            redirectDashboard();
        }


        
    }, [] );

    return (<></>)
}

export default DirectAccess;