import axios from 'axios';
import ApiUrls from './ApiUrls';

export const  getProfilesByCityId =  async ( city_id = 0 ) => {
    let response = {};
    if ( parseInt(city_id)>0 ){
        try {
            const profileDetailRes = await axios.post( ApiUrls.searchContactsByCityId , { city_id : city_id.toString() });
            response = profileDetailRes.data;

        } catch (e) {

        }

    }
    return response;
}
