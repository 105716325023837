import React, { useEffect, useState } from 'react';
import axios from 'axios';

import MobileSelection from '../components/MobileSelection';
import ApiUrls from '../helpers/ApiUrls';
//import Utilities from '../helpers/Utilities';

const LoginInputSelection = ( props ) => {

    const [ loginView, setLoginView ] = useState(0);
    const [ countryList, setCountryList ] = useState([]);

    const handeClick_loginView = ( type = 0) => {
        setLoginView(type);
        props.setLoginView(type);
    }

    const getCountries = async () => {
        await axios
            .get( ApiUrls.country , {} )
            .then( response => {
                if ( response.status===200 ){
                    setCountryList(response.data);
                }
            })
            .catch( (err) => {});
    }

    const changeCountryCode = ( passValues, inputName = '' ) => {
        props.pushValuePhonecode( passValues.id.toString() );
    }

    const loginInputHtml = ( loginType = 0 ) => {
        let inputHmtml = <div className="form-group">
                            <div className="form-col-2 cursor-pointer">
                                <div className="form-control text-center text-placeholder-color" onClick={ () => handeClick_loginView(1) }>email</div>
                                <div className="form-control text-center text-placeholder-color" onClick={ () => handeClick_loginView(2) }>phone no</div>
                            </div>
                        </div>

        if ( loginType===1){
            inputHmtml = <div className="form-group">
                            <input type="text" name="email" className="form-control  text-center" placeholder=" " autoFocus value={ props.formValues.email ?? '' } onChange={ (e) => props.pushValue(e, 'email') }  autoComplete="new-password" ></input>
                            <label className="form-label  text-center">Email</label>
                        </div>
        }else if (loginType===2){
            inputHmtml = <>
                             <MobileSelection inputValue={props.formValues.phone_number} countryListProvided={countryList} inputLabel="phone number" countryCodeProvided={props.formValues.phone_code} inputListingId="list-country-current" inputName="phone_number" pushValue={props.pushValue} changeCountryCode={changeCountryCode} showPlaceholder={false} autoFocus={true} showDropdownArrow={true} />
                        </>
        }

        return inputHmtml;
    }

    useEffect( () => {
        getCountries();
    }, []);

    useEffect( () => {
        setLoginView( props.loginType ?? 0 );
    }, [props.loginType]);


    return (
       <>
            { loginInputHtml(loginView) }
       </>
    )
}

export default LoginInputSelection;