import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import jQuery from 'jquery';

import Utilities from '../../../helpers/Utilities';
import ApiUrls from '../../../helpers/ApiUrls';

import MobileSelection from '../../../components/MobileSelection';
import { checkCredentailMobileValidity, updateCredentailMobile } from '../../../helpers/SecurityProfile';
import TitlesLabels from '../../../helpers/TitlesLabels';
import { reloadProfile } from '../../../actions';
import { checkMobileNumberExistance } from '../../../helpers/VerificationUtilities';

const Mobile = (props) => {

    const dispatch = useDispatch();

    const [ countryList, setCountryList ] = useState([]);

    const profileProvided = props.loginDetailProvided ?? {};

    let defaultFormValues = {
        current_mobile_number : '',
        current_mobile_code : profileProvided.country_code,
        new_mobile_number : '',
        new_mobile_code : profileProvided.country_code,
        confirm_mobile_number : '',
        confirm_mobile_code : profileProvided.country_code,
    }
    
    const [ formValues, setFormValues ] = useState(defaultFormValues);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if ( [...Object.keys(defaultFormValues)].includes(fieldName) ){

            let mobile_number = value.replace(' ','');
            if ( ! Utilities.isEmpty(mobile_number) && ! Utilities.contactNumberValidate( mobile_number, 1 ).status ){
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }

            if ( fieldName==='current_mobile_number'){
                formValuesTemp = { ...formValuesTemp, current_mobile_number : value };
            }else if ( fieldName==='new_mobile_number'){
                formValuesTemp = { ...formValuesTemp, new_mobile_number : value };
            }else if ( fieldName==='confirm_mobile_number'){
                formValuesTemp = { ...formValuesTemp, confirm_mobile_number : value };
            }
            
        }

        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);
    }

    const getCountries = () => {
        axios
        .get( ApiUrls.country , {} )
        .then( response => {
            if ( response.status===200 ){
                setCountryList(response.data);
            }
        })
        .catch( (err) => {});
    }

    const changeCountryCode = ( passValues, inputName = '' ) => {
        let formValuesTemp = formValues;
        if ( inputName==='current_mobile_number'){
            formValuesTemp = { ...formValuesTemp, current_mobile_code : passValues.id };
        }else if ( inputName==='new_mobile_number'){
            formValuesTemp = { ...formValuesTemp, new_mobile_code : passValues.id };
        }else if ( inputName==='confirm_mobile_number'){
            formValuesTemp = { ...formValuesTemp, confirm_mobile_code : passValues.id };
        }
        setFormValues(formValuesTemp);
    }

    const handleOnclickSubmit = async () => {

        const button_name = 'btn-submit-mobile';
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(profileProvided).length===0 ){ return false; }

        let errorCounter = 0;
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( Utilities.isEmpty(value)){
                jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                errorCounter++;

            }else if(! ['current_mobile_code', 'new_mobile_code', 'confirm_mobile_code'].includes(key) ){
                let mobile_number = value.replace(' ','');
                if (! Utilities.contactNumberValidate( mobile_number, 1 ).status ){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }
        });
        if ( errorCounter>0 ){  return false; }
      

        Utilities.elementDisabledStatus(button_name, true );
        const responseMobileValidation = await checkCredentailMobileValidity({ 
            account_id: profileProvided.account_id.toString(),
            country_id : formValues.current_mobile_code.toString(),
            mobile : formValues.current_mobile_number.toString(),
        });
        
        Utilities.elementDisabledStatus(button_name, false ); 
        
        if ( Object.keys(responseMobileValidation).length===0 ){ return false; }

        if ( parseInt(responseMobileValidation.status)===0 ){
            Utilities.messagePopup('Error', TitlesLabels.general.currentMobileIncorrent );
        }else{

            const   new_mobile_number_conde = formValues.new_mobile_code+'-'+formValues.new_mobile_number,
                    confirm_mobile_number_code = formValues.confirm_mobile_code+'-'+formValues.confirm_mobile_number;

            if ( new_mobile_number_conde!==confirm_mobile_number_code ){
                Utilities.messagePopup('Error', TitlesLabels.general.newConfirmMobileNotSame );
                
            }else{

                Utilities.elementDisabledStatus(button_name, true );
                const responseMobileCheckingDB = await checkMobileNumberExistance( formValues.confirm_mobile_code, formValues.confirm_mobile_number, formValues.current_mobile_code,formValues.current_mobile_number  );
                Utilities.elementDisabledStatus(button_name, false );

                if ( Object.keys(responseMobileCheckingDB).length>0 ){
                    if ( parseInt(responseMobileCheckingDB.exist)===1 ){
                        Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist );
                        return false;
                    }

                }
          
                    Utilities.elementDisabledStatus(button_name, true );
                    const responsePasswordUpdate = await updateCredentailMobile({ 
                        account_id: profileProvided.account_id.toString(),
                        country_id : formValues.new_mobile_code.toString(),
                        mobile : formValues.new_mobile_number.toString(),
                    });
                    Utilities.elementDisabledStatus(button_name, false );
                    
                    if ( Object.keys(responsePasswordUpdate).length===0 ){
                        Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotUpdateMobile );
                    }else{

                        if ( parseInt(responsePasswordUpdate.status)===0 ){
                            Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotUpdateMobile );
                        }else{
                        
                            Utilities.messagePopup('success', TitlesLabels.alertMessages.mobileUpdatedSuccessfully );
                            setFormValues({
                                            ...defaultFormValues,
                                            current_mobile_code : formValues.new_mobile_code,
                                            new_mobile_code : formValues.new_mobile_code,
                                            confirm_mobile_code : formValues.new_mobile_code
                                    });
                            

                            // UPDATE PROFILE IN LIST 
                            Utilities.updateProfileInAccountList({...profileProvided, country_code : formValues.new_mobile_code, mobile_number : formValues.new_mobile_number});
                            dispatch( reloadProfile(Date.now()) );
                        }

                    } 
            }
        }

    }


    useEffect( () => {
        getCountries();
    }, [] );

    return (
        <>
            <MobileSelection inputValue={formValues.current_mobile_number} countryListProvided={countryList} inputLabel="Current Mobile Number" countryCodeProvided={formValues.current_mobile_code} inputListingId="list-country-current" inputName="current_mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCode} />

            <MobileSelection inputValue={formValues.new_mobile_number} countryListProvided={countryList} inputLabel="New Mobile Number" countryCodeProvided={formValues.new_mobile_code} inputListingId="list-country-new" inputName="new_mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCode} />

            <MobileSelection inputValue={formValues.confirm_mobile_number} countryListProvided={countryList} inputLabel="Confirm Mobile Number" countryCodeProvided={formValues.confirm_mobile_code} inputListingId="list-country-confirm" inputName="confirm_mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCode}/>

            <button type="button" id="btn-submit-mobile" className="btn-theme-black  button-has-loader mt-5" onClick={ () => handleOnclickSubmit() }>submit</button>
        </>
    )
}

export default Mobile;