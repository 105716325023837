import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';
import { passwordForgotSendMail } from '../../helpers/VerificationUtilities';
import jQuery from 'jquery';


const ForgotPassword = ( props ) => {


    const [ emailAddress , setEmailAddress ] = useState(''); 

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let  value = e.target.value;

        if ( fieldName==='email_address'){
            setEmailAddress(value);
        }

        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
    }


    const highlightInput = () => {
        jQuery('[name="email_address"]').closest('.form-group').addClass('error');
    }

    const submitEmail = async () =>{

        const button_name = 'btn-submit-send-email';
        Utilities.elementDisabledStatus(button_name, false );

        if ( ! Utilities.isEmpty(emailAddress) ){

            if ( Utilities.isEmail(emailAddress) ){


                Utilities.elementDisabledStatus(button_name, true );
                const responseInformation = await passwordForgotSendMail( emailAddress );
                Utilities.elementDisabledStatus(button_name, false );

                if ( Object.keys(responseInformation).length>0 ){
                    const status = parseInt(responseInformation.success)
                    if ( status===1 ){
                        setEmailAddress('');
                        Utilities.messagePopup('success', responseInformation.successMessage );
                        props.handleForgotPasswordClosePopUp();

                    }else{
                        Utilities.messagePopup('error', responseInformation.errorMessage );
                    }
                   
                    
                }else{
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.cantSendEmail );
                }

            }else{
                highlightInput();
            }
        }else{
            highlightInput();
        }
        
    }

    useEffect(() => {

        return () => {
            setEmailAddress('');
        }

    }, []);

    return (

       <Modal id="popup-forgot-password"  show={ props.forgotPasswordPopup } onHide={ props.handleForgotPasswordClosePopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md" >
            <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline  pt-4">forgot password?</Modal.Header>
            <Modal.Body>
                <div className="fs-14 text-gray text-center">enter your email address and<br />we'll send you a link to reset your password</div>
                <div id="form-new-password" className="col-md-10 mx-auto text-lowercase form-theme py-4">

                    <div className="form-group ">
                        <input type="text" name="email_address" className="form-control" placeholder=" " autoFocus autoComplete="off" value={ emailAddress }  onChange={ (e) => pushValue( e, 'email_address')  } ></input>
                        <label className="form-label">email address</label>
                    </div>

                    <div className="button-wrap text-lowercase text-center mt-5">
                        <div className="mb-3">
                            <button type="button" id="btn-submit-send-email" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => submitEmail() }>reset password</button>
                        </div>
                    </div>

                </div>


            </Modal.Body>
        </Modal>
    )
}

export default ForgotPassword;
