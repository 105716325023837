export const social_links = {
    facebook : `https://www.facebook.com/beonchat`,
    youtube : `https://www.youtube.com/channel/UCTgsW-IB7wyBcMNiAOHgBSA`,
    twitter : `https://twitter.com/BeOnChat`,
    instagram : `https://www.instagram.com/beonchat`,
    tiktok : `#`,
    whatsapp : `https://wa.me/27693282028`
}

export const video_links = {
    beonchat : `https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat/beonchat_asset/videos/Beonchat`,
    beonchat_youtube : `https://www.youtube.com/embed/d2PBpDCvBMQ`
}