import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';

import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';


import { showHeader, showFooter, showLoader, pageTitle,  showMainSettings as showMainSettingsReducer, showDepartmentSettings as showDepartmentSettingsReducer , showPopupQuickLinks as showPopupQuickLinksReducer, showPopupVideo as showPopupVideoReducer } from '../../actions';

import { sendAppReports  } from '../../helpers/SendingEmails';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import { getAppVideos, getMarketingTools, talkToUsConversation } from '../../helpers/BizcomAssist';
import ChatUtilities from '../../helpers/ChatUtilities';

const BizcomAssist = () => {

    const history = useHistory();
    const dispatch = useDispatch();
   
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const IMG_PATH = process.env.REACT_APP_img_folder;
    //const errorMsg = process.env.REACT_APP_message_error_process;
    const siteTitle = process.env.REACT_APP_site_title;
    const maxAttachmentAllowed = 1;

    let defaultFormValues = {
        email : '',
        message : '',
        subject : siteTitle+' web app suggestion'
    }


    const fieldsChecker = ['message'];
    const subjectList = { 1 : 'suggestion', 2: 'app running issue' }; //, 3 : 'talk to team' };
    const subjectListMail = { 1 : defaultFormValues.subject , 2: siteTitle+' web app bug report' }; 
    

    const [ subjectListSelection , setSubjectListSelection ] = useState(subjectList);
    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ parentTabActive , setParentTabActive ] = useState(1);
    const [ childTabActive , setChildTabActive ] = useState(1);
    const [ headerTypeCurrent , setHeaderTypeCurrent ] = useState(1);
    const [ includeInformationBool , setIncludeInformationBool ] = useState(false);
    const [ attachments , setAttachments ] = useState([]);
    const [ attachment , setAttachment ] = useState('');

    const [ loaderIframe , setLoaderIframe ] = useState({ terms : false, licenses : false });
    const [ marketingList , setMarketingList ] = useState([]);
    const [ videoList , setVideoList ] = useState([]);
    

    const handleSelectFile = () => {
        jQuery('input#talkus_attachment').trigger('click');
    }
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if ( fieldName==='message'){
            formValuesTemp = { ...formValuesTemp, ...{ message : value } };
        }
        
        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
    }

    const previewAttachment = ( e ) => {
        let fileLocation = e.target.value;
        const fileValue = e.target.files[0];
        const fileSize = fileValue.size ?? 0;
        let fileLocationArr = fileLocation.toString().split("\\");
    
        const fileSizeFortmatted = Utilities.formatBytes(fileSize, 2);
        const fileSizeValue = fileSizeFortmatted.split(' ')[0].toLowerCase();
        const fileSizeExt = fileSizeFortmatted.split(' ')[1].toLowerCase();
        
        if ( ! ['bytes', 'kb'].includes(fileSizeExt) && parseFloat(fileSizeValue)>3 ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.exceededMaximumFileSize );
            jQuery(e.target).val('');
            return false;
        }

        if ( parseInt(attachments.length)===parseInt(maxAttachmentAllowed) ){
            Utilities.messagePopup('error', `maximum of ${maxAttachmentAllowed} attachments allowed!` );
            jQuery(e.target).val('');
            return false;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            const filebase64 = e.target.result;
            const attachmentsTemp = [...attachments, { name : fileLocationArr.slice(-1)[0].toString(), base64 : filebase64 }];

            setAttachments(attachmentsTemp);
            jQuery(e.target).val('');
        };

        setAttachment(fileValue);
        reader.readAsDataURL(fileValue);
    }

    const removeAttachment = (e, key = -1 ) => {
        key = parseInt(key);

        let attachmentsTemp = attachments;
        if ( key>-1 ){
            attachmentsTemp.splice(key, 1);
        }
        attachmentsTemp = [...attachmentsTemp];
        setAttachments(attachmentsTemp);
        setAttachment('');
       
    }

    const handleTabChange = async( tabId = 1 ) => {
        tabId = parseInt(tabId);
        setMarketingList({});

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        let passval = {}, responseDb = {} ;

        if ( tabId===1 ){

        }else if ( tabId===2 ){
            window.open( UrlSlugs.page_helpCenter, '_blank');

        }else if ( tabId===3 ){
            //window.open( UrlSlugs.page_termsConditions, '_blank');
            setLoaderIframe({ ...loaderIframe, ...{ terms : true, licenses : false } });

        }else if ( tabId===4 ){
            setLoaderIframe({ ...loaderIframe, ...{ terms : false, licenses : true } });

        /* }else if ( tabId===5 ){
            window.open( UrlSlugs.page_talkToUsUrl, '_blank'); */

        }else if ( tabId===6 ){

            
            passval = {
                api_token : API_TOKEN.toString(),
                account_id : userLoginDetailTemp.account_id.toString(),
                profile_id : parseInt(userLoginDetailTemp.account_type)===4 ? userLoginDetailTemp.company_detail.account_id.toString() : userLoginDetailTemp.account_id.toString(),
                profile_type : parseInt(userLoginDetailTemp.account_type)===4 ? userLoginDetailTemp.company_detail.account_type.toString() : userLoginDetailTemp.account_type.toString(),
            }


            dispatch( showLoader(1) );
            responseDb = await getMarketingTools(passval);
            dispatch( showLoader(0) );
          
            if ( parseInt(responseDb.success)===1 ){
                setMarketingList(responseDb.result.beonchat_marketing_tools);
            }


        }else if ( tabId===7 ){

            passval = {
                api_token : API_TOKEN.toString(),
                account_id : userLoginDetailTemp.account_id.toString()
            }

            dispatch( showLoader(1) );
            responseDb = await getAppVideos(passval);
            dispatch( showLoader(0) );
          
            if ( parseInt(responseDb.success)===1 ){
                setVideoList(responseDb.result.beonchat_videos_list);
            }

        }

        setChildTabActive(1);

        if ( ! [2,5].includes(tabId) ){
            setParentTabActive(tabId);
        }
       
    }
    

    const switchToMasterAccountAndBizcomChat = async () => {

        dispatch( showLoader(0) );
        
         // ASSOCIATED ACCOUNTS
         let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
         if ( accountsList!==null ){
             accountsList = JSON.parse(accountsList);
 
             if ( Object.keys(accountsList).length>0 ){

                const accountListNormalArray = Utilities.toNormalArrayObject(accountsList);
                let personalAccount = accountListNormalArray.filter( account => parseInt(account.account_type) === 1)[0]  ?? [];
               
                if ( Object.keys(personalAccount).length>0 ){

                    dispatch( showLoader(1) );

                    // UPDATE LOGIN - PUT MASTER ACCOUNT
                    PagesAuthentication.setLoginAccountDetail(personalAccount);

                    // REDIRECT TO BIZCOM GENERAL CHAT
                    const responseInformation = await talkToUsConversation( parseInt(personalAccount.account_id) );
                    dispatch( showLoader(0) );

                    if ( Object.keys(responseInformation).length>0 ){
                        const profileChat = responseInformation.bizchat_conversation.conversation_list;
                        let passValues = ChatUtilities.encryptChatDetail(profileChat); 

                        ChatUtilities.saveAccountsConversationDetail(profileChat );
                        history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileChat.account_id) )}/${passValues}`);
                    }

                    
                }

             }
         }
    }


    const handleTalkToUsSubject = async( id = 0, label = '' ) => {

        if ( id===3 ){
            switchToMasterAccountAndBizcomChat();
            return false;
            
        }else if ( id===2 ){
            setIncludeInformationBool(false);
        }

        if ( id!==3 ) {
            setChildTabActive(id);
        }
        
        label = subjectListMail[id].toString().toLowerCase();
        setFormValues({...formValues, ...{ subject : label } });
    }

    const handleSubmitTalkToUs = async () => {
        
        const button_name = 'btn-send-report';
        Utilities.elementDisabledStatus(button_name, false );
        dispatch( showLoader(0) );

        const formData = new FormData();

        if ( ! Utilities.isEmpty(attachment) ){
            formData.append('file', attachment);
        }

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                formData.append('account_id', loginProfileInfo.account_id);
            }
        }

        let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
        if ( sessionLocation!==null ){
            sessionLocation = JSON.parse(sessionLocation);
        
            if ( Object.keys(sessionLocation).length>0 ){
                formData.append('lat_long', sessionLocation.latitude+','+sessionLocation.longitude );
            }
        }
        
        const browerAgentNane = Utilities.getBrowerAgent();
        formData.append('include_info', includeInformationBool ? 1 : 0);
        formData.append('subject', formValues.subject );
        formData.append('subject_id', childTabActive.toString() );
        formData.append('device', browerAgentNane);
        formData.append('browser',  window.navigator.userAgent );
        

        let errorCounter = 0;
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( fieldsChecker.includes(key) ){
                if ( Utilities.isEmpty(value )){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;

                }else{
                    if ( key==='email' && ! Utilities.isEmail(value) ){
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.invalidEmail );
                        errorCounter++;
                    }

                    formData.append(key, value);
                }
            }
        });

        if ( errorCounter>0 ){ return false; }
        
        dispatch( showLoader(1) );
        Utilities.elementDisabledStatus(button_name, true );
        const responseInformation = await sendAppReports( formData );

        Utilities.elementDisabledStatus(button_name, false );
        dispatch( showLoader(0) );
        
        if ( Object.entries(responseInformation).length===0 ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.cantSendEmail );
        }

        if ( parseInt(responseInformation.status)===1 ){
            Utilities.messagePopup('success', TitlesLabels.alertMessages.messageSuccessfullySent );
            setFormValues(defaultFormValues);
            setAttachments([]);
            setAttachment('');
            setIncludeInformationBool(false);
        }else{
            Utilities.messagePopup('error', TitlesLabels.alertMessages.cantSendEmail );
        }

    }

    const handleOnLoadIFrame = ( actionName = '' ) => {

        if ( actionName==='terms' ){
            setLoaderIframe({ ...loaderIframe, ...{ terms : false, licenses : true } });

        }else if ( actionName==='licenses' ){
            setLoaderIframe({ ...loaderIframe, ...{ terms : true, licenses : false } });
        }
        
    }


    const htmlTabContent =  () => {
        let returnHtml = '';

        if ( parentTabActive===1 ){

            returnHtml =<div>

                            <div className={`d-flex align-items-center d-flex justify-content-around fs-18 mb-4 pb-3 ${ Object.keys(subjectListSelection).length===3 ? '' : 'col-8' } mx-auto`}>
                                {
                                    Object.entries(subjectListSelection).map( ([ key, labelName ]) => 
                                        <div key={key} className={`cursor-pointer ${ parseInt(key)===childTabActive ? '' : 'text-gray' } `} onClick={ (e) => handleTalkToUsSubject( parseInt(key), labelName ) }>{labelName}</div>
                                    )
                                }
                            </div>

                            <div className="col-8 mx-auto form-theme pb-5">

                                <div className="form-group">
                                    <textarea name="message" className="form-control" placeholder=" " autoFocus autoComplete="off" rows="7" value={ formValues.message }  onChange={ (e) => pushValue( e, 'message') }></textarea>
                                    <label className="form-label">Type here</label>
                                </div>
                                
                                {
                                    childTabActive===2 &&
                                    <div className="text-size-semi-medium checkbox-radio-default checkbox-radio-check-icon d-flex">
                                        <input type="checkbox" name="terms" checked={ includeInformationBool }  onChange={ () => setIncludeInformationBool( includeInformationBool ? false : true) }  ></input>
                                        <span className="ml-2 fs-14">include user information</span>
                                    </div>
                                }
                                

                                <div className="btn-attachement-trigger cursor-pointer " onClick={ () => handleSelectFile() } >add attachment</div>
                                
                                <ul className="attachment-list-selected mt-3 text-lowercase">
                                    {
                                        attachments.map( (row, key) =>
                                            <li key={key}>
                                                <div className="filename">{row.name}</div><div className="btn-remove-attachment" onClick={ (e) => removeAttachment(e,key) }></div>
                                            </li>
                                        )
                                    }
                                </ul>

                                <input type="file" name="talkus-attachment" id="talkus_attachment" className="invisible" readOnly onChange={ (e) => previewAttachment(e) }  />

                                <div className="button-wrap text-lowercase text-center">
                                    <button type="button" id="btn-send-report" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => handleSubmitTalkToUs() }>Send</button>
                                </div>
                                
                            </div>
                    </div>

        }else if ( parentTabActive===3 ){
            returnHtml = <>
                            { loaderIframe.terms &&  <div className="content-loader animate"></div> }
                            <iframe height={`${loaderIframe.terms?'0':'1000'}`} width="100%" onLoad={ () => handleOnLoadIFrame('terms') } src={`${ UrlSlugs.page_privacyPolicy}#toolbar=0&view=Fill`}></iframe>
                            {/* <object>
                                <embed type="application/pdf"  height={`${loaderIframe.terms?'0':'1000'}`} width="100%" onLoad={ () => handleOnLoadIFrame('terms') } src={`${ UrlSlugs.page_privacyPolicy}#toolbar=0&view=Fill`} />
                            </object>  */}
                        </>

        }else if ( parentTabActive===4 ){
            returnHtml =  <>
                            { loaderIframe.licenses &&  <div className="content-loader animate"></div> }
                            <iframe height={`${loaderIframe.licenses?'0':'1000'}`} width="100%" onLoad={ () => handleOnLoadIFrame('licenses') }  src={`${ UrlSlugs.page_licensesClean}#toolbar=0&view=Fill`}></iframe>
                        </>

        }else if ( parentTabActive===7 ){
            let videoListHtml = '';

            returnHtml = <div className="text-center text-lowercase no-record-label">no video available</div>

            if ( videoList.length>0 ){
                videoListHtml = <div className="mb-4"><ViewOutputHtml title={ videoList[0].title } code={ videoList[0].link.split('/').pop() } width="100%" height="420" /></div>

                returnHtml = <div className="mb-5 text-lowercase">
                                { videoListHtml }
                                { 
                                    videoList.length>1 &&
                                    <div className="form-col-2">
                                        { videoList.map( video => video.id!==videoList[0].id && <ViewOutputHtml key={video.id} title={ video.title } code={ video.link.split('/').pop() } /> ) }
                                    </div>
                                }
                            </div>
            }

           
        }

        

        return returnHtml;
    }

    const triggerSetting = () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if ( sesstionLoginType !== null ){
            sesstionLoginType = parseInt(sesstionLoginType);

            if ( sesstionLoginType===2 ){
                dispatch( showDepartmentSettingsReducer( new Date() ) );
            }else{
                dispatch( showMainSettingsReducer( new Date() ) );
            }
            
        }else{
            dispatch( showMainSettingsReducer( new Date() ) );
        }

    }

    const marketingListingTemplate = ( key = '' , detail = '' ) => {
        
        let returnHtml = <></>
        if ( ! Utilities.isEmpty(detail) ){
            
            let fileExt = detail.split('.').slice(-1)[0];

            if ( key==='sort_link_url' ){
                returnHtml  = <div className="text-center font-gotham-book fs-18">{ UrlSlugs.page_beonchat }<input id="sort_link_url" type="text" className="d-none" value={ UrlSlugs.page_beonchat } /></div>

            }else if ( key==='image_link' ){
                returnHtml = <img src={`${IMG_PATH}/logo/beonchat-and-initial-logo.svg`} width={120}/>

            }else{
                if ( Utilities.validate_image_ext(fileExt) ){
                    returnHtml = <img src={detail} className="w-100"/>
        
                }else if ( Utilities.validate_document_ext(fileExt) ){
                    returnHtml = <img src={`${IMG_PATH}/icons/gray/outline-file-doc.svg`} width={120}/>

                }else{
                    returnHtml = <img src={`${IMG_PATH}/icons/gray/outline-file-default.svg`} width={120}/>
                }

            }
        

            returnHtml = <div className={`${ key==='sort_link_url' ? 'link-tool' : '' }`}>
                            {returnHtml}
                            <div className={`fs-12 ${ key!=='sort_link_url' ? 'pt-3' : '' }`}>
                                {
                                    key==='sort_link_url' ?
                                        <div className="btn-tool-copy d-flex align-items-center justify-content-center cursor-pointer" onClick={ () => Utilities.copyContent('sort_link_url') }>copy link</div>
                                    :
                                        <a href={ Utilities.isEmpty(detail) ? '#' : detail } onClick={ () => unableToDownloadMsg(detail) } className="btn-tool-download d-block">download</a>
                                }
                            </div>
                        </div>
        }
                    
        return returnHtml;
    }


    const unableToDownloadMsg = ( detail= '') => {
        if ( Utilities.isEmpty(detail) ){
            Utilities.messagePopup('',TitlesLabels.general.unableToDownload );
        }
    }

    useEffect( () => {
        
        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.importantInformation }`) );

        let headerType = 1;
        let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        setHeaderTypeCurrent(loginType===null ? 0 : 1);

        if ( loginType!==null ){
            loginType = JSON.parse(loginType);
            loginType = parseInt(loginType);
            headerType = loginType===2 ? 5 : 1;
        }
        
        if ( headerType===1 && loginType!==null){
            setSubjectListSelection({...subjectListSelection, ...{ 3 : 'talk to team' } });
        }

        dispatch( showHeader(headerType) );
        dispatch( showFooter(1) );

        let segment = Utilities.getUrlSegments();
        let lastUrlSlug = segment.pop();
        lastUrlSlug = lastUrlSlug.toLowerCase();

        if ( lastUrlSlug==='terms-privacy'){
            handleTabChange(3);
        }else if ( lastUrlSlug==='licenses'){
            handleTabChange(4);
        }

    }, [] );

    return (
        <div className="important-information-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div className="current">{siteTitle} assist</div>
                           
                          </li>
                         <li className="btn-option">
                             <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                             {/* <div className="btn-icon btn-video" onClick={ () => dispatch( showPopupVideoReducer( Utilities.homeVideoUrl ) ) }  title="video"></div> */}
                            { headerTypeCurrent===1 && <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div> }
                         </li>
                     </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container">
                    
                       <ul className="list-tab--assist px-4 text-lowercase">
                           <li className={`tab-assist-icon talk-icon ${ parentTabActive===1 ? 'active' : '' }`} onClick={ () => handleTabChange(1) }>talk to us</li>
                           <li className={`tab-assist-icon videos-icon ${ parentTabActive===7 ? 'active' : '' }`} onClick={ () => handleTabChange(7) }>{siteTitle} videos</li>
                           <li className={`tab-assist-icon horn-icon ${ parentTabActive===6 ? 'active' : '' }`} onClick={ () => handleTabChange(6) }>marketing tools</li>
                           <li className={`tab-assist-icon question-icon ${ parentTabActive===2 ? 'active' : '' }`} onClick={ () => handleTabChange(2) }>help center</li>
                           <li className={`tab-assist-icon terms-icon ${ parentTabActive===3 ? 'active' : '' }`} onClick={ () => handleTabChange(3) }>terms and privacy policy</li>
                           <li className={`tab-assist-icon ribbon-icon ${ parentTabActive===4 ? 'active' : '' }`} onClick={ () => handleTabChange(4) }>licenses</li>
                           {/* <li className={`tab-assist-icon globe-icon ${ parentTabActive===5 ? 'active' : '' }`} onClick={ () => handleTabChange(5) }>{siteTitle}.com</li> */}
                       </ul>

                        <div className={`col-md-${[3,4,6,7].includes(parentTabActive) ? '10' : '8' } mx-auto mt-5 list-tab-content`}>
                            { htmlTabContent() }
                            {
                                parentTabActive===6 &&
                                <>
                                {
                                    Object.entries(marketingList).length===0 ?
                                        <div className="text-center text-lowercase no-record-label">{ TitlesLabels.general.noRecordAvailable }</div>
                                    :
                                        <ul className="ul-marketing-tools ul-grid-3 pt-2">
                                            {
                                                Object.entries(marketingList).map( ([ key, row ]) =>
                                                    <li key={key} className={`${ key==='sort_link_url' ? 'pt-3': '' }`}>{marketingListingTemplate(key, row)}</li>
                                                )
                                            }
                                        </ul>
                                }
                                </>

                            }
                        </div>

                </div>
            </div>
       </div>
    )
}

export default BizcomAssist;


const ViewOutputHtml = ( props ) => {
    return <div key={props.id??0}>
            <iframe width={`${props.width??'100%'}`} height={`${props.height??'260'}`} src={`https://www.youtube.com/embed/${props.code}?autoplay=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderRadius : 10, backgroundColor : "#000000" }}></iframe>
            <div className="font-gotham-book fs-14 mt-1 ellipsis-1">{ props.title }</div>
    </div>
}