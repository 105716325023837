import React, {  useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';

import { passChatTeamProfileRestrictionDetail as passChatTeamProfileRestrictionDetailReducer } from '../../actions';
import ChatUtilities from '../../helpers/ChatUtilities';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';

import { showMainSearch } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';


const ChatTeamRestriction = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const passChatTeamProfileRestrictionDetail = useSelector( state => state.passChatTeamProfileRestrictionDetail);

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN_CHAT = process.env.REACT_APP_chat_api_token;
    const limitCharacters = Utilities.teamRestrictionReasonLimit;

    const [ showPopup, setShowPopup ] = useState(false);
    const [ initialMessage, setInitialMessage ] = useState('');
    const [ teamProfileDetail, setTeamProfileDetail ] = useState({});
    const [ profileLoggedin, setProfileLoggedin ] = useState({});

    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( passChatTeamProfileRestrictionDetailReducer('') );
    }

    const handleSubmit = async () => {
        
        const charCounter = Utilities.charactersCounter(initialMessage);
        if ( charCounter>limitCharacters ){
            Utilities.messagePopup('error', `Only ${limitCharacters} characters are allowed!`);
            return false;
        }

        const button_name = 'btn-confirm-detail';
        Utilities.elementDisabledStatus(button_name, true );

        const passValuesRes = {
            api_token : TOKEN_CHAT.toString(),
            account_id : profileLoggedin.account_id.toString(),
            account_type : profileLoggedin.account_type.toString(),
            account_department_id : profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0',
            company_id : teamProfileDetail.hasOwnProperty('company_detail') ? teamProfileDetail.company_detail.account_id.toString() : '0',
            receiver_account_id : teamProfileDetail.account_id.toString(),
            connect_reason : initialMessage.toString()
        };

        const responseContactTeam = await QueryMasterSearchUtlities.requestAccessTeamCommunication(passValuesRes);
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.entries(responseContactTeam).length===0 ){ 
            Utilities.messagePopup('error', errorMsg );
            return false; 
        }

        if ( parseInt(responseContactTeam.success)===0 ){ 
            Utilities.messagePopup('error', responseContactTeam.errorMessage );
            return false; 
        }

        const conversation = responseContactTeam.result.bizchat_conversation.conversation_list;
        let chatPassValue = ChatUtilities.encryptChatDetail(conversation); 

        ChatUtilities.saveAccountsConversationDetail(conversation );
        history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(conversation.account_id) )}/${chatPassValue}`);

        dispatch( showMainSearch(0) );
        handleClosePopup();
    }

    useEffect(() => {

        if ( typeof passChatTeamProfileRestrictionDetail!=='undefined' &&  Object.entries(passChatTeamProfileRestrictionDetail).length>0  ){
            setTeamProfileDetail( passChatTeamProfileRestrictionDetail );
            setShowPopup(true);
        }

        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);

            }
        }

        return () => {
            setInitialMessage('');
            //setShowPopup(false);
        };
        
    }, [passChatTeamProfileRestrictionDetail]);


    return (

       <Modal id="popup-chat-team-profile-restriction"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme" backdrop="static" keyboard={ false }>
            <Modal.Header closeButton className="border-bottom-0 zindex-1"></Modal.Header>
            <Modal.Body>

                <div className="profile-selected-assoc text-lowercase mb-4 form-theme">
                        
                    <div className="col-10 mx-auto">
                        <div className="pb-3 text-center mx-auto col-8 line-height-1-2">
                            <div className="logo-size-75 profile-team-logo mx-auto profile-restricted fs-20-important "  style={{ backgroundImage:  `url(${Utilities.showHideProfileImage(teamProfileDetail.logo_url)})`, backgroundColor : Utilities.bgcolorHideInitialBg(teamProfileDetail.logo_url)  }}>{ Utilities.showHideInitial(teamProfileDetail.logo_url, teamProfileDetail.name) }</div>

                            <div className="font-gotham-book-16 notranslate mt-2">{teamProfileDetail.name}</div>
                            { ( teamProfileDetail.hasOwnProperty('category')  && teamProfileDetail.category!=='' ) && <div className="profile-label">{teamProfileDetail.category}</div> }
                        </div>

                        <div className="py-2 font-gotham-medium-14 text-center">
                            access to this chat is restricted.<br />please submit a request to communicate
                        </div>

                        <div className="bg-gray-theme p-4 mt-3">

                            <div className="text-center font-gotham-medium-16 mb-4">communication request</div>

                            <div className="form-group active border-0">
                                <textarea  name="initial_message" className="form-control" autoFocus rows="7" placeholder="type here" value={initialMessage} onChange={ (e) => setInitialMessage(e.target.value) }></textarea>
                                <label className="form-label">reason (if any)</label>
                            </div>

                            <div className="mt-3 text-center text-gray">{ Utilities.number_format(limitCharacters.toString()) } characters max</div>

                            <div className="pt-3 col-md-8 mx-auto">
                                 <button id="btn-confirm-detail" type="button" className="btn-theme-black button-has-loader" onClick={ () => handleSubmit() }>submit</button>
                            </div>

                        </div>
                        
                    </div>

                   

                </div>
                
            </Modal.Body>
        </Modal>
    )
}

export default ChatTeamRestriction;
