import { hot } from "react-hot-loader/root";
import React from 'react';
import ReactDOM from 'react-dom';

import { createStore } from 'redux';
import allReducers from './reducers';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore( allReducers );

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      //console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      //console.log("Service worker registration failed, error:", err);
    });
}


const render = (Component) =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Component />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

render(hot(App));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();