import React, { useEffect, useState } from 'react';
//import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';

const PasswordInput = ( props ) => {

    const [ showPassword, setShowPassword] = useState(false);
    const [ showChecker, setShowChecker] = useState(false);
    const [ showOption , setShowOption ] = useState(false);

    const [ passwordValidationInfo, setPasswordValidationInfo] = useState({});

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        
        const passwordValidation = Utilities.passwordValidation(value, true);
        setPasswordValidationInfo( passwordValidation );

        props.pushValue( e, fieldName ?? '');
    }


    const handleOnBlurInput = ( e, fieldName ) =>{
        if ( showChecker===true ){
            fieldName = fieldName.toLowerCase();
            let value = e.target.value;
    
            setPasswordValidationInfo({});

            if ( ! Utilities.isEmpty(value) ){
                const passwordValidation = Utilities.passwordValidation(value, true);
                if ( passwordValidation.response===false ){
                    //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(fieldName);
                    setPasswordValidationInfo({ ...passwordValidation, html : passwordValidation.generalHtml });
                }
            }else{
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
                Utilities.removeFormGroupClass(fieldName);
            }
        }
    }

    useEffect( () => {
        setShowChecker( props.showChecker ?? false );
        setShowOption(props.showOption ?? false);
    }, [props] );


    return (
        <>
            <div className={`form-group optional pr-0 ${ showOption===true ? 'optional' : '' }`}>
                <input type={`${showPassword?'text':'password'}`} name={props.inputName} className={`form-control ${ props.inputClass ?? ''}`} placeholder=" " value={ props.inputValue }  onChange={ (e) => pushValue( e, props.inputName) } autoComplete="new-password"  onBlur={ (e) => handleOnBlurInput( e, props.inputName) } ></input>
                <label className={`form-label ${props.inputLabelClass ?? ''} `}>{ props.inputLabel }</label>
                { showOption===true && <div className={`optional-label ${ ! Utilities.isEmpty(props.inputValue) ? 'pr-4' : ''}`}>Optional</div> }

                { 
                    ! Utilities.isEmpty(props.inputValue) &&
                        <div className="optional-label  d-flex">
                            <span className={`input-label-icons cursor-pointer ${showPassword?'eye-cross-icon':'eye-icon'}`} onClick={ () => setShowPassword( showPassword?false:true ) }></span>
                        </div>
                }

            </div>

            { ( showChecker===true && ! Utilities.isEmpty(props.inputValue) ) && <div dangerouslySetInnerHTML={{ __html: passwordValidationInfo.html }} ></div>  }
    </>
    )
}

export default PasswordInput;
