import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';
import ApiUrls from '../helpers/ApiUrls';

import { showNoteIndicator } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';


const ChatSpecialNote = () => {

    const dispatch = useDispatch();
    
    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const defaultValue = {  note : '' };
    let gobalConversID = 0;
    let globalLoginDetail = {};

    const passConversID = useSelector( state => state.passConversID);
    const passInternalProfile = useSelector( state => state.passInternalProfile);

    const [ formValues, setFormValues] = useState(defaultValue);
    const [ formValuesOld, setFormValuesOld ] = useState(defaultValue);
    const [ hasNote, setHasNote ] = useState(false);
    const [ loginDetail, setLoginDetail ] = useState({});
    const [ conversID, setConversID ] = useState(0);
    const [ updateOption, setUpdateOption ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if ( fieldName==='note'){
            formValuesTemp = { ...formValuesTemp, ...{ note : value } };
        }
        setFormValues(formValuesTemp);
    }

    const noteAvailableStatus = ( action ) => {
        setHasNote(action);

        let bolNumber = (action===false) ? 0 : 1;
        dispatch( showNoteIndicator(bolNumber) );

        if ( parseInt(gobalConversID)>0 ){
            let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( gobalConversID );
            const sp_notes_status = (action===false) ? 0 : 1;

            currentConversationDetailTemp = { ...currentConversationDetailTemp, ...{ sp_notes_status : sp_notes_status.toString() } };
            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);
        }

        if ( action===false ){
            setFormValues(defaultValue);
        }
    }
    
    const getNote = () => {

        const passValue = {
            api_token : TOKEN.toString(),
            account_id : globalLoginDetail.account_id.toString(),
            convers_id : gobalConversID.toString()
        }

        axios
        .post( ApiUrls.bizchatGetConversationNotes , passValue  )
        .then( response => {
           
            setIsLoading(false);

            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){

                    const responseResult = response.data.result ?? {};
     
                    if ( Object.keys(responseResult).length>0 ){

                        const valNote = { note : responseResult.conversation_special_notes.note };
                        setFormValues(valNote);
                        setFormValuesOld(valNote);

                        noteAvailableStatus(true);
                    }else{
                        noteAvailableStatus(false);
                    }
              
                }else{
                    noteAvailableStatus(false);
                }
                
            }else{
                noteAvailableStatus(false);
            }

        })
        .catch( (err) => {
            noteAvailableStatus(false);
            Utilities.messagePopup('error', errorMsg );
        }); 
    }


    const addUpdateNote = ( action ) => {

        if ( Utilities.isEmpty(formValues.note.toString()) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.noteShouldNotEmpty );
            return false;
        }

        let btn_name = ( parseInt(action)===1 ) ? 'submit' : 'update';
        if ( parseInt(action)===3){
            btn_name = 'delete';
        }

        Utilities.elementDisabledStatus('btn-'+btn_name+'-note', true );

        //flag = 1 : add, 2 : update, 3 :delete
        const passValue = {
            api_token : TOKEN.toString(),
            account_id : loginDetail.account_id.toString(),
            convers_id : conversID.toString(),
            flag : action.toString(),
            note : formValues.note.toString()
        }

        axios
        .post( ApiUrls.bizchatUpdateConversationNotes , passValue  )
        .then( response => {

            Utilities.elementDisabledStatus('btn-'+btn_name+'-note', false );

            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){

                    let message = 'saved';
                    if ( parseInt(action)===3 ){

                        noteAvailableStatus(false);
                        setUpdateOption(false);
                        message = 'deleted';

                    }else{
                        
                        setHasNote(true);
                        dispatch( showNoteIndicator(true) );

                        setUpdateOption(false);
                        
                        setFormValuesOld({ note : formValues.note });
                    }

                    Utilities.messagePopup('success', `Note has been ${message}!` );
                    
                }else{
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotSaveNote );
                }

            }else{
                Utilities.messagePopup('error', errorMsg );
            }

        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg );
            Utilities.elementDisabledStatus('btn-'+btn_name+'-note', false );
           
        }); 
        
    }

    const setOldValues = () => {
        setFormValues({ note : formValuesOld.note });
        setUpdateOption(false);
    }

    
    useEffect( () => {

        gobalConversID = parseInt(passConversID);
        setConversID(gobalConversID);

        if ( Object.keys(passInternalProfile).length>0 ){
            globalLoginDetail = passInternalProfile;
            setLoginDetail(passInternalProfile);
            
        }else{
            let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginBasicInfo!==null ){
                loginBasicInfo = JSON.parse(loginBasicInfo);
                globalLoginDetail = loginBasicInfo;
                setLoginDetail(loginBasicInfo);
            }
        }

        if ( gobalConversID>0 ){
            getNote();
        }

    }, [passConversID] );


    return (
        (
            isLoading ?
                <div className="content-loader animate"></div> 
            :
                <div className="special-note-content text-lowercase">
                        <textarea className="note-text-wrap" placeholder="type here" disabled={ hasNote && updateOption===true ? '' : hasNote===false && updateOption===false ? '' : 'disabled' } value={formValues.note} onChange={ (e) => pushValue(e, 'note') } ></textarea>

                        <div className={`button-group-boolean  mt-4 ${ updateOption ? 'd-none' : '' }` }>
                            <button type="button"  id="btn-delete-note" className="btn-theme-black" onClick={ () => addUpdateNote(3) }>delete</button>
                            {
                                hasNote ?
                                    <button type="button" className=" btn-theme-black text-lowercase" onClick={ () => setUpdateOption(true) }>edit</button>
                                :
                                    <button type="button" id="btn-submit-note"  className=" btn-theme-black button-has-loader" onClick={ () => addUpdateNote(1) }>add</button>
                            }
                        </div>


                        {
                            updateOption &&
                            <div className="button-group-boolean mt-4">
                                <button type="button" className=" btn-theme-black"  onClick={ () => setOldValues() } >cancel</button>
                                <button type="button" id="btn-update-note"  className=" btn-theme-black button-has-loader" onClick={ () => addUpdateNote(2) }>save</button>
                            </div>
                        }

                </div>
        )
       
    ) 
}

export default ChatSpecialNote;
