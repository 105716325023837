import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useHistory  } from 'react-router-dom';
import jQuery from 'jquery';
import * as RM from 'ramda';
import { confirmAlert } from 'react-confirm-alert';
import { Dropdown } from 'react-bootstrap';

import ChatUtilities from '../helpers/ChatUtilities';
import QueryChatGroupUtilities from '../helpers/QueryChatGroupUtilities';
import Utilities from '../helpers/Utilities';
import QueryUtilities from '../helpers/QueryUtilities';
import SessionUtilities from '../helpers/SessionUtilities';
import UrlSlugs from '../helpers/UrlSlugs';

import { showLoader, chatSidebarBlock, passImageUrl, passConversDetail as passConversDetailReducer } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';


const ChatGroup = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const passConversID = useSelector( state => state.passConversID);

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ profileDetail, setProfileDetail ] = useState({});
    const [ showLoading, setShowLoading ] = useState(false);
    const [ conversationDetail , setConversationDetail] = useState({});
    const [ groupDetail , setGroupDetail] = useState({});
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const deleteGroup = async () => {
        
        if ( Object.entries(conversationDetail).length>0 && conversationDetail.hasOwnProperty('id') ){

            const segment = Utilities.getUrlSegments();
            const teamId = atob(segment[1]);
            
            const button_name = 'btn-delete-groupchat-detail';
            Utilities.elementDisabledStatus(button_name, true );

            const requestValues = {
                api_token : TOKEN.toString(),
                account_id : teamId.toString(),
                group_id : conversationDetail.id.toString()
            }

            const responseDeleteGroup = await QueryChatGroupUtilities.deleteDetailGroup(requestValues);

            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseDeleteGroup).length===0 ){ 
                return false; 
            }

            if ( parseInt(responseDeleteGroup.success)===0 ){ 
                Utilities.messagePopup('error', responseDeleteGroup.errorMessage );
                return false; 
            }
            
            history.push(`/${UrlSlugs.internalChat}/${segment[1]}`);
        }
    }

    const deleteGroupConfirmation = () => {

        confirmAlert ({
            title: '',
            message: TitlesLabels.confirmMessages.wantToDeleteGroup ,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                {
                    label: 'Yes',
                    onClick: () => {  deleteGroup();  }
                }
            ]
        }); 

    }

    const getMembers = async ( conversDetail = {} ) => {
        
        if ( Object.entries(conversDetail).length>0 ){
            if ( conversDetail.hasOwnProperty('flag') && parseInt(conversDetail.flag)===2 ){

                const segment = Utilities.getUrlSegments();
                const teamId = atob(segment[1]);

                setShowLoading(true);

                const responseGetMembers = await QueryChatGroupUtilities.getDetailGroup({
                    api_token : TOKEN.toString(),
                    account_id : teamId.toString(),
                    group_id : conversDetail.id.toString()
                });

                setShowLoading(false);

                if ( Object.entries(responseGetMembers).length===0 ){ 
                    return false; 
                }

                if ( parseInt(responseGetMembers.success)===0 ){ 
                    Utilities.messagePopup('error', responseGetMembers.errorMessage );
                    return false; 
                }

                let groupDetailTemp = responseGetMembers.result.bizchat_group_details ?? {};

                if ( Object.entries(responseGetMembers.result.bizchat_group_details.members_list ?? {}).length>0 ){
                        
                    let allList = [];
                    let memberList = responseGetMembers.result.bizchat_group_details.members_list;
                    let ownProfile = memberList.filter( member => parseInt(member.account_id) === parseInt(teamId))[0]  ?? [];
                    let adminList = memberList.filter( member => ( parseInt(member.admin_status || 0) === 1 && parseInt(member.account_id) !== parseInt(teamId) )) ?? [];
                    let otherList = memberList.filter( member => ( parseInt(member.admin_status || 0) === 0 && parseInt(member.account_id) !== parseInt(teamId) )) ?? [];
                    
                    if ( Object.entries(ownProfile).length>0 ){
                        allList = [ownProfile, ...allList];
                    }

                    if ( adminList.length>0 ){
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                        adminList = RM.sort(byName, adminList);
                        allList = [...allList, ...adminList];
                    }

                    if ( otherList.length>0 ){
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                        otherList = RM.sort(byName, otherList);
                        allList = [...allList, ...otherList];
                    }

                    groupDetailTemp = {...groupDetailTemp, members_list : allList };
                }
                
                setGroupDetail(groupDetailTemp);
                
            }
        }
    }

    const directMessage = async ( profile = {} ) => {

        if ( Object.entries(profile).length>0 ){
            profile = { ...profile, account_type : '4', team_company_id : conversationDetail.convers_company_id.toString() };
            
            const contactRequestValues = ChatUtilities.contactRequest( profileDetail, profile, 0, 1 );
            const conversationDetailRes = await QueryUtilities.getConversationDetail( contactRequestValues.url, contactRequestValues.values  );
            
            const conversation = conversationDetailRes.result.bizchat_conversation.conversation_list;
            ChatUtilities.saveAccountsConversationDetail(conversation, 'group direct message' );

            const passValues = ChatUtilities.encryptChatDetail( conversation, 1, { account_id : profile.account_id } ); 
            window.location.href = `/${ UrlSlugs.chatConversation}/${btoa( parseInt(profileDetail.account_id) )}/${passValues}`;
        }
    }

    const menuOptionHtml = ( accountDetail = {} ) => {
        let returnHtml = '';

        if ( Object.entries(accountDetail).length>0 &&  parseInt(profileDetail.account_id)!==parseInt(accountDetail.account_id) ){
            
            returnHtml = <Dropdown className="btn-message-option">
                            <Dropdown.Toggle variant="dropdown-icon btn-message-option-icon"></Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-chat-option message-option-list">
                                {
                                    parseInt(groupDetail.master_admin_status || 0)===1 && 
                                    <Dropdown.Item onClick={ () => markAsAdmin(accountDetail) }>{ parseInt(accountDetail.admin_status || 0)===0 ? 'make' : 'remove' } as admin</Dropdown.Item>
                                }
                                <Dropdown.Item className="ellipsis-1" onClick={ () => directMessage(accountDetail) }>message {accountDetail.name}</Dropdown.Item>
                                {
                                    parseInt(groupDetail.master_admin_status || 0)===1 && 
                                    <Dropdown.Item onClick={ () => leaveMemberGroupConfirm(accountDetail) }>remove from group</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
        }

        return returnHtml;

    }

    const markAsAdmin = async ( accountDetail = {}) => {

        if ( Object.entries(accountDetail).length>0 ){
            
            const segment = Utilities.getUrlSegments();
            const teamId = atob(segment[1]);

            setShowLoading(true);

            const flag = parseInt(accountDetail.admin_status || 0)===0 ? 1 : 0;

            const responseMarkAdmin = await QueryChatGroupUtilities.markAdminGroup({
                api_token : TOKEN.toString(),
                account_id : teamId.toString(),
                group_id : conversationDetail.id.toString(),
                member_id : accountDetail.account_id.toString(),
                flag : flag.toString()
            });

            setShowLoading(false);

            if ( Object.entries(responseMarkAdmin).length===0 ){ 
                return false; 
            }

            if ( parseInt(responseMarkAdmin.success)===0 ){ 
                Utilities.messagePopup('error', responseMarkAdmin.errorMessage );
                return false; 
            }


            getMembers( conversationDetail ) ;
        }
    }

    const leaveMemberGroup = async ( accountDetail = {} ) => {

        if ( Object.entries(accountDetail).length>0 ){
            dispatch( showLoader(1) );
            
            const responseGroupLeave = await QueryChatGroupUtilities.leaveGroup({
                api_token : TOKEN.toString(),
                account_id : accountDetail.account_id.toString(),
                group_id : conversationDetail.id.toString()
            });

            dispatch( showLoader(0) );

            if ( Object.entries(responseGroupLeave).length===0 ){  return false;  }
            if ( parseInt(responseGroupLeave.success)===0 ){ 
                Utilities.messagePopup('error', responseGroupLeave.errorMessage );
                return false; 
            }

            Utilities.messagePopup('success', responseGroupLeave.successMessage );
            getMembers( conversationDetail ) ;

        }
    }

    const leaveMemberGroupConfirm = ( accountDetail = {} ) => {

        if ( Object.entries(accountDetail).length>0 ){
            confirmAlert ({
                title: '',
                message: `Are you sure you want remove '${accountDetail.name}' from the group?`,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'No',  onClick: () => {} },
                    {
                        label: 'Yes',
                        onClick: () => {
                            leaveMemberGroup(accountDetail);
    
                        }
                    }
                ]
            }); 
        }
    }

    const previewProfileImage = (logoUrl ) => {
        if ( ! Utilities.isEmpty(logoUrl) ){
             dispatch( passImageUrl(logoUrl) );
        }
    }


    const leavingGroup = async ( teamAccountId = 0 ) => {
        teamAccountId = parseInt(teamAccountId ?? 0);

        if ( teamAccountId>0 && conversationDetail.hasOwnProperty('id') ){

            const button_name = 'btn-leave-groupchat';
            Utilities.elementDisabledStatus(button_name, true );

            const responseGroupLeave = await QueryChatGroupUtilities.leaveGroup({
                api_token : TOKEN.toString(),
                account_id : teamAccountId.toString(),
                group_id : conversationDetail.id.toString()
            });

            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseGroupLeave).length===0 ){  return false;  }
            if ( parseInt(responseGroupLeave.success)===0 ){ 
                Utilities.messagePopup('error', responseGroupLeave.errorMessage );
                return false; 
            }
          
            let currentConversationDetailTemp = { ...conversationDetail, access_status : '0'  };
            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 'group leaving group');

            setConversationDetail(currentConversationDetailTemp);

            getMembers( conversationDetail ) ;

            dispatch( passConversDetailReducer(currentConversationDetailTemp) );
        }
    }

    const confirmMessageLeaveGroup = () => {

        const segment = Utilities.getUrlSegments();
        const teamId = atob(segment[1]);

        confirmAlert ({
            title: '',
            message: TitlesLabels.confirmMessages.wantToLeaveGroup ,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                {
                    label: 'Yes',
                    onClick: () => {
                        leavingGroup(teamId);

                    }
                }
            ]
        }); 

    }

    const deleteConversationSubmit = async ( teamAccountId = 0 ) => {
        teamAccountId = parseInt(teamAccountId ?? 0);

        if ( teamAccountId>0 && conversationDetail.hasOwnProperty('id') ){

            const button_name = 'btn-delete-conversation-groupchat';
            Utilities.elementDisabledStatus(button_name, true );
            
            const responseGroupLeave = await QueryChatGroupUtilities.bizchatDeleteGroupConversation({
                api_token : TOKEN.toString(),
                account_id : teamAccountId.toString(),
                group_id : conversationDetail.id.toString()
            });

            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseGroupLeave).length===0 ){  return false;  }
            if ( parseInt(responseGroupLeave.success)===0 ){ 
                Utilities.messagePopup('error', responseGroupLeave.errorMessage );
                return false; 
            }

            Utilities.messagePopup('success', responseGroupLeave.successMessage );
            history.push(`/${UrlSlugs.internalChat}/${btoa(teamAccountId)}`);
        }
    }

    const confirmMessageDeleteConversation = () => {
        
        const segment = Utilities.getUrlSegments();
        const teamId = atob(segment[1]);

        confirmAlert ({
            title: '',
            message: TitlesLabels.confirmMessages.wantDeleteConversation ,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteConversationSubmit(teamId);

                    }
                }
            ]
        }); 

    }

    useEffect(() => {
        if ( parseInt(passConversID)>0 ){
            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( passConversID );
            setConversationDetail( currentConversationDetailTemp );

            getMembers( currentConversationDetailTemp ) ;

            const segment = Utilities.getUrlSegments();
            const teamId = atob(segment[1]);

            let profileDetailTemp = SessionUtilities.getProfileDetail(teamId);
            setProfileDetail(profileDetailTemp);

        }

    }, [passConversID]);

    return (

        <div className="chatgroup-popup-content broadcast-popup-content  form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
    
            { 
                showLoading===true ?
                    <div className="content-loader animate"></div>

                :
                    typeof groupDetail.members_list!=='undefined' ?
                        <>
                            <div className="mb-5 pt-4 group-logo-detail">
                                <div className="logo-size-70" style={{ backgroundImage:  `url(${ typeof groupDetail.logo.split('/').pop()!=='undefined' ? groupDetail.logo : DEFAUL_AVATAR })`  }} ></div>
                                <div>
                                    <div className="font-gotham-medium-16 ellipsis-2">{groupDetail.name}</div>
                                    <div className="font-gotham-book-14 ellipsis-1">{groupDetail.members_count} member{ parseInt(groupDetail.members_count)>1 ? 's': ''}</div>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                                <label className="form-label">Search</label>
                            </div>
                            
                            <ul id="listing-profiles-member" className="list-members mt-2">
                                {
                                    Object.entries(groupDetail.members_list).map( ([key, row] ) =>
                                        parseInt(row.active_status)===1 &&
                                        <li key={key} data-keyword={row.name.toLowerCase() } >
                                            <div><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                            <div>
                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                <div className="profile-label">{row.title}</div>
                                            </div>

                                            <div>
                                                {
                                                    parseInt(row.owner_status ?? 0)===1 ? 
                                                        <span>creator</span>
                                                    :
                                                        parseInt(row.admin_status ?? 0)===1 && <span>admin</span> 
                                                }
                                            </div>

                                            <div>{ menuOptionHtml(row) }</div>
                                            
                                        </li>
                                    )
                                }
                            </ul>

                            {
                                parseInt(groupDetail.master_admin_status || 0)===1 ?
                                    <div className="py-4 button-group-boolean">
                                        <button type="button" id="btn-delete-groupchat-detail" className="btn-theme-black button-has-loader" onClick={ () => deleteGroupConfirmation() }>delete</button>
                                        <button type="button" className="btn-theme-black" onClick={ () => dispatch( chatSidebarBlock('groupdetailupdate') ) }>edit</button>
                                    </div>
                                :
                                <>
                                    {
                                        ( conversationDetail.hasOwnProperty('group_admin_status') && parseInt(conversationDetail.group_admin_status)!==1 && parseInt(conversationDetail.access_status || 0)===1 ) &&
                                            <div className="py-4">
                                                <button type="button" id="btn-leave-groupchat" className="btn-theme-black button-has-loader" onClick={ () => confirmMessageLeaveGroup() }>leave group</button>
                                            </div>
                                    }
                                    {
                                        ( conversationDetail.hasOwnProperty('group_admin_status') && parseInt(conversationDetail.group_admin_status)!==1 && parseInt(conversationDetail.access_status || 0)===0 ) &&
                                            <div className="py-4">
                                                <button type="button" id="btn-delete-conversation-groupchat" className="btn-theme-black button-has-loader" onClick={ () => confirmMessageDeleteConversation() }>delete conversation</button>
                                            </div>
                                    }
                                </>
                            }
                        </>
                    :
                        <div className="text-center text-lowercase no-record-label mt-3">{ Utilities.labelDetailAvailable }</div>
            }
            
        </div>
    )
}

export default ChatGroup;