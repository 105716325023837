import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';
import ChatUtilities from '../../helpers/ChatUtilities';

import { passQrcodeDetail as passQrcodeDetailDispatch } from '../../actions';

const Qrcode = () => {

    const dispatch = useDispatch();
    const passQrcodeDetail = useSelector( state => state.passQrcodeDetail);
    
    const [ showPopup, setShowPopup ] = useState(false);
    const [ qrcodeHtml , setQrcodeHtml ] = useState('');
    const [ qrcodeImage, setQrcodeImage ] = useState('');

    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( passQrcodeDetailDispatch('') );
    }

    useEffect( () => {

        if ( ! Utilities.isEmpty(passQrcodeDetail) ){

            const imageUrl = passQrcodeDetail.message.toString().split('|')[4];
            setQrcodeImage(imageUrl);
            setQrcodeHtml( ChatUtilities.qrcodeMessageHtml(passQrcodeDetail.message.toString()) );
            setShowPopup(true);
        }
        
    }, [passQrcodeDetail] );

    return (

       <Modal id="popup-qrcode"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme"  keyboard={ true }>
            <Modal.Body className="py-5">

                <div className="qrcode-popup-content col-9 mx-auto">

                    <div dangerouslySetInnerHTML={{ __html:  qrcodeHtml }}></div>

                    <div className="mt-4">
                        <a href={qrcodeImage} target="_blank" download="qrcode.jpg" className="btn-theme-black text-lowercase">Download</a>
                    </div>

                </div>
                
            </Modal.Body>
        </Modal>
    )
}

export default Qrcode;
