import React, { useEffect, useState } from 'react';
import ChatUtilities from '../helpers/ChatUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { conversationTeamMembersList } from '../helpers/QueryChatGroups';
import Utilities from '../helpers/Utilities';
import jQuery from 'jquery';
import UrlSlugs from '../helpers/UrlSlugs';
import ConnectTeamMember from './popups/ConnectTeamMember';


const ChatSettingsCompanyGroupsAuthorisedTeam = (props) => {

    const TOKEN = process.env.REACT_APP_chat_api_token;

    const conversId = parseInt(props.conversID ?? 0);
    const conversationDetail = ChatUtilities.getAccountConversationDetail(conversId);
    const accessFlag = parseInt(conversationDetail.access_flag ?? 0);
    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ showEmpty, setShowEmpty ] = useState(false);

    const [ profileSelected, setProfileSelected ] = useState({});
    const [ companyProfile, setCompanyProfile ] = useState({});
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
  
    const defaultValues = { title : '' };
    const [ formValues, setFormValues ] = useState(defaultValues);


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else if ( fieldName==='title' ){

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }



    const getMembers = async () => {
       
        // flag :  0: all, 1: active, 2: suspend, 3: pending
        const paramMembers = {
            api_token : TOKEN.toString(),
            account_id : (userLoginDetail.account_id ?? 0).toString(),
            group_id : (conversationDetail.id ?? 0).toString(),
            flag : "1"
        }

        const responseMembers = await conversationTeamMembersList(paramMembers);
        if ( Object.entries(responseMembers).length===0 ){ 
            setShowEmpty(true);
            return false; 
        }

            if ( parseInt(responseMembers.success)===0 ){ 
                setShowEmpty(true);
                return false; 
            }

            const memberListRes = responseMembers.result.conversation_team_members_list.members_list ?? [];
            if ( parseInt(memberListRes.length)>0 ){ 
                setListProfiles(memberListRes);

            }else{
                setShowEmpty(true);
            }
            

    }   

    const handleShow = ( profile ) => {

        if ( parseInt(userLoginDetail.account_id ?? -1)===parseInt(profile.account_id ?? 0) ){
            return false;
        }

        setProfileSelected({
            ...profile,
            logo_url : profile.logo,
            category : profile.title
        });


        if ([3].includes(accessFlag)){
            setCompanyProfile({
                ...profile,
                name : profile.company_name,
                logo_url : profile.company_logo,
                category_name : profile.company_category
            });
        }else{
            setCompanyProfile({});
        }

        setShow(true);
    }

    const showChat = ( profile ) => {
        //console.log(profile);
        //history.push(`/${UrlSlugs.chat}/${btoa( parseInt(profile.account_id) )}`);
    }


    useEffect( () => {
        getMembers();
    }, [] );

      
    return (

        <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
            {
                Object.keys(listProfiles).length>0 ? 
                    <>
                        <div className="form-group">
                            <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                            <label className="form-label">Search</label>
                        </div>
                        

                            <ul id="listing-profiles-member" className="list-members company-groups-member-list mt-2 ">
                                {
                                    Object.entries(listProfiles).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.name.toLowerCase() } className={`${parseInt(userLoginDetail.account_id ?? -1)===parseInt(row.account_id ?? 0) ? 'cursor-notallow' : 'cursor-pointer' }`} onClick={ () => handleShow(row) } >
                                            <div><div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                            <div>
                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                <div className="profile-label">{row.title}</div>
                                            </div>
                                            <div className="text-right">
                                                { ( parseInt(row.admin_status??0)===1 && ![3].includes(parseInt(conversationDetail.access_flag??0)) ) && <span>admin</span> }
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                            
                            <ConnectTeamMember show={show} handleClose={handleClose} profileSelected={profileSelected} companyProfile={companyProfile} showChat={showChat} />
                    </>
                :
                    showEmpty===false ? <div className="content-loader animate"></div> : <div className="text-center text-lowercase no-record-label ">{ Utilities.labelProfileAvailable }</div> 
            }       
        </div>

    )
}

export default ChatSettingsCompanyGroupsAuthorisedTeam;
