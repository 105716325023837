const API_URL = process.env.REACT_APP_api_url;
const API_CHAT_URL = process.env.REACT_APP_chat_api_url;

class ApiUrlsHelper {
    
    // LOCATION /////////////////////////////////////////////////////////////
    externalIPLookUp = `http://www.geoplugin.net/php.gp` //`https://extreme-ip-lookup.com/json/`
    externalIPgeolocationDB = `https://geolocation-db.com/json/` // make user put the slash (/) in the end

    
     // MAP LOCATION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
     getCoordinatesByLocation = `${API_URL}/userlocation/getCoordinatesByLocation`
     getLocationByCoordinates = `${API_URL}/userlocation/getLocationByCoordinates`

    // GENERAL
    globalCountryList = `${API_URL}/globalqueries/countryList`


    // VERIFICATION ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
     verificationMobileExistance = `${API_URL}/verification/mobileExistance`
     verificationEmailExistance = `${API_URL}/verification/emailExistance`
     verificationForgotChangePassword = `${API_URL}/verification/forgotChangePassword`
     verificationForgotPasswordAccessKeyExistance = `${API_URL}/verification/forgotPasswordAccessKeyExistance`
     verificationFirstLastNameExistance = `${API_URL}/verification/firstLastNameCheckerBasicProfile`

    // SOCIAL ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    loginSocialCredential = `${API_URL}/user/getSocialCredentialAccount`


     // SECURITY ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
     securityEmailValidation = `${API_URL}/securityprofile/getCurrentCredentailEmail`
     securityEmailUpdate = `${API_URL}/securityprofile/updateCredentailEmail`
     securityPasswordValidation = `${API_URL}/securityprofile/checkCredentailPasswordValidity`
     securityPasswordUpdate = `${API_URL}/securityprofile/updateCredentailPassword`
     securityMobileValidation = `${API_URL}/securityprofile/getCurrentCredentailMobile`
     securityMobileUpdate = `${API_URL}/securityprofile/updateCredentailMobile`

     
     // SENDING EMAILS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
     sendingEmailAppReports = `${API_URL}/sendemail/appReports`
     sendingProfileSignupVerification = `${API_CHAT_URL}/profile/send_signup_verification`
     sendingEmailGeneral = `${API_CHAT_URL}/general/send_system_email`


    // FORGOT PASSWORD ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
     profileResetPassword = `${API_CHAT_URL}/profile/reset_password`
     
    // CATEGORY SEARCH ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileGetCategoryList = `${API_CHAT_URL}/profile/get_category_list`


    // URL META DATA /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    utilitiesGetUrlMetaData = `${API_URL}/utilities/getUrlMetaData`
    //utilitiesGetUrlMetaData = `${API_BIZCOM_URL}/jazechat/getUrlMetaData`
    

    // QUICK LINKS /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomSearchQuickLink = `${API_CHAT_URL}/bizcom/search_quicklink`

   
    // USER //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    userGetByCredentail = `${API_URL}/user/getByCredentail`
    userCheckEmailExistance = `${API_URL}/user/checkEmailExistance`
    userGenerateSendVerificationCode = `${API_URL}/user/generateSendVerificationCode`
    userVerifyCode = `${API_URL}/user/verifyCode`

    userLocationSaveLocation = `${API_URL}/userlocation/saveLocation`
    userLocationGetUserLocationById = `${API_URL}/userlocation/getUserLocationById`
    
    userUpdateLoginHistory = `${API_URL}/user/updateLoginHistory`


    // MASTER SEARCH //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileSearchCategoryProfileIdsByCategoryId = `${API_URL}/profilesearchcategory/profileIdsByCategoryId`
    profileSearchCategoryProfileIdsByCategoryName = `${API_URL}/profilesearchcategory/profileIdsByCategoryName`
    profileSearchCategoryByCategoryName = `${API_URL}/profilesearchcategory/byCategoryName`


    // UPLOAD LOGO AWS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    awsUploadFilesProfileImage = `${API_URL}/awsuploadfiles/uploadProfileImage`
    

    // PROFILES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profile = `${API_URL}/profile`
    profilegetByID = `${API_URL}/profile/getByID`
    profileGetToolStatus = `${API_URL}/profile/getToolStatus`
    profileSignupTeamDetail = `${API_URL}/profile/signupTeamDetail`
    profileUploadLogo = `${API_URL}/profile/uploadLogo`
    profileUdpatePersonalBasic = `${API_URL}/profile/udpatePersonalBasic`
    profileDeleteUserDetails = `${API_CHAT_URL}/profile/delete_user_details`


    profileUpdateEmailMobileCredentail = `${API_URL}/user/updateEmailMobileCredential`
    

    // LINKED PORFILES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    linkProfilesTeamList = `${API_URL}/linkprofiles/teamList`
    linkProfilesTeamAssign = `${API_URL}/linkprofiles/teamAssign`
    linkProfilesAssignedStatusByIDs = `${API_URL}/linkprofiles/assignedStatusByIDs`
    


    // COMPANY //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    companyOthers = `${API_URL}/company/companyOthers`
    companyUpdateDetail = `${API_URL}/company/updateDetail`
    companyTeamList = `${API_URL}/company/teamList`
    companySubmitCompanyDetail = `${API_URL}/company/submitCompanyDetail`



    // MAIN SEARCH //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    mainSearchStatesByCountry = `${API_URL}/mainsearch/statesByCountry`
    mainSearch = `${API_URL}/mainsearch/search`
    territoryAllSearch = `${API_URL}/territory/allSearch`

    // CONTACT SEARCH //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    searchContactsByCityId = `${API_URL}/searchcontacts/bycity`

    
    // CATEGORIES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    mainCategorySearchByKeywords = `${API_URL}/maincategories/searchByKeywords`
    mainCategoryGetSubCategoriesByParentId = `${API_URL}/maincategories/getSubCategoriesByParentId`
    mainCategoryGetById = `${API_URL}/maincategories/getById`


    // KEYWORDS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    mainCompanyKeywordsSearchByKeywords = `${API_URL}/companykeywords/searchByKeywords`
    mainCompanyKeywordsSearchByKeywordsShowStates = `${API_URL}/companykeywords/searchByKeywordsShowStates`
    mainCompanyKeywordsGetProfileByIds = `${API_URL}/companykeywords/getProfileByIds`

    
    
    // LOCATIONS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    country = `${API_URL}/country`
    stateList = `${API_URL}/state/list`
    cityList = `${API_URL}/city/list`
    cityDetailById = `${API_URL}/city/detailById`
    citySearchByCityId = `${API_URL}/city/searchByCityId`
    citySearchByCityName = `${API_URL}/city/searchByCityName`
    citySearchByCityNameFromAPI = `${API_URL}/city/searchByCityNameFromAPI`
    citySearhByName = `${API_URL}/city/searchByName`
    areaList = `${API_URL}/area/list`

    
    // DOCUMENT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    documentGetDocumentByGroupIDs = `${API_URL}/document/getDocumentByGroupIDs`
    documentTransferFileToConversFolder = `${API_URL}/document/transferFileToConversFolder`
    documentGetEncodedFile = `${API_URL}/document/getEncodedFile`


    bizcomDocumentsLibrary = `${API_CHAT_URL}/bizcom_documents_library`
    //bizcomUploadLibrary = `${API_CHAT_URL}/plus/documents/upload_library`
    bizcomUploadLibrary = `${API_CHAT_URL}/documents/upload_library`

   
    // CHAT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    chatUploadAttachmentTransfer = `${API_URL}/chat/uploadAttachmentTransfer`
    chatUploadMedia = `${API_URL}/chat/uploadMedia`
    chatUploadAudio = `${API_URL}/chat/uploadAudio`


    // DEPARTMENTD //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    departmentListByAccountID = `${API_URL}/department/listByAccountID`
    departmentGetAccess = `${API_URL}/department/getAccess`
    departmentAddUpdate = `${API_URL}/department/addUpdateDepartment`
    departmentDelete = `${API_URL}/department/delete`
    departmentGetDetail = `${API_URL}/department/getDetail`
    departmentGetDetailByCompanyDepartmentID = `${API_URL}/department/getDetailByCompanyDepartmentID`


    departmentAccessGetDepartments = `${API_URL}/departmentaccess/getdepartments`
    departmentAccessAccessible = `${API_URL}/departmentaccess/accessible`
    departmentAccessLink = `${API_URL}/departmentaccess/link`
    departmentAccessUnlink = `${API_URL}/departmentaccess/unlink`
    departmentAccessDeleteLinked = `${API_URL}/departmentaccess/deletelinked`
    

    // TEAM //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    teamList = `${API_URL}/team/list`
    teamAddUpdateDetail = `${API_URL}/team/addUpdateDetail`
    teamDetail = `${API_URL}/team/detail`
    teamRemove = `${API_URL}/team/remove`

    teamProfileRemove = `${API_CHAT_URL}/profile/remove_team_profile`
    

    // MENU / NOTIFICATION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    teamMenuNotificationAccessUpdate = `${API_URL}/team/menuNotificationAccessUpdate`

    
    // DIVERT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileAssignTeamProfile = `${API_CHAT_URL}/profile/assign_team_profile`


    // DASHBOARD CONVERSATION LISTING  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomWebHomeDetails = `${API_CHAT_URL}/bizcom/bizcom_web_home_details`
    bizchatConversationList = `${API_CHAT_URL}/bizcom/bizcom_web_home_details`
    bizchatSendTeamInvitation = `${API_CHAT_URL}/bizchat/send_team_invitation`
    bizcomHomeConversationList = `${API_CHAT_URL}/bizcom/bizcom_home_conversation_list`
    //bizchatConversationList = `${API_CHAT_URL}/bizchat/conversation_list`
    

    bizchatTransferConversation = `${API_CHAT_URL}/bizchat/transfer_conversation`
    bizchatCreateBizcomConversation = `${API_CHAT_URL}/bizchat/create_bizcom_conversation`
    bizcomWebInternalHomeDetail = `${API_CHAT_URL}/bizcom/web_internal_home_details`
  
    bizchatCreateBroadcastDetails = `${API_CHAT_URL}/bizchat/create_broadcastdetails`
    bizchatUpdateBroadcastDetails = `${API_CHAT_URL}/bizchat/update_broadcastdetails`
    bizchatDeleteBroadcastDetails = `${API_CHAT_URL}/bizchat/delete_broadcastdetails`
    bizchatGetBroadcastMembersDetails = `${API_CHAT_URL}/bizchat/get_broadcast_members_details`

    
    bizchatSendBroadcastMessage = `${API_CHAT_URL}/bizchat/send_broadcast_message`
    bizchatSetReadStatus = `${API_CHAT_URL}/bizchat/set_read_status`


    bizchatGetConversationNotes = `${API_CHAT_URL}/bizchat/get_conversation_notes`
    bizchatUpdateConversationNotes = `${API_CHAT_URL}/bizchat/update_conversation_notes`

    bizchatGetTemplateMessage = `${API_CHAT_URL}/bizchat/get_template_message`
    bizchatUpdateTemplateMessage = `${API_CHAT_URL}/bizchat/update_template_message`

    bizchatGetAllMedia = `${API_CHAT_URL}/bizchat/get_all_media`
    bizchatMuteConversation = `${API_CHAT_URL}/bizchat/mute_conversation`
    bizchatReportConversation = `${API_CHAT_URL}/bizchat/report_conversation`

    bizchatForwardConversation = `${API_CHAT_URL}/bizchat/forward_conversation`
    bizchatDeleteMediaFile = `${API_CHAT_URL}/bizchat/delete_media_file`
    bizchatConversationBlockUnblock = `${API_CHAT_URL}/bizchat/conversation_blockunblock`
  
    bizchatClearConversation = `${API_CHAT_URL}/bizchat/clear_conversation`
 

    // DEPARTMENT FORMS  ------------------------------------------------------------------------------------------------------------------
    

    // this old urls ------------------------------------------------------------------------------------------------------------------
    bizchatRequestReservationComplaintsDetail =  `${API_CHAT_URL}/bizchat/request_reservation_complaints_details`
    bizchatAcceptRejectReservationCompaints =  `${API_CHAT_URL}/bizchat/acceptreject_reservation_complaints`
    bizchatGetReservationComplaintsDetails =  `${API_CHAT_URL}/bizchat/get_reservation_complaints_details`
    // end old urls ------------------------------------------------------------------------------------------------------------------

    
    bizchatRequestAppointmentComplaint =  `${API_CHAT_URL}/bizchat/request_appointment_reservation_complaint`
    bizchatGetAppointmentReservationComplaintsDetails =  `${API_CHAT_URL}/bizchat/get_appointment_reservation_complaints_details`
    bizchatCancelAppointmentReservation =  `${API_CHAT_URL}/bizchat/cancel_appointment_reservation`
    bizchatAcceptAppointmentReservation =  `${API_CHAT_URL}/bizchat/accept_appointment_reservation`
    bizchatAmendAppointmentReservation =  `${API_CHAT_URL}/bizchat/amend_appointment_reservation`
    bizchatRequestComplaintReply =  `${API_CHAT_URL}/bizchat/request_complaint_replay`
    diaryGetAppointmentReservationDetails =  `${API_CHAT_URL}/diary/get_appointment_reservation_details`
    
    
    // DEPARTMENT DEFAULT MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileUpdateDepartmentDefaultMessage =  `${API_CHAT_URL}/profile/update_department_default_message`
    profileDepartmentFormStatus =  `${API_CHAT_URL}/profile/update_department_form_status`
    profileActivateTeamAppointmentStatus =  `${API_CHAT_URL}/profile/activate_team_appointment_status`
    profileActivateTeamRestrictAccess =  `${API_CHAT_URL}/profile/activate_team_restrict_access`

    
    //profileGetProfileQrCode = `${API_CHAT_URL}/profile/get_profile_qr_code`
    profileGetProfileQrCode = `${API_CHAT_URL}/profile/get_profile_qrcode`
    profileGetAutomatedReplayMessage = `${API_CHAT_URL}/profile/get_automated_replay_message`
    profileUpdateAutomatedReplayMessage = `${API_CHAT_URL}/profile/update_automated_replay_message`

    profileAcceptRejectTeamInvitation = `${API_CHAT_URL}/profile/accept_reject_team_invitation`
    

    // CHAT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatGetConversationDetails =  `${API_CHAT_URL}/bizchat/get_conversation_details`
    bizchatReverseAccessteamCommunication =  `${API_CHAT_URL}/bizchat/reverse_access_team_communication`
    bizchatResendAccessTeamCommunication =  `${API_CHAT_URL}/bizchat/resend_access_team_communication`
    bizchatBeonchatConversationDetails =  `${API_CHAT_URL}/bizchat/beonchat_conversation_details`

    
    // CHAT COMMUNICATION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatAcceptRejectAccessCommunication = `${API_CHAT_URL}/bizchat/accept_reject_access_company_user_communication`
    bizchatReverseAccessCommunicationUserCommunication =  `${API_CHAT_URL}/bizchat/reverse_access_company_user_communication`
    bizchatResendAccessUserCommunication =  `${API_CHAT_URL}/bizchat/resend_access_company_user_communication`
    

    // CHAT GROUP - CHAT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatCheckGroupNameExist = `${API_CHAT_URL}/bizchat/check_group_name_exist`
    bizchatUploadGroupLogo = `${API_CHAT_URL}/bizchat/upload_group_logo`
    bizchatCreateGroup = `${API_CHAT_URL}/bizchat/create_group`
    bizchatLeaveGroup = `${API_CHAT_URL}/bizchat/leave_group`
    bizchatGroupDetail = `${API_CHAT_URL}/bizchat/group_details`
    bizchatDeleteGroupDetails = `${API_CHAT_URL}/bizchat/delete_group_details`
    bizchatUpdateGroupMemberAdminStatus = `${API_CHAT_URL}/bizchat/update_group_member_admin_status`
    bizchatUpdateGroupDetails = `${API_CHAT_URL}/bizchat/update_group_details`
    bizchatDeleteGroupConversation = `${API_CHAT_URL}/bizchat/delete_group_conversation`
    

    // MENU / PRICE LIST //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileManageCatalogDetails = `${API_CHAT_URL}/profile/manage_catalog_details`
    profileUploadCatalogFile = `${API_CHAT_URL}/profile/uploadCatalogFile`

    // TEAM RESTRICTION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatContactRequest = `${API_CHAT_URL}/bizchat/contact_request`

    // TEAM RESTRICTION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatContactRequestTeam = `${API_CHAT_URL}/bizchat/contact_request_team`
    bizchatRequestAccessTeamCommunication = `${API_CHAT_URL}/bizchat/request_access_team_communication`
    bizchatAcceptRejectAccessTeamCommunication = `${API_CHAT_URL}/bizchat/accept_reject_access_team_communication`


    // TALK TO US //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomTalkToBizcomAssist = `${API_CHAT_URL}/bizchat/talk_to_bizcom_assist`

    
    // CALENDAR //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    calendarGetCounterByDates = `${API_URL}/calendardiary/getCounterByDates`
    calendarGetAppointmentsByIds = `${API_URL}/calendardiary/getAppointmentsByIds`
    calendarGetAppointmentsByDate = `${API_URL}/calendardiary/getAppointmentsByDate`


    // FOLLOWING //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    followingUpdateStatus = `${API_URL}/following/updateFollowingStatus`
   
    

    // NOTICES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileUpdateCompanyFollowStatus = `${API_CHAT_URL}/profile/update_company_follow_status`
    followersNoticeList = `${API_CHAT_URL}/followers_notice/notice_list`
    noticeCompanyFollowers = `${API_CHAT_URL}/followers_notice/company_followers_list`
    noticeSendNotification = `${API_CHAT_URL}/followers_notice/send_follow_notification`
    noticeUploadFile = `${API_CHAT_URL}/followers_notice/upload_file`
    noticeUpdateCompanyFollowers = `${API_CHAT_URL}/followers_notice/update_company_followers`

    followersNotificationList = `${API_CHAT_URL}/followers_notice/home_notification_list`
    followersFollowingList = `${API_CHAT_URL}/followers_notice/following_list`
    followersUnfollowCompany = `${API_CHAT_URL}/followers_notice/unfollow_company`

    
     // BIZCOM ASSIST //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomMarketingTools = `${API_CHAT_URL}/bizcom/beonchat_marketing_tools`
    bizcomVideos = `${API_CHAT_URL}/bizcom/beonchat_videos`

    
    // COUNTERS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    counterByType = `${API_URL}/counter/byType`


    // GROUPS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    companyBroadcastLeaveGroup = `${API_CHAT_URL}/company_broadcast/leave_group`
    bizchatCompanyBroadcastDetails =  `${API_CHAT_URL}/bizchat/company_broadcast_conversation_details`
    companyBroadcastConversationMembersList = `${API_CHAT_URL}/company_broadcast/conversation_members_list`
    companyBroadcastAcceptRejectGroupRequest = `${API_CHAT_URL}/company_broadcast/accept_reject_group_request`
    companyBroadcastUpdateConversationMembers = `${API_CHAT_URL}/company_broadcast/update_conversation_members`
    companyBroadcastConversationsTeamMembersList = `${API_CHAT_URL}/company_broadcast/conversation_team_members_list`
    companyBroadcastCompanyGroupsList = `${API_CHAT_URL}/company_broadcast/company_broadcast_list`
    companyBroadcastAdminMembersList = `${API_CHAT_URL}/company_broadcast/admin_members_list`
    companyBroadcastCompanyCreateGroups = `${API_CHAT_URL}/company_broadcast/create_company_broadcast_details`
    companyBroadcastCompanyUpdateGroups = `${API_CHAT_URL}/company_broadcast/update_company_broadcast_details`
    companyBroadcastCompanyDeleteGroups = `${API_CHAT_URL}/company_broadcast/delete_company_broadcast_details`
    companyBroadcastCompanyGroupsListBizchat = `${API_CHAT_URL}/company_broadcast/company_group_list_bizchat`
    companyBroadcastJoinOpenGroup = `${API_CHAT_URL}/company_broadcast/join_open_group`
    companyBroadcastCompanyGroupsSendRequest = `${API_CHAT_URL}/company_broadcast/send_request_join`
    companyBroadcastCompanyGroupsJoinWithPasscode = `${API_CHAT_URL}/company_broadcast/join_group_passcode`
    companyBroadcastCompanyGroupsRemoveJoinRequest = `${API_CHAT_URL}/company_broadcast/remove_join_request`
    
}

export default new ApiUrlsHelper();

