import React, { useState, useEffect  }  from 'react';
import jQuery from 'jquery';

import PasswordInput from '../../../components/PasswordInput';

import { checkCredentailPasswordValidity, updateCredentailPassword } from '../../../helpers/SecurityProfile';
import TitlesLabels from '../../../helpers/TitlesLabels';
import Utilities from '../../../helpers/Utilities';

const Password = ( props ) => {

    let defaultFormValues = {
        current_password : '',
        new_password : '',
        confirm_password : '',
    }

    const profileProvided = props.profileProvided ?? {};

    const [ formValues, setFormValues ] = useState(defaultFormValues);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if ( [...Object.keys(defaultFormValues)].includes(fieldName) ){
            if ( fieldName==='current_password'){
                formValuesTemp = { ...formValuesTemp, current_password : value };
            }else if ( fieldName==='new_password'){
                formValuesTemp = { ...formValuesTemp, new_password : value };
            }else if ( fieldName==='confirm_password'){
                formValuesTemp = { ...formValuesTemp, confirm_password : value };
            }
        }

        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);
    }


    const handleOnclickSubmit = async () => {

        const button_name = 'btn-submit-password';
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(profileProvided).length===0 ){ return false; }

        let errorCounter = 0;
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( Utilities.isEmpty(value)){
                jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }else{
                const passwordValidation = Utilities.passwordValidation(value);
                if ( passwordValidation.response===false ){
                    jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }
        });
        if ( errorCounter>0 ){  return false; }
        
        Utilities.elementDisabledStatus(button_name, true );
        const responsePasswordValidation = await checkCredentailPasswordValidity({ account_id: profileProvided.account_id.toString(), password : formValues.current_password.toString() });
        Utilities.elementDisabledStatus(button_name, false ); 

        if ( Object.keys(responsePasswordValidation).length===0 ){ return false; }

        if ( parseInt(responsePasswordValidation.status)===0 ){
            Utilities.messagePopup('Error', TitlesLabels.general.currentPasswordIncorrent );
        }else{

            const new_password = formValues.new_password, confirm_password = formValues.confirm_password;
            if ( new_password!==confirm_password ){
                Utilities.messagePopup('Error', TitlesLabels.general.newConfirmPasswordNotSame );
                
            }else{


                Utilities.elementDisabledStatus(button_name, true );
                const responsePasswordUpdate = await updateCredentailPassword({ account_id: profileProvided.account_id.toString(), password : new_password });
                Utilities.elementDisabledStatus(button_name, false );
                
                if ( Object.keys(responsePasswordUpdate).length===0 ){
                    Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotUpdatePassword );
                }else{

                    if ( parseInt(responsePasswordUpdate.status)===0 ){
                        Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotUpdatePassword );
                    }else{
                      
                        Utilities.messagePopup('success', TitlesLabels.alertMessages.passwordUpdatedSuccessfully );
                        setFormValues(defaultFormValues);
                        
                    }

                }
            }

        }

    }


    useEffect( () => {

    }, []);

    return (
        <>
            <PasswordInput inputName="current_password" inputLabel="Current Password" inputValue={ formValues.current_password } pushValue={pushValue} />

            <PasswordInput inputName="new_password" inputLabel="New Password" inputValue={ formValues.new_password } pushValue={pushValue} showChecker={true} />

            <PasswordInput inputName="confirm_password" inputLabel="Confirm Password" inputValue={ formValues.confirm_password } pushValue={pushValue} />

            <button type="button" id="btn-submit-password" className="btn-theme-black  button-has-loader mt-5" onClick={ () => handleOnclickSubmit() }>submit</button>
        </>
    )
}

export default Password;