import React, { useEffect, useState } from 'react';
import TitlesLabels from '../helpers/TitlesLabels';
import Utilities from '../helpers/Utilities';

const FieldInput = ( props ) => {

    const checkerTypesArray = ['general', 'general-companyname'];

    const [ inputMessage , setInputMessage ] = useState('');
    const [ inputValue , setInputValue ] = useState('');
    const [ showOption , setShowOption ] = useState(false);

    useEffect( () => {
        let showOptionRequest = props.showOption ?? false;
        setShowOption(showOptionRequest);

        let  checkerTypeRequest = props.checkerType ?? '';
        checkerTypeRequest = props.checkerType.toLowerCase();

        let inputValueRequest = props.inputValue ?? '';
        inputValueRequest = inputValueRequest.toLowerCase();

        setInputValue( inputValueRequest );

        if ( checkerTypesArray.includes(checkerTypeRequest) ){
            const responseChecker = Utilities.inputCharacterChecker(inputValueRequest, checkerTypeRequest);
            setInputMessage( responseChecker===false ? TitlesLabels.general.specialCharactersNotAllowed : '' );
        }

    }, [props] );


    return (
        <>
            <div className={`form-group ${ showOption===true ? 'optional' : '' }`}>
                <input type="text" name={props.inputName} className={`form-control ${ props.inputClass ?? ''}`} placeholder=" " value={ inputValue }  onChange={ (e) => props.pushValue( e, props.inputName) } autoComplete="off" ></input>
                <label className="form-label">{ props.inputLabel }</label>
                { showOption===true && <div className="optional-label">Optional</div> }
            </div>

            { ! Utilities.isEmpty(inputMessage) && <div className="text-gray px-2 mt-n2 pb-3" dangerouslySetInnerHTML={{ __html: inputMessage }} ></div> }

        </>
    )
}

export default FieldInput;
