import React, { useEffect } from 'react';
import ContactForm from '../../components/ContactForm';
import { social_links } from '../../helpers/Constants';
import jQuery from 'jquery';

const Footer = () => {

    const contactEmail = `contact@beonchat.com`;
    const contactNumber = `+27 69 328 2028`;

    const contactMobileHtml =  <a href={`tel:${contactEmail}`} className="text-white">{contactNumber}</a>
    const copyrightHtml =  <div className="text-center fs-16">copyright &copy; {  new Date().getFullYear() }</div>
    const contactEmailHtml = <a href={`mailto:${contactEmail}`} className="text-white">{contactEmail}</a>
    const socialLinksHtml = <>
                                <a href={ social_links.facebook } target={`${ social_links.facebook!=='#' ? '_blank' : '_self' }`}><img src={`${process.env.REACT_APP_img_folder}/icons/social/outline-facebook-circle.svg`} alt="facebook"  width="35"/></a>
                                <a href={ social_links.youtube } target={`${ social_links.youtube!=='#' ? '_blank' : '_self' }`} className="ml-4"><img src={`${process.env.REACT_APP_img_folder}/icons/social/outline-youtube-circle.svg`} alt="youtube"  width="35"/></a>
                                <a href={ social_links.twitter } target={`${ social_links.twitter!=='#' ? '_blank' : '_self' }`} className="ml-4"><img src={`${process.env.REACT_APP_img_folder}/icons/social/outline-twitter-circle.svg`} alt="twitter"  width="35"/></a>
                                <a href={ social_links.instagram } target={`${ social_links.instagram!=='#' ? '_blank' : '_self' }`} className="ml-4"><img src={`${process.env.REACT_APP_img_folder}/icons/social/outline-instagram-circle.svg`} alt="instagram"  width="35"/></a>
                                {/* <a href={ social_links.tiktok } target={`${ social_links.tiktok!=='#' ? '_blank' : '_self' }`} className="ml-4"><img src={`${process.env.REACT_APP_img_folder}/icons/social/outline-tiktok-circle.svg`} alt="tiktok"  width="35"/></a> */}
                                {/* <a href={ social_links.whatsapp } target={`${ social_links.whatsapp!=='#' ? '_blank' : '_self' }`} className="ml-4"><img src={`${process.env.REACT_APP_img_folder}/icons/social/outline-whatsapp-circle.svg`} alt="tiktok"  width="35"/></a> */}
                             </>


    useEffect(() => {
        jQuery('body').addClass('mb-0');
    }, []);

    return (
        <section id="section-contact">  
            <footer className="footer login-page position-relative fs-18 bgcolor-dark text-white footer-window">
                <div className="login-container container py-5">
                    <div className="row px-3 px-sm-0 align-items-center">

                        <div className="col-lg-6 pb-4 pb-lg-0">
                            <h2 className="heading-title font-montserrat-bold mb-4 color-theme-green fs-35">contact</h2>
                            <div className="pt-3 mx-auto"><ContactForm buttonName="btn-submit-contact-info" messageInputRows={3} /></div>
                        </div>

                        <div className="col-lg-6 pb-4 pt-sm-4 pt-md-5 pt-lg-0 pb-sm-0">
                            <div className="text-center">
                                <div className="d-sm-inline d-md-block"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} className="footer-logo" alt="logo"  width="100"/></div>
                                <div className="d-sm-inline d-md-block pt-3 ml-sm-4 ml-lg-0"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-light.svg`} className="footer-logo-text" alt="logo"  width="300"/></div>
                            </div>
                        </div>

                    </div>

                    <div className="mt-2 mt-sm-5 pt-md-5 pt-lg-0">
                        <div className="text-center footer-social-icons"> { socialLinksHtml }</div>
                        <div className="text-center my-3 my-sm-4 pt-md-4 pt-lg-0 fs-22">
                            <div>{ contactEmailHtml }</div>
                            <div>{ contactMobileHtml }</div>
                        </div>
                        { copyrightHtml }
                    </div>
                    
                </div>
            </footer>


            <footer className="footer login-page position-relative fs-18 bgcolor-dark text-white pb-md-5 footer-mac">
                <div className="login-container container py-5">
                    
                    <div className="col-lg-7  mx-auto px-3 px-sm-0 align-items-center ">
                        <div className="pb-4 pb-lg-5">
                            <h2 className="heading-title font-montserrat-bold mb-4 color-theme-green fs-35">contact</h2>
                            <div className="pt-3 mb-4 mb-sm-0 mx-auto"><ContactForm /></div>
                        </div>

                        <div className="pb-4 pt-sm-4 pt-md-5 pb-md-5 pb-sm-0 pb-lg-4 mt-0 mt-md-5 mt-lg-0 mb-md-4 md-lg-0">
                            <div className="text-center">
                                <div className="d-sm-inline d-md-block ml-sm-4 ml-lg-0"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-light.svg`} className="footer-logo-text" alt="logo"  width="300"/></div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="mt-3">
                        <div className="text-center">
                            <div>{ contactEmailHtml }</div>
                            <div>{ contactMobileHtml }</div>
                        </div>
                        <div className="text-center footer-social-icons  my-4 my-sm-4 pt-lg-0 fs-22">
                            { socialLinksHtml }
                        </div>
                        { copyrightHtml }
                    </div>
                    
                </div>
            </footer>
        </section>
    )
}

export default Footer;