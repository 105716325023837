import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; 
import * as RM from 'ramda';

import ChatUtilities from '../helpers/ChatUtilities';
import Utilities from '../helpers/Utilities';

import { passImageUrl, passQrcodeDetail, passConversDetail as passConversDetailReducer  } from '../actions';
import QueryChatUtilities from '../helpers/QueryChatUtilities';


import ChatTeamRestrictionRejection from './popups/ChatTeamRestrictionRejection';
import ChatUserCommunicationRejection from './popups/ChatUserCommunicationRejection';
import TitlesLabels from '../helpers/TitlesLabels';
import PagesAuthentication from '../helpers/PagesAuthentication';

const ChatConversation = ( props ) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN_CHAT = process.env.REACT_APP_chat_api_token;
    const defaultProfileLogo = process.env.REACT_APP_default_avatar;
    const sessionLastConversationMessageKey = process.env.REACT_APP_session_last_conversation_message;

    const isSearch = props.isSearch;
    const ulConversationElem = jQuery('#chat-conversation--history-messages');

    const [ conversID, setConversID ] = useState(0);
    const [ loginAccount, setLoginAccount ] = useState({});
    const [ workAccount, setWorkAccount ] = useState({});
    const [ currentConversationDetail, setCurrentConversationDetail ] = useState({});
    const [ showLoadingMessages, setShowLoadingMessages ] = useState(true);
    const [ ownerIdConversation, setOwnerIdConversation ] = useState(0);
    const [ previousConversations, setPreviousConversations ] = useState([]);
    const [ presentConversations, setPresentConversations ] = useState([]);

    const [ messagesIdSelected , setMessagesIdSelected ] = useState([]);
    const [ selectedMessagesDetail, setSelectedMessagesDetail ] = useState([]);

    let conversationsDate = [];

    const transferTypesArray = [31,33];
    const dontShowMessageTypes = [32,34];

    const excludedOptionArray = [10,11,13,17,19,31,33,35,41,42,43,14,15];
    const multipleOptionArray = [1,2,3,4,5,6,7,8,9,16,15];
    const restrictionMessageTypesArray = [...ChatUtilities.restrictionMessageTypesArray];
    const communicationMessageTypeArray = [41,42,43];

    const [ showTeamRestrictionRejectPopup, setShowTeamRestrictionRejectPopup ] = useState(false);
    const [ showUserCommunicationRejectPopup, setShowUserCommunicationRejectPopup ] = useState(false);

    const handeFormSubmit = ( action = 0, formData = {}, messageData = {} ) => {

        formData = {...formData, action : action.toString() };

        if ( action===0){
            props.handeFormSubmit({});

        }else if ( action===3 ){
            confirmAlert({
                title: '',
                message: `are you sure to wish to cancel ${ Utilities.viewFormLabel(formData.type) }?`,
                overlayClassName : '',
                buttons: [
                    { label: 'No',  onClick: () => {} },
                    { label: 'yes',  onClick: () => { props.handeFormSubmit(formData, {}, messageData); } }
                ]
            }); 

        }else{
             props.handeFormSubmit(formData, {}, messageData);
        }
        

    }


    const messageBoxOption = ( messageData = {} ) => {
        
        const mainMessage = messageData.message.toString();
        const message_type = parseInt(messageData.message_type);

        if ( message_type===2 ){
            if ( ! Utilities.isEmpty(mainMessage) ){
                dispatch( passImageUrl(mainMessage) );
            }
        }else if ( message_type===16 ){
            if ( ! Utilities.isEmpty(mainMessage) && Object.keys(mainMessage).length>0 ){
                dispatch( passQrcodeDetail(messageData) );
           }
        }
    }


    const selectMessage = ( messageSelectedDetail = {} ) => {
        const message_id = parseInt(messageSelectedDetail.doc_id);
        let messagesIdSelectedTemp = messagesIdSelected;

        ( message_id>0 && ! ( messagesIdSelectedTemp.includes(message_id) )  ) ? messagesIdSelectedTemp.push(message_id) : ( messagesIdSelectedTemp = [...Utilities.removeValueInArray(messagesIdSelectedTemp, message_id)] ) ;
        messagesIdSelectedTemp = Utilities.arrayUnique(messagesIdSelectedTemp);

        setMessagesIdSelected( messagesIdSelectedTemp );

        const selectedMessagesDetailTemp = [...selectedMessagesDetail, messageSelectedDetail ];
        let selectedMessagesDetailPass = [];
        
        selectedMessagesDetailTemp.map( detail => {
            const idMsg = parseInt(detail.doc_id);
            if ( messagesIdSelectedTemp.includes(idMsg) ){
                selectedMessagesDetailPass.push(detail);
            }
        });

        setSelectedMessagesDetail(selectedMessagesDetailPass);
        props.messagesSelectedDetail(selectedMessagesDetailPass);

    }

    const companyGroupsHtmlContent = ( messageData, messageCustom ) => {
        let mainMesageHtml  = messageCustom.html_message;
        return(
            <>
                <div className={`message-form-title notranslate message-form-title text-center`} dangerouslySetInnerHTML={{ __html: mainMesageHtml }}></div>
                <div className="form-info-wrap">
                    { messageData.message }

                    <div className="button-group-boolean pt-4">
                        <button type="button" id={`btn-comp-group-access-reject-${messageData.doc_id}`}  className="btn-theme-black button-has-loader" onClick={ () => props.acceptRejectJoinInvitationCompanyGroups(messageData, 'reject') }>reject</button>
                        <button type="button" id={`btn-comp-group-access-accept-${messageData.doc_id}`}  className=" btn-theme-black button-has-loader" onClick={ () => props.acceptRejectJoinInvitationCompanyGroups(messageData, 'accept') }>accept</button>
                    </div>

                </div>
            </>
        )
    }

    const formsHtmlContent = ( messageData, messageCustom ) => {
        let returnHtml = messageData.message.toString();

        const message_type = parseInt(messageData.message_type);
        const msgFormArray = returnHtml.split('|'); //[ type, detail-json ]
  
        const currentLoginId = parseInt(loginAccount.account_id);
        const messageAccountId = parseInt(messageData.account_id);

        let class_ttle_array = [];
        if ( message_type===22 ){
            class_ttle_array.push('form-status-ammend');
        }else  if ( message_type===23 ){
            class_ttle_array.push('form-status-success');
        }else  if ( message_type===24 ){
            class_ttle_array.push('form-status-cancel');
        }

        let messageInfoArray = {};
        let messageInfoListHtml = '';
        let messageInfoButtonsHtml = '';

        if ( typeof msgFormArray[1]!=='undefined' ){
            const messageDetailArray = JSON.parse(msgFormArray[1]);
            if ( typeof messageDetailArray==='object' ){
                messageInfoArray = messageDetailArray;

                const formType = parseInt(msgFormArray[0] || 0);
                const button_status = parseInt( messageInfoArray.button_status || 0);

                const latestDateDisplay = ( messageInfoArray.hasOwnProperty('amendment_date') && ! Utilities.isEmpty(messageInfoArray.amendment_date) ) ?  messageInfoArray.amendment_date : messageInfoArray.date;
                const latestTimeDisplay = (  messageInfoArray.hasOwnProperty('amendment_time') && ! Utilities.isEmpty(messageInfoArray.amendment_time) ) ?  messageInfoArray.amendment_time : messageInfoArray.time;

                const oldDateDisplay = ( messageInfoArray.hasOwnProperty('amendment_date') && ! Utilities.isEmpty(messageInfoArray.amendment_date) ) ?  messageInfoArray.date : '';
                const oldTimeDisplay = ( messageInfoArray.hasOwnProperty('amendment_time') && ! Utilities.isEmpty(messageInfoArray.amendment_time) ) ?  messageInfoArray.time : '';
                
                if ( formType===1 ){
                    messageInfoListHtml = <Fragment>
                                            <div className="form-info-col">
                                                <div>name:</div>
                                                <div className="ellipsis-1">{ messageInfoArray.name }</div>
                                            </div> 
                                            <div className="form-info-col">
                                                <div>date:</div>
                                                <div>{Utilities.currentDateFormatted( latestDateDisplay )}</div>
                                                {  ! Utilities.isEmpty( oldDateDisplay )  && <div className="text-right text-color-pink">{Utilities.currentDateFormatted( oldDateDisplay )}</div> }
                                            </div>  
                                            <div className="form-info-col">
                                                <div>time:</div>
                                                <div>{Utilities.returnTimeFormat( messageInfoArray.date+' '+latestTimeDisplay ) }</div>
                                                { ! Utilities.isEmpty( oldTimeDisplay ) && <div className="text-right text-color-pink">{Utilities.returnTimeFormat( messageInfoArray.date+' '+oldTimeDisplay ) }</div> }
                                            </div> 
                                        </Fragment>
                }else if ( formType===2 ){

                    const latestNoPeopleDisplay = ( messageInfoArray.hasOwnProperty('amendment_no_people') &&  ! Utilities.isEmpty(messageInfoArray.amendment_no_people) ) ? messageInfoArray.amendment_no_people : messageInfoArray.no_people;
                    const oldNoPeopleDisplay = ( messageInfoArray.hasOwnProperty('amendment_no_people') &&  ! Utilities.isEmpty(messageInfoArray.amendment_no_people) ) ?  messageInfoArray.no_people : '';
                    
                    messageInfoListHtml = <Fragment>
                                            <div className="form-info-col">
                                                <div>date:</div>
                                                <div>{Utilities.currentDateFormatted( latestDateDisplay )}</div>
                                                { ! Utilities.isEmpty( oldDateDisplay )&& <div className="text-right text-color-pink">{Utilities.currentDateFormatted( oldDateDisplay )}</div> }
                                            </div>  
                                            <div className="form-info-col">
                                                <div>time:</div>
                                                <div>{Utilities.returnTimeFormat( messageInfoArray.date+' '+latestTimeDisplay ) }</div>
                                                { ! Utilities.isEmpty( oldTimeDisplay ) && <div className="text-right text-color-pink">{Utilities.returnTimeFormat( messageInfoArray.date+' '+oldTimeDisplay ) }</div> }
                                            </div> 
                                            <div className="form-info-col">
                                                <div>seat:</div>
                                                <div>{ latestNoPeopleDisplay }</div>
                                                { ! Utilities.isEmpty( oldNoPeopleDisplay ) && <div className="text-right text-color-pink">{oldNoPeopleDisplay}</div> }
                                            </div> 
                                        </Fragment>

                }else if ( formType===3 ){
                    messageInfoListHtml = <Fragment>
                                              <div className="form-info-col">
                                                <div>date:</div>
                                                <div>{Utilities.currentDateFormatted( latestDateDisplay )}</div>
                                                { ! Utilities.isEmpty( oldDateDisplay ) && <div className="text-right text-color-pink">{Utilities.currentDateFormatted( oldDateDisplay )}</div> }
                                             </div>  
                                             <div className="form-info-col">
                                                <div>time:</div>
                                                <div>{Utilities.returnTimeFormat( messageInfoArray.date+' '+latestTimeDisplay ) }</div>
                                                { ! Utilities.isEmpty( oldTimeDisplay ) && <div className="text-right text-color-pink">{Utilities.returnTimeFormat( messageInfoArray.date+' '+oldTimeDisplay ) }</div> }
                                             </div> 
                                        </Fragment>
                }

                const buttonDefaultHtml = <Fragment>
                                            <button type="button" id={`btn-view-form-detail-${messageData.doc_id}`} className="btn-theme-black button-has-loader" onClick={ () => props.handleViewFormDetail(messageData) }>amend request</button>
                                            <button type="button" id={`btn-submit-form-cancel-${messageData.doc_id}`} className=" btn-theme-black button-has-loader" onClick={ () => handeFormSubmit( 3, messageInfoArray, messageData ) }>cancel request</button>
                                        </Fragment>

                const buttonTwoOption = <div className="button-group-boolean pt-4">{ buttonDefaultHtml }</div>
                const buttonThreeOption = <div className="button-group-boolean button-group-3 pt-4">
                                            <button type="button" id={`btn-submit-reply-${messageData.doc_id}`} className="btn-theme-black button-has-loader" onClick={ () => handeFormSubmit( 4, messageInfoArray, messageData ) }>accept request</button>
                                            { buttonDefaultHtml }
                                        </div>

                if ( formType!==3 && ( button_status!==3 && button_status!==0 )  ){
                   
                        if ( currentLoginId===messageAccountId ){

                            if ( button_status===1 ){
                                messageInfoButtonsHtml = buttonTwoOption;

                            }else if ( button_status===2 ){
                                messageInfoButtonsHtml = buttonThreeOption;
                            }
                           
                        }else{

                            if ( button_status===1 ){
                                messageInfoButtonsHtml = buttonThreeOption;

                            }else if ( button_status===2 ){
                                messageInfoButtonsHtml = buttonTwoOption;
                            }
                        }

                }else if ( formType!==3 && button_status===0  ){
                    messageInfoButtonsHtml = buttonTwoOption;
                }


                // REMOVE BUTTON OPTION
                if ( parseInt(currentConversationDetail.access_status || 0)===0 ){
                    messageInfoButtonsHtml = '';
                }
            }
        }
        

        let mainMesageHtml  = messageCustom.html_message;

        const latestRemarksDisplay = messageInfoArray.hasOwnProperty('amendment_remarks') &&  ! Utilities.isEmpty(messageInfoArray.amendment_remarks) ? messageInfoArray.amendment_remarks : messageInfoArray.remarks;
        const oldRemarksDisplay = messageInfoArray.hasOwnProperty('amendment_remarks') && ! Utilities.isEmpty(messageInfoArray.amendment_remarks) ?  messageInfoArray.remarks : '';
        
        returnHtml = <Fragment>
                            <div className={`message-form-title notranslate message-form-title-${msgFormArray[0]} ${class_ttle_array.join(' ')} text-center`} dangerouslySetInnerHTML={{ __html: mainMesageHtml }}></div>
                            {
                                Object.entries(messageInfoArray).length>0 ?
                                <Fragment>
                                    <div className="form-info-wrap">
                                        { ! Utilities.isEmpty( messageInfoListHtml ) && <div className="pb-2">{messageInfoListHtml}</div> }
                                        { 
                                            parseInt(msgFormArray[0] || 0)===3 ?
                                                <div className="form-info-col"> 
                                                    <div>subject:</div>
                                                    <div>{ messageInfoArray.hasOwnProperty('subject') && messageInfoArray.subject }</div>
                                                </div>
                                            :
                                                <div className="text-blue-label">special request</div>
                                        }

                                        <div className="pt-2" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(latestRemarksDisplay) }}></div>
                                        { ! Utilities.isEmpty( oldRemarksDisplay ) && <div className="pt-2 text-color-pink" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(oldRemarksDisplay) }}></div> }

                                        {  ! Utilities.isEmpty( messageInfoButtonsHtml ) && messageInfoButtonsHtml  }
                                    </div>

                                </Fragment>
                                :
                                <div className="text-center pt-3 text-gray form-info-wrap">{ TitlesLabels.general.noDetailAvailable }</div>
                            }
                            
                        </Fragment>
      

        return returnHtml;

    }

    const toggleMultipleSelection = () => {
        setMessagesIdSelected([])
        setSelectedMessagesDetail([]);
        ulConversationElem.addClass('enable-selection');
    }

    const menuOptionHtml = ( messageData ) => {
        
        /* const conversFlag = parseInt(currentConversationDetail.flag || 0);
        if ( conversFlag===6 ){ //remove option if company groups
            return false;
        } */

        // setting last conversation message
        sessionStorage.setItem(
            sessionLastConversationMessageKey,
            JSON.stringify(messageData)
        );

        let returnHtml = '';
        const message_type = parseInt(messageData.message_type);

        const owner_account_id = parseInt(currentConversationDetail.owner_account_id);
        const flag_type = currentConversationDetail.hasOwnProperty('flag') ? parseInt(currentConversationDetail.flag || 0) : 0;

        const deleteOptionHtml = <Dropdown.Item onClick={ () => props.deleteMesage( messageData ) }>delete</Dropdown.Item>

        if (  message_type===12 ){
            if ( owner_account_id!==ownerIdConversation ){
                returnHtml = deleteOptionHtml;
            }

        }else if (  restrictionMessageTypesArray.includes(message_type) ){  
            returnHtml = '';

        }else{

            let dropdownItemDownload = '';

            if (  [3,4].includes(message_type) ){  
                dropdownItemDownload = <Dropdown.Item href={messageData.message} download >download</Dropdown.Item>
            }

            returnHtml =  <Fragment>
                                
                                { flag_type!==5 && <Dropdown.Item onClick={ () => props.setupReplyContent( messageData ) }>reply</Dropdown.Item> }
                                {/* <Dropdown.Item onClick={ () => props.forwardMesageOption( messageData ) }>forward</Dropdown.Item>
                                <Dropdown.Item onClick={ () => props.deleteMesage( messageData ) }>delete</Dropdown.Item> */}
                                <Dropdown.Item onClick={ () => toggleMultipleSelection() }>forward</Dropdown.Item>
                                { dropdownItemDownload }
                                <Dropdown.Item onClick={ () => toggleMultipleSelection() }>delete</Dropdown.Item>

                            </Fragment>
                                
        }

        if ( ! Utilities.isEmpty( returnHtml ) ){
            returnHtml = <Dropdown className="btn-message-option">
                                <Dropdown.Toggle variant="dropdown-icon btn-message-option-icon"></Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-chat-option message-option-list">
                                    { returnHtml } 
                                </Dropdown.Menu>
                            </Dropdown>
        }

        return returnHtml;
    }

    const requestActionHtml = (messageData) => {

        let returnHtml = '';
        const message_type = parseInt(messageData.message_type);
        const owner_account_id = parseInt(currentConversationDetail.owner_account_id);

        if ( message_type===12 ){
            if ( owner_account_id===ownerIdConversation ){
                returnHtml = <div className="invitation-option-wrap">
                                <button type="button" className="btn-theme-black" onClick={ () => props.acceptRejectRequest(2) }>reject</button>
                                <button type="button" className="btn-theme-black" onClick={ () => props.acceptRejectRequest(1) }>accept</button>
                            </div>
            }else{
                returnHtml = <div className="invitation-option-wrap">
                                <button type="button" className="btn-theme-black" onClick={ () => props.acceptRejectRequest(3) }>remove</button>
                            </div>
            }
        }

        return returnHtml;
    }


    const transferChatMessageHtml = ( key, messageData ) => {

        const message_type = parseInt(messageData.message_type);
        const message_account_id = messageData.account_id.toString();
        const message_content = messageData.message.toString();

        let messageContentArray = message_content.split('|');
        messageContentArray = messageContentArray.filter((a) => a);

        const profile_name = messageContentArray[0];
        const transfer_message = messageContentArray[1];
        let transfer_attachment = [];

        if ( typeof messageContentArray[2]!=='undefined' ){
            transfer_attachment = JSON.parse(messageContentArray[2]);
        }
        
        let isInform = 0;
        let transferLabel = 'to';

        if ( parseInt(loginAccount.account_type)===4 ){
            const company_id = parseInt(loginAccount.company_detail.account_id);
            if ( company_id!==parseInt(message_account_id)){
                isInform = 1;
            }else{

                if ( message_type===33){
                    transferLabel = 'from';
                }
                   
            }
        }else{
            isInform = 1;
        }

        
        return (
            
                isInform===1 ? 
                    <div className="message-item-content text-center pb-4" onClick={ () => messageBoxOption(messageData) }>

                        {
                            message_type===31 ?
                            <Fragment>
                                <div>this matters has been transferred to</div>
                                <div className="font-gotham-medium-18 text-center mb-2">{ profile_name }</div>

                            </Fragment>
                            :
                            <Fragment>
                                <div>hi { loginAccount.name },</div>
                                <div className="my-2">please be inform that your correspondence with</div>

                                <div className="font-gotham-medium-18 text-center mb-2">{ profile_name }</div>

                                <div>has been transferred to me.<br />i will be in touch shortly.</div>
                            </Fragment>
                        }
                         

                    </div>
                :
                    <div className="message-item-content" onClick={ () => messageBoxOption(messageData) }>
                        <div className="text-center">
                            <div>this chat has been transferred {transferLabel}</div>
                            <div className="font-gotham-medium-18">{ profile_name }</div>
                        </div>

                        <div className="my-2">
                            <div className="mb-2">message</div>
                            <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(transfer_message) }}></div>
                        </div>

                        {
                            parseInt(transfer_attachment.length)>0 &&
                            <ul className={`transfer-attachments ${parseInt(transfer_attachment.length)===1 ? 'd-flex' : '' }`}>
                                {
                                    transfer_attachment.map( (attachment, key) => (
                                        <li key={key}><a href={attachment.file} target="_blank" className="ellipsis-1">{ attachment.name }</a></li>
                                    ))
                                }
                            </ul> 
                        }

                    </div>
            
        )
    }

    const userCommunicationRejectConfirmation = () => {
        setShowUserCommunicationRejectPopup( showUserCommunicationRejectPopup ? false : true );
    }

    const communicationRequestAcceptReject = async ( status = 0, messsage = '' ) => {
        status = parseInt(status);

        if ( status>0 ){

            const button_name = status===1 ? 'btn-communication-accept' : 'btn-popup-communication-reject'; //'btn-communication-reject';
            Utilities.elementDisabledStatus(button_name, true );
            
            const passval = {
                api_token : TOKEN_CHAT.toString(),
                account_id : loginAccount.account_id.toString(),
                conversa_id : conversID.toString(),
                flag : status.toString(),
                connect_reason : messsage.toString()
            }

            const responseDB = await QueryChatUtilities.acceptRejectAccessCommunication(passval);

            Utilities.elementDisabledStatus(button_name, false );
            //console.log(responseDB);
            setShowUserCommunicationRejectPopup( false );

            // UPDATE CONVERSATION DETAIL
            //if ( status===1 ){
                const passValues = { api_token: TOKEN_CHAT.toString(),  account_id: loginAccount.account_id.toString(), convers_id : conversID.toString(), flag : "1"  };
                const conversDetail = await QueryChatUtilities.getConversationDetail(passValues);
                if ( ! RM.isEmpty(conversDetail) ){
                    if ( parseInt(conversDetail.success)===1 ){
                            const currentConversationDetailTemp = conversDetail.result.bizchat_conversation.conversation_list ?? {};
                            if ( Object.entries(currentConversationDetailTemp).length>0 ){
                                ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 6);

                                dispatch( passConversDetailReducer(currentConversationDetailTemp) );
                            }
                    }
                }

            //}

            jQuery('.message-communication-request-button').remove();
        }
    }

    
    const communicationRequestMessageHtml = ( key, messageData ) => {

        const message_type = parseInt(messageData.message_type);
        const message_account_id = parseInt(messageData.account_id);
        const message_content = messageData.message.toString();

        let htmlProvided = '';
        const headerLabelHtml = <div className="font-gotham-medium-12 text-center">{ Utilities.labelCommnicationRequest }</div>

        if ( message_type===41){
            const message_array = JSON.parse(message_content);
            const buttoOption = <div className="button-group-boolean message-restriction-button message-communication-request-button button-group-3 pt-4 col-md-9 mx-auto">
                                    <button type="button" id="btn-communication-reject" className="btn-theme-black button-has-loader" onClick={ () => userCommunicationRejectConfirmation() }>reject</button>
                                    <button type="button" id="btn-communication-accept" className="btn-theme-black button-has-loader" onClick={ () => communicationRequestAcceptReject(1) }>accept</button>
                                </div>
            htmlProvided = <div>
                            { headerLabelHtml }
                            {
                                ! Utilities.isEmpty(message_array.reason) &&
                                <>
                                    <div className="text-color-blue mt-2">reason</div>
                                    <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(message_array.reason) }}></div>
                                </>
                            }
                            { ( parseInt(message_array.button_status)===1 && message_account_id!==parseInt(loginAccount.account_id) ) && buttoOption }
                        </div>

        }else if ( message_type===42){
            htmlProvided =  <div>
                                { headerLabelHtml }
                                <div className="d-flex-center py-1"><div className="label-request-accepted font-gotham-medium-14">accepted</div></div>
                            </div>; 
        }else if ( message_type===43){

            const reasonMessage = message_content.split('{').length>1 ? JSON.parse(message_content).reason.toString() : '';

            htmlProvided =  <div>
                                { headerLabelHtml }
                                <div className="d-flex-center py-1"><div className="label-request-rejected font-gotham-medium-14">rejected</div></div>
                                {
                                    ! Utilities.isEmpty(reasonMessage) &&
                                    <>
                                       <div className="text-color-blue mt-2">reason</div>
                                       <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(reasonMessage) }}></div>
                                    </>
                                }
                            </div>; 
        }   

        return htmlProvided;
    }

    const teamRestrictionConfirmationHtml = ( key, messageData ) => {

        const message_type = parseInt(messageData.message_type);
        const message_account_id = parseInt(messageData.account_id);
        const message_content = messageData.message.toString();
        
        //let profileName = message_account_id!==parseInt(loginAccount.account_id) ? loginAccount.name : passChatProfile.name;
        let htmlProvided = '';

        const headerLabelHtml = <div className="font-gotham-medium-12 text-center">{ Utilities.labelCommnicationRequest }</div>

        if (  message_type===37 ){
            const message_array = JSON.parse(message_content);
            const buttoOption = <div className="button-group-boolean message-restriction-button button-group-3 pt-4 col-md-9 mx-auto">
                                    <button type="button" id="btn-restrict-reject" className="btn-theme-black button-has-loader" onClick={ () => teamRestrictionRejectConfirmation() }>reject</button>
                                    <button type="button" id="btn-restrict-accept" className="btn-theme-black button-has-loader" onClick={ () => teamRestrictionStatusProper(1) }>accept</button>
                                </div>
                                        
            htmlProvided = <div>
                                { headerLabelHtml }
                                {
                                    message_account_id===parseInt(loginAccount.account_id) &&
                                        <div className="d-flex-center py-1"><div className="label-waiting-reply font-gotham-medium-14">{ Utilities.labelAwaitingReply }</div></div>
                                }
                                
                                {
                                    ! Utilities.isEmpty(message_array.reason) &&
                                    <>
                                        <div className="text-color-blue mt-2">reason</div>
                                        <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(message_array.reason) }}></div>
                                    </>
                                }
                                { ( parseInt(message_array.button_status)===1 && message_account_id!==parseInt(loginAccount.account_id) ) && buttoOption }
                            </div>

        }else if (  message_type===38 ){
            htmlProvided =  <div>
                                { headerLabelHtml }
                                <div className="d-flex-center py-1"><div className="label-request-accepted font-gotham-medium-14">accepted</div></div>
                            </div>; 


        }else if (  message_type===39 ){

            const reasonMessage = message_content.split('{').length>1 ? JSON.parse(message_content).reason.toString() : message_content;

            htmlProvided = <div>
                                { headerLabelHtml }
                                <div className="d-flex-center py-1"><div className="label-request-rejected font-gotham-medium-14">rejected</div></div>
                                {
                                    ! Utilities.isEmpty(reasonMessage) &&
                                    <>
                                       <div className="text-color-blue mt-2">reason</div>
                                       <div dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(reasonMessage) }}></div>
                                    </>
                                }
                            </div>
        }
        
        return htmlProvided;
    }

    const teamRestrictionRejectConfirmation = () => {
        setShowTeamRestrictionRejectPopup( showTeamRestrictionRejectPopup ? false : true );
    }

    const teamRestrictionStatusProper = async ( status = -1, reason = '' ) => {
        
        if ( status===-1){ return false; }

        const passValuesRes = {
            api_token : TOKEN_CHAT.toString(),
            account_id : loginAccount.account_id.toString(),
            conversa_id : conversID.toString(),
            flag : status.toString(),
            reason : reason.toString()
        };

        const button_name = status===1 ? 'btn-restrict-accept' : 'btn-confirm-team-rejection';
        Utilities.elementDisabledStatus(button_name, true );

        const responseDB = await QueryChatUtilities.acceptRejectAccessTeamCommunication(passValuesRes);
        Utilities.elementDisabledStatus(button_name, false );
        setShowTeamRestrictionRejectPopup( false );
        
        if ( Object.entries(responseDB).length===0 ){ 
            Utilities.messagePopup('error', errorMsg );
            return false; 
        }

        if ( parseInt(responseDB.success)===0 ){ 
            Utilities.messagePopup('error', responseDB.errorMessage );
            return false; 
        }

        ulConversationElem.find('>li .message-restriction-button').remove();

        //if ( status===1 ){
            /* const currentConversationDetail = ChatUtilities.getAccountConversationDetail( conversID );

            let currentConversationDetailTemp = {  ...currentConversationDetail, access_status : '1' };
            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);

            props.setCurrentConversationDetail(currentConversationDetailTemp); */

            const conversDetail = await QueryChatUtilities.getConversationDetail({
                api_token: TOKEN_CHAT.toString(),
                account_id : loginAccount.account_id.toString(),
                convers_id : conversID.toString(),
                flag : "1"
            });
            if ( ! RM.isEmpty(conversDetail) ){
                if ( parseInt(conversDetail.success)===1 ){
                    const currentConversationDetailTemp = conversDetail.result.bizchat_conversation.conversation_list;
                    ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 7);

                    dispatch( passConversDetailReducer(currentConversationDetailTemp) );
                }
            }

        //}
    }

    const showMessageStartHere =  ( docDateId = 0 ) => {
        if ( docDateId>0 && isSearch===true && typeof props.showMessagesFromSearch!=='undefined' ){
            props.showMessagesFromSearch(docDateId);
        }
    }
    
    const htmlMessage = ( key, messageData ) => {
        
        //console.log(messageData);
        const conversFlag = parseInt(currentConversationDetail.flag ?? 0) ?? 0;
        const conversAccessFlag = parseInt(currentConversationDetail.access_flag ?? 0) ?? 0;
        const message_type = parseInt(messageData.message_type);
        
        if ( dontShowMessageTypes.includes(message_type) ) { return false; }
        
        let messageHtmlData = ChatUtilities.formatMessageHTML( ownerIdConversation, messageData, 1, loginAccount);
        const readReplyStatus = parseInt(messageData.reply_message_status || 0);
        //const currentLoginId = parseInt(loginAccount.account_id);
        let mainMesageHtml  = messageHtmlData.html_message;


        let messageReplyHTML = '', messageForwardHTML = '', messageAutoReplyHTML = '';
        let class_extra = [], class_extra_li = [], showMessageTime = true;
        
        const formTypesArray = ChatUtilities.formTypes();
        const formTypesKeysArray = Object.keys(formTypesArray);
        const li_groups_class = 'justify-content-center opacity-075 text-center pt-1 other';


        if ( readReplyStatus===1 ){
            const messageReplyData = messageData.reply_quote_details;
            const messageReplyHtmlData = ChatUtilities.formatMessageHTML( ownerIdConversation, messageReplyData, 1);
            messageReplyHTML = messageReplyHtmlData.html_message;

            if ( parseInt(messageReplyData.message_type)===2 ){
                const filename = messageReplyData.message.split('/').pop();
                messageReplyHTML = '<div class="d-flex align-items-center">'+messageReplyHTML+'<div class="ml-2 cut-text">'+filename+'</div></div>';
            }
        }else if ( readReplyStatus===2 ){
            messageForwardHTML = <div className="message-forwarded-label">forwarded</div>
        }

        // conversFlag : 6 - Company Groups
        // conversAccessFlag : 1-admin, 2-team members, 3-members



        if ( conversFlag===6 && ![15,16,17,18,20].includes(message_type) ){ // 19, 21
            const messageMemberList = messageData.members_list ?? "";

            if ( ! Utilities.isEmpty(messageMemberList) ){
                const memberListArr = messageMemberList.split(",");
                const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
               
                if ( ! memberListArr.includes( (userLoginDetail.account_id ?? '').toString() ) ){
                    messageHtmlData = {};
                }
            }
        }


        if ( [15,16].includes(message_type) ){
            if ( conversFlag===6 ){
                class_extra_li.push(li_groups_class);
                showMessageTime = false;

                if ( message_type==16 && ![1].includes(conversAccessFlag) ){
                    messageHtmlData = {};
                }
            }

        }else if ( message_type===17 ){
            if ( parseInt(loginAccount.account_id)===parseInt(messageData.account_id) ){
                messageHtmlData = {};
            }

            if ( conversFlag===6 ){
                class_extra_li.push(li_groups_class);
                showMessageTime = false;

                if ( ![2].includes(conversAccessFlag) ){
                    messageHtmlData = {};
                }
            }
            
        }else if ( message_type===18 ){
        

            if ( conversFlag===6 ){
                class_extra_li.push(li_groups_class);
                showMessageTime = false;

                if ( ![3].includes(conversAccessFlag) ){
                    messageHtmlData = {};
                }

            }else{
                class_extra.push('message-warning');
                class_extra_li.push('message-transfer-wrap');
                mainMesageHtml = mainMesageHtml.replace('Gotham, Medium', "Gotham Medium");
            }

        }else if ( message_type===19 ){
            
            if ( ![6].includes(conversFlag) ){
                class_extra.push('message-success');

            }else{

                /* messageHtmlData = {};
                class_extra_li.push(li_groups_class);
                showMessageTime = false; */
            }
            
            if ( parseInt(messageData.account_id)===4 ){
                class_extra_li.push('message-transfer-wrap');
            }

            mainMesageHtml = mainMesageHtml.replace('Gotham, Medium', "Gotham Medium");

        }else if ( message_type===21 ){

            // COMPANY GROUPS - Request to join ( admin only )
            if ( conversFlag===6 && ![1].includes(conversAccessFlag) ){
                messageHtmlData = {};
            }


        }else if ( message_type===31 ){
            class_extra.push('transfer-message');
            class_extra.push('transfer-warning');

        }else if ( message_type===33 ){
            class_extra.push('transfer-message');
            class_extra.push('transfer-success');

        }else if ( message_type===35 ){

            messageAutoReplyHTML = <div className="message-autoreply-label">auto reply</div>
            if ( parseInt(loginAccount.account_id)===parseInt(messageData.account_id) ){
                messageHtmlData = {};
            }
        }

        // COMPANY GROUPS CHAT
        if ( conversFlag===6 && ( !messageData.hasOwnProperty('account_name') || !messageData.hasOwnProperty('members_list') ) ){
            messageHtmlData = {};
        }
        // END COMPANY GROUPS CHAT

        
        let class_forms = '', class_forms_wrap = [];
        let id_forms = '';

        if ( formTypesKeysArray.includes(message_type.toString()) ){
            //class_forms = formTypesArray[message_type];
            class_forms_wrap.push('message-department-form');
            
            if ( ![6].includes(conversFlag) ){
                const messageFormSplitted = messageData.message.toString().split('|');
                const msgFormId = messageFormSplitted[0];

                if ( ChatUtilities.departmentFormsTypes.includes( message_type ) ){
                    id_forms = 'message-form-type-'+msgFormId.toString();             
                }

            }else{
                class_forms_wrap.push('bg-white ml-0');
            }
            
        }
       
        if ( restrictionMessageTypesArray.includes(message_type) ){
            class_forms_wrap.push('message-team-restriction');
        }

        if ( communicationMessageTypeArray.includes(message_type) ){
            class_forms_wrap.push('message-communication-request');
        }
        

        let hasDateSeparator = '', messageMessengerProfileHtml = '';;
        
        if (  Object.keys(messageHtmlData).length>0 ){
            if ( messageData.hasOwnProperty('date') &&  ! Utilities.isEmpty( messageData.date ) && typeof  messageData.date!=='undefined' &&  messageData.date!==null ){

                const localTime  = moment.utc(messageData.date);  
                let local_date = moment(new Date(localTime)).format('DDMMMYY').toLocaleString(); 
                local_date = local_date.toLowerCase();
            
                let messageDate = moment(new Date(localTime)).format(Utilities.dateFormatStr.toString()).toLocaleString();
    
                if ( ! conversationsDate.includes(local_date)  ){
        
                    const sameDay = moment(messageData.date).isSame(moment(), 'day');
                    if(sameDay===true) {  messageDate = 'today'; }

                    hasDateSeparator = <li className="message-date-separator"><span>{messageDate}</span></li>
                    conversationsDate.push(local_date);
                }
 
                if ( isSearch===true ){
                    hasDateSeparator = messageDate;
                }
            }


            if ( [2,6].includes(conversFlag) ){

                let senderLogo = defaultProfileLogo, senderName = '', accountNameAndLogoArray = [];

                if ( parseInt(workAccount.account_id ?? 0)===parseInt(messageData.account_id) ){
                    senderName = 'you';//loginAccount.name;
                    senderLogo = workAccount.logo_url;

                }else{
                    if ( typeof messageData.account_name!=='undefined' ){

                        accountNameAndLogoArray = messageData.account_name.split('|');
    
                        if ( accountNameAndLogoArray.length===1 ){
                           senderLogo = accountNameAndLogoArray[0].split('.').length>1 ? accountNameAndLogoArray[0] : defaultProfileLogo;
    
                        }else{
                            senderLogo = accountNameAndLogoArray[1].split('.').length>1 ? accountNameAndLogoArray[1] : defaultProfileLogo;
                            senderName = accountNameAndLogoArray[0];
                        }
    
                        if ( typeof senderLogo.split('/').pop()==='undefined' ){
                            senderLogo = defaultProfileLogo;
                        }
                        
                    }
                }

                //console.log(loginAccount, messageData);
                messageMessengerProfileHtml = <>
                                                <div className="sender-profile-logo" style={{ backgroundImage: `url(${senderLogo})` }}></div>
                                                { ! Utilities.isEmpty(senderName) ? <div className="sender-profile-name"><div>{senderName}</div></div> : '' }
                                            </>

                if ( [15,17].includes(message_type) && [6].includes(conversFlag) ){
                    messageMessengerProfileHtml = '';
                }


                // this is when no "|" in the account name
                if ( [6].includes(conversFlag) ){
                    accountNameAndLogoArray = messageData.account_name.split('|');
                    if ( accountNameAndLogoArray.length===1 ){
                        messageMessengerProfileHtml = '';

                        class_extra_li.push(li_groups_class);
                        showMessageTime = false;
                    }
                }
            }
        }
        
        
        let outputHtml = Object.keys(messageHtmlData).length>0 && 
                            <Fragment key={key}>

                                { isSearch===false && hasDateSeparator  }

                                <li key={key} id={ messageData.doc_id } className={ `fade-in ${ messagesIdSelected.includes( parseInt(messageData.doc_id) ) ? 'selected' : '' } ${messageHtmlData.class_names} ${class_extra_li.join('') }`} onClick={ () => showMessageStartHere( parseInt(messageData.doc_id) ) }>
                                    
                                    {
                                        multipleOptionArray.includes(message_type) &&
                                            <div className="checkbox-message-option"><input type="checkbox" value={ messageData.doc_id } onChange={ () => selectMessage( messageData ) } checked={ messagesIdSelected.includes( parseInt(messageData.doc_id) ) ? 'checked' : '' }  ></input></div>
                                    }

                                    <div className={`message-wrap ${class_forms_wrap.join(' ')} ${messageHtmlData.class_welcome + ' ' + class_extra.join(' ') } ` }>

                                        { messageForwardHTML }
                                        { messageAutoReplyHTML }
                                        { messageMessengerProfileHtml }

                                        {
                                            transferTypesArray.includes(message_type) &&
                                                <div className="transfer-header">chat transfer</div>
                                        }

                                        {
                                            ( 
                                                message_type>0 &&  
                                                ! excludedOptionArray.includes(message_type) && 
                                                ! formTypesKeysArray.includes(message_type.toString()) &&
                                                isSearch===false
                                            ) && 
                                                menuOptionHtml( messageData )
                                        }
                                        
                                        {   readReplyStatus===1 && <div className="message-reply-wrap" dangerouslySetInnerHTML={{ __html: messageReplyHTML }}></div> }

                                        <div>
                                            {
                                                ! formTypesKeysArray.includes(message_type.toString()) ?
                                                <>
                                                {
                                                    ! transferTypesArray.includes(message_type) ?
                                                    <>
                                                    {
                                                        parseInt(message_type)===2 ?
                                                            <div className="message-item-content" onClick={ () => messageBoxOption(messageData) }>
                                                                <img src={messageData.message.toString()} width="200" />
                                                            </div>
                                                        :
                                                            <>
                                                            {
                                                                restrictionMessageTypesArray.includes( message_type ) ?
                                                                   teamRestrictionConfirmationHtml( key, messageData)
                                                                :
                                                                <>
                                                                {
                                                                    communicationMessageTypeArray.includes( message_type ) ?
                                                                        communicationRequestMessageHtml( key, messageData)
                                                                    :
                                                                        <div className="message-item-content" onClick={ () => messageBoxOption(messageData) } dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(mainMesageHtml) }}></div>
                                                                }
                                                                </>
                                                            }
                                                            </>
                                                    }
                                                    </>
                                                    : 
                                                        transferChatMessageHtml( key, messageData)
                                                }
                                                </>
                                                :
                                                    <div id={id_forms} className={`message-item-content message-form-types ${class_forms} `}>
                                                        {  conversFlag===6 ? companyGroupsHtmlContent(messageData, messageHtmlData ) : formsHtmlContent(messageData, messageHtmlData ) }
                                                    </div>
                                            }
                                        

                                            {  requestActionHtml(messageData) }
                                            
                                            
                                            { showMessageTime && <div className="message-time"><span>{ isSearch===true && hasDateSeparator } { messageHtmlData.message_time }</span></div> }

                                        </div>

                                    </div>

                                </li>
                            </Fragment>

        if ( Object.keys(messageHtmlData).length>0 ){
            if ( message_type===19 && parseInt(messageData.account_id)===parseInt(currentConversationDetail.account_type)){
                outputHtml = '';
            }
        }
       
        //ChatUtilities.scrollBottomConversation();
        return outputHtml;
    }


    const scrollToBottom = () => {
        const timer = setTimeout( () => {
            ChatUtilities.scrollBottomConversation();
            clearTimeout(timer);
        }, 100);
    }


    useEffect( () => {
        setPreviousConversations([]);
        setPresentConversations([]);
        setShowLoadingMessages(true);

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                setLoginAccount(loginProfileInfo);
            }
        }

    }, []);


    useEffect( () => {
        setOwnerIdConversation( props.ownerIdConversation );
        //setCurrentConversationDetail(props.currentConversationDetail);

        if ( 'previousConversations' in props && parseInt(props.previousConversations.length)>0 ){
            setPreviousConversations( props.previousConversations );
        }

        if ( 'presentConversations' in props && parseInt(props.presentConversations.length)>0 ){
            setPresentConversations( props.presentConversations );
        }

        //setShowLoadingMessages(false);

    }, [props] );



    useEffect( async () => {
        if ( 'presentConversations' in props && parseInt(props.presentConversations.length)>0 ){
            scrollToBottom();
            ChatUtilities.removeDuplicateMessages();
           
        }

        // MARK AS READ 
        /* if ( ! Utilities.isEmpty(props.requestProfile) ){
            await ChatUtilities.markMessagesRead( parseInt(props.requestProfile.account_id), parseInt(props.conversID) );
        } */

    }, [props.presentConversations] );


    useEffect( () => {
        if ( ! Utilities.isEmpty(props.bolClearSelectedMessages) ){
            setSelectedMessagesDetail([]);
            props.messagesSelectedDetail([]);
        }
    }, [props.bolClearSelectedMessages] );
    

    useEffect( () => {
        const conversIDTemp = props.conversID;
       
    
        if ( parseInt(conversIDTemp)>0 ){
            setConversID(conversIDTemp);

            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( conversIDTemp );
        
            if ( parseInt(currentConversationDetailTemp.flag)===2 ){

                const accountAssociated = PagesAuthentication.getUsersAssociated();

                let conversValues = props.match.params.coversid;
                let encodedValues = ChatUtilities.decryptChatDetail(conversValues);

                if ( encodedValues.hasOwnProperty('internal_account_id') && parseInt(encodedValues.internal_account_id)>0 ){
                    const teamWorkProfile = Object.values(accountAssociated).filter( row => parseInt(row.account_id)===parseInt(encodedValues.internal_account_id) )[0] ?? {};
                    setWorkAccount(teamWorkProfile);
                }
            
            }
            setCurrentConversationDetail(currentConversationDetailTemp);
            ChatUtilities.globalCurrentConversationDetail = currentConversationDetailTemp;

            setShowLoadingMessages(false);
        }

    }, [props.conversID] );


    useEffect( () => {

        const bolUncheckSelectedMessages = props.bolUncheckSelectedMessages;
        if ( bolUncheckSelectedMessages===true ){
            setMessagesIdSelected([]);
            props.messagesSelectedDetail([]);
        }
    
    }, [props.bolUncheckSelectedMessages ]);


    return (
       <Fragment>
           
            { showLoadingMessages===true && <div className="content-loader animate"></div> }

            { 
                ( 'previousConversations' in props ) && 
                    Object.entries(previousConversations).map( ([key, row]) => htmlMessage(key, row ) )
            }

            { 
                ( 'presentConversations' in props ) &&
                    Object.entries(presentConversations).map( ([key, row]) => htmlMessage(key, row ) )
            }
            
            <ChatTeamRestrictionRejection showPopup={ showTeamRestrictionRejectPopup } handleTeamRestrictionStatusProper={ teamRestrictionStatusProper } handleTeamRestrictionRejectConfirmation={ teamRestrictionRejectConfirmation } />
            
            <ChatUserCommunicationRejection showPopup={ showUserCommunicationRejectPopup } handleUserCommunicationStatusProper={ communicationRequestAcceptReject } handleUserCommunicationRejectConfirmation={ userCommunicationRejectConfirmation } />

       </Fragment>
    )
}

export default ChatConversation;
