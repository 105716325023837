import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as RM from 'ramda';


import Utilities from './../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

const BreadcrumbInternal = ( props ) => {

    const internalChatLabel = TitlesLabels.siteTitles.internalChat.toLowerCase();

    const [ associatedAccountsCounter, setAssociatedAccountsCounter] = useState(-1);
    const [ associatedAccounts, setAssociatedAccounts ] = useState([]);
    const [ internalTotalCounter, setInternalTotalCounter ] = useState(0);
    const [ showLabel, setShowLabel ] = useState(true);

    useEffect( () => {
        
        const allConversationResults = props.allConversationResults ?? {};
        if ( Object.keys(allConversationResults).length>0 ){
            setInternalTotalCounter( parseInt(allConversationResults.internal_chat_count || 0) );

            let internal_chat_list = allConversationResults.internal_chat_list;

            if ( internal_chat_list.length>0 ){
                if ( 'title_name' in internal_chat_list[0] ){
                    //const byName = RM.ascend(RM.prop('title_name')); // key sensitve
                    const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('title_name')));
                    internal_chat_list = RM.sort(byName, internal_chat_list);
                }
            }
            setAssociatedAccounts(internal_chat_list);
            setAssociatedAccountsCounter(internal_chat_list.length);
        }

    }, [props.allConversationResults]);

    
    useEffect( () => {
        const showLabelTemp = props.showLabel;
        if ( typeof showLabelTemp !== 'undefined' ){
            setShowLabel(showLabelTemp);
        }
    }, [ props.showLabel ]);

    
    return (
        <Fragment>
            {
                parseInt(associatedAccountsCounter)===-1 && <div  className="btn-icon btn-group-people opacity-05 processing cursor-wait" title="internal chat"></div>
            }
            {
                Object.keys(associatedAccounts).length>1  ? 
                    <Dropdown className="dropdown-user-selection dropdown-internal-selection">
                        <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                            <div className="btn-icon btn-group-people" title={`${internalChatLabel}`}>
                                { parseInt(internalTotalCounter)>0 && <span className="counter">{ Utilities.counterMore(internalTotalCounter) }</span> }
                            </div>
                            { showLabel===true && <span className="ml-2">{internalChatLabel}</span> }
                        </Dropdown.Toggle>
        
                        <Dropdown.Menu >
                            { showLabel===false && <div className="dropdown-header">{internalChatLabel}</div> }
                            {
                                Object.entries(associatedAccounts).map( ([key, row] ) => 
                                    <Link to={`/${ UrlSlugs.internalChat }/${ btoa( parseInt(row.account_id ) ) }`} key={key} className="item-user-selection dropdown-item" role="button">
                                        <div>
                                            <div className={`profile-logo`}  style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, borderRightColor : Utilities.bgcolorHideInitialBg(row.logo)  }} >{ Utilities.showHideInitial(row.logo, row.name) }</div>
                                            <div>
                                                <div className="profile-name">{ row.title_name }</div>
                                                <div className="ellipsis-1">{ row.name }</div>
                                            </div>
                                            { parseInt(row.chat.count)>0 && <span className="counter  counter-styled"><em>{ Utilities.counterMore(row.chat.count) }</em></span> }
                                        </div> 
                                    </Link>
                                )
                            }
                            
                        </Dropdown.Menu>
                    </Dropdown>
                :
                    Object.entries(associatedAccounts).map( ([key, row] ) =>
                        <Link to={`/${ UrlSlugs.internalChat }/${ btoa( parseInt(row.account_id ) ) }`} key={key}  role="button">
                            <div  className="btn-icon btn-group-people" title="internal chat"> { parseInt(internalTotalCounter)>0 && <span className="counter">{ Utilities.counterMore(internalTotalCounter) }</span> }</div>
                            { showLabel===true && <span className="ml-2">internal chat</span> }
                        </Link>
                    )

            }
           
        </Fragment>
    )
}

export default BreadcrumbInternal;
