import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios'

import Utilities from '../helpers/Utilities';
import { uploadProfileLogo } from '../helpers/AWSUpload';

import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';

const PersonalUploadLogo = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;

    const [ emailAddress, setEmailAddress ] = useState('');
    const [ passwordUser, setPasswordUser ] = useState('');
    const [ logoProfile , setLogoProfile] = useState('');
    const [ logoProfileValue , setLogoProfileValue] = useState('');
    const [ accountID , setAccountID] = useState(0);
    
    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;
                
                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfileValue(e.target.result);
                    //setLogoProfile(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setLogoProfile(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }
    

    const sendJazenetMessage = ( profile ) => {
        dispatch( showLoader(1) );
        
        axios
        .post( ApiUrls.bizchatCreateBizcomConversation , { api_token: TOKEN.toString() ,  account_id : profile.account_id.toString() } )
        .then( response => {
            dispatch( showLoader(0) );
            history.push(`/${UrlSlugs.dashboard}`);

        })
        .catch( (err) => {
            dispatch( showLoader(0) );
            history.push(`/${UrlSlugs.dashboard}`);
        });
    }


    const loginUser = () => {
        if ( !Utilities.isEmpty(emailAddress) && !Utilities.isEmpty(passwordUser) ){
            dispatch( showLoader(1) );
            
            const windowNavigator = window.navigator;

            axios
            .post( ApiUrls.userGetByCredentail, { email: emailAddress,  password : passwordUser, browser_name: windowNavigator.appVersion.toLowerCase()}  )
            .then( response => {

                dispatch( showLoader(0) );
                const resStatus = response.status;
                if ( resStatus===200 ){

                    const resData = response.data;
                    const resLoginType = parseInt(resData.logintype);
                    
                    if ( parseInt(resData.status)===1){

                        //if ( parseInt(resData.detail.status)===1 ){

                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );

                            localStorage.setItem( process.env.REACT_APP_session_welcome_mesage, 1 );

                            sendJazenetMessage( resData.detail );
                            //history.push('/' + UrlSlugs.dashboard );
                            
                        /* }else{
                           Utilities.messagePopup('error', 'Account is not active!'  );
                        } */
                        
                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                } 
               
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                dispatch( showLoader(0) );
            });
        }

    }

    const submitProfileImage = async (e) => {
        e.preventDefault();
        
        const logo_value = document.getElementById('input_select_file').value;
        
        if ( Utilities.isEmpty(logo_value) ||  Utilities.isEmpty(logoProfile) ){
            Utilities.messagePopup('error',  TitlesLabels.alertMessages.imageMandatory );
            return false;
        }

        if ( accountID===0 ){ return false;   }

        dispatch( showLoader(1) );

        const formLogoData = new FormData();
        formLogoData.append('account_id', accountID);
        formLogoData.append('file', logoProfile);
        
        const responseUpload = await uploadProfileLogo( formLogoData );
        dispatch( showLoader(0) );

        if ( parseInt(responseUpload.status)===1 ){
            loginUser();
        }else{
            Utilities.messagePopup('error', errorMsg );
        }

    }

    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.profilePhoto ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        let signupPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_signup_personalinfo);
        if ( signupPersonalInfo!==null ){
            signupPersonalInfo = JSON.parse(signupPersonalInfo);
            if ( Object.keys(signupPersonalInfo).length>0 ){
                setEmailAddress(signupPersonalInfo.email);
                setPasswordUser(signupPersonalInfo.password);
                setAccountID(signupPersonalInfo.account_id);
            }
        }

    }, [] );

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
            <SignupHeader title="Profile Photo" />
             {/* <header className="header mb-5 text-center">
                 <div className="logo-wrap">
                     <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                     <div className="logo-type-text mt-2">Profile Photo</div>
                 </div>
             </header> */}

             <form className="col-6 mx-auto pt-1 text-center"  autoComplete="off">
                    <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension } readOnly onChange={ (e) => previewImage(e) } ></input>

                    <div className="avatar-wrap">
                        <div>
                            <div className="avatar-preview rounded-circle mx-auto position-relative overflow-visible" style={{ backgroundImage:  `url(${logoProfileValue})` }} >
                            </div>
                            <button type="button" className="btn-select-file text-lowercase mx-auto position-relative" onClick={ () => selectImage() }>select image</button>
                        </div>
                    </div>
                    <div className="mt-3">{Utilities.labelUploadSpecs}</div>

                    <div className="button-wrap text-lowercase text-center mt-5 pt-5">
                       <button type="button" className="btn-theme-black text-lowercase" onClick={ (e) => submitProfileImage(e) }>Submit</button>
                    </div>
             </form>
             
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_verification}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
    </div>
    )
}

export default PersonalUploadLogo;