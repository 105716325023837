import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import moment from 'moment'; 

import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import ChatUtilities from '../helpers/ChatUtilities';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import DepartmentForms from '../components/popups/DepartmentForms';

import { showMainSearch, showHeader, showFooter, pageTitle, passDocumentDetail, passConversationMessage } from '../actions';



const Departments = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    
    const [ loggedInProfile, setLoggedInProfile ] = useState({});
    const [ listDepartments, setListDepartments ] = useState([]);
    const [ departmentDetail, setDepartmentDetail ] = useState([]);
    const [ companyProfile, setCompanyProfile ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');

    const [ showFormPopUp, setShowFormPopUp ] = useState(false);
    const handleCloseFormPopUp = () => setShowFormPopUp(false);

    // MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = ( department ) => {
        setDepartmentDetail( department );
        setShow(true);
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-departments > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const toggleMainSearch = ( action = 0 ) => {
        dispatch( showMainSearch(action) );
    }

    const getDepartments = ( accountID ) => {

        if ( parseInt(accountID)>0 ){

            // GET COMPANY PROFILE
            axios
            .post( ApiUrls.profilegetByID, { id : accountID} )
            .then( response => {

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);
                    
                    if ( dataStatus===1 ){
                        
                        const company_detail = Utilities.formatProfileDetail(dataResponse.detail);
                        setCompanyProfile(company_detail);

                        dispatch( pageTitle(`${ TitlesLabels.siteTitles.departments } | ${dataResponse.detail.company_name}`) );
                    }
                }
            })
            .catch( (err) => {

            });


            // GET DEPARTMENTS
            axios
            .post( ApiUrls.departmentListByAccountID, { id : accountID, status : 1, show_number : 1 } )
            .then( response => {

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);
                    
                    if ( dataStatus===1 ){
                        if ( Utilities.isEmpty(dataResponse.departments) ){
                            setShowEmpty(true);
                        }else{
                            setShowEmpty(false);
                            setListDepartments(dataResponse.departments);
                        }
                    }else{
                        setShowEmpty(true);
                    }
                }

                setContentLoaderClass('');
            })
            .catch( (err) => {
                setShowEmpty(true);
                setContentLoaderClass('');
            }); 

        }

    }

    const handleConfirmAction = ( form_status = 0 ) => {
        form_status = parseInt(form_status);

        if ( companyProfile.hasOwnProperty('account_id') && departmentDetail.hasOwnProperty('group_id') ){

            let docid = match.params.docid;
            if ( typeof docid!=='undefined' ){
    
                const passValues = {
                    account_id : parseInt(companyProfile.account_id || 0),
                    convers_detail : {},
                    document_id : parseInt(atob(docid)),
                    department_id : parseInt(departmentDetail.group_id || 0)
                }
    
                dispatch( passDocumentDetail( passValues ) );
                handleClose();
                
                return false;
            }

            
            if ( form_status===0 ){
                history.push(`/${ UrlSlugs.chat }/${ btoa( companyProfile.account_id ) }/3/${ btoa(departmentDetail.group_id) }`);
            }else{
                setShowFormPopUp(true);
            }
        }

        setShow(false);
    }


    const getConversationDetail = async () => {

        let conversationDetail = {};
        if ( companyProfile.hasOwnProperty('account_id') && departmentDetail.hasOwnProperty('group_id') ){
            const contactRequestValues = ChatUtilities.contactRequest( loggedInProfile, companyProfile, departmentDetail.group_id  );
            conversationDetail = await axios.post( contactRequestValues.url, contactRequestValues.values );
        }

        return await conversationDetail;
    }

    const handeFormSubmit = async ( formDetail ) => {

        if ( companyProfile.hasOwnProperty('account_id') && departmentDetail.hasOwnProperty('group_id') ){
            
            const button_name = 'btn-submit-form';
            if ( Object.keys(formDetail).length>0 ){
                
                Utilities.elementDisabledStatus(button_name, true );

                const conversationDetail = await getConversationDetail();
                if ( Object.keys(conversationDetail).length===0 ){
                    Utilities.elementDisabledStatus(button_name, false );
                    return false;
                }

                let form_status = parseInt(departmentDetail.form_status || 0);
            
                let subject = '';
                if ( form_status===3 ){
                    subject = formDetail.subject.toString();
                }

                let name = '';
                if ( form_status===1 || form_status===2 ){
                    name = formDetail.name.toString();
                }

                let no_people = '';
                if ( form_status===2 ){
                    no_people = formDetail.no_people.toString();
                }
                
                let requestDate = formDetail.date;
                let requestTime = requestDate.toString()+' '+formDetail.time.toString()+':00';
                
                requestTime = moment(new Date(requestTime.toString())).format('HH:mm:ss').toLocaleString(); 
                requestDate = moment(new Date(requestDate.toString())).format('YYYY-MM-DD').toLocaleString(); 
                 
                const CONVERSATION_DETAIL = conversationDetail.data.result.bizchat_conversation;

                const conversation_list = CONVERSATION_DETAIL.conversation_list;
                const CONVERS_DATA = ChatUtilities.encryptChatDetail(conversation_list); 

                const conversIDGenerated = CONVERSATION_DETAIL.conversation_list.convers_id.toString();

                const account_department_id = loggedInProfile.hasOwnProperty('group_id') ? loggedInProfile.group_id.toString() : '0';
                

                const company_id = parseInt(conversation_list.account_company_id)===0 ? departmentDetail.account_id : conversation_list.account_company_id;
                const passValue = {
                    api_token : TOKEN.toString(),
                    account_id : loggedInProfile.account_id.toString(),
                    account_type : loggedInProfile.account_type.toString(),
                    company_id : company_id.toString(), //conversation_list.account_company_id.toString(), //departmentDetail.account_id.toString(),
                    conversa_id : conversIDGenerated.toString(),
                    department_id : departmentDetail.group_id.toString(),
                    type : form_status.toString(), // 1 : appointment, 2 : reservation, 3 : complaints
                    name : name,
                    subject : subject,
                    date : requestDate.toString(),
                    time : requestTime.toString(),
                    no_people : no_people,
                    remarks : formDetail.remarks.toString(),
                    account_department_id : account_department_id.toString()
                }

                axios
                .post( ApiUrls.bizchatRequestAppointmentComplaint , passValue  )
                .then( response => {

                    Utilities.elementDisabledStatus(button_name, false );

                    if ( parseInt(response.status)===201 ){
                        if ( parseInt(response.data.success)===1 ){
                            ChatUtilities.saveAccountsConversationDetail(conversation_list);
                            history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(conversation_list.account_id) )}/${CONVERS_DATA}`);
                            
                            Utilities.messagePopup('success', response.data.successMessage );
                            handleCloseFormPopUp();

                        }else{
                            Utilities.messagePopup('error', response.data.errorMessage );
                        }
                    }
                })
                .catch( (err) => {
                    Utilities.messagePopup('error', errorMsg );
                    Utilities.elementDisabledStatus(button_name, false );
                
                }); 
                
                
            }else{
                history.push(`/${ UrlSlugs.chat }/${ btoa( companyProfile.account_id ) }/3/${ btoa(departmentDetail.group_id) }`);
            }
        }
    }


    useEffect( () => {

        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);
            
            if ( parseInt(reqID)>0 ){
                setContentLoaderClass('animate');
                setListDepartments([]);

                getDepartments(reqID);
            }
        }

        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);
            setLoggedInProfile(loginBasicInfo);
        }

    }, [match.params.id] );


    useEffect( () => {
        PagesAuthentication.getUserLoggedin();
        let loginType = parseInt(localStorage.getItem(process.env.REACT_APP_session_logintype)??1);

        dispatch( showHeader(loginType===2 ? 5 : 1) );
        dispatch( showFooter(1) );

        dispatch( passConversationMessage({}) );

    }, []);

    return (
        <div className="departments-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div className="cursor-pointer" onClick={ () => toggleMainSearch(1) }>{ TitlesLabels.navigations.search }</div>
                            { companyProfile.hasOwnProperty('company_name') && <div>{companyProfile.company_name}</div> }
                            <div className="current">{ TitlesLabels.navigations.departments }</div>
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() }></div>
                         </li>
                     </ul>
                </div>
            </div>

            <div className="main-content">
                <div className="container">

                        { 
                            ! Utilities.isEmpty(contentLoaderClass) &&
                                <ul className="listing-profiles-preloader mt-4">
                                {
                                    [...Array(12).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                            </ul>
                        }

                        <div className="profile-search-wrap d-none">
                            <div className="profile-search-content">
                                <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                <div></div>
                                <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                            </div>
                        </div>

                        
                        { 
                            Object.entries(listDepartments).length>0 &&
                                <ul id="listing-departments" className={`listing-profiles max-height-fit  my-4 ${ Object.keys(listDepartments).length===0 ? 'd-none' : '' } `}>
                                {
                                    Object.entries(listDepartments).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.department_name} onClick={ () => handleShow(row) } className="cursor-pointer">
                                            <div className="profile-wrap">
                                                <div className="profile-image-wrap">
                                                    <div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(Utilities.formatProfileDetail(companyProfile).img) })`, backgroundColor: Utilities.bgcolorHideInitialBg( Utilities.formatProfileDetail(companyProfile).img )  }}>{ Utilities.showHideInitial( Utilities.formatProfileDetail(companyProfile).img , companyProfile.name) }</div>
                                                    </div>
                                                    {/* <div className={`profile-image bg-black-theme`}>{ Utilities.showHideInitial( Utilities.formatProfileDetail(companyProfile).img , companyProfile.name) }</div>
                                                    </div>  */}
                                                <div className="profile-name" data-name={row.department_name}>{row.department_name}</div>
                                            </div>
                                        </li>
                                    )
                                }
                                </ul>
                        }

                        { showEmpty && <div className="text-center text-lowercase no-record-label">{ TitlesLabels.general.noDepartmentAvailable }</div>  }

                        
                </div>
            </div>

            <Modal  show={show} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="lg" >
                <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
                <Modal.Body>
                        
                    <div className="profile-selected-assoc text-center text-lowercase">
                        
                        <div className="col-6 mx-auto">
                            <div className="profile-header text-left mb-4">
                                <div className={`profile-image ${ ( ( companyProfile.hasOwnProperty('plus_status') && parseInt(companyProfile.plus_status || 0)===1 ) ? 'badge-plus' : '') }`} style={{ backgroundImage:  `url(${companyProfile.logo})`  }}></div>
                                <div>
                                    <div className="profile-name">{companyProfile.name}</div>
                                    <div className="profile-label">{departmentDetail.department_name}</div>
                                </div>
                            </div>
                            
                            <div className="profile-address mb-5">
                                <div>{companyProfile.address_1}</div>
                                <div>{companyProfile.address_2}</div>

                                { ! Utilities.isEmpty(departmentDetail.contact_no) && <div className="font-gotham-book-16 mt-2">{departmentDetail.contact_no}</div> }
                                
                            </div>

                            <div className="mb-5">
                                <button type="button" onClick={ () => handleConfirmAction(departmentDetail.form_status) } className="btn-theme-black" >confirm</button>
                           </div>

                        </div>

                    </div>

                </Modal.Body>
            </Modal>

            <DepartmentForms departmentDetail={departmentDetail} handeFormSubmit={handeFormSubmit} handleCloseFormPopUp={handleCloseFormPopUp} showFormPopUp={showFormPopUp} action="create" timeStamp={ new Date() }  />

       </div>
    )
}

export default Departments;