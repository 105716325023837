import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; 

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import UrlSlugs from '../../helpers/UrlSlugs';

import { showLanguageSelectionPopup, changeCityPopup as changeCityPopupAction, showDepartmentSettings as showDepartmentSettingsReducer, toggleDepartmentDefaultMessage as toggleDepartmentDefaultMessageReducer, toggleDepartmentForms as toggleDepartmentFormsReducer, showLoader as showLoaderReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';
import { getReceiverAccountAndDeptReceiverIds } from '../../helpers/QueryDiary';
import QueryUtilities from '../../helpers/QueryUtilities';


const SettingDepartmentPopup = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const siteTitle = process.env.REACT_APP_site_title; 
    
    const showDepartmentSettings = useSelector( state => state.showDepartmentSettings);
    const passConversList = useSelector( state => state.passConversList);

    const counterDefault = { appointment : 0 };

    const [ divertReplyInfo, setDivertReplyInfo ] = useState({});
    const [ loggedinAccount, setLoggedinAccount ] = useState({});
    
    const [ departmentSettingStatus , setDepartmentStatus ] = useState(false);
    const [ showPopup, setShowPopup ] = useState(false);

    
    const [ counters, setCounters ] = useState(counterDefault);

    const handleClosePopup = () => {
        setShowPopup(false);
        setDepartmentStatus(false);
        dispatch( showDepartmentSettingsReducer('') );
    }
    
    const getIpDetails = async () => {
        const res = await axios.get( ApiUrls.externalIPgeolocationDB );
        const ipAddress = res.data.IPv4;
        return ipAddress;
    }

    const logoutuser = async () => {

        let loginDetail = {};
        let sessionLoginDetail = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( sessionLoginDetail!==null ){
            loginDetail = JSON.parse(sessionLoginDetail);
        }

        let companyDetail = {};
        let sessionCompanyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
        if ( sessionCompanyDetail!==null ){
            companyDetail = JSON.parse(sessionCompanyDetail);
        }
        
        dispatch( showLoaderReducer(1) );

        let ipAddress = await getIpDetails();

        // DELETE DEPARTMENTS
        const dataRequest = {
            account_id : companyDetail.account_id.toString(),
            department_id : loginDetail.group_id.toString(),
            ip_address : ipAddress.toString(),
        }

        axios
        .post( ApiUrls.departmentAccessDeleteLinked , dataRequest )
        .then( response => {
            dispatch( showLoaderReducer(0) );

            handleClosePopup();

            PagesAuthentication.logoutUser();
            history.push('/' );
        })
        .catch( (err) => {
            dispatch( showLoaderReducer(0) );
            PagesAuthentication.logoutUser();
            history.push('/' );
        }); 


    }

    const logoutConfirmation = async () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wantToLogout,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                { 
                    label: 'yes',
                    onClick: () => {  logoutuser(); }
                }
            ]
        });
    }

    const getCounters = async ( accountDetail = {} ) => {
        let countersTemp = {...counters };

        const { receiverAccountIdsArray, receiverDepartmentIdsArray } = getReceiverAccountAndDeptReceiverIds();

        const passval = {
            account_id : receiverAccountIdsArray.join(',').toString(),
            receiver_department_id : receiverDepartmentIdsArray.join(',').toString(),
            type : 'appointment-reservation'
        };
        
        const responseAppointmentReservation = await QueryUtilities.recordCounterByType(passval);
        if ( Object.entries(responseAppointmentReservation).length>0 ){
            countersTemp = { ...countersTemp, appointment : parseInt(responseAppointmentReservation.counter) }
        }
    
        setCounters(countersTemp);

    }

    const handleOnClickSettingNavigations = ( actionName = '' ) => {
        actionName = actionName.toLowerCase();

        if ( actionName==='default-message' ){
            handleClosePopup();
            dispatch( toggleDepartmentDefaultMessageReducer( new Date() ) ); 

        }else if ( actionName==='form' ){
            handleClosePopup();
            dispatch( toggleDepartmentFormsReducer( new Date() ) );

        }else if ( actionName==='qrcode' ){
            handleClosePopup();
            history.push(`/${ UrlSlugs.settings_shareQrCode}`);
            
        }else if ( actionName==='languages' ){
            handleClosePopup();
            dispatch( showLanguageSelectionPopup(Date.now()) );

        }else if ( actionName==='location' ){
            handleClosePopup()
            dispatch( changeCityPopupAction(Utilities.cityLocationLabel) );

        }else if ( actionName==='departments' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_linkedDepartments}`);

        }else if ( actionName==='important-information' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_importantInformation}`);
           
        }else if ( actionName==='calendar-diary' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.calendarDiary}`);
            
        }else if ( actionName==='bizcom-assist' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_bizcomAssist}`);
            
        }/* else if ( actionName==='talk-to-us' ){
            window.open( Utilities.talkToUsUrl, '_blank');

        }else if ( actionName==='bizcom247' ){
            window.open( Utilities.talkToUsUrl, '_blank');
        } */
    }

    useEffect( () => {

        if ( Object.keys(passConversList).length>0 ){
            const menu_setting = passConversList.hasOwnProperty('menu_setting') ? passConversList.menu_setting : passConversList;
            setDivertReplyInfo(menu_setting);

            localStorage.setItem(
                process.env.REACT_APP_session_divert_reply,
                JSON.stringify(menu_setting)
            );
        }

    }, [passConversList]);


    useEffect( () => {
    
        if ( ! Utilities.isEmpty(showDepartmentSettings.toString()) ){
            setShowPopup(true);
            setDepartmentStatus(true);

        }else{
            setShowPopup(false);
            setDepartmentStatus(false);
        }

        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginPersonalInfo!==null ){
            loginPersonalInfo = JSON.parse(loginPersonalInfo);
            setLoggedinAccount( loginPersonalInfo );

            getCounters(loginPersonalInfo);
        }

        return () => {
            setLoggedinAccount({});
        };
        
    }, [showDepartmentSettings]);


  
    return (

        departmentSettingStatus===true &&
            <Modal id="popup-setting-profiles"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="lg"  dialogClassName="popup-setting-profiles modal-dialog-theme-black"  keyboard={ true }>
                <Modal.Body className="px-5 py-4">
                    <div className="text-lowercase">
                        
                        <section className="col-12 mx-auto pb-3 font-gotham-light-14 px-0">

                            <ul className="settings-inline-navigations mb-4 py-3">
                             {/************ SWITCH TO DEPARTMENT ************/}
                             {
                                ( loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===4 && loggedinAccount.hasOwnProperty('group_id') ) &&
                                <>
                                    <li onClick={ () => handleOnClickSettingNavigations('default-message') }>
                                        <div className={`menu-icon comment-favorite-icon ${ ( divertReplyInfo.hasOwnProperty('depart_message') && parseInt(divertReplyInfo.depart_message.depart_message_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>default<br />message</div>
                                    </li>

                                    <li onClick={ () => handleOnClickSettingNavigations('form') }>
                                        <div className={`menu-icon form-icon ${ ( divertReplyInfo.hasOwnProperty('depart_form') && parseInt(divertReplyInfo.depart_form.depart_form_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>form</div>
                                    </li>
                                </>
                            }

                                <li onClick={ () => handleOnClickSettingNavigations('calendar-diary') }>
                                    <div className={`menu-icon calendar-diary-icon ${ parseInt(counters.appointment)>0?'active-menu' : '' }`}></div>
                                    <div>appointments/<br />reservations</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('qrcode') }>
                                    <div className="menu-icon qr-icon"></div>
                                    <div>qr code</div>
                                </li>


                            </ul>


                            <div className="font-gotham-book-18 pb-4 pt-2 mb-3 w-100">system settings</div>
                            
                            <ul className="settings-navigations text-center setting-department">
                                
                                <li  onClick={ () => handleOnClickSettingNavigations('languages') }>
                                    <div className="menu-icon languages-icon"></div>
                                    <div>languages</div>
                                </li>

                                <li  onClick={ () => handleOnClickSettingNavigations('location') }>
                                    <div className="menu-icon location-icon"></div>
                                    <div>location</div>
                                </li>

                                
                                <li onClick={ () => handleOnClickSettingNavigations('departments') }>
                                    <div className="menu-icon linked-profiles-icon"></div>
                                    <div>departments</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('important-information') }>
                                    <div className="menu-icon important-info-icon"></div>
                                    <div>important<br />information</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('bizcom-assist') }>
                                <div className="menu-icon bizcom-assist-icon"></div>
                                    <div>{siteTitle}<br />assist</div>
                                </li>

                                {/* <li onClick={ () => handleOnClickSettingNavigations('talk-to-us') }>
                                    <div className="menu-icon globe-icon"></div>
                                    <div>talk to<br />us</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('bizcom247') }>
                                    <div className="menu-icon comment-icon"></div>
                                    <div>bizcom<br />247</div>
                                </li> */}
                                
                            </ul>

                            <div className="d-flex align-items-center justify-content-center mt-5">
                                <div className="logout-user-nav" onClick={ () => logoutConfirmation() }>
                                    <div className="menu-icon power-icon mr-1"></div>
                                    <div>logout</div>
                                </div>
                            </div>

                        </section>

                    </div>
                </Modal.Body>
            </Modal>
        
    );
}

export default SettingDepartmentPopup;
