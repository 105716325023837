import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {  useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios'

import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';

import { showMainSearch, showHeader, showFooter, pageTitle, passDocumentDetail } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import ConnectTeamMember from '../components/popups/ConnectTeamMember';


const TeamMembers = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const clsParamTeamId = match.params.teamid ?? '';

    const [ listProfiles, setListProfiles ] = useState([]);
    const [ companyProfile, setCompanyProfile ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');

    const [ profileSelected, setProfileSelected ] = useState({});

    // MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = ( profile ) => {
        setProfileSelected( profile );
        setShow(true);
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span className="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const toggleMainSearch = ( action = 0 ) => {
        dispatch( showMainSearch(action) );
    }

    const getMembers = async ( accountID ) => {

        if ( parseInt(accountID)>0 ){
            let teamId = 0, memberList = [], teamDetail = {};

            await axios
                .post( ApiUrls.teamList , { company_id : accountID, show_company : 1, show_company_address : 1, listing_status : 1 } )
                .then( response => {

                    if ( response.status===200 ){
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if ( dataStatus===1 ){
                            if ( Utilities.isEmpty(dataResponse.list) ){
                                setShowEmpty(true);

                            }else{
                                setShowEmpty(false);

                                Object.entries(dataResponse.list).map( ( [id, memberdetail] ) => {
                                    memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];
                                });

                                setListProfiles(memberList);

                                if ( !Utilities.isEmpty(clsParamTeamId) ){
                                    teamId = parseInt(atob(clsParamTeamId));
                        
                                    if ( teamId>0 ){
                                        teamDetail = memberList.filter( row => parseInt(row.account_id) === teamId )[0]  ?? {};
                                        if ( Object.keys(teamDetail).length>0 ){
                    
                                            handleShow(teamDetail);
                                            history.push(`/${UrlSlugs.teamMembers}/${btoa(accountID)}`);
                                        }
                                    }
                                }

                            }
                        }else{
                            setShowEmpty(true);
                        }

                        setCompanyProfile(dataResponse.company_detail);
                        dispatch( pageTitle(`${ TitlesLabels.siteTitles.teamMembers } | ${ dataResponse.company_detail.company_name }`) );

                    }

                    setContentLoaderClass('');
                })
                .catch( (err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                }); 
        }

    }

    
    const showChat = ( profile ) => {

        /* let docid = match.params.docid;
        if ( typeof docid!=='undefined' ){

            const passValues = {
                account_id : parseInt(profile.account_id),
                convers_detail : {},
                document_id : parseInt(atob(docid))
            }

            dispatch( passDocumentDetail( passValues ) );
            handleClose();
            
            return false;
        } */

        history.push(`/${UrlSlugs.chat}/${btoa( parseInt(profile.account_id) )}`);
    }


    useEffect( async () => {

        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);

            if ( parseInt(reqID)>0 ){

                setContentLoaderClass('animate');
                setListProfiles([]);
                
                await getMembers(reqID);
            }
        }

    }, [match.params.id]);


    useEffect( () => {
        PagesAuthentication.getUserLoggedin();

        let loginType = parseInt(localStorage.getItem(process.env.REACT_APP_session_logintype)??1);

        dispatch( showHeader(loginType===2 ? 5 : 1) );
        dispatch( showFooter(1) );
        
    }, []);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div className="cursor-pointer" onClick={ () => toggleMainSearch(1) }>{ TitlesLabels.navigations.search }</div>
                            { companyProfile.hasOwnProperty('company_name') && <div>{companyProfile.company_name}</div> }
                            <div className="current">{ TitlesLabels.navigations.teamMembers }</div>
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() }></div>
                         </li>
                     </ul>
                </div>
            </div>

            <div className="main-content">
                <div className="container">
                    
                    { 
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <ul className="listing-profiles-preloader mt-4">
                        {
                            [...Array(12).keys()].map( ( key ) =>
                                <li key={key}>
                                    <div className="profile-image"></div>
                                    <div></div>
                                    <div></div>
                                </li>
                            )
                        }
                        </ul>
                    }

                    <div className="profile-search-wrap d-none">
                        <div className="profile-search-content">
                            <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                            <div></div>
                            <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                            <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                        </div>
                    </div>

                    { 
                        Object.entries(listProfiles).length>0 &&
                            <ul id="listing-profiles" className={`listing-profiles max-height-fit my-4 ${ Object.keys(listProfiles).length===0 ? 'd-none' : '' } `}>
                            {
                                Object.entries(listProfiles).map( ([key, row] ) =>
                                    <li key={key} data-keyword={row.name} onClick={ () => handleShow(row) }>
                                        <div className="profile-wrap">
                                            <div className="profile-image-wrap"><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div></div>
                                            <div className="profile-name notranslate" data-name={row.name}>{row.name} </div>
                                            <div className="profile-label">{row.team_position }</div>
                                        </div>
                                    </li>
                                )
                            }
                            </ul>
                    }

                    { showEmpty && <div className="text-center text-lowercase no-record-label">{ Utilities.labelTeamAvailable }</div>  }

                </div>
            </div>

            <ConnectTeamMember show={show} handleClose={handleClose} profileSelected={profileSelected} companyProfile={companyProfile} showChat={showChat} />
        

       </div>
    )
}

export default TeamMembers;