import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { showPopupTermsConditions } from '../../actions';

import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';

const TermsConditions = () => {

    const dispatch = useDispatch();
    const showPopupTermsConditionsRedux = useSelector( state => state.showPopupTermsConditions);

    const [ showPopup, setShowPopup ] = useState(false);
    const [ popupTitle, setPopupTitle ] = useState(TitlesLabels.general.termsAndConditions);
    const [ urlProvided, setUrlProvided ] = useState(UrlSlugs.page_termsConditionsClean);
    const [ loaderIframe, setLoaderIframe ] = useState(true);
    
    const handleClosePopUp = () => {
        setShowPopup(false);
        setLoaderIframe(false);
        dispatch( showPopupTermsConditions(false) );
    }

    useEffect( () => {
        setLoaderIframe(true);
        const urlProvidedRedux = Utilities.isValidURL( showPopupTermsConditionsRedux ) ? showPopupTermsConditionsRedux : UrlSlugs.page_termsConditionsClean ;

        setUrlProvided(urlProvidedRedux);

        const showPopupBol = ( Utilities.isValidURL( showPopupTermsConditionsRedux ) || showPopupTermsConditionsRedux===true ) ? true : false;
        setShowPopup( showPopupBol );

        if ( urlProvidedRedux===UrlSlugs.page_termsConditionsClean ){
            setPopupTitle(TitlesLabels.general.termsAndConditions);

        }else if ( urlProvidedRedux===UrlSlugs.page_privacyPolicy ){
            setPopupTitle(TitlesLabels.general.privacyPolicy);

        }else if ( urlProvidedRedux===UrlSlugs.page_disclaimer ){
            setPopupTitle(TitlesLabels.general.disclaimer);

        }else if ( urlProvidedRedux===UrlSlugs.page_enduserLicense ){
            setPopupTitle(TitlesLabels.general.endUserLicenseAgreement);
        }

    }, [showPopupTermsConditionsRedux] );
    
    return (

       <Modal id="popup-terms-conditions"  show={ showPopup } onHide={ handleClosePopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="lg" >
           <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline pt-4 text-lowercase">
               { popupTitle }
            </Modal.Header>
            <Modal.Body className="p-0 bg-white">
                    { loaderIframe &&  <div className="content-loader animate"></div> }
                    <iframe  width="100%"  height={`${loaderIframe?'0':'450'}`}  onLoad={ () => setLoaderIframe(false) } src={`${ urlProvided}#toolbar=0&view=Fill`}></iframe>
                    {/* <object>
                        <embed type="application/pdf"  width="100%"  height={`${loaderIframe?'0':'450'}`}  onLoad={ () => setLoaderIframe(false) } src={`${ UrlSlugs.page_termsConditionsClean}#toolbar=0&view=Fill`} />
                    </object>  */}

            </Modal.Body>
        </Modal>
    )
}

export default TermsConditions;
