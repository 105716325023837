export const footerReducer = ( state = 1, action ) =>{
    switch (action.type) {
        case 'SHOWFOOTER':
                state = action.payload;
                return state;
            break;

        default:
            return state;
            break;
    }
}

export const headerReducer = ( state = 1, action ) =>{
    switch (action.type) {
        case 'SHOWHEADER':
                state = action.payload;
                return state;
            break;

        default:
            return state;
            break;
    }
}


export const mainSearchReducer = ( state = 0, action ) =>{
    switch (action.type) {
        case 'SHOWMAINSEARCH':
                state = action.payload;
                return state;
            break;

        default:
            return state;
            break;
    }
}


export const pageReducer = ( state = 'Login', action ) =>{
    switch (action.type) {
        case 'PAGETITLE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const loaderReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'SHOWLOADER':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const reloadProfileReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'RELOADPROFILE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const globalNotificationCounterReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'GLOBALNOTIFICATIONCOUNTER':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const switchProfileReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'SWITCHPROFILE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const reloadAccountsDBReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'RELOADACCOUNTSDB':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const showChatSearchReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'SHOWCHATSEARCH':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passInternalProfileReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSINTERNALPROFILE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passChatProfileReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSCHATPROFILE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const globalProfileReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'GLOBALPROFILE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const passProfileReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSPROFILE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const chatSidebarBlockReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'CHATSIDEBARBLOCK':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showLanguageSelectionPopupReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'SHOWLANGUAGESELECTION':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const accountsWithNotificationReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'ACCOUNTSWITHNOTIFICATION':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const shareQRCodeReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'SHAREQRCODE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showNoteIndicatorReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'SHOWNOTEINDICATOR':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


/* export const allowManageNoteReducer = ( state = false , action ) =>{
    switch (action.type) {
        case 'ALLOWMANAGENOTE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
} */

export const passConversIDReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'PASSCONVERSID':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passImageUrlReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'PASSIMAGEURL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passQrcodeDetailReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'PASSQRCODETAIL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const passChatMessageTextReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'PASSCHATMESSAGETEXT':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passConversationMessageReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSCONVERSATIONMESSAGE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passConversDetailReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSCONVERSDETAIL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passConversListReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSCONVERSLIST':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const bizcomplusSwitchAccountReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'BIZCOMPLUSWITCHACCOUNT':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const changeCityPopupReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'CHANGECITYPOPUP':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const applyChangeLocationReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'APPLYCHANGELOCATION':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const newCityIDRequestReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'NEWCITYIDREQUEST':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const reloadDepartmentsReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'RELOADDEPARTMENTS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passForwardConversIdReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSFORWARDCONVERSID':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passDeleteMediaListReducer = ( state = [] , action ) =>{
    switch (action.type) {
        case 'PASSDELETEMEDIALIST':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const toggleAutomatedReplyReducer = ( state = '', action ) =>{
    switch (action.type) {
        case 'TOGGLEAUTOMATEDREPLY':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const toggleMenuPriceListReducer = ( state = {}, action ) =>{ // pass profile team detail
    switch (action.type) {
        case 'TOGGLEMENUPRICELIST':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const viewMenuPricePopupReducer = ( state = {}, action ) =>{ // pass profile team detail
    switch (action.type) {
        case 'VIEWMENUPRICEPOPUP':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const toggleDepartmentDefaultMessageReducer = ( state = '', action ) =>{
    switch (action.type) {
        case 'TOGGLEDEPARTMENTDEFAULTMESSAGE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const toggleDepartmentFormsReducer = ( state = '', action ) =>{
    switch (action.type) {
        case 'TOGGLEDEPARTMENTFORMS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const passDocumentDetailReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSDOCUMENTDETAIL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passDocumentIdReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'PASSDOCUMENTID':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const refershDashboadConversationReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'REFRESHDASHBOARDCONVERSATION':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const showMainSettingsReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'SHOWMAINSETTINGS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showDepartmentSettingsReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'SHOWDEPARTMENTSETTINGS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showPopupCompanyCategoryReducer = ( state = false , action ) =>{
    switch (action.type) {
        case 'SHOWPOPUPCOMPANYCATEGORY':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const passCompanyCategoryDetailReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSCOMPANYCATEGORYDETAIL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const passChatTeamProfileRestrictionDetailReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSCHATTEAMPROGILERESTRICTIONDETAIL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passPushNotificationDetailReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'PASSPUSHNOTIFICATIONDETAIL':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const showPopupTermsConditionsReducer = ( state = false , action ) =>{
    switch (action.type) {
        case 'SHOWPOPUPTERMSCONDITIONS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showPopupQuickLinksReducer = ( state = false , action ) =>{
    switch (action.type) {
        case 'SHOWPOPUPQUICKLINKS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showPopupDeleteAccountReducer = ( state = {} , action ) =>{
    switch (action.type) {
        case 'SHOWPOPUPDELETEACCOUNT':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showPopupVideoReducer = ( state = '' , action ) =>{
    switch (action.type) {
        case 'SHOWPOPUPVIDEO':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}


export const showPopupContactSearchReducer = ( state = { showPopup : false } , action ) =>{
    switch (action.type) {
        case 'SHOWPOPUPCONTACTSEARCH':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const blockUnblockChatReducer = ( state = false , action ) =>{
    switch (action.type) {
        case 'BLOCKUNBLOCKCHAT':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const passCompanyGroupsMembersIdsReducer = ( state = [] , action ) =>{
    switch (action.type) {
        case 'PASSCOMPANYGROUPSMEMBERSIDS':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const booleanChatReInitializeConversationReducer = ( state = "" , action ) =>{
    switch (action.type) {
        case 'BOOLEANCHATREINITIALIZECONVERSATION':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}