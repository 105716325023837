import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; 
import jQuery from 'jquery';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';

import Utilities from '../helpers/Utilities';
//import PagesAuthentication from '../helpers/PagesAuthentication';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle } from '../actions';

import MapBox from './../components/MapBox';
import SignupHeader from '../layout/SignupHeader';

const CompanyAddress = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const utilityLocation = Utilities.defaultLocation();
    const defaultLocation = {
        country_id : utilityLocation.country_id,
        country_name : utilityLocation.country_name,

        state_id : utilityLocation.state_id,
        state_name : utilityLocation.state_name,

        city_id : utilityLocation.city_id,
        city_name : utilityLocation.city_name,

        //area_id : utilityLocation.area_id,
        //area_name : utilityLocation.area_name,
    };

     let defaultFormValues = {
        country_id : defaultLocation.country_id,
        state_id : defaultLocation.state_id,
        city_id : defaultLocation.city_id,
        area_id : 0,
        street_name : '',
        street_number : '',
        building_name : '',
        building_number : '',
        postal_code : '',
        latitude : '',
        longitude : '',
        address_visibility_status : 0
    }

    let defaultFormLocationValues = {
        country_id : defaultLocation.country_id,
        state_id : defaultLocation.state_id,
        city_id : defaultLocation.city_id,
        area_id : 0,
    }

    const coordinatesInitial = { lat: 0, lng: 0 };

    const [ coordinates, setCoordinates] = useState(coordinatesInitial); 
    const [ mapLocationProvided, setMapLocationProvided] = useState(''); 
    const [ mapLocationAction, setMapLocationAction] = useState(2);  // 1 - from city/suburb, 2 - by coordinates

    const selectStateRef = useRef();
    const selectCityRef = useRef();
    const selectAreaRef = useRef();

    const [ formValues, setFormValues] = useState(defaultFormValues);

    const [ defaultLocationValues, setDefaultLocationValues] = useState(defaultLocation);
    const [ locationFormValues, setLocationFormValues] = useState(defaultFormLocationValues);

    //const [ accountID , setAccountID] = useState(0);
    const [ countryList, setCountryList] = useState([]);
    const [ stateList, setStateList] = useState([]);
    const [ cityList, setCityList] = useState([]);
    const [ areaList, setAreaList] = useState([]);
   
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;

        const allowFields = ['country_id', 'state_id', 'city_id', 'area_id'];

        //if ( fieldName==='country_id' || fieldName==='state_id' || fieldName==='city_id' || fieldName==='area_id'){
        if ( allowFields.includes(fieldName.toString()) ){
            
            setTimeout( () => {
                const inputElem = jQuery('input[name="'+fieldName+'"]');
                let locationFormValuesTemp = locationFormValues;

                if (inputElem.length>0){
                    let value = inputElem.val();
                    
                    if ( fieldName==='country_id'){

                        locationFormValuesTemp = { ...locationFormValuesTemp, ...{ country_id : value } };

                        getStateByCountryID( value );
                        selectStateRef.current.select.clearValue();
                        selectCityRef.current.select.clearValue();
                        selectAreaRef.current.select.clearValue();

                    }else if ( fieldName==='state_id'){

                        locationFormValuesTemp = { ...locationFormValuesTemp, ...{ state_id : value } };

                        getCityByStateID( value );
                        selectCityRef.current.select.clearValue();
                        selectAreaRef.current.select.clearValue();

                        
                    }else if ( fieldName==='city_id'){

                        locationFormValuesTemp = { ...locationFormValuesTemp, ...{ city_id : value } };

                        getAreByCityID( value );
                        selectAreaRef.current.select.clearValue();

                        const stateNameSelected = ! Utilities.isEmpty(locationFormValues.state_id) ? stateList.filter( state => parseInt(state.value) === parseInt(locationFormValues.state_id) )[0]['label']  ?? '' : '';
                        const cityNameSelected = ! Utilities.isEmpty(value) ? cityList.filter( city => parseInt(city.value) === parseInt(value) )[0]['label']  ?? '' : '';
                        if ( ! Utilities.isEmpty(cityNameSelected) ){
                            setMapLocationProvided(stateNameSelected+','+cityNameSelected);
                            setMapLocationAction(1);
                        }

                        
                        
                    }else if ( fieldName==='area_id'){
                        locationFormValuesTemp = { ...locationFormValuesTemp, ...{ area_id : value } };

                        const cityNameSelected = ! Utilities.isEmpty(locationFormValues.city_id) ? cityList.filter( city => parseInt(city.value) === parseInt(locationFormValues.city_id) )[0]['label']  ?? '' : '';
                        const areaNameSelected = ! Utilities.isEmpty(value) ? areaList.filter( area => parseInt(area.value) === parseInt(value) )[0]['label']  ?? '' : '';

                        if ( ! Utilities.isEmpty(areaNameSelected) ){
                            setMapLocationProvided(cityNameSelected +','+ areaNameSelected);
                            setMapLocationAction(1);
                        }
                    }

                    setLocationFormValues(locationFormValuesTemp);
                }

            }, 50);

        }else{
            let  value = e.target.value;

            if ( fieldName==='street_name'){
                formValuesTemp = { ...formValuesTemp, ...{ street_name : value } };
            }else if ( fieldName==='street_number'){
                formValuesTemp = { ...formValuesTemp, ...{ street_number : value } };
            }else if ( fieldName==='building_name'){
                formValuesTemp = { ...formValuesTemp, ...{ building_name : value } };
            }else if ( fieldName==='building_number'){
                formValuesTemp = { ...formValuesTemp, ...{ building_number : value } };
            }else if ( fieldName==='postal_code'){
                formValuesTemp = { ...formValuesTemp, ...{ postal_code : value } };
            }else if ( fieldName==='address_visibility_status'){
                value = parseInt(formValuesTemp.address_visibility_status)===1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ address_visibility_status : value } };
            }
            setFormValues(formValuesTemp);
            
            
            if ( ! Utilities.isEmpty(fieldName) && ['address_visibility_status'] && value===1  ){
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.companyAddressVisibilityWarningMessage ,
                    overlayClassName : 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmAddressVisibility(0) },
                        { label: 'continue',  onClick: () => confirmAddressVisibility( 1)  }
                    ],
                    onClickOutside: () => confirmAddressVisibility(0),
                    onKeypressEscape: () => confirmAddressVisibility(0),
                }); 
            }
        }

        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');


    }

    const confirmAddressVisibility = ( status = 0 ) => {
        setFormValues({...formValues, address_visibility_status : status });
    }

    const getCoordinatesByComplereLocation = async () => {

        let addressArray = [];
        //addressArray.push(formValues.building_number);
        addressArray.push(formValues.building_name);
        addressArray.push(formValues.street_name);
        addressArray.push(mapLocationProvided);

        addressArray = addressArray.filter(function (el) {
            return el != null;
        });


        const address = addressArray.join(',');

        setMapLocationProvided(address);
        setMapLocationAction(1);
    }

    
    const submitAddress = () => {

        let formValuesTemp = formValues;

        const locationFields = ['country_id', 'state_id', 'city_id', 'area_id'];
        const requiredFields = ['country_id', 'state_id', 'city_id', 'street_name' ];

        locationFields.map( key => {
            const inputElem = jQuery('input[name="'+key+'"]');
            if (inputElem.length>0){
                let value = inputElem.val();
                value = ( Utilities.isEmpty(value) ) ? 0 : value;
                if ( key==='country_id'){
                    formValuesTemp = { ...formValuesTemp, ...{ country_id : value } };
                }else if ( key==='state_id'){
                    formValuesTemp = { ...formValuesTemp, ...{ state_id : value } };
                }else if ( key==='city_id'){
                    formValuesTemp = { ...formValuesTemp, ...{ city_id : value } };
                }else if ( key==='area_id'){
                    formValuesTemp = { ...formValuesTemp, ...{ area_id : value } };
                }
            }
        });

        let errorCounter = 0;
        requiredFields.map( key => {
            const value = jQuery('input[name="'+key+'"]').val();
            if ( Utilities.isEmpty(value )){
                jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }
        });

        if ( errorCounter>0 ){ return false; }

        const utcTime = Utilities.currentUTCTime();
        const curretTime =  moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());

        formValuesTemp = { ...formValuesTemp, ...{ create_date : curretTime,  update_date : curretTime  } };

        if ( Object.keys(formValuesTemp).length>0 ){

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyaddress,
                JSON.stringify(formValuesTemp)
            );

            history.push(`/${ UrlSlugs.signup_companyLogo}`);
        }

    }


    // COUTNRIES
    const getCountries = () => {
        axios
        .get( ApiUrls.country , {} )
        .then( response => {
            if ( response.status===200 ){
                let optionList = [];
                Object.entries(response.data).map( ([key, row] ) => (
                    optionList = [...optionList , { value: row.id , label : row.country_name} ]
                ));
                setCountryList(optionList);
            }
        })
        .catch( (err) => {
           
        });
    }

    // STATES
    const getStateByCountryID = ( country_id = 0 ) => {

        if ( country_id>0 ){
            axios
            .post( ApiUrls.stateList , { id: 0, country_id : country_id } )
            .then( response => {
                if ( response.status===200 ){
                    let optionList = [];
                    Object.entries(response.data).map( ([key, row] ) => (
                        optionList = [...optionList , { value: row.id , label : row.state_name} ]
                    ));
                    setStateList(optionList);
                }
            })
            .catch( (err) => {
            
            });
        }
    }

    // CITIES
    const getCityByStateID = ( state_id = 0 ) => {

        if ( state_id>0 ){
            axios
            .post( ApiUrls.cityList, { id: 0, state_id : state_id } )
            .then( response => {
                if ( response.status===200 ){
                    let optionList = [];
                    Object.entries(response.data).map( ([key, row] ) => (
                        optionList = [...optionList , { value: row.id , label : row.city_name} ]
                    ));
                    setCityList(optionList);
                }
            })
            .catch( (err) => {
            
            });
        }
    }

    // AREA / SUBURB
    const getAreByCityID = (city_id = 0 ) => {

        if ( city_id>0 ){
            axios
            .post( ApiUrls.areaList, { id: 0, city_id : city_id } )
            .then( response => {
                if ( response.status===200 ){
                    let optionList = [];
                    Object.entries(response.data).map( ([key, row] ) => (
                        optionList = [...optionList , { value: row.area_id , label : row.area_name} ]
                    ));
                    setAreaList(optionList);
                }
            })
            .catch( (err) => {
            
            });
        }
    }


    const handleCoordinatesRequest = ( coodinatesResponse = coordinatesInitial ) => {
        let formValuesTemp = { ...formValues, ...{ latitude : coodinatesResponse.lat.toString(), longitude : coodinatesResponse.lng.toString() } };
        setFormValues(formValuesTemp);

        /* if ( coodinatesResponse.hasOwnProperty('change_selection') ){
            if ( coodinatesResponse.change_selection===true ){
                
                formValuesTemp = { ...formValuesTemp, }

                selectStateRef.current.select.clearValue();
                selectCityRef.current.select.clearValue();
                selectAreaRef.current.select.clearValue();

                setTimeout( function (){
                    const newLocation = {
                        ...defaultLocationValues, 
                        city_id : coodinatesResponse.city_id,
                        city_name : coodinatesResponse.city_name,
                        state_id : coodinatesResponse.state_id,
                        state_name :coodinatesResponse.state_name
                    }
                    console.log(newLocation);
                    setDefaultLocationValues(newLocation);
                }, 1000 );
               
                
            }
        } */

        
    }

    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.companyAddress ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        //PagesAuthentication.getUserLoggedin();

        getCountries();
        getStateByCountryID( defaultLocation.country_id );
        getCityByStateID( defaultLocation.state_id );
        getAreByCityID( defaultLocation.city_id );

    }, [] );


    return (
        <div className="main-content signup-page position-relative d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title="Company Address" />
                {/* <header className="header mb-5 text-center">
                    <div className="logo-wrap">
                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                        <div className="logo-type-text mt-2">Company Address</div>
                    </div>
                </header> */}

                <form className="col-12 col-md-8 col-lg-6 mx-auto pt-1 form-theme"  autoComplete="off">

                    <div className="form-group active">
                        <Select options={countryList} id="country_id" name="country_id"  onChange={ (e) => pushValue( e, 'country_id') }  placeholder="select country" defaultValue={ { value: defaultLocationValues.country_id , label: defaultLocationValues.country_name } } className="boostrap-select2" />
                        <label className="form-label">Country</label>
                    </div>

                    <div className="form-group active">
                        <Select options={stateList}   ref={selectStateRef}  name="state_id" placeholder="select state" onChange={ (e) => pushValue( e, 'state_id') }   defaultValue={ { value: defaultLocationValues.state_id , label: defaultLocationValues.state_name } }  className="boostrap-select2"/>
                        <label className="form-label">State or Province</label>
                    </div>

                    <div className="form-group active">
                        <Select options={cityList}   ref={selectCityRef} name="city_id"  placeholder="select city" onChange={ (e) => pushValue( e, 'city_id') }   defaultValue={ { value: defaultLocationValues.city_id , label: defaultLocationValues.city_name } } className="boostrap-select2"/>
                        <label className="form-label">City or Town</label>
                    </div>

                    <div className="form-group optional active">
                        <Select options={areaList}    ref={selectAreaRef} name="area_id" placeholder="select suburb" onChange={ (e) => pushValue( e, 'area_id') }   className="boostrap-select2" />
                        <label className="form-label">Suburb</label>
                        <div className="optional-label">Optional</div>
                    </div>

                    <div className="form-col-2">
                        <div className="form-group">
                            <input type="text" name="street_name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.street_name }  onChange={ (e) => pushValue( e, 'street_name') } onBlur={ () => getCoordinatesByComplereLocation() }></input>
                            <label className="form-label">Street Name</label>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="street_number" className="form-control" placeholder=" " autoComplete="off" value={ formValues.street_number }  onChange={ (e) => pushValue( e, 'street_number') } ></input>
                            <label className="form-label">Street No.</label>
                            <div className="optional-label">Optional</div>
                        </div>
                    </div>

                    <div className="form-col-2">
                        <div className="form-group optional">
                            <input type="text" name="building_name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.building_name }  onChange={ (e) => pushValue( e, 'building_name') }  onBlur={ () => getCoordinatesByComplereLocation() } ></input>
                            <label className="form-label">Building Name</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="building_number" className="form-control" placeholder=" " autoComplete="off" value={ formValues.building_number }  onChange={ (e) => pushValue( e, 'building_number') } ></input>
                            <label className="form-label">Apt, Office/Unit No.</label>
                            <div className="optional-label">Optional</div>
                        </div>
                    </div>

                    <div className="form-col-2">
                        <div className="form-group optional">
                            <input type="text" name="postal_code" className="form-control" placeholder=" " autoComplete="off" value={ formValues.postal_code }  onChange={ (e) => pushValue( e, 'postal_code') } ></input>
                            <label className="form-label">Postal Code</label>
                            <div className="optional-label">Optional</div>
                        </div>
                    </div>
                    
                    <div className="form-col-2">
                        <div className="form-group  has-off-on border-0">
                            <div className="form-control text-blue-placehoder">show full address</div>
                            <input type="checkbox" className="checkbox-onoff"  name="address_visibility_status"  value={ formValues.address_visibility_status }  checked={formValues.address_visibility_status===1 }   onChange={ (e) => pushValue(e, 'address_visibility_status') }></input>
                        </div>
                    </div>

                    <div className="mt-2">
                        <MapBox  
                            coordinatesAttributes={coordinates}
                            handleCoordinatesRequest={handleCoordinatesRequest}
                            mapMarkerDraggableAttribues={true}
                            mapMarkerDraggableGetLocation={true}
                            mapLocationActionAttributes={mapLocationAction}
                            mapLocationProvidedAttributes={mapLocationProvided}/>
                    </div>

                    <div className="button-wrap text-lowercase text-center mt-5 pt-5">
                        <button type="button" className="btn-theme-black text-lowercase" onClick={ () => submitAddress() } >Next</button>
                        <div className="mt-4"><Link to={`/${ UrlSlugs.signup_companyInformation }`}>{ TitlesLabels.general.exitWithoutSaving }</Link></div>
                    </div>

                </form>
                
         </div>

         
    </div>
    )
}

export default CompanyAddress;