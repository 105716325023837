import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery';

import Utilities from '../../helpers/Utilities';
import ChatUtilities from '../../helpers/ChatUtilities';
import QueryChatGroupUtilities from '../../helpers/QueryChatGroupUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

const ChatGroup = (props) => {

    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const minMemberRequired = 2;

    const [ memberList, setMemberList ] = useState([]);
    const [ memberAcctIDs, setMemberAcctIDs ] = useState([]);
    const [ acctIDConversID, setAcctIDConversID ] = useState({});
    const [ memberIDs, setMemberIDs ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ toggleAll, setToggleAll ] = useState(false);

    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ companyDetail, setCompanyDetail ] = useState([]);
    const [ profileLogoValue, setProfileLogoValue ] = useState('');

    const defaultValues = { title : ''};
    const [ formValues, setFormValues ] = useState(defaultValues);
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else{
            let formValuesTemp = formValues;
            if ( fieldName==='title' ){
                formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            }

            setFormValues(formValuesTemp);
        }
    }
    
    const handleClosePopup = () => {
        setFormValues(defaultValues);
        setMemberIDs([]);
        setToggleAll(false);
        setProfileLogoValue('');
        setDefaultSearchValue('');
        props.handleCloseChatGroup();
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleMemberInput = (e, row )  => {
        let memberIDsTemp = memberIDs;
        let id = row.account_id.toString();

        if ( ! memberIDsTemp.includes(id) ){
          memberIDsTemp.push(id);
        }else{
          memberIDsTemp =  Utilities.removeValueInArray(memberIDsTemp, id);
        } 

        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
        setMemberIDs(memberIDsTemp);
        setToggleAll(false);
    } 


    const toggleMemberAll = () => {
        
        if ( toggleAll===true ){
          setMemberIDs([]);
        }else{
          setMemberIDs([...memberAcctIDs]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const sendMessage = async () => {

        const button_name = 'btn-sent-broadcast-message';

        if ( Utilities.isEmpty(formValues.title) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.titleIsMissing );
            return false;
        }

        if ( parseInt(memberIDs.length)>=minMemberRequired ){

            Utilities.elementDisabledStatus(button_name, true );

            // CHECKING GROUP NAME EXISTANCE
            const responseGroupName = await QueryChatGroupUtilities.checkGroupNameExistance({
                api_token : TOKEN.toString(),
                account_id : profileDetail.account_id.toString(),
                title : formValues.title.toString(),
                group_id : '0'
            });

            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseGroupName).length===0 ){  return false;  }
            if ( parseInt(responseGroupName.success)===0 ){ 
                Utilities.messagePopup('error', responseGroupName.errorMessage );
                return false; 
            }

            let file_name = '';

            // UPLOAD LOGO
            if ( ! Utilities.isEmpty(profileLogoValue) ){

                const formData = new FormData();
                formData.append('account_id', profileDetail.account_id.toString());
                formData.append('api_token', TOKEN.toString());
                formData.append('file', profileLogoValue);

                Utilities.elementDisabledStatus(button_name, true );

                const responseUploadLogo = await QueryChatGroupUtilities.uploadGroupLogo(formData);
                Utilities.elementDisabledStatus(button_name, false );
                if ( Object.entries(responseUploadLogo).length>0){
                    if ( parseInt(responseUploadLogo.success)===1){
                        file_name = responseUploadLogo.result.file_name.toString();
                    }
                }
            }

            const passValues = {
                api_token : TOKEN.toString(),
                account_id : profileDetail.account_id.toString(),
                company_id : companyDetail.account_id.toString(),
                title : formValues.title.toString(),
                file_name  : file_name.toString(),
                members_list : memberIDs.join(',')
            }
        
            Utilities.elementDisabledStatus(button_name, true );
            const responseGroupCreated= await QueryChatGroupUtilities.createGroup(passValues);
            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseGroupCreated).length>0){
                if ( parseInt(responseGroupCreated.success)===1){

                    handleClosePopup();

                    /* props.handleCloseChatGroup();
                    setMemberIDs([]);
                    setFormValues(defaultValues); */

                    const conversation_list = responseGroupCreated.result.bizchat_conversation.conversation_list;
                    const passValues = ChatUtilities.encryptChatDetail(conversation_list, 1, profileDetail ); 

                    ChatUtilities.saveAccountsConversationDetail(conversation_list);
                    history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileDetail.account_id) )}/${passValues}`);

                }else{
                    Utilities.messagePopup('error', responseGroupCreated.errorMessage );
                }

            }else{
                Utilities.messagePopup('error', errorMsg );
            }


        }else if ( parseInt(memberIDs.length)<minMemberRequired ){
            Utilities.messagePopup('error', `mininum ${minMemberRequired} members required!` );

        }else{
            Utilities.messagePopup('error', TitlesLabels.alertMessages.noMemberSelected );
        }
    }


    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    jQuery('.avatar-preview').css('background-image', `url('${e.target.result}')`);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            setProfileLogoValue('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    useEffect( () => {

      const memberListTemp = props.memberList;
      
      let acctIDs = [];
      let acctIDConversID = {};
      let memberListUpdated = [];

      Object.entries(memberListTemp).map( ([key, row] ) => {
            

            let conversid = 0;
            if  ( row.hasOwnProperty('conversa_list') && Object.keys(row.conversa_list).length>0 ){
                conversid = row.conversa_list.convers_id ?? 0;

                if ( parseInt(row.conversa_list.flag)!==2 ){
                    memberListUpdated.push(row);
                    acctIDs.push(row.account_id.toString());
                }
            }

            if  ( row.hasOwnProperty('conversa_list') && Object.keys(row.conversa_list).length===0 ){
                memberListUpdated.push(row);
                acctIDs.push(row.account_id.toString());
            }
            
            acctIDConversID[row.account_id.toString()] = conversid.toString();
      });

      setMemberList(memberListUpdated);

      setMemberAcctIDs(acctIDs);
      setAcctIDConversID(acctIDConversID);

      setProfileDetail(props.profileDetail);
      setCompanyDetail(props.companyDetail);

    }, [props.memberList] );


    return (

      <Modal id="chat-group-popup"  show={ props.showChatGroup} onHide={ handleClosePopup } animation={false} className="broadcast-popup-content" aria-labelledby="contained-modal-title-vcenter" centered   size="xl" >
              <Modal.Body className=" p-0">
                    
                    <div className="broadcast-col text-lowercase checkbox-radio-default checkbox-radio-check-icon form-theme">

                        <div className="member-col pt-4">

                            <div className="form-group">
                                <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                                <label className="form-label">Search</label>
                            </div>
                        
                            <div className="select-all-wrap">
                                <div>select all</div>
                                <div className="mr-n1"><input type="checkbox"  checked={ `${ toggleAll ? 'checked' : '' }` }  onChange={ (e) => toggleMemberAll() }   /></div>
                            </div>

                            <ul id="listing-profiles-member" className="list-members mt-2">
                              {
                                    Object.entries(memberList).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.name.toLowerCase() } >
                                            <div><div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                            <div>
                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                <div className="profile-label">{row.title}</div>
                                            </div>
                                            <div>
                                                <input type="checkbox"  checked={ `${ memberIDs.includes(row.account_id.toString())  ? 'checked' : ''  }` }  onChange={ (e) => toggleMemberInput(e, row ) } />
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>

                        <div className="message-col px-5 pt-4">
                                
                              <div className="col-8 mx-auto mt-3">

                                  <h2 className="text-center mb-4 pb-3 text-size-large ">create group</h2>
                                    <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension } readOnly onChange={ (e) => previewImage(e) } ></input>

                                    <div className="upload-avatar avatar-wrap avatar-md-wrap mt-n4">
                                        <div>
                                            <div className="avatar-preview rounded-circle mx-auto" style={{ backgroundImage:  `url(${profileLogoValue})`  }}></div>
                                            <button type="button" className="btn-select-file text-lowercase mx-auto"  onClick={ () => selectImage() }>select image</button>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">{Utilities.labelUploadSpecs}</div>

                                  <div className="form-group mt-5">
                                      <input type="text"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ formValues.title }  onChange={ (e) => pushValue( e, 'title') } ></input>
                                      <label className="form-label">group name</label>
                                  </div>
                              </div>
                                
                        </div>

                    </div>

              </Modal.Body>

              <Modal.Footer className="p-0 border-0 text-lowercase">

                <div className="broadcast-col m-0 text-center">
                  <div className="member-col pt-0 pb-5">
                        <div className="team-selected-counter mt-3"> { memberIDs.length } Selected</div>
                  </div>
                  <div className="message-col px-5 mb-4 pb-3">
                      <button type="button" id="btn-sent-broadcast-message" className="btn-theme-black col-8 mx-auto button-has-loader" onClick={ () => sendMessage() }>save</button>
                  </div>
                </div>

             </Modal.Footer>
            
          </Modal>
    )
}

export default ChatGroup;