import React, { useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import jQuery from 'jquery';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import * as RM from 'ramda';


import { showMainSearch, applyChangeLocation as applyChangeLocationReducer, showLoader as showLoaderReducer, showPopupContactSearch as showPopupContactSearchReducer  } from '../actions';

import Utilities from '../helpers/Utilities';
import QueryMasterSearchUtlities from '../helpers/QueryMasterSearchUtlities';
import PagesAuthentication from '../helpers/PagesAuthentication';

import ApiUrls from '../helpers/ApiUrls';

import TitlesLabels from '../helpers/TitlesLabels';

import CitySearchPopup from './popups/CitySearch';
import MainSearchProfile from './popups/MainSearchProfile';

const MainSearch = ( props ) => {

    const dispatch = useDispatch();

    const applyChangeLocation = useSelector( state => state.applyChangeLocation);

    let cancelProfileTokenSource = ''; //axios.CancelToken.source();
    let cancelCategoryTokenSource = '';
    let cancelKeywordTokenSource = '';

    const nationalKeywordStages = {
        allCountries : 'all-countries',
        selectedCountry : 'selected-country',

        allStates : 'all-states',
        selectedState : 'selected-state',
        
        allCities : 'all-cities',
        selectedCity : 'selected-city',

        allStateCities : 'all-state-cities',
        selectedStateCity : 'selected-state-city',
    }


    const flag_dir = process.env.REACT_APP_img_folder+'/country-flags/';
    
    const { transcript, finalTranscript, resetTranscript } = useSpeechRecognition();
    
    const [ directorySearchType, setDirectorySearchType ] = useState(1);
    const [ enableSortDistance, setEnableSortDistance ] = useState(false);
    const [ keywordSearchValue, setKeywordSearchValue ] = useState('');

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ defaultSearchType, setDefaultSearchType ] = useState(3);
    const [ defaultLocationType, setDefaultLocationType ] = useState(1);
    const [ defaultLocationView, setDefaultLocationView ] = useState(1);

    const [ listeningStatus, setListeningStatus ] = useState(false);
    const [ searchInputClass, setSearchInputClass ] = useState('');
    const [ timeDelay, setTimeDelay ] = useState('');
    const [ isProccesing, setIsProcessing ] = useState(false);
    const [ isProcessingKeywords, setIsProcessingKeywords ] = useState(false);
    
    const [ globalAudioStatus, setGlobalAudioStatus ] = useState(false);

    const [ keywordNationalStage , setKeywordNationalStage ] = useState('');

    const [ showCateogryLoader, setShowCateogryLoader ] = useState(false);
    const [ categoryListing , setCategoryListing ] = useState([]);
    const [ categoryListingPrevious , setCategoryListingPrevious ] = useState([]);
    const [ keywordListing , setKeywordListing ] = useState([]);
    const [ keywordListingStatesAll , setKeywordListingStatesAll ] = useState([]);
    const [ keywordListingCountriesAll , setKeywordListingCountriesAll ] = useState([]);
    const [ keywordListingAllStates , setKeywordListingAllStates ] = useState([]);

    const [ keywordSelectedState , setKeywordSelectedState ] = useState({});

    const [ searchProfileDirectBol , setSearchProfileDirectBol ] = useState(false);
    const [ customCategoryResult , setCustomCategoryResult ] = useState({});

    const [ selectedCategoryDetail , setSelectedCategoryDetail ] = useState({});

    let counterTypeDefault = { company : 0, profile : 0, category : 0, keyword : 0 };
    const [ counterProfiles , setCounterProfiles ] = useState(counterTypeDefault);

    let counterLocationDefault = { local : 0, national : 0, international : 0 };
    const [ counterLocation , setCounterLocation ] = useState(counterLocationDefault);
   
    const [ listProfiles, setListProfiles ] = useState([]);
    
    const [ profileLoggedin, setProfileLoggedin ] = useState({});
    const [ listStates, setListStates ] = useState([]);
    const [ listStatesIDs, setListStatesIDs ] = useState([]);
    const [ totalCounterStates, setTotalCounterStates ] = useState(0);

    const [ listCountries, setListCountries ] = useState([]);
    const [ listCountriesIDs, setListCountriesIDs ] = useState([]);
    const [ totalCounterCountries, setTotalCounterCountries ] = useState(0);
    
    const [ listStatesCountries, setListStatesCountries ] = useState([]);
    const [ selectedCountry, setSelectedCountry ] = useState({});
    const [ profileSelected, setProfileSelected ] = useState({});

    const [ internationalCountrySelected, setInternationalCountrySelected ] = useState({});
    const [ internationalStateSelected, setInternationalStateSelected ] = useState({});

    const [ nationalStateSelected, setNationalStateSelected ] = useState({});
    const [ nationalCitySelected, setNationalCitySelected ] = useState({});

    const defaultLocation = Utilities.defaultLocation();
    const [ currentLocation, setCurrentLocation ] = useState(defaultLocation);
    const [ showLocationTab, setShowLocationTab ] = useState(true);
    
    const [show, setShow] = useState(false);

    const [ keywordsSelected, setKeywordsSelected ] = useState('');
    const [ keywordsStateCities, setKeywordsStateCities ] = useState({});
    const [ keywordsStateCity, setKeywordsStateCity ] = useState({});

    const [ showCityPopup, setShowCityPopup ] = useState(false);
    
    const handleClosePopUp = () => setShowCityPopup(false);
    const handleShowPopUp = () => setShowCityPopup(true);

    const handleClose = () => {
        setProfileSelected({});
        setShow(false);
    }


    const passCityDetail = ( cityDetail = {} ) => {

        if ( Object.entries(cityDetail).length>0 ){
            jQuery('div#country-id').text(cityDetail.country_id);
            jQuery('div#state-id').text(cityDetail.state_id);
            jQuery('div#city-id').text(cityDetail.city_id);

            defaultLocationValues();
            searchProper();

            let defaultLocationTemp = defaultLocation;
            
            let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if ( userLocation!==null ){
                defaultLocationTemp = JSON.parse(userLocation);
            }
            
           defaultLocationTemp = { ...defaultLocationTemp,
                                    country_name : cityDetail.country_name,
                                    state_name : cityDetail.state_name,
                                    city_name : cityDetail.city_name,

                                    country_id : cityDetail.country_id,
                                    state_id : cityDetail.state_id,
                                    city_id : cityDetail.city_id
                            };

            setCurrentLocation(defaultLocationTemp);
        }

        handleClosePopUp();
    }

    const handleShow = async ( profile ) => {
        setProfileSelected(profile);
        setShow(true);
    }



    const defaultLocationValues = () => {
        jQuery('div#location-type').text(1);
        setDefaultLocationType(1);
        setDefaultLocationView(1);
        setCounterLocation(counterLocationDefault);
        setCounterProfiles(counterTypeDefault);
        setSelectedCountry({});
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){

            setCounterLocation(counterLocationDefault);
            setCounterProfiles(counterTypeDefault);

            //setListProfiles([]);
            
            setDefaultSearchValue(value);
            defaultLocationValues();

        }
    }
    
    const closeSearchBox = () => {
        dispatch( showMainSearch(0) );
        setEnableSortDistance(false);
        setShow(false);
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        setListeningStatus(false);

        document.getElementById('input_keyword').focus();
        resetTranscript();

        defaultLocationValues();

        setCounterLocation(counterLocationDefault);
        setCounterProfiles(counterTypeDefault);

        setListProfiles([]);
        setCategoryListing([]);
        setKeywordListing([]);
        
        jQuery('div#category-id').text(0);
         
        let defaultLocationTemp = defaultLocation;

        let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
        if ( userLocation!==null ){
            defaultLocationTemp = JSON.parse(userLocation);
        }

        jQuery('div#country-id').text(defaultLocationTemp.country_id);
        jQuery('div#state-id').text(defaultLocationTemp.state_id);
        jQuery('div#city-id').text(defaultLocationTemp.city_id);

        setCurrentLocation(defaultLocationTemp);
    }

    const switchTypeSearch = (type = 3) => {
        const currentType = jQuery('div#account-type').text();
        jQuery('div#account-type').text(type);

        if ( parseInt(currentType)!==type ){
            setDefaultSearchType(type);
            defaultLocationValues();
            //setCounterProfiles(counterTypeDefault);
            setSelectedCategoryDetail({});
        }

        if ( type!==5 ){
            jQuery('div#category-id').text(0);
        }else{
            searchCategories();
        }

        setSearchProfileDirectBol(false);
        //searchProper();
    }

    const switchLocationType = (locationType = 1) => {

        locationType = parseInt(locationType);
        jQuery('div#location-type').text(locationType);

        setNationalStateSelected({});
        setNationalCitySelected({});

        if ( locationType!==3 ){ setSelectedCountry({}); }
        
        
        if ( defaultSearchType===6){
            setSelectedCountry({});
            setKeywordListingAllStates([]);
            setKeywordSelectedState([]);
        }

        setDefaultLocationType(locationType);
        setDefaultLocationView(locationType);


        if ( defaultSearchType===5 ){
            if ( searchProfileDirectBol===true ){

                setSelectedCountry({});

                const list_location = customCategoryResult.list;
                const list_states = customCategoryResult.list_states;
                const list_countries = customCategoryResult.list_countries;
                
                if ( locationType===1 ){
                    if ( Object.entries(list_location.local).length>0 ){
                        let list_local = list_location.local[Object.keys(list_location.local)[0]];
                        list_local = list_local[Object.keys(list_local)[0]];
                        showProfilesByAccountIdsCategories( list_local.join(',') );
                    }

                }else if ( locationType===2 ){
                    if ( Object.entries(list_location.national).length>0 ){
                        let list_national = list_location.national[Object.keys(list_location.national)[0]];

                        let statesList = [];
                        let statesAccountIdsCounter = 0;
                        Object.entries(list_national).map( ([ sid, ids ]) => {
                            statesList.push({
                                ...list_states[sid], counter : ids.length, ids : ids.join(',')
                            });
                            statesAccountIdsCounter = statesAccountIdsCounter + parseInt(ids.length);
                        });
                        setTotalCounterStates( statesAccountIdsCounter );
                        setListStates( statesList );
                    }

                }else if ( locationType===3 ){

                    if ( Object.entries(list_location.international).length>0 ){
                        let list_international = list_location.international;

                        let countriesList = [];
                        let accountIdsCounter = 0;

                        Object.entries(list_international).map( ([ cid, row ]) => {

                            let statesAccountIdsCounter = 0;
                            Object.entries(row).map( ([ sid, ids ]) => {
                                statesAccountIdsCounter = statesAccountIdsCounter + parseInt(ids.length);
                            });

                            accountIdsCounter = accountIdsCounter + statesAccountIdsCounter;

                            countriesList.push({
                                ...list_countries[cid], counter : statesAccountIdsCounter
                            });

                        });

                        setTotalCounterCountries( accountIdsCounter );
                        setListCountries( countriesList );
                    }
                }
               
                return false;
            }
        }

        if ( locationType===1 ){
            if ( defaultSearchType===6){
                setKeywordListing([]);
                searchProfileByKeywords();
            }else{
                searchNow(0);
            }
            
        }
    
    }
    
    const startRecognition = async () => {

        if ( globalAudioStatus===true ){
            resetSearchValue();
            setListeningStatus(true);
            
            SpeechRecognition.startListening();
        }else{
            Utilities.messagePopup('error', TitlesLabels.alertMessages.microphoneIsNotDetected );
        }
     
    }


    const searchByState = ( country_id = 0, state_id = 0, stateDetail = {} ) => {

        setSelectedCountry({});
        setDefaultLocationView(1);

        jQuery('div#location-type').text(2);

        if ( parseInt(country_id)===0 ){ 
            country_id = jQuery('div#country-id').text();
            state_id = listStatesIDs.join(',');
        }

        if ( defaultLocationType===3 ){
            setNationalStateSelected(stateDetail);
        }else{
            setNationalStateSelected( state_id>0 ? {} : { state_name : Utilities.labelAllStates } );
        }
       
        setNationalCitySelected( state_id>0 ? { ...stateDetail, city_name : Utilities.labelAllCities} : {} );
        
        searchNow(0, country_id, state_id, 0 );
    }

    const searchByCountry = ( country_id = 0, country = {} ) => {

        jQuery('div#location-type').text(3);
        setInternationalStateSelected({});
        
        if ( parseInt(country_id)===0 ){ 
            country_id = listCountriesIDs.join(',');
            searchNow(0, country_id, 0 , 0 );

            setDefaultLocationView(1);
            setInternationalCountrySelected({ country_name : Utilities.labelAllCountries});

        }else{

            const category_id = jQuery('div#category-id').text();
            const account_type =jQuery('div#account-type').text();
            const keyword = jQuery('#input_keyword').val();
            
            if ( keyword.length<3){ return false; }

            setIsProcessing(true);
            
            const dataRequest = {
                country_id :  country_id.toString(),
                type : account_type.toString(),
                location_type : '3',
                keyword : keyword.toString(),
                category_id : category_id.toString()
            }

            axios
            .post(ApiUrls.mainSearchStatesByCountry , dataRequest )
            .then( response => {
        
                setIsProcessing(false);
                if ( response.status===200 ){

                    if ( response.data.status===1){
                        const stateListCountries = response.data.list ?? {};

                        /* let totalCounter = stateListCountries.reduce((acc, curr) => {
                            return acc = acc + curr.counter;
                        }, 0); */
        
                        setListStatesCountries(stateListCountries);
                        setSelectedCountry(country);
   
                        setInternationalCountrySelected(country);
                    }
                }
               
            })
            .catch( (err) => {
                setIsProcessing(false);
            });
        }
    }


    const setupLocation = ( detail, country_id, state_id, city_id, type ) => {

        jQuery('div#country-id').text(country_id);
        jQuery('div#state-id').text(state_id);
        jQuery('div#city-id').text(city_id);
        
        handleCloseTerritory();

        defaultLocationValues();
        //searchNow();
        searchProper();

        let defaultLocationTemp = defaultLocation;
        
        let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
        if ( userLocation!==null ){
            defaultLocationTemp = JSON.parse(userLocation);
        }
        

        if ( type==='country'){
            defaultLocationTemp = { ...defaultLocationTemp, country_name : detail.country_name, state_name : 'All', city_name : 'All' };
        }else if ( type==='state'){
            defaultLocationTemp = { ...defaultLocationTemp, country_name : detail.country_name, state_name : detail.state_name, city_name : 'All' };
        }else if ( type==='city'){
            defaultLocationTemp = { ...defaultLocationTemp, country_name : detail.country_name, state_name : detail.state_name,  city_name : detail.city_name };
        }

        setCurrentLocation(defaultLocationTemp);
    }

    const handleSortDistance = () => {

        const enableSortDistanceTemp = enableSortDistance===true ? false : true
        setEnableSortDistance( enableSortDistanceTemp );
       
        setIsProcessing(true);

        setTimeout( () => {
            if ( enableSortDistanceTemp===true ){

                const list = Utilities.sortCalculateDistance(listProfiles, 1);
                setListProfiles( list );

            }else{

                let list = listProfiles;
                if ( list.length>0 ){
                    if ( 'name' in list[0] ){
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                        list = RM.sort(byName, list);
                    }

                    setListProfiles(list);
                }
            }

            setIsProcessing(false);
        }, 1000);

    }

   

    const showCitiesByState = ( stateDetail = {}  ) => {

        if ( typeof stateDetail!=='undefined' ){
            const state_id = parseInt(stateDetail.id);

            if ( state_id>0 ){
                setDefaultLocationView(22);
                setNationalStateSelected(stateDetail);
            }
        }
    }

    const showProfilesByState = async () => {
        if ( typeof listStatesCountries!=='undefined' ){
            
            let profileArray = [];
            for( let states of Object.values(listStatesCountries) ){
                if ( states.hasOwnProperty('cities') ){
                    for( let city of states.cities ){
                        profileArray.push(city.profiles);
                    }
                }
            }

            if ( profileArray.length===0 ){ return false; }
            
           setIsProcessing(true);
           const profileDetailRes = await QueryMasterSearchUtlities.getProfileDetail( profileArray.join(',') );
           setIsProcessing(false);
            
           setDefaultLocationView(1);
           setInternationalStateSelected({ state_name : Utilities.labelAllStates });

           if ( Object.entries(profileDetailRes).length>0 ){
               if ( parseInt(profileDetailRes.status)===1 ){
                   
                   let list = profileDetailRes.list;
                   if ( enableSortDistance===true ){
                       list = Utilities.sortCalculateDistance(list, 1);
   
                   }else{
                       if ( list.length>0 ){
                           if ( 'name' in list[0] ){
                               const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                               list = RM.sort(byName, list);
                           }
                       }
       
                   }
                   setListProfiles(list);
               }
           }

        }
    }

    const showProfilesByCity = async ( cityDetail = {} ) => {
        if ( typeof cityDetail!=='undefined' ){
          
           setIsProcessing(true);
           const profileDetailRes = await QueryMasterSearchUtlities.getProfileDetail( cityDetail.profiles );
           setIsProcessing(false);
            
           setNationalCitySelected(cityDetail);
           setDefaultLocationView(1);

           if ( Object.entries(profileDetailRes).length>0 ){
               if ( parseInt(profileDetailRes.status)===1 ){
                   
                   let list = profileDetailRes.list;
                   if ( enableSortDistance===true ){
                       list = Utilities.sortCalculateDistance(list, 1);
   
                   }else{
                       if ( list.length>0 ){
                           if ( 'name' in list[0] ){
                               const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                               list = RM.sort(byName, list);
                           }
                       }
       
                   }
                   setListProfiles(list);
               }
           }

        }
    }

    const handleBackToNationalStateFromCity = ( stateDetail = {}) =>{
       setDefaultLocationView(22);
       setNationalStateSelected(stateDetail);
    }

    const handleBackToNationalState = () => {
        setDefaultLocationView(2);
        setNationalCitySelected({});
    }

    const handleBackToNationalCity = () => {
        setDefaultLocationView(22);
        setNationalCitySelected({});
    }

    const handleBackToInternationalCountry = () => {
        setDefaultLocationView(3);
        setSelectedCountry({});
        setKeywordSelectedState([]);
    }

    const handleBackToInternationalCountryStates = () => {
        setDefaultLocationView(3);
        setInternationalStateSelected({});
        setListProfiles({});
    }

    const handleBackToInternationalState = () => {
        setDefaultLocationView(0);
        setSelectedCountry(internationalCountrySelected);
    }

    const handleBackToInternationalCity = () => {
        setDefaultLocationView(22);
        setSelectedCountry({});
    }
    

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const searchNow = async ( is_local = 1, pcountry_id = -1, pstate_id = -1, pcity_id = -1 ) => {
        clearTimeout(timeDelay);
        
        setShowLocationTab(true);
        is_local = parseInt(is_local);

        let keyword = jQuery('#input_keyword').val();
        let account_type =jQuery('div#account-type').text();
        let location_type = jQuery('div#location-type').text();
        let category_id = jQuery('div#category-id').text();

        let country_id = jQuery('div#country-id').text();
        let state_id = jQuery('div#state-id').text();
        let city_id = jQuery('div#city-id').text();

        if ( pcountry_id!='-1' ){ country_id = pcountry_id; } 
        if ( pstate_id!='-1' ){ state_id = pstate_id; } 
        if ( pcity_id!='-1' ){ city_id = pcity_id; } 


        if ( is_local===1 && parseInt(location_type)>1 ){ return false; }
        if ( typeof keyword==='undefined' ||  keyword.length<3 ){ return false; }

        const dataRequest = {
            country_id :  country_id.toString(),
            state_id : state_id.toString(),
            city_id : city_id.toString(),
            type : account_type.toString(),
            location_type : location_type.toString(),
            keyword : keyword.toString(),
            category_id : category_id.toString()
        }

        const pageNum = 1;
        
        setIsProcessing(true);

        //let timer = setTimeout( async () => { 
            await  axios
                    .post( ApiUrls.mainSearch+'?page='+pageNum, dataRequest , { cancelToken: cancelProfileTokenSource.token })
                    .then( response => {

                        setIsProcessing(false);

                        if ( response.status===200 ){
                            const responseData = response.data;
                            const counter_location = responseData.counter_location;
                            const counter_type = responseData.counter_type;
                            let list = responseData.list;
                            const states = responseData.states;
                            const countries = responseData.countries;
                            
                            if ( enableSortDistance===true ){
                                list = Utilities.sortCalculateDistance(list, 1);

                            }else{
                                if ( list.length>0 ){
                                    if ( 'name' in list[0] ){
                                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                                        list = RM.sort(byName, list);
                                    }
                                }
                            }

                            setListProfiles(list);

                            if ( is_local===1 ){

                                let counterProfilesTemp = { 
                                                                company : parseInt(counter_type.company),
                                                                profile : parseInt(counter_type.profile),
                                                                category : parseInt(counter_type.category),
                                                                keyword : parseInt(counter_type.keyword)
                                                        };

                                setCounterProfiles(counterProfilesTemp);
                            
                                let counterLocationDefault = { local : parseInt(counter_location.local), national : parseInt(counter_location.national), international : parseInt(counter_location.international) };
                                setCounterLocation(counterLocationDefault)

                                setListStates(states);

                                let counterTemp = 0;
                                let listIDsTemp = [];
                                Object.entries(states).map( ([key, row]) => {
                                    counterTemp = parseInt(counterTemp) + parseInt(row.counter);
                                    listIDsTemp.push(row.id);
                                });
                                setTotalCounterStates(counterTemp);
                                setListStatesIDs(listIDsTemp);

                                setListCountries(countries);

                                counterTemp = 0;
                                listIDsTemp = [];
                                Object.entries(countries).map( ([key, row]) => {
                                    counterTemp = parseInt(counterTemp) + parseInt(row.counter);
                                    listIDsTemp.push(row.id);
                                });
                                setTotalCounterCountries(counterTemp);
                                setListCountriesIDs(listIDsTemp)

                            }
                            
                        }
                        //setTimeDelay(timer);
                    })
                    .catch( (err) => {

                        setIsProcessing(false);
                        //setTimeDelay(timer);
                        //if (axios.isCancel(err)) return;

                    });

        //}, 350);

        //setTimeDelay(timer);
    }


    const loaderProfileHtml = () => {
        return (
            <div className="container pt-3">
                <ul className="dashboard-list-preloader">
                    {
                        [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                            <li key={key}>
                                <div className="profile-image"></div>
                                <div></div>
                                <div></div>
                            </li>
                        )
                    }
                    
                </ul>
            </div>
        )
    }


    const loaderCategoryHtml = () => {
        return (
            <ul className="list-categories global-preloader">
                {
                    [...Array( 12 ).keys()].map( ( key ) =>
                        <li key={key}>
                            <div className="category-image"></div>
                            <div className="font-gotham-book-14 ellipsis-2 line-height-1-2 pt-2 mb-1"><div className="height-15 bg-gray-theme col-10 mx-auto border-radius-25"></div></div>
                            <div className="font-gotham-light-12"><div className="height-12 bg-gray-theme col-8 mx-auto border-radius-25"></div></div>
                        </li>
                    )
                }
                
            </ul>
        )
    }

    const formattedCategoryName = ( name = '' ) => {
        if ( ! Utilities.isEmpty(name) ){
            const keyword = jQuery('#input_keyword').val().toString().toLowerCase();
            name = name.toString().toLowerCase();
            name = name.replace(keyword, '<span class="keyword-string">'+keyword+'</span>');
        }
        return name;
    }
    
    const countryListHtml = ( row ) => {
        return <div>
                    <div className="country-logo" style={{ backgroundImage:  `url(${flag_dir}${row.country_code}.svg)` } }></div>
                    <div>{ row.country_name }</div>
                    <div><span className="counter">{ row.counter }</span></div>
                </div>
    }

    const stateListHtml = ( row ) => {
        return <div>
                <div>{ row.state_name }</div>
                <div><span className="counter">{ row.counter }</span></div>
            </div>
    }
    
    const profileListingHtml = ( key, row ) => {

        let locationTab = jQuery('div#location-type').text();
        locationTab = parseInt(locationTab.trim());

        const locationAddressFormatted = QueryMasterSearchUtlities.formatAdressByLocationType( locationTab , row);
        const name = parseInt(row.account_type)===3 ? row.company_name : row.name;

        return <li key={key}>
                    <div className="profile-wrap" onClick={ () => handleShow( row ) }>
                        <div className="profile-image-wrap"><div className={`profile-image fs-14-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)   }}>{ Utilities.showHideInitial(row.logo_url, name) }</div></div>
                        <div>
                            <div className="profile-name notranslate" dangerouslySetInnerHTML={{__html: highlightKeywordInResults(name) }}></div>
                            { ( row.hasOwnProperty('category') && row.category!=='' ) && <div className="profile-label">{row.category}</div> }
                        </div>
                    
                        <div className="d-flex">
                            { ( row.hasOwnProperty('team_company_name') && ! Utilities.isEmpty(row.team_company_name) ) && <div className="profile-work-label mr-2 notranslate">{row.team_company_name}</div> }
                            <div className="profile-address">{ locationAddressFormatted }</div>
                        </div>
                
                        <div className="hidden-detail d-none">
                            <div className="name notranslate">{name}</div>
                            <div className="logo">{row.logo_url}</div>
                            <div className="address">{locationAddressFormatted}</div>
                        </div>
                
                    </div>
                </li>
    }

    // CATEGORIES ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const searchCategories = async () => {
        const keyword = jQuery('#input_keyword').val();
        setDefaultLocationView(1);
        setShowCateogryLoader(false);
        setShowLocationTab(false);
        
        if ( ! Utilities.isEmpty(keyword) ){
            if ( typeof keyword==='undefined' ||  keyword.length<3 ){ return false; }

            let country_id = jQuery('div#country-id').text();
            let state_id = jQuery('div#state-id').text();
            let city_id = jQuery('div#city-id').text();

            const passVal = {
                country_id :  country_id.toString(),
                state_id : state_id.toString(),
                city_id : city_id.toString(),
                keywords : keyword,
                main_search : 1 
            }

            setShowCateogryLoader(true);
            await axios
                .post( ApiUrls.mainCategorySearchByKeywords, passVal, { cancelToken: cancelCategoryTokenSource.token } )
                .then( response => {

                    setShowCateogryLoader(false);

                    if ( response.status===200 ){
                        const status = parseInt(response.data.status);
                        const list = response.data.list;
                        const counter = response.data.counter;

                        let counterProfilesTemp = { ...counterProfiles,
                                                    company : parseInt(counter.company || 0),
                                                    profile : parseInt(counter.profile || 0),
                                                    category : parseInt(counter.category || 0),
                                                    keyword : parseInt(counter.keyword || 0)
                                                };

                        setCounterProfiles(counterProfilesTemp);
                        setDefaultLocationView(0);

                        if ( status===1 ){
                            setCategoryListing(list);
                            setCategoryListingPrevious(list);
                        }else{
                            setCategoryListing([]);
                        }
                      
                    }else{
                        setCategoryListing([]);
                    }
                    
                }).catch( (err) => {
                    setCategoryListing([]);
                    setShowCateogryLoader(false);
                });

        }else{
            setCategoryListing([]);
        }
    }


    const getSubCategoryById = async ( providedCategory = {} ) => {


        if ( Object.entries(providedCategory).length>0 && providedCategory.hasOwnProperty('group_id') ){
            
            setSelectedCategoryDetail(providedCategory);
            const providedId = providedCategory.group_id;
            jQuery('div#category-id').text(providedId);

            if ( parseInt(providedCategory.parent_id || 0)>0 ){
                
                setSearchProfileDirectBol(false);

                await axios
                    .post( ApiUrls.mainCategoryGetSubCategoriesByParentId, { id : providedId } )
                    .then( async response => {

                        if ( response.status===200 ){

                            //const status = parseInt(response.data.status);
                            const list = response.data.list;

                            if ( Object.entries(list).length>0 ){
                                setCategoryListing(list);
                                setCategoryListingPrevious(list);

                            }else{


                                // CHECK PROFILE IS ONLY ONE
                                dispatch( showLoaderReducer(1) );

                                const profilesIdsRes = await QueryMasterSearchUtlities.getProfileCounterByCategoryId( providedId );
                                if ( Object.entries(profilesIdsRes).length>0 ){

                                    if ( parseInt(profilesIdsRes.status)===1  && parseInt(profilesIdsRes.count)===1 ){
                                        const profileDetailRes = await QueryMasterSearchUtlities.getProfileDetail( parseInt(profilesIdsRes.account_ids) );

                                        if ( Object.entries(profileDetailRes).length>0 ){
                                            if ( parseInt(profileDetailRes.status)===1  && parseInt(profileDetailRes.list.length)===1 ){
                                                handleShow(profileDetailRes.list[0]);
                                                return false;
                                            }
                                        }
                                    }
                                }

                                dispatch( showLoaderReducer(0) );
                                // IF MORE THAN ONE
                               
                                setShowLocationTab(true);
                                setCategoryListing([]);

                                // SHOW PROFILES
                                cancelProfileTokenSource = axios.CancelToken.source();
                                setDefaultLocationView(1);
                                searchNow();

                            }
                        }
                        
                    }).catch( (err) => {

                    });

            }else{


                // CHECK PROFILE IS ONLY ONE
                const profilesIdsRes = await QueryMasterSearchUtlities.getProfileCounterByCategoryName( providedCategory.cate_name );
                if ( Object.entries(profilesIdsRes).length>0 ){
                    if ( parseInt(profilesIdsRes.status)===1  && parseInt(profilesIdsRes.count)===1 ){

                        const profileDetailRes = await QueryMasterSearchUtlities.getProfileDetail( parseInt(profilesIdsRes.account_ids) );
                        if ( Object.entries(profileDetailRes).length>0 ){
                            if ( parseInt(profileDetailRes.status)===1  && parseInt(profileDetailRes.list.length)===1 ){
                                handleShow(profileDetailRes.list[0]);
                                return false;
                            }
                        }
                    }
                }


                setSearchProfileDirectBol(true);
                setIsProcessing(true);

                setShowLocationTab(true);
                setCategoryListing([]);
                //setListProfiles([]);
                
                //cancelProfileTokenSource = axios.CancelToken.source();
                setDefaultLocationView(1);
                //searchNow();

                let country_id = jQuery('div#country-id').text();
                let state_id = jQuery('div#state-id').text();
                let city_id = jQuery('div#city-id').text();
                let counterLocationDefault = { local : 0, national : 0, international : 0 };

                // GET ALL PROFILES BY CATEGORY NAME
                const requestValues = {
                    country_id :  country_id.toString(),
                    state_id : state_id.toString(),
                    city_id : city_id.toString(),
                    keywords : providedCategory.cate_name.toString(),
                }
                const allProfilesResultRes = await QueryMasterSearchUtlities.getAllProfileResultsByCaterogyName(requestValues);

                if ( Object.entries(allProfilesResultRes).length>0 ){

                    if ( parseInt(allProfilesResultRes.status)===1 ){
                        
                        setCustomCategoryResult(allProfilesResultRes);
                        const list_location = allProfilesResultRes.list;
                        const counter_location = allProfilesResultRes.counters;

                        counterLocationDefault = { 
                                        ...counterLocationDefault,
                                        local : parseInt(counter_location.local || 0),
                                        national : parseInt(counter_location.national || 0),
                                        international : parseInt(counter_location.international || 0)
                                    };
                        setCounterLocation(counterLocationDefault);

                        if ( counterLocationDefault.local>0 ){
                            const list_local = list_location.local[country_id.toString()][state_id.toString()];
                            showProfilesByAccountIdsCategories( list_local.join(',') );
                        }
                    }else{
                        setIsProcessing(false);
                        setCounterLocation(counterLocationDefault);
                        setListProfiles([]);
                    }


                }

            }
        }
    }


    const showProfilesByAccountIdsCategories = async ( accountIdsImploded = '') => {
        
        if ( Utilities.isEmpty(accountIdsImploded) ){ return false; }
        setDefaultLocationView(1);
        setIsProcessing(true);
        setSelectedCountry({});

        const profileDetailRes = await QueryMasterSearchUtlities.getProfileDetail( accountIdsImploded );
        setIsProcessing(false);

        if ( Object.entries(profileDetailRes).length>0 ){
            
            if ( parseInt(profileDetailRes.status)===1 ){
                
                let list = profileDetailRes.list;
                
                if ( enableSortDistance===true ){
                    list = Utilities.sortCalculateDistance(list, 1);

                }else{
                    if ( list.length>0 ){
                        if ( 'name' in list[0] ){
                            const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                            list = RM.sort(byName, list);
                        }
                    }
    
                }
                setListProfiles(list);
            }
        }
    }

    const customCategoryGetAllAccountIdsByState = ( countryid = 0, stateid = 0 ) => {

        let locationType = jQuery('div#location-type').text();
        locationType = parseInt(locationType);

        const list_location = customCategoryResult.list;
        
        if ( locationType===2 ){
            let list_national = list_location.national[Object.keys(list_location.national)[0]];

            let statesAccountIds = [];
            Object.entries(list_national).map( ([ sid, ids ]) => {
                statesAccountIds = [...statesAccountIds, ids ];
            });

            if ( statesAccountIds.length>0 ){
                showProfilesByAccountIdsCategories( statesAccountIds.join(',') );
            }
            
        }else if ( locationType===3 && parseInt(countryid)>0 && parseInt(stateid)>0 ){
            
            let accountIds = list_location.international[countryid][stateid];
            if ( accountIds.length>0 ){
                showProfilesByAccountIdsCategories( accountIds.join(',') );
            }
        }
    }


    const customCategoryGetAllStatesByCountry = ( countryid = 0 ) => {

        const list_location = customCategoryResult.list;

        if ( countryid>0 ){
            const list_states = customCategoryResult.list_states;
            const list_countries = customCategoryResult.list_countries;
            
            let country_states = list_location.international[countryid];

            let accountIdsCounter = 0;
            let statesList = [];
            Object.entries(country_states).map( ([ sid, ids ]) => {
                statesList.push({
                    ...list_states[sid], counter : ids.length, ids : ids.join(',')
                });

                accountIdsCounter = accountIdsCounter + parseInt(ids.length);
            });

            setSelectedCountry({ ...list_countries[countryid], counter : accountIdsCounter });
            setListStatesCountries( statesList );

        }else{

            let list_international = list_location.international;

            let accountIds = [];
            Object.entries(list_international).map( ([ cid, row ]) => {
                Object.entries(row).map( ([ sid, ids ]) => {
                    Object.entries(ids).map( ([ ky, id ]) => {
                        accountIds.push(id);
                    });
                });
            });

            if ( accountIds.length>0 ){
                showProfilesByAccountIdsCategories( accountIds.join(',') );
            }

            
        }
    }

    const showPreviousCategories = () => {
        setShowLocationTab(false);
        setCategoryListing(categoryListingPrevious);
    }

    // KETWORDS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const searchProfileByKeywords = async ( get_counters = 1, location_type = 1, countryid = '0', stateid = '0', cityid = '0' ) => {
        const keyword = jQuery('#input_keyword').val();
      
        if ( ! Utilities.isEmpty(keyword) ){
   
            if ( typeof keyword==='undefined' ||  keyword.length<3 ){ return false; }

            let country_id = jQuery('div#country-id').text();
            let state_id = jQuery('div#state-id').text();
            let city_id = jQuery('div#city-id').text();

            country_id = countryid==='0' ? country_id : countryid;
            state_id = stateid==='0' ? state_id : stateid;
            city_id = cityid==='0' ? city_id : cityid;

            const passVal = {
                country_id :  country_id.toString(),
                state_id : state_id.toString(),
                city_id : city_id.toString(),
                keywords : keyword,
                location_type : location_type.toString(),
                get_counters : get_counters.toString()
            }
        
            //setDefaultLocationType(location_type);
            //setDefaultLocationView(location_type);
            setIsProcessingKeywords(true);

            await axios
                .post( ApiUrls.mainCompanyKeywordsSearchByKeywords, passVal , { cancelToken: cancelKeywordTokenSource.token } )
                .then( response => {

                    setIsProcessingKeywords(false);

                    if ( response.status===200 ){
                        const status = parseInt(response.data.status);
                        const list = response.data.list;
                        const states = response.data.states;
                        const countries = response.data.countries;

                        const states_all = response.data.states_all;
                        const countries_all = response.data.countries_all;

                        const counter = response.data.counter;
                        const counterLocation = response.data.location;
                        
                        if ( parseInt(passVal.get_counters)===1 ){
        
                            let counterProfilesTemp = { ...counterProfiles,
                                                        company : parseInt(counter.company),
                                                        profile : parseInt(counter.profile),
                                                        category : parseInt(counter.category),
                                                        keyword : parseInt(counter.keyword)
                                                    };
                                                    
                            setCounterProfiles(counterProfilesTemp);

                            let counterLocationTemp = { ...counterLocation, local : parseInt(counterLocation.local) };
                            setCounterLocation(counterLocationTemp);
                                    
                            setListStates(states);
                            setListCountries(countries);

                            setKeywordListingStatesAll(states_all);
                            setKeywordListingCountriesAll(countries_all);

                            let counterTemp = 0;
                            Object.entries(states_all).map( ([key, row]) => {
                                counterTemp = parseInt(counterTemp) + parseInt(row.counter);
                            });
                            setTotalCounterStates(counterTemp);

                            counterTemp = 0;
                            Object.entries(countries_all).map( ([key, row]) => {
                                counterTemp = parseInt(counterTemp) + parseInt(row.counter);
                            });
                            setTotalCounterCountries(counterTemp);

                        }


                        if ( status===1 ){
                            setKeywordListing(list);

                        }else{
                            setKeywordListing([]);
                        }
                      
                    }else{
                        setKeywordListing([]);
                    }
                    
                }).catch( (err) => {
                    setKeywordListing([]);
                    setIsProcessingKeywords(false);
                });

        }else{
            setKeywordListing([]);
        }
    }


    const viewKeywordsEachCities = async ( selectedState= {}  ) => {

        if ( Object.entries(selectedState).length>0 ){
            setDefaultLocationView(22);
            setKeywordsStateCities(selectedState);
            setInternationalCountrySelected(selectedCountry);
            setKeywordNationalStage(nationalKeywordStages.selectedState);
        }
    }

    const viewKeywordsAllCities = async ( selectedState= {}  ) => {
        if ( Object.entries(selectedState).length===0 ){
            setKeywordNationalStage(nationalKeywordStages.allCities);

            if ( keywordsStateCities.hasOwnProperty('list') ){
                setDefaultLocationView(1);
                setKeywordSelectedState({ id : 0, state_name : keywordsStateCities.state_name, list : keywordsStateCities.list });
                setKeywordListing(keywordsStateCities.list);
            }

        }
    }

    const viewKeywordsSelectedCity = async ( selectedCity= {}  ) => {
        if ( Object.entries(selectedCity).length>0 ){

            if ( selectedCity.hasOwnProperty('list') ){

                setDefaultLocationView(1);
                setKeywordListing(selectedCity.list);
                
                setKeywordNationalStage(nationalKeywordStages.selectedCity);
                setKeywordSelectedState({ id : 0, state_name : keywordsStateCities.state_name });
                
                setKeywordsStateCity(selectedCity.detail);
            }
        }
    }

    const searchProfileByKeywordsStates = async ( selectedState = {} ) => {

        let allStatesKeywords = [];

        if ( defaultLocationType===2 ){
            setDefaultLocationView(2);

        }
        if ( Object.entries(selectedState).length===0 ){
            
            setKeywordSelectedState([]);
            setKeywordNationalStage(nationalKeywordStages.allStates);
           
            if ( Object.entries(keywordListingStatesAll).length>0 ){
                Object.entries(keywordListingStatesAll).map( ([ key, row ]) => {
                    allStatesKeywords[key] = row;
                });
            }
            
        }else{
            setKeywordSelectedState(selectedState);
            setKeywordNationalStage(nationalKeywordStages.selectedState);

            if ( selectedState.hasOwnProperty('list') ){
                Object.entries(selectedState.list).map( ([ key, row ]) => {
                        allStatesKeywords[key] = row;
                });
            }
        }

        if ( Object.entries(allStatesKeywords).length>0 ){
            setDefaultLocationView(1);
            setKeywordListing(allStatesKeywords);
        }
       
    }

    const searchProfileByKeywordsCountries = async ( selectedCountry = {} ) => {
        let allCountriesKeywords = [];

        setKeywordNationalStage(nationalKeywordStages.allCountries);

        if ( Object.entries(selectedCountry).length===0 ){
            setInternationalCountrySelected({});

            if ( Object.entries(keywordListingCountriesAll).length>0 ){
                Object.entries(keywordListingCountriesAll).map( ([ key, row ]) => {
                    allCountriesKeywords[key] = row;
                });
            }
            
        }else{
            
            if ( selectedCountry.hasOwnProperty('list') ){
                Object.entries(selectedCountry.list).map( ([ key, row ]) => {
                    allCountriesKeywords[key] = row;
                });
            }
        }

        if ( Object.entries(allCountriesKeywords).length>0 ){
            setDefaultLocationView(1);
            setKeywordListing(allCountriesKeywords);
        }
       
    }

    
    const searchProfileByKeywordsByCountryStates = async ( countryDetail = {} ) => {

        if ( Object.entries(countryDetail).length===0 ){ return false; }

        //setSelectedCountry(countryDetail);
        setInternationalCountrySelected(countryDetail);
        setDefaultLocationView(11);

        setKeywordNationalStage(nationalKeywordStages.selectedCountry);
        setListStatesCountries([]);

        const keyword = jQuery('#input_keyword').val();
      
        if ( ! Utilities.isEmpty(keyword) ){
   
            if ( typeof keyword==='undefined' ||  keyword.length<3 ){ return false; }

            const passVal = {
                country_id :  countryDetail.id.toString(),
                keywords : keyword
            }
    
            setIsProcessingKeywords(true);

            await axios
                .post( ApiUrls.mainCompanyKeywordsSearchByKeywordsShowStates, passVal )
                .then( response => {

                    setIsProcessingKeywords(false);

                    if ( response.status===200 ){
                        const status = parseInt(response.data.status);
                        const states = response.data.states;
                        const states_all = response.data.states_all;

                        if ( status===1 ){
                            setTotalCounterStates(response.data.counter_total);
                            setListStatesCountries(states);

                            setKeywordListingAllStates(states_all);
                        }
                    }

                }).catch( (err) => {
                    setIsProcessingKeywords(false);
                });

        }

    }


    const showAllKeywordsByState = async ( selectedState = {} ) => {


        if ( Object.entries(selectedState).length===0 ){
            setDefaultLocationView(1);
            setKeywordSelectedState([]);
            setKeywordListing(keywordListingAllStates);
            
            setKeywordNationalStage(nationalKeywordStages.allStates);
        }else{

            //setInternationalCountrySelected(selectedCountry);
            //setSelectedCountry({});

            setDefaultLocationView(22);
            setKeywordsStateCities(selectedState);
            setKeywordNationalStage(nationalKeywordStages.selectedState);
        }
        
    }

    const viewProfileList = async ( keywordProvided = '', detail = {} ) => {
        //console.log(keywordNationalStage);

        if ( Utilities.isEmpty(keywordProvided) ){ return false; }
        
        let allAcctIds = [];
        setKeywordsSelected(keywordProvided);

        if ( defaultLocationType===1 ){
            if ( Object.entries(keywordListing).length>0 ){
                if ( keywordProvided in keywordListing ){
                    const selected = keywordListing[keywordProvided.toString()] ?? {};
                    if ( selected.hasOwnProperty('account_id') ){
                        const explodedIds = selected.account_id.toString().split(',');
                        explodedIds.map( id => allAcctIds.push(id.toString() ) );
                    }

                }
            }

        }else if ( defaultLocationType===2 ){

           if ( keywordNationalStage===nationalKeywordStages.allCities ){
                const selected =  keywordsStateCities.list[keywordProvided.toString()] ?? {};
                if ( selected.hasOwnProperty('account_id') ){
                    const explodedIds = selected.account_id.toString().split(',');
                    explodedIds.map( id => allAcctIds.push(id.toString() ) );
                }

            }else if ( keywordNationalStage===nationalKeywordStages.selectedCity ){
                const selected =  keywordListing[keywordProvided.toString()] ?? {};
                if ( selected.hasOwnProperty('account_id') ){
                    const explodedIds = selected.account_id.toString().split(',');
                    explodedIds.map( id => allAcctIds.push(id.toString() ) );
                }

           }else{

                const selected =  Object.entries(keywordSelectedState).length>0  ? keywordSelectedState.list[keywordProvided.toString()] : keywordListingStatesAll[keywordProvided.toString()];
                if ( selected.hasOwnProperty('account_id') ){
                    const explodedIds = selected.account_id.toString().split(',');
                    explodedIds.map( id => allAcctIds.push(id.toString() ) );
                }
           }
           

        }else if ( defaultLocationType===3 ){

            if ( detail.hasOwnProperty('account_id') ){
                const explodedIds = detail.account_id.toString().split(',');
                explodedIds.map( id => allAcctIds.push(id.toString() ) );
            }

            if ( keywordSelectedState.hasOwnProperty('id') && keywordSelectedState.id===0 ){

                if ( keywordSelectedState.hasOwnProperty('list') ){
                    if ( keywordProvided.toString() in keywordSelectedState.list ){
                        setKeywordNationalStage(nationalKeywordStages.allStateCities);
                    }
                }else{
                    if (  allAcctIds.length>1 ){
                        setKeywordNationalStage(nationalKeywordStages.selectedStateCity);
                    }
                    
                }

            }
        }

        if ( allAcctIds.length===1 ){

            dispatch( showLoaderReducer(1) );
            const profileDetailRes = await QueryMasterSearchUtlities.getProfileDetail( allAcctIds[0] );
            dispatch( showLoaderReducer(0) );

            if ( Object.entries(profileDetailRes).length>0 ){
                if ( parseInt(profileDetailRes.status)===1  && parseInt(profileDetailRes.list.length)===1 ){
                    handleShow(profileDetailRes.list[0]);
                }
            }
            
        }else if ( allAcctIds.length>1 ){

            setDefaultLocationView(4);
            setIsProcessing(true);
            
            await  axios
                .post( ApiUrls.mainCompanyKeywordsGetProfileByIds, { account_ids : allAcctIds.join(',') }  )
                .then( response => {
                    setIsProcessing(false);

                    if ( response.status===200 ){
                        const responseData = response.data;
                        const status = responseData.status;
                        let list = responseData.list;
                        
                        if ( enableSortDistance===true ){
                            list = Utilities.sortCalculateDistance(list, 1);
                        }else{
                            if ( list.length>0 ){
                                if ( 'name' in list[0] ){
                                    const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                                    list = RM.sort(byName, list);
                                }
                            }
                        }

                        setListProfiles(list);

                    }

                }).catch( (err) => {
                    setIsProcessing(false);
                });

        }

    }

    const highlightKeywordInResults = ( name = '' ) => {
        const keyword = jQuery('#input_keyword').val().toLowerCase();
        return  Utilities.isEmpty(keyword) ? name : name.toLowerCase().replace(keyword, `<span class="keyword-string">${keyword}</span>`);
    }

    // SEARCH PROPER ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const searchProper = () => {

        setCounterLocation(counterLocationDefault);
        setIsProcessing(false);
        setKeywordsSelected('');

        // Send Axios request here
        if ( [1,11,3,4].includes( parseInt(defaultSearchType) ) ){
            cancelProfileTokenSource = axios.CancelToken.source();
            
            if ( ! RM.isEmpty(cancelCategoryTokenSource) ){
                cancelCategoryTokenSource.cancel();
            }

            if ( ! RM.isEmpty(cancelKeywordTokenSource) ){
                cancelKeywordTokenSource.cancel();
            }

            //setListProfiles([]);
            setCategoryListing([]);
            setKeywordListing([]);

            setDefaultLocationView(1);
            setShowLocationTab(true);
            searchNow();

        }else if ( [5].includes( parseInt(defaultSearchType) ) ){
            cancelCategoryTokenSource = axios.CancelToken.source();
            
            if ( ! RM.isEmpty(cancelProfileTokenSource) ){
                cancelProfileTokenSource.cancel();
            }

            if ( ! RM.isEmpty(cancelKeywordTokenSource) ){
                cancelKeywordTokenSource.cancel();
            }

            setListProfiles([]);
            setKeywordListing([]);

            setDefaultLocationView(0);
            setShowLocationTab(false);
            searchCategories();

        }else if ( [6].includes( parseInt(defaultSearchType) ) ){
            cancelKeywordTokenSource = axios.CancelToken.source();
            
            if ( ! RM.isEmpty(cancelProfileTokenSource) ){
                cancelProfileTokenSource.cancel();
            }

            if ( ! RM.isEmpty(cancelCategoryTokenSource) ){
                cancelCategoryTokenSource.cancel();
            }

            setCategoryListing([]);
            setKeywordListing([]);

            setShowLocationTab(true);
            searchProfileByKeywords();
        }
    }


    // INTERNAL / NATIONAL NAVIGATION ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleKeywordsCountryStateBackOption = () => {
        setDefaultLocationView(3);
    }

    const handleKeywordsProfileResultsBackOption = () => {
        //console.log(keywordNationalStage);

        if ( defaultLocationType===1 ){
            setDefaultLocationView(1);

        }else if ( defaultLocationType===2 ){
            
            if ( keywordNationalStage===nationalKeywordStages.allStates ){
                setDefaultLocationView(1);

            }else if ( keywordNationalStage===nationalKeywordStages.allCities ){
                setDefaultLocationView(1);

            }else if ( keywordNationalStage===nationalKeywordStages.selectedCity ){
                setDefaultLocationView(1);

            }

        }else if ( defaultLocationType===3 ){

            if ( keywordNationalStage===nationalKeywordStages.allCountries ){
                setDefaultLocationView(1);

            }else if ( keywordNationalStage===nationalKeywordStages.allStates ){
                setDefaultLocationView(1);
                
            }else if ( keywordNationalStage===nationalKeywordStages.selectedStateCity ){
                setDefaultLocationView(1);
            }
        }
    }


    const handleKeywordsResultsBackOption = () => {
        //console.log(keywordNationalStage);

        if ( defaultLocationType===2 ){

            if ( keywordNationalStage===nationalKeywordStages.allCities ){
                setDefaultLocationView(22);

            }else if ( keywordNationalStage===nationalKeywordStages.selectedCity ){
                setDefaultLocationView(22);
                
            }else{
                setDefaultLocationView(2);
            }

        }else if ( defaultLocationType===3 ){

            if ( keywordNationalStage===nationalKeywordStages.allCountries ){
                setDefaultLocationView(3);

            }else if ( keywordNationalStage===nationalKeywordStages.allStates ){
                setDefaultLocationView(11);
            
            }else if ( keywordNationalStage===nationalKeywordStages.allCities ){
                setDefaultLocationView(22);

            }else if ( [nationalKeywordStages.selectedCity, nationalKeywordStages.selectedStateCity ].includes(keywordNationalStage) ){
                setDefaultLocationView(22);
    
            }
        }
    }

    const handleKeywordsCityBackOption = () => {
        //console.log(keywordNationalStage);

        if ( defaultLocationType===2 ){
            setDefaultLocationView(2);

        }else if ( defaultLocationType===3 ){
            
            if ( keywordNationalStage===nationalKeywordStages.selectedState ){
                setDefaultLocationView(11);

            }else if ( keywordNationalStage===nationalKeywordStages.allCities ){
                setDefaultLocationView(11);

            }else if ( [nationalKeywordStages.selectedCity, nationalKeywordStages.selectedStateCity ].includes(keywordNationalStage) ){
                setDefaultLocationView(11);

            }
        }
    }

    

    // USEEFFECTS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {

        if ( Utilities.isEmpty(keywordSearchValue) ){
            setListProfiles([]);
            setCategoryListing([]);
            setKeywordListing([]);
            return false;
        }

        if ( Utilities.isEmpty(keywordSearchValue) || keywordSearchValue.length<3 ){
            setIsProcessing(false);
            return false;
        }

        const delayDebounceFn = setTimeout( () => {
            searchProper();
          }, 1000);


        return () => {
            clearTimeout(delayDebounceFn);
            setIsProcessing(false);
        };
        
    }, [keywordSearchValue]);
    

    useEffect( () => {
        setDefaultSearchValue(transcript);

        if (finalTranscript !== '') {
            setListeningStatus(false);
            defaultLocationValues();

            searchProper();
        }
        
    }, [transcript, finalTranscript]);


    useEffect(() => {
        searchProper();
    }, [defaultSearchType]);
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect( () => {
        
        if ( parseInt(props.showStatus)>0 ){
            
            defaultLocationValues();

            setNationalStateSelected({});
            setSelectedCategoryDetail({});
            //setShowContactProfileOption(false);
            setEnableSortDistance(false);
            //setCounterLocation(counterLocationDefault);
            //setCounterProfiles(counterTypeDefault);
            setDefaultSearchValue('');
            setListCountries([]);
            setListStates([]);
            setTotalCounterCountries(0);
            setTotalCounterStates(0);
            setListProfiles([])

            let searchTypeStatus = 1;
            let segment = Utilities.getUrlSegments();
            let urlSlug = segment.shift();

            urlSlug = typeof urlSlug!=='undefined' ? urlSlug.toString().toLowerCase() : '';

            if ( urlSlug==='dashboard'){
                PagesAuthentication.updateDirectorySearch( parseInt(props.showStatus) );
                searchTypeStatus = props.showStatus;
                
            }else{
                searchTypeStatus = PagesAuthentication.getDirectorySearchType() ?? props.showStatus ;
            }

            setDirectorySearchType(searchTypeStatus);

            if ( parseInt(searchTypeStatus)===2 ){
                setSearchInputClass('search-member');
                jQuery('div#account-type').text(11);
                setDefaultSearchType(11);
                //searchNow();

                setSelectedCountry({});

            }else{
                setSearchInputClass('');
                jQuery('div#account-type').text(3);
                setDefaultSearchType(3);
                //searchNow();

                setSelectedCountry({});
            }

            jQuery('#input_keyword').val('');
            document.getElementById('input_keyword').focus();


            navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setGlobalAudioStatus(true);
    
            }).catch(err => {
                setGlobalAudioStatus(false);
            }); 

            //getCountries();

        }

        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);
            }
        }

    }, [props.showStatus] );
    
    
    useEffect( () => {
        
        let applyChangeLocationTemp = applyChangeLocation
        if ( ! Utilities.isEmpty(applyChangeLocationTemp)  ){

            let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if ( userLocation!==null ){
                userLocation = JSON.parse(userLocation);
                setCurrentLocation(userLocation);
            }
            dispatch( applyChangeLocationReducer('') );
        }

    }, [applyChangeLocation] );


    useEffect( () => {

        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);
            }
        }
        
        let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
        if ( userLocation!==null ){
            userLocation = JSON.parse(userLocation);
            setCurrentLocation(userLocation);
        }

        return () => {
            setEnableSortDistance(false);
        }
    
    }, [] );


    if (!SpeechRecognition.browserSupportsSpeechRecognition() ) {
        //Utilities.messagePopup('Error', 'Speech Recognition is not supported by your browwer!');
        jQuery('#btn-main-search-mic').addClass('d-none');
        //return null;
    }



    return (
       <div className={ `main-search-component ${ parseInt(props.showStatus)>0 ? 'show' : ''  } ${ defaultSearchType===11 ? 'search-personal-email' : '' }`}>
            <div className="search-header">

                <div className="d-none notranslate">
                    <div id="account-type">3</div>
                    <div id="location-type">1</div>

                    <div id="country-id">{currentLocation.country_id}</div>
                    <div id="state-id">{currentLocation.state_id}</div>
                    <div id="city-id">{currentLocation.city_id}</div>

                    <div id="category-id">0</div>
                </div>


                <button type="button" className="btn-close-search" onClick={ () => closeSearchBox() }><span className="ion-ios7-close-empty"></span></button>

                <div className="container text-lowercase">

                    <ul className="location-option">
                        <li>
                            <div>Country</div>
                            <div className="cursor-pointer" onClick={ () => handleShowPopUp() }>{ currentLocation.country_name }</div>
                        </li>
                        <li>
                            <div>State</div>
                            <div className="cursor-pointer" onClick={ () => handleShowPopUp() }>{ currentLocation.state_name }</div>
                        </li>
                        <li>
                            <div>City</div>
                            <div className="cursor-pointer" onClick={ () => handleShowPopUp() }>{ currentLocation.city_name }</div>
                        </li>
                    </ul>

                    <ul className="keyword-option">
                        <li><input type="text" id="input_keyword" className="input-keyword" placeholder="search" value={ defaultSearchValue }  onChange={ (e) => { setKeywordSearchValue(e.target.value); pushValue( e, 'search-keyword'); }}  autoComplete="off" ></input></li>
                        <li><span  className="cursor-pointer" onClick={ () => resetSearchValue() }>Clear</span></li>
                        <li><button id="btn-main-search-mic" type="button" className={`btn-icon btn-mic ${ (listeningStatus===true) ? 'active' : '' }` } onClick={ () => startRecognition() }></button></li>
                    </ul>

                    {
                         Utilities.isEmpty(searchInputClass) ?
                                <ul className="profile-type-option">
                                    <li className={ (defaultSearchType===3 ? 'active' : '' ) } onClick={ () => switchTypeSearch(3) }>
                                        Company { ( parseInt(counterProfiles.company)>0 ) && <span></span> } </li>
                                    <li className={ (defaultSearchType===4 ? 'active' : '' ) } onClick={ () => switchTypeSearch(4) }>
                                        Profiles { ( parseInt(counterProfiles.profile)>0 ) && <span></span> }</li>
                                    
                                    { 
                                        parseInt(directorySearchType)!==1 && 
                                        <>
                                            <li className={ (defaultSearchType===5 ? 'active' : '' ) } onClick={ () => switchTypeSearch(5) }>
                                                Category { ( parseInt(counterProfiles.category)>0 ) && <span></span> }</li>
                                            <li className={ (defaultSearchType===6 ? 'active' : '' ) } onClick={ () => switchTypeSearch(6) }>
                                                Keywords { ( parseInt(counterProfiles.keyword)>0 ) && <span></span> }</li>
                                        </>
                                    }

                                    <li className="btn-option">
                                        <div className={`btn-icon btn-nearby ${ enableSortDistance===false ? 'opacity-05' : '' } `} onClick={ () => handleSortDistance() } title="nearby location"></div>
                                        <div className="btn-icon btn-contact"  title="directory search" onClick={ () => dispatch( showPopupContactSearchReducer({ showPopup : true, type : props.showStatus }) )}></div>
                                    </li>
                                </ul>
                            :
                                <ul className="profile-type-option">
                                    <li className={ (defaultSearchType===11 ? 'active' : '' ) } onClick={ () => switchTypeSearch(11) }>
                                        Email { ( parseInt(counterProfiles.emails)>0 ) && <span></span> } </li>
                                    <li className={ (defaultSearchType===1 ? 'active' : '' ) } onClick={ () => switchTypeSearch(1) }>
                                        Profiles { ( parseInt(counterProfiles.profile)>0 ) && <span></span> }</li>
                                </ul>
                    }

                </div>
            </div>

            <div className="search-result-content">
                    
                {
                    showLocationTab &&
                        <div className="container">
                            <ul className="result-option text-lowercase">
                                <li className={ (defaultLocationType===1 ? 'active' : '' ) } onClick={ () => switchLocationType(1) }>
                                    Local Results { ( parseInt(counterLocation.local)>0 ) && <span>{counterLocation.local}</span> }</li>

                                <li className={ (defaultLocationType===2 ? 'active' : '' ) } onClick={ () => switchLocationType(2) }>
                                    National Results { ( parseInt(counterLocation.national)>0 ) && <span>{counterLocation.national}</span> }</li>

                                <li className={ (defaultLocationType===3 ? 'active' : '' ) } onClick={ () => switchLocationType(3) }>
                                    International Results { ( parseInt(counterLocation.international)>0 ) && <span>{counterLocation.international}</span> }</li>
                            </ul>

                            {
                                ( defaultSearchType===5 && Object.entries(selectedCategoryDetail).length>0 ) &&
                                <div className="category-navigation-wrap text-lowercase pb-3 d-flex align-items-center">
                                    <div className="btn-back cursor-pointer" onClick={ () => showPreviousCategories() } ></div>
                                    <div className="font-gotham-book-16 ml-3">{ selectedCategoryDetail.cate_name }</div>
                                </div>
                            }
                        </div>
                }
                
                {
                    ( 
                        isProccesing && [11,3,4,5,6].includes( parseInt(defaultSearchType) ) &&
                        [4,1,11,22].includes(defaultLocationView)  && [2,3].includes(defaultLocationType)
                    ) &&
                     <div className="container pt-3">
                        <div className="state-navigation-wrap state-navigation-loader-wrap pb-3 global-preloader">
                            <div className="btn-back"></div>
                            <div className="font-gotham-book-16 ml-3"></div>
                            <div className="font-gotham-book-16 ml-3"></div>
                            <div className="font-gotham-book-16 ml-3"></div>
                        </div>
                    </div>
                }
                
                {   isProccesing && loaderProfileHtml() }
                
                <div className={`search-result-list ${ (showLocationTab && Object.entries(selectedCategoryDetail).length>0 ) ? 'category-profiles' : '' }`}>
                    <section className="container">
                    
                    {
                        isProccesing===false &&
                        <>
                            {
                                [5].includes( parseInt(defaultSearchType) ) &&
                                <div className="section-category text-lowercase text-center">
                                    { 
                                        
                                        showLocationTab===false && 
                                        <>
                                            {
                                                showCateogryLoader===false ?
                                                    Object.keys(categoryListing).length===0 ?
                                                        <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div>
                                                    :
                                                        <ul className="list-categories">
                                                            {
                                                                Object.entries(categoryListing).map( ( [key, row] ) =>
                                                                    <li key={key} onClick={ () => getSubCategoryById(row) }>
                                                                        <div className="category-image" style={{ backgroundImage:  `url(${row.category_image_url})`  }}></div>
                                                                        <div className="font-gotham-book-14 ellipsis-2 line-height-1-2 pt-2 mb-1" dangerouslySetInnerHTML={{__html: formattedCategoryName(row.cate_name) }}></div>
                                                                        {
                                                                            ( typeof row.parent_id!=='undefined' && parseInt(row.parent_id)>0 && typeof row.parent_detail.cate_name!=='undefined' ) &&
                                                                            <div className="font-gotham-light-12">{row.parent_detail.cate_name}</div>
                                                                        }
                                                                    </li>
                                                                )
                                                            }
                                                            
                                                        </ul>
                                                :
                                                    loaderCategoryHtml()
                                            }
                                        </>
                                    }

                                </div>

                            }
                           
                           {
                                [6].includes( parseInt(defaultSearchType) ) &&
                                <>  

                                    {/* ====================== PORFILE RESULTS ====================================================================== */}
                                    {
                                        defaultLocationView===4 && 
                                        <>
                                            {
                                                ! Utilities.isEmpty(keywordsSelected) &&
                                                <div className="state-navigation-wrap  pb-3">
                                                    <div className="btn-back cursor-pointer" onClick={ () => handleKeywordsProfileResultsBackOption() }></div>
                                                    {   
                                                        defaultLocationType===2 &&
                                                        <>
                                                            { keywordNationalStage===nationalKeywordStages.allStates &&  <div className="font-gotham-book-16 ml-3">{ Utilities.labelAllStates }</div> }
                                                            
                                                        </>
                                                    }

                                                    {   
                                                        defaultLocationType===3 &&
                                                        <>
                                                            <div className="font-gotham-book-16 ml-3">{ internationalCountrySelected.country_name ?? Utilities.labelAllCountries }</div>
                                                            { keywordNationalStage===nationalKeywordStages.allStates &&  <div className="font-gotham-book-16 ml-3">{ Utilities.labelAllStates }</div> }
                                                        </>
                                                    }

                                                    {
                                                        defaultLocationType>1 && 
                                                        <>
                                                            {
                                                                keywordNationalStage===nationalKeywordStages.allCities &&
                                                                <>
                                                                    <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div>
                                                                    <div className="font-gotham-book-16 ml-3">{ Utilities.labelAllCities }</div>
                                                                </>
                                                            }
                                                            {
                                                                [nationalKeywordStages.selectedCity, nationalKeywordStages.selectedStateCity ].includes(keywordNationalStage) &&
                                                                <>
                                                                    <div className="font-gotham-book-16 ml-3">{ keywordSelectedState.state_name }</div>
                                                                    <div className="font-gotham-book-16 ml-3">{ keywordsStateCity.city_name }</div>
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    <div className="font-gotham-book-16 ml-3">{keywordsSelected}</div>
                                                </div>
                                            }
                                            
                                            <ul className="profile-listing" >
                                                {  Object.entries(listProfiles).map( ([key, row] ) => profileListingHtml( key, row) ) }
                                            </ul>
                                        </>
                                    }
                                    

                                    {/* ====================== KEYWORDS RESULTS ====================================================================== */}
                                    {
                                        defaultLocationView===1 && 
                                        <div className="section-category">
                                            {
                                                isProcessingKeywords && 
                                                <ul className="list-keywords list-keywords-loader global-preloader">
                                                    {
                                                        [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) => (
                                                            <li key={key}>
                                                                <span className={`py-2 d-inline-block bg-gray-theme col-${ Math.floor(Math.random() * 4)+2 }`}></span>
                                                                <span className="counter bg-gray-theme d-inline-block bg-gray-theme col-1 py-2 ml-3"></span>
                                                            </li>
                                                        ))
                                                    }
                                                    
                                                </ul>
                                            }
                                            
                                            { 
                                                isProcessingKeywords===false && 

                                                    Object.keys(keywordListing).length===0 ?
                                                        <div className="text-center text-lowercase no-record-label  no-chats-label " >{ Utilities.labelNoFindResults }</div>
                                                    :
                                                    <>
                                                        {
                                                            defaultLocationType>1 && 
                                                            <div className="state-navigation-wrap pb-3">
                                                                {   
                                                                    defaultLocationType===2 &&
                                                                    <>
                                                                        <div className="btn-back cursor-pointer"  onClick={ () => handleKeywordsResultsBackOption() }></div>
                                                                    </>
                                                                }
                                                                {   
                                                                    defaultLocationType===3 &&
                                                                    <>
                                                                         <div className="btn-back cursor-pointer" onClick={ () => handleKeywordsResultsBackOption() }></div>
                                                                         { keywordNationalStage===nationalKeywordStages.allCountries &&  <div className="font-gotham-book-16 ml-3">{ Utilities.labelAllCountries }</div> }
                                                                         { internationalCountrySelected.hasOwnProperty('country_name') && <div className="font-gotham-book-16 ml-3">{ internationalCountrySelected.country_name }</div> }
                                                                         
                                                                    </>
                                                                }

                                                                { keywordNationalStage===nationalKeywordStages.allStates &&  <div className="font-gotham-book-16 ml-3">{ Utilities.labelAllStates }</div> }
                                                                
                                                                {
                                                                    keywordNationalStage===nationalKeywordStages.allCities &&
                                                                    <>
                                                                        <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div>
                                                                        <div className="font-gotham-book-16 ml-3">{ Utilities.labelAllCities }</div>
                                                                    </>
                                                                }
                                                                {
                                                                    [ nationalKeywordStages.selectedCity, nationalKeywordStages.selectedStateCity ].includes(keywordNationalStage) &&
                                                                    <>
                                                                        <div className="font-gotham-book-16 ml-3">{ keywordSelectedState.state_name }</div>
                                                                        <div className="font-gotham-book-16 ml-3">{ keywordsStateCity.city_name }</div>
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                       
                                                        <ul className="list-keywords">
                                                            {
                                                                Object.entries(keywordListing).map( ([kword, row] ) =>
                                                                    <li key={kword}  onClick={ () => viewProfileList(kword, row ) }>
                                                                        <span dangerouslySetInnerHTML={{__html: formattedCategoryName(kword) }}></span> <span className="counter ml-5">{row.counter}</span>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </>
                                            }
                                        </div>
                                    }
                                    
                                    {
                                        [11,2,22,3].includes(defaultLocationView) && 
                                        
                                        <div className="section-company-profiles">

                                            {/* ====================== NATIONAL STATES ====================================================================== */}
                                            { 
                                                [2].includes(defaultLocationView) &&
                                                    <div className="tab-list-national">
                                                        {
                                                            ( parseInt(totalCounterStates)===0 ||  Object.entries(listStates).length===0 ) ?
                                                                <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div> 
                                                            :
                                                                Object.entries(listStates).length>0 &&
                                                                    <ul className="states-listing">  
                                                                        <li onClick={ () => searchProfileByKeywordsStates() }>
                                                                            <div>
                                                                                <div>{ Utilities.labelShowAll }</div>
                                                                                <div><span className="counter">{ totalCounterStates }</span></div>
                                                                            </div>
                                                                        </li>

                                                                        { Object.entries(listStates).map( ([key, row] ) =>  parseInt(row.counter)>0 && <li key={key} onClick={ () => viewKeywordsEachCities(row) }  >{ stateListHtml(row) }</li> ) }
                                                                        
                                                                    </ul>
                                                        }

                                                    </div>
                                            }


                                            {/* ====================== CITIES ====================================================================== */}
                                            {
                                                ( [22].includes(defaultLocationView) && Object.entries(keywordsStateCities).length>0 ) && 

                                                <div className="tab-list-national  tab-list-cities">

                                                        {
                                                            defaultLocationType>1 && 
                                                            <div className="state-navigation-wrap pb-3">
                                                            {   
                                                                defaultLocationType===2 &&
                                                                <>
                                                                    <div className="btn-back cursor-pointer" onClick={ () => handleKeywordsCityBackOption() }></div>
                                                                    <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div>                                                                    
                                                                </>
                                                            }
                                                            {   
                                                                defaultLocationType===3 &&
                                                                <>
                                                                    <div className="btn-back cursor-pointer" onClick={ () => handleKeywordsCityBackOption() }></div>
                                                                    <div className="font-gotham-book-16 ml-3">{ internationalCountrySelected.country_name }</div>
                                                                    {
                                                                        [ nationalKeywordStages.selectedState, nationalKeywordStages.allCities, nationalKeywordStages.selectedCity, nationalKeywordStages.selectedStateCity ].includes(keywordNationalStage) && 
                                                                            <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div>
                                                                    }
                                                                    {/* { keywordNationalStage===nationalKeywordStages.selectedState &&  <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div> }
                                                                    { keywordNationalStage===nationalKeywordStages.allCities &&  <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div> }
                                                                    { keywordNationalStage===nationalKeywordStages.selectedCity &&  <div className="font-gotham-book-16 ml-3">{ keywordsStateCities.state_name }</div> } */}
                                                                </>
                                                            }
                                                            </div>
                                                        }

                                                    <ul className="states-listing">  
                                                        <li  onClick={ () => viewKeywordsAllCities() }>
                                                            <div>
                                                                <div>{ Utilities.labelShowAll }</div>
                                                                <div><span className="counter">{ keywordsStateCities.counter }</span></div>
                                                            </div>
                                                        </li>
                                                        {
                                                            Object.entries(keywordsStateCities.cities ?? {}).map( ([key, row] ) =>
                                                                <li key={key}>
                                                                    <div onClick={ () => viewKeywordsSelectedCity(row) }>
                                                                        <div>{ row.detail.city_name }</div>
                                                                        <div><span className="counter">{ row.counter }</span></div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        
                                                    </ul>
                                                </div>
                                            }



                                            {/* ====================== INTERNATIONAL COUNTRIES ====================================================================== */}
                                            {
                                                ( [3].includes(defaultLocationView) && Object.entries(selectedCountry).length===0 ) &&
                                                    <div className="tab-list-international">
                                                    {
                                                        parseInt(totalCounterCountries)===0 ?
                                                            <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div> 
                                                        :
                                                            <ul className="countries-listing">  
                                                                <li  onClick={ () => searchProfileByKeywordsCountries() }>
                                                                    <div>
                                                                        <div className="country-logo" style={{ backgroundImage:  `url(${flag_dir}worldwide.svg)` } }></div>
                                                                        <div>{ Utilities.labelShowAll }</div>
                                                                        <div><span className="counter">{ totalCounterCountries }</span></div>
                                                                    </div>
                                                                </li>

                                                                { Object.entries(listCountries).map( ([key, row] ) =>  parseInt(row.counter)>0 && <li key={key} onClick={ () => searchProfileByKeywordsByCountryStates(row) }>{ countryListHtml( row ) }</li> ) }
                                                                
                                                            </ul>
                                                    }
                                                        
                                                    </div>
                                            }


                                             {/* ====================== INTERNATION COUNTRY -> STATES ====================================================================== */}

                                            {
                                                ( [11].includes(defaultLocationView) && Object.entries(internationalCountrySelected).length>0 ) &&
                                                    <div className="tab-list-national" >

                                                        <div className="state-navigation-wrap  pb-3">
                                                            <div className="btn-back cursor-pointer" onClick={ () => handleKeywordsCountryStateBackOption() }></div>
                                                            <div className="font-gotham-book-16 ml-3">{internationalCountrySelected.country_name}</div>
                                                        </div>
                                                        
                                                        <ul className="states-listing country-states-listing">  
                                                            {
                                                                isProcessingKeywords===false &&
                                                                <>
                                                                    <li onClick={ () => showAllKeywordsByState() }>
                                                                        <div>
                                                                            <div>{ Utilities.labelShowAll }</div>
                                                                            <div><span className="counter">{ totalCounterStates }</span></div>
                                                                        </div>
                                                                    </li>

                                                                    { Object.entries(listStatesCountries).map( ([key, row] ) =>  parseInt(row.counter)>0 && <li key={key} onClick={ () => showAllKeywordsByState(row) } >{ stateListHtml(row) }</li> ) }
                                                                </>
                                                            }
                                                            
                                                        </ul>

                                                        {
                                                            isProcessingKeywords && 
                                                            <ul className="states-listing country-states-listing list-keywords-loader global-preloader">
                                                                {
                                                                    [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                                                                        <li key={key}>
                                                                            <div><span className={`py-2 d-inline-block bg-gray-theme col-${ Math.floor(Math.random() * 4)+8 }`}></span></div>
                                                                        </li>
                                                                    )
                                                                }
                                                                
                                                            </ul>
                                                        }
                                                        
                                                    </div>
                                            }
                                            
                                            
                                        </div>
                                    }
                                    
                                </>
                            }

                            {
                                ( [1,11,3,4,5].includes( parseInt(defaultSearchType) ) && showLocationTab===true ) && 
                                <div className="section-company-profiles">

                                    <div className={ `tab-list-international ${ defaultLocationView===3  ? '' : 'd-none'  } ${ Object.entries(selectedCountry).length===0 ? '' : 'd-none-force' } ` }>
                                        {
                                            parseInt(totalCounterCountries)===0 ?
                                                <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div> 
                                            :
                                                <ul className="countries-listing">  
                                                    <li onClick={ () => searchProfileDirectBol===false ?  searchByCountry( 0 ) :  customCategoryGetAllStatesByCountry() }>
                                                        <div>
                                                            <div className="country-logo" style={{ backgroundImage:  `url(${flag_dir}worldwide.svg)` } }></div>
                                                            <div>{ Utilities.labelShowAll }</div>
                                                            <div><span className="counter">{ totalCounterCountries }</span></div>
                                                        </div>
                                                    </li>
                                                    {
                                                        Object.entries(listCountries).map( ([key, row] ) => 
                                                            parseInt(row.counter)>0 &&
                                                                <li key={key} onClick={ () => searchProfileDirectBol===false ? searchByCountry( row.id, row) : customCategoryGetAllStatesByCountry(row.id)  }>
                                                                    { countryListHtml(row) }
                                                                </li>
                                                        )
                                                    }
                                                    
                                                </ul>
                                        }
                                            
                                    </div>

                                        
                                    {
                                        ( Object.entries(selectedCountry).length>0 && ! ( [1,22].includes(defaultLocationView) ) ) &&
                                            <div className="tab-list-national">
                                                <div className="state-navigation-wrap  pb-3">
                                                    <div className="btn-back cursor-pointer" onClick={ () => handleBackToInternationalCountry() }></div>
                                                    <div className="font-gotham-book-16 ml-3">{selectedCountry.country_name}</div>
                                                </div>
                                                <ul className="states-listing country-states-listing"> 
                                                    {
                                                        Object.entries(listStatesCountries).length>0 &&
                                                            <li onClick={ () => showProfilesByState() }>
                                                                <div>
                                                                    <div>{ Utilities.labelShowAll }</div>
                                                                    <div><span className="counter">{ selectedCountry.counter}</span></div>
                                                                </div>
                                                            </li>
                                                    }
                                                    {
                                                        Object.entries(listStatesCountries).map( ([key, row] ) =>
                                                            parseInt(row.counter)>0 &&
                                                                <li key={key} onClick={ () => searchProfileDirectBol===false ? showCitiesByState(row ) : customCategoryGetAllAccountIdsByState( row.country_id, row.id ) } >
                                                                    { stateListHtml(row) }
                                                                </li>
                                                        )
                                                    }
                                                
                                                </ul>
                                            </div>
                                    }


                                    <div className={ `tab-list-national ${ defaultLocationView===2 ? '' : 'd-none' } ` }>
                                        {
                                            parseInt(totalCounterStates)===0 ?
                                                <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div> 
                                            :
                                                <ul className="states-listing">  
                                                    <li onClick={ () => searchProfileDirectBol===false ? searchByState( 0 , 0 ) :  customCategoryGetAllAccountIdsByState() }>
                                                        <div>
                                                            <div>{ Utilities.labelShowAll }</div>
                                                            <div><span className="counter">{ totalCounterStates }</span></div>
                                                        </div>
                                                    </li>
                                                    {
                                                        Object.entries(listStates).map( ([key, row] ) =>
                                                            parseInt(row.counter)>0 &&
                                                                <li key={key} onClick={ () => searchProfileDirectBol===false ? showCitiesByState(row ) :  showProfilesByAccountIdsCategories( row.ids ) } >
                                                                    { stateListHtml(row) }
                                                                </li>
                                                        )
                                                    }
                                                    
                                                </ul>
                                        }

                                    </div>

                                    <div className={ `tab-list-national tab-list-cities ${ defaultLocationView===22 ? '' : 'd-none' } ` }>
                                        {
                                            parseInt( typeof nationalStateSelected.counter!=='undefined' ? ( nationalStateSelected.counter || 0 ) : 0  )===0 ?
                                                <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div> 
                                            :
                                                <>
                                                    <div className="state-navigation-wrap  pb-3">
                                                        {  defaultLocationType===2 && <div className="btn-back cursor-pointer" onClick={ () => setDefaultLocationView(2) }></div>  }
                                                        {  
                                                            defaultLocationType===3 && 
                                                            <>
                                                                <div className="btn-back cursor-pointer" onClick={ () => handleBackToInternationalState() }></div> 
                                                                <div className="font-gotham-book-16 ml-3">{internationalCountrySelected.country_name}</div>
                                                            </>
                                                        }
                                                        <div className="font-gotham-book-16 ml-3">{nationalStateSelected.state_name}</div>
                                                    </div>

                                                    <ul className="states-listing">  
                                                        {
                                                            parseInt(nationalStateSelected.counter)>0 &&
                                                                <li onClick={ () => searchByState( nationalStateSelected.country_id, nationalStateSelected.id, nationalStateSelected ) }>
                                                                    <div>
                                                                        <div>{ Utilities.labelShowAll }</div>
                                                                        <div><span className="counter">{ nationalStateSelected.counter }</span></div>
                                                                    </div>
                                                                </li>
                                                        }
                                                        {
                                                            Object.entries(nationalStateSelected.cities).map( ([key, row] ) =>
                                                                parseInt(row.counter)>0 &&
                                                                    <li key={key} onClick={ () => showProfilesByCity(row) }>
                                                                        <div>
                                                                            <div>{ row.city_name }</div>
                                                                            <div><span className="counter">{ row.counter }</span></div>
                                                                        </div>
                                                                    </li>
                                                            )
                                                        }
                                                        
                                                    </ul>
                                                </>
                                        }

                                    </div>


                                    <div className={ `tab-list-local ${ defaultLocationView===1 ? '' : 'd-none' } ` }>
                                        
                                        {
                                            Object.keys(listProfiles).length===0 ?
                                                <div className="text-center text-lowercase no-record-label  no-chats-label" >{ Utilities.labelNoFindResults }</div> 
                                            :
                                                <>
                                                    {
                                                        (
                                                            Object.entries( typeof nationalStateSelected!=='undefined' ? nationalStateSelected : {}).length>0 &&
                                                            Object.entries( typeof nationalCitySelected!=='undefined' ? nationalCitySelected : {}).length>0 &&
                                                            defaultLocationType===2
                                                        ) && 
                                                            <div className="state-navigation-wrap  pb-3">
                                                                <div className="btn-back cursor-pointer" onClick={ () => handleBackToNationalCity() }></div>
                                                                <div className="font-gotham-book-16 ml-3">{nationalStateSelected.state_name}</div>
                                                                <div className="font-gotham-book-16 ml-3">{nationalCitySelected.city_name}</div>
                                                            </div>
                                                    }
                                                    {
                                                        (
                                                            Object.entries( typeof nationalStateSelected!=='undefined' ? nationalStateSelected : {}).length>0 &&
                                                            Object.entries( typeof nationalCitySelected!=='undefined' ? nationalCitySelected : {}).length===0 &&
                                                            defaultLocationType===2
                                                        ) && 
                                                            <div className="state-navigation-wrap  pb-3">
                                                                <div className="btn-back cursor-pointer" onClick={ () => handleBackToNationalState() }></div>
                                                                <div className="font-gotham-book-16 ml-3">{nationalStateSelected.state_name}</div>
                                                            </div>
                                                    }

                                                    {
                                                        (
                                                            Object.entries( typeof nationalStateSelected!=='undefined' ? nationalStateSelected : {}).length===0 &&
                                                            Object.entries( typeof nationalCitySelected!=='undefined' ? nationalCitySelected : {}).length>0 &&
                                                            defaultLocationType===2
                                                        ) && 
                                                            <div className="state-navigation-wrap  pb-3">
                                                                <div className="btn-back cursor-pointer" onClick={ () => handleBackToNationalStateFromCity( nationalCitySelected ) }></div>
                                                                <div className="font-gotham-book-16 ml-3">{nationalCitySelected.state_name}</div>
                                                                <div className="font-gotham-book-16 ml-3">{nationalCitySelected.city_name}</div>
                                                            </div>
                                                    }



                                                    {
                                                        (
                                                            Object.entries( typeof internationalCountrySelected!=='undefined' ? internationalCountrySelected : {}).length>0 &&
                                                            defaultLocationType===3
                                                        ) && 
                                                            <div className="state-navigation-wrap  pb-3">
                                                                {
                                                                    Object.entries( typeof nationalCitySelected!=='undefined' ? nationalCitySelected : {}).length>0 ?
                                                                        <>
                                                                            <div className="btn-back cursor-pointer" onClick={ () => handleBackToInternationalCity() }></div>
                                                                            <div className="font-gotham-book-16 ml-3">{internationalCountrySelected.country_name}</div>
                                                                            <div className="font-gotham-book-16 ml-3">{nationalStateSelected.state_name}</div>
                                                                            <div className="font-gotham-book-16 ml-3">{nationalCitySelected.city_name}</div>
                                                                        </>
                                                                    :
                                                                        Object.entries(internationalStateSelected).length>0 ?
                                                                        <>
                                                                            <div className="btn-back cursor-pointer" onClick={ () => handleBackToInternationalCountryStates() }></div>
                                                                            <div className="font-gotham-book-16 ml-3">{internationalCountrySelected.country_name}</div>
                                                                            <div className="font-gotham-book-16 ml-3">{internationalStateSelected.state_name}</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="btn-back cursor-pointer" onClick={ () => handleBackToInternationalCountry() }></div>
                                                                            <div className="font-gotham-book-16 ml-3">{internationalCountrySelected.country_name}</div>
                                                                        </>
                                                                }
                                                            </div>
                                                    }
                                                    
                                                    <ul className="profile-listing" >
                                                        {  Object.entries(listProfiles).map( ([key, row] ) => profileListingHtml( key, row) ) }
                                                    </ul>
                                                </>
                                            }
                                        
                                    </div>


                                </div>
                            }
                       </>
                    }
                    
                    </section>

                </div>

            </div>

            <MainSearchProfile  handleShowProfilePopup={show} handleCloseProfilePopup={handleClose} profileSelected={profileSelected} showStatus={props.showStatus} closeSearchBox={closeSearchBox}  />

            <CitySearchPopup  showCityPopup={ showCityPopup } handleClosePopUp={ handleClosePopUp } passCityDetail={ passCityDetail } />

       </div>
    )
}

export default MainSearch;