import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; 

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle,  showMainSettings as showMainSettingsReducer} from '../../../actions';
import { unfollowProfiles, getFollowingList } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';

const FollowSettings = () => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;

    const [ userLoginDetail, setUserLoginDetail ] = useState({});
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ showProcessing , setShowProcessing ] = useState(true);

    const [ unFollowManage, setUnFollowManage ] = useState(false);
    const [ memberIDsSelected , setMemberIDsSelected ] = useState([]);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getFollowingListing = async ( profile = {} ) => {
        
        const passval = {
            api_token : API_TOKEN.toString(),
            master_account_id : profile.account_id.toString(),
        };

        setUnFollowManage(false);
        setShowProcessing(true);
        const companyFollowersResponse = await getFollowingList(passval);
        setShowProcessing(false);
        
        if ( Object.keys(companyFollowersResponse).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(companyFollowersResponse.success)===1 ){
            setListProfiles(companyFollowersResponse.result.company_following_list);


        }else{
            Utilities.messagePopup('error', companyFollowersResponse.errorMessage );
        }
    }

    const toggleActiveClass = ( e,  member = {} ) => {

        if ( !unFollowManage ){
            setMemberIDsSelected([]);
            return false;
        }

        let memberIDsTemp = memberIDsSelected;
        let id = member.group_id.toString();
        
        ! ( memberIDsTemp.includes(id) ) ? memberIDsTemp.push(id) : ( memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)] ) ;
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        setMemberIDsSelected(memberIDsTemp);
    }

    const handleClickCancel = ( action = false) => {
        setUnFollowManage(action);
        setMemberIDsSelected([]);
    }

    const handleClickSubmitStatus = async ( submitAction = 0 ) => {

        if ( memberIDsSelected.length===0 ){
            return false;
        }

        submitAction = parseInt(submitAction);

        if ( submitAction===0 ){

            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishToUnfollowCompany,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'no' },
                    { label: 'yes',  onClick: () => handleClickSubmitStatus(1) }
                ]
            }); 

            return false;
        }

        const button_name = 'btn-submit-status';
        const passval = {
            api_token : API_TOKEN.toString(),
            account_id : userLoginDetail.account_id.toString(),
            group_id : memberIDsSelected.join(',')
        };


        Utilities.elementDisabledStatus(button_name, true );
        const responseActionDb = await unfollowProfiles(passval);
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(responseActionDb).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(responseActionDb.success)===1 ){
            Utilities.messagePopup('success', responseActionDb.successMessage );
            await getFollowingListing(PagesAuthentication.getMasterAccountDetail());

        }else{
            Utilities.messagePopup('error', responseActionDb.errorMessage );
        }

    }

    useEffect( async () => {
        
        PagesAuthentication.getUserLoggedin();

        const userAccountDetailTemp = PagesAuthentication.getMasterAccountDetail();
        await getFollowingListing(userAccountDetailTemp);

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        setUserLoginDetail(userLoginDetailTemp);

        dispatch( pageTitle(`${TitlesLabels.general.followSettings}`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );

       
    }, [] );

    return (
        <div className="notication-company-followers-page team-members-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <Link to={`/${UrlSlugs.settings_notifications}`}>{ TitlesLabels.navigations.notifications }</Link>
                            <div className={`${!unFollowManage ? 'current' : 'cursor-pointer'}`}  onClick={ () => handleClickCancel() }>{ TitlesLabels.general.followSettings }</div>
                            { unFollowManage && <div className="current">{ TitlesLabels.general.unfollow }</div>}
                           
                          </li>
                         <li className="btn-option">
                            {
                                Object.keys(listProfiles).length>0 &&
                                <>
                                    <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() } title={ TitlesLabels.general.searchProfile }></div>
                                    <div className="btn-icon btn-person-remove-2" onClick={ () => handleClickCancel(true) }  title={ TitlesLabels.general.unfollow }></div>
                                </>
                            }
                            
                            <div className="btn-icon btn-gear" onClick={ () => dispatch( showMainSettingsReducer( new Date() ) ) } title={ TitlesLabels.settings } ></div>
                         </li>
                     </ul>
                </div>

            </div>

            <div className="main-content position-relative">
                <div className="container">

                    { 
                            showProcessing ?
                                <ul className="listing-profiles-preloader mt-4">
                                {
                                    [...Array(12).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                                </ul>
                            :
                                <>
                                {
                                    Object.keys(listProfiles).length===0 ?
                                        <div className="text-center text-lowercase no-record-label ">{ Utilities.labelRecordAvailable }</div>
                                    :
                                        <>
                                            <div className="profile-search-wrap d-none">
                                                <div className="profile-search-content">
                                                    <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                                    <div></div>
                                                    <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                                    <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                                                </div>
                                            </div>
                                            
                                            <ul id="listing-profiles" className={`listing-profiles max-height-fit my-4 cursor-auto`}>
                                            {
                                                Object.entries(listProfiles).map( ([key, row] ) =>
                                                    <li key={key} data-keyword={row.name} className={ `${ memberIDsSelected.includes(row.group_id.toString()) ? 'selected cursor-pointer' : unFollowManage ? 'cursor-pointer' : 'cursor-auto'  }` } onClick={ (e) => toggleActiveClass(e, row ) }>
                                                        <div className="profile-wrap">
                                                            <div className="profile-image-wrap"><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                                            <div className="profile-name notranslate" data-name={row.name}>{row.name} </div>
                                                            <div className="profile-label">{row.title }</div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            </ul>

                                            {
                                                unFollowManage &&
                                                <div className="button-group-boolean col-9 mx-auto pt-5 mt-5">
                                                    <button type="button" className="btn-theme-black" onClick={ () => handleClickCancel() }>cancel</button>
                                                    <button id="btn-submit-status" type="button" className="btn-theme-black button-has-loader" disabled={ memberIDsSelected.length===0 ? true : false } onClick={ () => handleClickSubmitStatus() }>submit</button>
                                                </div>
                                            }
                                        </>
                                }
                                </>
                        }

                </div>
            </div>
       </div>
    )
}

export default FollowSettings;