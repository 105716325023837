import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';

import { showLoader, showPopupQuickLinks as showPopupQuickLinksReducer, toggleAutomatedReply as toggleAutomatedReplyReducer, toggleMenuPriceList as toggleMenuPriceListReducer, toggleDepartmentDefaultMessage as toggleDepartmentDefaultMessageReducer, changeCityPopup as changeCityPopupReducer,showLanguageSelectionPopup as showLanguageSelectionPopupReducer } from '../../actions';

import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import ApiUrls from '../../helpers/ApiUrls';

import { talkToUsConversation } from '../../helpers/BizcomAssist';
import ChatUtilities from '../../helpers/ChatUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';


const QuickLinks = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN_BIZCOM = process.env.REACT_APP_chat_api_token_bizcom;
    const BIZCOM_URL = process.env.REACT_APP_bizcom_url;

    const showPopupQuickLinksRedux = useSelector( state => state.showPopupQuickLinks);

    const [ showPopup, setShowPopup ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    /* const sampleList = [
        { title : 'title 1', description : 'description', link : {} },
        { title : 'title 2', description : 'description', link : {} },
        { title : 'title 3', description : 'description', link : {} },
        { title : 'title 4', description : 'description', link : {} },
        { title : 'title 5', description : 'description', link : {} },
    ]; */

    const [ defaultValue, setdefaultValue ] = useState('');
    const [ resultList, setResultList ] = useState([]);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='keywords'){
            setdefaultValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-quicklist > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const handleClosePopUp = () => {
        setShowPopup(false);
        dispatch( showPopupQuickLinksReducer(false) );
    }

    const getQuickList = async () => {

        setLoading(true);

        const userLoggedinDetail = PagesAuthentication.getUserLoggedinDetail();
        let passVal = {
            api_token : TOKEN_BIZCOM.toString(),
            account_id : userLoggedinDetail.account_id.toString(),
            account_type : userLoggedinDetail.account_type.toString(),
            group_id : userLoggedinDetail.hasOwnProperty('group_id') ? userLoggedinDetail.group_id.toString() : '0',
            flag : '3'
        }

        await axios
                .post( ApiUrls.bizcomSearchQuickLink, passVal )
                .then( response => {
                    
                    if ( parseInt(response.status)===201 ){
                        if (parseInt(response.data.success)===1 ){
                            let quickListResult = response.data.result.quicklink_result;
                            let quickListResultFilter = [];

                            // ONLY HAVE LINKS
                            Object.entries(quickListResult).map( ([key, row]) => {
                                if ( Object.entries(row.link).length>0 ){
                                    quickListResultFilter.push(row);
                                }
                            });

                            quickListResultFilter.sort(Utilities.fieldSorter(['title']) );
                            //console.log(quickListResultFilter);
                            setResultList(quickListResultFilter);
                        }
                    }

                    setLoading(false);

                })
                .catch( (err) => {  setLoading(false); }); 

    }

    const handleClickSelected = ( detail = {} ) => {

        if ( Object.entries(detail).length>0 ){
            const userLoggedinDetail = PagesAuthentication.getUserLoggedinDetail();
            const accountIdEncoded = btoa(userLoggedinDetail.account_id);
            //console.log(userLoggedinDetail);

            const slugTitle = detail.title.replaceAll(' ', '-').toLowerCase();
            const providedUrl = detail.link.hasOwnProperty('link') ? detail.link.link.toLowerCase() : '';

            //console.log(slugTitle, detail);
            if ( ! Utilities.isEmpty(providedUrl) ){

                if ( ['automated-reply'].includes(slugTitle) ) {
                    dispatch( toggleAutomatedReplyReducer(new Date()) );
    
                }else if (
                            ['add-department', 'add-team-member', 'team-member-list', 'update-team-member-photo', 'update-team-member-profile', 'edit/delete-team-member', 'department-list',
                            'edit/delete-department', 'update-company-address', 'update-company-information', 'update-work-profile', 'change-company-photo', 'change-my-work-page-photo',
                            'divert'].includes(slugTitle) 
                        ) {

                        history.push(`/${ providedUrl }/${accountIdEncoded}`);

                }else if ( ['edit/delete-menu/products'].includes(slugTitle) ) {
                    dispatch( toggleMenuPriceListReducer( userLoggedinDetail ) );

                }else if ( ['change-location'].includes(slugTitle) ) {
                    dispatch( changeCityPopupReducer(Utilities.cityLocationLabel) );

                }else if ( ['change-language'].includes(slugTitle) ) {
                    dispatch( showLanguageSelectionPopupReducer(Date.now()) );

                }else if ( ['default-message'].includes(slugTitle) ) {
                    dispatch( toggleDepartmentDefaultMessageReducer( new Date() ) );

                }else if ( ['help-center', 'bizcom247'].includes(slugTitle) ) {
                    window.open( providedUrl, '_blank');
                    //window.open(BIZCOM_URL+'/'+providedUrl, '_blank');

                }else if ( ['talk-to-team'].includes(slugTitle) ) {
                    switchToMasterAccountAndBizcomChat();

                }else{
                    history.push(`/${ providedUrl }`);
                }
                
            }


            handleClosePopUp();
        }
    }


    const switchToMasterAccountAndBizcomChat = async () => {

        dispatch( showLoader(0) );
        
         // ASSOCIATED ACCOUNTS
         let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
         if ( accountsList!==null ){
             accountsList = JSON.parse(accountsList);
 
             if ( Object.keys(accountsList).length>0 ){

                const accountListNormalArray = Utilities.toNormalArrayObject(accountsList);
                let personalAccount = accountListNormalArray.filter( account => parseInt(account.account_type) === 1)[0]  ?? [];
               
                if ( Object.keys(personalAccount).length>0 ){

                    dispatch( showLoader(1) );

                    // UPDATE LOGIN - PUT MASTER ACCOUNT
                    PagesAuthentication.setLoginAccountDetail(personalAccount);

                    // REDIRECT TO BIZCOM GENERAL CHAT
                    const responseInformation = await talkToUsConversation( parseInt(personalAccount.account_id) );
                    dispatch( showLoader(0) );

                    if ( Object.keys(responseInformation).length>0 ){
                        const profileChat = responseInformation.bizchat_conversation.conversation_list;
                        let passValues = ChatUtilities.encryptChatDetail(profileChat); 

                        ChatUtilities.saveAccountsConversationDetail(profileChat );
                        history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileChat.account_id) )}/${passValues}`);
                    }

                }

             }
         }
    }

    useEffect( () => {
        setShowPopup(showPopupQuickLinksRedux);

        if ( showPopupQuickLinksRedux===true ){
            getQuickList();
        }
       
        return () => {
            setResultList([]);
            setdefaultValue('');
        }

    }, [showPopupQuickLinksRedux] );
    

    return (

       <Modal id="popup-quick-links"  show={ showPopup } onHide={ handleClosePopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md" dialogClassName="modal-dialog-theme"  >
           <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline pt-4 text-lowercase border-0">
               { TitlesLabels.general.quickLinks }
            </Modal.Header>
            <Modal.Body className="bg-white popup-quick-links-content">

                 
                <div className="text-lowercase form-theme pt-1 mx-4 col-10 mx-auto mb-5">
                        
                    <div id="from-group-city" className="form-group button-has-loader">
                        <input type="text" id="input_city_keyword"  className="form-control text-lowercase" placeholder=" " value={ defaultValue }  onChange={ (e) => pushValue( e, 'keywords') }  autoComplete="off" autoFocus ></input>
                        <label className="form-label">search</label>
                    </div>

                    {   
                        loading ?
                            <ul className="quick-links-list mb-4 global-preloader">
                                {
                                    [...Array(6).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="py-2 bg-gray border-radius-25"></div>
                                        </li>
                                    )
                                }
                            </ul>
                        :
                            Object.entries(resultList).length>0 ?
                                <ul id="listing-quicklist" className="quick-links-list mb-4">
                                    {
                                        Object.entries(resultList).map( ([key, row] ) =>
                                            <li key={key} data-keyword={row.title.toLowerCase()} onClick={ () => handleClickSelected(row) } >
                                                <div className="notranslate" data-name={row.title}>{ row.title }</div>
                                            </li>
                                        )
                                    }
                                </ul>
                            :
                                <div className="text-center text-color-gray mt-3 mb-5">{ Utilities.labelDetailAvailable }</div>
                    
                    }

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default QuickLinks;
