import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Utilities from './../helpers/Utilities';
import axios from 'axios'
import * as RM from 'ramda';

import SessionUtilities from '../helpers/SessionUtilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';

import { switchProfile as switchAccount, reloadAccountsDB, showLoader, accountsWithNotification, globalProfile, globalNotificationCounter as globalNotificationCounterReducer } from '../actions';

import HeaderDepartmentsViewChat from '../components/popups/HeaderDepartmentsViewChat';
import PagesAuthentication from '../helpers/PagesAuthentication';

const Header = ( props ) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    let imgBizLogo = `<div style="background-image:url('${process.env.REACT_APP_img_folder}/logo/logo-light.svg')" class="logo-img" ></div>`;
    let profileWithNotifcations = {};

    const [ companyIdSelected, setCompanyIdSelected ] = useState(0);
    const [ listAccounts, setListAccounts ] = useState([]);
    const [ listNotifications, setListNotifications ] = useState([]);
    const [ profileLoggedin, setProfileLoggedin ] = useState({});
    const [ profileLoader, setProfileLoader ] = useState(0);
    const [ loginType , setLoginType ] = useState(0);

    const [ popupDepartmentsViewChat, setPopupDepartmentsViewChat] = useState(false);
    const [ profileSelected, setProfileSelected] = useState({});

    const globalProfileRequest = useSelector( state => state.globalProfile);
    const passConversList = useSelector( state => state.passConversList);
    const bizcomplusSwitchAccountID = useSelector( state => state.bizcomplusSwitchAccount);
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleCloseDepartmentsViewChat = () => setPopupDepartmentsViewChat(false);

    const showDepartmentsViewChat = ( profile ) => {
        setProfileSelected(profile);
        setPopupDepartmentsViewChat(true);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const expandShowCompanies = ( profile) => {
        let companyIdSelectedTemp = 0;
        if ( profile.hasOwnProperty('team_company_id') ){
            const team_company_id = parseInt(profile.team_company_id || 0);
            companyIdSelectedTemp = team_company_id===parseInt(companyIdSelected) ? 0 : team_company_id;
        }
        setCompanyIdSelected( companyIdSelectedTemp);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const updatedLoggedinProfileSession = ( profile_data ) => {

        PagesAuthentication.setLoginAccountDetail( profile_data );

        /* let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                localStorage.setItem(
                    process.env.REACT_APP_session_login,
                    JSON.stringify(profile_data)
                );
            }
        } */
    }

     ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const autoExpandAccount = () => {
        
        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);

                const companyIdSelectedTemp = parseInt( profileLoggedinTemp.hasOwnProperty('team_company_id') ? ( profileLoggedinTemp.team_company_id || 0 ) : 0);
                setCompanyIdSelected( companyIdSelectedTemp);

            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const updateLoggedinProfile = ( reload = false ) => {

        let profileLoggedinTemp = [];

        // LOGGEDIN ACCOUNTS
        let hasLogin = false;
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if ( Object.keys(loginProfileInfo).length>0 ){
                profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);

                const companyIdSelectedTemp = parseInt( profileLoggedinTemp.hasOwnProperty('team_company_id') ? ( profileLoggedinTemp.team_company_id || 0) : 0);
                setCompanyIdSelected( companyIdSelectedTemp);

                hasLogin = true;
            }
        }


        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){

                let accountMain = [];
                let accountSub = [];;

                // get all accounts and departments; this is for counter notifications
                let availableAccountsTemp = {};
                let accountKey = {};
                let accountListTemp = Utilities.sortAccounts(accountsList);

                accountListTemp.forEach( acctdetail => {

                    const formattedDetails = Utilities.formatProfileDetail(acctdetail);

                    if ( parseInt(acctdetail.account_type)===1 ){
                        accountMain = formattedDetails;
                    }else{
                        accountSub = [ ...accountSub, formattedDetails ];
                    }
                    
                    
                    if ( parseInt(acctdetail.account_type)===1 ){
                        accountKey = {
                            account_id : acctdetail.account_id.toString(),
                            account_type : acctdetail.account_type.toString(),
                            //company_id : '0',
                            account_department_id : '0',
                            //flag : '1'
                        }
                        availableAccountsTemp[Utilities.basicJoinObjectValues(accountKey)] = acctdetail;
                    }

                    const account_type = parseInt(acctdetail.account_type);
                    if (account_type!==1){

                        if (parseInt(profileLoggedinTemp.account_type)===3){
                            
                            // NO NEED CUZ BY DEFAULT IT WILL RETURN TO PROFESSIONAL AFTER COMPANY REGISTRATION
                            /* if ( acctdetail.hasOwnProperty('departments') ){
                                setProfileLoggedin(acctdetail.departments[1]); // general
                            } */

                        }else{
                            
                            Object.entries(acctdetail.departments).map( ([key, dept]) => {

                                if ( parseInt(dept.flag)===4){
                                    accountKey = {
                                        account_id : dept.account_id.toString(),
                                        account_type : dept.account_type.toString(),
                                        //company_id : dept.team_company_id.toString(),
                                        account_department_id : '0',
                                        //flag : '1'
                                    }
                                    availableAccountsTemp[Utilities.basicJoinObjectValues(accountKey)] = dept;

                                }else{
                                    accountKey = {
                                        account_id : dept.account_id.toString(),
                                        account_type : dept.account_type.toString(),
                                        //company_id : dept.team_company_id.toString(),
                                        account_department_id : dept.group_id.toString(),
                                        //flag : '4'
                                    }
                                    availableAccountsTemp[Utilities.basicJoinObjectValues(accountKey)] = dept;
                                }
                            
                            });
                        }
                    }
                });

                sessionStorage.setItem(
                        process.env.REACT_APP_session_notification_accounts,
                        JSON.stringify(availableAccountsTemp)
                    );
                

                let accountAll = [];
                accountAll.push(accountMain);
       
                if ( accountSub.length>0 ){

                    if ( 'company_name' in accountSub[0] ){
                        //const byName = RM.ascend(RM.prop('company_name')); key senstive
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('company_name')));
                        accountSub = RM.sort(byName, accountSub);
                    }

                    accountAll = RM.concat(accountAll, accountSub);
                }
               
                setListAccounts(accountAll);

                // GET NOTIFICATION COUNTERS
                // ADD NOTIFICATION

                //return false;
                if ( hasLogin===true && reload===true ){

                    let master_account_id = loginProfileInfo.account_id;
                    accountsList = Object.values(accountsList);
                    master_account_id = accountsList[0].account_id;

                    let flag = '1';
                    let account_department_id = '0';
                    if ( loginProfileInfo.hasOwnProperty('group_id') ){
                        account_department_id = loginProfileInfo.group_id.toString();
                        flag = '4';
                    }

                    const passValues = { 
                        api_token : TOKEN.toString(),
                        account_id : loginProfileInfo.account_id.toString(),
                        account_type : loginProfileInfo.account_type.toString(),
                        master_account_id : master_account_id.toString(),
                        flag : flag.toString(),
                        group_id : account_department_id.toString(),
                    }

                    axios
                    .post( ApiUrls.bizcomWebHomeDetails ,passValues )
                    .then( response => {

                        if ( parseInt(response.status)===201 ){
                            if ( parseInt(response.data.success)===1 ){
                                setupNotificationProper( response.data.result );
                            }
                        }

                    })
                    .catch( (err) => {

                    });
                }

            }

        }

    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const setupNotificationProper = ( providedList = {} ) => {

        let switch_account_list = {};
        let total_notification_counter = 0;
        let switch_account_list_provided = providedList.switch_account_list ?? {};

        if ( Object.entries(switch_account_list_provided).length>0 ){
            Object.entries(switch_account_list_provided).map( ( [key, row] ) => {
                
                total_notification_counter = parseInt(total_notification_counter) + parseInt(row.chat.count);
                
                if ( row.hasOwnProperty('child_account_list') ){
                    let child_account_list_temp  = {};

                    if ( Object.keys(row.child_account_list).length>0 ){
                        Object.entries(row.child_account_list).map( ( [keyDept, rowDept] ) => {
                            child_account_list_temp[rowDept.account_id+'-'+rowDept.account_type+'-'+rowDept.group_id] = rowDept;
                        });
                    }
                    row = { ...row , child_account_list : child_account_list_temp };
                }

                switch_account_list[row.account_id] = row;
            });
        }

        let internal_chat_count =  providedList.hasOwnProperty('internal_chat_count') ? parseInt(providedList.internal_chat_count || 0) : 0;
        let notification_count =  providedList.hasOwnProperty('notification_count') ? parseInt(providedList.notification_count || 0) : 0;

        total_notification_counter = internal_chat_count + notification_count;
        
        dispatch( globalNotificationCounterReducer(total_notification_counter) );
        setListNotifications(switch_account_list);
        
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getNoticationCounter = ( profile, parent = 1 ) => {
        let counter = 0;
        const listNotificationsTemp = listNotifications;

        const accountType = parseInt(profile.account_type);
        const flag = parseInt(profile.flag); // 5 department
        
        const account_id = profile.account_id.toString();
        const account_type = accountType.toString();
        
        if ( Object.keys(listNotificationsTemp).length>0 ){
            let keyProfile =  `${account_id}-${account_type}-0-0-1`;

            if ( parent===1 ){
                if ( accountType===1 ){
                    counter = typeof listNotificationsTemp[account_id]==='undefined' ? 0 : listNotificationsTemp[account_id].chat.count;

                }else if ( accountType===4 && typeof listNotificationsTemp[profile.team_company_id.toString()]!=='undefined'){
                    counter = listNotificationsTemp[profile.team_company_id.toString()].chat.count;
                    keyProfile = `${account_id}-${account_type}-${profile.team_company_id.toString()}-0`;
                }

            }else{

                if ( profile.hasOwnProperty('team_company_id') ){
                    const team_company_id = profile.team_company_id.toString();
                    
                    if ( typeof listNotificationsTemp[team_company_id]!=='undefined' ){

                        if ( 'child_account_list' in listNotificationsTemp[team_company_id] ){
                            let department_id = '0';

                            if ( flag===5 ){
                                department_id = profile.group_id.toString();
                            }
            
                            let keyAccount = `${account_id}-${account_type}-${department_id}`;
                            keyAccount = keyAccount.toString();

                            const child_accounts = listNotificationsTemp[team_company_id].child_account_list;

                            if ( typeof child_accounts[keyAccount] !== 'undefined' ){

                                let keyFlag = '1';
                                if ( 'chat' in child_accounts[keyAccount] ){

                                    if ( accountType===4 && flag===4 ){
                                        counter = child_accounts[keyAccount].chat.count;
                                    }else if ( accountType===4 && flag===5 ){
                                        counter = child_accounts[keyAccount].chat.count;
                                        keyFlag = '4';
                                    }
                                }

                                keyProfile = `${account_id}-${account_type}-${team_company_id}-${department_id}-${keyFlag}`;
                            }
                        }
                    }
                }
            }

            if ( parseInt(counter)>0 ){

                if ( parseInt(parent)===0 ){
                     profileWithNotifcations[keyProfile] = { ...profile, unread : counter };
                }else{
                    if ( parseInt(account_type)===1 ){
                        profileWithNotifcations[keyProfile] = { ...profile, unread : counter };
                    }
                }
            } 
            
        } 

        return counter;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const properTypeLabel = () => {
        const flag_main = parseInt(profileLoggedin.flag_main);
        const flag = parseInt(profileLoggedin.flag);

        let displayName = '';
        if ( flag===4 || flag===5 ){
            displayName = profileLoggedin.company_detail.company_display_name || profileLoggedin.company_detail.company_name;
        }

        let typeLabel = {
                        type : parseInt(profileLoggedin.account_type),

                        headLogo : profileLoggedin.logo_url,
                        title : profileLoggedin.name,
                        name : Utilities.accountTypeLabels(profileLoggedin.account_type),

                        loader : Utilities.accountTypeLabels(profileLoggedin.account_type),
                        loader_title :  profileLoggedin.name,
                    };
    
        if ( flag===4 && flag_main===1 ){
            typeLabel.loader = displayName;
            typeLabel.loader_title = Utilities.accountTypeLabels(4);

            typeLabel.name = displayName;
            typeLabel.title = Utilities.accountTypeLabels(4);

        }else if ( flag===4 && flag_main===0 ){
            typeLabel.loader = Utilities.accountTypeLabels(4);
            typeLabel.name =  displayName;
            typeLabel.title = Utilities.accountTypeLabels(4);

        }else if ( flag===5 && flag_main===0 ){
            typeLabel.type = 4;
            typeLabel.name = displayName;
            typeLabel.title = profileLoggedin.department_name;
            typeLabel.headLogo = profileLoggedin.company_detail.logo_url;

            typeLabel.loader = displayName;
            typeLabel.loader_title = profileLoggedin.department_name;
        }
        
        if ( flag===4 && profileLoggedin.hasOwnProperty('department_name') ){
            typeLabel.title = profileLoggedin.department_name;
        }

        return typeLabel;
    }

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const listingName = ( profile ) => {
        const flag = parseInt(profile.flag);
        let nameArray = { name : profile.name, subname : '' };
        
        if (flag===4){
            const profileMain = parseInt(profile.main);

            if ( profileMain===1 ){
                nameArray.name = 'My Work Page';
            }else if ( profileMain===0 ){
                nameArray.subname = 'My Work Page';
            }
        }

        return nameArray;
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const classProfileSelection = ( profile, isProfile = 0) => {
        let class_name = '';
        let class_status = '';

        const flag_main = parseInt(profile.flag_main);
        const flag = parseInt(profile.flag);

        if ( flag_main===1 ){
            if ( profileLoggedin.account_id===profile.account_id){
                
                if ( profileLoggedin.hasOwnProperty('department_name') ){
                    if ( parseInt(profileLoggedin.group_id)===parseInt(profile.group_id) ){
                        class_name = 'current-item';
                    }
                    
                    if ( isProfile===1 ){
                        class_name = 'current-item';
                    }

                }else{
                    
                    if ( profileLoggedin.flag===profile.flag ){
                        class_name = 'current-item';
                    }
                }
                
            }

        }else{
  
            // TEAM
            if ( flag===4 ){
                if ( profileLoggedin.account_id===profile.account_id){
                    class_name = 'current-item';
                }

            // DEPARTMENT
            }else if ( flag===5 ){
                const group_id = parseInt(profile.group_id);

                if ( group_id===profileLoggedin.group_id ){
                    if ( profileLoggedin.account_id===profile.account_id){
                        class_name = 'current-item';
                    }
                }

                if ( profile.hasOwnProperty('flag_handle_status') ){
                    class_status = parseInt(profile.flag_handle_status || 0)===1 ? '' : 'd-none not-active';
                }   
            }
        }

        return class_name+' '+class_status;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const showInactiveDepartments = ( profile ) => {
        let more_html = '';
        if ( profile.hasOwnProperty('departments') ){

            const departments = profile.departments;
            if ( Object.entries(departments).length>0 ){
                
                let viewDepartments = 0;
                Object.entries(departments).map( ([key, row] ) => {
                    if ( parseInt(row.flag)===5 && parseInt(row.flag_handle_status)===0 ){
                        viewDepartments++;
                    }
                });

                if ( parseInt(viewDepartments)>0 ){
                    more_html = <Dropdown.Item className="btn-chat-only" onClick={ () => showDepartmentsViewChat(profile) }>view chats only</Dropdown.Item>
                }
            }
        }

        return more_html;
    }

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const switchProfile = ( profile  ) => {

        // PREVENT FROM SWITCHING
        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);

            if ( parseInt(profile.account_type)===1 && parseInt(loginBasicInfo.account_type)===1 ){
                return false;

            }else{

                const loginAccountId = parseInt(loginBasicInfo.account_id);
                const loginDepartmentId = parseInt( loginBasicInfo.group_id || 0 ); // null or undefined

                const profileAccountId = parseInt(profile.account_id);
                const profileDepartmentId = parseInt( profile.group_id || 0 );
                const profileFlag = parseInt(profile.flag);

                if ( [4,5].includes(profileFlag) ){
                    if ( loginAccountId===profileAccountId && loginDepartmentId===profileDepartmentId){
                        return false;
                    }
                }
            }
        } 
        
        let companyIdSelectedTemp = 0;
        if ( profile.hasOwnProperty('team_company_id') ){
            companyIdSelectedTemp = parseInt(profile.team_company_id || 0);
        }
        setCompanyIdSelected( companyIdSelectedTemp );
        
        handleCloseDepartmentsViewChat();
        
        setProfileLoggedin(profile);
        updatedLoggedinProfileSession(profile);

        setProfileLoader(1);
        dispatch( switchAccount(Date.now()) );

        // replace to ajax
        setTimeout( function (){
            setProfileLoader(0);
            history.push(`/${ UrlSlugs.dashboard }`);
        }, 1200);
        
    }
   
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const allowAccessAccount = ( profile ) => {

        let response = true;
        const account_type = parseInt(profile.account_type);
    
        if ( account_type===4 ){
            let availability = profile.hasOwnProperty('team_external_availability') ? parseInt(profile.team_external_availability || 0) : 0;
            if ( parseInt(availability)===1 ){
                response = false;
            }
        }

        return response;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    useEffect( () => {
        dispatch( accountsWithNotification(profileWithNotifcations) ); 
    }, [profileWithNotifcations]);


    useEffect( () => {
        updateLoggedinProfile();
    }, [props.reloadProfile]);

    useEffect( () => {
        if ( Object.entries(passConversList).length>0 ){
            setupNotificationProper(passConversList);
        }else{
            updateLoggedinProfile();
        }
        
    }, [passConversList]);
    

    useEffect( () => {
        const bizcomplus_account_id = parseInt(bizcomplusSwitchAccountID);
        if ( bizcomplus_account_id>0 ){

            let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginProfileInfo!==null ){
                loginProfileInfo = JSON.parse(loginProfileInfo);
                
                if ( Object.keys(loginProfileInfo).length>0 ){
                    const loginAccountId = parseInt(loginProfileInfo.account_id);

                    if ( loginAccountId!==bizcomplus_account_id ){

                         // ASSOCIATED ACCOUNTS
                        const accountsCounter = Object.keys(listAccounts).length;
                        if ( parseInt(accountsCounter)>1 ){
                            
                            let hasFound = 0;
                            Object.entries(listAccounts).map( ([key, row] ) => {

                                const account_id = parseInt(row.account_id);
                                const account_type = parseInt(row.account_type);

                                let team_is_admin = row.hasOwnProperty('team_is_admin') ? parseInt(row.team_is_admin || 0 ) : 0;
                                team_is_admin = parseInt(team_is_admin);

                                if ( parseInt(account_id)===bizcomplus_account_id){

                                    if (account_type===1 ){
                                        switchProfile(row);
                                        hasFound = 1;

                                    }else if (account_type===4 && team_is_admin===1){ 
                                        switchProfile(row.departments[0]);
                                        hasFound = 1;
                                    }
                                }
                            });

                            if ( parseInt(hasFound)===0 ){
                                history.push(`/${ UrlSlugs.dashboard }`);
                            }
                        }

                    }else{
                        history.push(`/${ UrlSlugs.dashboard }`);
                    }

                }
            }
            
        }

    }, [bizcomplusSwitchAccountID] );

    useEffect( () => {

        if ( Object.keys(globalProfileRequest).length>0 ){
            
            let notificationAccounts = sessionStorage.getItem(process.env.REACT_APP_session_notification_accounts);
            if ( notificationAccounts!==null ){
                notificationAccounts = JSON.parse(notificationAccounts);
                
                let keyReq = `${globalProfileRequest.account_id}-${globalProfileRequest.account_type}-${globalProfileRequest.group_id}`;
                keyReq = keyReq.toString();
    
                if ( keyReq in notificationAccounts ){
                    let profileReq = notificationAccounts[keyReq];

                    switchProfile(profileReq);
                    dispatch( globalProfile({}) );
                }
            }
           
        }

    }, [globalProfileRequest]);


    useEffect( () => {
        if ( parseInt(props.reloadAccountsDB)>0 ){
            
            if ( parseInt(props.reloadAccountsDB)!==2 ){ // 2 - located in department module, prevent the page not to load
                 dispatch( showLoader(1) );
            }

            SessionUtilities.sessionUpdateAccountsFromDB();

            // UDPATE DEPARTMENTS
            let timeOut = setTimeout( () =>{
                updateLoggedinProfile();
               
                if ( parseInt(props.reloadAccountsDB)!==2 ){ 
                    window.location.reload();
                }
               
                dispatch( reloadAccountsDB(0) );
                clearTimeout(timeOut);

            }, 1500);
        }
        
    }, [props.reloadAccountsDB]);


    useEffect(() => {

         // CHECK LOGIN TYPE
         const loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype);
         const sesstionLoginType = JSON.parse(loginTypeStorage);
     
         if ( sesstionLoginType !== null ){
             setLoginType( parseInt(sesstionLoginType) );
         }
 
         return () => {
             setLoginType(0);
         }

    }, []);
    


    // UPDATED WHEN GO BACK TO DASHBOARD
    useEffect( () => {

        const segment = Utilities.getUrlSegments();

        let urlSlug = segment.shift();
        if ( typeof urlSlug!=='undefined' ){
            urlSlug = urlSlug.toString().toLowerCase();
        }

        if ( ['dashboard','departmentdashboard'].includes(urlSlug) ){
            if ( Object.entries(passConversList).length>0 ){
                setupNotificationProper(passConversList);
            }else{
                //updateLoggedinProfile(true);
            }
        }

    }, [ window.location.pathname ]);

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <header className="main-header text-lowercase">
           <div className="main-header-content">

                <div className="main-logo">
                    { 
                        Object.keys(listAccounts).length>0 ?
                            <Link to={`/${ loginType===1 ? UrlSlugs.dashboard : UrlSlugs.departmentDashboard }`} dangerouslySetInnerHTML={{ __html: imgBizLogo }}></Link>
                        :
                            <Link to={`/${ UrlSlugs.login }`} dangerouslySetInnerHTML={{ __html: imgBizLogo }}></Link>
                    }
                    
                </div>

                <div className="user-navigate">

                    {
                        Object.keys(listAccounts).length>0 && 
                            <div className="profile-selection-list">

                                <Dropdown className="dropdown-user-selection">
                                    <Dropdown.Toggle variant="dropdown-user-current" id="dropdown-basic">
                                        <div className={`avatar-image fs-22-important bg-black ${ Utilities.classHideInitialBg( properTypeLabel().headLogo, 'bg-black' ) }`} onClick={ () => autoExpandAccount() } style={{ backgroundImage:  `url(${   Utilities.showHideProfileImage( properTypeLabel().headLogo ) })`  }}>{ Utilities.showHideInitial( properTypeLabel().headLogo , ( properTypeLabel().type===1 ? properTypeLabel().title : properTypeLabel().name ) ) }</div>
                                    </Dropdown.Toggle>

                                    { 
                                        Object.keys(listAccounts).length>0 && 
                
                                            <Dropdown.Menu >
                                                {
                                                    Object.entries(listAccounts).map( ([key, row] ) => 
                                                        ( row.type===3 || row.type===4 ) ?
                                                     
                                                                allowAccessAccount(row)===true && 
                                                                    <div key={key} className="dropdown-item-toggle">

                                                                        <div className={` profile-item ${ parseInt(row.team_company_id)===parseInt(companyIdSelected) ? 'active' : '' } ${ classProfileSelection(row, 1) } `} onClick={ (e) => expandShowCompanies(row) }>
                                                                            <div className="avatar-image-wrap">
                                                                                <div className={`avatar-image ${ Utilities.classHideInitialBg( (row.type===4 ? Utilities.formatProfileDetail(row, 1).img : row.img) , 'bg-black') }`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage( row.type===4 ? Utilities.formatProfileDetail(row, 1).img : row.img ) })`  }}>{ Utilities.showHideInitial( (row.type===4 ? Utilities.formatProfileDetail(row, 1).img : row.img ), (row.type===4 ? Utilities.formatProfileDetail(row, 1).name : row.name) ) }</div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="user-label notranslate">{ (row.type===4) ? Utilities.formatProfileDetail(row, 1).name : row.name }</div>
                                                                                <div className="user-name">{ Utilities.accountTypeCustomLabels(row) }</div>
                                                                            </div>
                                                                            { getNoticationCounter(row)>0 &&  <span className="counter"><em>{ Utilities.counterMore(getNoticationCounter(row)) }</em></span> }
                                                                        </div>

                                                                        <div className={`department-list ${ parseInt(row.team_company_id)===parseInt(companyIdSelected) ? 'bg-white' : 'd-none' } `}>
                                                                        {
                                                                            Object.entries(row.departments).map( ([keyDept, rowDept] ) => 
                                                                                    <Dropdown.Item key={keyDept} className={ `department-name ${ classProfileSelection( rowDept ) }` }  onClick={ () => switchProfile(rowDept) } >
                                                                                        <div className="user-name notranslate">{ listingName(rowDept).name }</div>
                                                                                        { ! Utilities.isEmpty( listingName(rowDept).subname ) && <div>{listingName(rowDept).subname}</div> } 
                                                                                        { getNoticationCounter(rowDept, 0)>0 &&  <span className="counter"><em>{ Utilities.counterMore(getNoticationCounter(rowDept, 0)) }</em></span> }
                                                                                    </Dropdown.Item>
                                                                            )
                                                                        }

                                                                        { showInactiveDepartments(row) }
                                                                        </div>
                                                                        
                                                                    </div>
                                                            
                                                            :
                                                                <Dropdown.Item href="#" key={key} className={ ` ${ classProfileSelection(row) } `} onClick={ () => switchProfile( row ) } >
                                                                    <div className="profile-item">
                                                                    
                                                                        <div className={ `avatar-image-wrap ${ (row.type===0) ? 'd-none' : '' }` }>
                                                                            <div className={`avatar-image ${ Utilities.classHideInitialBg(row.img) }`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.img) })`  }}>{ Utilities.showHideInitial(row.img, row.name) }</div>
                                                                        </div>

                                                                        <div>
                                                                            {  parseInt(row.type || 0)===0 ? '' : <div className="user-label">{ Utilities.accountTypeLabels(row.type) }</div>  }
                                                                            <div className="user-name notranslate">{ row.name }</div>
                                                                        </div>

                                                                        { getNoticationCounter(row)>0 &&  <span className="counter"><em>{ Utilities.counterMore(getNoticationCounter(row)) }</em></span> }

                                                                    </div>
                                                                </Dropdown.Item>
                                                    )
                                                }

                                            </Dropdown.Menu>
                                        
                                    }
                                </Dropdown>

                            </div>
                    }
                    

                    <div className="user-current-navigate">
                       <div className={ `user-name user-label notranslate` }>{ properTypeLabel().name }</div>
                       <div className="department-current user-label-4">{ properTypeLabel().title }</div>
                    </div>

                </div>


           </div>
           
           {
               profileLoader>0 && 
                <div className="profile-loader-wrap">
                        <div>
                            <div className={`profile-avatar fs-45-important ${Utilities.classHideInitialBg(properTypeLabel().headLogo, 'bg-black') }`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(properTypeLabel().headLogo) })`  }}>
                                { Utilities.showHideInitial(properTypeLabel().headLogo, ( properTypeLabel().type===1 ? properTypeLabel().loader_title : properTypeLabel().loader ) ) }
                            </div>
                            <div className="profile-label user-label notranslate">{ properTypeLabel().loader_title }</div>
                            <div className="profile-name">{ properTypeLabel().loader }</div>
                        </div>
                    </div>
           }
   
            <HeaderDepartmentsViewChat popupDepartmentsViewChat={popupDepartmentsViewChat} handleCloseDepartmentsViewChat={handleCloseDepartmentsViewChat} profileSelected={profileSelected} switchProfile={switchProfile} />


        </header>
    )
}

export default Header;
