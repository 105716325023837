import axios from 'axios'; 
import ApiUrls from './ApiUrls';

class QueryUtilities {
    
    getConversationDetail = async ( urlRequest = '', passValues = {} ) => {
        let responseDetail = {};

        if ( urlRequest!=='' && Object.entries(passValues).length>0 ){
            try {
                const profileDetailRes = await axios.post( urlRequest, passValues);
                responseDetail = profileDetailRes.data;

            } catch (e) {
                responseDetail = {};
            }

        }
        return responseDetail;
    }

    getIpDetail = async () => {
        let response = {};
        try {
            const ipRes = await axios.post( 'https://extreme-ip-lookup.com/json/');
            response = ipRes.data;

        } catch (e) {
            response = {}
        }
        return response;
    }

    getUrlMetaData = async ( urlArray = [], isObject = 0 ) => {
        
        let response = isObject===0 ? [] : {} ;
        if ( urlArray.length>0 ){
            try {
                const passValue = { urls : urlArray, isObject : isObject };
                const urlRequest = ApiUrls.utilitiesGetUrlMetaData ;
                const responseDetailRes = await axios.post( urlRequest, passValue);
                response = responseDetailRes.data;

            } catch (e) {
                response = isObject===0 ? [] : {} ;
            }
        }
        return response;
    }

    getProfileDetail =  async ( account_id = 0 , showCompany = 0 ) => {

        let profileDetail = {};
        if ( parseInt(account_id)>0 ){
            try {
                const passValue = { id : account_id.toString(), show_company : showCompany.toString() }
                const urlRequest = ApiUrls.profilegetByID ;
                const profileDetailRes = await axios.post( urlRequest, passValue);
                profileDetail = profileDetailRes.data.detail;

            } catch (e) {
                profileDetail = {};
            }

        }
        return profileDetail;
    }

    

    getDerpartmentDetail =  async ( account_id = 0, department_id = 0  ) => {

        let departmentDetail = {};
        if ( parseInt(account_id)>0 ){
            try {
                const passValue = { company_id : account_id.toString() , department_id : department_id.toString(), show_company : 1 };
                const urlRequest = ApiUrls.departmentGetDetailByCompanyDepartmentID;
                const departmentDetailRes = await axios.post( urlRequest, passValue);
                departmentDetail = departmentDetailRes.data;

            } catch (e) {
                departmentDetail = {};
            }

        }
        return departmentDetail;
    }

    getDocumentInfo =  async ( document_id = 0) => {

        let documentList = {};
        if ( parseInt(document_id)>0 ){
            try {
                const passValue = { ids : document_id.toString() }
                const urlRequest = ApiUrls.documentGetDocumentByGroupIDs;
                const documentDetailRes = await axios.post( urlRequest, passValue);
                documentList = documentDetailRes.data.list;

            } catch (e) {
                documentList = {};
            }

        }
        return documentList;
    }


    getDocumentEncoded =  async ( documentDetail = {} ) => {

        let document = '';
       
        if ( Object.keys(documentDetail).length>0 ){
            try {
                const urlRequest = ApiUrls.documentGetEncodedFile;
                const documentDetailRes = await axios.post( urlRequest, documentDetail);
                document = documentDetailRes.data;

            } catch (e) {
                document = '';
            }

        }
        return document;
    }

    profileAssignTeam = async ( passVal = {} ) => {

        let returnResponse = {};
        if (  Object.entries(passVal).length>0 ){

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.profileAssignTeamProfile;
                const returnRes = await axios.post( urlRequest, passValue);
                returnResponse = returnRes.data;

            } catch (e) {
                returnResponse = {};
            }

        }
        return returnResponse;

    }

    recordCounterByType = async ( passVal = {} ) => {

        let returnResponse = {};
        if (  Object.entries(passVal).length>0 ){

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.counterByType;
                const returnRes = await axios.post( urlRequest, passValue);
                returnResponse = returnRes.data;

            } catch (e) {
                returnResponse = {};
            }

        }
        return returnResponse;

    }

    updateLoginHistoryFCM = async ( passVal = {} ) => {

        let returnResponse = {};
        if (  Object.entries(passVal).length>0 ){

            try {
                const returnRes = await axios.post( ApiUrls.userUpdateLoginHistory , passVal);
                returnResponse = returnRes.data;

            } catch (e) {
     
            }

        }
        return returnResponse;

    }


    countryList = async () => {

        let returnResponse = {};
        try {
            const returnRes = await axios.post( ApiUrls.globalCountryList);
            returnResponse = returnRes.data.list;

        } catch (e) {
    
        }

        return returnResponse;
    }

}

export default new QueryUtilities();

