import axios from 'axios';
import ApiUrls from './ApiUrls';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

export const talkToUsConversation = async ( master_account_id = 0 ) =>{
    let responseDetail = {};
    
    if ( parseInt(master_account_id)>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.bizcomTalkToBizcomAssist, { api_token : API_PLUS_TOKEN.toString(), master_account_id : master_account_id.toString() });
            if ( parseInt(responseURL.data.success)===1 ){
                responseDetail = responseURL.data.result;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const getMarketingTools = async ( passval = {} ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_PLUS_TOKEN.toString() };

        try {
            const responseURL = await axios.post(  ApiUrls.bizcomMarketingTools, passval );
            responseDetail = responseURL.data;

        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const getAppVideos = async ( passval = {} ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        passval = { ...passval, api_token : API_PLUS_TOKEN.toString() };

        try {
            const responseURL = await axios.post(  ApiUrls.bizcomVideos, passval );
            responseDetail = responseURL.data;

        } catch (e) {
            
        }
    }

    return responseDetail;
}