import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import { Dropdown } from 'react-bootstrap';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle,  showMainSettings as showMainSettingsReducer} from '../../../actions';
import { getCompanyFollowers } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';

const NotificationFollowers = () => {


    const dispatch = useDispatch();
    
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ showProcessing , setShowProcessing ] = useState(true);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getCompanyFollowerList = async ( profile = {} ) => {
        
        const passval = {
            api_token : API_TOKEN.toString(),
            account_id : profile.account_id.toString(),
            company_id : profile.company_detail.account_id.toString(),
            flag : '0'
        };

        setShowProcessing(true);
        const companyFollowersResponse = await getCompanyFollowers(passval);
        setShowProcessing(false);

        if ( Object.keys(companyFollowersResponse).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(companyFollowersResponse.success)===1 ){
            setListProfiles(companyFollowersResponse.result.company_followers_list);


        }else{
            Utilities.messagePopup('error', companyFollowersResponse.errorMessage );
        }
    }

    useEffect( () => {
        
        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        if ( parseInt(userLoginDetailTemp.account_type)!==4 ){
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        }else{

            // RETURN to activation page
            if ( userLoginDetailTemp.hasOwnProperty('company_detail') ){
                if ( parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0)===0 ){
                    history.push(`/${UrlSlugs.settings_followersNotices}`);
                    return false;
                }
            }
        }

        getCompanyFollowerList(userLoginDetailTemp)

        dispatch( pageTitle(`${TitlesLabels.navigations.followersList}`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );

       
    }, [] );

    return (
        <div className="notication-company-followers-page team-members-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <Link to={`/${UrlSlugs.settings_notificationManagement}`}>{ TitlesLabels.navigations.notificationManagement }</Link>
                            <div className="current">{ TitlesLabels.navigations.followersList }</div>
                           
                          </li>
                         <li className="btn-option">
                            {
                                  Object.keys(listProfiles).length>0 &&
                                    <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() } title={ TitlesLabels.general.searchProfile }></div>
                            }
                            
                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                                    <div className="btn-icon btn-dots-vertical" title={ TitlesLabels.general.notificationOption }></div>
                                </Dropdown.Toggle>
                
                                <Dropdown.Menu className="dropdown-menu-medium">
                                    <Link to={`/${UrlSlugs.settings_notificationFollowersSuspend}`} className="item-user-selection dropdown-item" role="button">suspend follower</Link>
                                    <Link to={`/${UrlSlugs.settings_notificationFollowersRemove}`} className="item-user-selection dropdown-item" role="button">remove follower</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="btn-icon btn-gear" onClick={ () => dispatch( showMainSettingsReducer( new Date() ) ) } title={ TitlesLabels.settings } ></div>
                         </li>
                     </ul>
                </div>

            </div>

            <div className="main-content position-relative">
                <div className="container">

                    { 
                            showProcessing ?
                                <ul className="listing-profiles-preloader mt-4">
                                {
                                    [...Array(12).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                                </ul>
                            :
                                <>
                                {
                                    Object.keys(listProfiles).length===0 ?
                                        <div className="text-center text-lowercase no-record-label ">{ Utilities.labelProfileAvailable }</div>
                                    :
                                        <>
                                            <div className="profile-search-wrap d-none">
                                                <div className="profile-search-content">
                                                    <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                                    <div></div>
                                                    <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                                    <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                                                </div>
                                            </div>
                                            
                                            <ul id="listing-profiles" className={`listing-profiles max-height-fit my-4 cursor-auto`}>
                                            {
                                                Object.entries(listProfiles).map( ([key, row] ) =>
                                                    <li key={key} data-keyword={row.name} className={`${ parseInt(row.status)===2 ? 'opacity-05' : ''} cursor-auto`}>
                                                        <div className="profile-wrap">
                                                            <div className="profile-image-wrap"><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                                            <div className="profile-name notranslate" data-name={row.name}>{row.name} </div>
                                                            <div className="profile-label">{row.title }</div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            </ul>
                                        </>
                                }
                                </>
                        }

                </div>
            </div>
       </div>
    )
}

export default NotificationFollowers;