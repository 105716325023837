import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import * as RM from 'ramda';

import { switchProfile as switchAccount } from '../actions';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';

const HeaderDepartment = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    //let imgPlusLogo = `<div style="background-image:url('${process.env.REACT_APP_img_folder}/logo/logo-plus.svg')" class="logo-img logo-plus"></div>`;
    let imgBizLogo = `<div style="background-image:url('${process.env.REACT_APP_img_folder}/logo/logo-light.svg')" class="logo-img" ></div>`;

    const [ loginDepartment, setLoginDepartment ] = useState({});
    const [ activeProfile, setActiveProfile ] = useState({});
    const [ companyDetail, setCompanyDetail ] = useState({});
    const [ departmentList, setDepartmentList ] = useState({});
    
    const reloadDepartments = useSelector( state => state.reloadDepartments);

    const provideLoginProfileDetail = () => {
        
        let loginDetail = {};
        let sessionLoginDetail = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( sessionLoginDetail!==null ){
            loginDetail = JSON.parse(sessionLoginDetail);
            setLoginDepartment(loginDetail);
        }

        let activeDepartment = localStorage.getItem(process.env.REACT_APP_session_department_active);
        if ( activeDepartment!==null ){
            activeDepartment = JSON.parse(activeDepartment);
            setActiveProfile(activeDepartment);
        }

        let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
        if ( companyDetail!==null ){
            companyDetail = JSON.parse(companyDetail);
            setCompanyDetail(companyDetail);
        }

        let departmentList = localStorage.getItem(process.env.REACT_APP_session_company_departments);
        if ( departmentList!==null ){
            departmentList = JSON.parse(departmentList);
            departmentList = Utilities.toNormalArrayObject(departmentList);

            if ( departmentList.length>0 ){
                if ( 'name' in departmentList[0] ){
                    const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                    departmentList = RM.sort(byName, departmentList);
                }
            }

            setDepartmentList(departmentList);
        }
    }

    const switchDepartment = ( deptDetail = {} ) => {
        
        localStorage.setItem(
            process.env.REACT_APP_session_department_active,
            JSON.stringify(deptDetail)
        );
       
        setActiveProfile(deptDetail);

        dispatch( switchAccount(Date.now()) );
        history.push(`/${ UrlSlugs.departmentDashboard }`);

    }

    const getDepartments = ( passDetail = {} ) => {

        const dataRequest = {
            account_id : passDetail.account_id.toString(),
            department_id : passDetail.department_id.toString(),
            ip_address : passDetail.ip_address.toString(),
        };

        axios
        .post( ApiUrls.departmentAccessGetDepartments , dataRequest )
        .then( response => {

            if ( response.status===200 ){
                const dataResponse = response.data;
                //const dataStatus = parseInt(dataResponse.status);
                
                //if ( dataStatus===1 ){
                    localStorage.setItem(
                        process.env.REACT_APP_session_company_departments,
                        JSON.stringify(dataResponse.departments)
                    );
                    provideLoginProfileDetail();
               // }
            }

        })
        .catch( (err) => {

        }); 
        
    }

    useEffect( () => {
        provideLoginProfileDetail();

    }, [] );


    useEffect( async () => {
        if ( !Utilities.isEmpty(reloadDepartments)){
            getDepartments( reloadDepartments );
        }
    }, [reloadDepartments]);


    return (
        <header className="main-header main-header-department text-lowercase">
           <div className="main-header-content">

                 <div className="main-logo"><Link to={`/${ UrlSlugs.departmentDashboard }`} dangerouslySetInnerHTML={{ __html: imgBizLogo }}></Link></div>

                <div className="user-navigate">
                    <div className="profile-selection-list">

                        <Dropdown className="dropdown-user-selection">
                            <Dropdown.Toggle variant={`dropdown-user-current ${ Object.keys(departmentList).length===0 ? 'cursor-auto' : ''}`} id="dropdown-basic">
                                <div className={`avatar-image  fs-22-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(activeProfile.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(activeProfile.logo_url)  }}>{ Utilities.showHideInitial(activeProfile.logo_url, companyDetail.company_name) }</div>
                            </Dropdown.Toggle>  

                            {
                                Object.keys(departmentList).length>0 && 
                                    <Dropdown.Menu >
                                        <div className="dropdown-item-toggle">
                                            <div className={`department-list`}>

                                                {
                                                    Object.entries(departmentList).map( ( [key, deptDetail] ) =>
                                                        <Dropdown.Item key={key} className={ `department-name ${ parseInt(deptDetail.group_id)===parseInt(activeProfile.group_id) ? 'current-item' : '' }`} onClick={ () => switchDepartment(deptDetail) }>
                                                            <div className="user-name notranslate">{deptDetail.name}</div>
                                                            {/* <span className="counter"><em>1</em></span> */}
                                                        </Dropdown.Item>
                                                    )
                                                }
                                
                                            </div>
                                        </div>

                                    </Dropdown.Menu>
                            }
                            
                        </Dropdown>

                    </div>

                    <div className="user-current-navigate">
                       <div className={ `user-name user-label` }>{companyDetail.company_name}</div>
                       <div className={ `department-current user-label-4 ` }>{activeProfile.name}</div>
                    </div>

                </div>

                {/* <div className="logo-sub"><div dangerouslySetInnerHTML={{ __html: imgPlusLogo }}></div></div> */}

           </div>

        </header>
    )
}

export default HeaderDepartment;