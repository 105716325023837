import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; 
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import jQuery from 'jquery';
import Select from 'react-select';
import * as RM from 'ramda';

import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import TitlesLabels from '../../helpers/TitlesLabels';
import { uploadProfileLogo } from '../../helpers/AWSUpload';
import MapBox from '../../components/MapBox';
import FieldInput from '../../components/FieldInput';
import PasswordInput from '../../components/PasswordInput';

import {  showHeader, showFooter, pageTitle, reloadProfile, showMainSettings as showMainSettingsReducer, showPopupCompanyCategory as showPopupCompanyCategoryReducer, passCompanyCategoryDetail as passCompanyCategoryDetailReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showLoader as showLoaderReducer} from '../../actions';


const CompanyForm = ({match}) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    //const MAP_API = process.env.REACT_APP_google_map_key_api;
    const limitCharacters = Utilities.companyAboutWordsLimit;

    const passCompanyCategoryDetail = useSelector( state => state.passCompanyCategoryDetail);
    
    const defaultLocation = {
        country_id : 0, // process.env.REACT_APP_country_id,
        country_name : '', //process.env.REACT_APP_country_name,

        state_id : 0, //process.env.REACT_APP_state_id,
        state_name :  '', //process.env.REACT_APP_state_name,

        city_id : 0, //process.env.REACT_APP_city_id,
        city_name :  '', //process.env.REACT_APP_city_name,

        area_id : 0, //process.env.REACT_APP_area_id,
        area_name : '', // process.env.REACT_APP_area_name,
    };

    let defaultFormValues = {
        ...defaultLocation, 

        company_id : 0,
        company_name : '',
        company_display_name : '',
        company_email : '',
        company_website : '',
        category_name : '',
        category_id : 0,

        company_phone : '',
        company_phone_code : 0, //defaultLocationUtil.country_id,
        company_landline : '',
        company_landline_code : 0, //defaultLocationUtil.country_id,

        street_name : '',
        street_number : '',
        building_name : '',
        building_number : '',
        postal_code : '',

        latitude : '',
        longitude : '',

        company_phone_checked : 0,
        company_landline_checked : 0,

        address_visibility_status : 0
    }

    let finalSocialValues = { facebook :'', youtube : '', tiktok : '', instagram : '', twitter : '' };

    const formGroupKeywordElem = jQuery('#form-group-keyword');
    const coordinatesInitial = { lat: 0, lng: 0 };

    const [ coordinates, setCoordinates] = useState(coordinatesInitial); 
    const [ coordinatesOld, setCoordinatesOld] = useState(coordinatesInitial); 
    const [ mapLocationProvided, setMapLocationProvided] = useState(''); 
    const [ mapLocationAction, setMapLocationAction] = useState(2);  // 1 - from city/suburb, 2 - by coordinates
    
    const selectStateRef = useRef();
    const selectCityRef = useRef();
    const selectAreaRef = useRef();

    const [ formValues, setFormValues] = useState(defaultFormValues);
    const [ formValuesOld, setFormValuesOld] = useState(defaultFormValues);
   
    const [ formSocialValues, setFormSocialValues] = useState(finalSocialValues);
    const [ formSocialValuesOld, setFormSocialValuesOld] = useState(finalSocialValues);

    const [ countryNames, setCountryNames ] = useState({});
    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ companyDetail, setCompanyDetail ] = useState([]);
    
    const [ profileLogoOld, setProfileLogoOld ] = useState('');
    const [ profileLogo, setProfileLogo ] = useState('');
    const [ profileLogoValue, setProfileLogoValue ] = useState('');

    const [ defaultFlagMobile, setDefaultFlagMobile ] = useState(''); //process.env.REACT_APP_country_iso);
    const [ defaultCountryCodeMobile, setDefaultCountryCodeMobile ] = useState(''); //process.env.REACT_APP_country_ext);

    const [ defaultFlagLandline, setDefaultFlagLandline ] = useState(''); //process.env.REACT_APP_country_iso);
    const [ defaultCountryCodeLandline, setDefaultCountryCodeLandline ] = useState(''); //process.env.REACT_APP_country_ext);
    
    const [ countries, setCountries] = useState([]);
    const [ countryList, setCountryList] = useState([]);
    const [ stateList, setStateList] = useState([]);
    const [ cityList, setCityList] = useState([]);
    const [ areaList, setAreaList] = useState([]);

    const [ showButtonOption, setShowButtonOption ] = useState(false);
    const [ allowFormContent, setAllowFormContent ] = useState(false);

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');


    let defaultInfoValues = {
        company_keywords : [],
        company_about : ''
    }
    const [ formInfoValues, setFormInfoValues] = useState(defaultInfoValues);
    const [ formInfoValuesOld, setFormInfoValuesOld] = useState(defaultInfoValues);

    const [ showSection, setShowSection ] = useState('');
    const [ formInfoValuesPrev, setFormInfoValuesPrev ] = useState(defaultInfoValues);

    const [ showLandlineMsg, setShowLandlineMsg] = useState(false);
    const [ showMobileMsg, setShowMobileMsg] = useState(false);


    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
    }

    const pushKeywordValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
    }
    
    const extracyCompanyKeywords = ( keywordsProvided = {} ) => {
        setTimeout( () => {
            const formKeywordElem = jQuery('#form-keyword-info');
            Object.entries(keywordsProvided).map( ([key, keyword] )  => {
                formKeywordElem.find('input[name="keyword_'+(parseInt(key)+1)+'"]').val(keyword);
            });
        }, 200);
    }

    const previewImage = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setProfileLogo(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;

        let errorCounter = 0;

        if ( ['country_id','state_id','city_id','area_id'].includes(fieldName) ){
            
            if ( fieldName==='country_id'){
                getStateByCountryID( e.value );

                const country_name = ( typeof countryNames[e.value]!=='undefined' ) ? countryNames[e.value] : 'select country';
                formValuesTemp = { ...formValuesTemp, ...{  country_id : e.value, country_name : country_name, state_id : 0 , state_name : 'select state', city_id : 0 , city_name : 'select city' , area_id : 0 , area_name : 'select suburb' } };

            }else if ( fieldName==='state_id'){
                getCityByStateID( e.value );
                formValuesTemp = { ...formValuesTemp, ...{ state_id : e.value , state_name : e.label, city_name : 'select city' , area_id : 0 , area_name : 'select suburb' } };

            }else if ( fieldName==='city_id'){
                getAreByCityID( e.value );
                formValuesTemp = { ...formValuesTemp, ...{ city_id : e.value , city_name : e.label, area_id : 0 , area_name : 'select suburb' } };

                const stateNameSelected = ! Utilities.isEmpty(formValuesTemp.state_id) ? stateList.filter( state => parseInt(state.value) === parseInt(formValuesTemp.state_id) )[0]['label']  ?? '' : '';
                const cityNameSelected = ! Utilities.isEmpty(e.value) ? cityList.filter( city => parseInt(city.value) === parseInt(e.value) )[0]['label']  ?? '' : '';
                if ( ! Utilities.isEmpty(cityNameSelected) ){
                    setMapLocationProvided(stateNameSelected+','+cityNameSelected);
                    setMapLocationAction(1);
                }


            }else if ( fieldName==='area_id'){
                formValuesTemp = { ...formValuesTemp, ...{ area_id : e.value , area_name : e.label  } };

                const cityNameSelected = ! Utilities.isEmpty(formValuesTemp.city_id) ? cityList.filter( city => parseInt(city.value) === parseInt(formValuesTemp.city_id) )[0]['label']  ?? '' : '';
                const areaNameSelected = ! Utilities.isEmpty(e.value) ? areaList.filter( area => parseInt(area.value) === parseInt(e.value) )[0]['label']  ?? '' : '';
                if ( ! Utilities.isEmpty(areaNameSelected) ){
                    setMapLocationProvided(cityNameSelected +','+ areaNameSelected);
                    setMapLocationAction(1);
                }

            }
            setFormValues(formValuesTemp);
            

        }else{
           
            let  value = e.target.value;

            if ( fieldName==='company_name'){
                formValuesTemp = { ...formValuesTemp, ...{ company_name : value } };
            }else if ( fieldName==='company_display_name'){
                    formValuesTemp = { ...formValuesTemp, ...{ company_display_name : value } };
            }else if ( fieldName==='company_email'){
                formValuesTemp = { ...formValuesTemp, ...{ company_email : value } };
            }else if ( fieldName==='company_website'){
                formValuesTemp = { ...formValuesTemp, ...{ company_website : value } };

            }else if ( fieldName==='company_landline'){

                let company_landline = value.replace(' ','');
                setShowLandlineMsg('');
                if ( ! Utilities.isEmpty(company_landline) && ! Utilities.contactNumberValidate( company_landline ) ){
                    setShowLandlineMsg( Utilities.contactNumberValidate( company_landline, 1 ).msg);
                    jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
                formValuesTemp = { ...formValuesTemp, ...{ company_landline : company_landline } };

            }else if ( fieldName==='company_landline_code'){
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_code : value.toString().trim() } };
    

            }else if ( fieldName==='company_phone'){

                let company_phone = value.replace(' ','');
                setShowMobileMsg('');
                if ( ! Utilities.contactNumberValidate( company_phone ) ){
                    setShowMobileMsg( Utilities.contactNumberValidate( company_phone, 1 ).msg);
                    jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
                formValuesTemp = { ...formValuesTemp, ...{ company_phone : company_phone } };

            }else if ( fieldName==='company_phone_code'){
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_code : value.toString().trim() } };


            }else if ( fieldName==='street_name'){
                formValuesTemp = { ...formValuesTemp, ...{ street_name : value } };
            }else if ( fieldName==='street_number'){
                formValuesTemp = { ...formValuesTemp, ...{ street_number : value } };
            }else if ( fieldName==='building_name'){
                formValuesTemp = { ...formValuesTemp, ...{ building_name : value } };
            }else if ( fieldName==='building_number'){
                formValuesTemp = { ...formValuesTemp, ...{ building_number : value } };
            }else if ( fieldName==='postal_code'){
                formValuesTemp = { ...formValuesTemp, ...{ postal_code : value } };
            
            }else if ( fieldName==='company_phone_checked'){
                value = parseInt(formValuesTemp.company_phone_checked)===1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked : value } };
            }else if ( fieldName==='company_landline_checked'){
                value = parseInt(formValuesTemp.company_landline_checked)===1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked : value } };

            }else if ( fieldName==='address_visibility_status'){
                value = parseInt(formValuesTemp.address_visibility_status)===1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ address_visibility_status : value } };
            }
        
            setFormValues(formValuesTemp);

            if ( ! Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'].includes(fieldName) && value===1  ){
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.mobileActivationWarningMessage ,
                    overlayClassName : 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                        { label: 'continue',  onClick: () => confirmContactNumber(fieldName, 1)  }
                    ],
                    onClickOutside: () => confirmContactNumber(fieldName, 0),
                    onKeypressEscape: () => confirmContactNumber(fieldName, 0),
                }); 
            }

            if ( ! Utilities.isEmpty(fieldName) && ['address_visibility_status'].includes(fieldName) && value===1  ){
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.companyAddressVisibilityWarningMessage ,
                    overlayClassName : 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmAddressVisibility(0) },
                        { label: 'continue',  onClick: () => confirmAddressVisibility( 1)  }
                    ],
                    onClickOutside: () => confirmAddressVisibility(0),
                    onKeypressEscape: () => confirmAddressVisibility(0),
                }); 
            }
        }

        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }
       
    }

    const confirmAddressVisibility = ( status = 0 ) => {
        setFormValues({...formValues, address_visibility_status : status });
    }

    const confirmContactNumber = ( fieldName = '', value = 0 ) => {

        if ( ! Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'] ){
            let formValuesTemp = formValues;

            if ( fieldName==='company_phone_checked'){
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked : parseInt(value) } };

            }else if ( fieldName==='company_landline_checked'){
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked : parseInt(value) } };
            }

            setFormValues(formValuesTemp);
        }
    }


    const getCoordinatesByComplereLocation = async () => {

        let formValuesTemp = formValues;

        let addressArray = [];
        //addressArray.push(formValuesTemp.building_number);
        addressArray.push(formValuesTemp.building_name);
        addressArray.push(formValuesTemp.street_name);
        addressArray.push(formValuesTemp.city_name);
        addressArray.push(formValuesTemp.state_name);
        addressArray.push(formValuesTemp.country_name);

        addressArray = addressArray.filter(function (el) {
            return el != null;
        });

        const address = addressArray.join(',');

        setMapLocationProvided(address);
        setMapLocationAction(1);
    }


    const getCurrentLocation = async () => {

        dispatch( showLoaderReducer(1) );

        const responseGeoLocation = await axios.get(ApiUrls.externalIPgeolocationDB)
        await  axios
            .post( ApiUrls.citySearchByCityName, {...responseGeoLocation.data, keyword : responseGeoLocation.data.city } )
            .then( response => {
        
                 dispatch( showLoaderReducer(0) );
                    if ( parseInt(response.status)===200 ){

                        const location_detail = response.data.detail;
                        if ( parseInt(response.data.status)===1 ){
                            
                            // CHANGE MAP
                            setMapLocationAction(1);
                            const coordinatesValue = { lat : parseFloat(location_detail.latitude) , lng : parseFloat(location_detail.longitude) };
                            setCoordinates(coordinatesValue);
                            setMapLocationAction(3);

                            let profileCompanyTemp = { 
                                ...formValues ,

                                country_id : location_detail.country_id ?? 0,
                                state_id : location_detail.state_id ?? 0,
                                city_id : location_detail.city_id ?? 0,
                                area_id : 0,

                                country_name : location_detail.country_name ?? 'select country',
                                state_name : location_detail.state_name ??  'select state',
                                city_name : location_detail.city_name ??  'select city',
                                area_name : 'select suburb',

                                street_name : '',
                                street_number : '',
                                building_name : '',
                                building_number : '',
                                postal_code : '',

                                latitude : location_detail.latitude.toString(),
                                longitude : location_detail.longitude.toString(),
                            };
                        
                            setFormValues(profileCompanyTemp);

                            getStateByCountryID( location_detail.country_id ?? 0 );
                            getCityByStateID( location_detail.state_id ?? 0 );
                            getAreByCityID( location_detail.city_id ?? 0 );

                        }
                    }
            })
            .catch( (err) => {
                dispatch( showLoaderReducer(0) );
            });
            
        
    }

    const changeCountryCodeLandline = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagLandline(defaultFlagTemp);
        setDefaultCountryCodeLandline(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_landline_code : passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeMobile = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagMobile(defaultFlagTemp);
        setDefaultCountryCodeMobile(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_phone_code : passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    // COUTNRIES
    const getCountries = ( setupContact = {} ) => {

        axios
        .get( ApiUrls.country, {} )
        .then( response => {
            if ( response.status===200 ){
                let optionList = [];
                const countriesList = response.data;
                
                let country_names = {};
                Object.entries(countriesList).map( ([key, row] ) => {
                    optionList = [...optionList , { value: row.id , label : row.country_name} ];

                    country_names[row.id] = row.country_name;

                    if ( Object.keys(setupContact).length>0 ){

                        if ( parseInt(setupContact.company_landline_code)===parseInt(row.id) ) {
                            setDefaultFlagLandline(row.country_short_code.toLowerCase());
                            setDefaultCountryCodeLandline(row.phonecode);
                        }
    
                        if ( parseInt(setupContact.company_phone_code)===parseInt(row.id) ) {
                            setDefaultFlagMobile(row.country_short_code.toLowerCase());
                            setDefaultCountryCodeMobile(row.phonecode);
                        }
                    }

                });
                setCountryList(optionList);
                setCountries(countriesList);
                setCountryNames(country_names);
            }
        })
        .catch( (err) => {  });
    }

    // STATES
    const getStateByCountryID = ( country_id = 0 ) => {
        
        if ( country_id>0 ){
            axios
            .post( ApiUrls.stateList , { id: 0, country_id : country_id } )
            .then( response => {
                if ( response.status===200 ){
                    let optionList = [];
                    Object.entries(response.data).map( ([key, row] ) => {
                        optionList = [...optionList , { value: row.id , label : row.state_name} ];
                    });
                    setStateList(optionList);
                }
            })
            .catch( (err) => {
            
            });
        }
    }

    // CITIES
    const getCityByStateID = ( state_id = 0 ) => {

        if ( state_id>0 ){
            axios
            .post( ApiUrls.cityList , { id: 0, state_id : state_id } )
            .then( response => {
                if ( response.status===200 ){
                    let optionList = [];
                    Object.entries(response.data).map( ([key, row] ) => {
                        optionList = [...optionList , { value: row.id , label : row.city_name} ];
                    });
                    setCityList(optionList);
                }
            })
            .catch( (err) => {
            
            });
        }
    }

    // AREA / SUBURB
    const getAreByCityID = (city_id = 0 ) => {

        if ( city_id>0 ){
            axios
            .post( ApiUrls.areaList , { id: 0, city_id : city_id } )
            .then( response => {
                if ( response.status===200 ){
                    let optionList = [];
                    Object.entries(response.data).map( ([key, row] ) => {
                        optionList = [...optionList , { value: row.area_id , label : row.area_name} ]
                    });
                    setAreaList(optionList);
                }
            })
            .catch( (err) => {
            
            });
        }
    }


    const submitDetail = async (e) => {
        e.preventDefault();

        let formValuesTemp = formValues;
        let company_detail = profileDetail.company_detail;
        
        formGroupKeywordElem.removeClass('error');

        const formData = new FormData();
        formData.append('company_id', formValues.company_id );
       
        const locationFields = ['country_id', 'state_id', 'city_id', 'area_id'];
        const requiredFields = ['company_name', 'company_display_name', 'company_email', 'country_id', 'state_id', 'city_id', 'street_name', 'company_landline_code', 'company_phone_code' ];

        const saveFields = ['company_name', 'company_website', 'company_email', 'company_landline', 'country_id', 'state_id', 'city_id', 'area_id', 'street_name', 'street_number', 'building_name', 'building_number', 'company_landline_code', 'company_phone_code', 'company_phone', 'postal_code', 'category_id', 'business_type', 'address_visibility_status' ];

        
        let errorCounter = 0, responseChecker = false;
        requiredFields.map( key => {
            const value = jQuery('input[name="'+key+'"]').val();
            if ( Utilities.isEmpty(value )){
                jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }else{
                formData.append(key, value);
            }

            if ( ['company_name', 'company_display_name'].includes(key) ){
                responseChecker = Utilities.inputCharacterChecker(value, 'general-companyname');
                if ( responseChecker===false ){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }
        });

        if ( errorCounter>0 ){ return false; }

        saveFields.map( key => {
            const value = jQuery('input[name="'+key+'"]').val();
            formData.append(key, value);
        });

        locationFields.map( key => {
            const inputElem = jQuery('input[name="'+key+'"]');
            if (inputElem.length>0){
                let value = inputElem.val();
                value = ( Utilities.isEmpty(value) ) ? 0 : value;
                if ( key==='country_id'){
                     formData.append(key, value);
                     formValuesTemp = { ...formValuesTemp, ...{ country_id : value } };
                }else if ( key==='state_id'){
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ state_id : value } };
                }else if ( key==='city_id'){
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ city_id : value } };
                }else if ( key==='area_id'){
                    formData.append(key, value);
                    formValuesTemp = { ...formValuesTemp, ...{ area_id : value } };
                }

            }
        });
        
        let contactErrorCounter = 0;
        if ( ! Utilities.isEmpty(formValuesTemp.company_landline) && ! Utilities.contactNumberValidate( formValuesTemp.company_landline ) ){
            jQuery('[name="company_landline"]').closest('.form-group').addClass('error');
            contactErrorCounter++;
        }

        if ( ! Utilities.contactNumberValidate( formValuesTemp.company_phone ) ){
            jQuery('[name="company_phone"]').closest('.form-group').addClass('error');
            contactErrorCounter++;
        }

        if ( contactErrorCounter>0 ){ return false; }


        if ( formInfoValues.company_keywords.length===0 ){
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired );
            formGroupKeywordElem.addClass('error');
            return false;
        }


        formData.append('logo', profileLogoValue);
        formData.append('company_keywords', formInfoValues.company_keywords.length>0 ? formInfoValues.company_keywords.join('|') : '');
        formData.append('company_about', formInfoValues.company_about);
        
        formData.append('company_phone_checked', parseInt(formValuesTemp.company_phone_checked || 0) );
        formData.append('company_landline_checked', parseInt(formValuesTemp.company_landline_checked || 0) );


        formData.append('latitude', coordinates.lat);
        formData.append('longitude', coordinates.lng);

        let socialFieldValues = Object.entries(formSocialValues).filter( val => val ).join('|');
        formData.append('company_social', socialFieldValues);

        const button_name = 'btn-submit-detail';

        const logo_value = document.getElementById('input_select_file').value;
        if ( !Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue) ){

            Utilities.elementDisabledStatus(button_name, true );

            const formLogoData = new FormData();
            formLogoData.append('account_id', formValues.company_id);
            formLogoData.append('file', profileLogoValue);
            
            const responseUpload = await uploadProfileLogo( formLogoData );
            Utilities.elementDisabledStatus(button_name, false );

            if ( parseInt(responseUpload.status)===1 ){
                company_detail = {  ...company_detail, company_logo : responseUpload.file_detail.filename, logo_url : responseUpload.file_detail.url_thumb };

                setProfileLogo( responseUpload.file_detail.url_thumb );
                setProfileLogoOld( responseUpload.file_detail.url_thumb );
                
            }
        }

        Utilities.elementDisabledStatus(button_name, true );

        axios
        .post( ApiUrls.companyUpdateDetail ,  formData, {  headers : { 'Content-Type': 'multipart/form-data' } } )
        .then( response => {

            Utilities.elementDisabledStatus(button_name, false );

            setShowButtonOption(false);
            setAllowFormContent(false);

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);

                    if ( dataStatus>0){
                        Utilities.messagePopup('success', TitlesLabels.alertMessages.profileUpdatedSuccessfully );

                        let profileDetailTemp = profileDetail;
        
                        company_detail = { 
                                                    ...company_detail,
                                                    company_name : formValuesTemp.company_name,
                                                    company_display_name : formValuesTemp.company_display_name,
                                                    company_email : formValuesTemp.company_email,
                                                    company_website : formValuesTemp.company_website,

                                                    company_landline : formValuesTemp.company_landline,
                                                    company_landline_code : formValuesTemp.company_landline_code,

                                                    company_phone_code : formValuesTemp.company_phone_code,
                                                    company_phone : formValuesTemp.company_phone,

                                                    country_id : formValuesTemp.country_id,
                                                    state_id : formValuesTemp.state_id,
                                                    city_id : formValuesTemp.city_id,
                                                    area_id : formValuesTemp.area_id,

                                                    street_name : formValuesTemp.street_name,
                                                    street_number : formValuesTemp.street_number,
                                                    building_name : formValuesTemp.building_name,
                                                    building_number : formValuesTemp.building_number,
                                                    postal_code : formValuesTemp.postal_code,

                                                    company_phonne_checked : parseInt(formValuesTemp.company_phonne_checked || 0),
                                                    company_landline_checked : parseInt(formValuesTemp.company_landline_checked || 0),

                                                    latitude : coordinates.lat,
                                                    longitude : coordinates.lng,
                                            }

                        profileDetailTemp = { 
                                                ...profileDetail,
                                                company_detail
                                            };

                        localStorage.setItem(
                            process.env.REACT_APP_session_login,
                            JSON.stringify(profileDetailTemp)
                        );

                        setFormInfoValuesOld(formInfoValues);
                        setFormValues({...formValuesTemp, password : '', password_confirm : ''});
                        
                        setFormValuesOld(profileDetailTemp);
                        setCoordinatesOld({ lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) });
                        
                        setFormSocialValues(formSocialValues);
                        setFormSocialValuesOld(formSocialValues);
                        
                        // UPDATE PROFILE IN LIST 
                        Utilities.updateProfileInAccountList(profileDetailTemp);
                        dispatch( reloadProfile(Date.now()) );

                    }else if ( dataStatus===-1){
                        Utilities.messagePopup('error', Utilities.labelEmailAddressTaken );
                    }else{
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdateProfile );
                    }

                }else{
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdateProfile );
                }
        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg );
            Utilities.elementDisabledStatus(button_name, false );

        }); 

    }


    const setupForm = async ( reqID ) => {
        reqID = parseInt(reqID);

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){

                Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                    const account_id = parseInt(acctdetail.account_id);
                    if (account_id===reqID){

                        let accountProfile = Utilities.formatProfileDetail(acctdetail);
                        setProfileDetail(accountProfile);
                        
                        let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                        setCompanyDetail(profileCompanyTemp);

                        /* profileCompanyTemp = { 
                            ...profileCompanyTemp ,
                            company_id : profileCompanyTemp.account_id,
                            password: '',
                            password_confirm : ''
                        }; */


                        setProfileLogo(profileCompanyTemp.logo_url);
                        setProfileLogoOld(profileCompanyTemp.logo_url);
                        //setFormValues(profileCompanyTemp);

                        axios
                        .post( ApiUrls.profilegetByID ,  { id : profileCompanyTemp.account_id } )
                        .then( response => {

                            if ( response.status===200 ){
                                if ( response.data.status===1 ){
                                    
                                    const compData = response.data.detail;

                                    getStateByCountryID( compData.country_id );
                                    getCityByStateID( compData.state_id );
                                    getAreByCityID( compData.city_id );

                                    profileCompanyTemp = { 
                                        ...profileCompanyTemp ,
                                        company_id : profileCompanyTemp.account_id,
                                        password: '',
                                        password_confirm : '',

                                        country_id : compData.country_id,
                                        state_id : compData.state_id,
                                        city_id : compData.city_id,
                                        area_id : compData.area_id,

                                        country_name : compData.country_name,
                                        state_name : compData.state_name,
                                        city_name : compData.city_name,
                                        area_name : compData.area_name,

                                        company_phone_checked : parseInt(compData.mobile_number_status),
                                        company_landline_checked : parseInt(compData.landline_number_status),

                                        category_id : compData.category_id,
                                        category_name : compData.category_name,

                                        latitude : compData.latitude,
                                        longitude : compData.longitude,

                                        category_name : compData.category_name,
                                        address_visibility_status : parseInt(compData.address_visibility_status)
                                    };
                                                                   
                                    setFormValues(profileCompanyTemp);
                                    setFormValuesOld(profileCompanyTemp);

                                    setMapLocationAction(0);

                                    const coordinatesAssigned = { lat: parseFloat(profileCompanyTemp.latitude), lng: parseFloat(profileCompanyTemp.longitude) };
                                    setCoordinatesOld(coordinatesAssigned);

                                    let timer = setTimeout( function (){
                                        setCoordinates(coordinatesAssigned);
                                        setMapLocationAction(3);
                                        clearTimeout(timer);
                                    }, 100 );

                                    const formInfoValues = {
                                        company_about : compData.company_about ?? '',
                                        company_keywords : ! Utilities.isEmpty(compData.keywords) ? compData.keywords.split(',') : [],
                                    }

                                    setFormInfoValues(formInfoValues);
                                    setFormInfoValuesOld(formInfoValues);
                                    
                                    if ( compData.hasOwnProperty('company_social') ){
                                        const socialLinks = { ...formSocialValues,
                                                                facebook : compData.company_social.facebook,
                                                                youtube : '', //compData.company_social.youtube,
                                                                twitter : compData.company_social.twitter,
                                                                tiktok : compData.company_social.tiktok,
                                                                instagram : compData.company_social.instagram
                                                            };
                                        setFormSocialValues(socialLinks);
                                        setFormSocialValuesOld(socialLinks);
                                    }

                                    getCountries({ company_landline_code : profileCompanyTemp.company_landline_code, company_phone_code : profileCompanyTemp.company_phone_code });

                                }
                            }
                        })
                        .catch( (err) => {
                       
                        });

                    }
                });
                
            }

        }else{
            getStateByCountryID( defaultLocation.country_id );
            getCityByStateID( defaultLocation.state_id );
            getAreByCityID( defaultLocation.city_id );
        }

        
    }


    const focusDropdown = ( input_id) => {
        
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( !Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '' ) => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }


    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    const submitCompanyAbout = () => {
        const charCounter = Utilities.wordsCounter(formInfoValues.company_about);
        if ( parseInt(charCounter)>limitCharacters ){
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!` );
            return false;
        }
        
        setShowSection('');
    }

    const setUpAboutContent = () => {
        setFormInfoValuesPrev(formInfoValues);
        setShowSection('company-info');
    }
    
    const submitCompanyKeyword = () => {

        let keywordLoop = '', keywordJoined = [], keywordError = 0, formKeywordElem = jQuery('#form-keyword-info');
        formKeywordElem.find('input').each( function () {
            keywordLoop = jQuery(this).val();
            if ( ! Utilities.isEmpty(keywordLoop) ){
                if  ( ! Utilities.checkSpecialChar(keywordLoop) ){
                    keywordJoined.push(keywordLoop.toLowerCase());
                    jQuery(this).closest('.form-group').removeClass('error');
                }else{
                    jQuery(this).closest('.form-group').addClass('error');
                    keywordError++;
                }
            }
        }); 


        if ( keywordError>0 ){
            Utilities.messagePopup('Error', TitlesLabels.general.specialCharactersNotAllowed );
            return false;
        }

        if ( keywordJoined.length===0 ){
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired );
            return false;
        }

        setFormInfoValues({...formInfoValues, company_keywords : keywordJoined });
        setShowSection('');
    }

    const setUpkeywordContent = () => {

         // USE JQUERY FOR KEYWORD ----------------------------------------
         extracyCompanyKeywords(formInfoValues.company_keywords);
         // ----------------------------------------

         setFormInfoValuesPrev(formInfoValues);
        setShowSection('keyword-info');
    }


    const cancelKeywordContent = () => {
        setFormInfoValues(formInfoValuesPrev);
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }

    const cancelAboutContent = () => {
        setFormInfoValues(formInfoValuesPrev);
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }

    const cancelEdit= () => {
        setShowButtonOption(false);
        setAllowFormContent(false);
        setFormInfoValues(formInfoValuesOld);

        setMapLocationAction(0);

        let timer = setTimeout( function (){
            setCoordinates(coordinatesOld);
            setMapLocationAction(3);
            clearTimeout(timer);
        }, 250 );

        resetPassword();

        setFormValues(formValuesOld);
        setShowLandlineMsg('');
        setShowMobileMsg('');

        setFormSocialValues(formSocialValuesOld);
    }


    const resetPassword = () => {
        setFormValues({...formValues, ...{ password : '', password_confirm : '' } });
        jQuery('[name="password"], [name="password_confirm"]').closest('.form-group').removeClass('error');
    }


    const handleCoordinatesRequest = ( coodinatesResponse = coordinatesInitial ) => {
        /* let formValuesTemp = { ...formValues, ...{ latitude : coodinatesResponse.lat.toString(), longitude : coodinatesResponse.lng.toString() } };
        setFormValues(formValuesTemp); */
        setCoordinates(coodinatesResponse);
    }


    const submitCompanySocial = () => {
 
        if ( ! Utilities.isEmpty( formSocialValues.youtube ) && ! Utilities.isValidURL( formSocialValues.youtube ) ){
            Utilities.messagePopup('warning', TitlesLabels.alertMessages.invalidYoutubeUrl  );
            return false;
        }

        setShowSection('');
    }

    const htmlContent = () => {

        if ( showSection==='company-social'){

            return <section className="w-100">
                        <div className="container py-5">
                            <div id="form-company-social" className="col-sm-4 mx-auto">

                                <ul className="company-social-input px-4">
                                    <li className="social-facebook mb-2"><input type="text" className="pl-4" placeholder="user name"  value={formSocialValues.facebook} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ facebook : e.target.value } }) } /></li>
                                    <li className="social-twitter mb-2"><input type="text" className="pl-4" placeholder="user name"  value={formSocialValues.twitter} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ twitter : e.target.value } }) } /></li>
                                    <li className="social-instagram mb-2"><input type="text" className="pl-4" placeholder="user name"  value={formSocialValues.instagram} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ instagram : e.target.value } }) }  /></li>
                                    <li className="social-tiktok"><input type="text" style={{ paddingLeft : '35px' }} placeholder="user name"  value={formSocialValues.tiktok} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ tiktok : e.target.value } }) } /></li>
                                </ul>
                                
                                <div className="mt-5 pt-4">
                                    <button type="button" className="btn-theme-black" onClick={ () => submitCompanySocial() }>submit</button>
                                </div>
                            </div>

                        </div>
                    </section>
                    
        }else if( showSection==='company-info' ){
            return <section className="col-12 col-md-8 col-lg-7 px-5 mx-auto pt-3 form-theme text-lowercase mt-3">

                    <div id="form-company-info">
                                    
                        <div className="form-group">
                            <textarea id="company_about" name="company_about" className="form-control" rows="10" placeholder=" " value={formInfoValues.company_about} onChange={ (e) => setFormInfoValues({ ...formInfoValues, ...{ company_about : e.target.value } }) }></textarea>
                            <label className="form-label">about your business</label>
                        </div>

                        <div className="d-flex align-items-center justify-content-between text-color-gray">
                            <div className="text-color-blue cursor-pointer" onClick={ () => setFormInfoValues({...formInfoValues, company_about : '' }) }>clear</div>
                            <div>{ Utilities.number_format(limitCharacters.toString()) } words</div>
                        </div>
                        
                        <div className="mt-5 pt-5 button-group-boolean">
                            <button type="button" className="btn-theme-black" onClick={ () => cancelAboutContent() }>cancel</button>
                            <button type="button" className="btn-theme-black" onClick={ () => submitCompanyAbout() }>submit</button>
                        </div>

                    </div>
                </section>

        }else if( showSection==='keyword-info' ){
            return <section className="col-12 col-md-8 col-lg-7 px-5 mx-auto pt-3 form-theme text-lowercase mt-3">
                    <div id="form-keyword-info" >
                        
                        <div className="col-md-10 mx-auto">
                        {
                            [1,2,3,4,5].map( key => 
                                <div key={key} className="form-group ">
                                    <input type="text" name={`keyword_${key.toString()}`} className="form-control" placeholder=" " onChange={ (e) => pushKeywordValue( e, 'keyword_'+key.toString() ) }  autoComplete="off" ></input>
                                    <label className="form-label">keyword</label>
                                </div>
                            )
                        }
                        </div>

                        <div className="mt-5 pt-5 button-group-boolean">
                            <button type="button" className="btn-theme-black" onClick={ () => cancelKeywordContent() }>cancel</button>
                            <button type="button" className="btn-theme-black" onClick={ () => submitCompanyKeyword() }>submit</button>
                        </div>

                    </div>
                </section>


        }else{
            return <form className="col-8 mx-auto pt-1 form-theme text-lowercase"  autoComplete="off">

                        <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension } readOnly={true} onChange={ (e) => previewImage(e) } ></input>
                        <input type="hidden" id="input_company_phone_code"  name="company_phone_code"  readOnly={true} value={formValues.company_phone_code}  onChange={ (e) => pushValue( e, 'company_phone_code') } ></input>
                        <input type="hidden" id="input_company_landline_code"  name="company_landline_code"  readOnly={true} value={formValues.company_landline_code}  onChange={ (e) => pushValue( e, 'company_landline_code') } ></input>
                        <input type="hidden" id="input_category_id" name="category_id" readOnly={true} value={ formValues.category_id }  onChange={ (e) => pushValue( e, 'category_id') } ></input>

                        <div className={`${ allowFormContent===false ? 'content-disabled' : '' }`}>
                            
                            <section className="form-col-2">

                                <div>
                                    <div className="avatar-wrap avatar-md-wrap">
                                        <div>
                                            <div className={`avatar-preview border  rounded-circle mx-auto position-relative ${allowFormContent===true ? 'overflow-visible' : '' }`} style={{ backgroundImage:  `url(${profileLogo})` }} >
                                            </div>
                                            { allowFormContent===true && <button type="button" className="btn-select-file text-lowercase mx-auto position-relative" onClick={ () => selectImage() }>select image</button> }
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">{Utilities.labelUploadSpecs}</div>
                                </div>


                                <div className="pt-3">

                                    
                                    <FieldInput inputName="company_name" inputLabel="Company Name" inputValue={ formValues.company_name } pushValue={pushValue} checkerType="general-companyname"/>

                                    <FieldInput inputName="company_display_name" inputLabel="Display Name" inputValue={ formValues.company_display_name } pushValue={pushValue} checkerType="general-companyname"/>


                                    <div className="form-group ">
                                        <input type="text" name="company_email" className="form-control" placeholder=" " autoComplete="new-password" value={ formValues.company_email }  onChange={ (e) => pushValue( e, 'company_email') } disabled ></input>
                                        <label className="form-label">Email</label>
                                    </div>

                                    <div className="form-group ">
                                        <input type="text" name="company_website" className="form-control" placeholder=" " autoComplete="off" value={ formValues.company_website }  onChange={ (e) => pushValue( e, 'company_website') } ></input>
                                        <label className="form-label">Company Website</label>
                                    </div>

                                </div>
                            </section>



                            <section className="form-col-2 form-col-x-0">
                                
                                <div>
                                    <div className="form-group active optional has-off-on">
                                        <div className="form-group-mobile pr-4">
                                            <Dropdown className="dropdown-country-selection hide-arrow">
                                                <Dropdown.Toggle>
                                                    <div className="flag-code" onClick={ () => focusDropdown('landline-input') }>
                                                        <span className={`flag-icon flag-icon-${defaultFlagLandline} mr-2`}></span>
                                                        <span>{ !Utilities.isEmpty(defaultCountryCodeLandline) ? '+'+defaultCountryCodeLandline: ''}</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                
                                                <Dropdown.Menu >
                                                    <div className="px-4 mb-1">
                                                        <input type="text" id="landline-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'landline-input-list', 'search-keyword') }></input>
                                                    </div>
                                                    <div id="landline-input-list" className="country-code-list">
                                                    {
                                                        Object.entries(countries).map( ([key, row] ) => 
                                                            <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeLandline(row) } >
                                                                <div className="flag-code">
                                                                    <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                                    <span>{ row.country_name }</span>
                                                                    <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                                </div>
                                                            </Dropdown.Item>
                                                        )
                                                    }
                                                    </div> 
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        
                                            <input type="number" name="company_landline" className="form-control" placeholder=" " autoComplete="off"  value={ formValues.company_landline }  onChange={ (e) => pushValue( e, 'company_landline') } ></input>
                                        </div>
                                        <label className="form-label">Landline Number</label>
                                        <div className="optional-label position-placeholder mr-5">(Optional)</div>

                                        <input type="checkbox" className="checkbox-onoff" value={ formValues.company_landline_checked }  checked={formValues.company_landline_checked===1 }   onChange={ (e) => pushValue(e, 'company_landline_checked') } ></input>

                                    </div>

                                    {  ( ! Utilities.isEmpty(showLandlineMsg) && ! Utilities.isEmpty(formValues.company_landline) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showLandlineMsg }</div> }

                                </div>


                                <div>
                                    <div className="form-group active has-off-on">
                                        <div className="form-group-mobile">
                                            <Dropdown className="dropdown-country-selection hide-arrow">
                                                <Dropdown.Toggle>
                                                    <div className="flag-code" onClick={ () => focusDropdown('mobile-input') }>
                                                        <span className={`flag-icon flag-icon-${defaultFlagMobile} mr-2`}></span>
                                                        <span>{ !Utilities.isEmpty(defaultCountryCodeMobile) ? '+'+defaultCountryCodeMobile: ''}</span>
                                                    </div>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu >
                                                <div className="px-4 mb-1">
                                                    <input type="text" id="mobile-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'mobile-input-list', 'search-keyword') }></input>
                                                </div>
                                                <div id="mobile-input-list" className="country-code-list">
                                                {
                                                    Object.entries(countries).map( ([key, row] ) => 
                                                        <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeMobile(row) } >
                                                            <div className="flag-code">
                                                                <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                                <span>{ row.country_name }</span>
                                                                <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                </div>  
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        
                                            <input type="number" name="company_phone" className="form-control" placeholder=" " autoComplete="off" value={ formValues.company_phone }  onChange={ (e) => pushValue( e, 'company_phone') }  ></input>
                                        </div>
                                        <label className="form-label">Mobile Number</label>
                                        
                                        <input type="checkbox" className="checkbox-onoff" value={ formValues.company_phone_checked }  checked={formValues.company_phone_checked===1 }   onChange={ (e) => pushValue(e, 'company_phone_checked') } ></input>
                                    </div>

                                    {  ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(formValues.company_phone) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }
                                    
                                </div>


                                <div className="form-group">
                                    <input type="text" name="business_type" className="form-control cursor-pointer" placeholder=" " readOnly={true} autoComplete="off" value={ formValues.category_name }  onChange={ (e) => pushValue( e, 'category_name') } onClick={ () => dispatch( showPopupCompanyCategoryReducer(true) ) } ></input>
                                    <label className="form-label">Business Type</label>
                                </div>

                                <div className="form-group optional active" >
                                    <div className="form-control cursor-pointer" onClick={ () => setShowSection('company-social') }>view</div>
                                    <label className="form-label">Social Media</label>
                                    <div className="optional-label">Optional</div>
                                </div>

                                <div id="form-group-keyword" className="form-group active">
                                    <div className="form-control cursor-pointer" onClick={ () => setUpkeywordContent() }>view</div>
                                    <label className="form-label">Keyword Info</label>
                                </div>


                                <div className="form-group optional active">
                                    <div className="form-control cursor-pointer" onClick={ () => setUpAboutContent() }>view</div>
                                    <label className="form-label">Company Info</label>
                                    <div className="optional-label">Optional</div>
                                </div>

                            </section>

                            

                            <div className="form-heading text-lowercase mt-3 pb-3 mb-2">Address</div>

                            <div className="form-col-2">
                                
                                <div className="form-group active">
                                    <Select options={countryList} id="country_id" name="country_id"  onChange={ (e) => pushValue( e, 'country_id') }  placeholder="select country" defaultValue={ { value: formValues.country_id , label: formValues.country_name } } value={ { value: formValues.country_id , label: formValues.country_name } } className="boostrap-select2" />
                                    <label className="form-label">Country</label>
                                </div>

                                <div className="form-group active">
                                    <Select options={stateList}   ref={selectStateRef}  name="state_id" placeholder="select state" onChange={ (e) => pushValue( e, 'state_id') }   defaultValue={ { value: formValues.state_id , label: formValues.state_name } }  value={ { value: formValues.state_id , label: formValues.state_name } }  className="boostrap-select2"/>
                                    <label className="form-label">State or Province</label>
                                </div>  
                                
                            </div>

                            <div className="form-col-2">
                                
                                <div className="form-group active">
                                    <Select options={cityList}   ref={selectCityRef} name="city_id"  placeholder="select city" onChange={ (e) => pushValue( e, 'city_id') }   defaultValue={ { value: formValues.city_id , label: formValues.city_name } } value={ { value: formValues.city_id , label: formValues.city_name } } className="boostrap-select2"/>
                                    <label className="form-label">City or Town</label>
                                </div>

                                <div className="form-group optional active">
                                    <Select options={areaList}    ref={selectAreaRef} name="area_id" placeholder="select suburb" onChange={ (e) => pushValue( e, 'area_id') }  defaultValue={ { value: formValues.area_id , label: formValues.area_name } }   value={ { value: formValues.area_id , label: formValues.area_name } } className="boostrap-select2" />
                                    <label className="form-label">Suburb</label>
                                    <div className="optional-label">Optional</div>
                                </div>

                            </div>

                            <div className="form-col-2">
                                <div className="form-group">
                                    <input type="text" name="street_name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.street_name }  onChange={ (e) => pushValue( e, 'street_name') } onBlur={ () => getCoordinatesByComplereLocation() }></input>
                                    <label className="form-label">Street Name</label>
                                </div>

                                <div className="form-group optional">
                                    <input type="text" name="street_number" className="form-control" placeholder=" " autoComplete="off" value={ formValues.street_number }  onChange={ (e) => pushValue( e, 'street_number') } ></input>
                                    <label className="form-label">Street No.</label>
                                    <div className="optional-label">Optional</div>
                                </div>
                            </div>

                            <div className="form-col-2">
                                <div className="form-group optional">
                                    <input type="text" name="building_name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.building_name }  onChange={ (e) => pushValue( e, 'building_name') } onBlur={ () => getCoordinatesByComplereLocation() } ></input>
                                    <label className="form-label">Building or Complex Name</label>
                                    <div className="optional-label">Optional</div>
                                </div>

                                <div className="form-group optional">
                                    <input type="text" name="building_number" className="form-control" placeholder=" " autoComplete="off" value={ formValues.building_number }  onChange={ (e) => pushValue( e, 'building_number') } ></input>
                                    <label className="form-label">Apartment, Office or Unit No.</label>
                                    <div className="optional-label">Optional</div>
                                </div>
                            </div>

                            <div className="form-col-2">
                                <div className="form-group optional">
                                    <input type="text" name="postal_code" className="form-control" placeholder=" " autoComplete="off" value={ formValues.postal_code }  onChange={ (e) => pushValue( e, 'postal_code') } ></input>
                                    <label className="form-label">Postal Code</label>
                                    <div className="optional-label">Optional</div>
                                </div>
                            </div>
                            
                            <div className="form-col-2">
                                <div className="form-group  has-off-on border-0">
                                    <div className="form-control text-blue-placehoder">show full address</div>
                                    <input type="checkbox" className="checkbox-onoff" name="address_visibility_status"  value={ formValues.address_visibility_status }  checked={formValues.address_visibility_status===1 }   onChange={ (e) => pushValue(e, 'address_visibility_status') }></input>
                                </div>
                            </div>

                            <div className="mt-4">
                                <MapBox  
                                    coordinatesAttributes={coordinates}
                                    handleCoordinatesRequest={handleCoordinatesRequest}
                                    mapMarkerDraggableAttribues={true}
                                    mapLocationActionAttributes={mapLocationAction}
                                    mapLocationProvidedAttributes={mapLocationProvided} />
                            </div>
                            
                            {
                                showButtonOption===true &&
                                    <div className="pt-2 text-center"><span className="text-blue cursor-pointer"  onClick={ () => getCurrentLocation() }>{ TitlesLabels.general.clickHereToGetCurrentLocation }</span></div>
                            }
                        </div>

                    

                        <div className="button-wrap text-lowercase text-center my-5">
                            
                            {
                                showButtonOption===false ?
                                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => { setShowButtonOption(true); setAllowFormContent(true);  resetPassword(); } }>edit</button>
                                :
                                    <div className="button-group-boolean">
                                        <button type="button" className="btn-theme-black text-lowercase"
                                            onClick={ () => cancelEdit() }>Cancel</button>
                                        <button type="button" id="btn-submit-detail" className="btn-theme-black text-lowercase button-has-loader"  onClick={ (e) => submitDetail(e) }>Update</button>
                                    </div>
                            }

                        </div>

                    </form>
        }
    }


    useEffect( () => {
        getCountries();
        
    }, [] );


    useEffect( async () => {
        if( allowFormContent===false ){
            setProfileLogo(profileLogoOld);
        }
    }, [countries] );


    useEffect( async () => {
        if( allowFormContent===false ){
            setProfileLogo(profileLogoOld);
        }
    }, [allowFormContent] );


    useEffect( () => {

        if ( Object.entries( RM.isNil(passCompanyCategoryDetail) ? {} : passCompanyCategoryDetail ).length>0 ){

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ category_id : passCompanyCategoryDetail.category_id.toString() , category_name : passCompanyCategoryDetail.category_name.toString()  } };
            setFormValues(formValuesTemp);

            dispatch( passCompanyCategoryDetailReducer({}) );
        }

    }, [passCompanyCategoryDetail ])
    
    useEffect( () => {

        let reqAccountID = match.params.mid;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if ( reqAccountID>0 ){
               setupForm( reqAccountID );
            }
        }

    }, [match.params.mid] );

    useEffect( () => {
        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.companyProfile }`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );

    }, []);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div className="current">{ TitlesLabels.navigations.manageCompanyProfile }</div>
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div>

                         </li>
                     </ul>
                </div>


            </div>

            <div className="main-content">
                <div className="container">

                    { htmlContent() }

                </div>
            </div>
        </div>
        
    )
}

export default CompanyForm;