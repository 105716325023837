import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PagesAuthentication from '../../../helpers/PagesAuthentication';
import TitlesLabels from '../../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../../actions';

import Password from './Password';
import Email from './Email';
import Mobile from './Mobile';


const ProfileSecurity = () => {

    const dispatch = useDispatch();

    const tabOptionArray = { 1 : 'password', 2 : 'email', 3 : 'mobile' };

    const [ loginDetail , setLoginDetail ] = useState({});
    const [ activeOption , setActiveOption ] = useState(1);


    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    const handleOnClickTabOption = ( tabId = 1 ) => {
        setActiveOption( tabId );
    }

    

    const tabContentHtml = () => {
        let htmlReturn = '';

        if ( activeOption===1 ){
            htmlReturn = <Password profileProvided={loginDetail} />;

        }else if( activeOption===2 ){
            htmlReturn = <Email profileProvided={loginDetail} />;

        }else if( activeOption===3 ){
            htmlReturn = <Mobile loginDetailProvided={loginDetail} />;
        }

        return htmlReturn;
    }

    
    useEffect( () => {
        
        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.security }`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();

        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){
                Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                    const account_type = parseInt(acctdetail.account_type);
                    if (account_type===1){
                        setLoginDetail(acctdetail);
                    }
                });
            }
        }


    }, [] );

    return (
        <div className="profile-security-page chat-template-messages-content">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div className="current">{ TitlesLabels.navigations.security }</div>
                           
                          </li>
                         <li className="btn-option">
                             <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                             <div className="btn-icon btn-gear" onClick={ () => triggerSetting() }></div>
                         </li>
                     </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container">

                        <div className="col-5 mx-auto mt-3">
                            <div className="d-flex align-items-center d-flex justify-content-between fs-18">
                                {
                                    Object.entries(tabOptionArray).map( ([ key, labelName ]) => 
                                        <div key={key} className={`cursor-pointer ${ parseInt(key)===activeOption ? '' : 'text-gray' } `}  onClick={ (e) => handleOnClickTabOption( parseInt(key) ) } >{labelName}</div>
                                    )
                                }
                            </div>
                            
                            <div className=" text-lowercase form-theme mt-5 px-5">
                                { tabContentHtml() }
                            </div>
                        </div>

                </div>
            </div>
       </div>
    )
}

export default ProfileSecurity;