import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; 
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';

import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import { reloadProfile, showHeader, showFooter, pageTitle, showLoader, reloadAccountsDB, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer  } from '../../actions';

import DepartmentFormSamples from '../../components/popups/DepartmentFormSamples';
import FieldInput from '../../components/FieldInput';
import PasswordInput from '../../components/PasswordInput';

const FormDepartment = ({match}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const defaultLocationUtil = Utilities.defaultLocation();

    const defaultFormValues = {
        department_id : 0,
        department_name : '',
        department_email : '',
        department_password : '',
        department_password_confirm : '',
        department_members : '',
        default_message : '',
        default_msg_status : 0,
        form_status : 0,
        form_status_name : '',
        country_code : parseInt(defaultLocationUtil.country_id), //0,
        contact_number : '',
        type : 1
    };
    
    let companyProfileGlobal = {};
    
    const formStatusOption = Utilities.formTypes();

    const [ listProfiles, setListProfiles ] = useState([]);
    
    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ formValuesOld, setFormValuesOld ] = useState(defaultFormValues);

    const [ formAction , setFormAction ] = useState('add');
    const [ showTeamList , setShowTeamList ] = useState(false);
    const [ companyProfile, setCompanyProfile ] = useState([]);
    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ domainName, setDomainName ] = useState('');

    const [ memberIDs , setMemberIDs ] = useState([]);
    const [ memberIDsOld , setMemberIDsOld ] = useState([]);

    const [ memberNames, setMemberNames ] = useState('');
    const [ memberNameOlds, setMemberNamesOld ] = useState('');
    const [ teamAdmin, setTeamAdmin ] = useState([]);
    
    const [ showButtonOption, setShowButtonOption ] = useState(false);
    const [ showMessageOption, setShowMessageOption ] = useState(false);

    const [ allowFormContent, setAllowFormContent ] = useState(false);
    const [ formStatusHold, setFormStatusHold ] = useState({});

    const [ showFormPopUp, setShowFormPopUp ] = useState(false);
    const handleCloseFormPopUp = () => setShowFormPopUp(false);

    const [ defaultFlagLandline, setDefaultFlagLandline ] = useState(''); //process.env.REACT_APP_country_iso);
    const [ defaultCountryCodeLandline, setDefaultCountryCodeLandline ] = useState(''); //process.env.REACT_APP_country_ext);

    const [ countries, setCountries] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');


    const [ showMobileMsg, setShowMobileMsg] = useState(false);
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        
        let formValuesTemp = formValues;
        let errorCounter = 0;

        if ( fieldName==='department_name'){
            const email_value = Utilities.removeSpecialChar(value);
            formValuesTemp = { ...formValuesTemp, ...{ department_name : value, department_email :  email_value } };
        }else if ( fieldName==='department_email'){
            formValuesTemp = { ...formValuesTemp, ...{ department_email : value } };
        }else if ( fieldName==='department_password'){
            formValuesTemp = { ...formValuesTemp, ...{ department_password : value } };

        }else if ( fieldName==='department_password_confirm'){
            formValuesTemp = { ...formValuesTemp, ...{ department_password_confirm : value } };
        }else if ( fieldName==='department_members'){
            formValuesTemp = { ...formValuesTemp, ...{ department_members : value } };
        }else if ( fieldName==='default_message'){
            formValuesTemp = { ...formValuesTemp, ...{ default_message : value } };
        }else if ( fieldName==='default_msg_status'){
            value = parseInt(formValuesTemp.default_msg_status)===1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ default_msg_status : value } };
        }else if ( fieldName==='contact_number'){

            let contact_number = value.replace(' ','');
            setShowMobileMsg('');
            if ( ! Utilities.isEmpty(contact_number) && ! Utilities.contactNumberValidate( contact_number, 1 ).status ){
                setShowMobileMsg( Utilities.contactNumberValidate( contact_number, 1 ).msg);
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }

            formValuesTemp = { ...formValuesTemp, ...{ contact_number : value } };
        }

        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);
    }
    
    const changeCountryCodeLandline = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagLandline(defaultFlagTemp);
        setDefaultCountryCodeLandline(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ country_code : passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '' ) => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const handleFormType = ( detail ) => {
        const type = parseInt(detail.value);

        if ( type===0 ){
            setupFormType(detail);
        }else{
            setFormStatusHold(detail);
            setShowFormPopUp(true);
        }
    }

    const setupFormType = ( detail = {} ) => {

        let formValuesTemp = formValues;
        let formStatusHoldTemp = formStatusHold;

        if ( Object.keys(detail).length>0 ){
            formStatusHoldTemp = detail;
        }

        formValuesTemp = { ...formValuesTemp, ...{ form_status : parseInt(formStatusHoldTemp.value), form_status_name : formStatusHoldTemp.label } };
        setFormValues(formValuesTemp);
        setShowFormPopUp(false);
    }

    const handeResetFormType = () => {

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ form_status : 0, form_status_name : '' } };
        setFormValues(formValuesTemp);
    }

    const toggleActiveClass = (e, member )  => {
        let memberIDsTemp = memberIDs;
        let id = member.account_id.toString();
        
        ! ( memberIDsTemp.includes(id) ) ? memberIDsTemp.push(id) : ( memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)] ) ;
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        if ( parseInt(memberIDsTemp.length)===0 ){
            memberIDsTemp.push(teamAdmin.account_id.toString());
        }

        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
        setMemberIDs(memberIDsTemp);
    }

    const extractName = () => {

        let memberNamesTemp = '';
        if (memberIDs.length>0){
            let memberNamesArry = [];
            Object.entries(listProfiles).map( ([key, row] ) => {
                if ( memberIDs.includes(row.id.toString()) ){
                    memberNamesArry.push(row.name);
                }
            });

            memberNamesTemp = memberNamesArry.join(', ');
            memberNamesTemp = ( memberNamesTemp.length>30) ? memberNamesTemp.substring(0, 30)+'...' : memberNamesTemp;
        }
        
        if (memberIDs.length>2){
            memberNamesTemp = memberNamesTemp + ' +'+( parseInt(memberIDs.length)-2 );
        }
        
        setMemberNames(memberNamesTemp);

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ department_members : memberNamesTemp } };
        jQuery('[name="department_members"]').closest('.form-group').removeClass('error');

        setFormValues(formValuesTemp);
        setShowTeamList(false);
    }   

    const submitDepartment = ( action = '' ) => {

        if ( ! Utilities.isEmpty(formValues.contact_number) ){
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileActivationDepartmentWarningMessage ,
                overlayClassName : 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () =>  jQuery('[name="contact_number"]').focus() },
                    { label: 'continue',  onClick: () => submitDepartmentProper()  }
                ],
                onClickOutside: () => jQuery('[name="contact_number"]').focus(),
                onKeypressEscape: () => jQuery('[name="contact_number"]').focus(),
            }); 
            
        }else{
            submitDepartmentProper()
        }

    }

    const submitDepartmentProper = ( action = '' ) => {

        const button_name = 'btn-submit-department';
        Utilities.elementDisabledStatus(button_name, false );
      
        const formData = new FormData();
        formData.append('company_id', companyProfile.account_id);

        let errorCounter = 0, responseChecker = false;
        const fieldsChecker = ['department_id', 'department_name','department_email'];
       
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( fieldsChecker.includes(key) ){
                if ( Utilities.isEmpty(value )){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }else{

                    if (key==='department_email'){
                        if ( Utilities.checkSpecialChar(formValues.department_email)){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }else{
                            jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }
                    }else if ( ['department_name'].includes(key) ){
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if ( responseChecker===false ){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }else{
                            jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }

                    }else{
                        jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                        formData.append(key, value);
                    }
                }
            }else{
                formData.append(key, value);
            }
        });

        if ( !Utilities.isEmpty(formValues.department_password) ){

            /* if ( formValues.department_password.length<=5){
                Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                return false;
            } */
            const passwordValidation = Utilities.passwordValidation(formValues.department_password);
            if ( passwordValidation.response===false ){
                jQuery('[name="department_password"]').closest('.form-group').addClass('error');
                return false;
            }

            if ( formValues.department_password!==formValues.department_password_confirm ){
                Utilities.messagePopup('Error',  Utilities.labelPasswordConfirmNotSame )
                return false;
            }

            formData.append('department_password', formValues.department_password);
        }

        if ( ! Utilities.isEmpty( formValues.contact_number ) && ! Utilities.contactNumberValidate( formValues.contact_number ) ){
            jQuery('[name="contact_number"]').closest('.form-group').addClass('error');
            errorCounter++;
        }
        

        if ( errorCounter>0 ){  return false; }

        
        formData.append('country_code', formValues.country_code);
        formData.append('contact_number', formValues.contact_number);

        formData.append('domain_name', domainName);
        formData.append('member_ids', memberIDs.join(','));
        formData.append('form_status', formValues.form_status);

        const utcTime = Utilities.currentUTCTime();
        const curretTime =  moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());
        formData.append('create_date', curretTime);

        Utilities.elementDisabledStatus(button_name, true );

        axios
        .post( ApiUrls.departmentAddUpdate , formData )
        .then( response => {
           
            Utilities.elementDisabledStatus(button_name, false );

            if ( response.status===200 ){
                const dataResponse = response.data;

                if ( parseInt(dataResponse.status)===1 ){

                     dispatch( reloadProfile(Date.now()) );
                     dispatch( reloadAccountsDB(2) );

                    confirmAlert({
                        title: 'Success',
                        message: `Successully ${formAction==='add' ? 'created' : 'updated'}.`,
                        overlayClassName : 'success',
                        buttons: [
                            { label: 'Close',  onClick: () => {  history.push(`/${UrlSlugs.settings_manageDepartments}/${btoa(profileDetail.account_id)}`); } }
                        ]
                    }); 
                   
                }else{
                    Utilities.messagePopup('error', errorMsg );
                }
                
            }

        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg );
            Utilities.elementDisabledStatus(button_name, false );
        }); 

    }

    const resetDepartment = () => {
        setFormValues(defaultFormValues);

        let memberIDsTemp = [];
        memberIDsTemp.push(teamAdmin.account_id.toString());
        setMemberIDs(memberIDsTemp);
        setMemberNames(teamAdmin.name);

        jQuery('#listing-members > li').removeClass('selected');
    }

    const getMembers = ( companyID ) => {

        if ( parseInt(companyID)>0 ){
            axios
            .post( ApiUrls.companyTeamList , { account_id : companyID } )
            .then( response => {
            
                if ( response.status===200 ){
                    const dataResponse = response.data;
    
                    if ( parseInt(dataResponse.status)===1 ){
                        let memberList = [];

                        Object.entries(dataResponse.list).map( ( [id, memberdetail] ) => {
                            memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];
                           
                            if ( memberdetail.hasOwnProperty('team_is_admin') ){
                                if ( parseInt(memberdetail.team_is_admin)===1){
                                    setTeamAdmin(Utilities.formatProfileDetail(memberdetail));

                                    const pathname = window.location.pathname; 
                                    let segment = pathname.split('/');
                                    segment = segment.filter(Boolean);
                            
                                    const actionName = segment[2].toString().toLocaleLowerCase();

                                    if ( actionName==='add'){
                                        let memberIDsTemp = memberIDs;
                                        memberIDsTemp.push(memberdetail.account_id.toString());

                                        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
                                        setMemberIDs(memberIDsTemp);
                                        setMemberNames(memberdetail.name);
                                        
                                    }
                                } 
                            }
                            
                        });

                        setListProfiles(memberList);
                    }
                }
            })
            .catch( (err) => {
                setListProfiles([]);
            }); 
        }

    }


    const confirmDeletion = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wantDeleteDepartment ,
            buttons: [
                { label: 'No',  onClick: () => {} },
                { label: 'Yes',  onClick: () => { deleteDepartment() } }
            ]
        }); 
    }

    const deleteDepartment = () => {
        const id = formValues.department_id;
        if ( parseInt(id)>0 ){

            dispatch( showLoader(1) );

            axios
            .post( ApiUrls.departmentDelete , { id : id } )
            .then( response => {
                dispatch( showLoader(0) );

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    if ( parseInt(dataResponse.status)===1 ){

                        dispatch( reloadProfile(Date.now()) );
                        dispatch( reloadAccountsDB(2) );

                        history.push(`/${UrlSlugs.settings_manageDepartments}/${btoa(profileDetail.account_id)}`);

                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                }
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                dispatch( showLoader(0) );
            }); 
        }
    }

    const getDepartmentDetail = ( id ) => {
        if ( parseInt(id)>0 ){
            axios
            .post( ApiUrls.departmentGetDetail , { id : id } )
            .then( response => {
            
                if ( response.status===200 ){
                    const dataResponse = response.data;
                    if ( parseInt(dataResponse.status)===1 ){

                        const dataDetail = dataResponse.detail;
                        const dataMember = dataResponse.member_detail;
                       
                        let department_members ='';
                        department_members = ( dataMember.member_names.length>30) ? dataMember.member_names.substring(0, 30)+'...' : dataMember.member_names;

                        if ( dataMember.list.length>2 ){
                            department_members = department_members +' +'+ ( parseInt(dataMember.list.length) - 2 );
                        }

                        let form_status = dataDetail.hasOwnProperty('form_status') ? ( dataDetail.form_status || 0 ) : 0;
                        let form_status_name = '';

                        Object.entries(formStatusOption).map( ([key, row] ) => {
                            if ( parseInt(row.value)===parseInt(form_status) ){
                                form_status_name = row.label;
                            }
                        });
                        
                        const country_code = ( dataDetail.hasOwnProperty('country_code') && parseInt(dataDetail.country_code || 0)>1 ) ? parseInt(dataDetail.country_code || 0) : parseInt(defaultLocationUtil.country_id || 0);

                        const defaultFormValuesTemp = {
                            ...defaultFormValues,
                            department_id : dataDetail.group_id,
                            department_name : dataDetail.department_name,
                            department_email : dataDetail.department_email.split('@')[0],
                            department_password : '',
                            department_password_confirm : '',
                            department_members : department_members,
                            default_message : dataDetail.default_message,
                            default_msg_status : parseInt(dataDetail.default_msg_status || 0),
                            form_status : parseInt(form_status),
                            form_status_name : form_status_name,
                            type : dataDetail.type,
                            country_code : country_code,
                            contact_number : dataDetail.contact_number,
                        };

                        setFormValues(defaultFormValuesTemp);
                        setFormValuesOld(defaultFormValuesTemp);

                        setMemberNames(department_members);
                        setMemberNamesOld(department_members);

                        setMemberIDs(dataMember.ids.split(','));
                        setMemberIDsOld(dataMember.ids.split(','));


                        getCountries({ country_code : country_code });

                        if ( ! Utilities.isEmpty(dataDetail.default_message) ){
                            setShowMessageOption(true);
                        }else{
                            setShowMessageOption(false);
                        }
                        
                    }
                }
            })
            .catch( (err) => {
                console.log(err);
            }); 
        }
    }  
    
 
    const setupCurrentDetail = ( reqAccountID ) => {
        reqAccountID = parseInt(reqAccountID);

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){
                Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                    
                    const account_id = parseInt(acctdetail.account_id);
                    if (account_id===reqAccountID){

                        let accountProfile = Utilities.formatProfileDetail(acctdetail);
                        setProfileDetail(accountProfile);

                        let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                        setCompanyProfile(profileCompanyTemp);
                        companyProfileGlobal = profileCompanyTemp;

                        getMembers(profileCompanyTemp.account_id);
                        
                        const companyDomainRequest = Utilities.isEmpty(profileCompanyTemp.company_display_name) ? profileCompanyTemp.company_name : profileCompanyTemp.company_display_name;
                        let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                        domainNameTemp = domainNameTemp.toString().toLowerCase();

                        setDomainName(domainNameTemp);

                    }
                });
                
            }

        }
        
    }   

    const toggleDefaultMessage = () => {
        let showMessageOptionTemp = (showMessageOption===true) ? false : true;

        if ( showMessageOption===false ){
           let timer = setTimeout( () => {
               jQuery('textarea#default_message').focus();

               if ( formAction==='edit' && showButtonOption===false){
                    setShowButtonOption(true);
               }else if ( formAction==='add' ){
                    setShowButtonOption(true);

                    let formValuesTemp = formValues;
                    formValuesTemp = { ...formValuesTemp, ...{ default_message : '', default_msg_status : 1 } };
                    setFormValues(formValuesTemp);
                    
               }
               
               clearTimeout(timer);
           }, 50);

        }else{
            setShowButtonOption(false);
        }
        
        setShowMessageOption(showMessageOptionTemp);

    }

    const clearDefaultMessage = () => {

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ default_message : '', default_msg_status : 0 } };
        setFormValues(formValuesTemp);

        setShowMessageOption(false);
        setShowButtonOption(false);
    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }
    
    const handleOnclickEditOption = ( action = true ) => {
        setShowButtonOption(action);
        setAllowFormContent(action);
        setShowMessageOption(action);
    
    
        setFormValues({...formValues, ...formValuesOld, ...{ department_password : '', department_password_confirm : '' } });
        jQuery('[name="department_password"], [name="department_password_confirm"], [name="contact_number"]').closest('.form-group').removeClass('error');

        if ( action===false ){
            setMemberNames(memberNameOlds);
            setMemberIDs(memberIDsOld);

            setShowMobileMsg('');
        }
    }
    
    // COUTNRIES
    const getCountries = ( setupContact = {} ) => {

        axios
        .get( ApiUrls.country , {} )
        .then( response => {
            if ( response.status===200 ){
                let optionList = [];
                const countriesList = response.data;
                
                let country_names = {};
                Object.entries(countriesList).map( ([key, row] ) => {
                    optionList = [...optionList , { value: row.id , label : row.country_name} ];

                    country_names[row.id] = row.country_name;

                    if ( Object.keys(setupContact).length>0 ){

                        if ( parseInt(setupContact.country_code || 0)===parseInt(row.id) ) {
                            setDefaultFlagLandline(row.country_short_code.toLowerCase());
                            setDefaultCountryCodeLandline(row.phonecode);
                        }
                    }else{
                        if ( parseInt(companyProfileGlobal.company_landline_code || 0)===parseInt(row.id) ) {
                            setDefaultFlagLandline(row.country_short_code.toLowerCase());
                            setDefaultCountryCodeLandline(row.phonecode);
                        }
                    }

                });

                //setCountryList(optionList);
                setCountries(countriesList);
                //setCountryNames(country_names);
            }
        })
        .catch( (err) => {
           
        });
    }

    const handleClickUsernameInfo = () => {
        Utilities.messagePopup('', TitlesLabels.alertMessages.useThisToLogin );
    }

    const focusDropdown = ( input_id) => {
        
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( !Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    useEffect( () => {

        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.departments }`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        const pathname = window.location.pathname; 
        let segment = pathname.split('/');
        segment = segment.filter(Boolean);

        const actionName = segment[2].toString().toLocaleLowerCase();

        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' && actionName==='edit'){
            reqID = atob(reqID);
            if ( parseInt(reqID)>0 ){
                setFormAction('edit');
                getDepartmentDetail(reqID);
            }
            
        }else{
            setAllowFormContent(true);
        }

        let reqAccountID = match.params.mid;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if ( reqAccountID>0 ){
                setupCurrentDetail( reqAccountID );
            }
        } 

        getCountries();
        
    }, []);


    return (
        <div className="departments-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div>{ companyProfile.hasOwnProperty('name') ? companyProfile.name : '' }</div>
                            <Link to={`/${ UrlSlugs.settings_manageDepartments }/${ btoa(profileDetail.account_id) }`}>{ TitlesLabels.navigations.manageDepartments }</Link>
                            <div className="current">{ formAction } { TitlesLabels.navigations.departments }</div>
                         </li>
                         <li className="btn-option">
                             <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            { ( formAction==='edit' && parseInt(formValues.type)!==0 ) && <div className="btn-icon btn-trash" onClick={ () => confirmDeletion() }></div> }
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div>

                         </li>
                     </ul>
                </div>


            </div>

            <div className="main-content">
                <div className="container">

                    <div className={`member-list-wrap ${ !showTeamList && 'd-none' }`}>
                        
                        <div className={` ${ Object.keys(listProfiles).length===0 && 'd-none' } `}>

                            <ul id="listing-members" className="listing-profiles listing-members mt-4 text-lowercase">
                            {
                                Object.entries(listProfiles).map( ([key, row] ) => 
                                    <li key={key} className={ `${ ( ( memberIDs.includes(row.id.toString()) ) ? 'selected' : '' ) }` } onClick={ (e) => toggleActiveClass(e, row ) }>
                                        <div className="profile-wrap">
                                            <div className="profile-image-wrap">
                                                <div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div>
                                            </div>
                                            <div className="profile-name ellipsis-1">{row.name}</div>
                                            <div className="profile-position ellipsis-1">{row.team_position}</div>
                                        </div>
                                    </li>
                                )
                            }
                            </ul>

                            <div className="col-9 mx-auto  pt-5 mt-5">
                                <div className="button-group-boolean">
                                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => setShowTeamList(false) }>Cancel</button>
                                    <button type="button" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => extractName() }>Save</button>
                                </div>
                            </div>

                        </div>

                        <div className={` ${ Object.keys(listProfiles).length>0 && 'd-none' } `}>
                            <div className="text-center text-lowercase no-record-label ">{ Utilities.labelMemberAvailable }</div>

                            <div className="col-9 mx-auto  pt-5 mt-5">
                                <button type="button" className="btn-theme-black text-lowercase" onClick={ () => setShowTeamList(false) }>Cancel</button>
                            </div>
                        </div>
                        

                    </div>

                    <div className={`member-list-wrap ${ showTeamList && 'd-none' }`} >

                        <form id="form-department-info" className="pt-1 text-lowercase form-theme" autoComplete="off">
                            <input type="hidden" name="department_id" value={ formValues.department_id }  onChange={ (e) => pushValue( e, 'department_id') } autoComplete="off" ></input>

                            <div className={`col-5 mx-auto ${ allowFormContent===false ? 'content-disabled' : '' }`}>
                                <div className="upload-avatar avatar-wrap">
                                    <div>
                                        <div className={`avatar-preview rounded-circle mx-auto border fs-35-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(companyProfile.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg( companyProfile.logo ) }}>{ Utilities.showHideInitial(companyProfile.logo, companyProfile.company_name) }</div>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <FieldInput inputName="department_name" inputLabel="Department Name" inputClass={ parseInt(formValues.type)===0 ? 'cursor-notallow' : '' } inputValue={ formValues.department_name } pushValue={pushValue} checkerType="general"/>
                                </div>

                               {/*  <div className="form-group mt-5">
                                    <input type="text" name="department_name" className={`form-control ${ parseInt(formValues.type)===0 ? 'cursor-notallow' : '' }`} placeholder=" " value={ formValues.department_name }  onChange={ (e) => pushValue( e, 'department_name') } autoComplete="off" readOnly={ parseInt(formValues.type)===0 ? true : false }></input>
                                    <label className="form-label">Department Name</label>
                                </div> */}

                                <div className="form-group optional">
                                    <input type="text" name="department_email" className="form-control text-lowercase cursor-notallow" readOnly placeholder=" " value={ formValues.department_email }  onChange={ (e) => pushValue( e, 'department_email') } autoComplete="new-password" ></input>
                                    <label className="form-label">Department Email</label>
                                    <div className="optional-label d-flex">@{ domainName } <span className="input-label-icons cursor-pointer info-bolder-icon" onClick={ () => handleClickUsernameInfo() }></span></div>
                                </div>
                                

                                <PasswordInput inputName="department_password" inputLabel="Password"  inputValue={ formValues.department_password } pushValue={pushValue} showChecker={true}/>

                                <PasswordInput inputName="department_password_confirm" inputLabel="Confirm Password"  inputValue={ formValues.department_password_confirm } pushValue={pushValue}/>


                                <div className="form-group optional" onClick={ () => setShowTeamList(true) }>
                                    <input type="text" name="department_members" className="form-control text-lowercase" placeholder=" " value={memberNames}  onChange={ (e) => pushValue( e, 'department_members') }  autoComplete="off" readOnly ></input>
                                    <label className="form-label">Assign To</label>
                                    <div className="optional-label cursor-pointer">Add/Delete</div>
                                </div>

                                <div className={`form-group from-group-input ${ !Utilities.isEmpty(formValues.form_status_name) ? 'optional active' : ''}`}>

                                    <Dropdown className="dropdown-toggle-clean dropdown-with-check">
                                        <Dropdown.Toggle variant="dropdown-icon" className="w-100 text-left">{formValues.form_status_name}</Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-clean-option w-100">
                                        {
                                            Object.entries(formStatusOption).map( ([key, row ]) =>
                                                <Dropdown.Item key={key} onClick={ () => handleFormType(row) } className={ parseInt(row.value)===parseInt(formValues.form_status) ? 'selected' : '' }>{row.label}</Dropdown.Item>
                                            )
                                        }
                                        </Dropdown.Menu>

                                    </Dropdown>
                              
                                    <label className="form-label">System Form</label>
                                    { !Utilities.isEmpty(formValues.form_status_name) && <div className="optional-label cursor-pointer" onClick={ () => handeResetFormType() }>remove</div> }
                                </div>

                                
                                <div className="form-group active optional">
                                    <div className="form-group-mobile pr-4">
                                        <Dropdown className="dropdown-country-selection hide-arrow">
                                            <Dropdown.Toggle>
                                                <div className="flag-code" onClick={ () => focusDropdown('landline-input') }>
                                                    <span className={`flag-icon flag-icon-${defaultFlagLandline} mr-2`}></span>
                                                    <span>{ !Utilities.isEmpty(defaultCountryCodeLandline) ? '+'+defaultCountryCodeLandline: ''}</span>
                                                </div>
                                            </Dropdown.Toggle>
                                            
                                            <Dropdown.Menu >
                                            <div className="px-4 mb-1">
                                                <input type="text" id="landline-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'landline-input-list', 'search-keyword') }></input>
                                            </div>
                                            <div id="landline-input-list" className="country-code-list">
                                            {
                                                Object.entries(countries).map( ([key, row] ) =>
                                                    <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeLandline(row) } >
                                                        <div className="flag-code">
                                                            <span className={`flag-icon flag-icon-${ (  ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                            <span>{ row.country_name }</span>
                                                            <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            }
                                            </div> 
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    
                                        <input type="number" name="contact_number" className="form-control" placeholder=" " autoComplete="off"  value={ formValues.contact_number }  onChange={ (e) => pushValue( e, 'contact_number') } ></input>
                                    </div>
                                    <label className="form-label">Direct department number</label>
                                    <div className="optional-label position-placeholder mr-5" style={{ left: 150 }}>(Optional)</div>

                                </div>

                                {  ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(formValues.contact_number) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }
                                
                                <div>
                                    <div className="create-default-message pb-2">
                                        <div className="btn-create-message cursor-pointer" onClick={ () => toggleDefaultMessage() }>create default message</div>

                                        { showMessageOption && <input type="checkbox" className="checkbox-onoff"  value={ formValues.default_msg_status }  checked={formValues.default_msg_status===1 }   onChange={ (e) => pushValue(e, 'default_msg_status') } ></input> }
                                        
                                    </div>

                                    <div className="form-group">
                                        { showMessageOption && <textarea id="default_message" name="default_message" className="form-control text-tranform-initial" rows="6" placeholder=" " value={ formValues.default_message } onChange={ (e) => pushValue(e, 'default_message') }></textarea> }
                                    </div>

                                    { ( showButtonOption && showMessageOption ) &&  <div className="text-right mt-n2"><span className="btn-toggle-message" onClick={ () => clearDefaultMessage() }>clear</span></div> }

                                </div>

                            </div>

                            <div className="col-6 mx-auto  pt-5 mb-5">
                                {
                                    formAction==='add'?
                                        <div className="button-group-boolean mt-3">
                                            <button type="button" className="btn-theme-black text-lowercase" onClick={ () => resetDepartment() }>Reset</button>
                                            <button type="button" id="btn-submit-department" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => submitDepartment('add') }>Add</button>
                                        </div>
                                    :
                                        <div className="mt-3">
                                            
                                            {
                                                showButtonOption===false ?
                                                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => handleOnclickEditOption(true) }>edit</button>
                                                :
                                                    <div className="button-group-boolean">
                                                        <button type="button" className="btn-theme-black text-lowercase" onClick={ () => handleOnclickEditOption(false) } >Cancel</button>
                                                        <button type="button" id="btn-submit-department" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => submitDepartment('edit') }>Update</button>
                                                    </div>
                                            }
                                            
                                        </div>
                                }
                            
                            </div>
                            
                        </form>
                    </div>


                </div>
            </div>

            <DepartmentFormSamples handleCloseFormPopUp={handleCloseFormPopUp} showFormPopUp={showFormPopUp} formTypeDetail={formStatusHold} setupFormType={setupFormType} />

       </div>
    )
}

export default FormDepartment;