import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
//import moment from 'moment';

import { showMainSearch, showHeader, showFooter, pageTitle, passImageUrl, passChatProfile, newCityIDRequest, reloadAccountsDB, passConversList, refershDashboadConversation as refershDashboadConversationReducer, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer , showPopupTermsConditions, showLoader} from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import ProfileDetail from './../components/popups/ProfileDetail';
import WelcomeMessage from './../components/popups/WelcomeMessage';

import BreadcrumbProfile from './../layout/BreadcrumbProfile';
import BreadcrumbInternal from './../layout/BreadcrumbInternal';
import QueryChatUtilities from '../helpers/QueryChatUtilities';
import SessionUtilities from '../helpers/SessionUtilities';
import QueryUtilities from '../helpers/QueryUtilities';
import MainSearchProfile from '../components/popups/MainSearchProfile';


const Dashboard = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const TOKEN = process.env.REACT_APP_chat_api_token;
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    
    const refershDashboadConversation = useSelector( state => state.refershDashboadConversation);
    const switchAccount = useSelector( state => state.switchProfile);
    
    const [ paginationLoader, setPaginationLoader ] = useState(false);
    const [ paginationNumber, setPaginationNumber ] = useState(0);
    const [ globalPassvalConversation, setGlobalPassvalConversation ] = useState({});

    const [ showBizcomLoader, setShowBizcomLoader ] = useState(false);
    const [ requestJazenetProfileID, setRequestJazenetProfileID ] = useState(0);

    const [ showMoreOption, setShowMoreOption ] = useState(false);
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ profileSort, setProfileSort ] = useState(false);
    const [ loggedInProfile, setLoggedInProfile ] = useState({});
    const [ showSwitchInternalButton, setShowSwitchInternalButton ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ allowNewChat, setAllowNewChat ] = useState(true);
    const [ rowProfileSelected, setRowProfileSelected ] = useState({});

    const [ profileSelectedDirectory, setProfileSelectedDirectorySearch ] = useState({});
    const [ showProfileSelectedDirectory, setShowProfileSelectedDirectory ] = useState(false);

    const [ allConversationResults, setAllConversationResults ] = useState({});
    
    const [ popupWelcomeMessage,setPopupWelcomeMessage] = useState(false);

    const handleCloseWelcomeMessage = () => setPopupWelcomeMessage(false);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const handleCloseProfileDirectory = () => {
        setProfileSelectedDirectorySearch({});
        setShowProfileSelectedDirectory(false);
    }


    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const sortUnsortProfiles = () => {
        let tempProfileSort = profileSort;
        let tempListProfiles = listProfiles;

        if ( profileSort===false ){
            tempProfileSort = true;

            tempListProfiles.sort(Utilities.fieldSorter(['name']) );
            //tempListProfiles.reverse();
            setListProfiles(tempListProfiles);

        }else{
            tempProfileSort = false;
            tempListProfiles.reverse();
            setListProfiles(tempListProfiles);
        }

        setProfileSort(tempProfileSort);
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }


    const toggleMainSearch = ( action = 0 ) => {
        dispatch( showMainSearch(action) );
    }

    const showChat = async ( profileChat = {}  ) => {
   
        setRowProfileSelected(profileChat);

        let flagId = profileChat.hasOwnProperty('flag') ? profileChat.flag : 0;
        let profileChatConversation = {}, passValues = "", passval = {}, conversationDetailResponse = {};
        flagId = parseInt(flagId);

       if (flagId===6){
            passval = {
                api_token : TOKEN.toString(),
                account_id : loggedInProfile.account_id.toString(),
                group_id : profileChat.id.toString(),
                conversa_id : profileChat.convers_id.toString(),
            }

            conversationDetailResponse = await QueryChatUtilities.getBeonchatGroupsConversationDetail(passval);

       }else{
            passval = {
                api_token : TOKEN.toString(),
                account_id : loggedInProfile.account_id.toString(),
                account_type : loggedInProfile.account_type.toString(),
                conversa_id : profileChat.convers_id.toString(),
            }

            conversationDetailResponse = await QueryChatUtilities.getBeonchatConversationDetail(passval);
       }
       
      

       if ( Object.keys(conversationDetailResponse).length===0 ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
            return false;
        }

        if ( parseInt(conversationDetailResponse.success)===0){
            Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
            return false;
            
        }
        
        profileChatConversation = flagId!==6 ? conversationDetailResponse.result.beonchat_conversation : conversationDetailResponse.result.company_broadcast_conversation_details;

        passValues = ChatUtilities.encryptChatDetail(profileChatConversation); 
        ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'dashboard' );

        
       history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileChatConversation.account_id) )}/${passValues}`);
            
    }

    const noConversation = () => {
        setContentLoaderClass('');
        setListProfiles([]);
        setShowEmpty(true);
    }

    const getRecentConversation = async ( account = {} , refresh = 0 ) => {
        refresh = parseInt(refresh);
   
        const dasboardLoadedStatus = parseInt(SessionUtilities.getDashboadLoadedStatus());

        if ( refresh===0 ){
            setContentLoaderClass('animate');
            //SessionUtilities.setDashboadLoadedStatus(1);

        }

        if ( dasboardLoadedStatus===1 ){
            
            const dashboadLoadedListTemp = SessionUtilities.getDashboadLoadedListTemp();
            if (dashboadLoadedListTemp.length>0 ){
                setTimeout( () => {
                    setContentLoaderClass('');
                    setListProfiles([...dashboadLoadedListTemp]);
                }, 300);

                setShowBizcomLoader(false);
                setShowEmpty(false);
            }
        }
  
        let master_account_id = account.account_id;
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);
            accountsList = Object.values(accountsList);
            master_account_id = accountsList[0].account_id;
        }
        
        let flag = '1';
        let account_department_id = '0';
        if ( account.hasOwnProperty('group_id') ){
            account_department_id = account.group_id.toString();
            flag = '4';
        }

        const passValues = { 
            api_token : TOKEN.toString(),
            account_id : account.account_id.toString(),
            account_type : account.account_type.toString(),
            master_account_id : master_account_id.toString(),
            flag : flag.toString(),
            group_id : account_department_id.toString(),
       }
       //console.log(ApiUrls.bizcomWebHomeDetails, passValues);
       
       setGlobalPassvalConversation(passValues);

        await axios
                .post( ApiUrls.bizcomWebHomeDetails , passValues )
                .then( response => {
                    
                    setContentLoaderClass('');
                    dispatch( refershDashboadConversationReducer('') );

                    if ( parseInt(response.status)===201 ){
                        if ( parseInt(response.data.success)===1 ){
                            
                            const conversationResult = response.data.result;

                            dispatch( passConversList(conversationResult) );
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(conversationResult.menu_setting)
                            );
                                
                            setAllConversationResults(conversationResult);

                            if ( Object.keys(conversationResult.conversation_list ?? {}).length>0 ){

                                let conversationListingTemp = [];
                                Object.entries(conversationResult.conversation_list).map( ([key, row ]) => {
                    
                                    let converDetail = Utilities.formatConversationList(row);

                                    if ( converDetail.hasOwnProperty('department_name') && !Utilities.isEmpty(converDetail.department_name) ){
                                        if ( converDetail.name.toLowerCase()===converDetail.title.toLowerCase() ){
                                            converDetail = { ...converDetail , name : row.name.toLowerCase(), title : row.department_name.toLowerCase()  };
                                        }
                                    }

                                    conversationListingTemp.push(converDetail);
                                });
                                
                                setListProfiles(conversationListingTemp);
                                setShowEmpty(false);

                                SessionUtilities.setDashboadLoadedListTemp(conversationListingTemp);
                                SessionUtilities.setDashboadLoadedStatus(1);

                            }else{
                                noConversation();
                            }
                            
                        }else{
                            noConversation();
                        }
                    }else{
                        noConversation();
                    }

                })
                .catch( (err) => {
                    noConversation();
                    dispatch( refershDashboadConversationReducer('') );
                }); 

    }

    const paginationContacts = async ( elemId = '' ) => {
        const reachedScrollTopBool = Utilities.getElementSrollPosition(elemId);
        if ( reachedScrollTopBool===true && paginationLoader===false ){

            let paginationNumberTemp = paginationNumber + 1;
            const passval = { ...globalPassvalConversation, page : paginationNumberTemp.toString() };

            setPaginationLoader(true);
            const contactListResponse = await QueryChatUtilities.getContactPagination(passval);
            setPaginationLoader(false);

            if ( contactListResponse.length>0 ){

                let conversationListingTemp = [];
                Object.entries(contactListResponse ?? []).map( ([key, row ]) => {
                    conversationListingTemp.push( Utilities.formatConversationList(row) );
                });

                const updatedList = [...listProfiles, ...conversationListingTemp];
                setListProfiles(updatedList);
                setPaginationNumber(paginationNumberTemp);

                SessionUtilities.setDashboadLoadedListTemp(updatedList);

                jQuery(`#${elemId}`).stop().animate({
                    scrollTop : parseInt(document.querySelector(`#${elemId}`).scrollTop)+200

                }, 500, '', function () {  });
            }

        }
    }


    const refresLoggedinProfile = async  () => {

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setLoggedInProfile(profileLoggedinTemp);
                await getRecentConversation(profileLoggedinTemp);

                // RESTRICT NEW CHAT / REMOVE
                if ( profileLoggedinTemp.hasOwnProperty('flag_handle_status') ){
                    if ( parseInt(profileLoggedinTemp.flag_handle_status || 0)===0 ){
                        setAllowNewChat(false);
                    }
                }else{
                    setAllowNewChat(true);
                } 

            }
        
        }


        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);
            const accountsCounter = Object.keys(accountsList).length;
            if ( parseInt(accountsCounter)>1 ){
                setShowSwitchInternalButton(true);
            }
        }

    }


    const blockOrCounter = ( chatDetail ) => {
        let html_return = '';

        if ( chatDetail.hasOwnProperty('contact_settings') && parseInt(chatDetail.contact_settings.mute_status_settings.mute_status || 0)===1 ){
            html_return = <div className="onmute-icon"></div>
        } 

        if ( chatDetail.hasOwnProperty('block_status') ){
            const blockValue = parseInt(chatDetail.block_status.block_in_status || 0) + parseInt(chatDetail.block_status.block_out_status || 0);
            if ( parseInt(blockValue)>0 ){
                html_return = <span className="blocked-icon"></span>
            }
        }

        if ( chatDetail.hasOwnProperty('unread_count') && parseInt(chatDetail.unread_count  || 0)>0 ){
            html_return = <Fragment> {html_return} <div className="unread-counter">{ Utilities.counterMore(chatDetail.unread_count) }</div></Fragment>
        }

        return html_return;
    }

    const previewProfileImage = (logoUrl ) => {
        if ( ! Utilities.isEmpty(logoUrl) ){
             dispatch( passImageUrl(logoUrl) );
        }
    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    const handleClickQuickLinks = () => {
        dispatch( showPopupQuickLinksReducer(true) );
    }


    const getCompanyDetailDirectory = async ( account_id = 0 ) => {
        account_id = parseInt(account_id);

        if (account_id>0 ){

            dispatch( showLoader(1) );
            let responseProfileDetail = await QueryUtilities.getProfileDetail(account_id);
            dispatch( showLoader(0) );
            
            if ( Object.keys(responseProfileDetail).length===0 ){
                return false;
            }
            
            responseProfileDetail = {
                ...responseProfileDetail,
                ...responseProfileDetail.location
            }
       
            setProfileSelectedDirectorySearch(responseProfileDetail);
            setShowProfileSelectedDirectory(true);

            history.push(`/${UrlSlugs.dashboard}`);
            
        }
    }


    useEffect( async () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.dashboard ) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();

        // IF LOCATION CHANGE FROM BIZCOMPLUS
        let cityIDRequest = match.params.cityid;
        if ( typeof cityIDRequest!=='undefined' ){

            const defaultLocation = Utilities.defaultLocation();
            let userCurrentCityId = defaultLocation.city_id || 0;

            let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if ( userLocation!==null ){
                userLocation = JSON.parse(userLocation);
                userCurrentCityId = userLocation.city_id;
            }

            userCurrentCityId = parseInt(userCurrentCityId  || 0);
            cityIDRequest = parseInt(atob(cityIDRequest));

            if ( userCurrentCityId!==cityIDRequest ){
                dispatch( newCityIDRequest(cityIDRequest) );
            }
            
            dispatch( reloadAccountsDB(2) ); // load session accounts from DB // updates is located in header.js
            history.push(`/${UrlSlugs.dashboard}`);
        }


        const sessionWelcomeMessage = localStorage.getItem(process.env.REACT_APP_session_welcome_mesage);
        if ( sessionWelcomeMessage!==null ){
            setPopupWelcomeMessage(true);
            localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);
        }

        dispatch( passChatProfile({}) );

        
        let sessionJazenetRequestProfileID = sessionStorage.getItem(process.env.REACT_APP_session_jazenet_request_profileid);
        if ( sessionJazenetRequestProfileID!==null ){
            
            if ( parseInt(sessionJazenetRequestProfileID)>0 ){
                setRequestJazenetProfileID(sessionJazenetRequestProfileID);
                sessionStorage.removeItem(process.env.REACT_APP_session_jazenet_request_profileid);
            }
        }
        
        window.onpopstate = () =>  {
            const mainPopUp = jQuery('.main-search-component.show').length;
            if ( mainPopUp===1 ){
                toggleMainSearch(0);
                //window.history.forward();
                history.push(`/${UrlSlugs.dashboard}`);
            }

        }

        // CLOSE TERMS POPUP WHEN IN CREATING COMPANY
        dispatch( showPopupTermsConditions(false) );


        // PROFILE DIRECTORY SEACH 
        let paramsDirectConnectAccountId = match.params.accountid??'';
        if ( !Utilities.isEmpty(paramsDirectConnectAccountId) ){
            await getCompanyDetailDirectory( atob(paramsDirectConnectAccountId) );
        }
       


        return () => {
            dispatch( refershDashboadConversationReducer('') );
        }
        
    }, [] );


    useEffect( () => {

        const reqAccountID = match.params.id;
        if ( typeof reqAccountID==='undefined' ){
            setShowBizcomLoader(false);

            setShowEmpty(false);
            refresLoggedinProfile();
            setListProfiles([]);

            SessionUtilities.setDashboadInternalLoadedStatus(0);
            SessionUtilities.setDashboadLoadedInternalListTemp([]);

            //SessionUtilities.setDashboadLoadedStatus(0);
            //SessionUtilities.setDashboadLoadedListTemp([]);
        }
        
    }, [match.params.id] );


    useEffect( () => {

        if ( ! Utilities.isEmpty(switchAccount.toString())  && parseInt(switchAccount)>0  ){
            
            const reqAccountID = match.params.id;
            if ( typeof reqAccountID!=='undefined' ){
                return false;
            }
          
            SessionUtilities.setDashboadLoadedStatus(0);
            SessionUtilities.setDashboadLoadedListTemp([]);

            setShowEmpty(false);
            refresLoggedinProfile();
            setListProfiles([]);
        }

    }, [switchAccount] );


    useEffect( () => {

        const reqAccountID = match.params.id;
        if ( typeof reqAccountID!=='undefined' ){
            return false;
        }

        const isContentHigher = Utilities.checkContentIfHigher('listing-profiles');
        setShowMoreOption(isContentHigher);

    }, [listProfiles]);


    useEffect( async () => {

        if ( ! Utilities.isEmpty(refershDashboadConversation) ){
            let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginProfileInfo!==null ){
                loginProfileInfo = JSON.parse(loginProfileInfo);
                    
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                await getRecentConversation(profileLoggedinTemp, 1);
            }
        }

    }, [refershDashboadConversation] );



    return (

        <div className="dashboad-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li className="btn-option">
                            <div></div>
                        </li>
                        
                        {/* {
                            Utilities.isEmpty(contentLoaderClass)  &&  */}
                                <li className="btn-option">
                                    <div className="btn-icon btn-circle-arrow-right" onClick={ () => handleClickQuickLinks() } title={ TitlesLabels.general.quickLinks }></div>
                                    {  
                                        allowNewChat===true && 
                                        <Fragment>
                                            <BreadcrumbProfile  allConversationResults={ allConversationResults } showLabel={false}  />
                                            { showSwitchInternalButton &&  <BreadcrumbInternal  allConversationResults={ allConversationResults } showLabel={false} />  }
                                            <div className="btn-icon btn-contact"  onClick={ () => toggleMainSearch(3) } title={ TitlesLabels.general.directorySearch }></div>
                                        </Fragment>
                                    }
                                    
                                    <Link to={`/${ UrlSlugs.settings_notifications }`} className="btn-icon btn-bell" title={ TitlesLabels.general.newNotifications }>
                                        { parseInt(allConversationResults.notice_notification_count)>0 && <span className="counter">{ Utilities.counterMore(allConversationResults.notice_notification_count) }</span> }
                                    </Link>
                                    
                                    <div className="btn-icon btn-magnify" onClick={ () => toggleSeachBox() }  title={ TitlesLabels.general.chatSearch }></div>
                                    <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.setting }></div>
                                    
                                </li>
                        {/* } */}
                        
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container">
                        
                        { 
                            //( allowNewChat===true && Utilities.isEmpty(contentLoaderClass) ) &&
                            allowNewChat===true &&
                                <div className="mb-3 d-flex align-items-center">
                                    <div className="btn-new-chat btn-icon-wrap cursor-pointer" onClick={ () => toggleMainSearch(1) }>New Chat</div>
                                    { ! Utilities.isEmpty(refershDashboadConversation) && <div className="ml-3 text-color-gray">loading...</div> }
                                </div>
                        }

                        { 
                            ! Utilities.isEmpty(contentLoaderClass) &&
                            <>
                                {/* <div className="mb-3 global-preloader">
                                   <div className="btn-dummy-new-chat"></div>
                                </div> */}
                                <ul className="dashboard-list-preloader">
                                    {
                                        [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                                            <li key={key}>
                                                <div className="profile-image"></div>
                                                <div></div>
                                                <div></div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </>
                        }


                        {
                            showBizcomLoader===false && 

                            <Fragment>
                                <div className="profile-search-wrap d-none">
                                    <div className="profile-search-content">
                                        <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                        <div className="cursor-pointer opacity-0" onClick={ () => sortUnsortProfiles() }>a-z</div>
                                        <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                        <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                                    </div>
                                </div>

                                { 
                                    showEmpty===false  ?
                                         Object.keys(listProfiles).length>0 &&
                                         <>
                                            <ul id="listing-profiles" className="list-profile-messages" onScroll={ (e) =>  paginationContacts('listing-profiles') }>
                                                {
                                                    Object.entries(listProfiles).map( ([key, row] ) =>
                                                        <li key={key} data-keyword={row.name.toLowerCase()} className={`${ parseInt(row.access_status  || 0)===1 ? 'opacity-05' : ''} ${ parseInt(row.account_id ||0)===parseInt(rowProfileSelected.account_id||-1)?'cursor-wait':''}`}>
                                                            <div><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                                            <div  onClick={ () => showChat(row) }>
                                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                                <div className="profile-label">{row.title}</div>
                                                            </div>
                                                            <div className="time">{row.time}</div>
                                                            <div>{ blockOrCounter(row) }</div>
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                            { paginationLoader && <div className="content-loader list-loader animate"></div> }
                                        </>

                                    :
                                    <div className="text-center text-lowercase no-record-label no-chats-label">{ Utilities.labelNoChatActive }</div> 
                                    
                                }
                                
                                { showMoreOption===true && <div className="scroll-option-wrap"><span className="btn-scroll-down" onClick={ () => Utilities.scrollDownMore('listing-profiles') }></span></div> }
            
                            </Fragment>    
                        }
                        

                </div>
            </div>


            { parseInt(requestJazenetProfileID)>0 && <ProfileDetail requestID={requestJazenetProfileID} />}

            <WelcomeMessage popupWelcomeMessage={popupWelcomeMessage} handleCloseWelcomeMessage={handleCloseWelcomeMessage} profileLoggedin={loggedInProfile} />

            <MainSearchProfile  handleShowProfilePopup={showProfileSelectedDirectory} handleCloseProfilePopup={handleCloseProfileDirectory} profileSelected={profileSelectedDirectory} showStatus={1}  /> 

        </div>
    )
}

export default Dashboard;