import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';

import Utilities from '../../helpers/Utilities';
import TitlesLabels from '../../helpers/TitlesLabels';

const ChatMap = (props) => {

    const [ latitude, setLatitude ] = useState('');
    const [ longitude, setLongitude ] = useState('');

    const mapStyles = {  width :  '100%', height : '100%' }

    const closeMap = () => {
      props.handleCloseChatMap();
      props.submitGEOLocation( latitude ,  longitude );
    }
    
    useEffect( () => {
      
        if ( props.showChatMap===true ){
          navigator.geolocation.getCurrentPosition(function(position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          });
        }
        
    }, [props] );

    return (
      <Modal id="chat-map-popup"  show={ props.showChatMap } onHide={ props.handleCloseChatMap } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="lg" >
              <Modal.Header closeButton>
                  <h5 className="text-lowercase text-center w-100 my-2" >{ TitlesLabels.general.currentLocation }</h5>
              </Modal.Header>
              <Modal.Body className="p-0">
                    
                    <div className="chat-map-wrap">
                      {
                        ( props.showChatMap===true  && !Utilities.isEmpty(latitude) && !Utilities.isEmpty(longitude) ) ?
                          <Map
                              google={props.google}
                              zoom={14}
                              style={mapStyles}
                              initialCenter={{
                                lat: latitude,
                                lng: longitude
                              }}
                            > 

                            <Marker name={'Current location'} />
                          
                            </Map>
                        :
                          <div className="content-loader animate"></div>
                      }
                        
                    </div>

              </Modal.Body>

              <Modal.Footer>

                <div className="w-100">
                    <div  onClick={ () => closeMap() } className="btn-theme-black col-6 mx-auto text-lowercase " >Submit</div>
                </div>
               
              </Modal.Footer>
            
          </Modal>
    )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_google_map_key
})(ChatMap);
