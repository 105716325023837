import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { reloadAccountsDB } from '../../actions';

import UrlSlugs from '../../helpers/UrlSlugs';

const NotificationDivert = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [ showPopup, setShowPopup ] = useState(false);
    const [ notiItems , setNotiItems ] = useState([]);

    const handleClosePopUp = () => {
        setShowPopup(false);
        setNotiItems([]);

        localStorage.removeItem(process.env.REACT_APP_session_divert_notification);
        
        dispatch( reloadAccountsDB(2) );
        history.push(`/${UrlSlugs.dashboard}`);
    }

    useEffect( () => {

        if ( Object.entries(props.notificationItem ?? {}).length>0 ){
            setNotiItems(props.notificationItem);
            setShowPopup(true);
        }

        return () => {
            setShowPopup(false);
            setNotiItems([]);
        }

    }, [props.notificationItem]);


    return (

        Object.entries(notiItems).length>0 &&
            <Modal id="popup-notification-divert"  show={ showPopup } onHide={ handleClosePopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md" >
                <Modal.Header  className="border-bottom-0">
                    <h2 className="font-gotham-light-22 text-center w-100 pt-3 mb-0">divert alert</h2>
                </Modal.Header>
                <Modal.Body className="px-5">
                        
                        <div className="font-gotham-light-14 text-lowercase text-center pb-4">

                            <div className="logo-size-65 mx-auto" style={{ backgroundImage: `url(${notiItems.data.sender_logo})`}}></div>
                            <div className="mt-2">
                                <div className="font-gotham-book-16 notranslate ellipsis-1">{notiItems.data.sender_name}</div>
                                <div className="ellipsis-1">{notiItems.data.sender_account_title}</div>
                            </div>

                            <div className="mt-4 mb-2">
                                <div>{`has ${ parseInt(notiItems.data.notify_flag || 0)===13 ? 'diverted' : 'removed'} his/her work page`}</div>
                                <div>conversation to your profile</div>
                            </div>

                        </div>

                </Modal.Body>
            </Modal>
    );
}

export default NotificationDivert;
