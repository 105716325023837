import React, { useEffect, useState, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; 
import axios from 'axios';
import * as RM from 'ramda';

import Utilities from './../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from './../../helpers/PagesAuthentication';

import { showLanguageSelectionPopup, changeCityPopup as changeCityPopupAction, toggleAutomatedReply, showMainSettings as showMainSettingsReducer, toggleDepartmentDefaultMessage as toggleDepartmentDefaultMessageReducer, toggleDepartmentForms as toggleDepartmentFormsReducer, passConversList as passConversListReducer, showLoader as showLoaderReducer, toggleMenuPriceList } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';
import QueryUtilities from '../../helpers/QueryUtilities';
import { getReceiverAccountAndDeptReceiverIds } from '../../helpers/QueryDiary';


const SettingProfilePopup = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const siteTitle = process.env.REACT_APP_site_title; 

    const reloadProfile = useSelector( state => state.reloadProfile);
    const passConversList = useSelector( state => state.passConversList);
    const showMainSettings = useSelector( state => state.showMainSettings);

    const counterDefault = { document : 0, special : 0, appointment : 0 };

    const [ listAccounts, setListAccounts ] = useState([]);
    const [ counters, setCounters ] = useState(counterDefault);

    const [ activeSlide, setActiveSlide ] = useState(0);
    const [ sliderCounterArr, setSliderCounterArr ] = useState([]);

    const [ loggedinAccount, setLoggedinAccount ] = useState({});
    const [ allowShareQRCode, setAllowShareQRCode ] = useState(false); 

    const [ mainSettingsStatus , setMainSettingsStatus ] = useState(false);
    const [ divertReplyInfo, setDivertReplyInfo ] = useState({});

    const [ hasAssignee, setHasAssignee ] = useState([]);

    const [ slideProfiles, setSlideProfiles ] = useState(false);
    const [ slideLinkedProfiles, setSlideLinkedProfiles ] = useState(false);
    
    const [ showPopup, setShowPopup ] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
        setMainSettingsStatus(false);
        dispatch( showMainSettingsReducer('') );
    }

    const sliderNext = () => {
        let num = activeSlide+1;
        if ( num>0 && sliderCounterArr.includes(num) ){
            setActiveSlide(num);
        }
    }

    const sliderPrev = () => {
        let num = activeSlide-1;
        if ( num>=0 && sliderCounterArr.includes(num) ){
            setActiveSlide(num);
        }
    }
    
    const menuProductButtonHtml = ( profile = {} ) => {
        let htmlReturn = <></>
        const team_is_admin = parseInt(profile.team_is_admin ?? 0);
        const team_menu_permission = parseInt(profile.team_menu_permission ?? 0);
        
        if ([1].includes(team_is_admin) || [1].includes(team_menu_permission) ){
            htmlReturn = <li  onClick={ () => { handleClosePopup(); dispatch( toggleMenuPriceList( profile ) );  }}>
                            <div className={`menu-icon menuprice-icon`}></div>
                            <div>menu / pricelist<br />management</div>
                        </li>
        }

        return htmlReturn;
    }

    const notificationManagementHtml = ( profile = {} ) => {
        let htmlReturn = <></>
        const team_is_admin = parseInt(profile.team_is_admin ?? 0);
        const team_notification_permission = parseInt(profile.team_notification_permission ?? 0);

        if ([1].includes(team_is_admin) || [1].includes(team_notification_permission)){
            htmlReturn = <li onClick={ () => handleOnClickSettingNavigations('notification-management') }>
                            <div className={`menu-icon bell-icon`}></div>
                            <div>notification<br />management</div>
                        </li>
        }
        return htmlReturn
    }

    
    const userMenu = ( profile ) => {
        const account_id = parseInt(profile.account_id || 0);
        const account_id_encoded = btoa(account_id);
        const account_type = parseInt(profile.account_type || 0);

        let menuHtml = '';
        let team_is_admin = 0;

        if ( profile.hasOwnProperty('team_is_admin') ){
            team_is_admin = parseInt(profile.team_is_admin || 0);
        }

        if ( account_type===1 ){
            menuHtml =  <Fragment>
                            <Link to={`/${ UrlSlugs.settings_manageProfile }`} onClick={ () => handleClosePopup() }>manage personal profile</Link>
                            <Link to={`/${ UrlSlugs.settings_manageProfileSecurity }`} onClick={ () => handleClosePopup() }>security</Link>
                        </Fragment>
                        

        }else if ( account_type===3 ){
            menuHtml =  <Fragment>
                            <Link to={`/${ UrlSlugs.settings_manageProfileCompany }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>manage company profile</Link>
                            <Link to={`/${ UrlSlugs.settings_manageDepartments }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>departments</Link>
                            <Link to={`/${ UrlSlugs.settings_manageTeam }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>team members</Link>
                        </Fragment>

        }else if ( account_type===4 ){

            if ([1,2].includes(team_is_admin)){
                menuHtml =  <Fragment>
                                <Link to={`/${ UrlSlugs.settings_manageWorkProfile }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>manage work profile</Link>
                                <Link to={`/${ UrlSlugs.settings_manageProfileCompany }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>manage company profile</Link>
                                <Link to={`/${ UrlSlugs.settings_manageDepartments }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>departments</Link>
                                <Link to={`/${ UrlSlugs.settings_manageTeam }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>team members</Link>
                                {/* <div className="cursor-pointer" onClick={ () => { handleClosePopup(); dispatch( toggleMenuPriceList( profile ) );  }}>menu/products</div> */}
                                { [1].includes(team_is_admin) && <Link to={`/${ UrlSlugs.settings_specialPermissions }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>special permission</Link> }
                                <Link to={`/${ UrlSlugs.settings_manageCompanyGroups }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>groups</Link>
                            </Fragment>
            }else{
                menuHtml =  <Fragment>
                                <Link to={`/${ UrlSlugs.settings_manageWorkProfile }/${ account_id_encoded }` } onClick={ () => handleClosePopup() }>manage work profile</Link>
                            </Fragment>
            }
        }

        return menuHtml;
    }

    const subProfiles = ( profile ) => {
        
        let subProfilesTemp = [];
        if ( parseInt(profile.account_type)===4){            
            if ( profile.hasOwnProperty('departments') ){
                subProfilesTemp = [...subProfilesTemp, profile ];

                Object.entries(profile.departments).map( ([key, row ]) => {
                    if ( parseInt(row.group_id || 0)>0 && parseInt(row.flag || 0)===5 ){
                       
                        let departmentTemp = {
                            ...row,
                            logo : nameByAccountType(profile).logo,
                            name : row.department_name,
                            type : 3
                        }

                        subProfilesTemp = [...subProfilesTemp, departmentTemp ];
                    }
                });
            }   
        }

        return subProfilesTemp;
    }

    const teamAssigned = ( profile ) => {
    
        let returns = { indicator : false, assign  : false };
        if ( profile.hasOwnProperty('account_type') ){
            const account_type = parseInt(profile.account_type || 0);
            
            if ( account_type===4){
                const account_flag = parseInt(profile.flag || 0);
                let indicator = false;

                if (account_flag===4){
                    if ( hasAssignee.includes(profile.account_id.toString()) ){
                        indicator = true;
                    }
                    returns = { ...returns, indicator : indicator , assign  : true };
                }
            }
        }

        return returns;
    }


    const setupProfiles = () => {

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){

                let accountMain = [];
                let accountSub = [];
                let accountIDs = [];

                let accountListTemp = Utilities.sortAccounts(accountsList);

                accountListTemp.forEach( acctdetail => {
                    const formattedDetails = Utilities.formatProfileDetail(acctdetail);

                    if ( parseInt(acctdetail.account_type)===1 ){
                        accountMain = formattedDetails;
                    }else{
                        accountSub = [ ...accountSub, formattedDetails ];
                    }
                    
                    if ( parseInt(acctdetail.account_type)===4 ){
                        accountIDs.push(acctdetail.account_id);
                    }
                });

                let accountAll = [];
                accountAll.push(accountMain);

                if ( accountSub.length>0 ){
                    if ( 'company_name' in accountSub[0] ){
                        //const byName = RM.ascend(RM.prop('company_name')); key senstive
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('company_name')));
                        accountSub = RM.sort(byName, accountSub);
                    }

                    accountAll = RM.concat(accountAll, accountSub);
                }

                accountAll = Utilities.sliceIntoChunks(accountAll, 3);
                const sliderCounterAppTemp = [...Array(Object.entries(accountAll).length).keys()];
                
                setSliderCounterArr( sliderCounterAppTemp );
                setListAccounts(accountAll);

                if ( accountIDs.length>0 ){
                    axios
                    .post( ApiUrls.linkProfilesAssignedStatusByIDs , { account_ids : accountIDs.join(',').toString() } )
                    .then( response => {

                        if ( response.status===200 ){
                            const dataResponse = response.data;
                            const dataStatus = parseInt(dataResponse.status);

                            if ( dataStatus===1 ){
                                setHasAssignee(dataResponse.ids);
                            }
                        }
                    })
                    .catch( (err) => {
                        
                    });
                }
            }

        }
    }

    const submitAppointmentStatus = ( appointment_status = 0 ) => {

        const passValues = {
            api_token : TOKEN.toString(),
            account_id : loggedinAccount.account_id.toString(),
            flag : appointment_status.toString()
        }
            
        dispatch( showLoaderReducer(1) );

        let statusLabel = parseInt(appointment_status)===1 ? 'activated' : 'de-activated';

        axios
        .post( ApiUrls.profileActivateTeamAppointmentStatus , passValues )
        .then( response => {

            dispatch( showLoaderReducer(0) );

            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){

                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if ( divertReplyInfo!==null ){

                            divertReplyInfo = JSON.parse(divertReplyInfo);
                            divertReplyInfo = { 
                                                ...divertReplyInfo,
                                                appointment_status : appointment_status.toString()
                                            };

                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(divertReplyInfo)
                            );

                            dispatch( passConversListReducer(divertReplyInfo) );

                            Utilities.messagePopup('success', response.data.successMessage);
                            Utilities.messagePopup('success', `appointment request has been ${statusLabel} successfully.`);
                        }

                        handleClosePopup();
                        history.push(`/${UrlSlugs.dashboard}` );

                }else{
                    Utilities.messagePopup('success', response.data.errorMessage);
                }
                
            }else{
                Utilities.messagePopup('error', errorMsg);
            }

        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg);
            dispatch( showLoaderReducer(0) );
        });

    }

    const handleAppointmentStatus = () => {

        let statusLabel = 'activate';
        let appointment_status = 1;

        if ( divertReplyInfo.hasOwnProperty('appointment_status') && parseInt(divertReplyInfo.appointment_status || 0)===1 ){
            statusLabel = 'de-activate';
            appointment_status = 0;
        }

        confirmAlert({
            title: '',
            message: `are you sure to wish to ${statusLabel} appointment request?`,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                { 
                    label: 'yes',
                    onClick: () => { submitAppointmentStatus(appointment_status); }
                }
            ]
        });
    }
    
    const handleRestrictAccessStatus = () => {
        let statusLabel = 'activate';
        let restrict_status = 1;

        if ( divertReplyInfo.hasOwnProperty('restrict_access_status') && parseInt(divertReplyInfo.restrict_access_status || 0)===1 ){
            statusLabel = 'de-activate';
            restrict_status = 0;
        }

        confirmAlert({
            title: '',
            message: `are you sure to wish to ${statusLabel} restrict access?`,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                { 
                    label: 'yes',
                    onClick: () => {
                        submitRestrictAccessStatus(restrict_status);
                    
                    }
                }
            ]
        });
    }

    const submitRestrictAccessStatus = async ( restrict_status = 0 ) => {

        const passValues = {
            api_token : TOKEN.toString(),
            account_id : loggedinAccount.account_id.toString(),
            flag : restrict_status.toString()
        }

        dispatch( showLoaderReducer(1) );

        let statusLabel = parseInt(restrict_status || 0)===1 ? 'enabled' : 'removed';

        axios
        .post( ApiUrls.profileActivateTeamRestrictAccess , passValues )
        .then( response => {

            dispatch( showLoaderReducer(0) );

            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){

                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if ( divertReplyInfo!==null ){

                            divertReplyInfo = JSON.parse(divertReplyInfo);
                            divertReplyInfo = { 
                                                ...divertReplyInfo,
                                                restrict_access_status : restrict_status.toString()
                                            };


                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(divertReplyInfo)
                            );
            
                            dispatch( passConversListReducer(divertReplyInfo) );
                            Utilities.messagePopup('success', `restrict access successfully ${statusLabel}`);
                        }

                        handleClosePopup();
                        history.push(`/${UrlSlugs.dashboard}`);

                }else{
                    Utilities.messagePopup('success', response.data.errorMessage);
                }
                
            }else{
                Utilities.messagePopup('error', errorMsg);
            }
        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg);
            dispatch( showLoaderReducer(0) );
        });

    }

    const getCounters = async ( accountDetail = {} ) => {
        let countersTemp = {...counters };

        const responseDocuments = await QueryUtilities.recordCounterByType({  account_id : accountDetail.account_id.toString(), type : 'document' });
        if ( Object.entries(responseDocuments).length>0 ){
            countersTemp = { ...countersTemp, document : parseInt(responseDocuments.counter) }
        }

        const responseSpecialNote = await QueryUtilities.recordCounterByType({  account_id : accountDetail.account_id.toString(), type : 'special-note' });
        if ( Object.entries(responseSpecialNote).length>0 ){
            countersTemp = { ...countersTemp, special : parseInt(responseSpecialNote.counter) }
        }

        const { receiverAccountIdsArray, receiverDepartmentIdsArray } = getReceiverAccountAndDeptReceiverIds();
       
        // GET LOGIN USER
       /*  const userLoggedinDetail = PagesAuthentication.getUserLoggedinDetail();
        receiverAccountIdsArray.push( userLoggedinDetail.account_id.toString() );//+'|'+( userLoggedinDetail.hasOwnProperty('group_id') ? userLoggedinDetail.group_id.toString() : '0' ) );
        
        const usersAssociated = PagesAuthentication.getUsersAssociated() ?? {};
        Object.entries(usersAssociated).map( ([key, pdetail]) => {
            if ( parseInt(userLoggedinDetail.account_id)!==parseInt(pdetail.account_id) ){
                receiverAccountIdsArray.push( pdetail.account_id.toString() );
            }
        }); */

        const passval = {
            account_id : receiverAccountIdsArray.join(',').toString(),
            receiver_department_id : receiverDepartmentIdsArray.join(',').toString(),
            type : 'appointment-reservation'
        };
        
        const responseAppointmentReservation = await QueryUtilities.recordCounterByType(passval);
        if ( Object.entries(responseAppointmentReservation).length>0 ){
            countersTemp = { ...countersTemp, appointment : parseInt(responseAppointmentReservation.counter) }
        }
        
        setCounters(countersTemp);
    }

    const logoutuser = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wantToLogout,
            overlayClassName : 'normal-font-style',
            buttons: [
                { label: 'No',  onClick: () => {} },
                { 
                    label: 'yes',
                    onClick: () => {
                        handleClosePopup();  
                        PagesAuthentication.logoutUser();
                        history.push('/');
                    }
                }
            ]
        });
    }

    const nameByAccountType = ( profile ) =>{

        let name = profile.name;
        let logo  = profile.img;

        if ( parseInt(profile.account_type)===4 ){
            if ( profile.hasOwnProperty('company_detail') ){
                name = profile.company_detail.hasOwnProperty('company_display_name') ? profile.company_detail.company_display_name : profile.company_detail.company_name;
                logo = Utilities.formatProfileDetail(profile, 1).img;
            }
        }else{
            name = Utilities.accountTypeCustomLabels(profile);
        }

        logo  = typeof logo.split('/').pop()!=='undefined' ? logo : DEFAUL_AVATAR;

        return { name : name, logo : logo };
    }


    const handleOnClickSettingNavigations = ( actionName = '' ) => {
        actionName = actionName.toLowerCase();

        if ( actionName==='automated-reply' ){
            handleClosePopup();
            dispatch( toggleAutomatedReply(new Date()) );

        }else if ( actionName==='divert' ){
            handleClosePopup();
            history.push(`/${ UrlSlugs.settings_divertProfiles_assign }/${ btoa(loggedinAccount.account_id) }`);

        }else if ( actionName==='default-message' ){
            handleClosePopup();
            dispatch( toggleDepartmentDefaultMessageReducer( new Date() ) );

        }else if ( actionName==='form' ){
            handleClosePopup();
            dispatch( toggleDepartmentFormsReducer( new Date() ) );

        }else if ( actionName==='special-message' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_specialMessages}`);

        }else if ( actionName==='document-library' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.documents}`);

        }else if ( actionName==='qrcode' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_shareQrCode}`);

        }else if ( actionName==='calendar-diary' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.calendarDiary}`);
            
        }else if ( actionName==='profiles' ){
            setSlideProfiles(true);
            setActiveSlide(0);

        }else if ( actionName==='linked-accounts' ){
            setSlideLinkedProfiles(true);
            setActiveSlide(0);

        }else if ( actionName==='create-company' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.signup_companyInformation}`);

        }else if ( actionName==='location' ){
            handleClosePopup();
            dispatch( changeCityPopupAction(Utilities.cityLocationLabel) );

        }else if ( actionName==='languages' ){
            handleClosePopup();
            dispatch( showLanguageSelectionPopup(Date.now()) );

        }else if ( actionName==='important-information' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_importantInformation}`);

        }else if ( actionName==='bizcom-assist' ){
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_bizcomAssist}`);
        
        }else if ( actionName==='notification-management' ){

            let linkProvided = UrlSlugs.settings_followersNotices;
            if ( loggedinAccount.hasOwnProperty('follow_status') ){
                if ( parseInt(loggedinAccount.follow_status ?? 0)>=1 ){
                    linkProvided = UrlSlugs.settings_notificationManagement;
                }
            }

            handleClosePopup();
            history.push(`/${linkProvided}`);

        }/* else if ( actionName==='talk-to-us' ){
            window.open( Utilities.talkToUsUrl, '_blank');

        }else if ( actionName==='bizcom247' ){
            window.open( Utilities.talkToUsUrl, '_blank');
        } */
    }

    

    useEffect(() => {

        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
        if ( divertReplyInfo!==null ){
            divertReplyInfo = JSON.parse(divertReplyInfo);
            setDivertReplyInfo(divertReplyInfo);
        }

        return () => {
            setShowPopup(false);
            setMainSettingsStatus(false);
            dispatch( showMainSettingsReducer('') );
            setSlideProfiles(false);
            setSlideLinkedProfiles(false);
            setActiveSlide(0);
            setLoggedinAccount({});
        };

    }, []);



   useEffect( () => {
        setupProfiles();
        
    }, [reloadProfile]);

    useEffect( () => {

        setupProfiles();
        setActiveSlide(0);

        if ( Object.keys(passConversList).length>0 ){

            const menu_setting = passConversList.hasOwnProperty('menu_setting') ? passConversList.menu_setting : passConversList;
            setDivertReplyInfo(menu_setting);
 
            localStorage.setItem(
                process.env.REACT_APP_session_divert_reply,
                JSON.stringify(menu_setting)
            );

        }

    }, [passConversList]);



    useEffect( () => {
    
        setSlideProfiles(false);
        setSlideLinkedProfiles(false);
        setActiveSlide(0);

        if ( ! Utilities.isEmpty(showMainSettings.toString()) ){
            setShowPopup(true);
            setMainSettingsStatus(true);

        }else{
            setShowPopup(false);
            setMainSettingsStatus(false);
        }

        let allowShareQR = false;
        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);

        if ( loginPersonalInfo!==null ){
            loginPersonalInfo = JSON.parse(loginPersonalInfo);

            setLoggedinAccount( loginPersonalInfo );
            getCounters(loginPersonalInfo);

            if ( parseInt(loginPersonalInfo.account_type)===1){
                allowShareQR = true;

            }else{
                /* if ( loginPersonalInfo.hasOwnProperty('team_is_admin') ){
                    const team_is_admin = loginPersonalInfo.team_is_admin;
    
                    if ( parseInt(team_is_admin)===1 && parseInt(loginPersonalInfo.flag)===4 ){
                        allowShareQR = true;
                    }
                } */

                if ( loginPersonalInfo.hasOwnProperty('company_detail') ){
                    allowShareQR = true;
                }
            }
        }

        setAllowShareQRCode(allowShareQR);

      

        return () => {
            setLoggedinAccount({});
        }
        
    }, [showMainSettings]);



    return (

        mainSettingsStatus===true &&
            <Modal id="popup-setting-profiles"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="lg"  dialogClassName="popup-setting-profiles modal-dialog-theme-black"  keyboard={ true }>
                <Modal.Body className="px-5 py-4 position-relative overflow-hidden">
                    
                    <div className="text-lowercase">
                        
                        <section className="col-12 mx-auto pb-3 font-gotham-light-14  px-0">

                            <ul className="settings-inline-navigations mb-4 py-3">

                            {/************ WORK ACCOUNT ************/}
                            {
                                ( loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===4 && !( loggedinAccount.hasOwnProperty('group_id') ) ) &&
                                <>
                                    <li onClick={ () => handleAppointmentStatus() }>
                                        <div className={`menu-icon calendar-icon ${ ( divertReplyInfo.hasOwnProperty('appointment_status') && parseInt(divertReplyInfo.appointment_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>appointment<br />request</div>
                                    </li>

                                    <li onClick={ () => handleOnClickSettingNavigations('automated-reply') }>
                                        <div className={`menu-icon reply-icon ${ ( divertReplyInfo.hasOwnProperty('auto_replay_status') && parseInt(divertReplyInfo.auto_replay_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>automated<br />reply</div>
                                    </li>

                                    <li onClick={ () => handleOnClickSettingNavigations('divert')}>
                                        <div className={`menu-icon divert-icon ${ ( divertReplyInfo.hasOwnProperty('divert_status') && parseInt(divertReplyInfo.divert_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>divert</div>
                                    </li>

                                    <li onClick={ () => handleRestrictAccessStatus() }>
                                        <div className={`menu-icon lock-icon ${ ( divertReplyInfo.hasOwnProperty('restrict_access_status') && parseInt(divertReplyInfo.restrict_access_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>restrict<br />access</div>
                                    </li>
                                    
                                </>
                            }

                            {/************ PERSONAL ACCOUNT ************/}
                            {
                                ( loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===1 ) &&
                                <>
                                    <li onClick={ () => handleOnClickSettingNavigations('automated-reply') }>
                                        <div className={`menu-icon reply-icon ${ ( divertReplyInfo.hasOwnProperty('auto_replay_status') && parseInt(divertReplyInfo.auto_replay_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>automated<br />reply</div>
                                    </li>
                                </>
                            }

                            {/************ SWITCH TO DEPARTMENT ************/}
                            {
                                ( loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===4 && loggedinAccount.hasOwnProperty('group_id') ) &&
                                <>
                                    <li onClick={ () => handleOnClickSettingNavigations('default-message') }>
                                        <div className={`menu-icon comment-favorite-icon ${ ( divertReplyInfo.hasOwnProperty('depart_message') && parseInt(divertReplyInfo.depart_message.depart_message_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>default<br />message</div>
                                    </li>

                                    <li onClick={ () => handleOnClickSettingNavigations('form') }>
                                        <div className={`menu-icon form-icon ${ ( divertReplyInfo.hasOwnProperty('depart_form') && parseInt(divertReplyInfo.depart_form.depart_form_status || 0)===1 ) ? 'active-menu' : ''}`}></div>
                                        <div>form</div>
                                    </li>
                                </>
                            }

                                <li onClick={ () => handleOnClickSettingNavigations('calendar-diary') }>
                                    <div className={`menu-icon calendar-diary-icon ${ parseInt(counters.appointment)>0?'active-menu' : '' }`}></div>
                                    <div>appointments/<br />reservations</div>
                                </li>
                            
                                <li onClick={ () => handleOnClickSettingNavigations('special-message') }>
                                    <div className={`menu-icon special-messages-icon  ${ parseInt(counters.special)>0?'active-menu' : '' }`}></div>
                                    <div>saved frequent<br />message</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('document-library') }>
                                    <div className={`menu-icon document-library-icon ${ parseInt(counters.document)>0?'active-menu' : '' }`}></div>
                                    <div>document<br />library</div>
                                </li>


                                 {/************ WORK ACCOUNT ************/}
                                {
                                    ( loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===4 && !( loggedinAccount.hasOwnProperty('group_id') ) ) &&
                                    <>
                                        { menuProductButtonHtml( loggedinAccount ) }
                                        { notificationManagementHtml( loggedinAccount) }
                                    </>
                                }
                                
                                {
                                    allowShareQRCode &&
                                    <li onClick={ () => handleOnClickSettingNavigations('qrcode') }>
                                        <div className="menu-icon qr-icon"></div>
                                        <div>qr code</div>
                                    </li>
                                }

                               
                            </ul>


                            <div className="font-gotham-book-18 pb-4 pt-2 mb-3 w-100">system settings</div>

                            <ul className={`settings-navigations text-center ${ loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===4 ? '' : 'setting-personal' }`}>
                                
                                <li onClick={ () => handleOnClickSettingNavigations('profiles') }>
                                    <div className="menu-icon user-icon"></div>
                                    <div>profiles</div>
                                </li>
                                
                                <li onClick={ () => handleOnClickSettingNavigations('linked-accounts') }>
                                    <div className="menu-icon linked-profiles-icon"></div>
                                    <div>linked<br />accounts</div>
                                </li>

                                {
                                    ( loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type)===1 ) &&
                                        <li  onClick={ () => handleOnClickSettingNavigations('create-company') }>
                                            <div className="menu-icon building-icon"></div>
                                            <div>create<br />company</div>
                                        </li>
                                }

                                <li  onClick={ () => handleOnClickSettingNavigations('location') }>
                                    <div className="menu-icon location-icon"></div>
                                    <div>location</div>
                                </li>

                                <li  onClick={ () => handleOnClickSettingNavigations('languages') }>
                                    <div className="menu-icon languages-icon"></div>
                                    <div>languages</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('important-information') }>
                                    <div className="menu-icon important-info-icon"></div>
                                    <div>important<br />information</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('bizcom-assist') }>
                                    <div className="menu-icon bizcom-assist-icon"></div>
                                    <div>{siteTitle}<br />assist</div>
                                </li>

                                {/* <li onClick={ () => handleOnClickSettingNavigations('talk-to-us') }>
                                    <div className="menu-icon globe-icon"></div>
                                    <div>talk to<br />us</div>
                                </li>

                                <li onClick={ () => handleOnClickSettingNavigations('bizcom247') }>
                                    <div className="menu-icon comment-icon"></div>
                                    <div>bizcom<br />247</div>
                                </li> */}

                            </ul>

                            <div className="d-flex align-items-center justify-content-center mt-5">
                                <div className="logout-user-nav" onClick={ () => logoutuser() }>
                                    <div className="menu-icon power-icon mr-1"></div>
                                    <div>logout</div>
                                </div>
                            </div>

                        </section>


                        <section className={`font-gotham-light-14 profile-navigation-content text-color-black ${ slideProfiles===true ? 'opened' : '' }`}>

                            {
                                slideProfiles===true &&
                                    <Fragment>

                                         <div className="font-gotham-book-18 text-center pb-4 pt-4 mt-2 mb-3 w-100 position-relative">
                                            profiles
                                            <div className="btn-close" onClick={ () => setSlideProfiles(false) }></div>
                                        </div>
                                        
                                        {
                                            parseInt( Object.keys(listAccounts).length )>1 &&
                                            <div className="theme-slider-arrows px-5">
                                                <div className="slide-arrow">
                                                    <div className={`slide-arrow-left ${ parseInt(activeSlide)===0 ? 'opacity-025' : '' }` } onClick={ () => sliderPrev() }></div>
                                                    <div className={`slide-arrow-right ${ parseInt(sliderCounterArr.slice(-1)[0])===parseInt(activeSlide) ? 'opacity-025' : '' } `} onClick={ () => sliderNext() }></div>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className="profile-list-wrap col-10 mx-auto position-relative pt--4 overflow-hidden font-gotham-ligh-14">
                                            {
                                                Object.entries(listAccounts).map( ([keyAcct, listAccountPage] ) => 
                                                    <ul  key={keyAcct} className={`list-profile-linked  text-center fade-in ${ parseInt(activeSlide)===parseInt(keyAcct) ? 'active' : '' }`}>
                                                        {
                                                            Object.entries(listAccountPage).map( ([key, row] ) =>
                                                                <li  key={key}>

                                                                        <div className="dropdown-linked-profile cursor-auto">
                                                                            <div className={`logo-size-80 fs-22-important mx-auto mb-3`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage( nameByAccountType(row).logo ) })`, backgroundColor : Utilities.bgcolorHideInitialBg( nameByAccountType(row).logo )  }}>{ Utilities.showHideInitial( nameByAccountType(row).logo ,  row.name ) }</div>
                                                                            <div className="font-gotham-book-16  px-3 ellipsis-1">{ nameByAccountType(row).name }</div>                                                            

                                                                            <div className="list-subprofile-linked mt-3 pt-2">
                                                                                { userMenu(row) }
                                                                            </div>

                                                                        </div>  
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </Fragment>
                            }

                        </section>


                        <section className={`font-gotham-light-14 profile-navigation-content text-color-black  ${ slideLinkedProfiles===true ? 'opened' : '' } `}>

                            {
                                slideLinkedProfiles===true &&
                                    <Fragment>
                                        <div className="font-gotham-book-18 text-center pb-4 pt-4 mt-2 mb-3 w-100 position-relative">
                                            linked accounts
                                            <div className="btn-close" onClick={ () => setSlideLinkedProfiles(false) }></div>
                                        </div>
                                        
                                        {
                                             parseInt( Object.keys(listAccounts).length )>1 && 
                                                <div className="theme-slider-arrows px-5">
                                                    <div className="slide-arrow">
                                                        <div className={`slide-arrow-left ${ parseInt(activeSlide)===0 ? 'opacity-025' : '' }` } onClick={ () => sliderPrev() }></div>
                                                        <div className={`slide-arrow-right ${ parseInt(sliderCounterArr.slice(-1)[0])===parseInt(activeSlide) ? 'opacity-025' : '' } `} onClick={ () => sliderNext() }></div>
                                                    </div>
                                                </div>
                                        }
                                        
                    
                                        <div className="profile-list-wrap col-10 mx-auto position-relative pt--4 overflow-hidden font-gotham-ligh-14">
                                            {
                                                Object.entries(listAccounts).map( ([keyAcct, listAccountPage] ) =>
                                                    <ul  key={keyAcct} className={`list-profile-linked  text-center fade-in ${ parseInt(activeSlide)===parseInt(keyAcct) ? 'active' : '' }`}>
                                                        {
                                                            Object.entries(listAccountPage).map( ([key, row] ) => 
                                                                <li  key={key}>

                                                                        <div className="dropdown-linked-profile cursor-auto">
                                                                            <div className={`logo-size-80 fs-22-important mx-auto mb-3`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage( nameByAccountType(row).logo ) })`, backgroundColor : Utilities.bgcolorHideInitialBg( nameByAccountType(row).logo )  }}>{ Utilities.showHideInitial( nameByAccountType(row).logo ,  row.name ) }</div>
                                                                            <div className="font-gotham-book-16 px-3 ellipsis-1">{ nameByAccountType(row).name }</div>                                                            
                                                                            {
                                                                                parseInt(row.account_type)!==1 &&
                                                                                <div className="list-subprofile-linked mt-3 pt-2">
                                                                                    {
                                                                                        Object.entries(subProfiles(row)).map( ([key, row] ) => 
                                                                                            <div  key={key} className="profile-content">
                                                                                                <div className="profile-name">{ parseInt(row.type)===3 ? row.name : Utilities.accountTypeLabels(row.type) }</div>
                                                                                                {   teamAssigned(row).indicator===true &&  <div className="btn-linked-assigned"></div> } 
                                                                                            </div>
                                                                                        )
                                                                                    }  
                                                                                    
                                                                                </div>
                                                                            }

                                                                        </div>  
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </Fragment>
                            }
                            
                        </section>
                               
                    </div>
                    
                </Modal.Body>
            </Modal>
        
    );
}

export default SettingProfilePopup;
