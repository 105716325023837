import React, {  useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';


const ConnectTeamMember = ( props ) => {

   const profileSelected = props.profileSelected ?? {};
   const companyProfile = props.companyProfile ?? {};

   const showChat = (profileSelected) =>{
        typeof props.showChat===undefined ? false :  props.showChat (profileSelected );
   }
   
    useEffect(() => {
        return () => {
        
        }

    }, []);

    return (

       <Modal id="popup-forgot-password"  show={ props.show ?? false } onHide={ props.handleClose ?? '' } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="lg" >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
                <Modal.Body>
                        
                    <div className="profile-selected-assoc text-center text-lowercase">
                        
                        <div className="col-8 mx-auto">

                            {
                                parseInt(profileSelected.account_type)===3 ?
                                    <>
                                        <div className="profile-header text-left mb-4">
                                            <div className={`profile-image fs-22-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url) }}>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }</div>
                                            <div>
                                                <div className="profile-name">{profileSelected.name}</div>
                                                { ( profileSelected.hasOwnProperty('category') && profileSelected.category!=='' ) && <div className="profile-label">{profileSelected.category}</div> }
                                            </div>
                                        </div>
                                        
                                        <div className="profile-address mb-5">{ companyProfile.hasOwnProperty('address') ? companyProfile.address : '' }</div>

                                        <div className="mb-5">
                                            <ul className="profile-departments">
                                                <li><Link to={`/${ UrlSlugs.chat }/${ btoa( profileSelected.account_id ) }`} onClick={ () =>  props.show(false) } >general</Link></li>
                                                <li><Link to={`/${ UrlSlugs.departments }/${ btoa( profileSelected.account_id ) }`} onClick={ () => props.show(false) } >departments</Link></li>
                                                <li><Link to={`/${ UrlSlugs.teamMembers }/${ btoa( profileSelected.account_id ) }`} onClick={ () => props.show(false) } >team members</Link></li>
                                            </ul>
                                        </div>
                                    </>

                                :
                                    <>
                                        <div className="text-center">
                                            <div className={`logo-size-75 profile-team-logo mx-auto fs-22-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url) }}>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }</div>
                                            <div className=" mt-2">
                                                <div className="font-gotham-book-16 notranslate">{profileSelected.name}</div>
                                                { ( profileSelected.hasOwnProperty('category') && profileSelected.category!=='' ) && <div className="profile-label">{profileSelected.category}</div> }
                                            </div>
                                        </div>

                                        {
                                            Object.keys(companyProfile).length>0 &&
                                            <div className="profile-header-company text-left pt-3 py-4 my-3">
                                                <div className="logo-size-60" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(companyProfile.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(companyProfile.logo_url) }}>{ Utilities.showHideInitial(companyProfile.logo_url, companyProfile.name) }</div>
                                                <div className="line-height-1-2">
                                                    <div className="font-gotham-medium-16 notranslate">{ companyProfile.hasOwnProperty('name') ? companyProfile.name : '' }</div>
                                                    { ( companyProfile.hasOwnProperty('category_name') && companyProfile.category_name!=='' ) && <div className="profile-label">{companyProfile.category_name}</div> }
                                                </div>
                                            </div>
                                        }
                                        

                                        <div className={`mb-5 ${Object.keys(companyProfile).length===0 ? 'mt-5' : '' }`}>
                                                <div id="btn-confirm-chat"  onClick={ () => showChat (profileSelected )  } className="btn-theme-black cursor-pointer button-has-loader" >confirm</div>
                                        </div>
                                    </>
                            }
                            
                        </div>

                    </div>

                </Modal.Body>
        </Modal>
    )
}

export default ConnectTeamMember;
