import React, { useEffect, useState, Fragment } from 'react';
import {  useDispatch, useSelector  } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
import moment from 'moment';

import {  showHeader, showFooter, pageTitle, passInternalProfile, passImageUrl, passChatProfile, refershDashboadConversation as refershDashboadConversationReducer, passConversList, showPopupQuickLinks as showPopupQuickLinksReducer } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';

import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';
import SessionUtilities from '../helpers/SessionUtilities';
import QueryUtilities from '../helpers/QueryUtilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import ChatBroadCast from '../components/popups/ChatBroadCast';
import ChatGroup from '../components/popups/ChatGroup';
//import QueryChatUtilities from '../helpers/QueryChatUtilities';


const InternalDashboard = ( {match} ) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const BIZCOM_TOKEN = process.env.REACT_APP_chat_api_token_bizcom;
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    
    const refershDashboadConversation = useSelector( state => state.refershDashboadConversation);

    const [ showMoreOption, setShowMoreOption ] = useState(false);
    const [ memberList, setMemberList ] = useState([]);
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ profileSort, setProfileSort ] = useState(false);
    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ companyDetail, setCompanyDetail ] = useState([]);
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');
    
    const [ rowProfileSelected, setRowProfileSelected ] = useState({});
    const [ showChatBroadcast, setShowChatBroadcast] = useState(false);

    const handleCloseChatBroadcast = () => setShowChatBroadcast(false);
    const handleShowChatBroadcast = () =>  setShowChatBroadcast(true);

    const [ showChatGroup, setShowChatGroup] = useState(false);

    const handleCloseChatGroup = () => setShowChatGroup(false);
    const handleShowChatGroup = () =>  setShowChatGroup(true);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const sortUnsortProfiles = () => {
       let tempProfileSort = profileSort;
       let tempListProfiles = listProfiles;

        if ( profileSort===false ){
            tempProfileSort = true;

            tempListProfiles.sort(Utilities.fieldSorter(['name']) );
            //tempListProfiles.reverse();
            setListProfiles(tempListProfiles);

        }else{
            tempProfileSort = false;

            tempListProfiles.reverse();
            setListProfiles(tempListProfiles);
        }

        setProfileSort(tempProfileSort);
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const showChat = async ( profile = {} ) => {
        
        if ( parseInt(profile.chat_status || 0)===0  ) {
            return false;
        }

        setRowProfileSelected(profile);
        SessionUtilities.setDashboadInternalListSelectedProfile(profile);

        if  ( profile.hasOwnProperty('conversa_list') && Object.keys(profile.conversa_list).length>0 ){
           
            /* if ( profile.conversa_list.hasOwnProperty('group_members') ){
                // NEW API
                const passval = {
                    api_token : BIZCOM_TOKEN.toString(),
                    account_id : profileDetail.account_id.toString(),
                    account_type : profileDetail.account_type.toString(),
                    conversa_id : profile.conversa_list.convers_id.toString(),
                }
        
                const conversationDetailResponse = await QueryChatUtilities.getBeonchatConversationDetail(passval);
                if ( Object.keys(conversationDetailResponse).length===0 ){
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
                    return false;
                }
        
                if ( parseInt(conversationDetailResponse.success)===0){
                    Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
                    return false;
                    
                }
                
                let profileChatConversation = conversationDetailResponse.result.beonchat_conversation;       
                const passValues = ChatUtilities.encryptChatDetail(profileChatConversation, 1, { account_id : profileChatConversation.account_id } ); 

                ChatUtilities.saveAccountsConversationDetail(profileChatConversation );
                history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileDetail.account_id) )}/${passValues}`);
                console.log(1);

            }else{ */
            
                const passValues = ChatUtilities.encryptChatDetail(profile.conversa_list, 1, { account_id : profile.account_id } ); 

                ChatUtilities.saveAccountsConversationDetail(profile.conversa_list , 'internal 1');
                history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileDetail.account_id) )}/${passValues}`);
                //console.log(2);
            //}

        }else{

            const contactRequestValues = ChatUtilities.contactRequest( profileDetail, profile, 0, 1 );
            const conversationDetailRes = await QueryUtilities.getConversationDetail( contactRequestValues.url, contactRequestValues.values  );
            
            const conversation = conversationDetailRes.result.bizchat_conversation.conversation_list ?? {};
            ChatUtilities.saveAccountsConversationDetail(conversation, 'internal 2' );

            const passValues = ChatUtilities.encryptChatDetail( conversation, 1, { account_id : profile.account_id } ); 
            history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileDetail.account_id) )}/${passValues}`);
        }

    }
    

    const getMembers = async ( companyID = 0, accountDetail = {}, masterDetail = {}, refresh = 0 ) => {
        
        if ( parseInt(companyID)>0 && Object.keys(accountDetail).length>0 && Object.keys(masterDetail).length>0 ){

           
            const dasboardLoadedStatus = parseInt(SessionUtilities.getDashboadInternalLoadedStatus());
    
            if ( refresh===0){
                setContentLoaderClass('animate');
                //SessionUtilities.setDashboadInternalLoadedStatus(1);
    
            }

            if ( dasboardLoadedStatus===1 ){
                const listing = SessionUtilities.getDashboadLoadedInternalListTemp();
                if ( listing.length>0 ){
                    dispatch( passConversList({}) );

                    setTimeout( () => {
                        setContentLoaderClass('');
                        setListProfiles([...listing]);
                    }, 300);
        
                    //setContentLoaderClass('');
                    setShowEmpty(false);
                }
            }
            
            const passValues = {
                api_token : BIZCOM_TOKEN.toString(),
                account_id : accountDetail.account_id.toString(),
                company_id : companyID.toString(),
                master_account_id : masterDetail.account_id.toString(),
            }

            await axios
                .post( ApiUrls.bizcomWebInternalHomeDetail, passValues)
                .then( response => {

                    setContentLoaderClass('');
                    dispatch( refershDashboadConversationReducer('') );

                    if ( parseInt(response.status)===201 ){
                        if ( parseInt(response.data.success)===1 ){

                            dispatch( passConversList(response.data.result.module_list ?? {}) );

                            const team_list = response.data.result.module_list.internal_conversation ?? {};

                            if ( parseInt(team_list.length)>0 ){ 
                                
                                let memberList = [];
                                let memberBroadcasList = [];

                                Object.entries(team_list).map( ( [id, memberdetail] ) => {

                                        let local_time = '';
                                        const constconversa_list  = memberdetail.conversa_list ?? {};

                                        if  ( memberdetail.hasOwnProperty('conversa_list') && Object.keys(memberdetail.conversa_list).length>0 ){
                                            if ( constconversa_list.hasOwnProperty('order_by') ){
                                                
                                                let localTime  = moment(parseInt(constconversa_list.order_by));
                                                let local_date = moment(new Date(localTime)).format(Utilities.dateFormatStr.toString()).toLocaleString(); 
                                                
                                                if ( local_date.toString().toLocaleLowerCase() !== 'invalid date'){
                                                
                                                    var sameDay = moment(constconversa_list.order_by).isSame(moment(), 'day');
                                                    if(sameDay===true) {
                                                        localTime  = moment.utc(constconversa_list.order_by);                        
                                                        local_time = moment(new Date(localTime)).format(Utilities.timeFormatStr.toString()).toLocaleString(); 
                                                        
                                                    }else{
                                                        local_time = moment(constconversa_list.order_by).format(Utilities.dateFormatStr.toString());                     
                                                    }
                                                }
                                                
                                            }
                                        }

                                        memberdetail = { ...memberdetail, ...{ order_by : local_time } };
                                        memberList = [ ...memberList, memberdetail ];

                                        if ( parseInt(memberdetail.chat_status || 0)===1 && memberdetail.hasOwnProperty('conversa_list') && parseInt(memberdetail.conversa_list.flag || 0)!==5 ){
                                            memberBroadcasList = [ ...memberBroadcasList, memberdetail ];
                                        }
                                });

                                if ( memberBroadcasList.length>0 ){
                                    memberBroadcasList = memberBroadcasList.sort(Utilities.fieldSorter(['name']) );
                                    setMemberList(memberBroadcasList);
                                }

                                if ( parseInt(memberList.length)>0 ){
                                    setListProfiles(memberList);
                                    setShowEmpty(false);

                                    SessionUtilities.setDashboadLoadedInternalListTemp(memberList);
                                    SessionUtilities.setDashboadInternalLoadedStatus(1);

                                }else{
                                    setShowEmpty(true);
                                }

                            }else{
                                setShowEmpty(true);
                            }

                        }else{
                            setShowEmpty(true);
                        }
                        
                    }else{
                        setShowEmpty(true);
                    }
                })
                .catch( (err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                }); 

           
        }else{
            setShowEmpty(true);
            setContentLoaderClass('');
            dispatch( refershDashboadConversationReducer('') );
        }

    }

    const previewProfileImage = (logoUrl ) => {
        if ( ! Utilities.isEmpty(logoUrl) ){
             dispatch( passImageUrl(logoUrl) );
        }
    }

    const blockOrCounter = ( chatDetail ) => {
        let html_return = '';

        if ( chatDetail.hasOwnProperty('contact_settings') && parseInt(chatDetail.contact_settings.mute_status_settings.mute_status || 0)===1 ){
            html_return = <div className="onmute-icon"></div>
        } 

        if  ( chatDetail.hasOwnProperty('conversa_list') && Object.keys(chatDetail.conversa_list).length>0 ){
            const conversa_list = chatDetail.conversa_list;
            let  blockValue = 0;

            if ( ! ( conversa_list.hasOwnProperty('flag') ) && parseInt(conversa_list.flag || 0)!==2 ){
                if ( conversa_list.hasOwnProperty('block_status') ){
                    blockValue = parseInt(conversa_list.block_status.block_in_status || 0) + parseInt(conversa_list.block_status.block_out_status || 0);
                }
            }

            if ( parseInt(blockValue)>0 ){
                html_return = <span className="blocked-icon"></span>
            }
        }

        if ( chatDetail.hasOwnProperty('chat_count') && parseInt(chatDetail.chat_count || 0)>0 ){
            html_return = <Fragment> {html_return} <div className="unread-counter">{ Utilities.counterMore(chatDetail.chat_count) }</div></Fragment>
        }
       
        return html_return;
    }


    const getMemberList = async ( reload = 0 ) => {

        let reqAccountID = match.params.id;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            
            if ( parseInt(reqAccountID)>0 ){
                let profileDetailTemp = SessionUtilities.getProfileDetail(reqAccountID);
                setProfileDetail(profileDetailTemp);

                dispatch( passInternalProfile(profileDetailTemp) );

                let coompanyDetailTemp = Utilities.workToCompany(profileDetailTemp);
                setCompanyDetail(coompanyDetailTemp);

                let masterAccountArr = {};
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if ( accountsList!==null ){
                    accountsList = JSON.parse(accountsList);

                    let firstKey = Object.keys(accountsList)[0];
                    firstKey = parseInt(firstKey);

                    masterAccountArr = accountsList[firstKey];
                }

                await getMembers(coompanyDetailTemp.account_id, profileDetailTemp, masterAccountArr, reload );
                
            }
        }
    }

    useEffect( async () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.internalChat ) );
        dispatch( showHeader(2) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();

        await getMemberList();
        dispatch( passChatProfile({}) );

        return () => {
            dispatch( refershDashboadConversationReducer('') );
        }

    }, [] );


    useEffect( () => {
        const segment = Utilities.getUrlSegments();

        let urlSlug = segment.shift();
        if ( typeof urlSlug!=='undefined' ){
            urlSlug = urlSlug.toString().toLowerCase();
        }

        if ( urlSlug==='internal-chat' ){
            getMemberList();
        }

    }, [ window.location.pathname ]);



    useEffect( () => {
        const isContentHigher = Utilities.checkContentIfHigher('listing-profiles');
        setShowMoreOption(isContentHigher);

    }, [listProfiles]);


    useEffect( () => {
        if ( ! Utilities.isEmpty(refershDashboadConversation) ){
            getMemberList(1);
        }

    }, [refershDashboadConversation] );
    
    return (
        <div className="dashboad-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li></li>
                         <li className="btn-option">

                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>

                            { 
                                memberList.length===0 ?
                                    <>
                                        <div className="btn-icon btn-group-plus processing cursor-wait opacity-05" title="add group chat"></div>
                                        <div className="btn-icon btn-horn processing cursor-wait opacity-05" title="add group chat"></div>
                                    </>
                                :
                                    <>
                                        <div className="btn-icon btn-group-plus" onClick={ () => handleShowChatGroup() } title="add group chat"></div>
                                        <div className="btn-icon btn-horn" onClick={ () => handleShowChatBroadcast() } title="add broadcast chat"></div>
                                    </>
                            }
                            
                            <div className="btn-icon btn-magnify" onClick={ () => toggleSeachBox() } title="search contact"></div>
                            
                             {/* <BreadcrumbProfile allConversationResults={ allConversationResults } showLabel={false} /> */}

                         </li>
                     </ul>
                </div>
            </div>

            <div className="main-content">
                <div className="container">
                        
                        { 
                            ! Utilities.isEmpty(contentLoaderClass) &&
                                <ul className="dashboard-list-preloader">
                                    {
                                        [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                                            <li key={key}>
                                                <div className="profile-image"></div>
                                                <div></div>
                                                <div></div>
                                            </li>
                                        )
                                    }
                                    
                                </ul>
                        }

                        <div className="profile-search-wrap d-none">
                            <div className="profile-search-content">
                                <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                <div className="cursor-pointer  opacity-0" onClick={ () => sortUnsortProfiles() }>a-z</div>
                                <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                            </div>
                        </div>

                        { 
                            Utilities.isEmpty(contentLoaderClass) ?
                                showEmpty===false ?
                                    <ul id="listing-profiles" className={`list-profile-messages list-profile-messages-internal ${ Object.keys(listProfiles).length===0 ? 'd-none' : '' } `}>
                                        {
                                            Object.entries(listProfiles).map( ([key, row] ) =>
                                                <li key={key} data-keyword={row.name.toLowerCase() } className={ `${ ( parseInt(row.chat_status)===0 ) ? 'in-active cursor-notallow' : ''} ${ parseInt(row.access_status  || 0)===1 ? 'opacity-05' : ''} ${ parseInt(row.account_id ||0)===parseInt(rowProfileSelected.account_id||-1)?'cursor-wait':''} `}>
                                                    <div><div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                                    <div  onClick={ () => showChat(row) } >
                                                        <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                        <div className="profile-label">{row.title}</div>
                                                    </div>
                                                    <div className="time">{ row.order_by }</div>
                                                    <div>{ blockOrCounter(row) }</div>
                                                </li>
                                            )
                                        }
                                    </ul>

                                :
                                    <div className="text-center text-lowercase no-record-label no-chats-label">{ Utilities.labelNoChatActive }</div> 
                            : 
                                ''
                        }
                            
                        { showMoreOption===true && <div className="scroll-option-wrap"><span className="btn-scroll-down" onClick={ () => Utilities.scrollDownMore('listing-profiles') }></span></div> }


                </div>
            </div>

            <ChatBroadCast 
                showChatBroadcast={ showChatBroadcast }
                handleCloseChatBroadcast={handleCloseChatBroadcast}
                memberList={memberList}
                companyDetail={companyDetail}
                profileDetail={profileDetail}
            />

            <ChatGroup
                showChatGroup={ showChatGroup }
                handleCloseChatGroup={handleCloseChatGroup}
                memberList={memberList}
                companyDetail={companyDetail}
                profileDetail={profileDetail}
            />

       </div>
    )
}

export default InternalDashboard;