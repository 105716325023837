import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; 

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showLoader as showLoaderReducer,  showMainSettings as showMainSettingsReducer } from '../../../actions';
import { getNoticeList, updateCompanyFollowStatus } from '../../../helpers/FollowersNotices';
import SessionUtilities from '../../../helpers/SessionUtilities';
import Utilities from '../../../helpers/Utilities';


const NotificationManagement = () => {

    const history = useHistory();
    const dispatch = useDispatch();
   
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;

    const [ userLoginDetail , setUserLoginDetail ] = useState({});
    const [ selectedDetail , setSelectedDetail ] = useState({});
    const [ noticeListing , setNoticeListing ] = useState([]);
    const [ showProcessing , setShowProcessing ] = useState(true);

    const handleClickUpdateCompanyFollowStatusConfirmation = () => {

        let followStatusMessage = TitlesLabels.confirmMessages.wishToDeactivate;

        if ( parseInt(userLoginDetail.company_detail.follow_status ?? 0)===2 ){
            followStatusMessage = TitlesLabels.confirmMessages.wishToActivate;
        }
        
        confirmAlert({
            title: '',
            message: followStatusMessage,
            overlayClassName : 'normal-font-style warning',
            buttons: [
                { label: 'no' },
                { label: 'continue',  onClick: () => handleClickUpdateCompanyFollowStatus()  }
            ],
        }); 
    }

    const handleClickUpdateCompanyFollowStatus = async ( ) => {
        let follow_status = 2;

        if ( parseInt(userLoginDetail.company_detail.follow_status ?? 0)===2 ){
            follow_status = 1;
        }

        const passval = {
            api_token : API_TOKEN.toString(),
            account_id : userLoginDetail.account_id.toString(),
            company_id : userLoginDetail.company_detail.account_id.toString(),
            flag : follow_status.toString()
        };

        dispatch( showLoaderReducer(1) );
        const responseDb = await updateCompanyFollowStatus(passval);
        dispatch( showLoaderReducer(0) );

        if ( Object.keys(responseDb).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(responseDb.success)===1 ){
            
            let userLoginDetailTemp = {
                ...userLoginDetail,
                company_detail : {...userLoginDetail.company_detail, follow_status : follow_status.toString() }
            };

            PagesAuthentication.setLoginAccountDetail( userLoginDetailTemp );
            SessionUtilities.updateCompanyDetail( userLoginDetailTemp.company_detail );
            

            confirmAlert({
                title: 'success',
                message: responseDb.successMessage,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'close',  onClick: () => location.reload()  }
                ],
                onClickOutside : () =>  location.reload(),
                onKeypressEscape : () =>  location.reload()
            }); 


        }else{
            Utilities.messagePopup('error', responseDb.errorMessage );
        }

    }

    const getCompanytNoticeList = async ( profile = {} ) => {
        const passval = {
            api_token : API_TOKEN.toString(),
            account_id : profile.account_id.toString(),
            company_id : profile.company_detail.account_id.toString()
        };
        //console.log(passval);
        setShowProcessing(true);
        const noticeListResponse = await getNoticeList(passval);
        setShowProcessing(false);
        
        if ( Object.keys(noticeListResponse).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(noticeListResponse.success)===1 ){

        }

        if ( parseInt(noticeListResponse.success)===1 ){
            setNoticeListing(noticeListResponse.result.company_follow_notice_list);
        }else{
            Utilities.messagePopup('error', noticeListResponse.errorMessage );
        }


    }

    const handleClickSelected = ( detail = {} ) => {
        setSelectedDetail(detail);
    }

    const listingHtml = () => {
        return <>
                    {
                        Object.entries(userLoginDetail).length>0 &&
                            parseInt(userLoginDetail.company_detail.follow_status ?? 0)===1  &&
                                <div className="mb-3 d-flex align-items-center">
                                    <Link to={`/${UrlSlugs.settings_notificationSendNotification}`} className="btn-new-chat btn-icon-wrap cursor-pointer">add new notification</Link>
                                </div>
                    }
                   

                    {
                        showProcessing && 
                        <>
                            <div className="mb-3 global-preloader">
                                <div className="btn-dummy-new-chat"></div>
                            </div>
                            <ul className="dashboard-list-preloader">
                                {
                                    [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }

                    {
                        ! showProcessing && 
                        <>
                            {
                                Object.keys(noticeListing ?? []).length===0 ?
                                    <div className="text-center text-lowercase no-record-label">{ TitlesLabels.general.noRecordAvailable }</div>
                                :
                                    <ul  className="list-profile-messages cursor-auto">
                                        {
                                            Object.entries(noticeListing ?? []).map( ([key, row] ) =>
                                                <li key={key} onClick={ () => handleClickSelected(row) }>
                                                    <div><div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.image_path) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.image_path)  }} onClick={ () => previewProfileImage(row.image_path) }>{ Utilities.showHideInitial(row.logo, row.title) }</div></div>
                                                    <div>
                                                        <div className="profile-name notranslate" data-name={row.title}>{row.title}</div>
                                                        <div className="profile-label text-gray"><div className=" ellipsis-1 col-6 px-0">{row.description}</div></div>
                                                    </div>
                                                    <div className="time">{ Utilities.formatDateList(row.create_date) }</div>
                                                </li>
                                            )
                                        }
                                    </ul>
                            }
                        </>
                    }
            </>
    }

    const selectedDetailHtml = () => {
        
        if ( Object.keys(selectedDetail).length===0 ){ return false; }
        
        return <>
            <div className="col-md-6 mx-auto form-theme text-lowercase pb-5 mb-5">

                <div className="upload-avatar avatar-wrap mb-4">
                    <div className="w-100">
                        <div className="upload-box-preview" style={{ backgroundImage:  `url(${selectedDetail.image_path})` }}></div>
                    </div>
                </div>
                
                <div className="form-group active">
                    <label className="form-label">title</label>
                    <div className="form-control height-inherit">{selectedDetail.title}</div>
                </div>

                <div className="form-group active">
                    <label className="form-label">type here</label>
                    <div className="form-control height-inherit">{selectedDetail.description}</div>
                </div>
            </div>
        </>
    }

    useEffect( () => {
        
        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        if ( parseInt(userLoginDetailTemp.account_type)!==4 ){
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        }else{

            // RETURN to activation page
            if ( userLoginDetailTemp.hasOwnProperty('company_detail') ){
                if ( parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0)===0 ){
                    history.push(`/${UrlSlugs.settings_followersNotices}`);
                    return false;
                }
            }
        }

        setUserLoginDetail(userLoginDetailTemp);
        getCompanytNoticeList(userLoginDetailTemp);

        dispatch( pageTitle(`${TitlesLabels.navigations.notificationManagement}`) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );

       
    }, [] );

    return (
        <div className="notification-management dashboad-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <div className={`${Object.keys(selectedDetail).length===0 ? 'current' : 'cursor-pointer'}`} onClick={ () => setSelectedDetail({}) }>{ TitlesLabels.navigations.notificationManagement }</div>
                            { Object.keys(selectedDetail).length>0 && <div className="current">{ TitlesLabels.navigations.notificationDetail }</div> }
                           
                          </li>
                         <li className="btn-option">
                            <Link to={`/${UrlSlugs.settings_notificationFollowers}`} className="btn-icon btn-group-people-2" title={ TitlesLabels.general.followers }></Link>
                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                                    <div className="btn-icon btn-dots-vertical" title={ TitlesLabels.general.notificationOption }></div>
                                </Dropdown.Toggle>
                
                                <Dropdown.Menu className="dropdown-menu-medium">
                                    <div className="item-user-selection dropdown-item" role="button" onClick={ () => handleClickUpdateCompanyFollowStatusConfirmation() }>
                                        {
                                            Object.entries(userLoginDetail).length>0 && 
                                                parseInt(userLoginDetail.company_detail.follow_status ?? 0)===1 ? 'deactivate' : 'activate'
                                        }
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="btn-icon btn-gear" onClick={ () => dispatch( showMainSettingsReducer( new Date() ) ) } title={ TitlesLabels.settings } ></div>
                         </li>
                     </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container">

                        { Object.keys(selectedDetail).length>0 ? selectedDetailHtml() : listingHtml() }


                </div>
            </div>
       </div>
    )
}

export default NotificationManagement;