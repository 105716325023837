import axios from 'axios';
import ApiUrls from './ApiUrls';


export const checkEmailSocialAccount = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.loginSocialCredential, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}