import axios from 'axios'; 
import ApiUrls from './ApiUrls';

class QueryChatUtilities {
    
    getContactPagination = async ( passValues = {}) => {
        let responseDetail = [];

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizcomHomeConversationList , passValues);
                const responseDetailTemp = serverResponse.data;
                if ( parseInt(responseDetailTemp.success)===1 ){
                    if ( responseDetailTemp.result.home_conversation_list.length>0  ){
                        responseDetail = responseDetailTemp.result.home_conversation_list;
                    }
                }
            } catch (e) {

            }
        }
        return responseDetail;
    }

    getBeonchatGroupsConversationDetail = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatCompanyBroadcastDetails , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }


    getBeonchatConversationDetail = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatBeonchatConversationDetails , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    getConversationDetail = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatGetConversationDetails , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }
    
    getAppointmentReservationDetail = async ( passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.diaryGetAppointmentReservationDetails , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    requestAppointmentComplaint = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatRequestAppointmentComplaint , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    cancelAppointmentReservation = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatCancelAppointmentReservation , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    acceptAppointmentReservation = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatAcceptAppointmentReservation , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    amendAppointmentReservation = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatAmendAppointmentReservation , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    requestComplaintReply = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatRequestComplaintReply , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    acceptRejectAccessTeamCommunication = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatAcceptRejectAccessTeamCommunication , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    blockUnblockAccess = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatReverseAccessteamCommunication , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }


    reverseAccessCommunication = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatReverseAccessCommunicationUserCommunication , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    resendTeamAccessComm = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatResendAccessTeamCommunication , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    acceptRejectAccessCommunication = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatAcceptRejectAccessCommunication , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    resendUserComm = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatResendAccessUserCommunication , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

}



export default new QueryChatUtilities();

