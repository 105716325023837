import axios from 'axios';
import ApiUrls from './ApiUrls';

export const getLocationByCoordinates = async ( requestLatitude = '', requestLongitude = '') =>{
    let responseDetail = {};

    requestLatitude = requestLatitude.toString();
    requestLongitude = requestLongitude.toString();

    if ( requestLatitude!=='' && requestLongitude!=='' ){
        try {
            const responseURL = await axios.post(  ApiUrls.getLocationByCoordinates, { latitude : requestLatitude, longtitude :  requestLongitude});
            if ( parseInt(responseURL.data.status)===1 ){
                responseDetail = responseURL.data.result;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const getCoordinatesByLocation = async ( requestLocation = '' ) =>{
    let responseDetail = {};
    
    if ( requestLocation!=='' ){
        try {
            const responseURL = await axios.post(  ApiUrls.getCoordinatesByLocation, { location_name : requestLocation });
            if ( parseInt(responseURL.data.status)===1 ){
                responseDetail = responseURL.data.result[0];
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}