import firebase from 'firebase';

export const initializeFirebase = () => { 
  firebase.initializeApp({ 
    apiKey: process.env.REACT_APP_fs_api_key,
    authDomain: process.env.REACT_APP_fs_auth_domain,
    databaseURL: process.env.REACT_APP_fs_database_url,
    projectId: process.env.REACT_APP_fs_project_id,
    storageBucket: process.env.REACT_APP_fs_storage_bucket,
    messagingSenderId: process.env.REACT_APP_fs_messaging_senderid,
    appId: process.env.REACT_APP_fs_app_id,
    measurementId: process.env.REACT_APP_fs_measurement_id
  }); 
}

export const askPermissionToReceiveNotifications = async () => { 
    try { 
      const messaging = firebase.messaging(); 
      messaging.usePublicVapidKey(process.env.REACT_APP_fs_user_public_key);

      await messaging.requestPermission(); 

      const token = await messaging.getToken(); 
      return token; 

    } catch(error) { 
      return '';
     
    } 
}

export const deleteFCMToken = async () => { 
  try { 
    const messaging = firebase.messaging(); 
    messaging.usePublicVapidKey(process.env.REACT_APP_fs_user_public_key);
    
    messaging.deleteToken();

    return ''; 

  } catch(error) { 
    return '';
  } 
}
