import React, { useState, useEffect  } from 'react';
import { Dropdown } from 'react-bootstrap';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';

const MobileSelection = ( props ) => {

    const [ countryList, setCountryList ] = useState([]);
    const [ defaultFlag, setDefaultFlag ] = useState(''); 

    const [ currentCountryCode, setCurrentCountryCode ] = useState('');
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');

    const [ showMobileMsg, setShowMobileMsg] = useState(false);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        setShowMobileMsg('');
        let mobile_number = value.replace(' ','');
        if ( ! Utilities.isEmpty(mobile_number) && ! Utilities.contactNumberValidate( mobile_number, 1 ).status ){
            setShowMobileMsg( Utilities.contactNumberValidate( mobile_number, 1 ).msg);
        }

        props.pushValue( e, fieldName ?? '');
    }

    const focusDropdown = ( input_id ) => {
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( ! Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '' ) => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const changeCountryCode = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : '';
        setDefaultFlag(defaultFlagTemp);
        setCurrentCountryCode(passValues.phonecode);

        if ( typeof props.changeCountryCode!=='undefined' ){
            props.changeCountryCode(passValues, props.inputName ?? '');
        }
       
    }

    useEffect( () => {

        const { countryListProvided,  countryCodeProvided }  = props;
        if ( parseInt(countryListProvided.length)>0 ){
            setCountryList(countryListProvided);
            Object.entries(countryListProvided).map( ([key, row ]) => {
                if ( parseInt(countryCodeProvided)===parseInt(row.id) ) {
                    setDefaultFlag( row.country_short_code.toLowerCase());
                    setCurrentCountryCode(row.phonecode);
                }
            });
        }

    }, [props] );

    return (
        <>
            <div className="form-group active">
                <div className={`form-group-mobile ${ ( props.showDropdownArrow ?? false )===true ? 'with-arrow' : '' }`}>
                    <Dropdown className={`dropdown-country-selection ${ ( props.showDropdownArrow ?? false )===true ? 'show-arrow' : 'hide-arrow' } `}>
                        <Dropdown.Toggle>
                            <div className="flag-code" onClick={ () => focusDropdown(props.inputListingId ?? '') }>
                                <span className={`flag-icon flag-icon-${defaultFlag} mr-2`}></span>
                                <span>{ !Utilities.isEmpty(currentCountryCode) ? '+'+currentCountryCode: ''}</span>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                        <div className="px-4 mb-1">
                            <input type="text" id={props.inputListingId ?? ''} className="dropdown-search-input" placeholder="country name" autoComplete="new-password" alue={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, `${props.inputListingId ?? ''}-codes`, 'search-keyword') }></input>
                        </div>
                        <div id={`${props.inputListingId ?? ''}-codes`} className="country-code-list">
                        {
                            Object.entries(countryList).map( ([key, row] ) =>
                                <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCode(row) }>
                                    <div className="flag-code">
                                        <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                        <span>{ row.country_name }</span>
                                        <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                    </div>
                                </Dropdown.Item>
                            )
                        }
                        </div>
                        </Dropdown.Menu>
                    </Dropdown>
                
                    <input type="number" name={props.inputName ?? ''} className="form-control" placeholder=" " autoFocus={ props.autoFocus ?? false } value={props.inputValue} onChange={ (e) => pushValue( e, props.inputName ?? '') } autoComplete="off"  ></input>
                </div>
                {
                    (props.showPlaceholder ?? true)===true &&  <label className="form-label">{ props.inputLabel ?? '' }</label>
                }
               
            </div>

            {  ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(props.inputValue) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }
        </>
    )
}

export default MobileSelection;