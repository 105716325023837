import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import jQuery from 'jquery';
import firebase from 'firebase';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import Utilities from './helpers/Utilities';
import ApiUrls from './helpers/ApiUrls';
import UrlSlugs from './helpers/UrlSlugs';
import { updateAccountFCM } from './helpers/FCMNotificationUtilities';
import TitlesLabels from './helpers/TitlesLabels';

import { changeCityPopup as changeCityPopupAction, applyChangeLocation, newCityIDRequest as newCityIDRequestReducer, refershDashboadConversation as refershDashboadConversationReducer } from './actions';

import { askPermissionToReceiveNotifications } from './components/firebase/PushNotification';

import Header from './layout/Header';
import HeaderInternal from './layout/HeaderInternal';
import HeaderChat from './layout/HeaderChat';
import HeaderDepartment from './layout/HeaderDepartment';
import Footer from './layout/Footer';


// PUSH NOTIFICATION
import PushNotification from './components/firebase/NotificationItem';
import PushNotificationDivert from './components/firebase/NotificationDivert';


// COMPONENTS
import MainSearch from './components/MainSearch';
import ContactSearch from './components/ContactSearch';
import GoogleLanguage from './components/popups/GoogleLanguage';
import PreviewImage from './components/popups/PreviewImage';
import CityChange from './components/popups/CityChange';
import Qrcode from './components/popups/Qrcode';
import AutomatedReply from './components/popups/AutomatedReply';
import MenuPriceList from './components/popups/MenuPriceList';
import MenuPricePreview from './components/popups/MenuPricePreview';
import DocumentSend from './components/popups/DocumentSend';
import SettingProfilePopup from './components/popups/SettingProfilePopup';
import SettingDepartmentPopup from './components/popups/SettingDepartmentPopup';
import DepartmentDefaultMessage from './components/popups/DepartmentDefaultMessage';
import DepartmentSettingForms from './components/popups/DepartmentSettingForms';
import CompanyCategorySearch from './components/popups/CompanyCategorySearch';
import ChatTeamRestriction from './components/popups/ChatTeamRestriction';
import ChatCommunicationRequest from './components/popups/ChatCommunicationRequest';
import TermsConditions from './components/popups/TermsConditions';
import QuickLinks from './components/popups/QuickLinks';
import Videos from './components/popups/Videos';
import ConfirmationDeleteAccount from './components/popups/ConfirmationDeleteAccount';


// DOCUMENT
import ConversationProfiles from './pages/Documents/ConversationProfiles';
import DocumentList from './pages/Documents/DocumentList';

// PAGES
import Login from './pages/Login';
import Logout from './pages/Logout';

import PasswordNew from './pages/PasswordNew';

import Dashboard from './pages/Dashboard';
import InternalDashboard from './pages/InternalDashboard';
import DepartmentDashboard from './pages/DepartmentDashboard';

import ProfileForm from './pages/ProfileForm';
import ProfileFormDeletion from './pages/ProfileFormDeletion';

import LinkProfiles from './pages/LinkProfiles';
import LinkProfilesAssign from './pages/LinkProfilesAssign';
import Chat from './pages/Chat';
//import ChatGroups from './pages/ChatGroups';
import Departments from './pages/Departments';
import DepartmentsChatViewOnly from './pages/DepartmentsChatViewOnly';
import TeamMembers from './pages/TeamMembers';

import CompanyGroups from './pages/CompanyGroups';

import ManageDepartments from './pages/Departments/ManageDepartments';
import FormDepartment from './pages/Departments/FormDepartment';

import ManageTeam from './pages/Team/ManageTeam';
import FormTeam from './pages/Team/FormTeam';

import DirectAccess from './pages/DirectAccess';


// SETTINGS
import SettingCompanyForm from './pages/Settings/CompanyForm';
import SettingWorkForm from './pages/Settings/WorkForm';
import ShareQrcode from './pages/Settings/ShareQrcode';
import LinkedDepartments from './pages/Settings/LinkedDepartments';
import SpecialMessageInfo from './pages/Settings/SpecialMessageInfo';
import ImportantInformation from './pages/Settings/ImportantInformation';
import BizcomAssist from './pages/Settings/BizcomAssist';
import ProfileSecurity from './pages/Settings/Securities/ProfileSecurity';
import SpecialPermissions from './pages/Settings/SpecialPermissions';
import NotificationManagement from './pages/Settings/Notification/NotificationManagement';
import NotificationForm from './pages/Settings/Notification/NotificationForm';
import NotificationFollowers from './pages/Settings/Notification/NotificationFollowers';
import FollowersSupendRemove from './pages/Settings/Notification/FollowersSupendRemove';
import FollowersNotices from './pages/Settings/FollowersNotices';

import NotificationList from './pages/Settings/NotificationFollower/NotificationList';
import FollowSettings from './pages/Settings/NotificationFollower/FollowSettings';

import ManageCompanyGroups from './pages/Settings/ManageCompanyGroups';


// PAGES
//import Page from './pages/Page';
import NotFoundPage from './pages/NotFoundPage';
import SwitchFromPlus from './pages/SwitchFromPlus';


// SIGN UP
import AccountType from './signup/AccountType';
import PersonalInfo from './signup/PersonalInfo';
import PersonalCondition from './signup/PersonalCondition';
import PersonalLogin from './signup/PersonalLogin';
import AccountVerification from './signup/AccountVerification';
import PersonalUploadLogo from './signup/PersonalUploadLogo';
import SocialConnect from './signup/SocialConnect';

import CompanyInfo from './signup/CompanyInfo';
import CompanyKeywords from './signup/CompanyKeywords';
import CompanyCondition from './signup/CompanyCondition';
import CompanyAddress from './signup/CompanyAddress';
import CompanyLogo from './signup/CompanyLogo';
import CompanyTeamMember from './signup/CompanyTeamMember';

import CalendarDiary from './pages/CalendarDiary';
import LoginClean from './pages/LoginClean';
import QueryUtilities from './helpers/QueryUtilities';



function App() {

  const dispatch = useDispatch();

  const showFooter = useSelector( state => state.showFooter);
  const showHeader = useSelector( state => state.showHeader);
  const pageTitle = useSelector( state => state.pageTitle);
  const showLoader = useSelector( state => state.showLoader);
  const showMainSearch = useSelector( state => state.showMainSearch);
  const reloadProfile = useSelector( state => state.reloadProfile);
  const reloadAccountsDB = useSelector( state => state.reloadAccountsDB);
  const newCityIDRequest = useSelector( state => state.newCityIDRequest);
  const globalNotificationCounter = useSelector( state => state.globalNotificationCounter);
  const showPopupContactSearch = useSelector( state => state.showPopupContactSearch);
  
  const [ switchInternal , setSwitchInternal ] = useState(0);
  const [ popupContactSearch , setPopupContactSearch ] = useState({});
  const [ pageSiteTitle , setPageSiteTitle ] = useState(TitlesLabels.siteTitles.login);
  const [ availableLanguages, setAvailableLanguages ] = useState([]);

  const [ notificationItem , setNotificationItem ] = useState({});
  const [ notificationDivert , setNotificationDivert ] = useState({});
  const [ loginType , setLoginType ] = useState(0);
  const [ notificationCounter , setNotificationCounter ] = useState(0);

  
  
  const switchHeader = ( type  = 0) => {
      type = parseInt(type);
      let defaultHeader = '';

      if ( type===1 ){
        defaultHeader = <Header pageTitle={pageSiteTitle} reloadProfile={reloadProfile} switchInternal={switchInternal} reloadAccountsDB={reloadAccountsDB} />
      }else if ( type===2 ){
        defaultHeader = <HeaderInternal pageTitle={pageSiteTitle} />
      }else if ( type===3 || type===4 ){
        defaultHeader = <HeaderChat pageTitle={pageSiteTitle} headerType={type} />
      }else if ( type===5 ){
        defaultHeader = <HeaderDepartment pageTitle={pageSiteTitle} />
      }

      return defaultHeader;
  }


  const setupLocation = async () => {

    const responseGeoLocation = await axios.get(ApiUrls.externalIPgeolocationDB)
    await  axios
      .post( ApiUrls.citySearchByCityName, {...responseGeoLocation.data, keyword : responseGeoLocation.data.city } )
      .then( response => {
            if ( parseInt(response.status)===200 ){

              const location_detail = response.data.detail;
              if ( parseInt(response.data.status)===1 ){
                  sessionStorage.setItem(
                      process.env.REACT_APP_session_location,
                      JSON.stringify(location_detail)
                  );
              }
            }
      })
      .catch( (err) => {

      });

  }


  const setupUserLocation = ( city_id = 0 ) => {
      city_id = parseInt(city_id);
      if ( city_id>0 ){

          axios
          .post( ApiUrls.citySearchByCityId , { id : city_id }  )
          .then( response => {

            if ( parseInt(response.status)===200 ){

                const location_detail = response.data.detail;
                if ( parseInt(response.data.status)===1 ){

                    localStorage.setItem(
                      process.env.REACT_APP_user_location,
                      JSON.stringify(location_detail)
                    );

                    dispatch( applyChangeLocation(new Date) );
                }
              }
            
          })
          .catch( (err) => { });

      }
  }
  

  const checkingUserLocation = () => {

    const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
    let sesstionLoginType = JSON.parse(loginType);
    sesstionLoginType = parseInt(sesstionLoginType);
    
    if ( sesstionLoginType>0 ){

        let personalAccount = {};
        if ( sesstionLoginType===1 ){

            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
            accountsList = JSON.parse(accountsList);
            accountsList = Utilities.toNormalArrayObject(accountsList);
            personalAccount = accountsList.find(Boolean); 
            
        }else if ( sesstionLoginType===2 ){

            let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
            if ( companyDetail!==null ){
               personalAccount = JSON.parse(companyDetail);
            }
        }
        
        let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
        if ( userLocation!==null ){
            return false;
        }

        let session_location = sessionStorage.getItem( process.env.REACT_APP_session_location );
        if ( session_location!==null ){
            session_location = JSON.parse(session_location);
        }

        axios
        .post( ApiUrls.userLocationGetUserLocationById , { account_id : personalAccount.account_id }  )
        .then( response => {

            if ( parseInt(response.status)===200 ){
              const responseStatus = parseInt(response.data.status);
              if ( responseStatus===1 ){
                  const userLocationDetail = response.data.detail;
                  
                  if ( parseInt(userLocationDetail.permanent_status)===1 ){
                        // GETTING LOCATION DETAIL
                        setupUserLocation(userLocationDetail.city_id);

                  }else{
                      if ( userLocationDetail.current_location.toString().toLowerCase()!==session_location.city_name.toString().toLowerCase() ){
                          dispatch( changeCityPopupAction(Utilities.cityNewLocationLabel) );
                      }
                  }
                  
              }else if ( responseStatus===2 ){
                  dispatch( changeCityPopupAction(Utilities.cityNewLocationLabel) );
              }

            }
        })
        .catch( (err) => {

        });
    }
    
  }

  const updateLoginType = () => {
     
    // CHECK LOGIN TYPE
     const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
     const sesstionLoginType = JSON.parse(loginType);
 
     if ( sesstionLoginType !== null ){
       setLoginType( parseInt(sesstionLoginType) );
     }

  }
  
  useEffect( () => {
      setPageSiteTitle(pageTitle);

      let pageSlug = pageTitle.toLowerCase();
      pageSlug = pageSlug.replaceAll(' ', '-');

      if (  ['dashboard','department-dashboard'].includes(pageSlug)  && Utilities.isEmpty(newCityIDRequest) ){
        checkingUserLocation();
      }

      // CHECK LOGIN TYPE
      updateLoginType();

  }, [pageTitle]);


  useEffect( () => {
      if ( ! Utilities.isEmpty(newCityIDRequest) ){
          setupUserLocation(newCityIDRequest);
          dispatch( newCityIDRequestReducer('') );
      }
  }, [newCityIDRequest]);

  
  useEffect( () => {
      setupLocation();

      // CHECK LOGIN TYPE
      updateLoginType();

      const googleTranslateElementInit = async () => {

          new window.google.translate.TranslateElement({
                includedLanguages: Object.keys(Utilities.googleTranslateLanguages).join().toString(),
                layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
                autoDisplay: false },
                'google_translate_element'
          );

          jQuery('html,body').attr('style', '');

          const timer = setTimeout( () =>{ 

            let availableLanguagesTemp = []; 
            jQuery('#google_translate_element select.goog-te-combo > option').each(function (){
                const lang = jQuery(this).val();
                if ( !Utilities.isEmpty(lang) ){
                  const lang_name = jQuery(this).text();
                  availableLanguagesTemp[lang] = lang_name;
                }
            });

            setAvailableLanguages(availableLanguagesTemp);
            clearTimeout(timer);
          
          }, 1000 );
      
      }

      const addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit; 

  }, [] );


  useEffect( async () => {

    const timeUTC = moment.utc();

    let localStorageF_fcmToken = localStorage.getItem( process.env.REACT_APP_fs_fcm_token );
    localStorageF_fcmToken = JSON.parse(localStorageF_fcmToken) || '';

    let localStorage_loginType = localStorage.getItem( process.env.REACT_APP_session_logintype );
    localStorage_loginType = JSON.parse(localStorage_loginType) || 1;
    
    let accountDepartmentIds = [];
    if ( localStorage_loginType===1 ){

        let localStorage_sessionAccounts = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( localStorage_sessionAccounts!==null ){
            localStorage_sessionAccounts = JSON.parse(localStorage_sessionAccounts);

            Object.keys(localStorage_sessionAccounts).map( id => {
                accountDepartmentIds.push( id.toString()+':1' );
            });
        }
    }else if ( localStorage_loginType===2 ){

        let localStorage_departmentActive = localStorage.getItem(process.env.REACT_APP_session_department_active);
        if ( localStorage_departmentActive!==null ){
            localStorage_departmentActive = JSON.parse(localStorage_departmentActive);

            if ( localStorage_departmentActive.hasOwnProperty('group_id') ){
                accountDepartmentIds.push( localStorage_departmentActive.group_id.toString()+':2' );
            }
        }
    }

    //console.log(localStorage_loginType, accountDepartmentIds, accountDepartmentIds.length);

    const fcmToken = await askPermissionToReceiveNotifications();

    // UPDATE NOTIFICATION
    if ( ! Utilities.isEmpty(fcmToken) &&  Utilities.isEmpty(localStorageF_fcmToken) && accountDepartmentIds.length>0 ){

        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString(); 
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);
        console.log(dateFormatted, browserUniqueID);

        const passval = {
              accountids_logintypes : accountDepartmentIds.join(','),
              fcm_token : fcmToken.toString(),
              utc_date : dateFormatted.toString(),
              brower_id : browserUniqueID.toString(),
        };
        //console.log(passval);

        const loginHistoryResponse = await QueryUtilities.updateLoginHistoryFCM( passval );
        //console.log(loginHistoryResponse);
        if ( Object.keys(loginHistoryResponse).length>0 ){
            if ( parseInt(loginHistoryResponse.status ?? 0 )===1 ){
                localStorage.setItem(
                    process.env.REACT_APP_fs_fcm_token,
                    JSON.stringify(fcmToken)
                ); 
            }
        }

    }
    
    const messaging = firebase.messaging(); 
    
    messaging.onMessage((payload) => {
      if ( Object.entries(payload).length>0 ){
        
        const divertFlag = [13,14]; // 13- activate, 14- deactivated
        if ( payload.data.hasOwnProperty('notify_flag') &&  divertFlag.includes( parseInt(payload.data.notify_flag || 0) ) ){
          
          let segment = Utilities.getUrlSegments();
          if ( segment.length>0 ){
              if ( segment.shift().toString().toLowerCase()==='dashboard' ){
                 setNotificationDivert(payload);
              }
          }

          localStorage.setItem(
            process.env.REACT_APP_session_divert_notification,
            JSON.stringify(payload)
          );

        }else{
          setNotificationItem(payload);
          dispatch( refershDashboadConversationReducer( new Date() ) );
        }
      }
    
    }, e => {
        setNotificationItem({});
        setNotificationDivert({});
    });


  }, [] );

  useEffect(() => {

    let segment = Utilities.getUrlSegments()
    if ( segment.length>0 ){
        if ( segment.shift().toString().toLowerCase()==='dashboard' ){

          let divertNotificationDetail = localStorage.getItem(process.env.REACT_APP_session_divert_notification);
          if ( divertNotificationDetail!==null ){
              divertNotificationDetail = JSON.parse(divertNotificationDetail);
              setNotificationDivert(divertNotificationDetail);
          }

        }
    }


      const browerAgentNane = Utilities.getBrowerAgent();
      if ( browerAgentNane!=='safari' ){

          // IF FCM TOKEN CHANGED/UPDATED
          const messaging = firebase.messaging(); 
          messaging.onTokenRefresh(function () {
              messaging.getToken().then(function (newtoken) {
                  //console.log('Token refreshed.');
                  //console.log('Token refreshed: ', newtoken);
                  updateAccountFCM( newtoken );
              })
              .catch(function (reason) {

              });
          });
          
      }


  }, [window.location.pathname]);

  useEffect( () => {
    setNotificationCounter(globalNotificationCounter);
  }, [globalNotificationCounter] );
  

  useEffect( () => {
    setPopupContactSearch(showPopupContactSearch);
  }, [showPopupContactSearch] );

  
  return (
    
    <HelmetProvider> {/* TO remove 'Warning': Using UNSAFE_componentWillMount in strict mode recommended and may bugs in your code */}
      <Router>
          <Helmet>
            <title>{ `${ parseInt(notificationCounter || 0)>0 ? '('+notificationCounter+') ' : '' }${process.env.REACT_APP_site_title} | ${pageSiteTitle}` }</title>
          </Helmet>

            {  switchHeader( showHeader )  }

            <Switch>

                  <Route path="/" exact component={Login} />
                  <Route path={`/login-clean`} exact component={LoginClean} />
                  <Route path={`/${UrlSlugs.login}`} exact component={Login} />
                  <Route path={`/${UrlSlugs.login}/:id`} exact component={Login} />
                  <Route path={`/${UrlSlugs.logout}`} exact component={Logout} />

                  <Route path={`/${UrlSlugs.switchFromPlus}/:id/:deptid/:cityid`} exact component={SwitchFromPlus} />
                  <Route path={`/${UrlSlugs.switchFromPlus}/:id/:deptid/:cityid/:docid/:acctid`} exact component={SwitchFromPlus} />

                  
                  {/* FORGOT -> NEW PASSWORD */}
                  {/* <Route path={`/${UrlSlugs.pagePassword_new}`} exact component={PasswordNew} /> */}
                  <Route path={`/${UrlSlugs.pagePassword_new}/:id`} exact component={PasswordNew} />


                  {/* SIGN UP */}
                  <Route path={`/${UrlSlugs.signup_accountType}`} exact component={AccountType} />
                  <Route path={`/${UrlSlugs.signup_persoanlInfo}`} exact component={PersonalInfo} />
                  <Route path={`/${UrlSlugs.signup_personalCondition}`} exact component={PersonalCondition} />
                  <Route path={`/${UrlSlugs.signup_personalLogin}`} exact component={PersonalLogin} />
                  <Route path={`/${UrlSlugs.signup_verification}`} exact component={AccountVerification} />
                  <Route path={`/${UrlSlugs.signup_uploadLogo}`} exact component={PersonalUploadLogo} />
                  <Route path={`/${UrlSlugs.signup_socialConnect}`} exact component={SocialConnect} />

                  
                  {/* SIGN UP - COMPANY */}
                  <Route path={`/${UrlSlugs.signup_companyInformation}`} exact component={CompanyInfo} />
                  <Route path={`/${UrlSlugs.signup_companyKeywords}`} exact component={CompanyKeywords} />
                  <Route path={`/${UrlSlugs.signup_companyCondition}`} exact component={CompanyCondition} />
                  <Route path={`/${UrlSlugs.signup_companyAddress}`} exact component={CompanyAddress} />
                  <Route path={`/${UrlSlugs.signup_companyLogo}`} exact component={CompanyLogo} />
                  <Route path={`/${UrlSlugs.signup_teamMember}`} exact component={CompanyTeamMember} />
                  

                      {/*CALENDAR DIARY */}
                      <Route path={`/${UrlSlugs.calendarDiary}`} exact component={CalendarDiary} />
                      <Route path={`/${UrlSlugs.calendarDiary}/:rqdate`} exact component={CalendarDiary} />

                      
                      {/* CHAT PAGE */}
                      <Route path={`/${UrlSlugs.chatConversation}/:id/:coversid`} exact component={Chat} />

                      <Route path={`/${UrlSlugs.chat}/:id`} exact component={Chat} />
                      <Route path={`/${UrlSlugs.chat}/:id/:type`} exact component={Chat} />
                      <Route path={`/${UrlSlugs.chat}/:id/:type/:wid`} exact component={Chat} />
                      

                      {/* CHAT GROUPS PAGE */}
                      {/* <Route path={`/${UrlSlugs.chatGroupsConversation}/:cid/:coversid`} exact component={ChatGroups} /> */}
                      <Route path={`/${UrlSlugs.chatGroupsConversation}/:id/:coversid`} exact component={Chat} />

                      <Route path={`/${UrlSlugs.departmentDashboard}`} exact component={DepartmentDashboard} />
                      <Route path={`/${UrlSlugs.departmentDashboardDirectConnect}/:accountid`} exact component={DepartmentDashboard} />

                      <Route path={`/${UrlSlugs.dashboard}`} exact component={Dashboard} />
                      <Route path={`/${UrlSlugs.dashboardDirectConnect}/:accountid`} exact component={Dashboard} />

                      {
                        parseInt(loginType)===2 &&
                        [
                          //<Route key={1} path={`/${UrlSlugs.DepartmentDashboard}`} exact component={DepartmentDashboard} />,
                          <Route path={`/${UrlSlugs.settings_linkedDepartments}`} exact component={LinkedDepartments} />
                        ]
                      }
                     


                      <Route path={`/${UrlSlugs.settings_shareQrCode}`} exact component={ShareQrcode} />
                      <Route path={`/${UrlSlugs.settings_specialMessages}`} exact component={SpecialMessageInfo} />
                      <Route path={`/${UrlSlugs.settings_importantInformation}`} exact component={ImportantInformation} />
                      <Route path={`/${UrlSlugs.settings_bizcomAssist}`} exact component={BizcomAssist} /> 
                      <Route path={`/${UrlSlugs.settings_bizcomAssist_termsPrivacy}`} exact component={BizcomAssist} /> 
                      <Route path={`/${UrlSlugs.settings_bizcomAssist_licenses}`} exact component={BizcomAssist} /> 
                      <Route path={`/${UrlSlugs.settings_manageProfileSecurity}`} exact component={ProfileSecurity} /> 
                      

                      <Route path={`/${UrlSlugs.departments}/:id`} exact component={Departments} />
                      <Route path={`/${UrlSlugs.departments}/:id/:docid"`} exact component={Departments} />

                      <Route path={`/${UrlSlugs.teamMembers}/:id`} exact component={TeamMembers} />
                      {/* <Route path={`/${UrlSlugs.teamMembers}/:id/:docid`} exact component={TeamMembers} /> */}
                      <Route path={`/${UrlSlugs.teamMembers}/:id/:teamid`} exact component={TeamMembers} />

                      <Route path={`/${UrlSlugs.companyGroups}/:id`} exact component={CompanyGroups} />
                      <Route path={`/${UrlSlugs.companyGroups}/:id/:groupid`} exact component={CompanyGroups} />

                      <Route path={`/${UrlSlugs.directAccess}`} component={DirectAccess} />
                      <Route path={`/${UrlSlugs.directAccess}/:any`} component={DirectAccess} />

                      {
                        parseInt(loginType)===1 && 
                          [
                            <Route key={3} path={`/${UrlSlugs.internalChat}/:id`} exact component={InternalDashboard} />,

                            <Route key={4} path={`/${UrlSlugs.departments_chatViewOnly}/:id`} exact component={DepartmentsChatViewOnly} />,

                            //<Route key={5} path={`/${UrlSlugs.dashboard}`}"/dashboard" exact component={Dashboard} />,
                            <Route key={6} path={`/${UrlSlugs.dashboard}/:cityid`} exact component={Dashboard} />,


                            <Route key={7} path={`/${UrlSlugs.sendDocument}/:docid/:acctid`} exact component={ConversationProfiles} />,
                            <Route key={8} path={`/${UrlSlugs.documents}`} exact component={DocumentList} />,

                            
                            <Route key={9} path={`/${UrlSlugs.settings_manageProfile}`} exact component={ProfileForm} />,
                            <Route key={10} path={`/${UrlSlugs.settings_profileDeactivate}`} exact component={ProfileFormDeletion} />,

                            <Route key={11} path={`/${UrlSlugs.settings_linkedProfiles}`} exact component={LinkProfiles} />,
                            <Route key={12} path={`/${UrlSlugs.settings_linkedProfiles}/:id`} exact component={LinkProfilesAssign} />,
                            <Route key={13} path={`/${UrlSlugs.settings_divertProfiles_assign}/:id`} exact component={LinkProfilesAssign} />,


                            <Route key={14} path={`/${UrlSlugs.settings_manageDepartments}/:mid`} exact component={ManageDepartments} />,
                            <Route key={15} path={`/${UrlSlugs.settings_department_add}/:mid`} exact component={FormDepartment} />,
                            <Route key={16} path={`/${UrlSlugs.settings_department_edit}/:mid/:id`} exact component={FormDepartment} />,
                            

                            <Route key={17} path={`/${UrlSlugs.settings_manageTeam}/:mid`} exact component={ManageTeam} />,
                            <Route key={18} path={`/${UrlSlugs.settings_team_add}/:mid/:id`} exact component={FormTeam} />,
                            <Route key={19} path={`/${UrlSlugs.settings_team_edit}/:mid/:id`} exact component={FormTeam} />,
                            

                            <Route key={20} path={`/${UrlSlugs.settings_manageProfileCompany}/:mid`} exact component={SettingCompanyForm} />,
                            <Route key={21} path={`/${UrlSlugs.settings_manageWorkProfile}/:mid`} exact component={SettingWorkForm} />,

                            <Route key={22} path={`/${UrlSlugs.settings_specialPermissions}/:mid`} exact component={SpecialPermissions} />,

                            <Route key={23} path={`/${UrlSlugs.settings_followersNotices}`} exact component={FollowersNotices} />,
                            <Route key={24} path={`/${UrlSlugs.settings_notificationManagement}`} exact component={NotificationManagement} />,
                            <Route key={25} path={`/${UrlSlugs.settings_notificationSendNotification}`} exact component={NotificationForm} />,

                            <Route key={25} path={`/${UrlSlugs.settings_notificationFollowers}`} exact component={NotificationFollowers} />,
                            <Route key={26} path={`/${UrlSlugs.settings_notificationFollowersSuspend}`} exact component={FollowersSupendRemove} />,
                            <Route key={27} path={`/${UrlSlugs.settings_notificationFollowersRemove}`} exact component={FollowersSupendRemove} />,
                            
                            <Route key={28} path={`/${UrlSlugs.settings_notifications}`} exact component={NotificationList} />,
                            <Route key={29} path={`/${UrlSlugs.settings_notificationsFollowSettings}`} exact component={FollowSettings} />,

                            <Route key={30} path={`/${UrlSlugs.settings_manageCompanyGroups}/:mid`} exact component={ManageCompanyGroups} />

                          ]
                       }
                      

                  {/*  404 / NOT FOUND  */}
                  <Route path="*" component={NotFoundPage} />
                  

              </Switch>

            { showLoader===1 && <div className="main-loader show"><img src={ `${process.env.REACT_APP_img_folder}/loader.svg`} className="spinning-loader" /></div> }
            
            <GoogleLanguage availableLanguages={ availableLanguages } />
            
            <div id="google_translate_element" style={{ visibility: 'hidden', height :'0px', overflow :'hidden' }}></div>
            
            {  showHeader>=1 && <MainSearch showStatus={ showMainSearch } /> }

            {  showFooter===1  && <Footer /> }

            { ( Object.keys(popupContactSearch).length>0 && popupContactSearch.showPopup===true ) && <ContactSearch showPopupStatus={ popupContactSearch.showPopup } /> }
            
            <PreviewImage />

            <CityChange />

            <Qrcode />
            
            { parseInt(loginType)===1 && <AutomatedReply /> }
            
            <MenuPriceList />

            <MenuPricePreview />
            
            <DepartmentDefaultMessage />

            <DepartmentSettingForms />
            
            <DocumentSend />

            <PushNotification notificationItem={notificationItem} />

            <PushNotificationDivert notificationItem={notificationDivert} />
            
            { parseInt(loginType)===1 && <SettingProfilePopup /> }

            { parseInt(loginType)===2 && <SettingDepartmentPopup /> }
                      
            <CompanyCategorySearch />

            <ChatTeamRestriction />

            <ChatCommunicationRequest />

            <TermsConditions />

            <QuickLinks />

            <Videos />

            { parseInt(loginType)===1 && <ConfirmationDeleteAccount /> }

            
      </Router>
    </HelmetProvider>
  );
}

export default App;
