import Utilities from './Utilities';
import axios from 'axios';
import jQuery from 'jquery';
import moment from 'moment';
import ApiUrls from './ApiUrls';
import PagesAuthentication from './PagesAuthentication';


class ChatUtilities {

    siteTitle = process.env.REACT_APP_site_title
    
    sessionLastConversationMessage = process.env.REACT_APP_session_last_conversation_message

    departmentFormsTypes = [ 21, 22, 23, 24, 25 ]

    restrictionMessageTypesArray = [37,38,39]
    
    restrictionMessageTypesNamesArray = ['team_access_accepted', 'team_access_rejected']

    blockUnblocknMessageTypesNamesArray = ['block_contact', 'unblock_contact']

    communicationTypeNameArray = ['request_user_communication','request_user_communication_accepted','request_user_communication_rejected']

    allMessageTypesNamesArray = [
                                    'new_text_chat', 'block_contact', 'unblock_contact', 'team_invitation_received',
                                    'request_appoint_reserv', 'amend_appoint_reserv', 'accept_appoint_reserv', 'cancel_appoint_reserv', 'linked_team_profile',
                                    'unlinked_team_profile', 'team_invitation_approved', 'team_member_removed', 'bizchat_group_added', 'bizchat_group_removed',
                                    'group_member_add_remove', 'team_access_accepted', 'team_access_rejected' ]
    
    conversationLimit = 15
    
    globalCurrentConversationDetail = {}

    globalCompanyGroupsMemberSelected = []

    firebaseConfig = () => {
        /* const  firebaseConfig = {
            apiKey: "AIzaSyBrrJB1VIOvlAQtdsyhgSqXj3n2OAns_x8",
            authDomain: "bizcom-dac38.firebaseapp.com",
            databaseURL: "https://bizcom-dac38-default-rtdb.firebaseio.com",
            projectId: "bizcom-dac38",
            storageBucket: "bizcom-dac38.appspot.com",
            messagingSenderId: "949069925982",
            appId: "1:949069925982:web:3c9e7e110594863fe36ecf",
            measurementId: "G-EF92VE1DT4"
          }; */

         /*  const firebaseConfig = {
            apiKey: "AIzaSyAhctlL7RMZq8VVJPHRuU_WqfGg1Q3CbyU",
            authDomain: "jazenetbeonchat.firebaseapp.com",
            projectId: "jazenetbeonchat",
            storageBucket: "jazenetbeonchat.appspot.com",
            messagingSenderId: "902308838943",
            appId: "1:902308838943:web:fd89ebe49e53dd53581f2a",
            measurementId: "G-GGVTXBE7N6"
          }; */

        const  firebaseConfig = {
            apiKey: process.env.REACT_APP_fs_api_key,
            authDomain: process.env.REACT_APP_fs_auth_domain,
            //databaseURL: process.env.REACT_APP_fs_database_url,
            projectId: process.env.REACT_APP_fs_project_id,
            storageBucket: process.env.REACT_APP_fs_storage_bucket,
            messagingSenderId: process.env.REACT_APP_fs_messaging_senderid,
            appId: process.env.REACT_APP_fs_app_id,
            measurementId: process.env.REACT_APP_fs_measurement_id
        };
        

        return firebaseConfig;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    secondsToMinutes = (time) => {
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;
    
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    messageDeletedHtml = () => {
        return  '&#9865 <span class="message-deleted">this message was deleted</span>';
    }

     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    validURL = (s) => {
        var regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        return regexp.test(s);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    scrollBottomConversation = () => {
        let elem_wrap = jQuery('.chat-conversation--history');
        let scroll_allow = elem_wrap.attr('scroll');

        if ( parseInt(elem_wrap.length)>0 && scroll_allow==='true'){
            let get_height = elem_wrap[0].scrollHeight;
            elem_wrap.scrollTop(get_height);
        }
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    replaceURLs = (message) => {
        if(!message) return;
      
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.replace(urlRegex, function (url) {
          var hyperlink = url;
          if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
          }
          return `<a href="${hyperlink}" target="_blank" class="text-color-blue" rel="noopener noreferrer">${url}</a>`
        });
      }

     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    formTypes = () => {
        const formTypesArray = {
            21 : 'message-appointment',
            22 : 'message-reservation',
            23 : 'message-complaints',

            24 : 'message-appointment-accept',
            25 : 'message-reservation-accept',
            26 : 'message-complaints-accept',
           
            27 : 'message-appointment-reject',
            28 : 'message-reservation-reject',
            29 : 'message-complaints-reject'
        };

        return formTypesArray;
    }



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    encryptChatDetail = ( chatDetail, internal_chat = 0, account_detail = {} ) => {

        let encoded_array = [];
        internal_chat = parseInt(internal_chat);

        let convers_status_flag = chatDetail.convers_status_flag ?? "0";

        encoded_array.push( chatDetail.convers_id.toString() );
        encoded_array.push( convers_status_flag.toString() );

        /* if (  parseInt(chatDetail.department_status_flag)===1 ){
            encoded_array.push( chatDetail.department_id.toString() );
        }else{
            encoded_array.push( chatDetail.department_status_flag.toString() );
        } */

        if ( chatDetail.hasOwnProperty('department_name') ){
            if ( chatDetail.department_name==='' ){
                //encoded_array.push( chatDetail.department_status_flag.toString() );
                encoded_array.push('0');
            }else{
                encoded_array.push( chatDetail.department_id.toString() );
            }
        }else{
            encoded_array.push('0');
        }
        

        if ( parseInt(internal_chat)===1  && Object.keys(account_detail).length>0 ){
            encoded_array.push( account_detail.account_id.toString() );
        }

        return btoa( encoded_array.join('-').toString() );
    }

     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    decryptChatDetail = ( encryptedChatDetail ) => {
        let tempValuesArray = atob(encryptedChatDetail).toString().split('-');
        
        let tempValues = {
            convers_id : tempValuesArray[0].toString(),
            convers_status_flag : tempValuesArray[1].toString(),
            department_status_flag : tempValuesArray[2].toString(),
            internal_account_id :  tempValuesArray.length>3 ?  tempValuesArray[3].toString() : 0
        }

        return tempValues;
    }

     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
     qrcodeMessageHtml = ( messageText ) => {
        
        let htmlReturn = '';
        if ( messageText!='' ){
            const messageArray = messageText.split('|');
            htmlReturn = `<div class="profile-qrcode-message notranslate">
                               <div class="profile-logo" style="background-image:url('${messageArray[3]}')"></div>
                               <div class="profile-name">${messageArray[1]}</div>
                               <div class="profile-title">${messageArray[2]}</div>
                               <div class="profile-qrcode"><img src="${messageArray[4]}" width="100" /></div>
                        </div>`; 
        }
         
        return htmlReturn;
     }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    welcomeMessageHtml = ( messageData = {} ) => {
        const SELF = this;

        let user_fullname = '';
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                user_fullname = profileLoggedinTemp.name;
                
                if ( messageData.hasOwnProperty('account_id') ){
                    if ( parseInt(messageData.account_id)!==parseInt(profileLoggedinTemp.account_id) ){
                        user_fullname = jQuery('.chat-conversation-header .profile-name-wrap .profile-name').text();
                    }
                }
            }
        }


        const htmlFormatted = `<div class="welcome-chat text-lowercase">
                                <p>hi ${user_fullname}</p>
                                <p>welcome to ${SELF.siteTitle}.</p>
                                <p>we have designed ${SELF.siteTitle} as a chat app to facilitate greater ease of communication between customers/end users and businesses/service providers.</p>
                                <p>Please use this chat to communicate with us at anytime and provide any constructive suggestions you have. It is this feedback that would enable us to enhance the quality of our service to you and ensure an ongoing improved service.</p>
                                <p>Thank you and welcome again<br /><br /><span class="text-uppercase">${SELF.siteTitle}</span> team</p>
                            </div>`;

        return htmlFormatted;
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    invitationMessageHtml = ( current_message ) => {

        let user_fullname = '';
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                user_fullname = profileLoggedinTemp.name;

                if ( parseInt(profileLoggedinTemp.account_type)===4 ){
                    user_fullname = jQuery('.main-header-chat .profile-header .profile-name-wrap > .profile-name').text();
                }
            }
        }


        const current_message_arr = current_message.split('|');

        const htmlFormatted = `<div class="invitation-chat">
                                <p>hi ${user_fullname} !</p>
                                <p>${current_message_arr[0]} of <span class="font-gotham-medium-12">${current_message_arr[1]}</span> has sent you an invitation to<br />join the team and be added as a team member on our company profile.</p>
                            </div>`;

        return htmlFormatted;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    acceptRejectMessageHtml = ( current_message, type = 0 ) => {

        let action_label = 'i am happy to accept';
        if ( type===15 ){ action_label = 'i regretfully reject the invitation.'}


        const htmlFormatted = `<div class="invitation-chat">
                                <p>hi ${current_message} !</p>
                                <p>thank you for the team member invitation.</p>
                                <p>${action_label}</p>
                            </div>`;

        return htmlFormatted;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getConversationStart = ( convers_id , start_doc_id ) => {

        let startAt = 0;
        let sessionConversationStart = sessionStorage.getItem(process.env.REACT_APP_session_convers_start_ids);

        if ( sessionConversationStart!==null ){
            sessionConversationStart = JSON.parse(sessionConversationStart);
            if ( Object.keys(sessionConversationStart).length>0 ){
               
               if (typeof sessionConversationStart[convers_id.toString()] !== "undefined") {
                    startAt = sessionConversationStart[convers_id.toString()];
                }
            }
        }

        return startAt;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    saveConversationStart = ( convers_id , start_doc_id ) => {

        let sessionConversationStart = sessionStorage.getItem(process.env.REACT_APP_session_convers_start_ids);

       if ( sessionConversationStart!==null ){
            sessionConversationStart = JSON.parse(sessionConversationStart);
           if ( Object.keys(sessionConversationStart).length>0 ){
               
               let valueArray = sessionConversationStart;
               if (typeof sessionConversationStart[convers_id.toString()] === "undefined") {
                    valueArray[convers_id.toString()] = start_doc_id;

                    sessionStorage.setItem(
                        process.env.REACT_APP_session_convers_start_ids,
                        JSON.stringify(valueArray)
                    );
                }
           }
       }else{

           let valueArray = {};
           valueArray[convers_id.toString()] = start_doc_id;

           sessionStorage.setItem(
            process.env.REACT_APP_session_convers_start_ids,
            JSON.stringify(valueArray)
        );
       }

    }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
     getAccountConversationDetail = ( conversId ) => {

        let conversationDetail = {};
        let sessionConversationDetail = sessionStorage.getItem(process.env.REACT_APP_session_account_conversations_detail);

        if ( sessionConversationDetail!==null ){
            sessionConversationDetail = JSON.parse(sessionConversationDetail);
            if ( Object.keys(sessionConversationDetail).length>0 ){
                
               if ( conversId.toString() in  sessionConversationDetail ){
                conversationDetail = sessionConversationDetail[conversId.toString()];
               }
            }
        }

        return conversationDetail;

     }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    saveAccountsConversationDetail = ( profileChat, type = '0' ) => {
        //console.log('updating convers...: '+type.toString());
         let sessionConversationDetail = sessionStorage.getItem(process.env.REACT_APP_session_account_conversations_detail);

        if ( sessionConversationDetail!==null ){
            sessionConversationDetail = JSON.parse(sessionConversationDetail);
            if ( Object.keys(sessionConversationDetail).length>0 ){
                
                if ( typeof profileChat.convers_id!=='undefined' ){
                    let valueArray = sessionConversationDetail;
                    valueArray[profileChat.convers_id.toString()] = profileChat;

                    sessionStorage.setItem(
                        process.env.REACT_APP_session_account_conversations_detail,
                        JSON.stringify(valueArray)
                    );
                }
            }
        }else{

            let valueArray = {};
            valueArray[profileChat.convers_id.toString()] = profileChat;

            sessionStorage.setItem(
                process.env.REACT_APP_session_account_conversations_detail,
                JSON.stringify(valueArray)
            );
        }

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getMessages = ( FIRESTORE, CONVERSATION_LIST , INITIALIZE = 1,  LASTID = 0 ) => {

        const SELF = this;
        
        INITIALIZE = parseInt(INITIALIZE);
        let CONVERSID = CONVERSATION_LIST.convers_id.toString();
        const OWNERID = parseInt(CONVERSATION_LIST.owner_account_id);
        
        if ( parseInt(CONVERSID)>0 ){

            const FS_COLLECTION = process.env.REACT_APP_firebase_collection_name.toString();
            const FS_DOCUMENT = process.env.REACT_APP_firebase_document_name.toString();
            CONVERSID = CONVERSID.toString();

            let conversationTemp = [];
            const dbCollection = FIRESTORE.collection(FS_COLLECTION).doc(FS_DOCUMENT).collection(CONVERSID); 

            let firstQuery = '';
            /* if ( INITIALIZE===1 ){
                firstQuery = dbCollection.orderBy("date", "desc").limit(10);
            }else{
                firstQuery = dbCollection.orderBy("date", "desc").limit(1);
            } */

            firstQuery = dbCollection.orderBy("date", "desc");
            
            const unsubscribe = firstQuery.onSnapshot(function(snapshot) {

                const messages_list_reverse = snapshot.docChanges().reverse();
                messages_list_reverse.forEach(function(query) {

                    const messages_data = query.doc.data();
                    const formattedMessage = SELF.formatMessageHTML(OWNERID, messages_data);
                    conversationTemp.push(formattedMessage);

                    SELF.scrollBottomConversation();
                    
                });

                if ( parseInt(LASTID)===0){

                    if ( INITIALIZE===1){
                        jQuery('#chat-conversation--history-messages').html( conversationTemp.join('').toString() );
                    }else{
                        jQuery('#chat-conversation--history-messages').append( conversationTemp.join('').toString() );
                    }
                    
                }else{
                    jQuery('#chat-conversation--history-messages').append( conversationTemp.join('').toString() );
                }
                
                SELF.scrollBottomConversation();

            });

            
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    removeDuplicateMessages = () => {
        jQuery('#chat-conversation--history-messages > li').each(function (){

            const liId = jQuery(this).attr('id');
            const liCounter = parseInt(jQuery('#chat-conversation--history-messages > li#'+liId).length);
            if ( liCounter>1 ){
                jQuery('#chat-conversation--history-messages > li#'+liId)[0].remove();
            }

        });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    deleteMesage = (  FIRESTORE, convers_id, messageDetail, deleteInFirebse = 0  ) => {
        deleteInFirebse = parseInt(deleteInFirebse);

        const SELF = this;

        const FS_COLLECTION = process.env.REACT_APP_firebase_collection_name.toString();
        const FS_DOCUMENT = process.env.REACT_APP_firebase_document_name.toString();


        const delete_message_html = SELF.messageDeletedHtml();
        const doc_id = messageDetail.doc_id;

        if ( deleteInFirebse===1 ){
            FIRESTORE.collection(FS_COLLECTION).doc(FS_DOCUMENT).collection(convers_id.toString()).doc(doc_id.toString()).delete();
            
        }else{
            const $li_elem = jQuery('#chat-conversation--history-messages > li#'+doc_id);
            
            const FS_MESSAGE = FIRESTORE.collection(FS_COLLECTION).doc(FS_DOCUMENT).collection(convers_id.toString()).doc(doc_id.toString());

            FS_MESSAGE.get().then(function(doc) {
                if (doc.exists) {

                    let current_message = doc.data();
                    current_message['message'] = delete_message_html;
                    current_message['message_type'] = 0;

                    FS_MESSAGE.update(current_message);

                    jQuery('#chat-conversation--history-messages > li').removeClass('selected');

                    //$li_elem.remove();
                    $li_elem.removeClass('selected');
                    $li_elem.find('.btn-message-option').remove();
                    $li_elem.find('.message-reply-wrap').remove();
                    $li_elem.find('.message-item-content').html(delete_message_html);

                    setTimeout( () => {
                        SELF.removeDuplicateMessages()
                        
                    }, 500);
                
                
                }
            }).catch(function(error) {
            
            }); 
        }


        // SHOULE DELETE THE FILE FROM THE SERVER
        // const media_type_array = ['2','3','4'];
    }
    

     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
     sendMessageBoadcast = ( SENDER, CONVERSID,  messageType, messageText, extraMessage = {} ) => {

        if ( parseInt(CONVERSID)>0 ){

            //const API_URL = process.env.REACT_APP_chat_api_url;
            const TOKEN = process.env.REACT_APP_chat_api_token;
            const errorMsg = process.env.REACT_APP_message_error_process;

            const now = new Date();
            const utc_timestamp =  now.getTime();

            const utc_timezone = now.toUTCString();
            const utc_created_time = moment.utc(utc_timezone).format(Utilities.dateTimeServerStr.toString());

            let SENDER_ID = SENDER.account_id.toString();
            messageText = messageText.toString();
            
            let message_data = {
                api_token : TOKEN.toString(),
                convers_id : CONVERSID.toString(),
                account_id: SENDER_ID,
                message: messageText,
                message_type: messageType.toString(),
                date: utc_timestamp.toString(),
                created_time: utc_created_time.toString(),
            };

            let file_length = '';
            if ( parseInt(messageType)===3 && extraMessage.hasOwnProperty('duration') ){
                file_length = extraMessage.duration.toString();
            }

            message_data['file_length'] = file_length.toString();

            jQuery('#chat-main-container').addClass('content-disabled');

            axios
            .post( ApiUrls.bizchatSendBroadcastMessage ,  message_data )
            .then( response => {

                jQuery('#chat-main-container').removeClass('content-disabled');

                if ( parseInt(response.status)===201 ){
                    if ( parseInt(response.data.success)===1 ){
                        //Utilities.messagePopup('success', response.data.successMessage );
                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                }
  
            })
            .catch( (err) => {
                jQuery('#chat-main-container').removeClass('content-disabled');
                Utilities.messagePopup('error', errorMsg );
            });
        }
        
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    sendMessage = (  FIRESTORE, CONVERSATION_LIST, SENDER, RECEIVER, messageType, messageText, replyMessageDetail = {}, extraMessage = {} ) => {

        const SELF = this;

        let lasConversationMessageContent = {};
        const lastConversationMessage = sessionStorage.getItem(SELF.sessionLastConversationMessage);
        if ( typeof lastConversationMessage!=='undefined' ){
            lasConversationMessageContent = JSON.parse(lastConversationMessage);
        }
       
        lasConversationMessageContent = lasConversationMessageContent ?? {};

        let CONVERSID = CONVERSATION_LIST.convers_id.toString();
        let CONVERSFLAG_STATUS = CONVERSATION_LIST.convers_status_flag.toString();
        let DEPTFLAG_STATUS = CONVERSATION_LIST.department_status_flag.toString();
        
        if ( parseInt(CONVERSID)>0 ){


            let FLAG_STATUS = 0;
            const currentLoginUserDetail = PagesAuthentication.getUserLoggedinDetail();
            const currentConversationDetail = SELF.getAccountConversationDetail( CONVERSID );

            if ( currentConversationDetail.hasOwnProperty('flag') ){
                FLAG_STATUS = currentConversationDetail.flag;
            }


            if ( [2,6].includes(parseInt(FLAG_STATUS)) && Object.entries(lasConversationMessageContent).length>0 ){

                if ( lasConversationMessageContent.hasOwnProperty('members_list') ){
                    const memberListArray = lasConversationMessageContent.members_list.split(',');
                    
                    if ( ! ( memberListArray.includes(SENDER.account_id.toString()) ) ){

                        Utilities.messagePopup('warning', Utilities.labelCouldNoSendNotMemberGroup.toString() );
                        jQuery('.chat-compose-wrap').addClass('d-none');

                        let currentConversationDetailTemp = {  ...currentConversationDetail, access_status : '0' };
                        SELF.saveAccountsConversationDetail(currentConversationDetailTemp, 9);

                        return false;
                    }

                }
            }

           
            if ( currentConversationDetail.hasOwnProperty('department_status_flag') ){
                DEPTFLAG_STATUS = currentConversationDetail.department_status_flag.toString();
            }
            
            if ( parseInt(FLAG_STATUS)===5 ){
                //Utilities.messagePopup('error', 'We are still working on it.' );
                SELF.sendMessageBoadcast( SENDER, CONVERSID,  messageType, messageText, extraMessage = {} );
            
            }else{

                    const FS_COLLECTION = process.env.REACT_APP_firebase_collection_name.toString();
                    const FS_DOCUMENT = process.env.REACT_APP_firebase_document_name.toString();

                    const FS_NOTIFICATION = process.env.REACT_APP_firebase_notification_collection_name.toString();

                    const now = new Date();
                    //const utc_timestamp = new Date( now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds() ).getTime();
                    const utc_timestamp =  now.getTime();

                    const utc_timezone = now.toUTCString();
                    const utc_created_time = moment.utc(utc_timezone).format(Utilities.dateTimeServerStr.toString());
                    
                    
                    let SENDER_ID = SENDER.account_id.toString();
                    let SENDER_TYPE = SENDER.account_type.toString();

                    let RECEIVER_ID = RECEIVER.account_id.toString();
                    let RECEIVER_TYPE = RECEIVER.account_type.toString();

                    let reply_quote_details = null;
                    let reply_message_status = 0;

                    
                    if ( Object.keys(replyMessageDetail).length>0 ){

                        reply_quote_details = {
                            account_id : replyMessageDetail.account_id.toString(),
                            created_time : replyMessageDetail.created_time.toString(),
                            date : parseInt(replyMessageDetail.date),
                            message : replyMessageDetail.message.toString(),
                            message_type : parseInt(replyMessageDetail.message_type)
                        };

                        reply_message_status = 1;
                    }

                    messageText = messageText.toString();
                    
                    let message_data = {
                        account_id: SENDER_ID,
                        created_time: utc_created_time.toString(),
                        date: utc_timestamp,
                        message: messageText,
                        message_type: parseInt(messageType),
                        read_status: 0,
                        reply_message_status: parseInt(reply_message_status),
                        reply_quote_details: reply_quote_details,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
                    };

                    if ( [2].includes(parseInt(FLAG_STATUS)) ){
    
                        if ( currentConversationDetail.hasOwnProperty('group_members') ){
                            message_data = {...message_data, members_list : currentConversationDetail.group_members.toString() }
                        }
                        
                        if ( currentConversationDetail.hasOwnProperty('group_members_list') ){
                            let groupMembersList = currentConversationDetail.group_members_list;
                        
                            let senderProfile = groupMembersList.filter( person => parseInt(person.account_id) === parseInt(message_data.account_id))[0];
                            let senderLogo = '', senderName = '';

                            if (typeof senderProfile!=='undefined'){
                                senderName = senderProfile.name;
                                senderLogo = senderProfile.logo;
                            }

                            message_data = {...message_data, account_name : senderName.toString()+'|'+senderLogo.toString() }
                        }
                    }

                    // COMPANY GROUPS ////////////////////////////////////////////////////
                    if ( [6].includes(parseInt(FLAG_STATUS)) ){

                        const groupTeamMembers = [];
                        
                        if ( currentConversationDetail.hasOwnProperty('group_team_members') ){
                            groupTeamMembers.push(currentConversationDetail.group_team_members.toString());
                        }
                        

                        if ( SELF.globalCompanyGroupsMemberSelected.length===0){
                            if ( currentConversationDetail.hasOwnProperty('group_members') ){
                                groupTeamMembers.push(currentConversationDetail.group_members.toString());
                            }
                        }else{
                            groupTeamMembers.push(SELF.globalCompanyGroupsMemberSelected.join(",").toString());
                        }
                        
                        if ( groupTeamMembers.length>0 ){
                            message_data = {...message_data, members_list : groupTeamMembers.join(",").toString() }
                        }
                    
                        message_data = {...message_data, account_name : currentLoginUserDetail.name.toString()+'|'+currentLoginUserDetail.logo_url.toString() }

                       
                        
                    }

                    // END COMPANY GROUPS ////////////////////////////////////////////////////
                    
                     // RESET Members
                    SELF.globalCompanyGroupsMemberSelected = [];

                    
                    let file_length = '';
                    if ( parseInt(messageType)===3 && extraMessage.hasOwnProperty('duration') ){
                        file_length = extraMessage.duration.toString();
                    }

                    message_data['file_length'] = file_length;


                    FIRESTORE.collection(FS_COLLECTION).doc(FS_DOCUMENT).collection(CONVERSID).doc(utc_timestamp.toString()).set(message_data);

                    if ( parseInt(messageType)===16 ){
                        messageText = 'qrcode';
                    }

                    let noti_message_data_array = [];
                    noti_message_data_array.push(SENDER_ID);
                    noti_message_data_array.push(SENDER_TYPE);

                    noti_message_data_array.push(RECEIVER_ID);
                    noti_message_data_array.push(RECEIVER_TYPE);

                    noti_message_data_array.push(messageType.toString());
                    noti_message_data_array.push(messageText);

                    noti_message_data_array.push(CONVERSFLAG_STATUS);
                    noti_message_data_array.push(DEPTFLAG_STATUS);

                    noti_message_data_array.push(FLAG_STATUS.toString());

                    const noti_message_data = {
                        [utc_timestamp] : noti_message_data_array.join('|').toString()
                    }


                    FIRESTORE.collection(FS_NOTIFICATION).doc(CONVERSID).set(noti_message_data); 

                    let timer = setTimeout( () => {
                        SELF.scrollBottomConversation();
                        clearTimeout(timer);
                    }, 50 );
            
            }

            
            // CHECK AUTO REPLY
            if ( currentConversationDetail.hasOwnProperty('auto_replay_status') && parseInt(messageType)!==35){
                
                let timerReply = setTimeout( () => {
                    const auto_replay_status = currentConversationDetail.auto_replay_status;

                    const replay_status = parseInt(auto_replay_status.replay_status);
                    const replay_message = auto_replay_status.replay_message.toString();

                    if ( replay_status===1 ){
                        SELF.sendMessage( FIRESTORE, CONVERSATION_LIST,  RECEIVER,  SENDER , messageType = 35, replay_message,  replyMessageDetail = {} , extraMessage = {}  );
                    }
                    clearTimeout(timerReply);
                }, 800 );

               
            }
        }
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //  0 = delete, 1= text, 2 = image, 3 = voice, 4= video, 5= docs, 6 = pdf, 7 other application, 8 = links, 9 = location
    formatMessageHTML = ( ownerID, messageData, output_array = 0, loginAccount = {}  ) => {
        
        const SELF = this;
        const gCurrentConversDetail = SELF.globalCurrentConversationDetail;
        const gCurrentConversFlag = parseInt(gCurrentConversDetail.flag ?? 0);
        //const gCurrentConversAccessStatus = parseInt(gCurrentConversDetail.access_status ?? 0);

        ownerID = parseInt(ownerID);
        const mainMessage = ( messageData.message!==null ) ? messageData.message.toString() : '';
        const messageType = parseInt(messageData.message_type);
        const messageOwner = parseInt(messageData.account_id);
        const readStatus = parseInt(messageData.read_status);
        //const readReplStatus = parseInt(messageData.reply_message_status);


        //console.log(SELF.globalCurrentConversationDetail);

        let htmlMessage = '';

        if ( messageType===0 ){
            htmlMessage = SELF.messageDeletedHtml();

        }else if ( messageType===1 ){
            htmlMessage = SELF.replaceURLs(mainMessage);
            
            htmlMessage = htmlMessage.split('\n');
            htmlMessage = htmlMessage.map( text => Utilities.isEmpty(text) ? '<br />' : '<div>'+text+'</div>' );
            htmlMessage = htmlMessage.join('');

        }else if ( messageType===2 ){
            const img_html = `<img src="${mainMessage}" width="200" >`;
            htmlMessage = `<div>${img_html}</div>`;

        }else if ( messageType===3 ){

            let file_ext = messageData.message.split('.').pop();
            file_ext = file_ext.toLowerCase();
            
            let docname = mainMessage.match(/.*\/(.+?)\./);
            if (docname && docname.length > 1) {
                docname = docname[1];
            }

            docname = docname + '.' + file_ext;
            htmlMessage =   `<div>
                                <audio controls >
                                   <source src="${mainMessage}" type="audio/mp3">
                               </audio>
                            </div>`;  

        }else if ( messageType=== 4  ){

            let file_ext = messageData.message.split('.').pop();
            file_ext = file_ext.toLowerCase();

            let docname = mainMessage.match(/.*\/(.+?)\./);
            if (docname && docname.length > 1) {
                docname = docname[1];
            }

            docname = docname + '.' + file_ext;
            htmlMessage =   `<div>
                               <video width="300" height="100%" controls >
                                   <source src="${mainMessage}" type="video/mp4">
                               </video>
                               <div><a href="${mainMessage}" target="_blank">${docname.toString()}</a></div>
                            </div>`;

                            
        }else if (messageType === 5 || messageType === 6 || messageType === 7) {

            let class_attachment = '';
            
            let file_ext = messageData.message.split('.').pop();
            file_ext = file_ext.toLowerCase();

            let docname = mainMessage.match(/.*\/(.+?)\./);
            if (docname && docname.length > 1) { docname = docname[1]; }

            docname = docname + '.' + file_ext;

            htmlMessage = `<div>
                            <a class="attachment link attachment-file-${file_ext.toString().toLowerCase()} pl-0 ${class_attachment.toString()}" href="${mainMessage}" target="_blank">${docname.toString()}</a>
                        </div>`;
        
       
        }else if ( messageType===8  ){

            let message_request = mainMessage;
            if ( SELF.validURL( message_request )){

                let message_array = message_request.split("/");
                message_array = message_array.filter(Boolean);
               
                let url = '';
                let isYoutube = false;
                
                if ( message_array.includes('youtu.be') ){
                    url = message_array[2];
                    isYoutube = true;

                }else{

                    message_array = message_request.split('.');
                    if ( message_array.includes('youtube') ){
                        const url_array = message_request.split('=');
                        url = url_array[1];
                        isYoutube = true;
                    }
                }

                if( isYoutube===true && url!=='' ) {
                    htmlMessage = `<div>
                                        <iframe width="300" height="100%" src="https://www.youtube.com/embed/${url.toString()}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        <div><a href="${message_request.toString()}" class="link" target="_blank">${message_request.toString()}</a></div>
                                    </div>`;

                }else{
                    htmlMessage = `<div><a class="attachment link link-message"  href="${mainMessage}" target="_blank">${message_request}</a></div>`;
                }
            }
            
            
        }else if ( messageType===9 ){

            let mapUrl =`https://maps.google.com/?q=${mainMessage}`;
            htmlMessage = `<iframe width="100%" height="180"  class="doc-icon" src="${mapUrl}&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>`;

        }else if ( messageType===10 ){
            htmlMessage = '';//mainMessage;//we are now connected';

        }else if ( messageType===11 ){
            htmlMessage = SELF.welcomeMessageHtml(messageData);

        }else if ( messageType===12 || messageType===13 ){
            htmlMessage = SELF.invitationMessageHtml(mainMessage);

        }else if ( messageType===14 || messageType===15 ){
            htmlMessage = SELF.acceptRejectMessageHtml(mainMessage, messageType);
            
            if ( gCurrentConversFlag===6 ){
                htmlMessage = mainMessage;
            }
            
        }else if ( messageType===16 ){
            htmlMessage = SELF.qrcodeMessageHtml(mainMessage);

            
        }else if ( SELF.departmentFormsTypes.includes(messageType) && [6].includes(gCurrentConversFlag) ){

            let actionLabel = '';
            if ( messageType===21 ){
                actionLabel = 'awaiting permission';
            }

            htmlMessage = actionLabel;


        //}else if ( messageType===21 || messageType===22 || messageType===23 || messageType===24 || messageType===25){ // REQUEST
        }else if ( SELF.departmentFormsTypes.includes(messageType) && ![6].includes(gCurrentConversFlag) ){

            const msgFormArray = mainMessage.toString().split('|'); //[ type, detail-json ]
  
            let request_number = '';
            if ( typeof msgFormArray[1]!=='undefined'){
                const messageInfoArray = JSON.parse(msgFormArray[1]);
                if ( typeof messageInfoArray==='object'   ){
                    request_number = messageInfoArray.request_no.toString();
                }
            }
            
            let actionLabel = '';

            if ( messageType===21 ){
                actionLabel = 'request';

                if ( parseInt(msgFormArray[0])===3){
                    //actionLabel = ownerID===messageOwner ? 'submitted' : 'received';
                    request_number = '';
                }

            }else if ( messageType===22 ){
                actionLabel = 'amendment';

            }else if ( messageType===23 ){
                actionLabel = 'accepted';

            }else if ( messageType===24 ){
                actionLabel = 'cancellation';

            }else if ( messageType===25 ){
                actionLabel = 'replied';
            }

            actionLabel += ownerID===messageOwner ? ' sent' : ' received';

            request_number = Utilities.isEmpty(request_number) ? '' : `no. ${request_number}`;
            
            htmlMessage = `<div class="font-gotham-light-12">${Utilities.viewFormLabel(msgFormArray[0])} ${request_number}</div> ${actionLabel}`;

        }else{
            htmlMessage = mainMessage;
        }
        

        let htmlFormatted = '';
        let htmlFormattedArray = {};
        let message_time = '';

        if ( htmlMessage!=='' ){

            let local_time_html = '<div class="message-time"><span></span></div>';

            if ( messageData.hasOwnProperty('date') &&  messageData.date!=='' &&  typeof messageData.date!=='undefined' && messageData.date!==null ){
                const localTime  = moment.utc(messageData.date);  
                let local_date = moment(new Date(localTime)).format(Utilities.timeFormatStr.toString()).toLocaleString(); 

                message_time = local_date;
            }
            
            
            const welcomeClass = ( messageType===10 ) ? ' message-welcome ' : '';
            let otherClass = ( messageOwner!==ownerID ) ? ' other ' : '';
            //let otherClass = ( messageOwner!==parseInt(ownerID) ) ? ' other ' : '';
            let readClass = ( readStatus===1 ) ? ' read ' : '';
            
            if ( messageType===10 ) { otherClass = ''; }
            if ( messageType===11 ) { otherClass = ' other '; }

            if ( Object.keys(loginAccount).length>0 ){
                if ( parseInt(loginAccount.account_type)===4 ){
                    if ( messageOwner===parseInt(loginAccount.team_company_id) ){
                        otherClass = '';
                    }
                }
            }

            htmlFormatted = `<li class="message-item ${otherClass} ${readClass}">
                               <div class="message-wrap ${welcomeClass}">
                                   <div>${htmlMessage}</div> ${local_time_html}
                                </div>
                            </li>`;

            if ( output_array===1 ){
                htmlFormattedArray = {
                    message_time : message_time,
                    html_message : htmlMessage,
                    class_welcome : welcomeClass,
                    class_names : otherClass+readClass
                }
            }
        }
        
        if ( output_array===1 ){
            return htmlFormattedArray;
        }else{
            return htmlFormatted;
        }
       
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    markMessagesRead = async ( account_id , convers_id ) =>{

        if ( parseInt(account_id)>0 && parseInt(convers_id)>0){

            const currentConversationDetailTemp = this.getAccountConversationDetail( convers_id );

            //const API_URL = process.env.REACT_APP_chat_api_url;
            const TOKEN = process.env.REACT_APP_chat_api_token;
            
            const passValue = {
                api_token : TOKEN.toString(),
                account_id : account_id.toString(),
                convers_id : convers_id.toString(),
                unread_account_id : currentConversationDetailTemp.unread_account_id.toString(),
            }

            await axios
                    .post( ApiUrls.bizchatSetReadStatus ,  passValue)
                    .then( response => {

                    })
                    .catch( (err) => {

                    });
        }
    }

   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    blockContact = ( accountDetail , convers_id ) =>{

        if ( parseInt(accountDetail.account_id)>0 && parseInt(convers_id)>0){
            
            //const API_URL = process.env.REACT_APP_chat_api_url;
            const TOKEN = process.env.REACT_APP_chat_api_token;
            const errorMsg = process.env.REACT_APP_message_error_process;
            
            const passValue = {
                api_token : TOKEN.toString(),
                account_id : accountDetail.account_id.toString(),
                account_type : accountDetail.account_type.toString(),
                convers_id : convers_id.toString()
            }
            
            axios
            .post( ApiUrls.bizchatConversationBlockUnblock ,  passValue)
            .then( response => {

                if ( parseInt(response.status)===201 ){

                    if ( parseInt(response.data.success)===1 ){
                        Utilities.messagePopup('success', response.data.successMessage );

                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage ); 
                    }

                }else{
                    Utilities.messagePopup('error', errorMsg ); 
                }
                
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg); 
            });

        }
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    clearConversation =  async ( account_id , convers_id ) =>{

        if ( parseInt(account_id)>0 && parseInt(convers_id)>0){

            //const API_URL = process.env.REACT_APP_chat_api_url;
            const TOKEN = process.env.REACT_APP_chat_api_token;
            const errorMsg = process.env.REACT_APP_message_error_process;
            
            const passValue = {
                api_token : TOKEN.toString(),
                account_id : account_id.toString(),
                convers_id : convers_id.toString()
            }
            
            axios
            .post( ApiUrls.bizchatClearConversation ,  passValue)
            .then( response => {
                
                if ( parseInt(response.status)===201 ){

                    if ( parseInt(response.data.success)===1 ){
                        Utilities.messagePopup('success', response.data.successMessage );
                        jQuery('#chat-conversation--history-messages').html('');

                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage ); 
                    }

                }else{
                    Utilities.messagePopup('error', errorMsg ); 
                }
                
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg); 
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getFlagValue = ( type ) => {
        let flag = 1;
        type = parseInt(type);
        
        if ( type===1 || type===4 ){
            flag = 1;
        }else if ( type===2 ){
            flag = 3;
        }
        
        return flag;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    contactRequest = ( account, profile, deptID = 0, isInternal = 0 ) => {
        
        deptID = parseInt(deptID);
        let is_internal = parseInt(isInternal);

        //const API_URL = process.env.REACT_APP_chat_api_url;
        const TOKEN = process.env.REACT_APP_chat_api_token;

        let flag = this.getFlagValue(profile.account_type);
        if ( is_internal===1 ){  flag = 3;  }
        if ( deptID>0 ){ flag = 4; }

        let req_account_type = profile.hasOwnProperty('team_company_id') ? profile.team_company_id : 0;
        req_account_type = profile.hasOwnProperty('company_id') ? profile.company_id : req_account_type ;


        const account_type = parseInt(profile.account_type);
        if (account_type===3){
            req_account_type = profile.account_id;
        }

        let account_department_id = '0';
        if ( account.hasOwnProperty('group_id') ){
            account_department_id = account.group_id.toString();
        }
 
        const passValue = {
                            url : ApiUrls.bizchatContactRequest,
                            values : {
                                        api_token : TOKEN.toString(),
                                        account_id : account.account_id.toString(),
                                        account_type : account.account_type.toString(),
                                        account_department_id : account_department_id.toString(),
                                        company_id : req_account_type.toString(),
                                        receiver_account_id : profile.account_id.toString(),
                                        department_id : deptID.toString(),
                                        flag : flag.toString()
                            }
                        }
      
        return passValue;
    }

}

export default new ChatUtilities();

