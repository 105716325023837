import React, { useEffect, useState } from 'react';
//import axios from 'axios';

//import ApiUrls from '../helpers/ApiUrls';
import Utilities from '../helpers/Utilities';

const TeamMembersSelectionListingTemplate = ( props ) => {

    //const companyId = props.companyId ?? 0;
    const listProfiles = props.listProfiles ?? [];

    //const [ listProfiles , setListProfiles ] = useState([]);
    const [ memberSelectedIDs , setMemberSelectedIDs ] = useState(props.memberSelectedIDs ?? []);

    const toggleActiveClass = (e, member )  => {
        let memberSelectedIDsTemp = memberSelectedIDs;
        let id = (member.id ?? member.account_id).toString();
        
        ! ( memberSelectedIDsTemp.includes(id) ) ? memberSelectedIDsTemp.push(id) : ( memberSelectedIDsTemp = [...Utilities.removeValueInArray(memberSelectedIDsTemp, id)] ) ;
        memberSelectedIDsTemp = Utilities.arrayUnique(memberSelectedIDsTemp);
        setMemberSelectedIDs(memberSelectedIDsTemp);
    }

    const submitTeamSelection = () => {
        //console.log(memberSelectedIDs);
        const memberSelectedDetailTemp = listProfiles.filter( member => memberSelectedIDs.includes( (member.account_id || 0).toString() ) )  ?? [];
        props.submitTeamSelection(memberSelectedDetailTemp);
    }

    /* const getMembers = async () => {

        if ( parseInt(companyId)>0 ){
            await axios
                    .post( ApiUrls.companyTeamList , { account_id : companyId } )
                    .then( response => {
                    
                        if ( response.status===200 ){
                            const dataResponse = response.data;
            
                            if ( parseInt(dataResponse.status)===1 ){
                                let memberList = [];

                                Object.entries(dataResponse.list).map( ( [id, memberdetail] ) => {
                                    memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];                                    
                                });

                                setListProfiles(memberList);
                            }
                        }
                    })
                    .catch( (err) => {
                        setListProfiles([]);
                    }); 
        }
    } */

    useEffect( () => {
        //getMembers();
    }, [] );


    return (
        <div className="departments-page">
        {
            Object.keys(listProfiles).length>0 ?
                    <ul id="listing-members" className="listing-profiles listing-members mt-4 text-lowercase">
                    {
                        Object.entries(listProfiles).map( ([key, row] ) => 
                            <li key={key} className={ `${ ( ( memberSelectedIDs.includes((row.id ?? row.account_id).toString()) ) ? 'selected' : '' ) }` } onClick={ (e) => toggleActiveClass(e, row ) }>
                                <div className="profile-wrap">
                                    <div className="profile-image-wrap">
                                        <div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo_url ?? row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url ?? row.logo)  }}>{ Utilities.showHideInitial(row.logo_url ?? row.logo, row.name) }</div>
                                    </div>
                                    <div className="profile-name ellipsis-1">{row.name}</div>
                                    <div className="profile-position ellipsis-1">{row.team_position ?? row.position}</div>
                                </div>
                            </li>
                        )
                    }
                    </ul>
                :
                <div className="text-center text-lowercase no-record-label mt-5">{ Utilities.labelProfileAvailable }</div> 
        }

            <div className="col-5 mx-auto  pt-5 mt-5">
                <button type="button" className="btn-theme-black" onClick={ () => props.submitTeamSelection ? submitTeamSelection() : ''}>{Object.keys(listProfiles).length===0?'close':'done'}</button>
            </div>
        </div>
     
    )
}

export default TeamMembersSelectionListingTemplate;
