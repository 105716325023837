import React, { useState  } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';

import Utilities from '../../../helpers/Utilities';
import { getCurrentCredentailEmailValidation, updateCredentailEmail  } from '../../../helpers/SecurityProfile';
import { checkEmailExistance } from '../../../helpers/VerificationUtilities';

import TitlesLabels from '../../../helpers/TitlesLabels';
import { reloadProfile } from '../../../actions';

const Email = ( props ) => {

    const dispatch = useDispatch();

    let defaultFormValues = {
        current_email : '',
        new_email : '',
        confirm_email : '',
    }

    const profileProvided = props.profileProvided ?? {};

    const [ formValues, setFormValues ] = useState(defaultFormValues);
    

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if ( [...Object.keys(defaultFormValues)].includes(fieldName) ){

            if ( fieldName==='current_email'){
                formValuesTemp = { ...formValuesTemp, current_email : value };
            }else if ( fieldName==='new_email'){
                formValuesTemp = { ...formValuesTemp, new_email : value };
            }else if ( fieldName==='confirm_email'){
                formValuesTemp = { ...formValuesTemp, confirm_email : value };
            }
            
            if ( ! Utilities.isEmpty(value) && ! Utilities.isEmail(value) ){
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }
        }

        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);
    }


    const handleOnclickSubmit = async () => {

        const button_name = 'btn-submit-email';
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(profileProvided).length===0 ){ return false; }

        let errorCounter = 0;
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( Utilities.isEmpty(value)){
                jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }else{
                if ( ! Utilities.isEmail(value) ){
                    jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }
        });
        if ( errorCounter>0 ){  return false; }


        Utilities.elementDisabledStatus(button_name, true );
        const responseEmailValidation = await getCurrentCredentailEmailValidation({ account_id: profileProvided.account_id.toString(), email_address : formValues.current_email.toString() });
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(responseEmailValidation).length===0 ){ return false; }

        if ( parseInt(responseEmailValidation.status)===0 ){
            Utilities.messagePopup('Error', TitlesLabels.general.currentEmailIncorrent );
        }else{


            const new_email = formValues.new_email.toLowerCase(), confirm_email = formValues.confirm_email.toLowerCase();
            if ( new_email!==confirm_email ){
                Utilities.messagePopup('Error', TitlesLabels.general.newConfirmEmailNotSame );
                
            }else{

                Utilities.elementDisabledStatus(button_name, true );
                const responseEmailCheckingExistanceDb = await checkEmailExistance( new_email, formValues.current_email.toString() );
                Utilities.elementDisabledStatus(button_name, false );

                if ( parseInt(responseEmailCheckingExistanceDb.exist)===1 ){
                    Utilities.messagePopup('Error', Utilities.labelEmailAddressAlreadyExist );

                }else{

                    Utilities.elementDisabledStatus(button_name, true );
                    const responseEmailUpdate = await updateCredentailEmail({ account_id: profileProvided.account_id.toString(), email_address : new_email });
                    Utilities.elementDisabledStatus(button_name, false );

                    if ( Object.keys(responseEmailUpdate).length===0 ){
                        Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotUpdateEmail );
                    }else{

                        if ( parseInt(responseEmailUpdate.status)===0 ){
                            Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotUpdateEmail );
                        }else{
                          
                            Utilities.messagePopup('success', TitlesLabels.alertMessages.emailUpdatedSuccessfully );
                            setFormValues(defaultFormValues);

                            // UPDATE PROFILE IN LIST 
                            Utilities.updateProfileInAccountList({...profileProvided, email : new_email});
                            dispatch( reloadProfile(Date.now()) );
                            
                        }

                    }
                }
            }

        }
       
    
    }


    return (
        <>
            <div className="form-group">
                <input type="email" name="current_email" className="form-control" placeholder=" " autoComplete="off" value={ formValues.current_email }  onChange={ (e) => pushValue( e, 'current_email') }  ></input>
                <label className="form-label">Current Email</label>
            </div>
            <div className="form-group">
                <input type="email" name="new_email" className="form-control" placeholder=" " autoComplete="off"  value={ formValues.new_email }  onChange={ (e) => pushValue( e, 'new_email') } ></input>
                <label className="form-label">New Email</label>
            </div>
            <div className="form-group">
                <input type="email" name="confirm_email" className="form-control" placeholder=" " autoComplete="off" value={ formValues.confirm_email }  onChange={ (e) => pushValue( e, 'confirm_email') } ></input>
                <label className="form-label">Confirm Email</label>
            </div>

            <button type="button" id="btn-submit-email" className="btn-theme-black  button-has-loader mt-5" onClick={ () => handleOnclickSubmit() }>submit</button>
        </>
    )
}

export default Email;