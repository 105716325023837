import React, { useEffect, useState } from 'react';
import jQuery from 'jquery';
import Utilities from '../helpers/Utilities';

const TimePicker = ( props ) => {

    const format = 12;

    const [ hour, setHour ] = useState('');
    const [ minute, setMinute ] = useState('');
    const [ timeFormat, setTimeFormat ] = useState('');

    const updatePassTime = () => {

        let timerOut = setTimeout( () => {
            const currentHour = jQuery('#timepicker__hour').val();
            const currentMinute = jQuery('#timepicker__minute').val();
            const currentFormat = jQuery('#timepicker__format').val();
            
            const returnFormat =  `${currentHour}:${currentMinute} ${currentFormat}`;
            props.setRequestTime(returnFormat);

            clearTimeout(timerOut);
        }, 50);
    }

    const updateHour = ( action = 'inc') => {
        let hourTemp = parseInt(hour);

        if ( action==='inc'){
            if ( hourTemp===12){ hourTemp = -1;  }
            hourTemp++;
        }else{
            if ( hourTemp===0){ hourTemp = 13;  }
            hourTemp--;
        }
        
        hourTemp = parseInt(hourTemp.toString().length)===1 ? '0'+hourTemp: hourTemp;
        setHour(hourTemp);

        updatePassTime();
    }

    const updateMinute = ( action = 'inc') => {
        let minuteTemp = parseInt(minute);

        if ( action==='inc'){
            if ( minuteTemp===59){ minuteTemp = -1;  }
            minuteTemp++;
        }else{
            if ( minuteTemp===0){ minuteTemp = 60; }
            minuteTemp--;
        }
        
        minuteTemp = parseInt(minuteTemp.toString().length)===1 ? '0'+minuteTemp: minuteTemp;
        setMinute(minuteTemp);

        updatePassTime();
    }

    const updateFormat = ( formatRequest = '' ) => {
        setTimeFormat(formatRequest);
        updatePassTime();
    }

    useEffect( () =>{
        const currentFormattedTime = Utilities.returnTimeFormat();
        const hourTime = currentFormattedTime.split(' ')[0];

        setHour(hourTime.split(':')[0]);
        setMinute(hourTime.split(':')[1]);

        setTimeFormat(currentFormattedTime.split(' ')[1]);

    }, []);


    return (
       <div className="form-theme-timepicker">
           <div className="form-theme-timepicker--content">
                <div>
                    <input id="timepicker__hour" type="number" value={hour} readOnly />
                    <span className="arrow-up" onClick={ () => updateHour('inc') }></span>
                    <span className="arrow-down" onClick={ () => updateHour('dec') }></span>
                </div>
                <div>
                    <input  id="timepicker__minute" type="number" value={minute}  readOnly />
                    <span className="arrow-up" onClick={ () => updateMinute('inc') }></span>
                    <span className="arrow-down" onClick={ () => updateMinute('dec') }></span>
                </div>
                <div>
                    <input  id="timepicker__format" type="text" value={timeFormat}  readOnly />
                    <span className="arrow-up" onClick={ () => updateFormat('am') }></span>
                    <span className="arrow-down" onClick={ () => updateFormat('pm') }></span>
                </div>
           </div>
       </div>
    )
}

export default TimePicker;
