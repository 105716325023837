import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
import firebase from 'firebase';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment'; 
import getBlobDuration from 'get-blob-duration';
import { Dropdown } from 'react-bootstrap';


import { showHeader, showFooter, pageTitle, showChatSearch, passChatProfile, showLoader, chatSidebarBlock, reloadProfile, shareQRCode, passChatMessageText, showNoteIndicator, passConversID, passInternalProfile, reloadAccountsDB, passForwardConversId as passForwardConversIdDispatch, passDeleteMediaList as passDeleteMediaListDispatch, passConversationMessage, passConversDetail as passConversDetailReducer, blockUnblockChat as blockUnblockChatReducer, passCompanyGroupsMembersIds as passCompanyGroupsMembersIdsReducer, booleanChatReInitializeConversation as booleanChatReInitializeConversationReducer} from '../actions';

import ChatConversation from '../components/ChatConversation';
import ChatTransfer from '../components/ChatTransfer';
import ChatSearch from '../components/ChatSearch';
import ChatSidebar from '../components/ChatSidebar';
import ChatMap from '../components/popups/ChatMap';
import DepartmentForms from '../components/popups/DepartmentForms';
import ChatSendDococument from '../components/ChatSendDococument';

import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import ChatUtilities from '../helpers/ChatUtilities';
import QueryChatUtilities from '../helpers/QueryChatUtilities';
import SessionUtilities from '../helpers/SessionUtilities';
import TitlesLabels from '../helpers/TitlesLabels';
import { leaveFromGroups, joinInvitationResponse } from '../helpers/QueryChatGroups';
import UrlSlugs from '../helpers/UrlSlugs';


const Chat = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const FS_COLLECTION = process.env.REACT_APP_firebase_collection_name.toString();
    const FS_DOCUMENT = process.env.REACT_APP_firebase_document_name.toString();

    const firebaseConfig = ChatUtilities.firebaseConfig();
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
    const FIRESTORE = firebase.firestore(); 

    const FS_DBCOLLECTION = FIRESTORE.collection(FS_COLLECTION).doc(FS_DOCUMENT);
    const conversationLimit = ChatUtilities.conversationLimit;
    const cantAccessLabel = Utilities.labelYouCantReplyThisConversation;


    const showChat = useSelector( state => state.showChatSearch);
    const sidebarBlock = useSelector( state => state.chatSidebarBlock);
    const shareQRCodeAction = useSelector( state => state.shareQRCode);
    const passChatMessageTextValue = useSelector( state => state.passChatMessageText);
    const passConversDetail = useSelector( state => state.passConversDetail);
    const passForwardConversId = useSelector( state => state.passForwardConversId);
    const passDeleteMediaList = useSelector( state => state.passDeleteMediaList);
    const blockUnblockChat = useSelector( state => state.blockUnblockChat);
    const passCompanyGroupsMembersIds = useSelector( state => state.passCompanyGroupsMembersIds);
    const booleanChatReInitializeConversation = useSelector( state => state.booleanChatReInitializeConversation);
    
    const [ cantAutoReplayLabel, setCantAutoReplayLabel ] = useState('');

    const [ showNewMessageWarning, setShowNewMessageWarning ] = useState(false);
    const [ warningMessageDetail, setWarningMessageDetail ] = useState({});

    const [ isInternalChat, setIsInternalChat ] = useState(false);
    const [ hideMainContent, setHideMainContent ] = useState(false);
    const [ coverMainContent, setCoverMainContent ] = useState(false);
    const [ showSendDocument, setShowSendDocument ] = useState(false);
    const [ currentConversationDetail, setCurrentConversationDetail ] = useState({});
    
    const [ defaultMessageValue, setDefaultMessageValue ] = useState('');
    const [ showAttachmentOption, setShowAttachmentOption ] = useState(0);
    const [ conversation, setConversation ] = useState({});
    const [ requestContact, setRequestContact ] = useState({});
    const [ loggedinProfile, setLoggedinProfile ] = useState({});
    const [ activeProfile, setActiveProfile ] = useState({});
    const [ requestProfile, setRequestProfile ] = useState({});
    const [ conversID, setConversID ] = useState(0);
    const [ sidebarViewType, setSidebarViewType ] = useState('');
    const [ allowCompose, setAllowCompose ] = useState(false);

    const [ selectedMessagesDetail, setSelectedMessagesDetail ] = useState([]);
    const [ selectedMessageDetail, setSelectedMessageDetail ] = useState({});
    const [ replyContentHtml, setReplyContentHtml ] = useState('');
    const [ showSearchProfile, setShowSearchProfile ] = useState(0);
    
    const [ ownerIdConversation, setOwnerIdConversation ] = useState(0);
    
    let previousTempConversations = [];
    let allConversationId = [];

    const [ previousConversations, setPreviousConversations ] = useState([]);
    const [ presentConversations, setPresentConversations ] = useState([]);
    
    const [ showChatMap, setShowChatMap ] = useState(false);
    const [ allowShareQRCode, setAllowShareQRCode ] = useState(false); 

    const [ blockStatus, setBlockStatus ] = useState(false); 
    const [ blockStatusHtml, setBlockStatusHtml ] = useState(false); 
    
    const [ bolUncheckSelectedMessages , setBolUncheckSelectedMessages ] = useState(false);
    const [ bolClearSelectedMessages , setBolClearSelectedMessages ] = useState('');

    const [ unsubscribe, setUnsubscribe ] = useState();
    const [ chatHeaderProfile, setChatHeaderProfile ] = useState({});


    let globalConversation = {};
    let globalActiveProfile = {};
    let globalRequestProfile = {};

    const handleCloseChatMap = () => setShowChatMap(false);
    const handleShowChatMap = () => {
        setShowChatMap(true);
        toggleAttachmentOption();
    }

    const couldntUploadMsg = TitlesLabels.general.couldNotUploadFile ;

    // FORMS
    const [ formDetail, setFormDetail ] = useState({});
    const [ formsAction, setFormsAction ] = useState('create');
    const [ departmentDetail, setDepartmentDetail ] = useState({});
    const [ showFormPopUp, setShowFormPopUp ] = useState(false);

    // COMPANY GROUPS
    const [ onblurSendingOption, setOnblurSendingOption ] = useState(0);
    

    const handleCloseFormPopUp = () => {
        setShowFormPopUp(false);
        setHideMainContent(false);

        dispatch( passConversationMessage({}) );
    }
    

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='message-content'){
            setDefaultMessageValue(value);
            setShowAttachmentOption(0);
            ChatUtilities.globalCompanyGroupsMemberSelected = [];
        }
    }

    const handleClickTextArea = () => {
        setShowAttachmentOption(0);
    }

    const toggleSendDocumentOption = () => {
        let action = ( showSendDocument===false ) ? true : false;
        setShowSendDocument( action );
        setShowAttachmentOption(0);
    }

    const toggleAttachmentOption = () => {
        let action = ( showAttachmentOption===0 ) ? 1 : 0;
        setShowAttachmentOption( action );
    }

    const toggleSearchProfile = () => {
        let action = ( showSearchProfile===0 ) ? 1 : 0;
        setShowSearchProfile( action );
        dispatch( showChatSearch(action) );
    }

    const messagesSelectedDetail = ( messagesDetailArr = []) => {
        setSelectedMessagesDetail(messagesDetailArr);
        setBolUncheckSelectedMessages(false);
        setReplyContentHtml('');

        const messageCounter = parseInt(messagesDetailArr.length);
        if ( messageCounter===0 ){
            dispatch( chatSidebarBlock('') );
            jQuery('#chat-conversation--history-messages').removeClass('enable-selection');
        }
        
        setBolClearSelectedMessages('');
    }

    const messagesSelectedDetailInMedia = ( messagesDetailArr = []) => {
        setSelectedMessagesDetail(messagesDetailArr);
    }


    const uncheckSelecedMessages = () => {
        setSelectedMessagesDetail([]);
        setBolUncheckSelectedMessages(true);

        jQuery('#chat-conversation--history-messages').removeClass('enable-selection');
        setBolClearSelectedMessages(new Date());
    }

    const confirmMessageDeletion = () => {

        const messageCounter = parseInt(selectedMessagesDetail.length);
        if ( messageCounter>0 ){

            const messageLabel = messageCounter===1 ? 'message' : messageCounter+' messages';

            confirmAlert ({
                title: '',
                message: `delete ${messageLabel}?`,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'cancel',  onClick: () => {} },
                    {
                        label: 'delete for everyone',
                        onClick: () => {
                            
                            selectedMessagesDetail.map( detail => {
                               ChatUtilities.deleteMesage( FIRESTORE, conversID,  { doc_id : detail.doc_id } );
                            });

                            uncheckSelecedMessages();
                            jQuery('#chat-conversation--history-messages > li').removeClass('selected');
                        }
                    }
                ]
            }); 
        }

    }
    
    const deleteWarningNewMessage = ( showComposer = false ) => {
        if ( Object.keys(warningMessageDetail).length>0 ){
            ChatUtilities.deleteMesage( FIRESTORE, conversID,  { doc_id : warningMessageDetail.doc_id }, 1 );
            setWarningMessageDetail({});
            setShowNewMessageWarning(false);
            setAllowCompose(showComposer);
        }
    }

    const forwardMultipleMessages = () => {
        setSelectedMessageDetail({});
        dispatch( chatSidebarBlock('') );

        setTimeout( () => {  dispatch( chatSidebarBlock('forward') ); }, 200 );
    }

    const replyMultipleMessage = () => {
    
        if ( parseInt(selectedMessagesDetail.length)===1 ){
            setupReplyContent(selectedMessagesDetail[0]);
            
            setSelectedMessagesDetail([]);
            //setBolClearSelectedMessages(new Date());

            jQuery('#chat-conversation--history-messages').removeClass('enable-selection');
            jQuery('#chat-conversation--history-messages > li').removeClass('selected');
        }
    }
    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const removeReplyContent = () => {
        setReplyContentHtml('');
        setSelectedMessageDetail({});
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const setupProfile = (id, isInternal = 0, isRequest = 1 ) => {
        isRequest = parseInt(isRequest);
        
        let currentConversationDetailTemp = {};
        let CONVERSID = jQuery('#hidden-convers-id').text().toString();

        if ( ! Utilities.isEmpty(CONVERSID) && parseInt(CONVERSID)>0  ){
            currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( CONVERSID );
            id = currentConversationDetailTemp.account_id;
        }


        axios
        .post( ApiUrls.profilegetByID, { id : id, show_company : 1 }  )
        .then( response => {
            const resStatus = response.status;

            if ( resStatus===200 ){
                const resData = response.data;
                const resStatus = parseInt(resData.status);

                if ( resStatus===1){
                    const profileInfo = Utilities.formatProfileDetail(resData.detail);
                    
                    if ( isRequest===0){

                        /* let profileInfoTemp = profileInfo;
                        if ( profileInfoTemp.hasOwnProperty('company_detail') ){
                            profileInfoTemp = { ...profileInfoTemp, category : profileInfo.company_detail.name };
                        }else{
                            profileInfoTemp = { ...profileInfoTemp, category : profileInfo.title };
                        } */

                        //dispatch( passChatProfile(profileInfoTemp) ); // temporary skip

                        if ( isRequest===0 ){
                            //let CONVERSID = jQuery('#hidden-convers-id').text().toString();
                            //const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( CONVERSID );

                            const profileInfoTemp = {
                                name : currentConversationDetailTemp.name,
                                category :   Utilities.isEmpty(currentConversationDetailTemp.title) ? profileInfo.category: currentConversationDetailTemp.title ,
                                logo_url: currentConversationDetailTemp.logo,
                                img: currentConversationDetailTemp.logo,
                                account_id : currentConversationDetailTemp.account_id,
                            }

                            dispatch( passChatProfile(profileInfoTemp) );
                        }
                        
                    }
                    
                    globalRequestProfile = profileInfo;
                    setRequestProfile(profileInfo);
                    
                    setupChat(resData.detail, isInternal, 0 , isRequest);
                   
                }
            }

        })
        .catch( (err) => {

        }); 

    }

    
     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const setupProfileDepartment = ( compID, deptID, is_request ) => {

        axios
        .post( ApiUrls.departmentGetDetailByCompanyDepartmentID , { company_id : compID, department_id : deptID, show_company : 1 } )
        .then( response => {

            if ( parseInt(response.status)===200){
                if ( parseInt(response.data.status)===1 ){
            
                    const companyProfile = Utilities.formatProfileDetail(response.data.company);
                    let profileInfo = {
                        name :  companyProfile.name,
                        category : response.data.detail.department_name,
                        logo_url: companyProfile.logo,
                        img: companyProfile.logo,
                        account_id: companyProfile.account_id
                    }

                    globalRequestProfile = companyProfile;
                    setRequestProfile(companyProfile);

                    dispatch( passChatProfile(profileInfo) );

                    profileInfo = { ...response.data.detail, ...{ profileInfo : profileInfo } };

                    setupChat(companyProfile, 0, parseInt(response.data.detail.group_id || 0), is_request,  profileInfo );

                    setDepartmentDetail(response.data.detail);
                }
            }
        })
        .catch( (err) => {
           
        }); 

    }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const setupChat = ( profile, isInternal = 0, deptID = 0, isRequest = 1, departmentDetail  ) => {

        isRequest = parseInt(isRequest);

        const loginSession = localStorage.getItem(process.env.REACT_APP_session_login);
        let currentAccount = JSON.parse(loginSession);

        let chatType = match.params.type;
        chatType = parseInt(chatType);

        if (chatType>=2){
            if ( isInternal===1 ){

                let workAccountID = match.params.wid;
                workAccountID = atob(workAccountID);
                const workProfile = SessionUtilities.getProfileDetail(workAccountID);

                currentAccount = workProfile;
            }
        }
    
        setActiveProfile(currentAccount);
        globalActiveProfile = currentAccount;

        if ( isRequest===0 ){

            const encodedValues = ChatUtilities.decryptChatDetail( match.params.coversid);
            if (isInternal===1){         

                let reqAccountID = match.params.id;
                reqAccountID = atob(reqAccountID);

                const workProfile = SessionUtilities.getProfileDetail( parseInt(reqAccountID) );
                currentAccount = workProfile;

                setActiveProfile(currentAccount);
                globalActiveProfile = currentAccount;

            }
            
           
            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( encodedValues.convers_id );
            setCurrentConversationDetail( currentConversationDetailTemp );

            const conversationDetailList = {
                convers_id : encodedValues.convers_id,
                owner_account_id : currentAccount.account_id
            }
            //ChatUtilities.getMessages( FIRESTORE, conversationDetailList );
            getConversations( conversationDetailList , 1 );

         
            // ADD API for Read Status Here...
            markMessagesRead( currentAccount.account_id.toString() , encodedValues.convers_id );
            
            // Update the counter in header
            dispatch( reloadProfile(Date.now()) );

            dispatch( passConversID( encodedValues.convers_id ) );

            dispatch( showNoteIndicator(parseInt(currentConversationDetailTemp.sp_notes_status || 0)) );

            if ( parseInt(shareQRCodeAction)===1 ){
                const timerQr = setTimeout( () => {
                    jQuery('#btn-share-qrcode').trigger('click');
                    //setupAllowShareQRCode();
                    clearTimeout(timerQr);
                }, 1000);

            }else if ( parseInt(shareQRCodeAction)===2 ){
                const timerQr = setTimeout( () => {
                    jQuery('#btn-share-qrcode-company').trigger('click');
                    clearTimeout(timerQr);
                }, 2500);
            }

        }else{

          
            const contactRequestValues = ChatUtilities.contactRequest(currentAccount, profile, deptID , isInternal );

            axios
            .post( contactRequestValues.url, contactRequestValues.values )
            .then( response => {

                if ( parseInt(response.status)===201 ){
                    if ( parseInt(response.data.success)===1 ){
                    
                        const CONVERSATION_DETAIL = response.data.result.bizchat_conversation;
                        globalConversation = CONVERSATION_DETAIL.conversation_list;
                        setConversation(CONVERSATION_DETAIL.conversation_list);
                        setRequestContact(CONVERSATION_DETAIL.request_contact);

                        //onsole.log(CONVERSATION_DETAIL);

                        const conversIDGenerated = CONVERSATION_DETAIL.conversation_list.convers_id.toString();
                        setConversID(conversIDGenerated);
                        jQuery('#hidden-convers-id').text(conversIDGenerated);

                        let profileInfo = {
                            name : CONVERSATION_DETAIL.conversation_list.name,
                            category : CONVERSATION_DETAIL.conversation_list.title,
                            img: CONVERSATION_DETAIL.conversation_list.logo,
                            logo_url : CONVERSATION_DETAIL.conversation_list.logo,
                            account_id : CONVERSATION_DETAIL.conversation_list.account_id,
                        }
                        

                        if ( parseInt(deptID)>0 &&  parseInt(departmentDetail.type)>0){

                            let name = departmentDetail.hasOwnProperty('profileInfo') ? departmentDetail.profileInfo.name : departmentDetail.department_name;

                            profileInfo = {
                                name : name,
                                category : CONVERSATION_DETAIL.conversation_list.title,
                                img: CONVERSATION_DETAIL.conversation_list.logo,
                                logo_url : CONVERSATION_DETAIL.conversation_list.logo,
                                account_id : CONVERSATION_DETAIL.conversation_list.account_id,
                            }
                        
                        }

                        dispatch( passChatProfile(profileInfo) );

                        dispatch( showNoteIndicator(0) );

                        ChatUtilities.saveAccountsConversationDetail( CONVERSATION_DETAIL.conversation_list, 1 );

                        const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( CONVERSATION_DETAIL.conversation_list.convers_id );
                        setCurrentConversationDetail( currentConversationDetailTemp );

                        getConversations( CONVERSATION_DETAIL.conversation_list, 1 );

                        if ( parseInt(shareQRCodeAction)===1 ){
                            const timerQr = setTimeout( () => {
                                jQuery('#btn-share-qrcode').trigger('click');
                                //setupAllowShareQRCode();
                                clearTimeout(timerQr);
                            }, 1000);
                        }else if ( parseInt(shareQRCodeAction)===2 ){
                            const timerQr = setTimeout( () => {
                                jQuery('#btn-share-qrcode-company').trigger('click');
                                clearTimeout(timerQr);
                            }, 1000);
                        }

                        dispatch( passConversID( conversIDGenerated ) );
                        

                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage); 
                    }

                }else{
                    Utilities.messagePopup('error', errorMsg); 
                }

            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg); 
            }); 
        }

    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const acceptRejectRequestProper = ( action ) => {

        let CONVERSID = jQuery('#hidden-convers-id').text().toString();
        const passValue = {
            api_token : TOKEN.toString(),
            account_id : activeProfile.account_id.toString(),
            convers_id : CONVERSID.toString(),
            flag : action.toString()
        }


        dispatch( showLoader(1) );
        axios
        .post( ApiUrls.profileAcceptRejectTeamInvitation , passValue )
        .then( response => {

            dispatch( showLoader(0) );

            if ( parseInt(response.status)===201 ){

                if ( parseInt(response.data.success)===1 ){
                    Utilities.messagePopup('success', response.data.successMessage );
                    jQuery('#chat-conversation--history .invitation-option-wrap').remove();

                    if ( parseInt(action)===1){
                        dispatch( reloadAccountsDB(Date.now()) );
                    }
                    
                }else{
                    Utilities.messagePopup('error', response.data.errorMessage ); 
                }

            }else{
                Utilities.messagePopup('error', errorMsg ); 
            }
            
        })
        .catch( (err) => {
            dispatch( showLoader(0) );
            Utilities.messagePopup('error', errorMsg); 
        });

    }

    const acceptRejectRequest = ( action ) => {
       
        action = parseInt(action);
        if ( action>1 ){

            let labelAction = (action===2) ? 'reject' : 'remove';
           confirmAlert ({
                title: '',
                message: `Are you sure you want to ${labelAction} invitation?`,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'No',  onClick: () => {} },
                    {
                        label: 'Yes',
                        onClick: () => {
                            acceptRejectRequestProper(action);
                        }
                    }
                ]
            }); 

        }else{
            acceptRejectRequestProper(action);
        }
        
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const morePreviousConversation = ( request_conversation ) => {
        previousTempConversations = [...request_conversation, ...previousTempConversations];
        setPreviousConversations(previousTempConversations);
    }

    const getPreviousConversations = () => {
       
        let CONVERSID = jQuery('#hidden-convers-id').text().toString();
        const dbCollection = FS_DBCOLLECTION.collection(CONVERSID); 

        if ( parseInt(CONVERSID)>0 ){
            
            let conversationTemp = [];

            const lastDocumentID = parseInt( jQuery('#prev-messageid').text() || 0);
            let pageQuery = '';
            
            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( CONVERSID );
            if ( Object.keys(currentConversationDetailTemp).length>0 ){
                const conv_date_details = currentConversationDetailTemp.conv_date_details;

                if ( parseInt(conv_date_details.clear_flag || 0)===1 ){
                    let conv_end_date = conv_date_details.conv_end_date;
                    conv_end_date = new Date(conv_end_date);
                    conv_end_date = conv_end_date.getTime();
          
                    pageQuery = dbCollection.where("date", ">=", parseInt(conv_end_date) ).where('date', '<=',lastDocumentID ).orderBy("date", "desc").startAfter(lastDocumentID).limit(conversationLimit);
                }else{
                    pageQuery = dbCollection.where('date', '<',lastDocumentID ).orderBy("date", "desc").startAfter(lastDocumentID).limit(conversationLimit);
                }

            }else{
                pageQuery = dbCollection.where('date', '<', lastDocumentID ).orderBy("date", "desc").startAfter(lastDocumentID).limit(conversationLimit);
            }

            let docIDsArray = [];
            pageQuery.get().then(function(snapshot) {
                const messages_list_reverse = snapshot.docChanges().reverse();

                messages_list_reverse.forEach(function(query) {
                    let messages_data = query.doc.data();
                    messages_data = { doc_id : query.doc.id.toString(), ...messages_data };
                    conversationTemp.push(messages_data);
                    docIDsArray.push(query.doc.id);
                });

                morePreviousConversation(conversationTemp);

                const firstDocumentID = docIDsArray[0];
                jQuery('#prev-messageid').text(firstDocumentID);
                
            });
        }
    }

     ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const showMessagesFromSearch = ( docDateId = 0 ) => {
        if ( parseInt(docDateId)>0 ){
            
            if ( typeof unsubscribe!=='undefined') {
                unsubscribe.unsubscribe();
           
                setPresentConversations([]);
                setPreviousConversations([]);
                
                getConversations(currentConversationDetail,2, 0, [], docDateId );

             }
        }
    }

     ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
     const getConversations = async ( CONVERS_LIST, initialize = 0, lastDocID = 0,  conversationTemp = [], docDateId = 0 ) => {
      
        let CONVERSID = CONVERS_LIST.convers_id.toString();
        const OWNERID = parseInt(CONVERS_LIST.owner_account_id);
        const dbCollection = FS_DBCOLLECTION.collection(CONVERSID); 
        const segment = Utilities.getUrlSegments();

        const encryptedParam = segment.slice(-1).pop();
        const splitUrlParam = atob(encryptedParam).toString().split('-');

        setOwnerIdConversation(OWNERID);
        
        if ( parseInt(CONVERSID)>0 ){

            let pageQuery = '';

            const loginUserDetail = PagesAuthentication.getUserLoggedinDetail();
            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( CONVERSID );
            const ownerAccountId = parseInt(loginUserDetail.account_id ?? 0);
            const conversFlag = parseInt(currentConversationDetailTemp.flag ?? 0);

            if ( Object.keys(currentConversationDetailTemp).length>0 ){
                const conv_date_details = currentConversationDetailTemp.conv_date_details;

                if ( parseInt(conv_date_details.clear_flag || 0)===1 ){

                    let conv_end_date = conv_date_details.conv_end_date;
                    //conv_end_date = new Date(conv_end_date);
                    //conv_end_date = conv_end_date.getTime();
                    
                    conv_end_date = moment.utc(conv_end_date);
                    conv_end_date = moment(conv_end_date).local().valueOf(); // unix() / will remove remove some values
                    
                    if ( docDateId>0 ){
                        pageQuery = dbCollection.where("date", ">=", parseInt(docDateId) ).orderBy("date", "desc");
                    }else{
                        pageQuery = dbCollection.where("date", ">", parseInt(conv_end_date) ).orderBy("date", "desc").limit(conversationLimit);
                    }
                }
            }

            if ( Utilities.isEmpty(pageQuery) ){
                if ( docDateId>0 ){
                    pageQuery = dbCollection.where("date", ">=", parseInt(docDateId) ).orderBy("date", "desc");
                }else{
                    pageQuery = dbCollection.orderBy("date", "desc").limit(conversationLimit);
                }
            }

           
            const lastDocumentDetail = await new Promise ( passId => {
                                                                pageQuery.get().then( snapshot => {
                                                                const messages_list_reverse = snapshot.docChanges().reverse();

                                                                //let messagesData = [];
                                                                let docIDsArray = [];
                                                                messages_list_reverse.forEach( query => {
                                                                    let messages_data = query.doc.data();
                                                                 
                                                                    messages_data = { doc_id : query.doc.id.toString(), ...messages_data };
                                                                    conversationTemp.push(messages_data);
                                                                    docIDsArray.push(query.doc.id);
                                                                    
                                                                });
                                                            
                                                                const firstDocumentID = docIDsArray[0];
                                                                //passId( docIDsArray[docIDsArray.length-1]);
                                                                passId( conversationTemp[docIDsArray.length-1] );
                                                                //conversationTemp = [...conversationTemp, ...messagesData];


                                                                jQuery('#prev-messageid').text(firstDocumentID);

                                                            });
            });

            const lastDocumentID = parseInt(Object.keys(lastDocumentDetail).length>0 ? lastDocumentDetail.doc_id : 0 ) ?? 0;
           
            if ( lastDocumentID>0 ){
                
                let operation = ">";

               
                if ( [17].includes(parseInt(lastDocumentDetail.message_type ?? 0)) ) {

                    if ( [6].includes(conversFlag) ){  // 6-Company groups

                        const conversAccesFlag = parseInt(currentConversationDetailTemp.access_flag ?? 0);
                        if ( conversAccesFlag>0 && [3].includes(conversAccesFlag) ){

                            // for normal chat only; excluded internal chat
                            if ( splitUrlParam.length===3 ){
                                setTimeout( () => {
                                    setAllowCompose(false);
                                    setShowNewMessageWarning(true);
                                }, 500);
                            }
                        }

                    }else{
                        operation = "<" ;
                        conversationTemp = [];

                        setWarningMessageDetail(lastDocumentDetail);

                        if ( parseInt(ownerAccountId)===parseInt(lastDocumentDetail.account_id ?? 0) ){
                            //conversationTemp = [];

                        }else{

                            // for normal chat only; excluded internal chat
                            if ( splitUrlParam.length===3 ){
                                setTimeout( () => {
                                    setAllowCompose(false);
                                    setShowNewMessageWarning(true);
                                }, 500);
                            }
                        }
                    }
                }
                

                //console.log(operation, ownerAccountId, lastDocumentDetail);
                let firstQuery = dbCollection.where("date", operation.toString() , lastDocumentID ).orderBy("date", "desc");

                let unsubscribe = firstQuery.onSnapshot( snapshot => {

                    //let hasDublicate = false;
                    const messages_list_reverse = snapshot.docChanges().reverse();
                    messages_list_reverse.forEach( async function(query) {
                        
                        let messages_data = query.doc.data();
                        let message_id = query.doc.id.toString();

                        const message_type = parseInt(messages_data.message_type);

                        if ( ChatUtilities.departmentFormsTypes.includes(message_type) ){
                            if ( allConversationId.includes(message_id.toString()) ){
                               // hasDublicate = true;

                               jQuery('#chat-conversation--history-messages > li#'+message_id+' .button-group-boolean').remove();
                            }
                        }

                        
                        /* if ( parseInt(messages_data.message_type)===8 ){
                            const urlMetaData =  await QueryUtilities.getUrlMetaData([messages_data.message]);
                            messages_data = { ...messages_data, meta : urlMetaData };
                        } */
                        
                        messages_data = { doc_id : message_id, ...messages_data };
                        conversationTemp = [...conversationTemp, messages_data];

                        allConversationId.push(message_id);
                    });

                    allConversationId = Utilities.arrayUnique(allConversationId);

                    //if ( hasDublicate===true ){
                        conversationTemp.sort(Utilities.fieldSorter(['doc_id']));
                    //}

                    setPresentConversations(conversationTemp);
                    ChatUtilities.scrollBottomConversation();
                });

                setUnsubscribe({unsubscribe});

            }


            if ( parseInt(initialize)===2 ){
                const conversationElem = jQuery('#chat-conversation--history');
                conversationElem.attr('scroll', 'false');
               
                setTimeout( () => {
                    conversationElem.scrollTop(0);
                }, 500);

                setTimeout( () => {
                    conversationElem.attr('scroll', 'true');
                }, 2000);
             }
            
            //unsubsribe();
        }

     }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const deleteMesage = ( messageDetail ) => {
    
        if ( parseInt(conversID)>0  ){

            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wantDeleteSelectedMessages,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'No',  onClick: () => {} },
                    {
                        label: 'Yes',
                        onClick: () => {
                            ChatUtilities.deleteMesage( FIRESTORE, conversID, messageDetail );
                        }
                    }
                ]
            }); 
        }
    }


        
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const forwardMesageOption = ( messageDetail = {} ) => {

        if ( parseInt(conversID)>0  ){
            setSelectedMessageDetail(messageDetail);

            dispatch( chatSidebarBlock('') );
            setTimeout( () => {  dispatch( chatSidebarBlock('forward') ); }, 200 );
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const forwardMesageSubmit = ( passConversIds = '' ) => {

        if ( Object.keys(passConversIds).length>0 ){

            let selectedMessageInfo = [];

            if ( Object.keys(selectedMessageDetail).length>0 ){
                selectedMessageInfo.push({
                    message : selectedMessageDetail.message.toString(),
                    message_type : selectedMessageDetail.message_type.toString(),
                    timestamp : selectedMessageDetail.doc_id.toString()
                });
            }
            
            const messageCounter = parseInt(selectedMessagesDetail.length);
            if ( messageCounter>0 ){
                selectedMessageInfo = [];
                
                selectedMessagesDetail.map( detail => {

                    let file_length = '';
                    if ( parseInt(detail.message_type)===3 &&  detail.hasOwnProperty('file_length') ){
                        file_length = detail.file_length.toString();
                    }

                    selectedMessageInfo.push({
                        message : detail.message.toString(),
                        message_type : detail.message_type.toString(),
                        file_length : file_length.toString(),
                        timestamp : detail.doc_id.toString()
                    })
                });
            }

            const passValues = { 
                api_token : TOKEN.toString(),
                account_id : activeProfile.account_id.toString(),
                account_type : activeProfile.account_type.toString(),
                convers_id : conversID.toString(),
                receivers_convers_id :  passConversIds,
                conversation : selectedMessageInfo
               /*  conversation : [{
                    "message" : selectedMessageDetail.message.toString(),
                    "message_type" : selectedMessageDetail.message_type.toString(),
                    "timestamp" : selectedMessageDetail.doc_id.toString(),
                }] */
            } 

            //console.log(passValues);
           Utilities.elementDisabledStatus('btn-forward-send', true );

           axios
            .post( ApiUrls.bizchatForwardConversation , passValues)
            .then( response => {
                Utilities.elementDisabledStatus('btn-update-broadcast-detail', false );
                
                if ( parseInt(response.status)===201 ){
                  if ( parseInt(response.data.success)===1 ){
                        Utilities.messagePopup('success', response.data.successMessage );

                        setSelectedMessageDetail({});
                        dispatch( passForwardConversIdDispatch('') );
                        dispatch( chatSidebarBlock('') );
    
                  }else{
                      Utilities.messagePopup('error', response.data.errorMessage );
                  }
                }else{
                  Utilities.messagePopup('error', errorMsg );
                }
                
                Utilities.elementDisabledStatus('btn-forward-send', false );
                uncheckSelecedMessages();
                jQuery('#chat-conversation--history-messages > li').removeClass('selected');
                
            })
            .catch( (err) => {

                Utilities.elementDisabledStatus('btn-forward-send', false );
                Utilities.messagePopup('error', errorMsg );
            });
  

        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const deleteSelectedMedias = ( mediaList = [] ) => {

        
        if ( Object.keys(mediaList).length>0 && Object.entries(activeProfile).length>0 ){

            let conversation = [];
            const mediaNavigation = Utilities.mediaNavigation;

            Object.entries(mediaList).map( ( [key, row] ) => {
                conversation.push({ timestamp : row.time_stamp.toString(), message : row.message.toString(), message_type : row.type.toString()  });
            });

            const passValues = { 
                api_token : TOKEN.toString(),
                account_id : activeProfile.account_id.toString(),
                account_type : activeProfile.account_type.toString(),
                convers_id : conversID.toString(),
                conversation : conversation,
                flag : '1'
            } 

           Utilities.elementDisabledStatus('btn-delete-media-selected', true );

           axios
           .post( ApiUrls.bizchatDeleteMediaFile , passValues)
           .then( response => {

               if ( parseInt(response.status)===201 ){
                 if ( parseInt(response.data.success)===1 ){
                       Utilities.messagePopup('success', response.data.successMessage );

                       dispatch( passDeleteMediaListDispatch([]) );
                       dispatch( chatSidebarBlock('') );

                       jQuery('#btn-delete-media-selected').parent('div').addClass('d-none');

                       /* Object.entries(mediaList).map( ( [key, row] ) => {
                           jQuery('ul.media-list li#media-'+row.time_stamp.toString()).remove();
                       }); */

                       if ( sidebarViewType in mediaNavigation ){
                           dispatch( chatSidebarBlock(sidebarViewType) );
                       }

                 }else{
                     Utilities.messagePopup('error', response.data.errorMessage );
                 }
               }else{
                 Utilities.messagePopup('error', errorMsg );
               }
               
              
               Utilities.elementDisabledStatus('btn-delete-media-selected', false );
              

           })
           .catch( (err) => {

               Utilities.elementDisabledStatus('btn-delete-media-selected', false );
               Utilities.messagePopup('error', errorMsg );
           });
        }

    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const setupReplyContent = ( messageDetail ) => {
        
        const messageHtmlData = ChatUtilities.formatMessageHTML( ownerIdConversation, messageDetail, 1);
        let message_content = messageHtmlData.html_message;

        if ( parseInt(messageDetail.message_type)===2 ){
            const filename = messageDetail.message.split('/').pop();
            message_content = '<div class="d-flex align-items-center">'+message_content+'<div class="ml-3 cut-text">'+filename+'</div></div>';
        }

        setReplyContentHtml( message_content  );
        setSelectedMessageDetail(messageDetail);
    }

     
     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const submitTextMessage = ( passMessage = '' ) => {

        let messageValue = defaultMessageValue.toString().trim();
        if ( !Utilities.isEmpty(passMessage) ) {
            messageValue = passMessage;
        }

        if ( !Utilities.isEmpty(messageValue) ) {

            let messageType = 1;
            
            let messageValueArray = messageValue.split(':');
            if ( messageValueArray.includes('https') || messageValueArray.includes('http') ){
               messageType = 8;
            }   

            ChatUtilities.sendMessage( FIRESTORE, conversation,  activeProfile, requestProfile , messageType, messageValue, selectedMessageDetail );

            setDefaultMessageValue('');
            setReplyContentHtml('');
            setSelectedMessageDetail([]);

            setOnblurSendingOption(0);
            //ChatUtilities.globalCompanyGroupsMemberSelected = [];  // Added in ChatUtilities.sendMessage
        }

    }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const submitGEOLocation = ( latitude ,  longitude ) => {
        setOnblurSendingOption(0);

        if ( !Utilities.isEmpty(latitude) &&  !Utilities.isEmpty(longitude) ){
            const coordinates = latitude.toString()+','+longitude.toString();
            ChatUtilities.sendMessage( FIRESTORE, conversation,  activeProfile, requestProfile , 9 , coordinates.toString() , {}, {} );
            setDefaultMessageValue('');
            setReplyContentHtml('');
            setSelectedMessageDetail({});
        }

        handleCloseChatMap();
    }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const triggerTextMessage = (event) => {
        let value = event.target.value;
        value = value.trim();

        const conversFlag =  parseInt(currentConversationDetail.flag || 0);

        if ( event.keyCode == 13 && ! event.shiftKey){

             // enter
            if ( [6].includes(conversFlag) ){
                jQuery('#btn-send-company-groups-option').trigger('click');
            }else{
                submitTextMessage();
            }
            event.preventDefault();
            
        }
    }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
        toggleAttachmentOption();
    }

    const sendAttachment = ( e ) => {
        const value = e.target.value;
        const elem =  e.target;

        let fileExtension = value.substr((value.lastIndexOf('.') + 1)).toString().toLowerCase();

        let isValid = false;
        let attachment = '';
        let messageType = 1;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            attachment = e.target.files[0];
            isValid = true;

            messageType = 2;

        } else if ( Utilities.validate_document_ext( value ) ){

            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            attachment = e.target.files[0];
            isValid = true;

            messageType = 7; // others

            if ( fileExtension==='pdf' ){
                messageType = 6;
            }else if ( fileExtension==='doc' || fileExtension==='docx' ){
                messageType = 5; 
            }

        }else{
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.fileExtensionIsNotValid );
        }

        jQuery(elem).val('');
        setOnblurSendingOption(0);
        
        if ( isValid===true && parseInt(conversID)>0 ){

            dispatch( showLoader(1) );

            const formData = new FormData();
            formData.append('attachment', attachment);
            formData.append('convers_id', conversID);
            
            axios
            .post( ApiUrls.chatUploadMedia ,  formData, {  headers : { 'Content-Type': 'multipart/form-data' } } )
            .then( response => {

                dispatch( showLoader(0) );

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    if ( parseInt(dataResponse.status)===1 ){

                        const messageContent = dataResponse.url.toString();
                        ChatUtilities.sendMessage( FIRESTORE, conversation,  activeProfile, requestProfile , messageType , messageContent, {}, {} );
                        setDefaultMessageValue('');

                    }else{
                        Utilities.messagePopup('error', couldntUploadMsg );
                    }

                }else{
                    Utilities.messagePopup('error', couldntUploadMsg );
                }

            })
            .catch( (err) => {
                dispatch( showLoader(0) );
                Utilities.messagePopup('error', errorMsg );
            });
        }

    }

    const uploadSendDocument = ( documentFileRequest = {} ) => {

        setOnblurSendingOption(0);

        if ( Object.entries(documentFileRequest).length>0 && parseInt(documentFileRequest.type ?? 0)>0 && parseInt(conversID)>0 ){

            const btn_name = 'btn-upload-send-document';
            Utilities.elementDisabledStatus(btn_name, true );

            const formData = new FormData();
            formData.append('attachment', documentFileRequest.file);
            formData.append('convers_id', conversID);
            
            axios
            .post( ApiUrls.chatUploadMedia ,  formData, {  headers : { 'Content-Type': 'multipart/form-data' } } )
            .then( response => {

                Utilities.elementDisabledStatus(btn_name, false );
                toggleSendDocumentOption();

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    if ( parseInt(dataResponse.status)===1 ){

                        const messageContent = dataResponse.url.toString();
                        ChatUtilities.sendMessage( FIRESTORE, conversation,  activeProfile, requestProfile , documentFileRequest.type.toString() , messageContent, {}, {} );

                    }else{
                        Utilities.messagePopup('error', couldntUploadMsg );
                    }

                }else{
                    Utilities.messagePopup('error', couldntUploadMsg );
                }

            })
            .catch( (err) => {
                Utilities.elementDisabledStatus(btn_name, false );
                Utilities.messagePopup('error', errorMsg );
            });

            //
        }

    }
    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const sendAudio = ( attachment, audioDuration ) =>{

        setOnblurSendingOption(0);
        const convers_id = jQuery('#hidden-convers-id').text();
        if ( parseInt(convers_id)>0 && !Utilities.isEmpty(attachment) ){ 
            
            dispatch( showLoader(1) );
            
            const formData = new FormData();
            formData.append('attachment', attachment);
            formData.append('convers_id', convers_id);
                
            axios
            .post( ApiUrls.chatUploadAudio ,  formData )
            .then( response => {
                
                dispatch( showLoader(0) );
                if ( response.status===200 ){
                    const dataResponse = response.data;
                    if ( parseInt(dataResponse.status)===1 ){
                        
                        const messageContent = dataResponse.url.toString();
                        const extra = { duration : audioDuration.toString() };

                        ChatUtilities.sendMessage( FIRESTORE, globalConversation,  globalActiveProfile, globalRequestProfile , 3 , messageContent, {}, extra  );

                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }

                }else{
                    Utilities.messagePopup('error', errorMsg );
                }

            })
            .catch( (err) => {
                dispatch( showLoader(0) );
                Utilities.messagePopup('error', errorMsg );
            });

        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const markMessagesRead = async ( account_id = 0 , convers_id = 0 ) => {

        if ( parseInt(account_id)>0 && parseInt(convers_id)>0){
            await ChatUtilities.markMessagesRead( account_id, convers_id );
          
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const confirmBlock = () => {
       
         if ( parseInt(conversID)>0 && parseInt(activeProfile.account_id)>0 ){
            //ChatUtilities.blockContact( activeProfile , conversID );

            const passValue = {
                api_token : TOKEN.toString(),
                account_id : activeProfile.account_id.toString(),
                account_type : activeProfile.account_type.toString(),
                convers_id : conversID.toString()
            }
            
            dispatch( showLoader(1) );

            axios
            .post( ApiUrls.bizchatConversationBlockUnblock ,  passValue)
            .then( response => {
                dispatch( showLoader(0) );

                if ( parseInt(response.status)===201 ){

                    if ( parseInt(response.data.success)===1 ){

                            let currentConversationDetailTemp = currentConversationDetail;
                            const blockStatus = parseInt(response.data.result.block_status.block_out_status);

                            currentConversationDetailTemp = { 
                                                            ...currentConversationDetail,
                                                            block_status : {
                                                                            ...currentConversationDetail.block_status,
                                                                            block_out_status : blockStatus.toString()
                                                                        }
                                                        };
                            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 2);
                            setCurrentConversationDetail(currentConversationDetailTemp);

                            dispatch( passConversDetailReducer( currentConversationDetailTemp ) );

                            if ( Object.keys(warningMessageDetail).length>0 ){
                                setShowNewMessageWarning(blockStatus===1 ? false : true);
                                deleteWarningNewMessage(blockStatus===1 ? false : true);
                            }
                            
                        Utilities.messagePopup('success', response.data.successMessage );

                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage ); 
                    }

                }else{
                    Utilities.messagePopup('error', errorMsg ); 
                }
                
            })
            .catch( (err) => {
                dispatch( showLoader(0) );
                Utilities.messagePopup('error', errorMsg); 
                console.log(err);
            });

        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const reverseRequestConnectionStatus = async () => {

        if ( parseInt(conversID)>0 && parseInt(activeProfile.account_id)>0 ){

            const passValue = {
                api_token : TOKEN.toString(),
                account_id : loggedinProfile.account_id.toString(),
                conversa_id  : conversID.toString()
            }
            
            dispatch( showLoader(1) );
            const responseDB = await QueryChatUtilities.blockUnblockAccess(passValue);
            dispatch( showLoader(0) );

            if ( Object.entries(responseDB).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

            if ( parseInt(responseDB.success)===0 ){ 
                Utilities.messagePopup('error', responseDB.errorMessage );
                return false; 
            }

            
            Utilities.messagePopup('success', responseDB.successMessage );

            let currentConversationDetailTemp = {  ...currentConversationDetail, access_status : '1' };

            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 3);
            setCurrentConversationDetail(currentConversationDetailTemp);

            dispatch( passConversDetailReducer( currentConversationDetailTemp ) );
        }

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const reverseRequestCommunicationStatus = async () => {
        if ( parseInt(conversID)>0 && parseInt(activeProfile.account_id)>0 ){

            const passValue = {
                api_token : TOKEN.toString(),
                account_id : loggedinProfile.account_id.toString(),
                conversa_id  : conversID.toString()
            }
            
            dispatch( showLoader(1) );
            const responseDB = await QueryChatUtilities.reverseAccessCommunication(passValue);
            dispatch( showLoader(0) );

            if ( Object.entries(responseDB).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

            if ( parseInt(responseDB.success)===0 ){ 
                Utilities.messagePopup('error', responseDB.errorMessage );
                return false; 
            }

            
            Utilities.messagePopup('success', responseDB.successMessage );

            let currentConversationDetailTemp = {  ...currentConversationDetail, access_status : '1' };

            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 4);
            setCurrentConversationDetail(currentConversationDetailTemp);

            dispatch( passConversDetailReducer( currentConversationDetailTemp ) );
        }
    }

    
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const confirmClearMessages = async () => {
        if ( parseInt(conversID)>0 && parseInt(activeProfile.account_id)>0 ){

            await ChatUtilities.clearConversation( activeProfile.account_id, conversID );

            // add here/ update session conversation detail
            // flag 1 + conv_end_date
            
            let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( conversID );
            const now = new Date();
            const utc_timezone = now.toUTCString();
            const utc_created_time = moment.utc(utc_timezone).format(Utilities.dateTimeServerStr.toString());

            currentConversationDetailTemp = { ...currentConversationDetailTemp, ...{ conv_date_details : { ...currentConversationDetailTemp.conv_date_details, clear_flag : 1, conv_end_date : utc_created_time } } };

            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 5);
            setCurrentConversationDetail( currentConversationDetailTemp );
        }
    }


     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
     const setupAllowShareQRCode = ( actionType = 1) => {
        actionType = parseInt(actionType);

        setShowAttachmentOption(0);
        setOnblurSendingOption(0);

        let profile_id = 0;
        let loginBasicInfo = {};

        if ( parseInt( Object.keys(loggedinProfile).length )===0  ){
            
            loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginBasicInfo!==null ){
                loginBasicInfo = JSON.parse(loginBasicInfo);

                /* if ( parseInt(loginBasicInfo.account_type)===1){
                    profile_id = loginBasicInfo.account_id;

                }else{
                    if ( loginBasicInfo.hasOwnProperty('team_company_id')  ){
                        profile_id = loginBasicInfo.team_company_id;
                    }
                } */

                profile_id = loginBasicInfo.account_id;
                if ( loginBasicInfo.hasOwnProperty('company_detail') ){
                    if ( loginBasicInfo.hasOwnProperty('group_id') ){
                        profile_id = loginBasicInfo.company_detail.account_id;
                    }
                }
                
            }
        }else{
            profile_id = loggedinProfile.account_id;
            /* if ( parseInt(loggedinProfile.account_type)===4 ){
                profile_id = loggedinProfile.team_company_id;
            } */

            if ( loggedinProfile.hasOwnProperty('company_detail') ){
                if ( loggedinProfile.hasOwnProperty('group_id') ){
                    profile_id = loggedinProfile.company_detail.account_id;
                }
            }
        }

        if ( actionType===2 ){
            if ( loginBasicInfo.hasOwnProperty('company_detail') ){
                profile_id = loginBasicInfo.company_detail.account_id;
            }
        }

        const formData = {
            api_token : TOKEN.toString(),
            account_id : profile_id.toString(), // loggedinProfile.account_id
            //profile_id : profile_id.toString()
        }

        dispatch( showLoader(1) );

        axios
            .post( ApiUrls.profileGetProfileQrCode ,  formData )
            .then( response => {

                dispatch( showLoader(0) );
                if ( parseInt(response.status)===201 ){

                    if ( parseInt(response.data.success)===1 ){
                        
                        let qrcode_detail = response.data.result.qr_account_details;
                        if ( actionType===2 ){
                            qrcode_detail = qrcode_detail.company_details;
                        }
                        /* message_type : 16,
                        message: "account_id,name,title,logo,qr_code", */
                        
                        let messageContentArray = [];
                        messageContentArray.push(qrcode_detail.account_id.toString());
                        messageContentArray.push(qrcode_detail.name.toString().trim());
                        messageContentArray.push(qrcode_detail.category.toString());
                        messageContentArray.push(qrcode_detail.logo.toString());
                        messageContentArray.push(qrcode_detail.qr_code.toString());
                        messageContentArray.push(qrcode_detail.file_path.toString());

                        messageContentArray = messageContentArray.join('|');
                        console.log(messageContentArray);
                        const messageContent = messageContentArray.toString();


                        ChatUtilities.sendMessage( FIRESTORE, conversation,  activeProfile, requestProfile , 16 , messageContent, {}, {} );
                        setDefaultMessageValue('');

                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage ); 
                    }

                }else{
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGenerateQrCode );
                }
                
            })  
            .catch( (err) => {
                dispatch( showLoader(0) );
                Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGenerateQrCode );
                console.log(err);
            }); 


     }

     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const setupAccountSetting = () => {

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){
                
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);

                // RESTRICT NEW CHAT / REMOVE
                if ( profileLoggedinTemp.hasOwnProperty('flag_handle_status') ){
                    if ( parseInt(profileLoggedinTemp.flag_handle_status)===0 ){
                        setAllowCompose(false);
                    }else{
                        setAllowCompose(true);
                    }
                }else{
                   // setAllowCompose(true);
                }
            }
        }

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handeFormSubmit = async (formDetailRequest = {}, oldFormValues = {}, messageData = {}  ) => {

        if ( Object.keys(formDetailRequest).length>0 ){
  
            let form_status = 0;
            let department_id = 0;
            let department_company_id = 0;
            
            if ( Object.keys(departmentDetail).length>0 ){
                form_status = parseInt(departmentDetail.form_status);
                department_id = parseInt(departmentDetail.group_id);
                department_company_id = parseInt(departmentDetail.account_id);
            }else{
                form_status = formDetail.hasOwnProperty('type') ? parseInt(formDetail.type) : 1;
                department_id = formDetail.hasOwnProperty('department_id') ? parseInt(formDetail.department_id) : 0;
                department_company_id = formDetail.hasOwnProperty('company_id') ? parseInt(formDetail.company_id) : 0;

                if ( department_company_id===0 && requestProfile.hasOwnProperty('team_company_id') ){
                    department_company_id = parseInt(requestProfile.team_company_id);
                }
                
            }
          
            if ( form_status===0 && department_id===0 && department_company_id===0){
                return false;
            }

            let subject = '';
            if ( form_status===3 ){
                subject = formDetailRequest.subject.toString();
            }

            let name = '';
            if ( form_status===1 || form_status===2 ){
                name = formDetailRequest.name.toString();
            }

            let no_people = '';
            if ( form_status===2 ){
                no_people = formDetailRequest.no_people.toString();
            }
            
            let requestDate = '';
            let requestTime = '';

            if ( formDetailRequest.hasOwnProperty('date') ){
                requestDate = formDetailRequest.date;
                requestTime = requestDate.toString()+' '+formDetailRequest.time.toString()+':00';
                
                requestTime = moment(new Date(requestTime.toString())).format(Utilities.timeFormatServerStr.toString()).toLocaleString(); 
                requestDate = moment(new Date(requestDate.toString())).format(Utilities.dayFormatServerStr.toString()).toLocaleString(); 
            }

            const action = parseInt(formDetailRequest.action);

            let groupId = formDetailRequest.hasOwnProperty('group_id') ? parseInt(formDetailRequest.group_id) : 0;

            /* if ( formsAction==='update' ){
                groupId = parseInt(formDetailRequest.group_id);
            } */

            if ( parseInt(groupId)===0 && action!==1 ){ return false; }
            
            let button_name = '';

            if ( action===1 ){

                // AMEND
                const fieldChecker = ['date', 'no_people', 'remarks', 'time'];
                if  ( Object.entries(oldFormValues).length>0 ){

                    let amendmentDetail = {};
                    Object.entries(oldFormValues).map( ([ key, val ]) => {
                        if ( ! Utilities.isEmpty(val) ){
                            val = val.toString().toLowerCase();

                            if ( key in formDetailRequest && fieldChecker.includes(key.toString()) ){
                                const reqValue = formDetailRequest[key].toString().toLowerCase()
                                if ( val!==reqValue ){
                                    amendmentDetail[key] = reqValue;
                                }
                            }
                        }
                    });

                    amendmentDetail = {
                        amendment_date : amendmentDetail.hasOwnProperty('date') ? requestDate.toString() : '',
                        amendment_time : amendmentDetail.hasOwnProperty('time') ? requestTime.toString() : '',
                        amendment_no_people : amendmentDetail.hasOwnProperty('no_people') ? formDetailRequest.no_people.toString() : '',
                        amendment_remarks : amendmentDetail.hasOwnProperty('remarks') ? formDetailRequest.remarks.toString() : '',
                    }
                    
                    const passValues = {
                        api_token : TOKEN.toString(),
                        account_id : activeProfile.account_id.toString(),
                        group_id : groupId.toString(),
                        conversa_id : conversation.convers_id.toString(),
                        amendment_details : amendmentDetail
                    }

                    button_name = 'btn-submit-form';
                    Utilities.elementDisabledStatus(button_name, true );
                    
                    const amendmentResponse = await QueryChatUtilities.amendAppointmentReservation( passValues );
                    Utilities.elementDisabledStatus(button_name, false );
              
                    if ( parseInt(amendmentResponse.success)===1 ){
                        Utilities.messagePopup('success', amendmentResponse.successMessage );
                        handleCloseFormPopUp();
                    }else{
                        Utilities.messagePopup('error', amendmentResponse.errorMessage );
                    }


                // NEW
                }else{
                    
                   
                    const account_department_id = activeProfile.hasOwnProperty('group_id') ? activeProfile.group_id.toString() : '0';
                    
                    const passValues = {
                        api_token : TOKEN.toString(),
                        account_id : activeProfile.account_id.toString(),
                        account_type : activeProfile.account_type.toString(),
                        company_id : department_company_id.toString(),
                        conversa_id : conversation.convers_id.toString(),
                        department_id : department_id.toString(),
                        type : form_status.toString(), // 1 :appointment, 2  reservation: , 3 : complaints
                        name : name,
                        subject : subject,
                        date : requestDate.toString(),
                        time : requestTime.toString(),
                        no_people : no_people,
                        remarks : formDetailRequest.remarks.toString(),
                        account_department_id : account_department_id.toString()
                    }

                    button_name = 'btn-submit-form';
                    Utilities.elementDisabledStatus(button_name, true );
                    
                    const requestResponse = await QueryChatUtilities.requestAppointmentComplaint( passValues );
                    Utilities.elementDisabledStatus(button_name, false );
    
                    if ( parseInt(requestResponse.success)===1 ){
                        Utilities.messagePopup('success', requestResponse.successMessage );
                        handleCloseFormPopUp();
                    }else{
                        Utilities.messagePopup('error', requestResponse.errorMessage );
                    }
                }

                
            }else if ( action===3 ){

                const passValues = {
                    api_token : TOKEN.toString(),
                    account_id : activeProfile.account_id.toString(),
                    group_id : groupId.toString(),
                    conversa_id : conversation.convers_id.toString()
                }


                button_name = 'btn-submit-form-cancel'+( Object.entries(messageData).length>0 ? '-'+messageData.doc_id: '');

                Utilities.elementDisabledStatus(button_name, true );
                
                const cancelationResponse = await QueryChatUtilities.cancelAppointmentReservation( passValues );
                Utilities.elementDisabledStatus(button_name, false );

                if ( parseInt(cancelationResponse.success)===1 ){
                    Utilities.messagePopup('success', cancelationResponse.successMessage );
                    handleCloseFormPopUp();

                    //restartConversation();

                }else{
                    Utilities.messagePopup('error', cancelationResponse.errorMessage );
                }
                
            }else if ( action===4 ){

                const passValues = {
                    api_token : TOKEN.toString(),
                    account_id : activeProfile.account_id.toString(),
                    group_id : groupId.toString(),
                    conversa_id : conversation.convers_id.toString()
                }
 
                button_name = 'btn-submit-reply'+( Object.entries(messageData).length>0 ? '-'+messageData.doc_id: '');
                Utilities.elementDisabledStatus(button_name, true );
                
                const acceptanceResponse = await QueryChatUtilities.acceptAppointmentReservation( passValues );
                Utilities.elementDisabledStatus(button_name, false );

                if ( parseInt(acceptanceResponse.success)===1 ){
                    Utilities.messagePopup('success', acceptanceResponse.successMessage );
                    handleCloseFormPopUp();

                    //restartConversation();
                    
                }else{
                    Utilities.messagePopup('error', acceptanceResponse.errorMessage );
                }

                
            }
            

            if ( Object.entries(messageData).length>0 ){
                jQuery('#chat-conversation--history-messages > li#'+messageData.doc_id+' .button-group-boolean').remove();
            }
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handeComplaintReplySubmit = async ( replyContent ) => {
        if ( Object.keys(formDetail).length===0 ){
            return false;
        }

        if ( Object.keys(replyContent).length>0 ){

            const form_status = parseFloat(replyContent.form_status);

            let  status = 1; //1: accept,  2 reject
            if ( form_status===1 || form_status===3 ){
                status = 2;
            }

            const passValues = {
                api_token : TOKEN.toString(),
                account_id : activeProfile.account_id.toString(),
                convers_id  : conversation.convers_id.toString(),
                group_id : formDetail.group_id.toString(),
                message : replyContent.message.toString()
            }
            
            let button_name = 'btn-submit-reply';
            Utilities.elementDisabledStatus(button_name, true );
            
            const complaintReplyResponse = await QueryChatUtilities.requestComplaintReply( passValues );
            Utilities.elementDisabledStatus(button_name, false );

            if ( parseInt(complaintReplyResponse.success)===1 ){
                Utilities.messagePopup('success', complaintReplyResponse.successMessage );
                handleCloseFormPopUp();
            }else{
                Utilities.messagePopup('error', complaintReplyResponse.errorMessage );
            }

        }

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const handleViewFormDetail = ( messageData  ) => {

        const btn_name = parseInt(messageData.doc_id);
        Utilities.elementDisabledStatus('btn-view-form-detail-'+btn_name, true );

        const msgFormArray = messageData.message.toString().split('|');
        if ( typeof msgFormArray[1]!=='undefined' ){
            const messageDetailArray = JSON.parse(msgFormArray[1]);
            if ( typeof messageDetailArray==='object' ){
                
                dispatch( passConversationMessage( messageDetailArray ) );

                setFormDetail(messageDetailArray);
                setFormsAction('update');
                setShowFormPopUp(true);

                setHideMainContent(true);
                Utilities.elementDisabledStatus('btn-view-form-detail-'+btn_name, false );

            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const recordAudio = () => new Promise(async resolve => {
          
        let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        let audioChunks = [];

        mediaRecorder.addEventListener('dataavailable', event => {
          audioChunks.push(event.data);
        });

        const start = () => {
          audioChunks = [];
          mediaRecorder.start();
        };

        const stop = () => new Promise(resolve => {

            mediaRecorder.addEventListener('stop', () => {
                const audioBlob = new Blob(audioChunks);
                const audioUrl = URL.createObjectURL(audioBlob);
                const audio = new Audio(audioUrl);
                const play = () => audio.play();
                
                resolve({ audioChunks, audioBlob, audioUrl, play });
            });

            /* const track = stream.getTracks()[0];
            track.stop(); */
            
            /* stream.getTracks().forEach(function(track) {
                track.stop();
            }); */

            mediaRecorder.stop();

        });

        resolve({ start, stop });
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const triggerAudio = ( action = 0 ) => {
        action = parseInt(action);

        if ( action===1 ){
            jQuery('#record').trigger('click');
        }else{
            jQuery('#stop').trigger('click');
        }

        handleClickTextArea();
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleCloseTransferContent = () => {
        setCoverMainContent(false);
    }


    const showHideComposer = ( currentConversationDetail = {} ) => {

        if ( Object.keys(currentConversationDetail).length>0 ){
            const chatDetail = currentConversationDetail;


            let isAllowCompose = 0;
            if ( chatDetail.hasOwnProperty('access_status') ){
                
                if ( parseInt(chatDetail.access_status)===1 ){
                    //setAllowCompose(true);
                    isAllowCompose = 1;
                }else{
                    isAllowCompose = 0;
                    //setAllowCompose(false);
                }
            }

            if ( isAllowCompose===1 && ![2,6].includes( parseInt(chatDetail.flag) ) ){

                const blockValue = parseInt(chatDetail.block_status.block_in_status) + parseInt(chatDetail.block_status.block_out_status);
                if ( parseInt(blockValue)>0 ){
                    //setAllowCompose(false);
                    setBlockStatus(true);
                    isAllowCompose = 0;

                    let showBlockWarning = false;
                    if ( parseInt(chatDetail.block_status.block_out_status) ){
                        showBlockWarning = true;
                    }

                    setBlockStatusHtml(showBlockWarning);

                }else{
                    //setAllowCompose(true);
                    setBlockStatus(false);
                    isAllowCompose = 1;
                }
                

                if ( isAllowCompose===1  ) {
                    setAllowCompose(true);
                }else{
                    setAllowCompose(false);
                }
                
            }

            if ( isAllowCompose===1 ){
                
                let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                if ( divertReplyInfo!==null ){
                    divertReplyInfo = JSON.parse(divertReplyInfo);
                    
                    if ( parseInt(divertReplyInfo.auto_replay_status)===1 ){
                        setAllowCompose(false);
                        setCantAutoReplayLabel(Utilities.labelYouHaveEnabledAutoReply);
                        isAllowCompose = 0;

                    }else{

                        setCantAutoReplayLabel('');

                        if ( isAllowCompose===0 ){
                            setAllowCompose(false);
                        }else{
                            setAllowCompose(true);
                            isAllowCompose = 1;
                        }
                        
                    }
                
                }

                ///if ( isInternalChat===false || parseInt(isAllowCompose)===1 ){
                if ( isInternalChat===false && isAllowCompose===1 ){
                    setupAccountSetting();
                }
            }
        }

    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const htmlBlockWarning = () => {
        
        if ( blockStatus===true ){

            let chatDetail =  {};
            if ( parseInt(conversID)>0 ){
                chatDetail = ChatUtilities.getAccountConversationDetail( conversID );
            }

            if  ( blockStatusHtml===false ){
                return <div className="chat-warning-message">{cantAccessLabel}</div>

            }else{

                let htmlBLock = <div className="chat-warning-message">{ Utilities.labelYouCantReplyThisConversation }</div>
                if ( parseInt(chatDetail.block_status.block_out_status)===1 ){
                    htmlBLock = <div className="chat-warning-message cursor-pointer" onClick={ () => dispatch( chatSidebarBlock('block') ) }>unblock</div>
                }
                return htmlBLock;
            }  

        }else{
            return '';
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const leaveCompanyGroups = async () => {

        const paramLeave = {
            api_token : TOKEN.toString(),
            account_id : activeProfile.account_id.toString(),
            convers_id : currentConversationDetail.convers_id.toString(),
            group_id :  currentConversationDetail.id.toString()
        }

            dispatch( showLoader(1) );
            const responseDB = await leaveFromGroups(paramLeave);
            dispatch( showLoader(0) );

            if ( Object.entries(responseDB).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

                if ( parseInt(responseDB.success)===0 ){ 
                    Utilities.messagePopup('error', responseDB.errorMessage );
                    return false; 
                }

                //Utilities.messagePopup('success', responseDB.successMessage );
                Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyLeaved );  
                handleGoBack();
    }

    
    const sendCompanyGroupsMembers = ( optionType = 0) => {

        setSelectedMessageDetail({});
        dispatch( chatSidebarBlock('') );
        
        setTimeout( () => {  dispatch( chatSidebarBlock('select-company-groups-members') );   }, 200 );

        setOnblurSendingOption(optionType);
    }


    const acceptRejectJoinInvitationCompanyGroups = async ( messageData = {}, actionName = '' ) =>{

        // flag  : 1 : accept, 2 : reject
        // result_flag  : 0 : conversation screen, 1 : pending list

        if ( Object.keys(messageData).length>0 && ! Utilities.isEmpty(actionName) ){

            const paramInvitation = {
                api_token : TOKEN.toString(),
                account_id : (loggedinProfile.account_id ?? 0).toString(),
                group_id : (currentConversationDetail.id ?? '0').toString(),
                conversa_id : (currentConversationDetail.convers_id ?? '0').toString(),
                profile_id : (messageData.account_id ?? '0').toString(),
                flag : actionName==='accept' ? '1' : '2',
                result_flag : "0"
            }

                const button_name = actionName==='accept' ? `btn-comp-group-access-accept-${messageData.doc_id}` : `btn-comp-group-access-reject-${messageData.doc_id}`;

                Utilities.elementDisabledStatus(button_name, true );
                const responseDB = await joinInvitationResponse(paramInvitation);
                Utilities.elementDisabledStatus(button_name, false );

                
                if ( Object.entries(responseDB).length===0 ){ 
                    Utilities.messagePopup('error', errorMsg );
                    return false; 
                }

                    if ( parseInt(responseDB.success)===0 ){ 
                        Utilities.messagePopup('error', responseDB.errorMessage );
                        return false; 
                    }

                    Utilities.messagePopup('success', responseDB.successMessage );
                    jQuery(`#${button_name}`).closest('li').prev('li.message-date-separator').remove();
                    jQuery(`#${button_name}`).closest('li').remove();
                    
                    // UPDATE
                    let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( parseInt(paramInvitation.conversa_id) );

                    if ( actionName==='accept' ){

                        const groupMembers_count = parseInt(currentConversationDetailTemp.group_members_count ?? 0) + 1;
                        let groupMmebers = [];

                        if ( ! Utilities.isEmpty(currentConversationDetailTemp.group_members) ){
                            groupMmebers = [...currentConversationDetailTemp.group_members.split(",")];
                            groupMmebers.push(messageData.account_id.toString());
                        }

                        currentConversationDetailTemp = { 
                                                        ...currentConversationDetailTemp,
                                                        group_members : groupMmebers.join(","),
                                                        group_members_count : groupMembers_count.toString()
                                                    };
                       

                    }

                        let groupPendingMembers_count = parseInt(currentConversationDetailTemp.pending_group_members_count ?? 0) - 1;
                        groupPendingMembers_count = groupPendingMembers_count<0 ? 0 : groupPendingMembers_count;
    
                        currentConversationDetailTemp = { 
                                            ...currentConversationDetailTemp,
                                            pending_group_members_count : groupPendingMembers_count.toString()
                                        };

                        ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);
                        setCurrentConversationDetail(currentConversationDetailTemp);

                        // REINITIALIZE CONVERSATION
                        /* const conversationDetailList = {
                            convers_id : parseInt(paramInvitation.conversa_id),
                            owner_account_id : parseInt(loggedinProfile.account_id)
                        }

                        reInitializeConversation( conversationDetailList); */
                        //getConversations( conversationDetailList , 1 );
    

        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const reInitializeConversation = ( conversationDetailList = {} ) => {
        
        if ( Object.keys(conversationDetailList).length===0 ){
            conversationDetailList = {
                convers_id : parseInt(conversID),
                owner_account_id : parseInt(loggedinProfile.account_id)
            }
        }

        if ( Object.keys(conversationDetailList).length>0 ){
            getConversations( conversationDetailList , 1 );
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

     const handleGoBack = () => {
        let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        if ( loginType!==null ){
            loginType = JSON.parse(loginType);
            loginType = parseInt(loginType);

            let urlDashboard = loginType===2 ? `/${UrlSlugs.departmentDashboard}` : `/${UrlSlugs.dashboard}`;
            history.push( urlDashboard );

        }else{
            history.push(`/${UrlSlugs.departmentDashboard}`);
        }
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    const sendingOptionButtons = () => {

        const conversFlag =  parseInt(currentConversationDetail.flag || 0);
 
        const normalOptionHtml =  <>
                                    <Dropdown drop="up" alignRight className="btn-icon">
                                        <Dropdown.Toggle variant="dropdown-icon" className="p-0 btn-icon btn-plus" title="attachments"></Dropdown.Toggle>
                                        <Dropdown.Menu className="fs-14 bg-transparent p-0 border-0" style={{ width: 37, minWidth : 0}}>
                                            {  allowShareQRCode && <Dropdown.Item id="btn-share-qrcode" className="btn-icon btn-qr-code  p-0 mb-1" onClick={ () => setupAllowShareQRCode() } title="qrcode"></Dropdown.Item> }
                                            <Dropdown.Item className="btn-icon btn-map-pin p-0 mb-1" onClick={ () => handleShowChatMap() } title="map"></Dropdown.Item>
                                            <Dropdown.Item className="btn-icon btn-file p-0 mb-1" onClick={ () => toggleSendDocumentOption() } title="document"></Dropdown.Item>
                                            <Dropdown.Item className="btn-icon btn-image p-0 mb-1" onClick={ () => selectImage() } title="image"></Dropdown.Item>
                                        </Dropdown.Menu>

                                    </Dropdown>

                                    <div>
                                        <div id="button-record" type="button" className="btn-icon btn-mic" onClick={ () => triggerAudio(1) } title="start recording"></div>
                                        <div id="button-stop" type="button" className="btn-icon btn-mic active blinking d-none" onClick={ () => triggerAudio(0) } title="stop recording"></div>
                                    </div>

                                    <div className="btn-icon btn-paper-plane" onClick={ () => submitTextMessage() } title="send"></div>
                                </>

        if( [6].includes(conversFlag) ){

            const membersCounter = currentConversationDetail.group_members_count ?? 0;
            const notAllowSelectedMemberOptionHtml = <Dropdown.Item className="opacity-05 cursor-notallow">{ TitlesLabels.general.sendToSelectedMembers }</Dropdown.Item>

            return <>
                    {
                         onblurSendingOption===0 &&
                         <>
                            <Dropdown drop="up" alignRight className="btn-icon">
                                <Dropdown.Toggle variant="dropdown-icon" className="p-0 btn-icon btn-plus" title="attachments"></Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14">
                                    <Dropdown.Item onClick={ () => setOnblurSendingOption(3) }>send to all ({ membersCounter })</Dropdown.Item>
                                    { membersCounter>1 ?  <Dropdown.Item onClick={ () => sendCompanyGroupsMembers(33) }>{ TitlesLabels.general.sendToSelectedMembers }</Dropdown.Item> : notAllowSelectedMemberOptionHtml }
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown drop="up" alignRight className="btn-icon">
                                <Dropdown.Toggle variant="dropdown-icon" id="btn-send-company-groups-record" className="p-0 btn-icon btn-mic" title="start recording"></Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14">
                                    <Dropdown.Item onClick={ () => { setOnblurSendingOption(2); triggerAudio(1); } }>send to all ({ membersCounter })</Dropdown.Item>
                                    { membersCounter>1 ?  <Dropdown.Item onClick={ () => sendCompanyGroupsMembers(22) }>{ TitlesLabels.general.sendToSelectedMembers }</Dropdown.Item> : notAllowSelectedMemberOptionHtml }
                                    </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown drop="up" alignRight className="btn-icon">
                                <Dropdown.Toggle variant="dropdown-icon" id="btn-send-company-groups-option" className="p-0 btn-icon btn-paper-plane"  title="send"></Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14">
                                    <Dropdown.Item onClick={ () => submitTextMessage() }>send to all ({ membersCounter })</Dropdown.Item>
                                    { membersCounter>1 ?  <Dropdown.Item onClick={ () => sendCompanyGroupsMembers(1) }>{ TitlesLabels.general.sendToSelectedMembers }</Dropdown.Item> : notAllowSelectedMemberOptionHtml }
                                </Dropdown.Menu>
                            </Dropdown>
                         </>
                    }
                 
                    { 
                        onblurSendingOption===3 &&
                            <Dropdown drop="up" alignRight className="btn-icon" show >
                                <Dropdown.Toggle variant="dropdown-icon" className="p-0 btn-icon btn-plus btn-toggle" title="attachments" onClick={ () => setOnblurSendingOption(0) }></Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14 bg-transparent p-0 border-0" style={{ width: 30, minWidth : 0}}>
                                    <Dropdown.Item className="btn-icon btn-map-pin p-0 mb-1" onClick={ () => handleShowChatMap() } title="map"></Dropdown.Item>
                                    <Dropdown.Item className="btn-icon btn-file p-0 mb-1" onClick={ () => toggleSendDocumentOption() } title="document"></Dropdown.Item>
                                    <Dropdown.Item className="btn-icon btn-image p-0 mb-1" onClick={ () => selectImage() } title="image"></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                    }

                    {
                        onblurSendingOption===2 &&
                        <>
                            <div className="btn-icon cursor-auto"></div>
                            <div id="button-stop" type="button" className="btn-icon btn-mic active blinking" onClick={ () =>{ triggerAudio(0); setOnblurSendingOption(0);  }} title="stop recording"></div>
                        </>
                    }
                    
                </>
            
        }else{
            return normalOptionHtml
        }
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleCloseChatSidebar = () => {
        setSidebarViewType('');
        dispatch( chatSidebarBlock('') );
        setOnblurSendingOption(0);
        ChatUtilities.globalCompanyGroupsMemberSelected = [];
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect( async () => {
        
        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle( TitlesLabels.siteTitles.chat ) );
        dispatch( showFooter(1) );

        let reqAccountID = match.params.id;

        let chatType = match.params.type;
        let conversValues = match.params.coversid;
        let workOrDeptID = match.params.wid;

        /* const pathname = window.location.pathname; 
        let segment = pathname.split('/');
        segment = segment.filter(Boolean); */

        const segment = Utilities.getUrlSegments();

       
        /* setCoverMainContent(false);
        dispatch( chatSidebarBlock('') ); */

        let currentConversID = 0;

        // EXISTING
        if ( segment[0].toString().toLocaleLowerCase()==='chat-conversation'){
            
            let encodedValues = ChatUtilities.decryptChatDetail(conversValues);
            currentConversID = encodedValues.convers_id.toString();

            const conversationDetailList = {
                convers_id : currentConversID,
                convers_status_flag : encodedValues.convers_status_flag.toString(),
                department_status_flag : encodedValues.department_status_flag.toString(),
            }

            
            setConversID(currentConversID);
            setConversation(conversationDetailList);
            globalConversation = conversationDetailList;
            
            jQuery('#hidden-convers-id').text(currentConversID);

            dispatch( showHeader(3) );
            reqAccountID = atob(reqAccountID);
            
            let is_internal = 0;
            if (  parseInt(encodedValues.internal_account_id)>0 ){
                is_internal = 1;
                setIsInternalChat(true);
            }


            if ( parseInt( encodedValues.department_status_flag )>0 ){
                await setupProfileDepartment(reqAccountID , encodedValues.department_status_flag , 0);
            }else{

                /* const decryptedArr = atob(segment[2].toString()).split('-');
                reqAccountID = decryptedArr[3]; */
 
                await setupProfile(reqAccountID, is_internal , 0);
            }
           

            if ( parseInt(is_internal)===0 ){
                //setupAccountSetting();

                const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( currentConversID );
                showHideComposer( currentConversationDetailTemp  );

            }else{

                const teamMemberDetail = SessionUtilities.getProfileDetail(reqAccountID);
                dispatch( passInternalProfile(teamMemberDetail) );
            }

    

        // NEW CHAT REQUEST
        }else{

            if ( typeof chatType!=='undefined' ){ 
                dispatch( showHeader(4) );

                reqAccountID = atob(reqAccountID);
                chatType = parseInt(chatType);

                if (chatType===2){   // internal chat
                    setupProfile(reqAccountID, 1);

                    const teamMemberDetail = SessionUtilities.getProfileDetail(reqAccountID);
                    dispatch( passInternalProfile(teamMemberDetail) );

                }else if (chatType===3){ // department

    
                    if ( typeof workOrDeptID==='undefined' ){  // department without dept id, then get general as default
                        setupProfileDepartment(reqAccountID , 0, 1);

                    }else{ // department with dept id

                        workOrDeptID = atob(workOrDeptID);
                        setupProfileDepartment(reqAccountID, workOrDeptID, 1);
                    }

                }

            // normal chat /  from team search
            }else{
                dispatch( showHeader(3) );
                reqAccountID = atob(reqAccountID);
                setupProfile(reqAccountID);

            }

        }

        // RECORD AUDIO
        setTimeout(() => {

                let recorder;
                let audio;
                let audioStatus = false;

                const recordButton = document.querySelector('#record');
                const stopButton = document.querySelector('#stop');
                const playButton = document.querySelector('#play');
                
                recordButton.addEventListener('click', async () => {

                    if ( audioStatus===false ){
                        try {
                            const microphoneStatus = await navigator.mediaDevices.getUserMedia({ audio: true });
                            if ( microphoneStatus.active===true ){
                               
                                recordButton.setAttribute('disabled', true);
                                stopButton.removeAttribute('disabled');
                                playButton.setAttribute('disabled', true);

                                jQuery('#button-record').addClass('d-none');
                                jQuery('#button-stop').removeClass('d-none');

                                if (!recorder) {
                                    recorder = await recordAudio();
                                }

                                audioStatus = true;
                                recorder.start();
                            }else{
                                Utilities.messagePopup('error', TitlesLabels.alertMessages.microphoneIsNotDetected );
                            }
                        } catch(err) {
                            Utilities.messagePopup('error', TitlesLabels.alertMessages.microphoneIsNotDetected );
                        }
                    }else{
                        recordButton.setAttribute('disabled', true);
                        stopButton.removeAttribute('disabled');
                        playButton.setAttribute('disabled', true);

                        jQuery('#button-record').addClass('d-none');
                        jQuery('#button-stop').removeClass('d-none');

                        if (!recorder) {
                            recorder = await recordAudio();
                        }
                        recorder.start();
                    }
                    
                });
                
                stopButton.addEventListener('click', async () => {

                    recordButton.removeAttribute('disabled');
                    stopButton.setAttribute('disabled', true);
                    playButton.removeAttribute('disabled');
                    audio = await recorder.stop();
            

                    let audioDuration = await getBlobDuration(audio.audioBlob);
                    audioDuration = ChatUtilities.secondsToMinutes(audioDuration);

                    const reader = new FileReader();
                    reader.readAsDataURL(audio.audioBlob);
                    reader.onload = () => {
                        const base64AudioMessage = reader.result.split(',')[1];
                        sendAudio(base64AudioMessage, audioDuration );
                    };

                    jQuery('#button-record').removeClass('d-none');
                    jQuery('#button-stop').addClass('d-none');

                });
                
                playButton.addEventListener('click', () => {
                    audio.play();
                });


                const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( currentConversID );
                showHideComposer( currentConversationDetailTemp  );

        }, 1000 );

        
        jQuery('#chat-conversation--history').on('scroll', function(e) {     
            e.preventDefault();

            let scroll_allow = jQuery(this).attr('scroll');
            let  scroll_pos = jQuery(this).scrollTop();
            scroll_pos = parseInt(scroll_pos);
            
              if ( scroll_pos===0 && scroll_allow==='true' ) {
                   getPreviousConversations();
              }  
        });


          // ALLOW QRCODE
          let allowShareQR = true; //false;

          let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
          if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);
              
              let profile_request = loginBasicInfo;

              /* if ( parseInt(loginBasicInfo.account_type)===1){
                  allowShareQR = true;
  
              }else{
                    //if ( loginBasicInfo.hasOwnProperty('team_is_admin') ){
                    if ( loginBasicInfo.hasOwnProperty('team_company_id') ){
                      //const team_is_admin = loginBasicInfo.team_is_admin;
      
                        //if ( parseInt(team_is_admin)===1 && parseInt(loginBasicInfo.flag)===4 ){
                        //if ( parseInt(loginBasicInfo.flag)===4 ){
                          allowShareQR = true;
                          profile_request = loginBasicInfo; //loginBasicInfo.company_detail;
                      //}
                    }
              } */

              setLoggedinProfile(profile_request);
             
          }
          setAllowShareQRCode(allowShareQR);


          // CHECK IF DIRECT SHARE QRCODE
          if ( [1,2].includes(parseInt(shareQRCodeAction)) ){
                dispatch( showLoader(1) );
                dispatch( shareQRCode(0) );
          }

    }, [] );


    // CLEAN UP
    useEffect( () => {
        setShowSearchProfile(0);
        
        let timerCompose = setTimeout( () => {
            document.getElementById('input_chat_message').focus();
            clearTimeout(timerCompose);
            
          }, 1500);

          
          return async () => {
            clearTimeout(timerCompose);
            dispatch( showChatSearch(0) );
            setOnblurSendingOption(0);
            ChatUtilities.globalCompanyGroupsMemberSelected = [];
          }

    }, [] );
   
    useEffect( () => {
        setShowSearchProfile( showChat );
        setShowSendDocument(false);
        handleClickTextArea();
    }, [showChat]);


    useEffect( () => {
        if ( !Utilities.isEmpty(passChatMessageTextValue) ){
            submitTextMessage( passChatMessageTextValue.toString() );
            dispatch( passChatMessageText('') );
        }
    }, [passChatMessageTextValue] );


    useEffect( () => {
        if ( blockUnblockChat===true ){
            confirmBlock();
            dispatch( blockUnblockChatReducer(false) );
        }
    }, [blockUnblockChat] );
    
    
    useEffect( () => {

        setShowSendDocument(false);
        handleClickTextArea();

        let sidebarBlockAction = sidebarBlock.toString().toLowerCase();
        
        if ( ['block', 'clear'].includes( sidebarBlockAction ) ){

            if ( sidebarBlock==='block' ){

                const blockUnblockLabel = currentConversationDetail.hasOwnProperty('block_status') && parseInt(currentConversationDetail.block_status.block_out_status || 0)===0 ? 'block' : 'unblock';
                
                confirmAlert({
                    title: '',
                    message: `Are you sure you want to ${blockUnblockLabel} user?`,
                    overlayClassName : 'normal-font-style',
                    buttons: [
                        { label: 'No',  onClick: () => {} },
                        { label: 'Yes',  onClick: () => { confirmBlock(); } }
                    ]
                }); 

                dispatch( chatSidebarBlock('') );

            }else if ( sidebarBlockAction==='clear' ){

                confirmAlert({
                    title: '',
                    message: TitlesLabels.confirmMessages.wantToClearMessages,
                    overlayClassName : 'normal-font-style',
                    buttons: [
                        { label: 'No',  onClick: () => {} },
                        { label: 'Yes',  onClick: () => { confirmClearMessages() } }
                    ]
                }); 

                dispatch( chatSidebarBlock('') );
            }
        
            
        }else if ( sidebarBlockAction==='reverse-rejection' ){

            confirmAlert({
                title: '',
                message: `Are you sure you want to ${ sidebarBlockAction.replaceAll('-', ' ') }?`,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'No',  onClick: () => {} },
                    { label: 'Yes',  onClick: () => { reverseRequestConnectionStatus(); } }
                ]
            }); 

            dispatch( chatSidebarBlock('') );

        }else if ( sidebarBlockAction==='leave-company-group' ){

                confirmAlert({
                    title: '',
                    message: `Are you sure you want to leave the group?`,
                    overlayClassName : 'normal-font-style',
                    buttons: [
                        { label: 'No',  onClick: () => {} },
                        { label: 'Yes',  onClick: () => leaveCompanyGroups() }
                    ]
                }); 
    
                dispatch( chatSidebarBlock('') );
    
        }else if ( sidebarBlockAction==='reverse-rejection-communication' ){

            confirmAlert({
                title: '',
                message: `Are you sure you want to ${ sidebarBlockAction.replaceAll('-', ' ') }?`,
                overlayClassName : 'normal-font-style',
                buttons: [
                    { label: 'No',  onClick: () => {} },
                    { label: 'Yes',  onClick: () => { reverseRequestCommunicationStatus(); } }
                ]
            }); 

            dispatch( chatSidebarBlock('') );

        }else if ( ['forms'].includes( sidebarBlockAction ) ){

            //if ( Object.keys(departmentDetail).length>0 ){
                //let form_status = parseInt(departmentDetail.form_status);

                setFormsAction('create');
                setShowFormPopUp(true);
                dispatch( chatSidebarBlock('') );
            //}
            
        }else if ( sidebarBlockAction==='transferchat' ){
            setCoverMainContent(true);

        }else{
            setSidebarViewType( sidebarBlockAction );
        }
        
        if ( !Utilities.isEmpty(sidebarBlockAction) ){
            setShowSearchProfile( 0 );
            dispatch( showChatSearch(0) );

        }else{
            setCoverMainContent(false);
        }
        
    }, [sidebarBlock]);


    useEffect( () => {
        if ( Object.keys(passConversDetail).length>0 ){
            setCurrentConversationDetail( passConversDetail );
        }
    }, [passConversDetail] );
    

    useEffect( () => {
        
        if (  Object.keys(passDeleteMediaList).length>0 ){
            deleteSelectedMedias(passDeleteMediaList);
        }
        
    }, [ passDeleteMediaList ]);
    

    useEffect( () => {

       if ( Object.keys(currentConversationDetail).length>0 ){
            showHideComposer(currentConversationDetail);

            if ( currentConversationDetail.hasOwnProperty('default_msg_details') && parseInt(currentConversationDetail.default_msg_details.default_msg_status || 0)===1 ) {
                setChatHeaderProfile( currentConversationDetail.default_msg_details );
            }
        
       }
       
    }, [currentConversationDetail] );


    useEffect( () => {

        if ( Object.keys(passForwardConversId).length>0 ){
            forwardMesageSubmit( passForwardConversId );
        }
    }, [passForwardConversId]);

    useEffect( () => {

        if ( Object.keys(passCompanyGroupsMembersIds).length>0 ){
            ChatUtilities.globalCompanyGroupsMemberSelected = passCompanyGroupsMembersIds;

            if ( onblurSendingOption===1 ){
                submitTextMessage();
                setOnblurSendingOption(0);
                
            }else if ( onblurSendingOption===22 ){
                setOnblurSendingOption(2);

            }else if ( onblurSendingOption===33 ){
                setOnblurSendingOption(3);
            }

            dispatch( passCompanyGroupsMembersIdsReducer([]) );
            dispatch( chatSidebarBlock('') );

        }
    }, [passCompanyGroupsMembersIds]);


    useEffect( () => {
        if ( ! Utilities.isEmpty(booleanChatReInitializeConversation) ){
            reInitializeConversation();
            dispatch( booleanChatReInitializeConversationReducer("") );
        }
    }, [booleanChatReInitializeConversation] ); 

    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="chat-page">
            
            <div className="d-none">
                <div id="hidden-convers-id">0</div>
                {/* <div id="first-messageid"></div> */}
                <div id="prev-messageid"></div>
                {/* <div id="prev-messageids"></div> */}
            </div>

            <div className={`main-content ${ ( chatHeaderProfile.hasOwnProperty('default_msg_status') && parseInt(chatHeaderProfile.default_msg_status || 0)===1 ) ? 'pt-0' : '' }`}>
                {
                    ( chatHeaderProfile.hasOwnProperty('default_msg_status') && parseInt(chatHeaderProfile.default_msg_status || 0)===1 ) &&
                        <div className={`subheader-chat ${ hideMainContent===true ? 'opacity-0' : ''}`}>
                            <div className="container">
                                <div className="col-7 mx-auto text-center line-height-1-2" dangerouslySetInnerHTML={{ __html: ChatUtilities.replaceURLs( chatHeaderProfile.default_message )  }}></div>
                            </div>
                        </div>  
                }
                

                {
                    coverMainContent===true &&
                        <div className="chat-transfer-container"><ChatTransfer  activeProfile={ activeProfile } handleCloseTransfer={handleCloseTransferContent} conversID={ conversID } /></div>
                }

                {
                    showSendDocument===true &&
                        <ChatSendDococument toggleSendDocumentOption={toggleSendDocumentOption} uploadSendDocument={uploadSendDocument} conversID={ conversID } activeProfile={ activeProfile }  requestProfile={requestProfile} />
                }
                

                <div id="chat-main-container" className={`container ${ hideMainContent===true ? 'opacity-0' : '' } ${ coverMainContent===true ? 'd-none' : '' }`}>

                        <div className={ `chat-conversation-content ${ showSearchProfile===1 ? 'd-none' : '' }` }>

                            <div id="chat-conversation--history" className={`chat-conversation--history ${ ( chatHeaderProfile.hasOwnProperty('default_msg_status') && parseInt(chatHeaderProfile.default_msg_status || 0)===1 ) ? 'mt-3' : '' }`} scroll="true">

                                <ul id="chat-conversation--history-messages" className={`chat-conversation--history-messages checkbox-radio-default checkbox-radio-check-icon ${ [2,6].includes(parseInt(currentConversationDetail.flag)) ? 'chat-group-conversation' : ''}`}>
                                    <ChatConversation previousConversations={previousConversations}  presentConversations={ presentConversations } ownerIdConversation={ownerIdConversation} deleteMesage={ deleteMesage } forwardMesageOption={ forwardMesageOption } setupReplyContent={ setupReplyContent } acceptRejectRequest={acceptRejectRequest} currentConversationDetail={ currentConversationDetail }  conversID={ conversID } handleViewFormDetail={handleViewFormDetail} messagesSelectedDetail={messagesSelectedDetail} bolUncheckSelectedMessages={bolUncheckSelectedMessages} bolClearSelectedMessages={bolClearSelectedMessages} isSearch={false} requestProfile={requestProfile} handeFormSubmit={handeFormSubmit}  setCurrentConversationDetail={ setCurrentConversationDetail } acceptRejectJoinInvitationCompanyGroups={acceptRejectJoinInvitationCompanyGroups} match={match}/>
                                </ul>

                            </div>

                            <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeChatImageAcceptExtension } readOnly onChange={ (e) => sendAttachment(e) } ></input>
                            
                           
                          { 
                            ( allowCompose===true ) &&
                                <div className="chat-compose-wrap fade-in">
                                    
                                    {
                                        ! Utilities.isEmpty(replyContentHtml) &&
                                        <div className="chat-reply-content">
                                            <button type="button" className="btn-close-reply" onClick={ () => removeReplyContent() }><span className="ion-ios7-close-empty"></span></button>
                                            <div dangerouslySetInnerHTML={{ __html: replyContentHtml }}></div>
                                        </div>
                                    }

                                    <>
                                        <div className={`chat-compose-content ${ parseInt(selectedMessagesDetail.length)>0 ? 'd-none' : '' }`}>
                                            <div>
                                                <textarea  id="input_chat_message" name="input-chat-message"  className="input-message" placeholder="type message here"  onChange={ (e) => pushValue( e, 'message-content') } value={ defaultMessageValue } onKeyDown={ (e) => triggerTextMessage(e) } onClick={ () =>{ handleClickTextArea(); setOnblurSendingOption(0); }}></textarea>
                                                <div className="text-left fs-12 text-gray-2">hold shift key + enter for new line</div>
                                            </div>

                                            { sendingOptionButtons() }
                                            
                                            <div id="btn-share-qrcode-company" className="d-none" onClick={ () => setupAllowShareQRCode(2) }></div>

                                        </div>

                                        

                                        <div className={`chat-delete-forward-wrap ${ parseInt(selectedMessagesDetail.length)>0 ? '' : 'd-none' } `}>
                                            <div className="d-flex align-items-center btn-option  bordered-white">
                                                <div className="btn-icon btn-remove mr-3 ml-0" onClick={ () => uncheckSelecedMessages() }></div>
                                                <div>{ selectedMessagesDetail.length } selected</div>
                                            </div>

                                            <div className="chat-delete-forward-buttons btn-option  bordered-white d-flex align-items-center">
                                                <div className="btn-icon btn-trash" onClick={ () => confirmMessageDeletion() } title="delete"></div>
                                                
                                                {
                                                    parseInt(selectedMessagesDetail.length)===1 &&
                                                    <div className="btn-icon btn-arrow-left-curve  ml-2" onClick={ () => replyMultipleMessage() } title="reply"></div>
                                                }
                                                <div className="btn-icon btn-arrow-right-curve  ml-2" onClick={ () => forwardMultipleMessages() } title="forward"></div>
                                            </div>
                                        </div>


                                    </>
                                </div>
                          }


                            {
                                ( allowCompose===false && ! Utilities.isEmpty(currentConversationDetail.handle_account_name ?? '') ) &&
                                    <div className="chat-warning-message">handled by {currentConversationDetail.handle_account_name}</div>
                            }

                            {
                                ( showNewMessageWarning===true && ![6].includes( parseInt(currentConversationDetail.flag) ) ) &&
                                <div className="bg-gray py-4 px-5 font-gotham-book mt-n5 position-relative">
                                    <div>{ TitlesLabels.general.chatNewMessageWarning }</div>
                                    <div className="text-danger mt-2">{ TitlesLabels.general.chatNewMessageInfo }</div>

                                    <div className="button-group-boolean column-3 mt-4">
                                        <button type="button" className=" btn-theme-black" onClick={ () => dispatch( chatSidebarBlock('block') ) }>block</button>
                                        <button type="button" className=" btn-theme-black" onClick={ () => dispatch( chatSidebarBlock('report') ) }>block & report</button>
                                        <button type="button" className=" btn-theme-black" onClick={ () => deleteWarningNewMessage(true) }>continue</button>
                                    </div>
                                    
                                </div>
                            }


                            {
                                blockStatus===true ?
                                    htmlBlockWarning()
                                :
                                <>
                                    {
                                        parseInt( currentConversationDetail.hasOwnProperty('block_status') ? ( currentConversationDetail.block_status.block_out_status ?? 0 ) : 0 )===1 ?
                                            <div className="chat-warning-message cursor-pointer" onClick={ () => dispatch( chatSidebarBlock('block') ) }>unblock</div>
                                        :
                                            <>
                                                {  
                                                    [1,3].includes( parseInt(currentConversationDetail.team_restrict_status ?? 0) ) && 
                                                        <div className="chat-warning-message bg-gray-theme text-dark">{Utilities.labelAwaitingPemissionToConnect}</div>
                                                }
                                                {  
                                                    [2].includes( parseInt(currentConversationDetail.team_restrict_status ?? 0) ) && 
                                                        <div className="chat-warning-message bg-gray-theme text-dark">{cantAccessLabel}</div>
                                                }
                                                {  
                                                    ( 
                                                        ! [1,2,3].includes( parseInt(currentConversationDetail.team_restrict_status ?? 0) ) &&
                                                        [2,3,4].includes( parseInt(currentConversationDetail.access_status ?? 0) )
                                                    ) && 
                                                        <div className="chat-warning-message bg-gray-theme text-dark">{cantAccessLabel}</div>
                                                }
                                            </>
                                    }
                                    
                                </> 
                            }      
   

                            {  ! Utilities.isEmpty(cantAutoReplayLabel) &&  <div className="chat-warning-message">{cantAutoReplayLabel}</div> }

                        </div>

                        
                        <div className="d-none">
                            {  allowCompose===false && <textarea  id="input_chat_message" name="input-chat-message"  className="input-message d-none" /> }

                            <button id="record">Record</button>
                            <button id="stop" disabled>Stop</button>
                            <button id="play" disabled>Play</button>
                        </div>
                        

                        {
                            showSearchProfile===1 &&
                            <ChatSearch showSearchProfile={ showSearchProfile } toggleSearchProfile={ toggleSearchProfile }  conversationDetail={currentConversationDetail} firestore={FIRESTORE}  fsCollection={FS_DBCOLLECTION} showMessagesFromSearch={showMessagesFromSearch} />
                        }
                        
                        
                        <ChatMap showChatMap={ showChatMap } handleCloseChatMap={ handleCloseChatMap } submitGEOLocation={ submitGEOLocation } />

                        { 
                            ( !Utilities.isEmpty(sidebarViewType)  && sidebarViewType!=='transferchat') && 
                                <div className="chat-sidebar">
                                    <div className="btn-close-chat-sidebar" onClick={ () => handleCloseChatSidebar() }></div>
                                    <ChatSidebar viewType={ sidebarViewType } activeProfile={ activeProfile }  conversID={ conversID } messagesSelectedDetailInMedia={messagesSelectedDetailInMedia} />
                                </div>
                        }

                </div>
                
            </div>

            <DepartmentForms departmentDetail={departmentDetail} handeFormSubmit={handeFormSubmit} handleCloseFormPopUp={handleCloseFormPopUp} showFormPopUp={showFormPopUp} pageName="chat" action={formsAction} formDetail={formDetail}  timeStamp={ new Date() }  handeComplaintReplySubmit={handeComplaintReplySubmit} />
            

       </div>
    )
}

export default Chat;