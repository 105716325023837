import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import jQuery from 'jquery';
import moment from 'moment';
import * as RM from 'ramda';


import Utilities from '../helpers/Utilities';
//import PagesAuthentication from '../helpers/PagesAuthentication';
import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import { checkMobileNumberExistance } from './../helpers/VerificationUtilities';

import FieldInput from '../components/FieldInput';
import PasswordInput from '../components/PasswordInput';
import SignupHeader from '../layout/SignupHeader';


const CompanyInfo = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    //const errorMsg = process.env.REACT_APP_message_error_process;
    const limitCharacters = Utilities.companyAboutWordsLimit;;
    const defaultLocation = Utilities.defaultLocation();

    let defaultFormValues = {
        company_name : '',
        company_display_name : '',
        company_email : '',
        company_website : '',
        category_name : '',
 
        company_phone : '',
        company_phone_code : defaultLocation.country_id,
        company_landline : '',
        company_landline_code : defaultLocation.country_id,

        company_phone_checked : 0,
        company_landline_checked : 0,

        company_info : '',
        company_social : ''
    }

    let finalInfoValues = {  company_about : '' }, defaultInfoValues = { company_about : '' };
    let finalSocialValues = { facebook :'', youtube : '', tiktok : '', instagram : '', twitter : '' };

    const [ showSection, setShowSection ] = useState('');

    const [ formValues, setFormValues] = useState(defaultFormValues);
    const [ formInfoValues, setFormInfoValues] = useState(defaultInfoValues);
    const [ formFinalInfoValues, setFormFinalInfoValues] = useState(finalInfoValues);

    const [ formSocialValues, setFormSocialValues] = useState(finalSocialValues);

    const [ countryList, setCountryList] = useState([]);

    const [ defaultFlagMobile, setDefaultFlagMobile ] = useState(defaultLocation.country_iso);
    const [ defaultCountryCodeMobile, setDefaultCountryCodeMobile ] = useState(defaultLocation.country_ext);

    const [ defaultFlagLandline, setDefaultFlagLandline ] = useState(defaultLocation.country_iso);
    const [ defaultCountryCodeLandline, setDefaultCountryCodeLandline ] = useState(defaultLocation.country_ext);
    
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');

    const [ showLandlineMsg, setShowLandlineMsg] = useState(false);
    const [ showMobileMsg, setShowMobileMsg] = useState(false);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if ( fieldName==='company_name'){
            formValuesTemp = { ...formValuesTemp, ...{ company_name : value } };
        }else if ( fieldName==='company_display_name'){
            formValuesTemp = { ...formValuesTemp, ...{ company_display_name : value } };
        }else if ( fieldName==='company_email'){
            formValuesTemp = { ...formValuesTemp, ...{ company_email : value } };

        }else if ( fieldName==='category_name'){
            formValuesTemp = { ...formValuesTemp, ...{ category_name : value } };
        }else if ( fieldName==='company_website'){
            formValuesTemp = { ...formValuesTemp, ...{ company_website : value } };

        }else if ( fieldName==='company_landline'){
           
            let company_landline = value.replace(' ','');
            setShowLandlineMsg('');
            if ( ! Utilities.isEmpty(company_landline) && ! Utilities.contactNumberValidate( company_landline, 1 ).status ){
                setShowLandlineMsg( Utilities.contactNumberValidate( company_landline, 1 ).msg);
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ company_landline : company_landline } };

        }else if ( fieldName==='company_landline_code'){
            formValuesTemp = { ...formValuesTemp, ...{ company_landline_code : value.toString().trim() } };

        }else if ( fieldName==='company_phone'){
            
            let company_phone = value.replace(' ','');
            setShowMobileMsg('');
            if ( ! Utilities.contactNumberValidate( company_phone , 1).status ){
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                setShowMobileMsg( Utilities.contactNumberValidate( company_phone, 1 ).msg);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ company_phone : company_phone } };

        }else if ( fieldName==='company_phone_code'){
            formValuesTemp = { ...formValuesTemp, ...{ company_phone_code : value.toString().trim() } };

        }else if ( fieldName==='company_phone_checked'){
            value = parseInt(formValuesTemp.company_phone_checked)===1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked : value } };
        }else if ( fieldName==='company_landline_checked'){

            value = parseInt(formValuesTemp.company_landline_checked)===1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked : value } };

        }else if ( fieldName==='company_info'){
            formValuesTemp = { ...formValuesTemp, ...{ company_info : value } };

        }else if ( fieldName==='company_social'){
            formValuesTemp = { ...formValuesTemp, ...{ company_social : value } };
        }
        
        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);

        if ( ! Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'] && value===1  ){
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileActivationWarningMessage ,
                overlayClassName : 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue',  onClick: () => confirmContactNumber(fieldName, 1)  }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            }); 
        }
    }

    const confirmContactNumber = ( fieldName = '', value = 0 ) => {

        if ( ! Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'] ){
            let formValuesTemp = formValues;

            if ( fieldName==='company_phone_checked'){
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked : parseInt(value) } };

            }else if ( fieldName==='company_landline_checked'){
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked : parseInt(value) } };
            }
            setFormValues(formValuesTemp);
        }
    }


    const submitInformation = async () => {

        jQuery('#form-company-info .error').removeClass('error');
        dispatch( showLoader(0) );

        let errorCounter = 0, responseChecker = false;
        const optionInputsArray = ['company_info', 'company_social', 'company_website','company_landline', 'company_landline_code'];

        Object.entries(formValues).map( ([ key, value]) => { 

            if ( Utilities.isEmpty(value ) && ! optionInputsArray.includes(key.toString()) ){
                jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }

            if ( key==='company_email'){
                if ( !Utilities.isEmail(value) ){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }else if ( ['company_name', 'company_display_name'].includes(key) ){
                responseChecker = Utilities.inputCharacterChecker(value, 'general-companyname');
                if ( responseChecker===false ){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }
        });

        let contactErrorCounter = 0;
        if ( ! Utilities.isEmpty(formValues.company_landline) && ! Utilities.contactNumberValidate( formValues.company_landline ) ){
            jQuery('[name="company_landline"]').closest('.form-group').addClass('error');
            contactErrorCounter++;
        }

        if ( ! Utilities.contactNumberValidate( formValues.company_phone ) ){
            jQuery('[name="company_phone"]').closest('.form-group').addClass('error');
            contactErrorCounter++;
        }

        if ( contactErrorCounter>0 ){ return false; }

        if ( errorCounter>0 ){  return false;  }


        const utcTime = Utilities.currentUTCTime();
        const curretTime =  moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ create_date : curretTime,  update_date : curretTime  } };


        if ( Object.keys(formValuesTemp).length>0 ){


                // Checking mobile number if exist in database
                dispatch( showLoader(1) );
                const responseMobileCheckingDB = await checkMobileNumberExistance( formValues.company_phone_code, formValues.company_phone );
                dispatch( showLoader(0) );

                if ( Object.keys(responseMobileCheckingDB).length>0 ){
                    if ( parseInt(responseMobileCheckingDB.exist)===1 ){

                        Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist )
                        return false;
                    }
                }


                sessionStorage.setItem(
                    process.env.REACT_APP_session_signup_companyinfo,
                    JSON.stringify(formValuesTemp)
                );

                sessionStorage.setItem(
                    process.env.REACT_APP_session_signup_companyinfo_about,
                    JSON.stringify(formFinalInfoValues)
                );

                sessionStorage.setItem(
                    process.env.REACT_APP_session_signup_companyinfo_social,
                    JSON.stringify(formSocialValues)
                );

                history.push(`/${ UrlSlugs.signup_companyKeywords}`);

        }
    }
    

    const changeCountryCodeLandline = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagLandline(defaultFlagTemp);
        setDefaultCountryCodeLandline(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_landline_code : passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeMobile = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagMobile(defaultFlagTemp);
        setDefaultCountryCodeMobile(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_phone_code : passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }


    const getCountries = () => {
        axios
        .get( ApiUrls.country , {} )
        .then( response => {
            if ( response.status===200 ){
                setCountryList(response.data);
            }
        })
        .catch( (err) => { });
    }

    const focusDropdown = ( input_id) => {
        
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( !Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '') => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const handleShowSectionAbout = () => {
        setShowSection('company-info');
    }

    const handleShowSectionSocial = () => {
        setShowSection('company-social');
    }


    const submitCompanySocial = () => {

        if ( ! Utilities.isEmpty( formSocialValues.youtube ) && ! Utilities.isValidURL( formSocialValues.youtube ) ){
            Utilities.messagePopup('warning', TitlesLabels.alertMessages.invalidYoutubeUrl  );
            return false;
        }

        setShowSection('');

        let formValuesTemp = {...formValues, company_social : ''};
        let concatSocialText = Object.values(formSocialValues).filter( val => val!=='' ).join('|');
        concatSocialText = concatSocialText.trim();

        if ( ! Utilities.isEmpty(concatSocialText) ){
            formValuesTemp = {...formValues, company_social : 'view'};
        }
        setFormValues(formValuesTemp);

    }

    const submitCompanyAbout = () => {

        const charCounter = Utilities.wordsCounter(formInfoValues.company_about);
        if ( parseInt(charCounter)>limitCharacters ){
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }
        
        setShowSection('');
        setFormFinalInfoValues(formInfoValues);

        let formValuesTemp = {...formValues, company_info : ''};
        if ( ! Utilities.isEmpty(formInfoValues.company_about)  ){
            formValuesTemp = {...formValues, company_info : 'click here to view'};
        }
        setFormValues(formValuesTemp);
    }

    const headerHtml = <SignupHeader title="Company Information" />
                        {/* <header className="header mb-5 text-center">
                            <div className="logo-wrap">
                                <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                                <div className="logo-type-text mt-2">Company Information</div>
                            </div>
                        </header> */}
       
    const headerSocialHtml = <SignupHeader title="Social Media" />
                        {/* <header className="header mb-5 text-center">
                            <div className="logo-wrap">
                                <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                                <div className="logo-type-text mt-2">Social Media</div>
                            </div>
                        </header> */}

    const htmlContent = () => {
        
        if ( showSection==='company-social'){
            return <section className="w-100">
                        <div className="container py-5">
                            { headerSocialHtml }

                            <div id="form-company-social" className="col-sm-4 mx-auto">
                                <div className="btn-icon-option btn-outline-arrow-left-black" style={{ top: '-110px'}} onClick={ () => setShowSection('') }></div>
                                <ul className="company-social-input px-4">
                                    <li className="social-facebook mb-2"><input type="text" className="pl-4" placeholder="user name"  value={formSocialValues.facebook} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ facebook : e.target.value } }) } /></li>
                                    <li className="social-twitter mb-2"><input type="text" className="pl-4" placeholder="user name"  value={formSocialValues.twitter} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ twitter : e.target.value } }) } /></li>
                                    <li className="social-instagram mb-2"><input type="text" className="pl-4" placeholder="user name"  value={formSocialValues.instagram} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ instagram : e.target.value } }) }  /></li>
                                    <li className="social-tiktok"><input type="text" style={{ paddingLeft : '35px' }} placeholder="user name"  value={formSocialValues.tiktok} onChange={ (e) => setFormSocialValues({ ...formSocialValues, ...{ tiktok : e.target.value } }) } /></li>
                                </ul>
                                
                                <div className="mt-5 pt-4">
                                    <button type="button" className="btn-theme-black" onClick={ () => submitCompanySocial() }>submit</button>
                                </div>
                            </div>

                        </div>
                    </section>

        }else if( showSection==='company-info' ){
            return <section className="w-100">
                        <div className="container py-5">
                            
                            { headerHtml }
                            
                            <div className="col-12 col-md-8 col-lg-6 px-5 mx-auto pt-1 form-theme">
                                <div className="btn-icon-option btn-outline-arrow-left-black" style={{ top: '-110px'}} onClick={ () => setShowSection('') }></div>

                                <div id="form-company-info">

                                    <div className="form-group">
                                        <textarea id="company_about" name="company_about" className="form-control" rows="7" placeholder=" " value={formInfoValues.company_about} onChange={ (e) => setFormInfoValues({ ...formInfoValues, ...{ company_about : e.target.value } }) }></textarea>
                                        <label className="form-label">about your business</label>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between text-color-gray">
                                        <div className="text-color-blue cursor-pointer" onClick={ () => setFormInfoValues({...formInfoValues, company_about : '' }) }>clear</div>
                                        <div>maximum { Utilities.number_format(limitCharacters.toString()) } words</div>
                                    </div>
                                    
                                    <div className="mt-5 pt-5">
                                        <button type="button" className="btn-theme-black" onClick={ () => submitCompanyAbout() }>submit</button>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </section>

        }else{
            return <section className="w-100">
                        <div className="container py-5">

                            { headerHtml }

                            <form id="form-company-info" className="col-12 col-md-8 col-lg-6 mx-auto pt-1 form-theme"  autoComplete="off" method="POST">

                                <input type="hidden" id="input_company_phone_code"  name="company_phone_code"  readOnly value={formValues.company_phone_code}  onChange={ (e) => pushValue( e, 'company_phone_code') } ></input>
                                <input type="hidden" id="input_company_landline_code"  name="company_landline_code"  readOnly value={formValues.company_landline_code}  onChange={ (e) => pushValue( e, 'company_landline_code') } ></input>


                                <FieldInput inputName="company_name" inputLabel="Company Name" inputValue={ formValues.company_name } pushValue={pushValue} checkerType="general-companyname"/>

                                <FieldInput inputName="company_display_name" inputLabel="Display Name" inputValue={ formValues.company_display_name } pushValue={pushValue} checkerType="general-companyname"/>
                                
                                <div className="form-group ">
                                    <input type="email" name="company_email" className="form-control" placeholder=" " autoComplete="new-password" value={ formValues.company_email }  onChange={ (e) => pushValue( e, 'company_email') } ></input>
                                    <label className="form-label">Email</label>
                                </div>


                                <div className="form-group active optional  has-off-on">
                                    <div className="form-group-mobile  pr-5">
                                        <Dropdown className="dropdown-country-selection hide-arrow">
                                            <Dropdown.Toggle>
                                                <div className="flag-code"  onClick={ () => focusDropdown('landline-input') }>
                                                    <span className={`flag-icon flag-icon-${defaultFlagLandline} mr-2`}></span>
                                                    <span>+{defaultCountryCodeLandline}</span>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu >
                                            <div className="px-4 mb-1">
                                                <input type="text" id="landline-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'landline-input-list', 'search-keyword') }></input>
                                            </div>
                                            <div id="landline-input-list" className="country-code-list">
                                            {   
                                                Object.entries(countryList).map( ([key, row] ) =>
                                                    <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeLandline(row) } >
                                                        <div className="flag-code">
                                                            <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                            <span>{ row.country_name }</span>
                                                            <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            }
                                            </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    
                                        <input type="number" name="company_landline" className="form-control" placeholder=" " autoComplete="off"  value={ formValues.company_landline }  onChange={ (e) => pushValue( e, 'company_landline') } ></input>
                                    </div>
                                    <label className="form-label">Landline Number</label>
                                    <div className="optional-label position-placeholder mr-5">(Optional)</div>

                                    <input type="checkbox" className="checkbox-onoff" value={ formValues.company_landline_checked }  checked={formValues.company_landline_checked===1 }   onChange={ (e) => pushValue(e, 'company_landline_checked') } title={ TitlesLabels.general.chooseNumberRefectOnBusiness }></input>

                                </div>

                                {  ( ! Utilities.isEmpty(showLandlineMsg) && ! Utilities.isEmpty(formValues.company_landline) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showLandlineMsg }</div> }


                                <div className="form-group active optional has-off-on">
                                    <div className="form-group-mobile">
                                        <Dropdown className="dropdown-country-selection hide-arrow">
                                            <Dropdown.Toggle>
                                                <div className="flag-code" onClick={ () => focusDropdown('mobile-input') }>
                                                    <span className={`flag-icon flag-icon-${defaultFlagMobile} mr-2`}></span>
                                                    <span>+{defaultCountryCodeMobile}</span>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu >
                                            <div className="px-4 mb-1">
                                                <input type="text" id="mobile-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'mobile-input-list', 'search-keyword') }></input>
                                            </div>
                                            <div id="mobile-input-list" className="country-code-list">
                                            {
                                                Object.entries(countryList).map( ([key, row] ) =>
                                                    <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeMobile(row) } >
                                                        <div className="flag-code">
                                                            <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                            <span>{ row.country_name }</span>
                                                            <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            }
                                            </div>  
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    
                                        <input type="number" name="company_phone" className="form-control" placeholder=" " autoComplete="off" value={ formValues.company_phone }  onChange={ (e) => pushValue( e, 'company_phone') }  ></input>
                                    </div>
                                    <label className="form-label">Mobile Number</label>

                                    <div className="optional-label position-placeholder mr-5">(mandatory)</div>
                                    <input type="checkbox" className="checkbox-onoff" value={ formValues.company_phone_checked }  checked={formValues.company_phone_checked===1 }   onChange={ (e) => pushValue(e, 'company_phone_checked') } title={ TitlesLabels.general.chooseNumberRefectOnBusiness }></input>
                                </div>

                                { ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(formValues.company_phone) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }

                                <div className="form-group optional">
                                    <input type="text" name="company_website" className="form-control" placeholder=" " autoComplete="off" value={ formValues.company_website }  onChange={ (e) => pushValue( e, 'company_website') } ></input>
                                    <label className="form-label">Company Website</label>
                                    <div className="optional-label">Optional</div>
                                </div>

                                <div className="form-group optional optional-category">
                                    <input type="text" name="category_name" className="form-control" placeholder=" " autoComplete="off" value={ formValues.category_name }  onChange={ (e) => pushValue( e, 'category_name') } onKeyDown={ (e) => e.key === 'Tab' && handleShowSectionAbout() }  ></input>
                                    <label className="form-label">Business Type</label>
                                    <div className="optional-label">e.g. airline, restaurant, etc.</div>
                                </div>

                                <div className="form-group optional content-disabled cursor-pointer" onClick={ () =>  handleShowSectionAbout() }>
                                    <input type="text" name="company_info" className="form-control" placeholder=" " autoComplete="off" readOnly value={ formValues.company_info }  onChange={ (e) => pushValue( e, 'company_info') }></input>
                                    <label className="form-label">Company Info</label>
                                    <div className="optional-label">Optional</div>
                                </div>


                                <div className="form-group optional content-disabled cursor-pointer" onClick={ () =>  handleShowSectionSocial() }>
                                    <input type="text" name="company_social" className="form-control" placeholder=" " autoComplete="off" readOnly value={ formValues.company_social }  onChange={ (e) => pushValue( e, 'company_social') }></input>
                                    <label className="form-label">Social Media</label>
                                    <div className="optional-label">Optional</div>
                                </div>


                                <div className="button-wrap text-lowercase text-center pt-5">
                                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => submitInformation() } >Next</button>
                                </div>
                            </form>
                            
                        </div>

                        <div className="footer-navigation position-absolute text-center">
                            <div className="text-lowercase cursor-pointer text-blue" onClick={() => history.goBack()}>{ TitlesLabels.general.exitWithoutSaving }</div>
                        </div>
                        
                    </section>

        }
    }

    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.companyInformation ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        //PagesAuthentication.getUserLoggedin();
        getCountries();
        
    }, [] );

    return (
        <div className={`main-content signup-page position-${ [''].includes(showSection) ? 'relative' : 'fixed' } d-flex justify-content-center align-items-center w-100  h-100 text-lowercase`}>

            { htmlContent() }

    </div>
    )
}

export default CompanyInfo;