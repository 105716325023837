import React, { useEffect, useState, useCallback } from 'react';
import jQuery from 'jquery';
import firebase from 'firebase';

import Utilities from '../helpers/Utilities';
import DocumentUtilities from '../helpers/DocumentUtilities';
import ChatUtilities from '../helpers/ChatUtilities';
import TitlesLabels from '../helpers/TitlesLabels';

const PreviewImage = (props) => {

    const firebaseConfig = ChatUtilities.firebaseConfig();
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
    const FIRESTORE = firebase.firestore(); 

    const fileDefault = { type : 0, ext : '', file : ''};

    const [ showSection, setShowSection ] = useState(0);
    const [ documentImageRequest, setDocumentImageRequest ] = useState('');
    const [ documentFileRequest, setDocumentFileRequest ] = useState(fileDefault);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');

    const [ loginDetail, setLoginDetail] = useState({});
    const [ documentSelected , setDocumentSelected ] = useState({});
    const [ documentList , setDocumentList ] = useState([]);
    const [ loadingStatus , setLoadingStatus ] = useState(false);

    const selectImage = () =>{
        jQuery('input#input_select_document').trigger('click');
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-documents > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const title = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const title_html = Utilities.isEmpty(keyword) ? title : title.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(title_html);
        });
    }

    //  0 = delete, 1= text, 2 = image, 3 = voice, 4= video, 5= docs, 6 = pdf, 7 other application, 8 = links, 9 = location
    const previewFile = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        const fileExtension = value.substr((value.lastIndexOf('.') + 1)).toString().toLowerCase();
        if ( Utilities.validate_image_ext( value ) ){

            const reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {
                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');

                        setDocumentFileRequest(fileDefault);
                        setDocumentImageRequest('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');

                        setDocumentFileRequest(fileDefault);
                        setDocumentImageRequest('');
                        return false;
                    }

                    setDocumentImageRequest(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setDocumentFileRequest({ type : 2, ext : '', file : e.target.files[0]});
        
        }else if ( Utilities.validate_document_ext( value ) ){

            let messageType = 7; // others
            if ( fileExtension==='pdf' ){
                messageType = 6;
            }else if ( fileExtension==='doc' || fileExtension==='docx' ){
                messageType = 5; 
            }
            setDocumentImageRequest('');
            setDocumentFileRequest({ type : messageType, ext : fileExtension, file : e.target.files[0]});

        }else if ( Utilities.validate_video_ext( value ) ){
            setDocumentImageRequest('');
            setDocumentFileRequest({ type : 4, ext : fileExtension, file : e.target.files[0]});

        }else if ( Utilities.validate_audio_ext( value ) ){
            setDocumentImageRequest('');
            setDocumentFileRequest({ type : 3, ext : fileExtension, file : e.target.files[0]});

        }else{
            setDocumentFileRequest(fileDefault);
            setDocumentImageRequest('');

            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }

    const uploadSendDocument = () => {
        if ( documentFileRequest.hasOwnProperty('ext') && parseInt(documentFileRequest.type || 0)>0 ){
           props.uploadSendDocument(documentFileRequest);
        }else{
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.selectFileToSend );
        }
    }

    const getDocuments = async ( loginDetail = {} ) => {
        
        const passValue = {
            account_id : loginDetail.account_id.toString(),
            document_id : '0',
            flag : '0',
            title : '',
            attachment : ''
        }
        setLoadingStatus(true);

        const documentListResponse = await DocumentUtilities.getDocumentList(passValue);
        if ( parseInt(documentListResponse.success)===1 ){
            const docList = documentListResponse.result.library_list;
            setDocumentList(docList);
        }else{
            setDocumentList([]);
        }

        setLoadingStatus(false);
    }


    const sendLibraryDocument = async () => {
        
        const activeProfile = props.activeProfile ?? {};
        const conversID = props.conversID || 0;
        const requestProfile = props.requestProfile ?? {};

        if ( parseInt(conversID)>0 && Object.entries(activeProfile).length>0 && Object.entries(requestProfile).length>0 ){
            const conversation = ChatUtilities.getAccountConversationDetail( conversID );
           
            let mainAccountId = activeProfile.account_id;
            //const documentDetailRes = await getDocumentDetail(documentSelected.id); 

            const passValues = {
                account_id : mainAccountId.toString(),
                convers_id : conversation.convers_id.toString(),
                attachment : documentSelected.attachment
            }

            const btn_name = 'btn-send-document-from-library';
            Utilities.elementDisabledStatus(btn_name, true );

            const documentRes = await DocumentUtilities.copyFileToConversFolder(passValues);
 
            if ( parseInt(documentRes.status)===1 ){
                // SEND FILE
                ChatUtilities.sendMessage( FIRESTORE, conversation,  activeProfile, requestProfile , documentRes.message_type.toString(), documentRes.file_url.toString(), {} );
                props.toggleSendDocumentOption();

            }else{
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotSendFileTryAgain );
            }

            Utilities.elementDisabledStatus(btn_name, false );
        }
       
    }

    const htmlMediaProvide = ( document = {} ) => {
        const ext = document.attachment.file_name.substr((document.attachment.file_name.lastIndexOf('.') + 1)).toString().toLowerCase();
        let bg = document.thumb_path.toString()
        
        if ( document.hasOwnProperty('attachment') ){
            if (  parseInt(document.attachment.file_type || 0)!==2 ){
                bg = '';
            }
        }

        let detail = { bg : bg, ext : ext.toString() };
        return detail;
    }

    const escFunction = useCallback ((event) => {
        if(event.keyCode === 27) {
          //Do whatever when esc is pressed
          setDocumentSelected({});
        }
    }, []);

    useEffect( async () => {

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){

                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setLoginDetail(profileLoggedinTemp);
                getDocuments(profileLoggedinTemp);
            }
        }

        document.addEventListener("keydown", escFunction, false);

        return () => {
            setShowSection(0);
            setDocumentFileRequest(fileDefault);
            setDocumentImageRequest('');
            setDocumentList([]);
            setLoadingStatus(false);

            document.removeEventListener("keydown", escFunction, false);
        }

    }, [] );

    return (
        <div className="section-document-content chat-sidebar">

            <div className="btn-close-chat-sidebar" onClick={ () => props.toggleSendDocumentOption() }></div>
            <div className="sidebar-title">add document</div>

                <ul className="document-button-selection mt-4 px-4 mx-2">
                    <li className={`${showSection===0 ? 'current' : '' }`} onClick={ () => setShowSection(0) }>system document</li>
                    <li className={`${showSection===1 ? 'current' : '' }`} onClick={ () => setShowSection(1) }>document library</li>
                </ul>

                {
                    showSection===0 ?
                        <section className="px-4 mx-2">
                            <input type="file" id="input_select_document" name="file" className="invisible" readOnly onChange={ (e) => previewFile(e) }></input>

                            <div className="document-upload-preview mt-2 mb-4 cursor-pointer" style={{ backgroundImage : `url(${documentImageRequest})`}} onClick={ () => selectImage() }>
                                { 
                                    ! Utilities.isEmpty(documentFileRequest.ext) ?
                                        <div className="document-file-icon">.{documentFileRequest.ext}</div>
                                    :
                                        parseInt(documentFileRequest.type || 0)===0 &&
                                            <div className="document-plus-icon"></div>
                                }
                            </div>

                            <div className="mt-5">
                                <button type="button" id="btn-upload-send-document"  className=" btn-theme-black text-lowercase button-has-loader" onClick={ () => uploadSendDocument() }>send</button>
                            </div>

                        </section>
                    :
                        <section className="position-relative form-theme text-lowercase">

                            {
                                 Object.entries(documentList).length>0  &&
                                 <div className="pt-4 px-4 mx-2">
                                    <div className="form-group">
                                    <input type="text" name="input_document_name" id="input_document_name" className="form-control" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder=" "></input>
                                        <label className="form-label">search</label>
                                    </div>
                                </div>
                            }
                            

                            <div className="sidebar-block long-content sidebar-block-media">
                                <div className="sidebar-block-content sidebar-media">
                                {
                                    loadingStatus===false ?
                                        Object.entries(documentList).length>0 ?
                                            <ul id="listing-documents" className="media-list">
                                                {
                                                    Object.entries(documentList).map( ( [key, document ] ) =>
                                                        <li key={key} className="cursor-pointer content-item"  data-keyword={document.title.toLowerCase()} onClick={ () => setDocumentSelected(document) }>
                                                            <div className={`media-file-wrap ${ Utilities.isEmpty( htmlMediaProvide(document)['bg'] ) ? 'medial-file-icon-wrap' : ''}`} style={{ backgroundImage:`url(${ htmlMediaProvide(document)['bg'] })`}}>
                                                               { 
                                                                    Utilities.isEmpty( htmlMediaProvide(document)['bg'] ) &&
                                                                    <div>{ htmlMediaProvide(document)['ext']}</div>
                                                               }
                                                    
                                                            </div>
                                                            <div className="font-gotham-book-14 py-1 ellipsis-1" data-name={document.title.toLowerCase()}>{document.title.toLowerCase()}</div>
                                                        </li>
                                                    )
                                                }
                                                
                                            </ul>
                                        :
                                                <div className="text-center text-lowercase no-record-label mt-5">{ Utilities.labelDocumentAvailable }</div>
                                    :
                                        <div className="content-loader animate"></div>
                                }
                                </div>
                            </div>

                        </section>
                }


                {
                    Object.entries(documentSelected).length>0 &&
                        <div className="document-review">
                            <div className="btn-close" onClick={ () => setDocumentSelected({}) }></div>
                        
                                {
                                    parseInt(documentSelected.attachment.file_type || 0)===2 ?
                                        <img className="document-preview-file" src={documentSelected.thumb_path}/>
                                    :
                                        <div className="document-preview-file file-item"><span>.{ documentSelected.attachment.file_name.split('.').pop() }</span></div>
                                }
                                
                                <div className="w-100 pt-3 pb-5 font-gotham-book-16">{documentSelected.title}</div>

                                <div className="document-container mx-auto">
                                    <button id="btn-send-document-from-library" type="button" className="btn-theme-black w-100 button-has-loader" onClick={ () => sendLibraryDocument() }>send</button>
                                </div>
                    
                        </div>
                }
                
       </div>
    )
}

export default PreviewImage;
