import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; 
import axios from 'axios';
import jQuery from 'jquery';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';

import { toggleAutomatedReply as toggleAutomatedReplyReducer, passConversList } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';


const AutomatedReply = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const toggleAutomatedReply = useSelector( state => state.toggleAutomatedReply);

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const maxWords = 200;
    const defaultFormValues = {  message : '', status : 0 };

    const [ showPopup, setShowPopup ] = useState(false);
    const [ isEdit, setIsEdit ] = useState(false);
    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ accountDetail, setAccountDetail ] = useState({});
    const [ isProcessing, setIsProcessing ] = useState(false);
    const [ showEditButton, setShowEditButton ] = useState(false);

    const [ typeHereLabel , setTypeHereLabel ] = useState('Type here');

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        
        let formValuesTemp = formValues;

        if ( fieldName==='message'){

            const wordsCounter = Utilities.wordsCounter(value);
            if ( parseInt(wordsCounter)>maxWords ){
                Utilities.messagePopup('error', `Only ${maxWords} words allowed!` );
                return false;
            }

            formValuesTemp = { ...formValuesTemp, ...{ message : value } };

        }else if ( fieldName==='status'){
            value = parseInt(formValuesTemp.status)===1 ? 0 : 1;

            if ( value===0 ){
                confirmAlert({
                    title: '',
                    message: TitlesLabels.confirmMessages.wishDeActivateAutomatedReply,
                    overlayClassName : '',
                    buttons: [
                        {  
                            label: 'no',
                            onClick: () => {
                                formValuesTemp = { ...formValuesTemp, ...{ status : 1 } };
                                setIsEdit(true);
                                setFormValues(formValuesTemp);
                                setShowEditButton(false);
                            } 
                        },
                        {  
                            label: 'yes', 
                            onClick: () => {
                                formValuesTemp = { ...formValuesTemp, ...{ status : 0 } };
                                setIsEdit(false);
                                setShowEditButton(false);
                                setFormValues(formValuesTemp);

                                submitInfo( formValuesTemp );
                                setShowPopup(false);
                            } 
                        }
                    ]
                });
                 
            }else{
                formValuesTemp = { ...formValuesTemp, ...{ status : value } };

                let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                if ( divertReplyInfo!==null ){
                    divertReplyInfo = JSON.parse(divertReplyInfo);

                    if ( parseInt(divertReplyInfo.auto_replay_status || 0)===0 ){
                        setShowEditButton(false);
                        setIsEdit(true);
                    }

                }else{
                    if ( ! Utilities.isEmpty(formValuesTemp.message) ){
                        setShowEditButton(true);
                    }else{
                        setShowEditButton(false);
                    }
                    setIsEdit(true);
                }
            }
        }

        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
    }


    const clearMessage = () => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ message : '' } };
        setFormValues(formValuesTemp);
        setTypeHereLabel('Type here');
    }
    

    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( toggleAutomatedReplyReducer('') );
    }


    const getAutomatedDetail = ( profileDetail  = {} ) => {

        const passValues = {
            api_token : TOKEN.toString(),
            account_id : profileDetail.account_id.toString(),
        }
        
        axios
        .post( ApiUrls.profileGetAutomatedReplayMessage , passValues)
        .then( response => {
            
            setIsProcessing(false);

            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){

                    const auto_replay_message = response.data.result.auto_replay_message;
                    let defaultFormValuesTemp = defaultFormValues;

                    if ( parseInt(Object.keys(auto_replay_message).length)>0 ){

                        defaultFormValuesTemp = {
                            message : auto_replay_message.message.toString(),
                            status : parseInt(auto_replay_message.status)
                        };

                        setIsEdit( parseInt(defaultFormValuesTemp.status)===1 ? true : false );
                        setShowEditButton(  parseInt(defaultFormValuesTemp.status)===1 ? true : false );

                        setTypeHereLabel( ! Utilities.isEmpty(defaultFormValuesTemp.message) ? 'Message' : 'Type here' );

                    }else{
                        setIsEdit(false);
                    }
                
                    setFormValues(defaultFormValuesTemp);
                }
            }
          
        })
        .catch( (err) => {
            setIsProcessing(false);
            setShowPopup(false);
        }); 
        
    }


    const submitInfo = ( formValuesTemp = formValues ) => {

        if ( parseInt(accountDetail.account_id)>0 ){

            const wordsCounter = Utilities.wordsCounter(formValues.message.toString());
            if ( parseInt(wordsCounter)>maxWords ){
                Utilities.messagePopup('error', `Only ${maxWords} words allowed!` );
                return false;
            }

            if ( Utilities.isEmpty(formValues.message) && parseInt(formValuesTemp.status)===1 ){
                Utilities.messagePopup('error', TitlesLabels.alertMessages.messageIsRequired );
                return false;
            }

            const passValues = {
                api_token : TOKEN.toString(),
                account_id : accountDetail.account_id.toString(),
                message : formValuesTemp.message.toString(),
                status : formValuesTemp.status.toString(),
            }

            const button_name = 'btn-submit-automated';

            Utilities.elementDisabledStatus(button_name, true );
            
            axios
            .post( ApiUrls.profileUpdateAutomatedReplayMessage , passValues)
            .then( response => {
                
                Utilities.elementDisabledStatus(button_name, false );

                if ( parseInt(response.status)===201 ){
                    if ( parseInt(response.data.success)===1 ){
                        

                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if ( divertReplyInfo!==null ){

                            divertReplyInfo = JSON.parse(divertReplyInfo);
                            divertReplyInfo = { ...divertReplyInfo, auto_replay_status : formValuesTemp.status.toString() };

                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(divertReplyInfo)
                            );
                                
                            dispatch( passConversList(divertReplyInfo) );

                            const messageText = parseInt(formValuesTemp.status)===1 ? 'activated' : 'de-activated';
                            Utilities.messagePopup('success', `automated reply has been successfully ${messageText}`);

                        }

                        setShowPopup(false);

                        history.push(`/${UrlSlugs.dashboard}`);
                        
                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                }
            })
            .catch( (err) => {
                Utilities.elementDisabledStatus(button_name, false );
                Utilities.messagePopup('error', errorMsg );
            }); 

        }

    }


    const changeTypeHereLabel = ( action = '' ) => {

        if ( action==='click' ){
            setTypeHereLabel('Message');
            
        }else if ( action==='blur' ){
           
            let requestLabel = 'Type here';
            const message = jQuery('textarea#default_message').val();
            if ( ! Utilities.isEmpty(message) ){
                requestLabel = 'Message';
            }
            setTypeHereLabel(requestLabel);
        }
    }

    useEffect( () => {

        setShowEditButton(false);
        if ( ! Utilities.isEmpty(toggleAutomatedReply) ){

            let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginBasicInfo!==null ){
                loginBasicInfo = JSON.parse(loginBasicInfo);

                setIsProcessing(true);
                setShowPopup(true);

                getAutomatedDetail(loginBasicInfo);
                setAccountDetail(loginBasicInfo);
            }

        }else{
            setShowPopup(false);
            setIsProcessing(false);
        }
        
    }, [toggleAutomatedReply] );

    return (

       <Modal id="popup-automated-reply"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme"  keyboard={ true }>
            <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline border-bottom-0 pt-4">{ TitlesLabels.general.automatedReply }</Modal.Header>
            <Modal.Body className="pt-2 pb-5">
                    {
                        isProcessing===true ?
                            <div className="content-loader animate"></div>
                        :
                            <div className="col-10 px-3 mx-auto  form-theme">

                                <div className="pb-4">

                                    <div className={`font-gotham-medium-16 ${ isEdit===false ? 'mt-4' : '' }`}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="mt-n1">activate</div>
                                            <input type="checkbox" className="checkbox-onoff ml-2"  value={ formValues.status }  checked={formValues.status===1 }   onChange={ (e) => pushValue(e, 'status') } ></input>
                                        </div>
                                    </div>
                                    
                                    {
                                         isEdit===true &&
                                            <div className={`${ ( isEdit===true && showEditButton===false ) ? '' : 'content-disabled' }`}>
                                                <div className="form-group mt-3">
                                                    <textarea id="default_message" name="message" className="form-control  px-0 text-tranform-initial" rows="8" placeholder=" " value={ formValues.message } onChange={ (e) => pushValue(e, 'message') } onClick={ () => changeTypeHereLabel('click') } onBlur={ () => changeTypeHereLabel('blur') } ></textarea>
                                                    <label className="form-label px-0">{typeHereLabel}</label>
                                                </div>
                                            
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div><span className="cursor-pointer text-color-blue" onClick={ () => clearMessage() }>clear</span></div>
                                                    <div className="text-color-gray text-right mt-n2">max {maxWords} words</div>
                                                </div>
                                            </div>
                                    }
                                   
                                    
                                </div>

                                {
                                     ( isEdit===true && showEditButton===false ) &&
                                        <button type="button" id="btn-submit-automated"  className=" btn-theme-black button-has-loader" onClick={ () => submitInfo() }>save</button>
                                }

                                {
                                    showEditButton===true &&
                                        <button type="button"  className=" btn-theme-black button-has-loader" onClick={ () => { setIsEdit(true); setShowEditButton(false); } }>edit</button>
                                }
                            
                            </div>
                    }
                    
                
            </Modal.Body>
        </Modal>
    )
}

export default AutomatedReply;
