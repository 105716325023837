import axios from 'axios'; 
import ApiUrls from './ApiUrls';


class QueryMasterSearchUtlities {
    

    getAllProfileResultsByCaterogyName = async ( passValue = {}  ) => {
        let response = {};
        if ( Object.entries(passValue).length>0 ){
            try {
                const urlRequest = ApiUrls.profileSearchCategoryByCategoryName ;
                const detailRes = await axios.post( urlRequest, passValue);
                response = detailRes.data;
                
            } catch (e) {

            }
        }

        return response;
    }

    getProfileCounterByCategoryName = async ( category_name = '' ) => {

        let response = {};
        if ( category_name!=='' ){
            try {
                const passValue = { keywords : category_name.toString() }
                const urlRequest = ApiUrls.profileSearchCategoryProfileIdsByCategoryName ;
                const detailRes = await axios.post( urlRequest, passValue);
                response = detailRes.data;
                
            } catch (e) {

            }
        }

        return response;
    }

    getProfileCounterByCategoryId = async ( category_id = 0 ) => {

        let response = {};
        if ( category_id>0 ){
            try {
                const passValue = { category_id : category_id.toString() }
                const urlRequest = ApiUrls.profileSearchCategoryProfileIdsByCategoryId ;
                const detailRes = await axios.post( urlRequest, passValue);
                response = detailRes.data;
                
            } catch (e) {

            }
        }

        return response;
    }
    
    getProfileDetail =  async ( account_id = 0 ) => {
        account_id = account_id.toString();

        let profileDetail = {};
        if ( account_id!=='' ){
            try {
                const passValue = { account_ids : account_id.toString() }
                const urlRequest = ApiUrls.mainCompanyKeywordsGetProfileByIds ;
                const profileDetailRes = await axios.post( urlRequest, passValue);
                profileDetail = profileDetailRes.data;

            } catch (e) {
                profileDetail = {};
            }

        }
        return profileDetail;
    }

    followingStatusUpdate =  async (passValue = {} ) => {

        let responseDetail = {};
        if ( Object.entries(passValue).length>0 ){
            try {
                const urlRequest = ApiUrls.followingUpdateStatus ;
                const responseDetailDb = await axios.post( urlRequest, passValue);
                responseDetail = responseDetailDb.data;

            } catch (e) {
 
            }

        }
        return responseDetail;
    }


    contactRequestTeamDetail =  async (passValue = {} ) => {

        let responseDetail = {};
        if ( Object.entries(passValue).length>0 ){
            try {
                const urlRequest = ApiUrls.bizchatContactRequestTeam ;
                const responseDetailDb = await axios.post( urlRequest, passValue);
                responseDetail = responseDetailDb.data;

            } catch (e) {
                responseDetail = {};
            }

        }
        return responseDetail;
    }

    requestAccessTeamCommunication =  async (passValue = {} ) => {

        let responseDetail = {};
        if ( Object.entries(passValue).length>0 ){
            try {
                const urlRequest = ApiUrls.bizchatRequestAccessTeamCommunication ;
                const responseDetailDb = await axios.post( urlRequest, passValue);
                responseDetail = responseDetailDb.data;

            } catch (e) {
                responseDetail = {};
            }

        }
        return responseDetail;
    }

    // type = 1 => local, 2 => nationals, 3 => internationals
    formatAdressByLocationType = ( loctype = 1, profile = {} ) => {
        let returnStr = '', locationArr = [];
        loctype = parseInt(loctype);
        
       
        if ( loctype>0 && Object.keys(profile).length>0 ){

            let street_name = profile.hasOwnProperty('street_name') ? profile.street_name : '';
            let area_name = profile.hasOwnProperty('area_name') ? profile.area_name : '';
            let city_name = profile.hasOwnProperty('city_name') ? profile.city_name : '';
            //let state_name = profile.hasOwnProperty('state_name') ? profile.state_name : '';
            let country_name = profile.hasOwnProperty('country_name') ? profile.country_name : '';

            if ( loctype===1 ){
                locationArr.push(area_name);
                locationArr.push(street_name);
            }else if ( loctype===2 ){
                locationArr.push(area_name);
                locationArr.push(city_name);
            }else if ( loctype===3 ){
                locationArr.push(country_name);
                locationArr.push(city_name);
            }

            locationArr = locationArr.filter(function(e){return e}); 
                
            returnStr = locationArr.join(', ');
        }

        return returnStr;
    }


}

export default new QueryMasterSearchUtlities();

