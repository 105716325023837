import axios from 'axios';
import ApiUrls from './ApiUrls';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

class DocumentUtilities {
    
    copyFileToConversFolder = async ( passVal = {} ) => {
       
        let documentDetail = {};
        if (  Object.entries(passVal).length>0 ){
            
            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.documentTransferFileToConversFolder;
                const documentDetailRes = await axios.post( urlRequest, passValue);
                documentDetail = documentDetailRes.data;

            } catch (e) {
                documentDetail = {};
            }

        }
        return documentDetail;

    }

    getDocumentList = async ( passVal = {} ) => {

        let documentList = {};
        if ( Object.entries(passVal).length>0 ){
            
            passVal = {...passVal, api_token : API_PLUS_TOKEN.toString() };

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.bizcomDocumentsLibrary;
                const documentRes = await axios.post( urlRequest, passValue);
                documentList = documentRes.data;
            } catch (e) {
                documentList = {};
            }

        }
        return documentList;
    }

    uploadFile = async ( formData  ) => {

        let returnResponse = {};    
        try {
            const urlRequest = ApiUrls.bizcomUploadLibrary;
            const documentRes = await axios.post( urlRequest, formData);
            returnResponse = documentRes.data;

        } catch (e) {
            returnResponse = {};
        }

        return returnResponse;

    }

    saveDocumentDetail = async ( passVal = {} ) => {

        let returnResponse = {};
        if (  Object.entries(passVal).length>0 ){
            
            passVal = {...passVal, api_token : API_PLUS_TOKEN.toString() };

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.bizcomDocumentsLibrary;
                const documentRes = await axios.post( urlRequest, passValue);
                returnResponse = documentRes.data;

            } catch (e) {
                returnResponse = {};
            }

        }
        return returnResponse;

    }


}

export default new DocumentUtilities();