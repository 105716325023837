import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const SignupHeader = ( props ) => {

    useEffect( () => {

    }, [ ]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <header className="header mb-5 text-center">
            <div className="logo-wrap">
                <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                <div className="logo-type-text mt-2">{props.title}</div>
            </div>
        </header>
    )
}

export default SignupHeader;
