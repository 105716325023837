import React, { useState, useEffect, useCallback  } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; 

import PagesAuthentication from '../../helpers/PagesAuthentication';
import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';

import DocumentPreview from './DocumentPreview';

import Utilities from '../../helpers/Utilities';
import DocumentUtilities from '../../helpers/DocumentUtilities';
import TitlesLabels from '../../helpers/TitlesLabels';

const DocumentList = () => {

    const dispatch = useDispatch();

    const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token_bizcom;
    const maxFileSizeMb = Utilities.maxPDFFileSize;
    
    let defaultFormValues = { document_id : 0,  document_title : '' }
    
    const [ formValues, setFormValues] = useState(defaultFormValues);
    const [ loginDetail, setLoginDetail] = useState({});
    const [ documentSelected , setDocumentSelected ] = useState({});
    const [ documentList , setDocumentList ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ loadingStatus , setLoadingStatus ] = useState(true);

    const [ documentImageRequest, setDocumentImageRequest ] = useState('');
    const [ documentFileRequest, setDocumentFileRequest ] = useState('');
    const [ requestFileExtension, setRequestFileExtension ] = useState('');
    
    const [ showDocumentForm, setDhowDocumentForm ] = useState(false);
    const handleDocumentForm = () => setDhowDocumentForm(false);

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }else{

            let formValuesTemp = formValues;
            if ( fieldName==='document_title'){
                formValuesTemp = { ...formValuesTemp, ...{ document_title : value } };
            }

            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            setFormValues(formValuesTemp);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-documents > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const title = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const title_html = Utilities.isEmpty(keyword) ? title : title.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(title_html);
        });
    }


    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }
    
    
    const selectFile = () =>{
        jQuery('input#input_select_file').trigger('click');
    }
    
    const previewFile = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        const fileExtension = value.substr((value.lastIndexOf('.') + 1)).toString().toLowerCase();
        
        const fileSize = Utilities.formatBytes(e.target.files[0].size);
        const fileSizeArray = fileSize.split(' ');
        const fileSizeExt = fileSizeArray[1].toLowerCase();
        const fileSizeAmount = fileSizeArray[0];

        if ( fileSizeExt==='mb' && parseFloat(fileSizeAmount)>maxFileSizeMb ){

            setRequestFileExtension('');
            setDocumentFileRequest('');
            setDocumentImageRequest('');

            jQuery(e.target).val('');
            Utilities.messagePopup('warning', `filesize should not more than ${maxFileSizeMb}mb.`);

            return false;
        }

        if ( Utilities.validate_image_ext( value ) ){

                const reader = new FileReader();
                reader.onload = function (e) {
                
                    var img = new Image();      
                    img.src = e.target.result;

                    img.onload = function () {

                        // Check the image resolution
                        if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                            Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                            jQuery(elem).val('');
                            return false;
                        }
                        if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                            Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                            jQuery(elem).val('');
                            return false;
                        }

                        
                        setDocumentImageRequest(e.target.result);
                        //jQuery('.avatar-preview').css('background-image', 'url(' +  e.target.result + ')');
                    }
                };

                reader.readAsDataURL(e.target.files[0]);
                setDocumentFileRequest(e.target.files[0]);
                setRequestFileExtension('');

        }else if ( Utilities.validate_document_ext( value ) ){

            setRequestFileExtension(`.${fileExtension}`);
            setDocumentFileRequest(e.target.files[0]);
            setDocumentImageRequest('');

        }else{

            setRequestFileExtension('');
            setDocumentFileRequest('');
            setDocumentImageRequest('');

            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }

    const handleDocumenForm = ( action = '', detail = {} ) => {

        setDocumentFileRequest('');
        
        if ( action==='add'){
            setRequestFileExtension('');
            setDocumentImageRequest('');
            setFormValues(defaultFormValues);

        }else if ( action==='edit'){
            setFormValues({...defaultFormValues, document_id : documentSelected.id.toString(), document_title : documentSelected.title.toString() });

            if ( parseInt(documentSelected.attachment.file_type || 0)===2 ){
                setDocumentImageRequest(documentSelected.thumb_path);
                setRequestFileExtension('');
            }else{
                setDocumentImageRequest('');

                const fileName = documentSelected.attachment.file_name;
                const fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
                setRequestFileExtension(`.${fileExtension}`);
            }
        }

        setDhowDocumentForm(true);
    }

    const handleDeleteDocument = ( documentIds = '' ) => {

        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wishToDeleteDocument ,
            overlayClassName : 'success',
            buttons: [
                {  label: 'no',  onClick: () => {} },
                {
                    label: 'yes',  onClick: () => {
                        submitDelete(documentIds);
                    }
                }
            ]
        }); 

       
    }

    const submitDelete = async ( documentIds ='' ) => {
        
        if ( ! Utilities.isEmpty(documentIds) ){
            const passValue = {
                account_id : loginDetail.account_id.toString(),
                document_id : documentIds.toString(),
                flag : '3',
                title : '',
                attachment : ''
            }

            const documentListResponse = await DocumentUtilities.getDocumentList(passValue);
            if ( parseInt(documentListResponse.success)===1 ){

                Utilities.elementDisabledStatus('btn-submit-document', false );
                Utilities.messagePopup('Success', TitlesLabels.alertMessages.documentSuccessfullyDeleted );

                getDocuments(loginDetail);

            }else{
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotDeleteDocument );
            }
        }
       
    }
    

    const submitDocument = async () => {
        
        if ( Utilities.isEmpty(formValues.document_title) ){
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.titleIsRequired );
            jQuery('input[name="document_title"]').closest('.form-group').addClass('error');
            return false;
        }
        
        if ( Object.entries(documentSelected).length===0 ){
            if ( Utilities.isEmpty(documentFileRequest) ){
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.fileShouldNotBeEmpty );
                return false;
            }
        }
        
        const button_name = 'btn-submit-document';
        Utilities.elementDisabledStatus(button_name, true );

        let flag =  parseInt(formValues.document_id || 0)===0 ? 1 : 2;

        let passValue = {
            account_id : loginDetail.account_id.toString(),
            document_id : formValues.document_id.toString(),
            flag : flag.toString(),
            title : formValues.document_title.toString(),
            attachment : ''
        }
        
        const msgCouldNotUploadImage =  `Could not ${ parseInt(formValues.document_id || 0)===0 ? 'save' : 'update' } document!`;
        if ( ! Utilities.isEmpty(documentFileRequest) ){

            const formData = new FormData();
            formData.append('profile_id', loginDetail.account_id.toString());
            formData.append('account_id', loginDetail.account_id.toString());
            formData.append('api_token', API_PLUS_TOKEN.toString());
            formData.append('file', documentFileRequest);

            const fileDocumentResponse = await DocumentUtilities.uploadFile(formData);
            if ( parseInt(fileDocumentResponse.success)===1 ){
                const fileDocumentDetail = fileDocumentResponse.result.documents_encrypted_details;

                let documentRequest = [];
                documentRequest.push(fileDocumentDetail);

                passValue = {...passValue, attachment : documentRequest };

            }else{

                Utilities.messagePopup('Error', msgCouldNotUploadImage);
                Utilities.elementDisabledStatus(button_name, false );
            }

        }
        
        const documentListResponse = await DocumentUtilities.getDocumentList(passValue);
        if ( parseInt(documentListResponse.success)===1 ){
            Utilities.messagePopup('Success', `Document successfully ${ parseInt(formValues.document_id || 0)===0 ? 'saved' : 'updated' }!`);
            getDocuments(loginDetail);

        }else{
            Utilities.messagePopup('Error', msgCouldNotUploadImage);
        }

        Utilities.elementDisabledStatus(button_name, false );
    }


    const getDocuments = async ( loginDetail = {} ) => {
        
        setRequestFileExtension('');
        setDocumentFileRequest('');
        setDocumentImageRequest('');
        setDocumentSelected({});
        handleDocumentForm();

        setLoadingStatus(true);

        const passValue = {
            account_id : loginDetail.account_id.toString(),
            document_id : '0',
            flag : '0',
            title : '',
            attachment : ''
        }

        const documentListResponse = await DocumentUtilities.getDocumentList(passValue);
        if ( parseInt(documentListResponse.success)===1 ){
            const docList = documentListResponse.result.library_list;
            setDocumentList(docList);
            setLoadingStatus(false);

        }else{
            setDocumentList([]);
            setLoadingStatus(false);
        }

    }

    const escFunction = useCallback ((event) => {
        if(event.keyCode === 27) {
          //Do whatever when esc is pressed
          setDocumentSelected({});
        }
    }, []);

    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.documents ) );
        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
            if ( Object.keys(loginProfileInfo).length>0 ){

                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                //profileLoggedinTemp = {...profileLoggedinTemp, account_id : 176 };
                setLoginDetail(profileLoggedinTemp);
                getDocuments(profileLoggedinTemp);
            }
        }

        document.addEventListener("keydown", escFunction, false);
        
        const hash = window.location.hash;
        if ( ! Utilities.isEmpty(hash) ){
            if ( hash==='#add') {
                handleDocumenForm('add');
            }
        }

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };

    }, [] );

    return (
        <div className="documents-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <div>{ TitlesLabels.navigations.documents }</div>
                           
                          </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            <div className="btn-icon btn-plus" onClick={ () => handleDocumenForm('add') }></div>
                            <div className="btn-icon btn-magnify" onClick={ () => toggleSeachBox() } title={ TitlesLabels.navigations.search } ></div>
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div>
                         </li>
                     </ul>
                </div>


            </div>


            <div className="main-content">
                <div className="container">

                    <div className="profile-search-wrap d-none">
                        <div className="profile-search-content">
                            <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                            <div></div>
                            <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                            <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                        </div>
                    </div>

                    {
                        loadingStatus===false ?
                            <div className="documents-list-wrap mt-3">
                                {
                                    Object.entries(documentList).length>0 ?
                                        <ul id="listing-documents" className="ul-document-list text-lowercase">
                                            {
                                                Object.entries(documentList).map( ( [key, document ] ) =>
                                                    <li key={key} className="cursor-pointer"  data-keyword={document.title.toLowerCase()} onClick={ () => setDocumentSelected(document) }>
                                                        <div className="p-3"><div className={`document-preview file-${ document.attachment.file_name.substr((document.attachment.file_name.lastIndexOf('.') + 1)).toString().toLowerCase()}`} style={{ backgroundImage:`url(${document.thumb_path})`}}></div></div>
                                                        <div className="document-info">
                                                            <div className="font-gotham-book-14 mb-1 ellipsis-1" data-name={document.title.toLowerCase()}>{document.title.toLowerCase()}</div>
                                                            <div className="fs-12 text-color-gray">{ Utilities.systemDateFormatted(document.create_date)}</div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    :
                                         <div className="text-center text-lowercase no-record-label mt-5">{ Utilities.labelDocumentAvailable }</div>
                                }
                            </div>
                        :
                            
                            <ul className="ul-document-list-preloader mt-3">
                            {
                                [...Array(10).keys()].map( ( key ) => <li key={key}></li>  )
                            }
                            </ul>
                        
                    }

                </div>
            </div>

            {
                Object.entries(documentSelected).length>0 &&
                <DocumentPreview documentSelected={documentSelected} setDocumentSelected={setDocumentSelected} handleDocumenForm={handleDocumenForm} handleDeleteDocument={handleDeleteDocument}/>
            }

            <Modal id="document-form-popup"  show={showDocumentForm} onHide={handleDocumentForm} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme document-form-popup"  size="lg" >
                <Modal.Header closeButton className="border-bottom-0">
                    <div className="font-gotham-light-22 text-center w-100 pt-2">upload document</div>
                </Modal.Header>
                <Modal.Body className="pb-5">

                    <div className="text-lowercase form-theme form-theme-medium px-5">

                        <input type="file" id="input_select_file" name="file" className="invisible" readOnly onChange={ (e) => previewFile(e) } ></input>

                        <div className="px-5">
                            <div className="form-group">
                                <input name="document_title"  className="form-control" placeholder=" "  value={ formValues.document_title }  onChange={ (e) => pushValue( e, 'document_title') } autoFocus ></input>
                                <label className="form-label">title</label>
                            </div>

                            
                            <div className="upload-avatar avatar-wrap">
                                <div className="w-100">
                                    <div className="document-upload-preview mt-4" style={{ backgroundImage : `url(${documentImageRequest})`}}>
                                        { Utilities.isEmpty(documentImageRequest) && <div className="document-file-icon">{requestFileExtension}</div> }
                                    </div>
                                    <button type="button" className="btn-select-file text-lowercase mx-auto position-relative" onClick={ () => selectFile() }>select file</button>
                                </div>
                            </div>
                            
                            <div className="text-center text-color-gray pt-3">{ Utilities.labelUploadSpecs }</div>
                        </div>

                        <div className="button-group-boolean mt-5">
                            <button type="button" className="btn-theme-black" onClick={ () => handleDocumentForm () }>cancel</button>
                            <button type="button" id="btn-submit-document" className="btn-theme-black button-has-loader" onClick={ () => submitDocument() }>{ parseInt(formValues.document_id || 0)===0 ? 'submit' : 'update' }</button>
                        </div>
                        
                    </div>

                </Modal.Body>
            </Modal>

       </div>
    )
}

export default DocumentList;