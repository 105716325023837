import { confirmAlert } from 'react-confirm-alert'; 
import jQuery from 'jquery';
import TitlesLabels from './TitlesLabels';
import moment from 'moment';

class UtilitiesHelper {
    
    // LABELS ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    labelShowAll = "Show All"
    
    labelAllStates = "All States"

    labelAllCountries = "All Countries"

    labelAllCities = "All Cities"

    labelUploadSpecs = `format - jpeg, png, bmp min. size - 50x50px`

    labelInvalidFileExtension = `Invalid file extension!`

    labelNoChatActive = `Sorry, we couldn't find any results`
    
    labelNoFindResults = `Sorry, we couldn't find any results`

    labelKeywordThreeCharacters = `keyword should be minimum three characters`

    labelEmailExistUseOnother = `Email is already exist. Please use another email account!`

    labelPasswordConfirmNotSame = `Password and confirm password is not same!`

    labelMobileNumberAlreadyExist = `Mobile number already exist!`

    labelEmailAddressAlreadyExist = `Email address already exist!`

    labelLandlineNumberAlreadyExist = `Landline number already exist!`

    labelNotCheckedTermsConditions = `Terms and conditions is not checked!`

    labelPasswordFiveCharacters = `Password should be more the five characters.`

    labelUsernamePasswordNotMatched = `Username or Password did not matched!`

    labelEmailExist = `Email already exist!`

    labelEmailAddressTaken = `Email address is already taken!`

    labelAccountNotActive = `Account is not active!`
    
    labelUsernamePasswordMandatory = `Username and password is mandatory!`

    labelUsernamePasswordNotMatched = `Username or password did not matched!`

    labelCouldNotLoginCompanyAccount = `Could not login to company account!`

    labelCouldNotCreateAccountTryAgain = `Could not create account. Please try again!`

    labelCouldNoSendNotMemberGroup = `could not send message, you are not longer member of the group!`

    labelGroupChatDeletedByAdmin = `Group chat has been deleted by admin!`

    labelAreYouWishToDelete = `Are you sure you wish to delete?`
    
    labelCantDownloadFile = `Can't download orginal file!`

    labelAwaitingPemissionToConnect = `awaiting permission to connect`

    labelYouCantReplyThisConversation = `you can't reply to this conversation`

    labelHappyToAcceptCommunication = `i am happy to accept your communication<br />request and look forward to hearing from you.<br /><br />regards`

    labelYouHaveEnabledAutoReply = `you have enabled auto reply`

    labelCommnicationRequest = `communication request`

    labelAwaitingReply = `awaiting reply`

    labelReasonForResend = `reason for resend`
   
    labelReasonForRejection = `reason for rejection`

    labelPleaseWait = `please wait...`

    labelRecordAvailable = `No record available.`

    labelDetailAvailable = `No detail available.`
    
    labelProfileAvailable = `No profile available.`

    labelDocumentAvailable = `No document available.`

    labelMessageAvailable = `No message available.`

    labelDepartmentAvailable = `No department available`

    labelTeamAvailable = `No team available.`

    labelCitiesAvailable = `No cities available`

    labelMemberAvailable = `No member available`


    // CONSTANT ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    dateFormatStr = 'DD/MM/YY'

    timeFormatStr = 'hh:mm A'

    dayFormatServerStr  = 'YYYY-MM-DD'

    timeFormatServerStr  = 'HH:mm:ss'

    dateTimeServerStr  = 'YYYY-MM-DD HH:mm:ss'

    minImageResolution = { height : 50, width : 1800, lowMsg : 'Resolution is too low!', highMsg : 'Resolution is too high!' }

    menuProductOption = { 1 : 'menu', 2 : 'products' }

    menuProductOptionLabel = { 1 : 'menu', 2 : 'product list' }

    companyAboutWordsLimit = 2000

    teamRestrictionReasonLimit = 1000

    userCommunicationReasonLimit = 1000

    teamAboutWordsLimit = 2000

    appointmentReservationCharLimit = 200

    minPDFFileSize = 3  // in MB

    maxPDFFileSize = 15 // in MB

    cropProfileDimentionWidth = 160

    cropProfileDimentionHeight = 160

    cropProfileDimentionWidthLarge = 250

    cropProfileDimentionHeightLarge = 250

    googleTranslateLanguages = { 
                                'ar' : 'arabic', 'en' : 'english', 'es' : 'spanish',
                                'fr' : 'french', 'hi' : 'hindi', 'ja' : 'japanese',
                                'zh-CN' : 'chinese (simplified)', 'af' : 'afrikaans', 'ru' : 'russian',
                                'de' : 'german', 'sv' : 'swedish'
                            }


    cityLocationLabel = 'location'

    cityNewLocationLabel = 'new-location'

    talkToUsUrl = `https://beonchat.com`

    attributeImageAcceptExtension = `image/x-png,image/jpeg,image/bmp`

    attributeChatImageAcceptExtension = `image/x-png,image/jpeg,image/bmp,.doc, .docx,.ppt, .pptx,.txt,.pdf,.xlsx,.xls`
    

    homeVideoUrl = `https://www.img.jazenet.com/images/master.resources/uploads/jazenet/tutorial_videos/home.mp4`

    mediaNavigation = { 'media' : 'images', 'documents' : 'documents', 'links' : 'links', 'locations' : 'locations', 'qr-code' : 'qr code'  }
    

    // FUNCTIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    isEmpty = ( s ) =>  {
        return ( ( typeof s==='undefined' ) || (s === null) || (s.length === 0));
    }

    isDigit = (c) => {
		return ((c >= "0") && (c <= "9"))
	}

    statusLabels = (n) => {
        return ( n===1 ) ? 'active' : 'inactive';
    }

    featuredLabels = (n) => {
        return ( n===1 ) ? 'yes' : 'no';
    }

    isEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
   }

    inputCharacterChecker = ( valueString = '', type = 'general') =>{
        let format = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
        if (type==='general-companyname'){
            format = /[`!@#$%^*()+\=\[\]{};:"\\|,<>\/?]+/;
        }
        return format.test(valueString) ? false : true ;
    }

    checkSpecialChar = (string) =>{
        //var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        return format.test(string) ? true : false;
    }

    sliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    removeSpecialChar = (text) => {
        //const  regExpr = /[^a-zA-Z0-9-. ]/g; // excluded space and .
        const  regExpr = /[^a-zA-Z0-9-]/g;
        return text.replace(regExpr, "");
    }
    
    messagePopup = ( title = 'Error', message = '' ) => {
        confirmAlert({
            title: title,
            message: message,
            overlayClassName : title.toLowerCase(),
            buttons: [
                { label: 'Close',  onClick: () => {} }
            ]
        }); 
    }

    isValidURL = (str) => {
        var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if(!regex .test(str)) {
            return false;
        } else {
            return true;
        }
    }

    counterMore = ( currentCounter = 0, maxCounter = 99 ) => {
        let counterReturn = parseInt(currentCounter)>parseInt(maxCounter) ? maxCounter.toString()+'+' : currentCounter;
        counterReturn = counterReturn.toString();
        return counterReturn;
    }

    months = (num = -1) => {
        let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        if ( parseInt(num)>-1 ){
            months = months[num];
        }
        return months;
    }

    passwordValidation = ( requestPassword = '', showHtml = false ) => {
        let errorMessage = '', valid = true, htmlList = '', errorCounter = 0, genetalHtml = '';

        const errorLength = `be at least eight characters long`;
        const errorUppercase = `have at least one uppercase`;
        const errorLowercase = `have at least one lowercase`;
        const errorNumber = `have at least one number`;
        const errorSymbol = `have at leat special character`;

        let validList = { long : true, lowerCase : true, upperCase : true, numeric : true, symbol : true };
        

        if (requestPassword.length < 8) {
            errorMessage = errorLength;
            errorCounter++;
            validList = {...validList, long : false };
        }

        if (requestPassword.search(/[a-z]/) < 0) {
            errorMessage = errorLowercase;
            errorCounter++;
            validList = {...validList, lowerCase : false };
        }
        if (requestPassword.search(/[A-Z]/) < 0) {
            errorMessage = errorUppercase;
            errorCounter++;
            validList = {...validList, upperCase : false };
        }
        if (requestPassword.search(/[0-9]/) < 0) {
            errorMessage = errorNumber;
            errorCounter++;
            validList = {...validList, numeric : false };
        }
        if (requestPassword.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/) < 0) {
            errorMessage = errorSymbol;
            errorCounter++;
            validList = {...validList, symbol : false };
        }

        valid = parseInt(errorCounter)>0 ? false : true;

        if ( showHtml===true ){
            htmlList = `<ul class="password-verification-guide-list">
                            <li>password must:</li>
                            <li class="${ validList.long===true ? 'passed' : '' }">${errorLength}</li>
                            <li class="${ validList.upperCase===true ? 'passed' : '' }">${errorUppercase}</li>
                            <li class="${ validList.lowerCase===true ? 'passed' : '' }">${errorLowercase}</li>
                            <li class="${ validList.numeric===true ? 'passed' : '' }">${errorNumber}</li>
                            <li class="${ validList.symbol===true ? 'passed' : '' }">${errorSymbol}</li>
                        </ul>`
        }

        if ( requestPassword==='' ){ htmlList = ''; }
        if ( valid===false ){ genetalHtml = '<div class="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">password does not match the password criteria</div>'; }

        return { response : valid, errorMsg : errorMessage, html : htmlList, generalHtml : genetalHtml };

    }

    systemDateFormatted = ( dateRequest = '') => {
        const _SELF = this;
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        let month = currentDate.getMonth();
        month = parseInt(month.toString().length)===1 ? '0'+month: month;

        let date = currentDate.getDate() // 23
        date = parseInt(date.toString().length)===1 ? '0'+date: date;

        const year = currentDate.getFullYear() // 2019

        return `${date}/${month}/${year}`;
        //return `${month}/${date}/${year}`;
    } 

    currentDateFormatted = ( dateRequest = '') => {
        const _SELF = this;
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        const monthIndex = currentDate.getMonth();
        const month_name = _SELF.months(monthIndex);
        
        const year = currentDate.getFullYear() // 2019
        const date = currentDate.getDate() // 23

        return `${date} ${month_name} ${year}`;
    } 
    
    returnDateFormat = ( dateRequest = '') => {
        const _SELF = this;
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        let month = currentDate.getMonth();
        month = parseInt(month.toString().length)===1 ? '0'+month: month;

        let date = currentDate.getDate() // 23
        date = parseInt(date.toString().length)===1 ? '0'+date: date;

        const year = currentDate.getFullYear() // 2019

        return `${date}-${month}-${year}`;
    }

    convertTo24Hour = ( dateRequest = '' ) => {
        let returnValue = dateRequest;
        if ( ! this.isEmpty(dateRequest) ){
            const currentDate = new Date( '12-12-12 ' + dateRequest );
            returnValue = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
        }
        return returnValue;
    }

    returnTimeFormat = ( dateRequest = '', format = 12) => {
        
        const _SELF = this;

        let formatLabel = 'am';
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        let hour = currentDate.getHours();
        if ( parseInt(hour)>=12){ formatLabel = 'pm'; }
        if ( format===12 ){
            hour = hour % 12;
            if ( parseInt(hour)===0 ){
                hour = 12;
            }
        }
        hour = parseInt(hour.toString().length)===1 ? '0'+hour: hour;

        let min = currentDate.getMinutes()
        min = parseInt(min.toString().length)===1 ? '0'+min: min;

        return `${hour}:${min} ${formatLabel}`;
    }

    numberWithCommas = (x , symbol = ',') => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
    }
    
    numberFormat = (number, digit ) => {
        let decimal = 2;
        if( !this.isEmpty(digit) ){ decimal =  digit; } 
        return parseFloat(number).toFixed(decimal);
    }
    
    number_format = (number, digit = 0, symbol = ',')  => {
        number = this.numberFormat(number, digit);
        number = this.numberWithCommas(number, symbol);
        return number;
    }
        
    validate_image_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['jpg', 'png', 'jpeg', 'bmp'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }

    validate_document_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf', 'xlsx', 'xls'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }


    validate_document_zip = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['zip'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }

    validate_video_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['mp4', 'avi' ,'mov', 'flv', 'wmv'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }


    validate_audio_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['mp3', 'acc'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }


    scrollDownMore = ( elemId ) => {
        const selectedElem = jQuery('#'+elemId);
        const yCurrent = selectedElem.scrollTop();
        selectedElem.animate({ scrollTop: yCurrent + 250 });
    }

    scrollTopMore = ( elemId ) => {
        const selectedElem = jQuery('#'+elemId);
        const yCurrent = selectedElem.scrollTop();
        selectedElem.animate({ scrollTop: yCurrent - 250 });
    }
    
    copyContent = ( elemId ) => {
        /* Get the text field */
        const copyText = document.getElementById(elemId);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
    }
    
    checkContentIfHigher = ( elemId ) => {
        let returnVal = false;
        if ( !this.isEmpty(elemId) ){

            let elemSelected = document.getElementById(elemId);
            if (typeof(elemSelected) != 'undefined' && elemSelected != null){

                const innerHeight = document.querySelector('#'+elemId).scrollHeight;
                const selectedElem = jQuery('#'+elemId);
                
                const elemHeight = selectedElem.height();

                if ( parseInt(innerHeight)> 510){
                    if ( parseInt(innerHeight)> parseInt(elemHeight) ){
                        returnVal = true;
                    }
                }
            }
        }
        
        return returnVal;
    }

    wordsCounter = ( textString ) => {
        let counter = 0;
        if ( !this.isEmpty(textString) ){
            let counterArr = textString.split(' ');
            counter = counterArr.length;
        }
        return parseInt(counter);
    }

    charactersCounter = ( textString ) => {
        let counter = 0;
        if ( !this.isEmpty(textString) ){
            textString = textString.replaceAll(' ', '');
            counter = textString.toString().length;
        }
        return parseInt(counter);
    }

    contactNumberValidate = ( textString, showMsg = 0  ) => {
        let result = false;
        if ( !this.isEmpty(textString) ){
            textString = textString.toString();
            const counter = textString.length;
            result = ( counter<6 || counter>11 ) ? false : true;

            if ( parseInt(textString.split('')[0])===0){
                result = false;
            }    
        }

        return showMsg===0 ? result : { status : result , msg : TitlesLabels.general.numberInvalidIncorrect };
    }

    removeBackSlash = (str = '' ) => {
        let strFormatted = str.split("\\n");
        strFormatted = strFormatted.map( text => this.isEmpty(text) ? '<br />' : '<div>'+text+'</div>' );
        str = strFormatted.join('');
       return str.replaceAll(/\\/g, "");
    }

    replaceNewlineToSpace = (str = '' ) => {
        return str.split("\\n").filter(Boolean).join(' ');
    }

    basicJoinObjectValues = ( objectArray, separator = '-' ) => {
        let keyArr = [];
        Object.entries(objectArray).map( ([key, row]) => {
            keyArr.push(row);
        });
        keyArr = parseInt(keyArr.length)===0 ? '' : keyArr.join('-');
        return keyArr.toString();
    }

    formTypes = (type = 0) =>  {
        let types_labels = [
                { value : 1, label :'appointment' },
                { value : 2, label :'reservation' },
                { value : 3, label : 'feedback' }
            ];
        return types_labels;
    }

    viewFormLabel = ( formType = 1 ) => {
        formType = parseInt(formType);
        let returnLabel = '';

        if ( formType===1 ){
            returnLabel = 'Appointment';
        }else if ( formType===2 ){
            returnLabel = 'Reservation';
        }else if ( formType===3 ){
            returnLabel = 'Feedback';
        }

        return returnLabel;
    }


    accountTypeLabels = ( type, switchToCompany = 0 ) =>  {
        let types = [1,3,4];
        type = parseInt(type);
        let account_types = { 1 : 'personal', 3 : 'company', 4 : 'work'}
        if(types.indexOf(type) !== -1){

            let account_type_name = account_types[type];
            if (parseInt(switchToCompany)===1 && parseInt(type)===4){
                account_type_name = account_types[3];
            }
            return account_type_name;
        }else{ return ''; }
    }

    accountTypeCustomLabels = ( profile ) =>  {

        const SELF = this;
        let types = [1,3,4];
        const acctType = parseInt(profile.account_type);
        
        let account_types = { 1 : 'personal', 3 : 'company', 4 : 'work'}
        let title = '';
        
        if(types.indexOf(acctType) !== -1){

            title = account_types[acctType];
            if (acctType===4){
                title = 'team member';

                if ( profile.hasOwnProperty('company_detail') ){
                    if ( profile.company_detail.hasOwnProperty('category_name') ){
                        title = ! SELF.isEmpty(profile.company_detail.category_name) ? profile.company_detail.category_name : title;
                    }
                }

                if ( profile.hasOwnProperty('team_is_admin') ){
                    if ( parseInt(profile.team_is_admin)===1 ){
                        title = 'admin';

                    }else if ( parseInt(profile.team_is_admin)===2 ){
                        title = 'sub admin';
                    }
                }
            }
        }

        return title;
    }

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0)
    
    currentUTCTime = ()  => {
        const now = new Date();        
        const utc_timezone  = now.toUTCString();
        return utc_timezone;
    }

    arrayUnique = ( dataArray) => {
        dataArray = dataArray.filter(function(item, pos) {
            return dataArray.indexOf(item) === pos;
        })
        return dataArray;
    }

    removeValueInArray = (dataArray, value) => {
        let index = dataArray.indexOf(value);
        if (index !== -1) {
            dataArray.splice(index, 1);
        }
        return dataArray;
    }

    elementDisabledStatus = ( id, action = false ) => {
        if ( !this.isEmpty(id) ){
            let elemSelected = document.getElementById(id);
            if (typeof(elemSelected) != 'undefined' && elemSelected != null){
                elemSelected.disabled = action;

                if (action){
                    elemSelected.classList.add("animate");
                }else{
                    elemSelected.classList.remove("animate");
                }
            }
        }
    }

    randonLetter = (length = 2 ) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; //'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    generateSignupCode = () => {
        //let numeric = ( Math.floor(Math.random() * 10) + '' + Math.floor(Math.random() * 10) ); // max 9 - max 9
        return 'Q1w2'; //numeric + this.randonLetter();
    }

    logoFolderByAccountType = (type = 0) => {
        let url = '';
        if ( type>0 ){
            if ( type===1){ url = process.env.REACT_APP_images_external_url+'/individual_user'; }
            else if ( type===3){ url = process.env.REACT_APP_images_external_url+'/company'; }
            else if ( type===4){ url = process.env.REACT_APP_images_external_url+'/team_member'; }
        }
        return url;
    }

    toNormalArrayObject = (arr) =>{
        let arr_updated = [];
        for (const [key, value] of Object.entries(arr)) {
            arr_updated.push(value);
        }
        return arr_updated;
    }  
    
    getUrlSegments = () => {
        const pathname = window.location.pathname; 
        let segment = pathname.split('/');
        return segment.filter(Boolean);
    }

    defaultLocation = () => {

        let defaultLocation = {
            country_id : process.env.REACT_APP_country_id,
            country_name : process.env.REACT_APP_country_name,

            country_iso : process.env.REACT_APP_country_iso.toString().toLowerCase(),
            country_ext : process.env.REACT_APP_country_ext,
    
            state_id : process.env.REACT_APP_state_id,
            state_name : process.env.REACT_APP_state_name,
    
            city_id : process.env.REACT_APP_city_id,
            city_name : process.env.REACT_APP_city_name,
    
            area_id : process.env.REACT_APP_area_id,
            area_name : process.env.REACT_APP_area_name,
        };
    
        let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
        //let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_user_location);
        if ( sessionLocation!==null ){
            sessionLocation = JSON.parse(sessionLocation);
            defaultLocation = {
                country_id : sessionLocation.country_id || 0,
                country_name : sessionLocation.country_name,

                country_iso : sessionLocation.country_iso.toString().toLowerCase(),
                country_ext : sessionLocation.country_ext,
        
                state_id : sessionLocation.state_id || 0,
                state_name : sessionLocation.state_name,
        
                city_id : sessionLocation.city_id || 0,
                city_name : sessionLocation.city_name,
        
                area_id : 0,
                area_name : '',
            };
        }

        return defaultLocation;
    }


    formatProfileName = ( profile ) => {
        let profile_name = '';

        if ( parseInt(profile.account_type)===1 ){
            profile_name = profile.first_name+' '+profile.last_name;

        }else if ( parseInt(profile.account_type)===3 ){
            profile_name = profile.company_name;

        }else if ( parseInt(profile.account_type)===4 ){
            profile_name = profile.team_first_name+' '+profile.team_last_name;
        }

        return profile_name;
    }

    updateProfileInAccountList = ( profileDataRequest ) => {
        const SELF = this;
        
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){
                Object.entries(accountsList).map( ([key, profile]) => {

                    let profileTemp = profile;
                    if ( parseInt(profile.account_id)===parseInt(profileDataRequest.account_id)){
                        
                        profileTemp = { ...profile, ...profileDataRequest };
                        profileTemp = { ...profileTemp, name : SELF.formatProfileName(profileDataRequest) };

                        if ( profile.hasOwnProperty('departments') ){

                            let departmentsTemp = profile.departments;
                            Object.entries(profile.departments).map( ([keyDept, department]) => {
                              
                                if ( parseInt(department.account_id)===parseInt(profileDataRequest.account_id) && parseInt(department.account_type)===4 ){

                                    let propose_data = {
                                        ...department,
                                        team_first_name : profileDataRequest.team_first_name,
                                        team_last_name : profileDataRequest.team_last_name,
                                        flag : department.flag || '0',
                                        flag_main : department.flag_main || '0',
                                        leader_id : department.leader_id || '0',
                                        main : department.main || '0',
                                    }
                                    if ( parseInt(department.flag || 0)===4 ){
                                        propose_data = { ...propose_data,  name : SELF.formatProfileName(profileDataRequest) };
                                    }
                                    departmentsTemp[keyDept] = propose_data;
                                }
                            });

                            profileTemp = { ...profileTemp, departments : departmentsTemp };
                        }

                        accountsList[key] = profileTemp;
                    }
                });
            }

            localStorage.setItem(
                process.env.REACT_APP_session_accounts,
                JSON.stringify(accountsList)
            );
        }
    }


    workToCompany = ( profile ) => {
        let profileFormatted = profile;
        const account_type = parseInt(profile.account_type);
        if ( account_type===4 ){
            profileFormatted = this.formatProfileDetail(profile.company_detail);
        }else{
            profileFormatted = this.formatProfileDetail(profile);
        }

        return profileFormatted;
    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    sortAccounts = ( accountsList = {} ) => {
        let accountListTemp = [];
        if ( Object.entries(accountsList).length>0 ){

            Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                accountListTemp.push(acctdetail);
            });
            accountListTemp.sort((accountListTemp,b)=> (accountListTemp.account_type > b.account_type ? 1 : -1));
        }

        return accountListTemp;
    }

    calculateDistance = ( lat1 = '', lon1 = '', lat2 = '', lon2 = '', unit = 'K' ) => {
        let dist = 0 ;

        if ( lat1!=='' && lon1!=='' && lat2!=='' && lon2!=='' ){
 
            let radlat1 = Math.PI * lat1/180;
            let radlat2 = Math.PI * lat2/180;
            let radlon1 = Math.PI * lon1/180;
            let radlon2 = Math.PI * lon2/180;
            let theta = lon1-lon2;
            let radtheta = Math.PI * theta/180;
            
            dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;

            if (unit==="K") { dist = dist * 1.609344 }
            if (unit==="N") { dist = dist * 0.8684 }
        }

        return dist;
    }

    sortCalculateDistance = ( providedArray = {}, putZeroLast = 0 ) => {
        const SELF = this;

        let sortedList = [];
        if ( Object.entries(providedArray).length>0 ){
            const uniqueNodes = [];
            
            let latitude_init = providedArray[0]["latitude"];
            let longitude_init = providedArray[0]["longitude"];

            let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
            if ( sessionLocation!==null ){
                sessionLocation = JSON.parse(sessionLocation);
                latitude_init = sessionLocation.latitude ?? 0;
                longitude_init = sessionLocation.longitude ?? 0;
            }


            Object.entries( providedArray ).map( ( [ key, row ] ) => {
                const distance = SELF.calculateDistance( latitude_init, longitude_init ,row.latitude, row.longitude, "K");
                uniqueNodes.push({ ...row, distance : distance });
            });

            uniqueNodes.sort(function(a, b) { 
                return a.distance - b.distance;
            });

            sortedList = uniqueNodes;

            if ( putZeroLast===1 ){
                const distanceZero = uniqueNodes.filter( member => ( parseInt(member.distance) === 0 ) )  ?? [];
                let distanceGreater = uniqueNodes.filter( member => ( parseInt(member.distance) > 0 ) )  ?? [];

                distanceGreater.sort(function(a, b) { 
                    return a.distance - b.distance;
                });

                sortedList = [...distanceGreater, ...distanceZero ];
            }
        }

        return sortedList;
    }

    formatProfileDetail = ( profile, switchToCompany = 0 ) => {
        let profileFormatted = profile;
        
        let profile_logo = process.env.REACT_APP_default_avatar;
        const account_id = parseInt(profile.account_id || 0);
        const account_type = parseInt(profile.account_type || 0);

        let profile_name = '';
        let category = '';

        if ( account_type===1 ){
            if ( profile.hasOwnProperty('logo_url') ){
                profile_logo = profile.logo_url
            }else{
                if ( !this.isEmpty(profile.profile_logo) ){
                    profile_logo =  this.logoFolderByAccountType( account_type )+'/'+account_id+'/logo_thumb/thumb_'+profile.profile_logo;
                }
            }
            profile_name = profile.first_name+' '+profile.last_name;

        }else if ( account_type===3 ){
            if ( profile.hasOwnProperty('logo_url') ){
                profile_logo = profile.logo_url
            }else{
                if ( !this.isEmpty(profile.company_logo) ){
                    profile_logo =  this.logoFolderByAccountType( account_type )+'/'+account_id+'/logo_thumb/thumb_'+profile.company_logo;
                }
            }
            profile_name = profile.name;
            category = profile.category_name;

        }else if ( account_type===4 ){

            if ( switchToCompany===1 ){
                if ( typeof profile.company_detail.company_logo!=='undefined' ){
                    if ( !this.isEmpty(profile.company_detail.company_logo) ){
                        profile_logo =  this.logoFolderByAccountType(3)+'/'+profile.team_company_id+'/logo_thumb/thumb_'+profile.company_detail.company_logo;
                    }
                    
                    profile_name = profile.company_detail.hasOwnProperty('company_display_name') ? profile.company_detail.company_display_name : profile.company_detail.name;
                    category = profile.category_name;
                }

            }else{
                if ( profile.hasOwnProperty('logo_url') ){
                    profile_logo = profile.logo_url
                }else{
                    if ( !this.isEmpty(profile.team_display_pic) ){
                        profile_logo =  this.logoFolderByAccountType( account_type )+'/'+account_id+'/logo_thumb/thumb_'+profile.team_display_pic;
                    }
                }
                profile_name = profile.team_first_name+' '+profile.team_last_name;
                category = profile.team_position;
            }
            
        }

        profileFormatted = { 
                ...profile,
                id : parseInt(profile.account_id),
                type : parseInt(profile.account_type),
                name : profile_name,
                img : profile_logo,
                logo : profile_logo,
                category : category
            };

        return profileFormatted;
    }

    getUrlMetaDetail = ( providedMeta = {} ) => {
        let detail = { title : '', image : '', description : '' };
        if ( Object.keys(providedMeta).length>0 ){

            if ( providedMeta.hasOwnProperty('title') ){  detail = {...detail, title : providedMeta.title };  }

            if ( providedMeta.hasOwnProperty('twitter:title') ){ detail = {...detail, title : providedMeta['twitter:title'] }; }

            if ( providedMeta.hasOwnProperty('facebook:title') ){  detail = {...detail, title : providedMeta['facebook:title'] };  }

            if ( providedMeta.hasOwnProperty('description') ){ detail = {...detail, title : providedMeta.description }; }

            if ( providedMeta.hasOwnProperty('twitter:description') ){ detail = {...detail, description : providedMeta['twitter:description'] }; }

            if ( providedMeta.hasOwnProperty('facebook:description') ){ detail = {...detail, description : providedMeta['facebook:description'] }; }

            if ( providedMeta.hasOwnProperty('image') ){ detail = {...detail, image : providedMeta.image }; }

            if ( providedMeta.hasOwnProperty('twitter:image') ){  detail = {...detail, image : providedMeta['twitter:image'] }; }

            if ( providedMeta.hasOwnProperty('facebook:image') ){ detail = {...detail, image : providedMeta['facebook:image'] };  }

        }
        return detail;
    }

    getBrowerAgent = ( showVersion = false ) => {

        navigator.browserDetection = (function(){
            var ua= navigator.userAgent, tem, 
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE '+(tem[1] || '');
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
                M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
                if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
                return showVersion===false ? M[0] : M.join(' ');
        })();
        
        return navigator.browserDetection.toLowerCase(); // outputs: `Chrome 92`
    }


    socialLink = ( type = '', username = '' ) =>  {
        let urlResponse = '';
        if ( ['facebook','instagram','youtube','tiktok', 'twitter'].includes(type) && username!==''  ){

            if ( ['facebook'].includes(type) ){
                urlResponse = `https://www.facebook.com/${username}`;
            }else if ( ['instagram'].includes(type) ){
                urlResponse = `https://www.instagram.com/${username}`;
            }else if ( ['youtube'].includes(type) ){
                urlResponse = username;
            }else if ( ['tiktok'].includes(type) ){
                urlResponse = `https://www.tiktok.com/@${username}`;
            }else if ( ['twitter'].includes(type) ){
                urlResponse = `https://twitter.com/${username}`;
            }
        }
        
        return urlResponse;
    }

    
    statusDummyImage = ( urlProvided = '' ) => {
        let returnStatus = false, imageName = '';

        if ( ! this.isEmpty(urlProvided) ){
            imageName = urlProvided.split('/').slice(-1)[0];
            imageName = imageName.split('.')[0];
            
            returnStatus = false;
            if (imageName==='dummy_profile'){ //dummy_profile logo_1656425015184 logo_1657106473583 thumb_logo_180_1655774627845 thumb_logo_491_1655983374411 thumb_logo_491_1655983374411 thumb_logo_181_1655761826985
                returnStatus = true;
            }
        }else{
             returnStatus = true;
        }

        return returnStatus;
    }

    getInitialName = ( providedName = '') => {
        let initial = '';
        if ( ! this.isEmpty(providedName) ){
            providedName = providedName.trim();
            initial = providedName.split('')[0];
            if ( providedName.split(' ').length>1 ){
                initial = initial+providedName.split(' ').slice(-1)[0].split('')[0];
            }
        }
        return initial;
    }

    showHideProfileImage = ( logoUrl = '') => {
        if ( this.statusDummyImage(logoUrl)===true && logoUrl!=='' ){
            logoUrl = '';
        }
        return logoUrl;
    }

    showHideInitial = ( logoUrl = '', nameProfided = '' ) => {
        let letters = '';
        if ( this.statusDummyImage(logoUrl)===true && logoUrl!=='' ){
            letters = this.getInitialName(nameProfided);
        }
        return letters;
    }

    classHideInitialBg = ( logoUrl = '', className = 'bg-theme-black') => {
        return ( this.statusDummyImage(logoUrl)===true && logoUrl!=='' ) ? className : '';
    }

    bgcolorHideInitialBg = ( logoUrl = '') => {
        return ( this.statusDummyImage(logoUrl)===true && logoUrl!=='' ) ? this.randomBgColor() : '';
    }


    randomBgColor = () => {
        const randomColor = Math.floor(Math.random()*16777215).toString(16);
        return "#" + randomColor;
    }

    getElementSrollPosition = ( elemIdParent = '' ) => {
        let reachedBottom = false;
        if ( !this.isEmpty(elemIdParent) ){
            const scrollDemo = document.querySelector(`#${elemIdParent}`);
            const currentScroll = Math.ceil(scrollDemo.scrollTop);
            const totalHeight = Math.ceil(document.getElementById(elemIdParent).scrollHeight - document.getElementById(elemIdParent).offsetHeight);

            //console.log(currentScroll+'---'+totalHeight);
            if ( parseInt(currentScroll)===parseInt(totalHeight) ){ reachedBottom = true; }

        }
        return reachedBottom;
    }

    formatConversationList = ( row = {} ) => {
        let retunRow = {}

        if ( Object.keys(row).length>0 ){

            row = { 
                ...row,
                name : row.hasOwnProperty('name') ? row.name.toLowerCase() : '',
                logo :   row.hasOwnProperty('logo') ? row.logo : process.env.REACT_APP_default_avatar.toString(),
            };

            retunRow = row;

            let localTime  = moment(parseInt(row.order_by));
            let local_date = moment(new Date(localTime)).format(this.dateFormatStr.toString()).toLocaleString(); 
            
            let dateFormatted = '';
            if ( local_date.toString().toLocaleLowerCase() !== 'invalid date'){
            
                var sameDay = moment(row.order_by).isSame(moment(), 'day');
                if(sameDay===true) {
                    localTime  = moment.utc(row.order_by);                        
                    dateFormatted = moment(new Date(localTime)).format(this.timeFormatStr.toString()).toLocaleString(); 
                }else{
                    dateFormatted = moment(row.order_by).format(this.dateFormatStr.toString());                     
                }
            }

            retunRow = { ...retunRow , time : dateFormatted };

        }

        return retunRow;
    }

    formatDateList = ( dateProvided = '' ) => {
        let localTime  = moment(parseInt(dateProvided));
        let local_date = moment(new Date(localTime)).format(this.dateFormatStr.toString()).toLocaleString(); 3

        let dateFormatted = '';
        if ( local_date.toString().toLocaleLowerCase() !== 'invalid date'){
        
            var sameDay = moment(dateProvided).isSame(moment(), 'day');
            if(sameDay===true) {
                localTime  = moment.utc(dateProvided);                        
                dateFormatted = moment(new Date(localTime)).format(this.timeFormatStr.toString()).toLocaleString(); 
            }else{
                dateFormatted = moment(dateProvided).format(this.dateFormatStr.toString());                     
            }
        }
        
        return dateFormatted;
    }

    formattedURL  = ( str = '') =>{

        if ( (str ?? '') !== '' ){
            str = str.replaceAll('http://', '');
            str = str.replaceAll('https://', '');

            str = 'http://'+str;
        }

        return str;
    }

    addFormGroupClass = (key = '') => {
        jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
    }

    removeFormGroupClass = (key = '') => {
        jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
    }

    rootFilterBlur = ( status = false ) => {
        //console.log(status);
        if ( status===true ){
            jQuery('#root').addClass('filter-blur');
        }else{
            jQuery('#root').removeClass('filter-blur');
        }
       
    }
}

export default new UtilitiesHelper();

