import React, { useEffect, useState  } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { showHeader, showFooter, pageTitle } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';

const PersonalLogin = () => {

    const dispatch = useDispatch();
    const history = useHistory(); 

    const [ formValues, setFormValues ] = useState({ email : '', password : '' });
    const [ defaultResMessage, setDefaultResMessage ] = useState({ status : false, msg : Utilities.labelUsernamePasswordNotMatched  });

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='email'){
            setFormValues({...formValues, ...{ email : value } });
        }else  if ( fieldName==='password'){
            setFormValues({...formValues, ...{ password : value } });
        }
    }
    
    const showResMessageWarning = () => {
        let timer = setTimeout( () => { 
            setDefaultResMessage({ status : false , msg : '' });
            clearTimeout(timer);
        }, 2000);
    }
    
    const loginAccount = () => {

        const errorMsg = process.env.REACT_APP_message_error_process;
        setDefaultResMessage( { status : 0, msg : errorMsg} );

        const button_name = 'btn-login-user';

        if ( !Utilities.isEmpty(formValues.email) && !Utilities.isEmpty(formValues.password) ){

            Utilities.elementDisabledStatus(button_name, true );

            axios
            .post( ApiUrls.userGetByCredentail , formValues )
            .then( response => {
                const resStatus = response.status;

                Utilities.elementDisabledStatus(button_name, false );

               if ( resStatus===200 ){
                    const resData = response.data;
                    const resStatus = parseInt(resData.status);

                    if ( parseInt(resData.status)===1){

                        if ( parseInt(resData.detail.status)===1 ){

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_signup,
                                JSON.stringify(resData.detail)
                            );

                            /* localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            ); */
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );

                            localStorage.setItem( process.env.REACT_APP_session_welcome_mesage, 1 );
                            
                            history.push(UrlSlugs.companyInformation);

                        }else{
                            setDefaultResMessage({ status : true, msg : Utilities.labelAccountNotActive }) ;
                            showResMessageWarning();
                        }

                    }else if ( resStatus===2 ) {
                        setDefaultResMessage({ status : true, msg : Utilities.labelCouldNotLoginCompanyAccount  });
                        showResMessageWarning();
                     
                    }else{
                        setDefaultResMessage({ status : true, msg : Utilities.labelUsernamePasswordNotMatched });
                        showResMessageWarning();
                    }

                }else{
                    setDefaultResMessage({ status : true, msg : errorMsg});
                    showResMessageWarning();
                } 
    
            })
            .catch( (err) => {
                setDefaultResMessage({ status : true, msg : errorMsg});
                showResMessageWarning();

                Utilities.elementDisabledStatus(button_name, false );
            });

        }else{
            setDefaultResMessage({ status : true, msg :Utilities.labelUsernamePasswordMandatory  });
            showResMessageWarning();

            Utilities.elementDisabledStatus(button_name, false );
        }
    }

    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.personalLogin ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        PagesAuthentication.checkLoginStatus();

    }, [] );

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
            <SignupHeader title="Account Type" />
             {/* <header className="header mb-5 text-center">
                 <div className="logo-wrap">
                     <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                     <div className="logo-type-text mt-2">Account Type</div>
                 </div>
             </header> */}

             <div className="col-6 mx-auto pt-1">

                 <div className="text-center text-size-medium">
                    <div className="mb-4">in order to create a company account, you must have already created a personal account.</div>
                    <div>if you already have one, enter your login details</div>
                 </div>
                 

                <div className="login-form col-10 py-4 mx-auto  text-lowercase form-theme">
                    
                        <div className="form-group mt-4">
                            <input type="text" name="email" className="form-control  text-center" placeholder=" " value={ formValues.email } onChange={ (e) => pushValue(e, 'email') }  autoComplete="new-password" ></input>
                            <label className="form-label  text-center">Username</label>
                        </div>

                        <div className="form-group">
                            <input type="password" name="password" className="form-control text-center" placeholder=" " value={ formValues.password } onChange={ (e) => pushValue(e, 'password') } autoComplete="new-password" ></input>
                            <label className="form-label text-center">Password</label>
                        </div>

                        <div className={ `process-message pt-1 text-center ${ defaultResMessage.status===true ? '' : 'invisible' }` }>{ defaultResMessage.msg }</div>

                        <div className="button-wrap text-lowercase text-center mt-4">
                            <div className="mb-3"><button type="button" id="btn-login-user" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => loginAccount() }>Sign In</button></div>
                        </div>
                </div>


                <div className="text-center text-size-medium mt-3">
                    <div>Don't have a personal account?</div>
                    <div> click here to <Link to={`/${ UrlSlugs.signup_persoanlInfo}`} className="text-color-pink">create</Link></div>
                 </div>


             </div>
             
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_accountType}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
    </div>
    )
}

export default PersonalLogin;