import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as RM from 'ramda';

import Utilities from './../helpers/Utilities';
import { globalProfile } from '../actions';
import { Fragment } from 'react';
import UrlSlugs from '../helpers/UrlSlugs';

const BreadcrumbProfile = ( props ) => {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [ listAccounts, setListAccounts ] = useState([]);
    const [ notificationTotalCounter, setNotificationTotalCounter ] = useState(-1);
    const [ accountKeyExpanded, setAccountKeyExpanded ] = useState(-1);
    const [ showLabel, setShowLabel ] = useState(true);

    const expandList = ( key ) => {
        key = ( parseInt(key)===parseInt(accountKeyExpanded) ) ? -1 : key;
        setAccountKeyExpanded( parseInt(key) );
    }

    const extractDetail = ( key, row ) => {

        let htmlReturn = '';
        if ( parseInt(row.account_type)===3 ){
            const accountlabel = row.hasOwnProperty('account_title') ? row.account_title : 'team member';

            htmlReturn = <div className={`item-user-selection dropdown-item ${ parseInt(accountKeyExpanded)===parseInt(key) ? 'expanded' : ''}`} onClick={ () => expandList(key) }>
                            <div className={`profile-logo logo-size-40`} style={{ backgroundImage : `url(${ Utilities.showHideProfileImage(row.logo) })`, borderRightColor : Utilities.bgcolorHideInitialBg(row.logo) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div>
                            <div>
                                <div className="user-label ellipsis-1">{ row.name }</div>
                                <div className="ellipsis-1">{accountlabel}</div>
                            </div>
                            {   parseInt(row.count)>0  && <span className="counter counter-styled"><em>{ Utilities.counterMore(row.count) }</em></span> }
                        </div>

            let htmlReturnSub = '';
            Object.entries(row.child_account_list).map( ([keySub , rowSub] ) => {

                htmlReturnSub = 
                            <Fragment>
                                { htmlReturnSub }
                                <Dropdown.Item key={keySub}  className={`item-user-selection profile-subaccounts dropdown-item ${ parseInt(accountKeyExpanded)===parseInt(key) ? '' : 'd-none'}`} onClick={ () => switchProfile(rowSub) } >
                                    <div></div>
                                    <div>
                                        <div className="user-name ellipsis-1">{ rowSub.name }</div>
                                    </div>
                                    {   parseInt(rowSub.count)>0  && <span className="counter"><em>{ Utilities.counterMore(rowSub.count) }</em></span> }
                                </Dropdown.Item>
                            </Fragment>
 
            });
                        
            htmlReturn = <div key={key} className="profile-group-wrapper">{ htmlReturn } { htmlReturnSub }</div>

        }else{
            
            htmlReturn = <Dropdown.Item  key={key} className="item-user-selection" onClick={ () => switchProfile(row) }>
                            <div className={`profile-logo logo-size-40`} style={{ backgroundImage : `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div>
                            <div>
                                <div className="user-label ellipsis-1">{ row.title_name }</div>
                                <div className="ellipsis-1">{ row.name }</div>
                            </div>
                            {   parseInt(row.count)>0  && <span className="counter counter-styled"><em>{Utilities.counterMore(row.count) }</em></span> }
                        </Dropdown.Item>
            
        }

        return htmlReturn;
    }

    const switchProfile = (profile) => {
        dispatch( globalProfile(profile) );
        history.push(`/${UrlSlugs.dashboard}`);
    }

    useEffect( () => {

        let notification_list = props.allConversationResults.notification_list;
        if ( typeof notification_list !== 'undefined' ){
           
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
            if ( accountsList!==null ){
                accountsList = JSON.parse(accountsList);

                if ( parseInt( Object.keys(accountsList).length )>0 ){

                    let accountArrangedByCompany = [];
                    Object.entries(accountsList).map( ([key, account]) => {
                        if ( parseInt(account.account_type)===4 ){
                            accountArrangedByCompany[account.team_company_id.toString()] = account;
                        }
                    });

                    let accountSub = [];
                    let notificationListTemp = [];

                    for( const notification of notification_list ){
                        const account_type = parseInt(notification.account_type);

                        let accountLabel = 'team member';
                        if ( account_type===3 ){

                            if ( typeof accountArrangedByCompany[notification.account_id.toString()]!=='undefined' ){

                                const accountDetail = accountArrangedByCompany[notification.account_id.toString()];
                                const team_is_admin = parseInt(accountDetail.team_is_admin);

                                if ( team_is_admin===1 ){
                                    accountLabel = 'admin';
                                }/* else{
                                    if ( accountDetail.hasOwnProperty('company_detail') ){
                                        if ( 'category_name' in accountDetail.company_detail ){
                                            accountLabel = ! Utilities.isEmpty(accountDetail.company_detail.category_name) ? accountDetail.company_detail.category_name : accountLabel;
                                        }
                                    }
                                } */
                            }

                            accountSub.push({ ...notification, account_title : accountLabel });;

                        }else if ( account_type===1 ){
                            notificationListTemp.push({ ...notification, account_title : accountLabel });
                        }
                    }

                    if ( accountSub.length>0 ){
                        if ( 'name' in accountSub[0] ){
                            const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                            accountSub = RM.sort(byName, accountSub);
                        }
                        notificationListTemp = RM.concat(notificationListTemp, accountSub);
                    }
                    notification_list = notificationListTemp;
                }
                
            }

            setListAccounts(notification_list);
            setNotificationTotalCounter(props.allConversationResults.notification_count);
            
        }

    }, [ props.allConversationResults ]);


    useEffect( () => {
        const showLabelTemp = props.showLabel;
        if ( typeof showLabelTemp !== 'undefined' ){
            setShowLabel(showLabelTemp);
        }
    }, [ props.showLabel ]);
    

    return (
        <>
        {
            parseInt(notificationTotalCounter)>0 &&
                <Dropdown className="dropdown-user-selection">
                    <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" id="dropdown-basic">
                        <div className="btn-icon btn-comment" title="new messages">
                            {  ( parseInt(notificationTotalCounter)>0 ) && <span className="counter">{ Utilities.counterMore(notificationTotalCounter) }</span> }
                        </div>
                        { showLabel===true && <span className="ml-2">new messages</span> }
                    </Dropdown.Toggle>

                    {  
                        ( parseInt(notificationTotalCounter)>0 ) && 
                            <Dropdown.Menu >
                                { showLabel===false && <div className="dropdown-header">new messages</div> }
                                {
                                    Object.entries(listAccounts).map( ([key, row] ) =>  extractDetail( key, row ) )
                                }
                                
                            </Dropdown.Menu>
                    }
                </Dropdown>
        
        
        }

        {
             parseInt(notificationTotalCounter)===-1 && <div className="btn-icon btn-comment opacity-05 processing cursor-wait" title="new messages"></div>
        }

        </>

    )
}

export default BreadcrumbProfile;