import React, { useEffect, useState  } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { showHeader, showFooter, pageTitle } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';

const CompanyCondition = () => {

    const dispatch = useDispatch();
    const history = useHistory(); 

    const siteTitle = process.env.REACT_APP_site_title; 

    const [ acceptCondition, setAcceptCondition] = useState(false);

    const pushCondition = () => {
        setAcceptCondition(acceptCondition  ? false : true );
    }

    const submitAcceptance = () => {
        history.push(`/${UrlSlugs.signup_companyAddress}`);
       
    }

    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.importantInformation ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
    

    }, [] );

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
            <SignupHeader title={ TitlesLabels.general.signup_importantInformation } />
             {/* <header className="header mb-5 text-center">
                 <div className="logo-wrap">
                     <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                     <div className="logo-type-text mt-2 text-pink-3">{ TitlesLabels.general.signup_importantInformation }</div>
                 </div>
             </header> */}

             <div className="col-6 mx-auto pt-1">

                    <ul className="ul-listing-bullet font-gotham-book text-gray-3 pb-5">
                        <li className="mb-3">{siteTitle} was designed to ensure simplistic ease for users to connect with you as a business.</li>
                        <li className="mb-3">a non-negotiable condition is that connectivity is not abused by businesses in spamming and sending unsolicited advertisements to connected users or other businesses and professionals that you wish to do business with.</li>
                        <li>this is an infringement of their privacy and will result in very strong censure if their rights are violated.</li>
                    </ul>

                    <div className="text-size-semi-medium py-4 checkbox-radio-default checkbox-radio-check-icon d-flex  mt-5">
                        <input type="checkbox" name="acceptance"  checked={ acceptCondition }  onChange={ () => pushCondition() } ></input>
                        <span className="ml-2 text-black font-gotham-book-16">do you agree?</span>
                    </div>

                    <div className="button-wrap text-lowercase text-center pt-3">
                        <div className="mb-3">
                            <button type="button" id="btn-submit-personal-condition" className="btn-theme-black button-has-loader" onClick={ (e) => submitAcceptance() } disabled={ acceptCondition ? false : true}>continue</button>
                        </div>
                    </div>

             </div>
             
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_companyKeywords}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
    </div>
    )
}

export default CompanyCondition;