import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {  useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';

import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import TitlesLabels from '../helpers/TitlesLabels';

import { showMainSearch, showHeader, showFooter, pageTitle, showLoader  } from '../actions';
import {  companyGroupsListBizchat, joinOpenGroup, joinWithPasscode, removeJoinRequest, requestToJoin } from '../helpers/QueryChatGroups';
import QueryChatUtilities from '../helpers/QueryChatUtilities';
import ChatUtilities from '../helpers/ChatUtilities';
import UrlSlugs from '../helpers/UrlSlugs';



const CompanyGroups = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const clsParamGroupId = match.params.groupid ?? '';


    const [ loggedInProfile, setLoggedInProfile ] = useState({});
    const [ listGroups, setListGroups ] = useState([]);
    const [ selectedGroupDetail, setSelectedGroupDetail ] = useState([]);
    const [ companyProfile, setCompanyProfile ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');

    const [ reasonContent, setReasonContent ] = useState('');
    const [ showJoinReason, setShowJoinReason ] = useState(false);
    const [ showPasscodeEntry, setShowPasscodeEntry ] = useState(false);
    const [ showInvalidPasscode, setShowInvalidPasscode ] = useState(false);

    // MODAL
    const [show, setShow] = useState(false);
    const [showChatConnect, setShowChatConnect] = useState(false);

    const handleCloseChatConnect = () =>{
        setShowChatConnect(false);
    }

    const handleClose = () =>{
        setShow(false);
        setSelectedGroupDetail({});
    }

    const connectChatToGroup = async ( groupDetail = {} ) => {

        if ( Object.keys(selectedGroupDetail).length>0){
            groupDetail = selectedGroupDetail;
        }

        if ( Object.keys(groupDetail).length===0){ return false; }
        
        let conversationDetailResponse = {}, profileChatConversation = {}, passValues = {}, paramsValues = {}, button_name = 'btn-chat-connect';

        paramsValues = {
            api_token : TOKEN.toString(),
            account_id : loggedInProfile.account_id.toString(),
            group_id : groupDetail.id.toString(),
            conversa_id : groupDetail.conversa_id.toString(),
        }
       
        Utilities.elementDisabledStatus(button_name, true );

        conversationDetailResponse = await QueryChatUtilities.getBeonchatGroupsConversationDetail(paramsValues);
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.keys(conversationDetailResponse).length===0 ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
            return false;
        }

        if ( parseInt(conversationDetailResponse.success)===0){
            Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
            return false;
        }

        profileChatConversation = conversationDetailResponse.result.company_broadcast_conversation_details
        passValues = ChatUtilities.encryptChatDetail(profileChatConversation); 
        ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'company-group-page' );

        handleClose();
        history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileChatConversation.account_id) )}/${passValues}`);
    }

    const handleShow = async ( groupDetail = {}  ) => {
        const join_status = parseInt(groupDetail.join_status);

        setSelectedGroupDetail( groupDetail );

        if ( [1,2].includes(join_status) ){
            setShowChatConnect(true);
            return false;
        }

        setShowJoinReason(false);
        setShowPasscodeEntry(false);
        setReasonContent('');
        jQuery('.codes-input input').val('');
        setShowInvalidPasscode(false);
        setShow(true);
    }


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-departments > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const toggleMainSearch = ( action = 0 ) => {
        dispatch( showMainSearch(action) );
    }

    const getGroupList = async ( loginUserId = 0, companyIdProvided = 0 ) => {
        loginUserId = parseInt(loginUserId);
        companyIdProvided = parseInt(companyIdProvided);
        let groupId = 0, groupDefaultDetail = {};

        const paramList = {
            api_token : TOKEN.toString(),
            account_id : (loginUserId>0 ? loginUserId : loggedInProfile.account_id ?? 0).toString(),
            company_id : (companyIdProvided>0 ? companyIdProvided : companyProfile.account_id ?? 0).toString(),
        }

        const responseList = await companyGroupsListBizchat(paramList);
        setContentLoaderClass('');

        if ( Object.entries(responseList).length===0 || parseInt(responseList.success ?? 0)===0){ 
            setShowEmpty(true);
            return false; 
        }
   
        const groupsListRes = responseList.result.company_group_list ?? [];
        setListGroups(groupsListRes);


            if ( !Utilities.isEmpty(clsParamGroupId) ){
                groupId = parseInt(atob(clsParamGroupId));
                
                if ( groupId>0 ){
                    groupDefaultDetail = groupsListRes.filter( row => parseInt(row.id) === groupId )[0]  ?? {};
                    if ( Object.keys(groupDefaultDetail).length>0 ){

                        handleShow(groupDefaultDetail);
                        history.push(`/${UrlSlugs.companyGroups}/${btoa(companyIdProvided)}`);
                    }
                }
            }

    }

    const getCompanyDetail = ( accountID ) => {

        if ( parseInt(accountID)>0 ){

            // GET COMPANY PROFILE
            axios
            .post( ApiUrls.profilegetByID, { id : accountID} )
            .then( response => {

                if ( response.status===200 ){
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);
                    
                    if ( dataStatus===1 ){
                        
                        const company_detail = Utilities.formatProfileDetail(dataResponse.detail);
                        setCompanyProfile(company_detail);

                        if ( Utilities.isEmpty(dataResponse.detail.company_name??'') ){
                            history.push(`/${UrlSlugs.dashboard}`);
                        }
                        
                        dispatch( pageTitle(`${ TitlesLabels.siteTitles.companyGroups } | ${dataResponse.detail.company_name}`) );
                    }
                }else{
                    history.push(`/${UrlSlugs.dashboard}`);
                }
            })
            .catch( (err) => {
                history.push(`/${UrlSlugs.dashboard}`);
            });
        }
    }

    const joinLabel = ( status = 0 ) => {
        status = parseInt(status);
        if ( status===1 ){
            return `joined`;
        }else if ( status===2 ){
            return `pending`;
        }
    }

    const handleRemoveInvitation = async () => {
        let paramsValues = {}, responseStatus = {}, button_name = 'btn-chat-cancel-invitation';

        paramsValues = {
            api_token : TOKEN.toString(),
            account_id : (loggedInProfile.account_id??0).toString(),
            company_id : (companyProfile.account_id??0).toString(),
            group_id : (selectedGroupDetail.id??0).toString(),
            convers_id : (selectedGroupDetail.conversa_id??0).toString()
        }
        
        Utilities.elementDisabledStatus(button_name, true );
        responseStatus = await removeJoinRequest(paramsValues);
        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.entries(responseStatus).length===0 ){ 
            Utilities.messagePopup('error', errorMsg );
            return false; 
        }

            if ( parseInt(responseStatus.success)===0 ){ 
                Utilities.messagePopup('error', responseStatus.errorMessage );
                return false; 
            }

            Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyRemoved );


        getGroupList();
        handleCloseChatConnect();
    }

    const joinSubmission = async ( action = 0 ) => {
        
        action = parseInt(action);
        if ( action===0 || Object.keys(selectedGroupDetail).length===0 ){ return false; }

        let responseJoin = {}, button_name = 'btn-submit-join'; //, input_codes = '';
        
        let paramsJoin = {
            api_token : TOKEN.toString(),
            account_id : (loggedInProfile.account_id??0).toString(),
            company_id : (companyProfile.account_id??0).toString(),
            group_id : (selectedGroupDetail.id??0).toString()
        }

        // NORMAL JOIN
        if ( action===1 ){

            Utilities.elementDisabledStatus(button_name, true );
            responseJoin = await joinOpenGroup(paramsJoin);
           
        // REQUEST TO JOIN
        } else if ( action===2 ){

            if ( Utilities.isEmpty(reasonContent) ){
                return false;
            }

            button_name = 'btn-submit-join-request';
            Utilities.elementDisabledStatus(button_name, true );

            paramsJoin = {...paramsJoin , reason : reasonContent.toString() };
            responseJoin = await requestToJoin(paramsJoin);

        // REQUEST TO JOIN with PASSCODE
        } else if ( action===3 ){

            // CHECKING PASSCODE
            /* input_codes = '';
            jQuery('.codes-input input').each(function (){
                input_codes += jQuery(this).val();
            }); */

            dispatch( showLoader(1) );
            responseJoin = await joinWithPasscode(paramsJoin);

        }

        if ( Object.keys(paramsJoin).length>0 ){

            Utilities.elementDisabledStatus(button_name, false );
            dispatch( showLoader(0) );

            if ( Object.entries(responseJoin).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }
    
                if ( parseInt(responseJoin.success)===0 ){ 
                    Utilities.messagePopup('error', responseJoin.errorMessage );
                    return false; 
                }

                Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyJoined );
                
                handleClose();
                getGroupList();

        }
    }


    const handleClickProvideReason = () =>{
        setShowJoinReason(true);
        setTimeout( () => {
            jQuery('textarea[name="joining_reason"]').focus();
        }, 500);
    }

    const codeReset = ( e) => {
        jQuery(e.target).val('');
        setShowInvalidPasscode(false);
    }

    const codeFocus = ( e, type = '' ) => {
        let value = e.target.value;
        if ( !Utilities.isEmpty( value ) ){
            jQuery(e.target).next('input').val('').focus();
        }
        if ( type==='last' ){
            jQuery('input#dummy').focus();

            // CHECKING PASSCODE
            let input_codes = '';
            jQuery('.codes-input input').each(function (){
                input_codes += jQuery(this).val();
            });

                if ( ! Utilities.isEmpty(input_codes) ){

                    if ( parseInt(input_codes)===parseInt(atob(selectedGroupDetail.security_passcode)) ){
                        setShowInvalidPasscode(false);
                        joinSubmission(3);

                    }else{
                        setShowInvalidPasscode(true)
                    }
                }
                
    
        }
    }

    const codeBack = (e) => {
        const key = parseInt(e.keyCode || e.charCode);
        
        if( key===8 ){
            jQuery(e.target).prev('input').val('').focus();
            setShowInvalidPasscode(false);
        }
    }

    useEffect( async () => {

        let reqID = match.params.id;

        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);
            
            if ( parseInt(reqID)>0 ){

                let userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
                setLoggedInProfile(userLoginDetail ?? {});

                await getGroupList( userLoginDetail.account_id, reqID);
                await getCompanyDetail(reqID);

            }
        }


    }, [match.params.id] );


    useEffect( () => {

        PagesAuthentication.getUserLoggedin();
        let loginType = parseInt(localStorage.getItem(process.env.REACT_APP_session_logintype)??1);

        dispatch( showHeader(loginType===2 ? 5 : 1) );
        dispatch( showFooter(1) );

        dispatch( pageTitle(`${ TitlesLabels.siteTitles.companyGroups }`) );

    }, []);

    return (
        <div className="departments-page company-groups-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div className="cursor-pointer" onClick={ () => toggleMainSearch(1) }>{ TitlesLabels.navigations.search }</div>
                            { companyProfile.hasOwnProperty('company_name') && <div>{companyProfile.company_name}</div> }
                            <div className="current">{ TitlesLabels.navigations.companyGroups }</div>
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() }></div>
                         </li>
                     </ul>
                </div>
            </div>

            <div className="main-content">
                <div className="container">

                        { 
                            ! Utilities.isEmpty(contentLoaderClass) &&
                                <ul className="listing-profiles-preloader mt-4">
                                {
                                    [...Array(12).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                            </ul>
                        }

                        <div className="profile-search-wrap d-none">
                            <div className="profile-search-content">
                                <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                <div></div>
                                <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                            </div>
                        </div>

                        { 
                            Object.entries(listGroups).length>0 &&
                                <ul id="listing-departments" className={`listing-profiles max-height-fit  my-4`}>
                                {
                                    Object.entries(listGroups).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.name} onClick={ () => handleShow(row) } className="cursor-pointer">
                                            <div className="profile-wrap">
                                                <div className="profile-image-wrap">
                                                    <div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg( row.logo )  }}>{ Utilities.showHideInitial( row.logo , row.name) }</div>
                                                    </div>
                                                <div className="profile-name" data-name={row.name}>{row.name}</div>
                                                <div className="text-gray">{ joinLabel(row.join_status) }</div>
                                            </div>
                                        </li>
                                    )
                                }
                                </ul>
                        }


                        { showEmpty && <div className="text-center text-lowercase no-record-label">{ TitlesLabels.general.noGroupsAvailable }</div>  }
                        
                </div>
            </div>
        
            <Modal  show={show} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered contentClassName="position-relative overflow-hidden " dialogClassName="modal-company-join-groups" >
                <Modal.Header closeButton className="border-bottom-0 zindex-1"></Modal.Header>
                <Modal.Body className="px-0">
                        
                    <div className="profile-selected-assoc px-5 text-center text-lowercase">

                            { parseInt(selectedGroupDetail.type ?? 0)===1 && <div className="font-gotham-medium fs-22 mb-4 mt-n5 text-pink">restricted access</div> }
                            
                            <div className="profile-image" style={{ backgroundImage:  `url(${selectedGroupDetail.logo})`  }}></div>
                            <div className="profile-name mt-3">{selectedGroupDetail.name}</div>

                            <div className="my-3">{selectedGroupDetail.description}</div>

                            <div className="my-4">
                            {
                                parseInt(selectedGroupDetail.type ?? 0)===0 ?
                                    <button type="button" id="btn-submit-join" className="btn-theme-black mt-5 button-has-loader" onClick={ () => joinSubmission(1) }>join</button>
                                :
                                    <>
                                        <div className="py-3 text-blue mb-2 cursor-pointer" onClick={ () => setShowPasscodeEntry(showPasscodeEntry?false:true) }>join with request</div>
                                        {
                                            showPasscodeEntry ?
                                                <div className="mt-n3">
                                                    <input type="text" id="dummy" className="opacity-0" style={{ height :0 }} readOnly ></input>
                                                    <div className="codes-wrap mb-3">
                                                        <div className="codes-input">
                                                            <input type="text" id="code_1" name="code[]" onKeyUp={ (e) => codeFocus(e) } onClick={ (e) => codeReset(e) }  autoFocus  autoComplete="new-password" ></input>
                                                            <input type="text" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e) } onClick={ (e) => codeReset(e) } autoComplete="new-password"  ></input>
                                                            <input type="text" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e) } onClick={ (e) => codeReset(e) }  autoComplete="new-password" ></input>
                                                            <input type="text" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e,) } onClick={ (e) => codeReset(e) } autoComplete="new-password"  ></input>
                                                            <input type="text" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e,) } onClick={ (e) => codeReset(e) } autoComplete="new-password"  ></input>
                                                            <input type="text" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e, 'last') } onClick={ (e) => codeReset(e) } autoComplete="new-password"  ></input>
                                                        </div>
                                                    </div>
                                                    { showInvalidPasscode && <div className="text-pink">invalid passcode. please enter valid passcode</div> }
                                                </div>
                                            :
                                                <button type="button" className="btn-theme-black" onClick={ () => handleClickProvideReason() }>send request to join</button>
                                        }
                                        
                                    </>
                                  
                            }
                            </div>

                    </div>

                </Modal.Body>
                {
                    parseInt(selectedGroupDetail.type ?? 0)===1 &&
                    <div className={`join-content-reason p-5 text-lowercase form-theme ${showJoinReason?'opened':''}`}>
                        <div className="btn-close" onClick={ () => setShowJoinReason(false) }></div>
                            
                            <div className="font-gotham-medium fs-22 mb-4 mt-n4 text-center">reason</div>
                            
                            <div className="form-group">
                                <textarea name="joining_reason" className="form-control text-tranform-initial" rows="8" placeholder=" " value={ reasonContent }  onChange={ (e) => setReasonContent(e.target.value) }></textarea>
                                <label className="form-label">type here</label>
                            </div>

                            <button type="button" id="btn-submit-join-request" className="btn-theme-black mt-5 button-has-loader" onClick={ () => joinSubmission(2) }>send</button>
                    </div>
                }
                
                
            </Modal>


            <Modal  show={showChatConnect} onHide={handleCloseChatConnect} animation={false} aria-labelledby="contained-modal-title-vcenter" centered contentClassName="position-relative overflow-hidden ">
                <Modal.Header closeButton className="border-bottom-0 zindex-1"></Modal.Header>
                <Modal.Body className="px-0">

                    <div className="profile-selected-assoc px-5 text-center text-lowercase pb-4">

                        <div className="profile-image" style={{ backgroundImage:  `url(${selectedGroupDetail.logo})`  }}></div>
                        <div className="profile-name mt-3">{selectedGroupDetail.name}</div>
                
                        <div className="mt-3 mb-5">
                            {
                                parseInt(selectedGroupDetail.join_status)===1 ?
                                    <div>{selectedGroupDetail.description}</div>
                                :
                                    <div className="fs-20">do you want to remove invitation?</div>
                            }
                           
                        </div>

                        {
                                parseInt(selectedGroupDetail.join_status)===1 ?
                                    <button type="button" id="btn-chat-connect" className="btn-theme-black button-has-loader" onClick={ () => connectChatToGroup() }>connect</button>
                                :
                                    <div className="button-group-boolean">
                                        <button type="button" className="btn-theme-black" onClick={ () => handleCloseChatConnect() }>no</button>
                                        <button type="button" id="btn-chat-cancel-invitation" className=" btn-theme-black button-has-loader" onClick={ () => handleRemoveInvitation() }>yes</button>
                                    </div>
                        }
                    </div>

                </Modal.Body>

            </Modal>
            
       </div>
    )
}

export default CompanyGroups;