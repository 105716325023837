import React, {useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory  } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import * as RM from 'ramda';


import Utilities from '../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';

const HeaderInternal = () => {

    const history = useHistory();
    
    const internalConversationList = useSelector( state => state.passConversList);
    let passInternalProfile = useSelector( state => state.passInternalProfile);
    passInternalProfile = Utilities.workToCompany(passInternalProfile);

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [ associatedAccounts, setAssociatedAccounts ] = useState([]);

    const switchProfile = ( profile = {} ) => {
        if ( ! RM.isEmpty(profile) ){

            const companyId = parseInt(profile.company_id);
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);

            if ( ! RM.isEmpty(accountsList) ){
                accountsList = JSON.parse(accountsList);
                
                let work_account_id = 0;
                Object.entries(accountsList).map( ([key, row] ) => {
                    if ( row.hasOwnProperty('team_company_id') && parseInt(row.account_type || 0)===4 ){
                        if ( parseInt(row.team_company_id)===companyId ){
                            work_account_id = row.account_id;
                        }
                    }
                });

                history.push(`/${ UrlSlugs.internalChat }/${ btoa( parseInt(work_account_id) ) }`);
            }
        }

    }

    useEffect( async () => {
        
        if ( typeof internalConversationList!=='undefined' && Object.entries(internalConversationList).length>0 ){
            if ( internalConversationList.hasOwnProperty('internal_chat_list') ){
                let internal_chat_list = internalConversationList.internal_chat_list;

                if ( internal_chat_list.length>0 ){
                    if ( 'title_name' in internal_chat_list[0] ){
                        //const byName = RM.ascend(RM.prop('title_name')); // key senstive
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('title_name')));
                        internal_chat_list = RM.sort(byName, internal_chat_list);
                    }
                }

                setAssociatedAccounts(internal_chat_list);
            }
        }

        return () => {
            setAssociatedAccounts([]);
        }
        
    }, [internalConversationList]);
    

    return (
        <header className="main-header main-header-chat text-lowercase">
           <div className="main-header-content">

                <div className="main-logo"><div className="back-navigation" onClick={ () => history.push(`/${UrlSlugs.dashboard}`) } ></div></div>

                <div className="user-navigate chat-internal-header">

 
                            <div className="profile-selection-list">

                                <Dropdown className="dropdown-user-selection">
                                    <Dropdown.Toggle variant={`dropdown-user-current ${Object.keys(associatedAccounts).length===1 ? 'cursor-auto' : ''}`} id="dropdown-basic">
                                        <div className={`avatar-image fs-22-important ${ Utilities.classHideInitialBg(passInternalProfile.logo) }`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(passInternalProfile.logo) })`  }}>{ Utilities.showHideInitial(passInternalProfile.logo, passInternalProfile.name ?? '') }</div>
                                    </Dropdown.Toggle>  

                                    { 
                                        Object.keys(associatedAccounts).length>1 &&
                                        
                                            <Dropdown.Menu >
                                            {
                                                Object.entries(associatedAccounts).map( ([key, row] ) =>
                                                    <Dropdown.Item href="#" key={key} className={`${ parseInt(row.company_id)===parseInt(passInternalProfile.account_id) ? 'current-item' : '' }`} onClick={ () => switchProfile(row) }>
                                                        <div className="profile-item">
                                                            
                                                            <div className={`avatar-image-wrap`}>
                                                                <div className={`avatar-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor :  Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.title_name) }</div>
                                                            </div>

                                                            <div>
                                                                <div className="user-label">{row.title_name}</div>
                                                                <div className="user-name notranslate">{ row.category_name }</div>
                                                            </div>

                                                            { parseInt(row.chat.count)>0 && <span className="counter"><em>{ Utilities.counterMore(row.chat.count) }</em></span> }

                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            }
                                            </Dropdown.Menu >
                                        
                                    }

                                </Dropdown>

                            </div>

                            <div className="user-current-navigate">
                                <div className={ `user-name user-label` }>Internal Chat</div>
                                <div className={ `department-current user-label-4 ` }>{ passInternalProfile.hasOwnProperty('name') ? passInternalProfile.name : '' }</div>
                            </div>
                       
                    

                </div>

                <div className="logo-sub"></div>

           </div>

        </header>
    )
}

export default HeaderInternal;