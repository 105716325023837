import React, { useState, useEffect  } from 'react';
import jQuery from 'jquery';
import Utilities from '../../../helpers/Utilities';
import { getCompanyFollowers } from '../../../helpers/FollowersNotices';
import PagesAuthentication from '../../../helpers/PagesAuthentication';


const SidebarFollowers = (props) => {

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;

    //const [ userLoginDetail , setUserLoginDetail ] = useState({});
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ showProcessing , setShowProcessing ] = useState(true);

    const [ toggleAll, setToggleAll ] = useState(false);
    const [ accountIDs, setAccountIDs ] = useState([]);
    const [ accountIDsAll, setAccountIDsAll ] = useState([]);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleMemberAll = () => {

        if ( toggleAll===true ){
            setAccountIDs([]);
        }else{
            setAccountIDs([...accountIDsAll]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const toggleMemberInput = (e, row )  => {
        let accountIDsTemp = accountIDs;
        let id = row.account_id.toString();
        
        ( ! accountIDsTemp.includes(id) ) ?  accountIDsTemp.push(id) :  ( accountIDsTemp = [...Utilities.removeValueInArray(accountIDsTemp, id)] );
        accountIDsTemp = Utilities.arrayUnique(accountIDsTemp);

        setAccountIDs(accountIDsTemp);
        setToggleAll(accountIDsTemp.length===accountIDsAll.length ? true : false);
    } 

    const handleClickSubmitMembers = () => {
        props.handleSelectedMembers(accountIDs);
    }
    
    const getCompanyFollowerList = async ( profile = {} ) => {
        
        const passval = {
            api_token : API_TOKEN.toString(),
            account_id : profile.account_id.toString(),
            company_id : profile.company_detail.account_id.toString(),
            flag : '0'
        };

        setShowProcessing(true);
        const companyFollowersResponse = await getCompanyFollowers(passval);
        setShowProcessing(false);

        if ( Object.keys(companyFollowersResponse).length===0 ){
            Utilities.messagePopup('error', errorMsg );
            return false;
        }

        if ( parseInt(companyFollowersResponse.success)===1 ){
            const followerList = companyFollowersResponse.result.company_followers_list, gIDs = [];
            setListProfiles(followerList);

            Object.values(followerList).map( row => {
                gIDs.push(row.account_id.toString());
            });

            setAccountIDsAll(gIDs);

        }else{
            Utilities.messagePopup('error', companyFollowersResponse.errorMessage );
        }
    }

    useEffect( () => {
        
        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        //setUserLoginDetail(userLoginDetailTemp);
        getCompanyFollowerList(userLoginDetailTemp)

    }, [] );

    return (
        <div className="notication-sidebar-followers chat-sidebar form-theme checkbox-radio-default checkbox-radio-check-icon form-theme broadcast-popup-content">
            <div className="btn-close-chat-sidebar" onClick={ () => props.handleSelectedMembers() }></div>
            <div className="sidebar-title">send notification</div>
        
                <div className="sidebar-block long-content">
                    <div className="sidebar-block-content">

                        { 
                            showProcessing ?
                                <div className="content-loader animate"></div>
                            :
                                <>
                                {
                                    Object.keys(listProfiles).length===0 ?
                                        <div className="text-center text-lowercase no-record-label ">{ Utilities.labelProfileAvailable }</div>
                                    :
                                        <>
                                            <div className="form-group">
                                                <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                                                <label className="form-label">Search</label>
                                            </div>

                                            <div className="select-all-wrap">
                                                <div>select all</div>
                                                <div className="mr-n1"><input type="checkbox"  checked={ `${ toggleAll ? 'checked' : '' }` }  onChange={ (e) => toggleMemberAll() }   /></div>
                                            </div>
                                            
                                            <ul id="listing-profiles-member" className="list-members mt-2">
                                                {
                                                    Object.entries(listProfiles).map( ([key, row] ) =>
                                                        <li key={key} data-keyword={row.name.toLowerCase() } >
                                                            <div><div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                                            <div>
                                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                                <div className="profile-label">{row.title}</div>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox"  checked={ `${  ( accountIDs.includes(row.account_id.toString()) ) ? 'checked' : ''  }` }  onChange={ (e) => toggleMemberInput(e, row ) } />
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                            <div className="team-selected-counter mt-3 text-center"> { accountIDs.length } Selected</div>

                                            {
                                                parseInt(accountIDs.length)>0 &&
                                                    <div className="py-5">
                                                        <button type="button" id="btn-notice-send" className="btn-theme-black button-has-loader" onClick={ () => handleClickSubmitMembers() }>send</button>
                                                    </div>
                                            }
                                        
                                        </>
                                }
                                </>
                        }

                    </div>
                </div>

       </div>
    )
}

export default SidebarFollowers;