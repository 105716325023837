import axios from 'axios'; 
import ApiUrls from './ApiUrls';

class QueryChatGroupUtilities {

    checkGroupNameExistance = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatCheckGroupNameExist , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    createGroup = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatCreateGroup , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    updateGroup = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatUpdateGroupDetails , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }
    

    leaveGroup = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatLeaveGroup , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }
    
    getDetailGroup = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatGroupDetail , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    markAdminGroup = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatUpdateGroupMemberAdminStatus , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }
    
    
    deleteDetailGroup = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatDeleteGroupDetails , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    bizchatDeleteGroupConversation = async (passValues = {} ) => {
        let responseDetail = {};

        if ( Object.entries(passValues).length>0 ){
            try {
                const serverResponse = await axios.post( ApiUrls.bizchatDeleteGroupConversation , passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }
    

    uploadGroupLogo = async (passValues = {} ) => {
        let responseDetail = {};
        try {
            const serverResponse = await axios.post( ApiUrls.bizchatUploadGroupLogo , passValues);
            responseDetail = serverResponse.data;

        } catch (e) {
            responseDetail = {};
        }
        return responseDetail;
    }


    
}

export default new QueryChatGroupUtilities();

