import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Utilities from '../../helpers/Utilities';

const GoogleLanguage = (props) => {

    const showLanguageSelectionPopup = useSelector( state => state.showLanguageSelectionPopup);

    const defaultLanguange = process.env.REACT_APP_default_languge;

    const [ availableLanguages, setAvailableLanguages ] = useState([]);
    const [ activeLanguage , setActiveLanguage] = useState('en');
    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => setShowPopup(false);
    const handleShowPopup = () => setShowPopup(true);
    
    const changeSiteLanguage = (lang) => {
      handleClosePopup();

      const selectField = document.querySelector("#google_translate_element select");

      for(let i=0; i < selectField.children.length; i++){
        let option = selectField.children[i];
        // find desired langauge and change the former language of the hidden selection-field 
        if(option.value===lang){
          selectField.selectedIndex = i;
          // trigger change event afterwards to make google-lib translate this side
          selectField.dispatchEvent(new Event('change'));
          break;
        }
      }

      setActiveLanguage(lang);
      sessionStorage.setItem(process.env.REACT_APP_session_googlelang, lang);

    };

    useEffect( () => {
        
        const googleLang = sessionStorage.getItem(process.env.REACT_APP_session_googlelang);
        if ( googleLang!==null ){
            setActiveLanguage(googleLang);
        }else{
            setActiveLanguage(defaultLanguange);
        }

        if ( ! Utilities.isEmpty(showLanguageSelectionPopup) ){
           handleShowPopup();
        }

    }, [showLanguageSelectionPopup]);

    useEffect( () => {
     setAvailableLanguages(props.availableLanguages);
     
    }, [props.availableLanguages]);

    return (
        <Modal id="chat-map-popup"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md" >
              <Modal.Header className="border-bottom-0">
                  <h5 className="text-uppercase text-center w-100 my-2" >
                     <img src={ `${process.env.REACT_APP_img_folder}/icons/language-circle-bg-black.svg` } width="70" />
                  </h5>
              </Modal.Header>
              <Modal.Body className="px-4">
                    
                <ul className="google-language-list notranslate">
                  {
                    Object.entries(availableLanguages).map( ([lang, row]) =>
                        <li key={lang} className={`${ ( activeLanguage===lang) ? 'active' : '' }`} onClick={ () => changeSiteLanguage(lang) }>{ Utilities.googleTranslateLanguages[lang] }</li>
                    )
                  }

                </ul>

              </Modal.Body>
            
          </Modal>
    )
}

export default GoogleLanguage;
