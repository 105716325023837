import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from "jquery";
import { confirmAlert } from 'react-confirm-alert'; 
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';

import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import { sendVerificationCode } from '../helpers/SendingEmails';

import PagesAuthentication from '../helpers/PagesAuthentication';
import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import { checkEmailExistance, checkMobileNumberExistance, updateEmailMobileCredentail } from '../helpers/VerificationUtilities';
import SignupHeader from '../layout/SignupHeader';


const AccountVerification = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;
    
    const defaultLocation = Utilities.defaultLocation();
    //const msgSendError = TitlesLabels.alertMessages.couldNptSendEmailTryAgain;
    const notificationValuesDefault = {  fcm_token : '', utc_date : '' , brower_id : '' };
    const verificationTypeOption = { 1 : 'email', 2 : 'mobile number' };

    let defaultFormValues = {
        email_address : '',
        phonecode_country_id : '',
        mobile_number : '',
    } 


    const [ defaultFlag, setDefaultFlag ] = useState(defaultLocation.country_iso);
    const [ defaultCountryCode, setDefaultCountryCode ] = useState(defaultLocation.country_ext);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    
    const [ formValues, setFormValues] = useState(defaultFormValues);
    const [ personalInfo, setPersonalInfo] = useState({});
    const [ changeVerification, setChangeVerification ] = useState(false);
    const [ verificationType, setVerificationType ] = useState(1);

    const [ emailAddress, setEmailAddress ] = useState('');
    const [ passwordUser, setPasswordUser ] = useState('');
    const [ accountID, setAccountID ] = useState(0);
    const [ countDown, setCountDown ] = useState('00:00');
    
    const [ timerInterval , setTimerInterval ] = useState('');
    const [ notificationValues, setNotificationValues ] = useState(notificationValuesDefault);

    const [ countryList, setCountryList] = useState([]);
    const [ showMobileMsg, setShowMobileMsg] = useState(false);
    const [ showEmailMsg, setShowEmailMsg] = useState(false);

    let globalRecordTimer = '';

    const messagePinPopup = ( title = '', message = '' ) => {

        confirmAlert({
            title: title,
            message: message,
            overlayClassName : title.toLowerCase(),
            buttons: [
                { 
                    label: 'Close', 
                    onClick: () => {
                        jQuery('.codes-input input').val('');
                        document.getElementById('code_1').focus();
                    }
                }
            ]
        }); 
    }

    const timerCountDown = ( action = 1 ) => {
        
        let countDownTemp = '00:00'; //countDown;
        if ( action===0 ){
            stopTimer();
        }

        let timer_split = countDownTemp.split(":");
        let min_split = parseInt(timer_split[0]);
        let sec_split = parseInt(timer_split[1]);
       
        globalRecordTimer = setInterval(function() {

                        sec_split++;
                        sec_split = parseInt( (sec_split===60) ? 0 : sec_split );

                        let sec_str = sec_split.toString();
                        if (sec_str.length===1){ sec_str = '0'+sec_str; }
                        
                        if (sec_split===0){ min_split++; }
                        
                        let min_str = min_split.toString();
                        if (min_str.length===1){ min_str = '0'+min_str; }

                        let timer_init = min_str+':'+sec_str;
                        setCountDown(timer_init);
                        
                        if (timer_init==='05:00'){
                            stopTimer();
                            clearInterval(globalRecordTimer);
                            Utilities.messagePopup('error', TitlesLabels.alertMessages.titlesUpSendOtherCode );
                        }

                        if ( action===0 ){
                            clearInterval(timerInterval);
                            clearInterval(globalRecordTimer);
                        }

                }, 1000 ); 
                
        if ( action===0 ){
            clearInterval(timerInterval);
            clearInterval(globalRecordTimer);
        }else{
            setTimerInterval(globalRecordTimer);
        }
    }

    const codeFocus = ( e, type = '' ) => {
        let value = e.target.value;
        if ( !Utilities.isEmpty( value ) ){
            jQuery(e.target).next('input').val('').focus();
        }
        if ( type==='last' ){
            jQuery('input#dummy').focus();
            verifyCodes();
        }
    }

    const codeBack = (e) => {
        const key = parseInt(e.keyCode || e.charCode);
        
        if( key===8 ){
            jQuery(e.target).prev('input').val('').focus();
        }
    }

    const codeReset = ( e) => {
        jQuery(e.target).val('');
    }
    
    const stopTimer = () => {
        setCountDown('00:00'); 
        clearInterval(timerInterval);
        clearInterval(globalRecordTimer);
    }

    const resendPincode = async() => {

        stopTimer();
        timerCountDown(0);


        jQuery('.codes-input input').val('');
        document.getElementById('code_1').focus();

        const pincode = Utilities.generateSignupCode();
        const request_id = accountID;

        dispatch( showLoader(0) );

        if ( request_id>0 ){

            const passvalVerification = {
                api_token: TOKEN.toString(),
                flag: "1",
                account_id : request_id.toString(),
                name : personalInfo.first_name+' '+personalInfo.last_name,
                email : personalInfo.email,
                phone_number : '+'+personalInfo.mobile_phonecode.toString()+personalInfo.mobile_number.toString(),
                code : pincode.toUpperCase()
            };

            dispatch( showLoader(1) );
            const responSendingVeification = await sendVerificationCode( passvalVerification );
            dispatch( showLoader(0) );

            if ( Object.keys(responSendingVeification).length===0 ){
                Utilities.messagePopup('error', TitlesLabels.alertMessages.verificationSentCheckYourInbox ); //msgSendError
                return false;
            }

            if ( parseInt(responSendingVeification.success)===1 ){
                Utilities.messagePopup('success', TitlesLabels.alertMessages.verificationSentCheckYourInbox );
                timerCountDown();
            }else{
                Utilities.messagePopup('Error', responSendingVeification.errorMessage );
            }

          
        }
    }

    const sendJazenetMessage = ( profile ) => {
        dispatch( showLoader(1) );
        
        axios
        .post( ApiUrls.bizchatCreateBizcomConversation , { api_token: TOKEN.toString() ,  account_id : profile.account_id.toString() } )
        .then( response => {
            dispatch( showLoader(0) );
            history.push(`/${UrlSlugs.dashboard}`);

        })
        .catch( (err) => {
            dispatch( showLoader(0) );
            history.push(`/${UrlSlugs.dashboard}`);
        });
    }
    

    const loginUser = () => {

        if ( !Utilities.isEmpty(emailAddress) && !Utilities.isEmpty(passwordUser) ){
            dispatch( showLoader(1) );

            const windowNavigator = window.navigator;

            axios
            .post( ApiUrls.userGetByCredentail, { ...notificationValues, email: emailAddress,  password : passwordUser, browser_name: windowNavigator.appVersion.toLowerCase() }  )
            .then( response => {

                dispatch( showLoader(0) );
                const resStatus = response.status;
            
                if ( resStatus===200 ){
                    const resData = response.data;
                    const resLoginType = parseInt(resData.logintype);
                    
                    if ( resData.status===1){

                        if ( parseInt(resData.detail.status)===1 ){

                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );

                            localStorage.setItem( process.env.REACT_APP_session_welcome_mesage, 1 );

                            sendJazenetMessage( resData.detail );

                        }else{
                            Utilities.messagePopup('error', Utilities.labelAccountNotActive  );
                        }
                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                } 
               
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                dispatch( showLoader(0) );
            });
        }
    }

    const verifyCodes = () => {

        const request_id = accountID;
        let input_codes = '';
        jQuery('.codes-input input').each(function (){
            input_codes += jQuery(this).val();
        });
        
        if ( !Utilities.isEmpty(input_codes) ){

            dispatch( showLoader(1) );

            axios
            .post( ApiUrls.userVerifyCode , { id : request_id, pincode : input_codes }  )
            .then( response => {
                const msgSendError = TitlesLabels.alertMessages.incorrectCodeCheckInbox;

                dispatch( showLoader(0) );

                if ( response.status===200 ){

                    const returnStatus = parseInt(response.data);
                    if ( returnStatus>0 ){


                        const socialSignupConnect = PagesAuthentication.getSignupSocialDetail();
      
                        if ( Object.keys(socialSignupConnect).length>0 ){
                            if ( parseInt(socialSignupConnect.type ?? 0)>0 ){

                                stopTimer();
                                loginUser();
                                return false;
                            }
                        }

                        confirmAvatarCreation( '', TitlesLabels.confirmMessages.wishToAddProfileImage );

                    }else{
                        messagePinPopup( 'error', msgSendError );
                    }
                    
                }else{
                    messagePinPopup( 'error', msgSendError );
                }

            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                dispatch( showLoader(0) );
            });
        }
    }

    const confirmAvatarCreation = ( title = '', message = '' ) => {
        stopTimer();

        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {  label: 'No',  onClick: () => {  loginUser()  } },
                { 
                    label: 'Yes',
                    onClick: () => { 
                        stopTimer();
                        history.push( UrlSlugs.uploadLogo );
                    }
                }
            ]
        }); 
    }

    const updateNotificationValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = notificationValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString(); 
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);
        
        if ( Utilities.isEmpty(fcmToken) ){
            const fcmTokenTemp = localStorage.getItem( process.env.REACT_APP_fs_fcm_token );
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        }else{
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {...formValuesTemp,
                            ...{ 
                                fcm_token : fcmToken.toString(),
                                utc_date : dateFormatted.toString(),
                                brower_id : browserUniqueID.toString(),
                            }
                        };         
        setNotificationValues(formValuesTemp);
        
    }

    const handleOnClickChange = ( actionName = '' ) => {

        if ( actionName==='change' ){
            setChangeVerification(true);
            
        }else if ( actionName==='back' ){
            setChangeVerification(false);
        }
        
        // reset fields
        setVerificationType(1);
        setDefaultFlag(defaultLocation.country_iso);
        setDefaultCountryCode(defaultLocation.country_ext);
        setDefaultSearchValue('');
        setFormValues(defaultFormValues);

    }
    
    const handleClickResentVerification = async () => {
        setChangeVerification(false);
        
        if ( verificationType===1 ){

            // Checking email address if exist in database
            dispatch( showLoader(1) );
            const responseEmailCheckingDB = await checkEmailExistance( formValues.email_address.toString() );
            dispatch( showLoader(0) );

            if ( Object.keys(responseEmailCheckingDB).length>0 ){
                if ( parseInt(responseEmailCheckingDB.exist)===1 ){
                    Utilities.messagePopup('Error', Utilities.labelEmailAddressAlreadyExist );
                    return false;
                }
            }

           
            // Update Email adress
            dispatch( showLoader(1) );
            const responseEmailUpdateDB = await updateEmailMobileCredentail({
                account_id : personalInfo.account_id.toString(),
                email_address : formValues.email_address.toString(),
                type : 1
            });
            dispatch( showLoader(0) );
            
            if ( Object.entries(responseEmailUpdateDB).length===0 ){
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest );
                return false;
            }

            if ( parseInt(responseEmailUpdateDB.status)===0 ){
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest );
                return false;
            }

            setEmailAddress( formValues.email_address.toString() );

            const personalInfoTemp = {...personalInfo, ...{ email : formValues.email_address.toString() } };
            localStorage.setItem(
                process.env.REACT_APP_session_signup_personalinfo,
                JSON.stringify(personalInfoTemp)
            );

            setPersonalInfo(personalInfoTemp);

        }else if ( verificationType===2 ){

            // Checking mobile number if exist in database
            dispatch( showLoader(1) );
            const responseMobileCheckingDB = await checkMobileNumberExistance( formValues.phonecode_country_id, formValues.mobile_number );
            dispatch( showLoader(0) );

            if ( Object.keys(responseMobileCheckingDB).length>0 ){
                if ( parseInt(responseMobileCheckingDB.exist)===1 ){
                    Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist );
                    return false;
                }
            }

            // Update mobile number
            dispatch( showLoader(1) );
            const responseMobileUpdateDB = await updateEmailMobileCredentail({
                account_id : personalInfo.account_id.toString(),
                phonecode_country_id : formValues.phonecode_country_id.toString(),
                mobile_number : formValues.mobile_number.toString(),
                type : 2
            });
            dispatch( showLoader(0) );

            if ( Object.entries(responseMobileUpdateDB).length===0 ){
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest );
                return false;
            }

            if ( parseInt(responseMobileUpdateDB.status)===0 ){
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest );
                return false;
            }

            const personalInfoTemp = {
                                        ...personalInfo,
                                        ...{ country_code : formValues.phonecode_country_id.toString(), mobile_number : formValues.mobile_number.toString() }
                                    };
            localStorage.setItem(
                process.env.REACT_APP_session_signup_personalinfo,
                JSON.stringify(personalInfoTemp)
            );

            setPersonalInfo(personalInfoTemp);
        }


        resendPincode();
    }

    const focusDropdown = ( input_id) => {
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( !Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }


    const changeCountryCode = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlag(defaultFlagTemp);
        setDefaultCountryCode(passValues.phonecode);

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ phonecode_country_id : passValues.id } };
        setFormValues(formValuesTemp);
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '' ) => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if ( fieldName==='email_address'){

            setShowEmailMsg('');
            if (  ! Utilities.isEmpty(value) && !Utilities.isEmail(value) ){
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                setShowEmailMsg(TitlesLabels.general.emailAddressInvalid);
                errorCounter++;
            }

            formValuesTemp = { ...formValuesTemp, ...{ email_address : value, mobile_number : '' } };

        }else if ( fieldName==='mobile_number'){

            let mobile_number = value.replace(' ','');
            setShowMobileMsg('');
            if ( ! Utilities.isEmpty(mobile_number) && ! Utilities.contactNumberValidate( mobile_number, 1 ).status ){
                setShowMobileMsg( Utilities.contactNumberValidate( mobile_number, 1 ).msg);
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }

            formValuesTemp = { ...formValuesTemp, ...{ mobile_number : value, email_address : '' } };
        }
        
        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);
    }

    const verificationOptionHtml = () => {

        if ( verificationType===1 ){
            return <>
                    <div className="fs-16 pb-2">please enter new email address</div>
                    <div className="form-group">
                        <input type="email" id="input-email" name="email_address" className="form-control" placeholder=" " autoComplete="new-password" value={ formValues.email_address }  onChange={ (e) => pushValue( e, 'email_address') }  ></input>
                        <label className="form-label">Email</label>
                    </div>
                    
                    {  ( ! Utilities.isEmpty(showEmailMsg) && ! Utilities.isEmpty(formValues.email_address) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showEmailMsg }</div> }
                </>

        }else if ( verificationType===2 ){
                return <>
                    <div className="fs-16 pb-2">please enter new mobile number</div>
                    <div className="form-group active">
                            <div className="form-group-mobile">
                                <Dropdown className="dropdown-country-selection hide-arrow">
                                    <Dropdown.Toggle>
                                        <div className="flag-code" onClick={ () => focusDropdown('landline-input') }>
                                            <span className={`flag-icon flag-icon-${defaultFlag} mr-2`}></span>
                                            <span>+{defaultCountryCode}</span>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu >
                                    <div className="px-4 mb-1">
                                        <input type="text" id="landline-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'landline-input-list', 'search-keyword') }></input>
                                    </div>
                                    <div id="landline-input-list" className="country-code-list">
                                    {
                                        Object.entries(countryList).map( ([key, row] ) =>
                                            <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCode(row) } >
                                                <div className="flag-code">
                                                    <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                    <span>{ row.country_name }</span>
                                                    <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                </div>
                                            </Dropdown.Item>
                                        )
                                    }
                                    </div>   
                                    </Dropdown.Menu>
                                </Dropdown>
                            
                                <input type="text" name="mobile_number" className="form-control" placeholder=" " value={ formValues.mobile_number }  onChange={ (e) => pushValue( e, 'mobile_number') } autoComplete="off"  ></input>
                            </div>
                            <label className="form-label">Mobile Number</label>
                        </div>

                        {  ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(formValues.mobile_number) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }
                </>
        }
    }

    const getCountries = async () => {
        await axios
                .get( ApiUrls.country , {} )
                .then( response => {
                    if ( response.status===200 ){
                        setCountryList(response.data);
                    }
                })
                .catch( (err) => {
                
                });
    }

    const formattedEmailMobileText = ( type = 1 ) => {

        let mobileNumberHidden = [], emailHidden = [];

        let mobileNumber = personalInfo.hasOwnProperty('mobile_number') ? personalInfo.mobile_number : '';
        let emailAddress = personalInfo.hasOwnProperty('email') ? personalInfo.email : '';

        if ( type===1 && ! Utilities.isEmpty(mobileNumber) ){
            [...Array(mobileNumber.length).keys()].map( num => {
                mobileNumberHidden.push( parseInt(num) < (parseInt(mobileNumber.length)-4) ? 'x' : num );
            });

            return mobileNumberHidden.join('');

        }else if ( type===2 && ! Utilities.isEmpty(emailAddress) ){

            [...Array(emailAddress.split('@')[0].length-1).keys()].map( num => {
                emailHidden.push('x');
            });

            return emailAddress.split('')[0]+emailHidden.join('')+'@'+emailAddress.split('@')[1];
        }

    }

    useEffect ( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.digitVerification ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        PagesAuthentication.checkLoginStatus();

        updateNotificationValues();

        stopTimer();
        timerCountDown(0);
        timerCountDown();

        getCountries();

        let signupPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_signup_personalinfo);
        if ( signupPersonalInfo!==null ){
            signupPersonalInfo = JSON.parse(signupPersonalInfo);
            if ( Object.keys(signupPersonalInfo).length>0 ){
                setEmailAddress(signupPersonalInfo.email);
                setAccountID(signupPersonalInfo.account_id);
                setPasswordUser(signupPersonalInfo.password);

                setPersonalInfo(signupPersonalInfo);
            }
        }
        
        return () => {
            stopTimer();
        }
        
      },[]); 

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title={ changeVerification===false ? 'Account Verification' : 'change verification details' } />    
                {/* <header className="header mb-5 text-center">
                    <div className="logo-wrap">
                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                        <div className="logo-type-text mt-2">{ changeVerification===false ? 'Account Verification' : 'change verification details' }</div>
                    </div>
                </header> */}

             <div className="col-10 mx-auto pt-1">

             
                {
                    changeVerification===false ?
                    <>
                        <div className="text-center fs-18">
                            <div className="mb-4">
                                Please enter 4-digit verification code<br />
                                sent your mobile number +{ personalInfo.hasOwnProperty('mobile_phonecode') && personalInfo.mobile_phonecode }{ formattedEmailMobileText(1) }<br />
                                and to your email address { formattedEmailMobileText(2) }
                            </div>
                        </div>
                        

                        <div className="login-form col-10 pt-3 pb-4 mx-auto  text-lowercase form-theme">
                            
                            <input type="text" id="dummy" className="opacity-0" readOnly ></input>

                            <div className="codes-wrap">
                                <div className="codes-input">
                                    <input type="password" id="code_1" name="code[]" onKeyUp={ (e) => codeFocus(e) } onClick={ (e) => codeReset(e) }  autoFocus  autoComplete="new-password" ></input>
                                    <input type="password" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e) } onClick={ (e) => codeReset(e) } autoComplete="new-password"  ></input>
                                    <input type="password" name="code[]" onKeyDown={ (e) => codeBack(e) }  onKeyUp={ (e) => codeFocus(e) } onClick={ (e) => codeReset(e) }  autoComplete="new-password" ></input>
                                    <input type="password" name="code[]" onKeyDown={ (e) => codeBack(e) } onKeyUp={ (e) => codeFocus(e, 'last') } onClick={ (e) => codeReset(e) } autoComplete="new-password"  ></input>
                                </div>
                            </div>


                            <div className="timer-content mt-4">
                                <div id="timer" className="timer-wrap">{ countDown }</div>
                            </div>
                        </div>


                        <div className="text-center text-size-semi-medium mt-3">
                            <div className="mb-4">Didn't received the code?</div>
                            <div><a href="#" className="text-color-pink" onClick={ () => resendPincode() }>send code again</a></div>
                            <div><a href="#" className="text-color-pink" onClick={ () => handleOnClickChange('change') }>change phone number/ email</a></div>
                        </div>
                    </>
                    :
                    <>
                        <div className="login-form col-10 col-sm-5 pb-4 mx-auto  text-lowercase form-theme">
                            <div>choose one</div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                {
                                    Object.entries(verificationTypeOption).map( ([key, label ]) => 
                                        <div key={key} className="text-size-semi-medium pb-5 checkbox-radio-default checkbox-radio-check-icon d-flex font-gotham-book">
                                            <input type="radio" name="terms"  checked={ parseInt(key)===verificationType?true:false } onChange={ () => setVerificationType(parseInt(key)) } ></input>
                                            <span className="ml-2">{ label }</span>
                                        </div>
                                    )
                                }
                            </div>
                            
    
                            { verificationOptionHtml() }

                            <div className="pt-5">
                                <button type="submit" id="btn-submit-change-verification" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => handleClickResentVerification() }>save</button>
                                <div className="pt-3 text-center">
                                    <span className="text-blue cursor-pointer" onClick={ () => handleOnClickChange('back') }>back</span>
                                </div>
                            </div>
                            
                        </div>
                    </>
                }
                 


             </div>
             
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_persoanlInfo}`} onClick={ () => stopTimer() }>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
    </div>
    )
}

export default AccountVerification;