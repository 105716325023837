import React, { useState, useEffect, Fragment  } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; 
import moment from 'moment';

import Utilities from '../helpers/Utilities';
import QueryUtilities from '../helpers/QueryUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../actions';

const LinkProfilesAssign = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const API_BIZCOMPLUS_TOKEN = process.env.REACT_APP_chat_api_token_bizcom;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const [ listProfiles, setListProfiles ] = useState([]);
    const [ profileDetail , setProfileDetail ] = useState({});
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');
    const [ memberIDs , setMemberIDs ] = useState([]);

    const [ cancelRedirect , setCancelRedirect ] = useState( '/'+ UrlSlugs.dashboard ); // '/settings/linked-profiles'

    const [ buttonLabel , setButtonLabel ] = useState('assign');

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const toggleActiveClass = (e, member )  => {
        let id = member.account_id.toString();
        let memberIDsTemp = [];
        
        if ( memberIDs.includes(id) ){
            memberIDsTemp = [];
        }else{
            memberIDsTemp.push(id);
        }
        setMemberIDs(memberIDsTemp);
    }

    const getMembers = ( companyID, account_id, exclude_ids ) => {
        companyID = parseInt(companyID);
        
        if ( companyID>0 ){
   
            axios
            .post( ApiUrls.linkProfilesTeamList , { company_account_id : companyID, account_id : account_id, exclude_ids : exclude_ids  } )
            .then( response => {
                
                if ( response.status===200 ){
                    const dataResponse = response.data;
                    const dataStatus = parseInt(dataResponse.status);

                    if ( dataStatus===1 ){
                        if ( ! ( dataResponse.hasOwnProperty('list') ) ){
                            setShowEmpty(true);

                        }else{
                            setShowEmpty(false);
                            
                            let memberIDsTemp = memberIDs;
                            let memberList = [];

                            Object.entries(dataResponse.assigned).map( ( [id, memberdetail] ) => {
                                memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];
                                memberIDsTemp.push(memberdetail.account_id.toString());
                            });

                            Object.entries(dataResponse.list).map( ( [id, memberdetail] ) => {
                                memberList = [ ...memberList, Utilities.formatProfileDetail(memberdetail) ];
                            });

                            setListProfiles(memberList);

                            memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
                            setMemberIDs(memberIDsTemp);
                        }
                    }else{
                        setShowEmpty(true);
                    }
                }

                setContentLoaderClass('');
            })
            .catch( (err) => {
                setListProfiles([]);
            }); 
        }

    }

    const setupDetail = ( reqID ) => {
        reqID = parseInt(reqID);

        axios
        .post( ApiUrls.teamDetail , { id : reqID } )
        .then( response => {
            if ( response.status===200 ){
                const dataResponse = response.data;
                if ( parseInt(dataResponse.status)===1 ){

                    const dataDetail = dataResponse.detail;
                    setProfileDetail(dataDetail);
                    getMembers(dataDetail.team_company_id, reqID, reqID );
                }
            }
        })
        .catch( (err) => {
            
        }); 
    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }
    
    const submitTeam = async () => {
        const account_id = parseInt(profileDetail.account_id);
        const button_name = 'btn-submit-team';

        if ( account_id>0 ){
            
            const segment = Utilities.getUrlSegments();
            Utilities.elementDisabledStatus(button_name, true );

            if ( segment[1]==='divert-profiles'){
                
                const passValues = {
                    api_token: API_BIZCOMPLUS_TOKEN.toString(),
                    account_id: account_id.toString(),
                    flag: memberIDs.length>0 ? '1' : '0',
                    team_accounts: memberIDs.join(',').toString()
                }

                const assignResponse = await QueryUtilities.profileAssignTeam(passValues);
                if ( Object.entries(assignResponse).length>0 ){

                    if ( parseInt(assignResponse.success)===1 ){

                        let messageText = 'activated';
                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if ( divertReplyInfo!==null ){
                            divertReplyInfo = JSON.parse(divertReplyInfo);
        
                            if ( divertReplyInfo.hasOwnProperty('divert_status') && parseInt(divertReplyInfo.divert_status || 0)===1 && parseInt(passValues.flag || 0)===0 ){
                                messageText = 'deactivated';
                            }
                        }

                        confirmAlert({
                            title: 'Success',
                            message: `divert successfully ${messageText}`,
                            overlayClassName : 'success',
                            buttons: [
                                { label: 'Close',  onClick: () => { history.push(`/${UrlSlugs.dashboard}`); } }
                            ]
                        }); 

                    }else{
                        Utilities.messagePopup('Error', errorMsg);
                    }
                }

                Utilities.elementDisabledStatus(button_name, false );

            }else{

                const utcTime = Utilities.currentUTCTime();
                const curretTime =  moment.utc(utcTime).format(Utilities.dateTimeServerStr);

                const dataValues = { account_id : account_id,  assigned_ids : memberIDs.join(',').toString(), create_date : curretTime  };

                axios
                .post( ApiUrls.linkProfilesTeamAssign ,  dataValues )
                .then( response => {
                    
                    Utilities.elementDisabledStatus(button_name, false );

                    if ( response.status===200 ){
                        const dataResponse = response.data;
                        if ( parseInt(dataResponse.status)===1 ){
                            history.push(`/${UrlSlugs.dashboard}`);

                        }else{
                            Utilities.messagePopup('Error', errorMsg);
                        }

                    }else{
                        Utilities.messagePopup('Error', errorMsg);
                    }
                })
                .catch( (err) => {
                    Utilities.elementDisabledStatus(button_name, false );
                    Utilities.messagePopup('Error', errorMsg);
                }); 
                
            }
            
        }

    }

    useEffect( () => {

        const segment = Utilities.getUrlSegments();

        let page_title = TitlesLabels.siteTitles.linkedChats;
        let urlCancelRedirect = '/' + UrlSlugs.settings_linkedProfiles;
        let buttonLabelTemp = 'assign';

        if ( segment[1]==='divert-profiles'){
            page_title = TitlesLabels.siteTitles.divertChats;
            urlCancelRedirect = '/' + UrlSlugs.dashboard;
            buttonLabelTemp = 'submit';
        }

        setButtonLabel(buttonLabelTemp);
        //setCancelRedirect(urlCancelRedirect);
        
        dispatch( pageTitle(`${ TitlesLabels.settings } | ${page_title} | ${ TitlesLabels.siteTitles.assign }`) );

        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        PagesAuthentication.getUserLoggedin();

        let reqAccountID = match.params.id;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            
            if ( parseInt(reqAccountID)>0 ){
               setupDetail( reqAccountID );
            }
        }


    }, [] );

    return (
        <div className="team-members-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                     <li>
                            <div>{ TitlesLabels.settings }</div>
                            {
                                buttonLabel==='assign' ?
                                <Fragment>
                                    <Link to={`/${ UrlSlugs.settings_linkedProfiles}`}>{ TitlesLabels.navigations.linkedChats }</Link>
                                    <div className="current">assign</div>
                                </Fragment>
                                :
                                <div className="current">{ TitlesLabels.navigations.divert }</div>
                            }
                           
                          </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            <div className="btn-icon btn-magnify"  onClick={ () => toggleSeachBox() } ></div>
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() }></div>

                         </li>
                     </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container content-loader">

                        <div className="w-100">
                            <div className="profile-search-wrap d-none">
                                <div className="profile-search-content">
                                    <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search"></input></div>
                                    <div></div>
                                    <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                                    <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => toggleSeachBox() }></span></div>
                                </div>
                            </div>

                            { 
                                ! Utilities.isEmpty(contentLoaderClass) &&
                                    <ul className="listing-profiles-preloader mt-4">
                                    {
                                        [...Array(12).keys()].map( ( key ) =>
                                            <li key={key}>
                                                <div className="profile-image"></div>
                                                <div></div>
                                                <div></div>
                                            </li>
                                        )
                                    }
                                    </ul>
                            }


                            <ul id="listing-profiles" className="listing-profiles mt-4 notranslate">
                                {
                                    Object.entries(listProfiles).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.name} className={ `${ ( memberIDs.includes(row.id.toString())  ? 'selected' : '' ) }` } onClick={ (e) => toggleActiveClass(e, row ) } >
                                            <div className="profile-wrap">
                                                <div className="profile-image-wrap">
                                                    <div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div>
                                                </div>
                                                <div className="profile-name">{row.name } </div>
                                                <div className="profile-label">{row.team_position }</div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>


                            <div className={`text-center text-lowercase no-record-label ${ !showEmpty && 'd-none' }`} >{ TitlesLabels.general.noTeamAdded } </div> 

                            <div className={`col-8 mx-auto  pt-5 my-5 ${ Utilities.isEmpty(contentLoaderClass) ? '' : 'd-none' } `}>
                                
                                { 
                                    ! showEmpty ?
                                        <div className="button-group-boolean mt-5">
                                            <Link to={cancelRedirect} className="btn-theme-black text-lowercase" >Cancel</Link>
                                            <button type="button" id="btn-submit-team" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => submitTeam() }>{buttonLabel}</button>
                                        </div>
                                    :
                                        <div className="mt-5">
                                            <Link to={cancelRedirect} className="btn-theme-black text-lowercase" >Cancel</Link>
                                        </div>
                                }
                               
                            </div>

                        </div>

                </div>
            </div>
       </div>
    )
}

export default LinkProfilesAssign;