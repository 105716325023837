import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios';

import { passForwardConversId } from '../actions';

import Utilities from '../helpers/Utilities';
import SessionUtilities from '../helpers/SessionUtilities';
import ApiUrls from '../helpers/ApiUrls';


const ChatForward = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const BIZCOM_TOKEN = process.env.REACT_APP_chat_api_token_bizcom;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const passConversID = useSelector( state => state.passConversID);

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ toggleAll, setToggleAll ] = useState(false);
    const [ isInternal, setIsInternal ] = useState(false);

    const [ conversIDs, setConversIDs ] = useState([]);
    const [ conversIDAll, setConversIDAll ] = useState([]);
    const [ conversAcctIDs, setConversAcctIDs ] = useState([]);
    const [ conversForward , setConversForward ] = useState([]);

    const defaultValues = { title : '' };
    const [ formValues, setFormValues ] = useState(defaultValues);


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else if ( fieldName==='title' ){

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleMemberInput = (e, row )  => {
        let conversIDsTemp = conversIDs;
        let id = row.convers_id.toString();
        
        ( ! conversIDsTemp.includes(id) ) ?  conversIDsTemp.push(id) :  ( conversIDsTemp = [...Utilities.removeValueInArray(conversIDsTemp, id)] );
        conversIDsTemp = Utilities.arrayUnique(conversIDsTemp);

        setConversIDs(conversIDsTemp);
        setToggleAll(false);
    } 

    const toggleMemberAll = () => {

        if ( toggleAll===true ){
          setConversIDs([]);
        }else{
          setConversIDs([...conversIDAll]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const getRecentConversation = ( account ) => {
        
        let master_account_id = account.account_id;
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);
            accountsList = Object.values(accountsList);
            master_account_id = accountsList[0].account_id;
        }
        
        let flag = '1';
        let account_department_id = '0';
        if ( account.hasOwnProperty('group_id') ){
            account_department_id = account.group_id.toString();
            flag = '4';
        }

        const passValues = { 
            api_token : TOKEN.toString(),
            account_id : account.account_id.toString(),
            account_type : account.account_type.toString(),
            master_account_id : master_account_id.toString(),
            flag : flag.toString(),
            group_id : account_department_id.toString(),
       }

       axios
        .post( ApiUrls.bizcomWebHomeDetails  , passValues )
        .then( response => {

            let conversationListingTemp = [];
            let convesIDs = [];
            let conversForward = {};
            
            if ( parseInt(response.status)===201 ){
                if ( parseInt(response.data.success)===1 ){
   
                    if ( Object.keys(response.data.result.conversation_list ?? {}).length>0 ){

                        Object.entries(response.data.result.conversation_list ?? {}).map( ([key, row ]) => {

                            const convers_id = parseInt(row.convers_id || 0);
                            if ( convers_id!=parseInt(passConversID) ){

                                convesIDs.push(row.convers_id.toString());
                                row = { ...row, name : row.name.toLowerCase() };

                                let converDetail = row;
                                if ( converDetail.hasOwnProperty('department_name') && !Utilities.isEmpty(converDetail.department_name) ){
                                    if ( converDetail.name.toLowerCase()===converDetail.title.toLowerCase() ){
                                        converDetail = { ...converDetail , name : row.name.toLowerCase(), title : row.department_name.toLowerCase()  };
                                    }
                                }

                                conversationListingTemp.push(converDetail);
                                conversForward[convers_id] = row;
                            }
                        });

                        setConversAcctIDs(convesIDs);
                        setConversIDAll(convesIDs);

                        setConversForward(conversForward);
                        setListProfiles(conversationListingTemp);

                        if ( conversationListingTemp.length===0 ){
                            setShowEmpty(true);
                        }

                    }else{
                        setShowEmpty(true);
                    }

                }else{
                    setShowEmpty(true);
                }
            }else{
                setShowEmpty(true);
            }

            
        })
        .catch( (err) => { setShowEmpty(true); });

    }


    const getRecentConversationInternal = ( companyID, accountDetail = {} , masterDetail = {}  ) => {
        
        if ( parseInt(companyID)>0 && Object.keys(accountDetail).length>0 && Object.keys(masterDetail).length>0 ){

            const passValues = {
                api_token : BIZCOM_TOKEN.toString(),
                account_id : accountDetail.account_id.toString(),
                company_id : companyID.toString(),
                master_account_id : masterDetail.account_id.toString(),
            }

            axios
            .post( ApiUrls.bizcomWebInternalHomeDetail, passValues)
            .then( response => {

                if ( parseInt(response.status)===201 ){
                    if ( parseInt(response.data.success)===1 ){
                        
                        const team_list = response.data.result.module_list.internal_conversation ?? {};

                        if ( parseInt(team_list.length)>0 ){ 

                            let memberList = [];
                            let convesIDs = [];
                            let conversForward = {};

                            Object.entries(team_list).map( ( [id, memberdetail] ) => {

                                const constconversa_list  = memberdetail.conversa_list ?? {};
                                if  ( memberdetail.hasOwnProperty('conversa_list') && Object.keys(memberdetail.conversa_list).length>0 ){

                                    // excluded broadcast
                                    if ( parseInt(constconversa_list.flag || 0)!==5 && parseInt(constconversa_list.access_status || 0)===1 ){

                                        const convers_id = parseInt(constconversa_list.convers_id || 0);
                                        if ( convers_id!=parseInt(passConversID) ){

                                            convesIDs.push(constconversa_list.convers_id.toString());
                                            memberList.push(constconversa_list);
                                            conversForward[convers_id] = constconversa_list;
                                        }
                                    }
                                }
                            });

                            setConversAcctIDs(convesIDs);
                            setConversForward(conversForward);
                            setListProfiles(memberList);
                        }
                    }
                }
            })
            .catch( (err) => {

            });

        }
    }


    const sendForwardConversId = () => {
        
        if ( conversIDs.length>0 ){

            const selectedConvers = [];
            conversIDs.map( id => {
                const detail = conversForward[id];
  
                let auto_replay_status = '';
                let auto_replay_account = '';

                if ( detail.hasOwnProperty('auto_replay_status') ){
                    auto_replay_status = detail.auto_replay_status.replay_status.toString();
                    auto_replay_account = detail.auto_replay_status.replay_account.toString();
                }

                let passValues = {
                    convers_flag: detail.convers_flag.toString(),
                    conversation_id: detail.convers_id.toString(),
                    auto_replay_status : auto_replay_status.toString(),
                    auto_replay_account : auto_replay_account.toString()
                };

                if ( isInternal===true ){
                    passValues = { ...passValues, member_id : detail.account_id.toString() , company_id : detail.convers_company_id.toString()  };
                }
                selectedConvers.push(passValues);
            });
            
            dispatch( passForwardConversId( selectedConvers ) );
        }
    }

    useEffect( () => {


        const segment = Utilities.getUrlSegments();
        const encryptedUrlValues = atob(segment[2]);

        // internal chat
        if ( encryptedUrlValues.split('-').length===4 ){
            const teamId = atob(segment[1]);

            let profileDetailTemp = SessionUtilities.getProfileDetail(teamId);
            let companyDetailTemp = Utilities.workToCompany(profileDetailTemp);

            let masterAccountArr = {};
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
            if ( accountsList!==null ){
                accountsList = JSON.parse(accountsList);

                let firstKey = Object.keys(accountsList)[0];
                firstKey = parseInt(firstKey || 0);

                masterAccountArr = accountsList[firstKey];
            }

            getRecentConversationInternal(companyDetailTemp.account_id, profileDetailTemp, masterAccountArr );
            setIsInternal(true);

        }else{
            let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginProfileInfo!==null ){
                loginProfileInfo = JSON.parse(loginProfileInfo);
                if ( Object.keys(loginProfileInfo).length>0 ){
                    
                    let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                    getRecentConversation(profileLoggedinTemp);
                    setIsInternal(false);
                }
            }
        }

    }, [] );

      
    return (

        <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">

            {
                Object.keys(listProfiles).length>0 ? 
                    <Fragment>

                        <div className="form-group">
                            <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                            <label className="form-label">Search</label>
                        </div>
                        
                        <div className="select-all-wrap">
                            <div>select all</div>
                            <div className="mr-n1"><input type="checkbox"  checked={ `${ toggleAll ? 'checked' : '' }` }  onChange={ (e) => toggleMemberAll() }   /></div>
                        </div>
                        
                        <ul id="listing-profiles-member" className="list-members mt-2">
                            {
                                Object.entries(listProfiles).map( ([key, row] ) =>
                                    <li key={key} data-keyword={row.name.toLowerCase() } >
                                        <div><div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                        <div>
                                            <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                            <div className="profile-label">{row.title}</div>
                                        </div>
                                        <div>
                                            <input type="checkbox"  checked={ `${  ( conversIDs.includes(row.convers_id.toString()) ) ? 'checked' : ''  }` }  onChange={ (e) => toggleMemberInput(e, row ) } />
                                        </div>
                                    </li>
                                )
                            }
                        </ul>

                        <div className="team-selected-counter mt-3 text-center"> { conversIDs.length } Selected</div>

                        {
                            parseInt(conversIDs.length)>0 &&
                                <div className="py-5">
                                    <button type="button" id="btn-forward-send" className="btn-theme-black button-has-loader" onClick={ () => sendForwardConversId() }>send</button>
                                </div>
                        }
                        

                    </Fragment>

                :

                ( showEmpty===false ? <div className="content-loader animate"></div> : <div className="text-center text-lowercase no-record-label ">{ Utilities.labelProfileAvailable }</div>  )
            }
            
            
        </div>

    )
}

export default ChatForward;
