import React, { useEffect, useState } from 'react';
import {  useHistory  } from 'react-router-dom';
import {  useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import * as RM from 'ramda';

import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';

import QueryChatUtilities from '../helpers/QueryChatUtilities';


//import ChatProfilePopup from '../components/popups/ChatProfile';
import ChatTeamRestrictionResend from '../components/popups/ChatTeamRestrictionResend';
import ChatUserCommunicationResend from '../components/popups/ChatUserCommunicationResend';

import {  showChatSearch, chatSidebarBlock, passImageUrl, passConversationMessage as passConversationMessageReducer, passConversDetail as passConversDetailReducer } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import SessionUtilities from '../helpers/SessionUtilities';


const HeaderChat = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const showChat = useSelector( state => state.showChatSearch);
    const passChatProfile = useSelector( state => state.passChatProfile);
    const showNoteIndicator = useSelector( state => state.showNoteIndicator);
    const passConversID = useSelector( state => state.passConversID);
    const passConversDetail = useSelector( state => state.passConversDetail);
    const passConversationMessage = useSelector( state => state.passConversationMessage);

    const [ profileSelected, setProfileSelected ] = useState({});
    const [ showNoteDot, setShowNoteDot] = useState(false);
    const [ conversID, setConversID] = useState(0);
    const [ conversationDetail , setConversationDetail] = useState({});
    const [ loginDetail, setLoginDetail ] = useState({});
    const [ loginType , setLoginType ] = useState(0);
    const [ isInternalChat , setIsInternalChat ] = useState(false);

    const [showShowRestrictReasonPopUp, setShowShowRestrictReasonPopUp] = useState(false);
    const [showUserCommunicationReasonPopUp, setShowUserCommunicationReasonPopUp] = useState(false);

    const handleCloseRestrictReasonPopUp = () => setShowShowRestrictReasonPopUp(false);
    const handleShowRestrictReasonPopUp = () => setShowShowRestrictReasonPopUp(true);

    const handleShowUserCommunicationReasonPopUp = () => setShowUserCommunicationReasonPopUp(true);
    const handleCloseUserCommunicationReasonPopUp = () => setShowUserCommunicationReasonPopUp(false);
    

    const toggleSearchProfile = () => {
        let action = ( showChat===0 ) ? 1 : 0; 
        dispatch( showChatSearch(action) );
        dispatch( chatSidebarBlock('') );
    }

    const showSpecialNote = () => {

        if ( parseInt(conversationDetail.access_status || 1 )===0 && ! Utilities.isEmpty(conversationDetail.handle_account_name ?? '') ) {
            return false;
        }

        dispatch( chatSidebarBlock('') );
        const timer = setTimeout( () => {
            
            const flag = ( conversationDetail.hasOwnProperty('flag') && parseInt(conversationDetail.flag || 0)===2 ) ? 'groupdetail' : 'note';
            dispatch( chatSidebarBlock(flag.toString()) );
            clearTimeout(timer);

        }, 100 );
    }

    const handleTeamRestrictionResendProper = async ( reasonMsg = '' ) => {

        if ( parseInt(conversID)>0 ){

            const passValue = {
                api_token : TOKEN.toString(),
                account_id : loginDetail.account_id.toString(),
                conversa_id  : conversID.toString(),
                connect_reason : reasonMsg.toString()
            }
            
            const button_name = 'btn-confirm-team-request-resend';
            Utilities.elementDisabledStatus(button_name, true );

            const responseDB = await QueryChatUtilities.resendTeamAccessComm(passValue);
            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseDB).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

            if ( parseInt(responseDB.success)===0 ){ 
                Utilities.messagePopup('error', responseDB.errorMessage );
                return false; 
            }
            
            Utilities.messagePopup('success', responseDB.successMessage );
            handleCloseRestrictReasonPopUp();

            const conversDetail = await QueryChatUtilities.getConversationDetail({
                api_token: TOKEN.toString(),
                account_id : loginDetail.account_id.toString(),
                convers_id : conversID.toString(),
                flag : "1"
            });

            if ( ! RM.isEmpty(conversDetail) ){
                if ( parseInt(conversDetail.success)===1 ){
                    const currentConversationDetailTemp = conversDetail.result.bizchat_conversation.conversation_list;
                    ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 10);

                    dispatch( passConversDetailReducer(currentConversationDetailTemp) );
                }
            }
        }

    }

    const handleUserCommunicationResendProper = async ( reasonMsg = '' ) => {
        if ( parseInt(conversID)>0 ){

            const passValue = {
                api_token : TOKEN.toString(),
                account_id : loginDetail.account_id.toString(),
                conversa_id  : conversID.toString(),
                connect_reason : reasonMsg.toString()
            }
            
            const button_name = 'btn-confirm-user-communication-resend';
            Utilities.elementDisabledStatus(button_name, true );

            const responseDB = await QueryChatUtilities.resendUserComm(passValue);
            Utilities.elementDisabledStatus(button_name, false );

            if ( Object.entries(responseDB).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

            if ( parseInt(responseDB.success)===0 ){ 
                Utilities.messagePopup('error', responseDB.errorMessage );
                return false; 
            }
            
            Utilities.messagePopup('success', responseDB.successMessage );
            handleCloseUserCommunicationReasonPopUp();

            const conversDetail = await QueryChatUtilities.getConversationDetail({
                api_token: TOKEN.toString(),
                account_id : loginDetail.account_id.toString(),
                convers_id : conversID.toString(),
                flag : "1"
            });

            if ( ! RM.isEmpty(conversDetail) ){
                if ( parseInt(conversDetail.success)===1 ){
                    const currentConversationDetailTemp = conversDetail.result.bizchat_conversation.conversation_list;
                    ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 11);

                    dispatch( passConversDetailReducer(currentConversationDetailTemp) );
                }
            }
        }
    }

    const handleGoBack = async () => {
        
        let accountIdRequest = loginDetail.account_id;


        //--------------------this is for internal chat only ----------------------------------
        let segment = Utilities.getUrlSegments();
        const pageSlug = segment[0];

        let urlSlug = segment.pop();
        if ( typeof urlSlug!=='undefined' && pageSlug.toLowerCase()!=='chat' ){
            urlSlug = urlSlug.toString();

            let decryptedValues = atob(urlSlug);
            let decryptedValuesArray = decryptedValues.split('-');

            if ( decryptedValuesArray.length===4 ){
                accountIdRequest = decryptedValuesArray.pop();
            }
            
        }
        //--------------------end this is for internal chat only ----------------------------------
       
        await ChatUtilities.markMessagesRead( parseInt(accountIdRequest), parseInt(conversID) );

        if ( parseInt(history.length)>1 ){

            segment = Utilities.getUrlSegments();
            let segmentTemp = segment;
            urlSlug = segmentTemp.pop();
            
            if ( typeof urlSlug!=='undefined' ){

                // DEPARTMENT
                if ( urlSlug==='3' ){
                    history.goBack();
                    return false;
                }

                const idsSplitted = atob(urlSlug).toString().split('-');
                if ( idsSplitted.length===4){
                    history.push(`/${UrlSlugs.internalChat}/${segment[1]}`);
                }else{
                    history.goBack();
                }

            }else{
                history.goBack();
            }
        }else{

            let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
            if ( loginType!==null ){
                loginType = JSON.parse(loginType);
                loginType = parseInt(loginType);

                let urlDashboard = loginType===2 ? `/${UrlSlugs.departmentDashboard}` : `/${UrlSlugs.dashboard}`;
                history.push( urlDashboard );

            }else{
                history.goBack();
            }
        }
        

    }

    
    const blockUnblockResendOption = ( type = 1 ) => {
        const currentConversationDetail = { ...conversationDetail };

        const accessStatus = parseInt ( currentConversationDetail.access_status ?? 0 );
        const ownerAccountId = parseInt( currentConversationDetail.owner_account_id ?? 0 );
        const loginAccountId = parseInt( loginDetail.account_id ?? 0 );
        
        const blockInStatus = parseInt( currentConversationDetail.hasOwnProperty('block_status') &&  ( currentConversationDetail.block_status.block_in_status ?? 0 ) );
        const blockOutStatus = parseInt( currentConversationDetail.hasOwnProperty('block_status') &&  ( currentConversationDetail.block_status.block_out_status ?? 0 ) );

        let returnOptionHtml = '';

        if ( accessStatus===3 ){
            if ( type===1 ){
                if ( ownerAccountId===loginAccountId && blockInStatus===0 ){
                    returnOptionHtml = <Dropdown.Item onClick={ () => handleShowRestrictReasonPopUp() }>resend request</Dropdown.Item>
                }

                if ( ownerAccountId!==loginAccountId ){
                    returnOptionHtml = <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('reverse-rejection') ) } >reverse rejection</Dropdown.Item>
                }
               
            }else if ( type===2 ){
                if ( ownerAccountId!==loginAccountId){
                    const labelAction = blockOutStatus===0 ? 'block' : 'unblock';
                    returnOptionHtml = <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('block') ) } >{ labelAction }</Dropdown.Item>
                }
            }

        }else if ( accessStatus===4  ){
            if ( type===1 ){
                if ( ownerAccountId!==loginAccountId){
                    returnOptionHtml = <Dropdown.Item onClick={ () => handleShowUserCommunicationReasonPopUp() }>resend request</Dropdown.Item>
                }

                if ( ownerAccountId===loginAccountId  && blockInStatus===0  ){
                    returnOptionHtml = <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('reverse-rejection-communication') ) } >reverse rejection</Dropdown.Item>
                }

            }else if ( type===2 ){
                const labelAction = blockOutStatus===0 ? 'block' : 'unblock';
                returnOptionHtml = <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('block') ) }>{ labelAction }</Dropdown.Item>
            }
        }

        return returnOptionHtml;
    }

    const dropdownOptionHtml = () => {

        const conversFlag = parseInt(conversationDetail.flag  || 0 ) || 0;
        const accessFlag = parseInt(conversationDetail.access_flag  || 0 ) || 0;
        const pendingMembersCounter = parseInt(conversationDetail.pending_group_members_count  || 0 ) || 0;
        
        if ( [6].includes(conversFlag) ){
            return <>
                <Dropdown.Item  onClick={ () => dispatch( chatSidebarBlock('company-groups-authorised-team') ) }>authorised team</Dropdown.Item>

                { [1,2].includes(accessFlag) && <Dropdown.Item  onClick={ () => dispatch( chatSidebarBlock('company-groups-members') ) }>group members list</Dropdown.Item> }
                { 
                    [1].includes(accessFlag) && 
                    <> 
                        {
                            pendingMembersCounter>0 ?
                                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('company-groups-pending-members') ) }>pending member/s ({pendingMembersCounter})</Dropdown.Item>
                            :
                                <Dropdown.Item className="opacity-05 cursor-notallow">pending member/s ({pendingMembersCounter})</Dropdown.Item>
                        }
                    </>
                }

                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('template') ) }>saved frequent message</Dropdown.Item>
                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('mute') ) }>mute notification</Dropdown.Item>
                
                { [3].includes(accessFlag) && <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('leave-company-group') ) }>leave group</Dropdown.Item> }
            </>

        }else{
            return <>
                {
                    ( conversationDetail.hasOwnProperty('flag') && parseInt(conversationDetail.flag  || 0 )===5 ) &&
                        <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('broadcast') ) }>broadcast</Dropdown.Item>
                }

                { 
                    parseInt(conversationDetail.flag)!==2 &&
                        <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('template') ) }>saved frequent message</Dropdown.Item>
                }

                { 
                    ( parseInt(loginDetail.account_type)===4 && loginDetail.hasOwnProperty('account_type') && parseInt(conversationDetail.access_status || 0)===1 && ![2].includes(parseInt(conversationDetail.flag || 0)) && isInternalChat===false ) &&
                        <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('transferchat') ) }>transfer chat</Dropdown.Item>
                }

                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('media') ) }>media</Dropdown.Item>
                

                { 
                    ![2].includes(parseInt(conversationDetail.flag || 0)) &&
                        <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('block') ) }>
                            { conversationDetail.hasOwnProperty('block_status') && parseInt(conversationDetail.block_status.block_out_status)===0 ? 'block' : 'unblock' }
                        </Dropdown.Item>
                }
                
                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('mute') ) }>mute notification</Dropdown.Item>
                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('clear') ) }>clear chat</Dropdown.Item>
                <Dropdown.Item onClick={ () => dispatch( chatSidebarBlock('report') ) }>report</Dropdown.Item>
            </>
        }
    }

    useEffect( () => {

        // CHECK LOGIN TYPE
        const loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginTypeStorage);
    
        if ( sesstionLoginType !== null ){
            setLoginType( parseInt(sesstionLoginType) );
        }


        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);
            setLoginDetail(loginBasicInfo);
        }

        return () => {
            setLoginType(0);
        }

    }, [] );

    

    useEffect( () => {
        
        dispatch( chatSidebarBlock('') );
        let segment = Utilities.getUrlSegments(), profileSelected = {}, profileSelectedSession = {};
        
        const headerType = parseInt(props.headerType || 0);
        
        if ( headerType===3 || headerType===4 ){
            if (  Object.keys(passChatProfile).length===0  ){
                profileSelected = {};
            }else{

                if ( Object.entries(profileSelected).length===0 ){
                    profileSelected = passChatProfile;
                }
            }
        }else{
            profileSelected = {}
        }

        setProfileSelected(profileSelected);
        

        segment = Utilities.getUrlSegments();
        if ( ! Utilities.isEmpty(segment.slice(-1)[0])  ){
            const lastValueArray = atob(segment.slice(-1)[0]).split('-');
            if ( lastValueArray.length===4 ){
                setIsInternalChat(true);    

                profileSelectedSession = SessionUtilities.getDashboadInternalListSelectedProfile()??{};
                if ( Object.keys(profileSelected).length===0 && Object.keys(profileSelected).length>0 ){
                    setProfileSelected(profileSelectedSession);
                       
                }
            }
        }

    }, [passChatProfile] );


    useEffect( () => {

        let noteBol = showNoteIndicator || 0;
        noteBol = parseInt(noteBol)===0 ? false : true;

        setShowNoteDot(noteBol);

    }, [showNoteIndicator] );


    useEffect( () => {
        if ( parseInt(passConversID)>0 ){
            setConversID(passConversID);

            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( passConversID );
            setConversationDetail( currentConversationDetailTemp );
        }


        /* let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginBasicInfo!==null ){
            loginBasicInfo = JSON.parse(loginBasicInfo);
        }

        // MAKK ALL MESSAGES READ
        return async () => {
            await ChatUtilities.markMessagesRead( parseInt(loginBasicInfo.account_id), parseInt(passConversID) );
        } */

        return () => {
            dispatch( chatSidebarBlock('') );
        }

    }, [passConversID] );


    useEffect( () => {
        //if ( Object.keys( RM.isNil(passConversDetail) ? {} : passConversDetail ).length>0 ){
        if ( Object.keys( passConversDetail ?? {} ).length>0 ){
            setConversationDetail( passConversDetail );
        }
    }, [passConversDetail] );


    return (
        <>
            <header className="main-header main-header-chat text-lowercase">
                <div className="main-header-content">

                        <div className="main-logo"><div className={`back-navigation ${ Object.entries(passConversationMessage).length>0 ? 'ontop' : '' }`} onClick={ () =>  handleGoBack() } ></div></div>

                        <div className="user-navigate chat-conversation-header text-left">

                            {
                                Object.keys(profileSelected).length===0 ?
                                    <div className="profile-header-loader">
                                        <div className="profile-image"></div>
                                        <div className="profile-detail">
                                            <div className="profile-name"></div>
                                            <div className="profile-label"></div>
                                        </div>
                                    </div>
                                :
                                    <div className={`profile-header ${ ( parseInt(conversationDetail.access_status || 1 )===0 && ! Utilities.isEmpty(conversationDetail.handle_account_name ?? '')  ) ? '' : 'cursor-pointer' }`}>
                                        <div className={`profile-image fs-22-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url) }} onClick={ () => dispatch( passImageUrl(profileSelected.logo_url) ) }>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }</div>
                                        <div className="cursor-pointer" onClick={ () => showSpecialNote() }>
                                            <div className="profile-name-wrap"><div className="profile-name  text-left">{profileSelected.name}</div> { <span className={`note-indicator ${showNoteDot?'active':''}`}></span>}</div>
                                            <div className="profile-label">{profileSelected.category}</div>
                                        </div>
                                    </div>
                            }

                        </div>
                        
                        <div className="logo-sub">

                            <ul className="chat-nav-option">
                                <li className="btn-option">

                                    {
                                        !( 
                                            [1,2,3].includes( parseInt( conversationDetail.team_restrict_status ?? 0 ) ) ||
                                            parseInt( conversationDetail.hasOwnProperty('block_status') ?  ( conversationDetail.block_status.block_in_status ?? 0 ) : 0 )===1 
                                        ) && 
                                        <>

                                            {
                                                ( 
                                                    parseInt( conversationDetail.department_form_status )>0 &&
                                                    parseInt ( conversationDetail.access_status ?? 0 )!==3
                                                ) &&
                                                    <div className="btn-icon btn-document" onClick={ () => { dispatch( chatSidebarBlock('forms') ); dispatch( passConversationMessageReducer({}) ); }}></div>
                                            }

                                            <div className="btn-icon btn-magnify" onClick={ () => toggleSearchProfile() }></div>

                                            <Dropdown className="btn-icon btn-list position-relative">
                                                <Dropdown.Toggle variant="dropdown-icon" ><div onClick={ () => dispatch( chatSidebarBlock('') ) } className="btn-toggle"></div></Dropdown.Toggle>

                                                <Dropdown.Menu align="end" className="dropdown-chat-option">

                                                    { 
                                                        [3,4].includes( parseInt ( conversationDetail.access_status ?? 0 ) ) ?
                                                            <>
                                                                { blockUnblockResendOption(1) }
                                                                { blockUnblockResendOption(2) }
                                                            </>

                                                        :
                                                            dropdownOptionHtml()
                                                    }

                                                    
                                                    
                                                </Dropdown.Menu>

                                            </Dropdown>

                                        </>
                                    }

                                </li>
                            </ul>

                        </div>

                </div>

            </header>

            
            <ChatTeamRestrictionResend handleCloseRestrictReasonPopUp={ handleCloseRestrictReasonPopUp } showShowRestrictReasonPopUp={showShowRestrictReasonPopUp} handleTeamRestrictionResendProper={handleTeamRestrictionResendProper} />

            <ChatUserCommunicationResend handleCloseUserCommunicationReasonPopUp={ handleCloseUserCommunicationReasonPopUp } showUserCommunicationReasonPopUp={showUserCommunicationReasonPopUp} handleUserCommunicationResendProper={handleUserCommunicationResendProper} />

            

        </>
    )
}

export default HeaderChat;