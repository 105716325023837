import React, { useState , useEffect } from 'react';
import { useDispatch } from 'react-redux';
//import moment from 'moment';

import { showHeader, showFooter, showLoader, pageTitle, showMainSettings as showMainSettingsReducer, showDepartmentSettings as showDepartmentSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../actions';

import TitlesLabels from '../helpers/TitlesLabels';
import Utilities from '../helpers/Utilities';

import { getCounterByDates, getAppointmentsByIDs, getAppointmentsByDate, getReceiverAccountAndDeptReceiverIds} from '../helpers/QueryDiary';
import PagesAuthentication from '../helpers/PagesAuthentication';

import AppointmentReservationDetail from '../components/popups/AppointmentReservationDetail';

const CalendarDiary = () => {

    const dispatch = useDispatch();
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const globalDateFull = new Date();
    const globalCurrentDayNum = globalDateFull.getDate();
    const globalCurrentMonthNum = globalDateFull.getMonth();
    const globalCurrentMonth = globalDateFull.getMonth()+1;
    const globalCurrentYear =  globalDateFull.getFullYear();
    const globalCurrentYearMonth = globalCurrentYear+'-'+( globalCurrentMonth.toString().length===1 ? '0'+globalCurrentMonth : globalCurrentMonth.toString() );
    const globalTodayDate = globalCurrentYear+'-'+( globalCurrentMonth.toString().length===1 ? '0'+globalCurrentMonth : globalCurrentMonth.toString() )+'-'+( globalCurrentDayNum.toString().length===1 ? '0'+globalCurrentDayNum : globalCurrentDayNum.toString() );


    const dayBoxesArray = [...Array(35).keys()];
    const daysName = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const daysNameNum = { 'sun' : 6, 'mon' : 0, 'tue' : 1, 'wed' : 2, 'thu' : 3, 'fri' : 4, 'sat' : 5 };
    const monthYearTitleDefault = Utilities.months(globalCurrentMonthNum) +' '+globalCurrentYear;
    const labelStatusClassColor = { '1' : 'green', '0' : 'orange', '2' : 'orange', '3' : 'red' };
    const labelStatusText = { '1' : 'completed', '0' : 'pending', '2' : 'pending', '3' : 'cancelled' };

    
    const [ calendayDays, setCalendayDays] = useState([]);
    const [ selectedDate, setSelectedDate] = useState('');
    const [ selectedDayNum, setSelectedDayNum] = useState(globalCurrentDayNum);
    const [ calendayYearMonth, setCalendayYearMonth] = useState(globalCurrentYear+'-'+( globalCurrentMonth.toString().length===1 ? '0'+globalCurrentMonth : globalCurrentMonth.toString() ) );
    const [ monthYearTitle, setMonthYearTitle] = useState( monthYearTitleDefault );

    const [ headerTypeCurrent , setHeaderTypeCurrent ] = useState(1);
    const [ recordList, setRecordList] = useState([]);

    const [ recordSelected, setRecordSelected] = useState({});
    const [ showAppoinmentPopup , setShowAppoinmentwPopup ] = useState(false); 

    const [ userLoggedinInfo , setUserLoggedinInfo] = useState({});

    const handleCloseAppointmentPopUp = () => {
        setShowAppoinmentwPopup(false);
    }

    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    }


    const handleCalendarNavigation = ( actionName = '') => {

        if ( ! Utilities.isEmpty(actionName) ){

            const date = new Date(calendayYearMonth);
            let yearNum = date.getFullYear();
            let monthNum  = date.getMonth();
            let yearMonth = {};
            
            monthNum = monthNum===0 ? 12 : monthNum;

            if ( actionName==='next' ){
                yearNum = monthNum===11 ? yearNum+1 : yearNum;
                yearMonth = new Date(yearNum,monthNum+1,monthNum);
                
            }else{
                yearNum = monthNum===12 ? yearNum-1 : yearNum;
                yearMonth = new Date(yearNum,monthNum-1,monthNum);
            }

            monthNum  = yearMonth.getMonth()+1;

            let currentDayNum = globalCurrentMonthNum===(monthNum-1) ? globalCurrentDayNum : 1;
            setSelectedDayNum(currentDayNum);

            monthNum = monthNum.toString().length===1 ? '0'+monthNum : monthNum;
            setupCalendar(yearNum+'-'+monthNum);

            setRecordList([]);
            setSelectedDate('');
        }
    }


    const setupCalendar = async ( requestYearMonth = '') => {

        const date = Utilities.isEmpty(requestYearMonth) ? new Date() : new Date(requestYearMonth);
        const currentYear = date.getFullYear();
        //const currentDayNum = date.getDate();
        const previousMonth  = date.getMonth();
        const currentMonth  = date.getMonth() + 1;
        const currentMonthFormatted = currentMonth.toString().length===1 ? '0'+currentMonth : currentMonth;

        const prevDateMonth = new Date(currentYear,date.getMonth()-1,previousMonth);
        const nextDateMonth = new Date(currentYear,date.getMonth()+1,previousMonth);
        const nextDateMonthNum = nextDateMonth.getMonth() + 1;

        const calPrevYearMonth = prevDateMonth.getFullYear()+'-'+( previousMonth.toString().length===1 ? '0'+previousMonth : previousMonth.toString() );
        const calNextYearMonth = nextDateMonth.getFullYear()+'-'+( nextDateMonthNum.toString().length===1 ? '0'+nextDateMonthNum : nextDateMonthNum.toString() );

        const dateFirstDay = new Date(currentYear+' '+currentMonth);
        const dayNameFirstDay = dateFirstDay.getDay();
        const firstDayKey = daysNameNum[daysName[dayNameFirstDay]];

        const daysInPreviousMonth = getDaysInMonth(currentYear, previousMonth);
        const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

        let daysDisplay = [];
        let counterDay = 1;
        let counterDayNext = 1;
        let counterDayPrev = daysInPreviousMonth-(firstDayKey-1);

        let dayBoxesArr = [...dayBoxesArray];
        const totalKeysCount = daysInCurrentMonth+(firstDayKey-1);
        if ( totalKeysCount>35 ){
            dayBoxesArr = [...Array(42).keys()];
        }

        let counterDayFormatted = '', counterDayNextFormatted = '', counterDayPrevFormatted = '', calendarDates = [], dateFormatted = '';

        dayBoxesArr.map( dayKey => {
                    
            counterDayFormatted = counterDay.toString().length===1 ? '0'+counterDay : counterDay.toString();
            counterDayNextFormatted = counterDayNext.toString().length===1 ? '0'+counterDayNext : counterDayNext.toString();
            counterDayPrevFormatted = counterDayPrev.toString().length===1 ? '0'+counterDayPrev : counterDayPrev.toString();

            if ( dayKey>=firstDayKey && counterDay<=daysInCurrentMonth ) {
                dateFormatted = currentYear+'-'+currentMonthFormatted+'-'+counterDayFormatted;
                daysDisplay.push({
                    'date' : dateFormatted,
                    'day' : counterDayFormatted,
                    'type' : 'current',
                });
                
                counterDay++;
                
            }else if ( dayKey>daysInCurrentMonth ){
                dateFormatted = calNextYearMonth+'-'+counterDayNextFormatted;
                daysDisplay.push({
                    'date' : dateFormatted,
                    'day' : counterDayNextFormatted,
                    'type' : 'next',
                });
                counterDayNext++;
                
            }else if ( dayKey<firstDayKey ){
                dateFormatted = calPrevYearMonth+'-'+counterDayPrevFormatted;
                daysDisplay.push({
                    'date' : dateFormatted,
                    'day' : counterDayPrevFormatted,
                    'type' : 'previous',
                });
                counterDayPrev++;
            }

            calendarDates.push(dateFormatted);

        });


        const { receiverAccountIdsArray, receiverDepartmentIdsArray } = getReceiverAccountAndDeptReceiverIds();

        //console.log(usersAssociated);
        //console.log(receiverDepartmentIdsArray);

        const passval = {
                            days : calendarDates.join(),
                            receiver_account_id : receiverAccountIdsArray.join(',').toString(),
                            receiver_department_id : receiverDepartmentIdsArray.join(',').toString(),
                            type : '0'
                        };
        const responseDatesInformation = await getCounterByDates( passval );
 

        let loopDateInfo = {}, loopDateApiInfo = {}, daysDisplayLoop = [], selectedDayRecordIds = '';
        Object.entries(daysDisplay).map( ([ key, detail ]) => {
            loopDateInfo = { 'ids' : '', count : 0 };

            if ( responseDatesInformation.hasOwnProperty (detail.date.toString() ) ){
                loopDateApiInfo = responseDatesInformation[detail.date.toString()];
                loopDateInfo = { 'ids' : loopDateApiInfo.ids, count : parseInt(loopDateApiInfo.count) };

                if ( Utilities.isEmpty(requestYearMonth) ){
                    if (detail.date.toString()===globalTodayDate){
                        selectedDayRecordIds = loopDateInfo.ids;
                    }
                    
                }else{

                    if ( requestYearMonth===globalCurrentYearMonth ){
                        if (detail.date.toString()===globalTodayDate){
                            selectedDayRecordIds = loopDateInfo.ids;
                        }

                    }else{
                        if (detail.date.toString()===requestYearMonth+'-0'){
                            selectedDayRecordIds = loopDateInfo.ids;
                        }
                    }
                    
                }
            }

            daysDisplayLoop.push({...detail, ...loopDateInfo});
        });


        // GET APPOINTMENT/RESERVATION BY IDS
        if ( ! Utilities.isEmpty(selectedDayRecordIds) ){
            const responseRecordsInformation = await getAppointmentsByIDs( selectedDayRecordIds );
            if ( Object.keys(responseRecordsInformation).length>0 ){
                setRecordList(responseRecordsInformation);
            }

        }

        //console.log(daysDisplayLoop);
        //console.log(calendarDates.join());

        setCalendayDays(daysDisplayLoop);
        setMonthYearTitle( Utilities.months(previousMonth) +' '+ currentYear );
        setCalendayYearMonth(currentYear+'-'+currentMonthFormatted);

    }


    const setupCalendaDateBoxContent = ( dayArray = {} ) => {
        return (
            <div className="day-name-wrap">
                <div className="date-number"><span>{ dayArray.day }</span></div>
            </div>
        );
    }

    /* const handleSelectedDateRequest = async ( dateRequest = '') => {

        // GET APPOINTMENT/RESERVATION BY DATE
        if ( ! Utilities.isEmpty(dateRequest) ){

             const { receiverAccountIdsArray, receiverDepartmentIdsArray } = getReceiverAccountAndDeptReceiverIds();

            const passval = {
                                date_request : dateRequest.toString(),
                                receiver_account_id : receiverAccountIdsArray.join(',').toString(),
                                receiver_department_id : receiverDepartmentIdsArray.join(',').toString(),
                                type : '0'
                            };

            setRecordList([]);
            
            dispatch( showLoader(1) );
            const responseRecordsInformation = await getAppointmentsByDate( passval );
            dispatch( showLoader(0) );

            if ( Object.keys(responseRecordsInformation).length>0 ){
                setRecordList(responseRecordsInformation);
            }

        }
    } */
    
    const handleSelectedDateReload = () => {
        //handleSelectedDateRequest(selectedDate);

        if ( Utilities.isEmpty(selectedDate) ){
            setupCalendar();
        }else{
            const selectDateArray =  selectedDate.split('-');
            setupCalendar(selectDateArray[0]+'-'+selectDateArray[1]);
        }

    }

    const handleSelectedDate = async (dayArray = {}) => {

        if ( dayArray.type==='current' ){
            setSelectedDate( dayArray.date );

            // GET APPOINTMENT/RESERVATION BY IDS
            if ( ! Utilities.isEmpty(dayArray.ids) ){

                dispatch( showLoader(1) );
                const responseRecordsInformation = await getAppointmentsByIDs( dayArray.ids );
                dispatch( showLoader(0) );

                if ( Object.keys(responseRecordsInformation).length>0 ){
                    setRecordList(responseRecordsInformation);
                }

            }else{
                setRecordList([]);
            }

        }
       
    }

    const triggerSetting = () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if ( sesstionLoginType !== null ){
            sesstionLoginType = parseInt(sesstionLoginType);

            if ( sesstionLoginType===2 ){
                dispatch( showDepartmentSettingsReducer( new Date() ) );
            }else{
                dispatch( showMainSettingsReducer( new Date() ) );
            }
            
        }else{
            dispatch( showMainSettingsReducer( new Date() ) );
        }

    }

    const handleClickAppointment = ( detail = {} ) => {
        setRecordSelected(detail);
        setShowAppoinmentwPopup(true);
    }

    const profileImage = (detail = {} ) => {
        return  parseInt(userLoggedinInfo.account_id)===parseInt(detail.sender_detail.account_id) ? ( detail.receiver_detail.logo_url ?? '') :  ( detail.sender_detail.logo_url ?? '');
    }

    useEffect( async () =>{
        
        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.calendarDiary }`) );
        PagesAuthentication.getUserLoggedin();

        let headerType = 1;
        let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        setHeaderTypeCurrent(loginType===null ? 0 : 1);

        if ( loginType!==null ){
            loginType = JSON.parse(loginType);
            loginType = parseInt(loginType);
            headerType = loginType===2 ? 5 : 1;
        }
        
        dispatch( showHeader(headerType) );
        dispatch( showFooter(1) );


        setUserLoggedinInfo( PagesAuthentication.getUserLoggedinDetail());
        setupCalendar();


    }, [] );
      
    return ( 
        <div className="calendar-diary-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{ TitlesLabels.navigations.calendar }</div>
                            <div className="current">{ TitlesLabels.navigations.diary }</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            { headerTypeCurrent===1 && <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div> }
                         </li>
                    </ul>
                </div>
            </div>

            <div className="main-content calendar-dairy-content">
                <div className="container">

                    <div className="calendar-diary-wrap pb-5 text-lowercase">

                        {
                            Object.entries(calendayDays).length===0 ?
                                <div className="row global-preloader">
                                    <div className="col-sm-8">
                                            <div className="calendar-diary--nav text-center fs-20 font-gotham-book pb-4 text-lowercase">
                                                <div>
                                                    <div className="prev-nav-icon calendar-nav"></div>
                                                    <div className="px-4 bg-gray"><span className="opacity-0">{ monthYearTitle }</span></div>
                                                    <div className="next-nav-icon calendar-nav"></div>
                                                </div>
                                            </div>

                                            <ul className="list-day-name-wrap">
                                                {  ['mon','tue','wed','thu','fri','sat','sun'].map( dayname => <li key={dayname} className="bg-gray-theme"></li> ) }
                                            </ul>

                                            <div className="list-calendar-daycontent mt-2">
                                                <ul className="list-calendar-day-wrap">
                                                { 
                                                    [...Array(35).keys()].map( ( key ) =>
                                                        <li key={key}>
                                                            <div className="day-name-wrap">
                                                                <div className="date-number"><span className="bg-gray border-radius-none box-loader"></span></div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                                </ul>
                                            </div>

                                    </div>
                                    <div className="col-sm-4 pt-5 bg-gray"></div>

                                </div>
                            :
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="calendar-diary--nav text-center fs-20 font-gotham-book pb-4 text-lowercase">
                                            <div>
                                                <div className="prev-nav-icon calendar-nav" onClick={ () => handleCalendarNavigation('prev') }></div>
                                                <div className="px-4">{ monthYearTitle }</div>
                                                <div className="next-nav-icon calendar-nav" onClick={ () => handleCalendarNavigation('next') }></div>
                                            </div>
                                        </div>

                                        <ul className="list-day-name-wrap">
                                            {  ['mon','tue','wed','thu','fri','sat','sun'].map( dayname => <li key={dayname}>{dayname} </li> ) }
                                        </ul>

                                        <div className="list-calendar-daycontent mt-2">
                                            <ul className="list-calendar-day-wrap">
                                            { 
                                                Object.entries(calendayDays).map( ( [key, dayArray] ) =>
                                                    <li key={key} className={`${dayArray.type} ${ ( selectedDayNum===parseInt(dayArray.day) && dayArray.type==='current' )  ? 'active' : '' } ${ ( selectedDate===dayArray.date && dayArray.type==='current' ) ? 'selected' : '' } ${ parseInt(dayArray.count)>0 ? 'has-record' : ''} `} onClick={ () => handleSelectedDate( dayArray ) }>
                                                        { setupCalendaDateBoxContent(dayArray) }
                                                    </li>
                                                )
                                            }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-sm-4 pt-5 bg-gray">
                                        {
                                            Object.entries(recordList).length>0 ?
                                                <ul className="calendar-diary-record-list">
                                                    {
                                                        Object.entries(recordList).map( ([key, detail ]) => 
                                                            <li key={key} className="py-2" onClick={ () => handleClickAppointment(detail) }>
                                                                <div className="logo-size-50" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileImage(detail)) })`, backgroundColor :  Utilities.bgcolorHideInitialBg(profileImage(detail)) }}>{ Utilities.showHideInitial(profileImage(detail), detail.description) }</div>
                                                                <div>
                                                                    <div className="text-gray">{ Utilities.returnTimeFormat(detail.date_time) }</div>
                                                                    <div className="font-gotham-book ellipsis-2">{ detail.description || TitlesLabels.general.noContent }</div>
                                                                    <div className={`fs-12 font-gotham-book label-color-${ labelStatusClassColor[detail.request_status.toString()] }`}>{ labelStatusText[detail.request_status.toString()] }</div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            :
                                                <div className="fs-18 text-center px-5 text-gray pt-5 mt-5">{ TitlesLabels.general.noAppointmentReservationFound }</div>
                                        }
                                        
                                    </div>

                                </div>
                        }
                        
                    </div>

                </div>
                
            </div>

            <AppointmentReservationDetail appointmentDetail={recordSelected} showAppoinmentPopup={showAppoinmentPopup}  handleCloseAppointmentPopUp={handleCloseAppointmentPopUp} handleSelectedDateReload={handleSelectedDateReload}/>

        </div>
    )
}

export default CalendarDiary;