import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChatUtilities from '../helpers/ChatUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { getMembersGroups } from '../helpers/QueryChatGroups';
import Utilities from '../helpers/Utilities';
import jQuery from 'jquery';

import { passCompanyGroupsMembersIds } from '../actions';

const ChatCompanyGroupsMembers = (props) => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    //const BIZCOM_TOKEN = process.env.REACT_APP_chat_api_token_bizcom;
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const conversId = parseInt(props.conversID ?? 0);
    const conversationDetail = ChatUtilities.getAccountConversationDetail(conversId);
    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
    
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);

    const [ memberAccountIds, setMemberAccountIds ] = useState([]);
    const [ memberSelectedAccountIds, setMemberSelectedAccountIds ] = useState([]);
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ toggleAll, setToggleAll ] = useState(false);

   

    const defaultValues = { title : '' };
    const [ formValues, setFormValues ] = useState(defaultValues);


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else if ( fieldName==='title' ){

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleMemberInput = (e, row )  => {
        let memberSelectedAccountIdsTemp = memberSelectedAccountIds;
        let id = row.account_id.toString();
        
        ( ! memberSelectedAccountIdsTemp.includes(id) ) ?  memberSelectedAccountIdsTemp.push(id) :  ( memberSelectedAccountIdsTemp = [...Utilities.removeValueInArray(memberSelectedAccountIdsTemp, id)] );
        memberSelectedAccountIdsTemp = Utilities.arrayUnique(memberSelectedAccountIdsTemp);

        setMemberSelectedAccountIds(memberSelectedAccountIdsTemp);
        setToggleAll(false);
    } 

    const toggleMemberAll = () => {

        if ( toggleAll===true ){
            setMemberSelectedAccountIds([]);
        }else{
            setMemberSelectedAccountIds([...memberAccountIds]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const forwardSelectedAccountIds = () => {
        if ( memberAccountIds.length>0 ){
            dispatch( passCompanyGroupsMembersIds( memberSelectedAccountIds ) );
        }
    }

    const getMembers = async () => {

        // flag :  0: all, 1: active, 2: suspend, 3: pending
        const paramMembers = {
            api_token : TOKEN.toString(),
            account_id : (userLoginDetail.account_id ?? 0).toString(),
            group_id : (conversationDetail.id ?? 0).toString(),
            flag : "1"
        }

        const responseMembers = await getMembersGroups(paramMembers);
        if ( Object.entries(responseMembers).length===0 ){ 
            setShowEmpty(true);
            return false; 
        }

            if ( parseInt(responseMembers.success)===0 ){ 
                setShowEmpty(true);
                return false; 
            }

            const memberListRes = responseMembers.result.conversation_members_list.members_list ?? [];
            if ( parseInt(memberListRes.length)>0 ){ 

                let memberAccountIdsLoop = [];

                Object.entries(memberListRes).map( ( [id, memberdetail] ) => {
                    memberAccountIdsLoop.push(memberdetail.account_id.toString());
                });

                setMemberAccountIds(memberAccountIdsLoop);
                setListProfiles(memberListRes);
            }

    }   

    useEffect( () => {
        getMembers();
    }, [] );

      
    return (

        <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
            {
                Object.keys(listProfiles).length>0 ? 
                    <>
                        <div className="form-group">
                            <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                            <label className="form-label">Search</label>
                        </div>
                        
                        <div className="select-all-wrap">
                            <div>select all</div>
                            <div className="mr-n1"><input type="checkbox"  checked={ `${ toggleAll ? 'checked' : '' }` }  onChange={ (e) => toggleMemberAll() }   /></div>
                        </div>

                        <ul id="listing-profiles-member" className="list-members mt-2">
                                {
                                    Object.entries(listProfiles).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.name.toLowerCase() } >
                                            <div><div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                            <div>
                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                <div className="profile-label">{row.title}</div>
                                            </div>
                                            <div>
                                                <input type="checkbox"  checked={ `${  ( memberSelectedAccountIds.includes(row.account_id.toString()) ) ? 'checked' : ''  }` }    onChange={ (e) => toggleMemberInput(e, row ) } />
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                            <div className="team-selected-counter mt-3 text-center"> { memberSelectedAccountIds.length } Selected</div>

                            {
                            parseInt(memberSelectedAccountIds.length)>0 &&
                                <div className="py-5">
                                    <button type="button" id="btn-forward-send" className="btn-theme-black button-has-loader" onClick={ () => forwardSelectedAccountIds() }>done</button>
                                </div>
                        }
                    </>
                :
                    showEmpty===false ? <div className="content-loader animate"></div> : <div className="text-center text-lowercase no-record-label ">{ Utilities.labelProfileAvailable }</div> 
            }       
        </div>

    )
}

export default ChatCompanyGroupsMembers;
