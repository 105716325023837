import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; 
import jQuery from 'jquery';
import axios from 'axios';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';

import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import CryptoJS from 'crypto-js';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import { uploadProfileLogo } from '../helpers/AWSUpload';

import {  showHeader, showFooter, pageTitle  } from '../actions';

import FieldInput from '../components/FieldInput';
//import PasswordInput from '../components/PasswordInput';
import QueryUtilities from '../helpers/QueryUtilities';
import SignupHeader from '../layout/SignupHeader';

const CompanyTeamMember = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;

    let defaultFormValues = {
        team_first_name : '',
        team_last_name : '',
        team_email : '',
        //password : '',
        //password_confirm : '',
        
        team_department : '',
        team_position : '',

        team_internal_chat_status : 0,
        team_is_listing : 0,

        team_contact_number_code_id : 0,
        team_contact_number : '',
        team_contact_number_status : 0,
    }
    
    //const defaultLocation = Utilities.defaultLocation();

    const notificationValuesDefault = {
        fcm_token : '', utc_date : '' , brower_id : ''
    }
    
    const [ notificationValues, setNotificationValues ] = useState(notificationValuesDefault);

    const [ formValues, setFormValues] = useState(defaultFormValues);
    const [ domainName, setDomainName ] = useState('');
    const [ personalAccount, setPersonalAccount ] = useState({});
    const [ companyAccount, setCompanyAccount ] = useState({});
    const [ logoProfile , setLogoProfile] = useState('');
    const [ logoProfileValue , setLogoProfileValue] = useState('');
    const [ accountID , setAccountID] = useState(0);

    const [ defaultFlagMobile, setDefaultFlagMobile ] = useState();
    const [ defaultCountryCodeMobile, setDefaultCountryCodeMobile ] = useState('');

    const [ countries, setCountries] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showMobileMsg, setShowMobileMsg] = useState(false);


    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfileValue(e.target.result);
                    //setLogoProfile(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setLogoProfile(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let errorCounter = 0, formValuesTemp = formValues;

        if ( fieldName==='team_first_name'){
            formValuesTemp = { ...formValuesTemp, ...{ team_first_name : value } };
        }else if ( fieldName==='team_last_name'){
            formValuesTemp = { ...formValuesTemp, ...{ team_last_name : value } };
        }else if ( fieldName==='team_email'){
            formValuesTemp = { ...formValuesTemp, ...{ team_email : value } };
        /* }else if ( fieldName==='password'){
            formValuesTemp = { ...formValuesTemp, ...{ password : value } };

        }else if ( fieldName==='password_confirm'){
            formValuesTemp = { ...formValuesTemp, ...{ password_confirm : value } }; */

        }else if ( fieldName==='team_department'){
            formValuesTemp = { ...formValuesTemp, ...{ team_department : value } };
        }else if ( fieldName==='team_position'){
            formValuesTemp = { ...formValuesTemp, ...{ team_position : value } };
        
        }else if ( fieldName==='team_contact_number'){

            let team_contact_number = value.replace(' ','');
            setShowMobileMsg('');
            if ( ! Utilities.isEmpty(team_contact_number) && ! Utilities.contactNumberValidate( team_contact_number ) ){
                setShowMobileMsg( Utilities.contactNumberValidate( team_contact_number, 1 ).msg);
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number : team_contact_number } };

        }else if ( fieldName==='team_contact_number_status'){
            value = parseInt(formValuesTemp.team_contact_number_status)===1 ? 0 : 1;
            
            if ( value===1 && Utilities.isEmpty(formValues.team_contact_number || '') ){
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                //jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                value = 0;
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status : value } };
        }
        
        
        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);

        if ( ! Utilities.isEmpty(fieldName) && ['team_contact_number_status'] && value===1  ){
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileTeamActivationWarningMessage ,
                overlayClassName : 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue',  onClick: () => confirmContactNumber(fieldName, 1)  }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            }); 
        }
    }

    const confirmContactNumber = ( fieldName = '', value = 0 ) => {

        if ( ! Utilities.isEmpty(fieldName) && ['team_contact_number_status'] ){
            let formValuesTemp = formValues;

            if ( fieldName==='team_contact_number_status'){
                formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status : parseInt(value) } };

            }
            setFormValues(formValuesTemp);
        }
    }

    const pushValueVisiblity = ( fieldName ) =>{
        
        if ( !Utilities.isEmpty(fieldName) ){

            let formValuesTemp = formValues;
            if ( fieldName==='team_is_listing'){
                let valueTemp = ( parseInt(formValuesTemp.team_is_listing)===0) ? 1 : 0 ;
                formValuesTemp = { ...formValuesTemp, ...{ team_is_listing : valueTemp } };
                
            }else if ( fieldName==='team_internal_chat_status'){
                let valueTemp = ( parseInt(formValuesTemp.team_internal_chat_status)===0) ? 1 : 0 ;
                formValuesTemp = { ...formValuesTemp, ...{ team_internal_chat_status : valueTemp } };
            }

            setFormValues(formValuesTemp);
        }
       
    }


    const loginUser = async () => {

        const button_name = 'btn-submit-member';
        Utilities.elementDisabledStatus(button_name, true );


        await axios
            .post( ApiUrls.userGetByCredentail , { ...notificationValues, account_id : parseInt(personalAccount.account_id) }  )
            .then( response => {

                Utilities.elementDisabledStatus(button_name, false );

                const resStatus = response.status;
                if ( resStatus===200 ){

                    const resData = response.data;
                    const resLoginType = parseInt(resData.logintype);

                    if ( parseInt(resData.status)===1){
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );
                            
                            history.push(`/${UrlSlugs.dashboard}`);

                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                } 
                
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                Utilities.elementDisabledStatus(button_name, false );
            });

    }


    const submitTeam = async (e) => {
        e.preventDefault();

        const button_name = 'btn-submit-member';
        Utilities.elementDisabledStatus(button_name, false );

        const logo_value = document.getElementById('input_select_file').value;
        
        if ( Utilities.isEmpty(logo_value) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory );
            return false;
        }
    

        if ( accountID===0 ){ return false;   }

        const formData = new FormData();
        formData.append('company_id', accountID);
        formData.append('domain', domainName);
       
        let errorCounter = 0, responseChecker = false;
        //const fieldsChecker = ['team_first_name', 'team_last_name','team_email','team_department', 'team_position'];
        const fieldsOptionalChecker = ['team_contact_number'];
  
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( ! fieldsOptionalChecker.includes(key) ){
                if ( Utilities.isEmpty(value )){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
    
                }else{

                    if (key==='team_email'){
                        if ( Utilities.checkSpecialChar(formValues.team_email)){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }else{
                            jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }

                    }else if ( ['team_first_name', 'team_last_name', 'team_department', 'team_position' ].includes(key) ){
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if ( responseChecker===false ){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }else{
                            jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }

                    }else{
                        jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                        formData.append(key, value);
                    }
                }
            }
        });

        if ( !Utilities.isEmpty(formValues.password) ){

            /* if ( formValues.password.length<=5){
                Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                return false;
            } */

            const passwordValidation = Utilities.passwordValidation(formValues.password);
            if ( passwordValidation.response===false ){
                jQuery('[name="password"]').closest('.form-group').addClass('error');
                return false;
            }

            if ( formValues.password!==formValues.password_confirm ){
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame );
                return false;
            }

            formData.append('password', formValues.password);
        }
        

        let team_contact_number = formValues.team_contact_number || '';

        setShowMobileMsg('');
        if ( ! Utilities.isEmpty(team_contact_number) ){
            if ( ! Utilities.contactNumberValidate(team_contact_number) ){
                setShowMobileMsg( Utilities.contactNumberValidate(team_contact_number, 1 ).msg);
                jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                errorCounter++;

            }else{
                jQuery('[name="team_contact_number"]').closest('.form-group').removeClass('error');
            }
        }else{
            if ( parseInt(formValues.team_contact_number_status)===1 ){
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                errorCounter++;
            }
        }
        

        if ( errorCounter>0 ){  return false; }

        
        formData.append('team_contact_number', formValues.team_contact_number || '');
        formData.append('team_contact_number_code_id', formValues.team_contact_number_code_id || 0);
        formData.append('team_contact_number_status', formValues.team_contact_number_status || 0);

        formData.append('company_category_id', companyAccount.category_id);

        formData.append('personal_id', personalAccount.account_id);
        formData.append('country_code', personalAccount.country_code);
        formData.append('mobile_number', personalAccount.mobile_number);
        formData.append('country_id', companyAccount.country_id);
        formData.append('city_id', companyAccount.city_id);
        formData.append('area_id', companyAccount.area_id);
        formData.append('state_id', companyAccount.state_id);
        formData.append('team_internal_chat_status', formValues.team_internal_chat_status );
        formData.append('team_is_listing', formValues.team_is_listing );
        
        const utcTime = Utilities.currentUTCTime();
        const curretTime =  moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());
        formData.append('create_date', curretTime);


        Utilities.elementDisabledStatus(button_name, true );

        axios
        .post( ApiUrls.profileSignupTeamDetail ,  formData, {  headers : { 'Content-Type': 'multipart/form-data' } } )
        .then( async (response) => {

            Utilities.elementDisabledStatus(button_name, false );

            if ( response.status===200 ){
                const dataResponse = response.data;

                if ( parseInt(dataResponse.status)===2 ){
                    Utilities.messagePopup('error', Utilities.labelEmailExist );

                }else{

                    if ( dataResponse.hasOwnProperty('account_id') ){

                        Utilities.elementDisabledStatus(button_name, true );

                        const formLogoData = new FormData();
                        formLogoData.append('account_id', dataResponse.account_id ); // team_account_id
                        formLogoData.append('file', logoProfile);
                        
                        await uploadProfileLogo( formLogoData );
                        Utilities.elementDisabledStatus(button_name, false );
                    }
                        
                    await loginUser();

                    //dispatch( reloadAccountsDB(Date.now()) );
                    //history.push('/dashboard' );
                }   

            }else{
                Utilities.messagePopup('error', errorMsg );
            }

        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg );
            Utilities.elementDisabledStatus(button_name, false );
        });
    
    }
    
    const updateNotificationValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = notificationValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString(); 
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);
        
        if ( Utilities.isEmpty(fcmToken) ){
            const fcmTokenTemp = localStorage.getItem( process.env.REACT_APP_fs_fcm_token );
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        }else{
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {...formValuesTemp,
                            ...{ 
                                fcm_token : fcmToken.toString(),
                                utc_date : dateFormatted.toString(),
                                brower_id : browserUniqueID.toString(),
                            }
                        };         
        setNotificationValues(formValuesTemp);
        
    }

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const focusDropdown = ( input_id) => {
        
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( !Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    const changeCountryCodeMobile = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagMobile(defaultFlagTemp);
        setDefaultCountryCodeMobile(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id : parseInt(passValues.id) } };
        setFormValues(formValuesTemp);
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '' ) => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getCountries = async ( providedMobileCode = 0 ) => {
        providedMobileCode = parseInt(providedMobileCode);

        const countriesResponse = await QueryUtilities.countryList();
        if ( countriesResponse.length>0 ){

            let optionList = [], formValuesTemp = formValues;
            Object.entries(countriesResponse).map( ([key, row] ) => {
                optionList = [...optionList , { value: row.id , label : row.country_name} ];
                
                if ( providedMobileCode>0 ){

                    if ( parseInt(providedMobileCode)===parseInt(row.id) ) {
                        setDefaultFlagMobile(row.country_short_code.toLowerCase());
                        setDefaultCountryCodeMobile(row.phonecode);

                        formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id : parseInt(row.id) } };
                        setFormValues(formValuesTemp);
                    }
                }

            });
            
            setCountries(countriesResponse);
        }

    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.teamMember ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        updateNotificationValues();

         // LOGGEDIN ACCOUNTS
         let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
         if ( loginProfileInfo!==null ){
             loginProfileInfo = JSON.parse(loginProfileInfo);
 
             if ( Object.keys(loginProfileInfo).length>0 ){
                let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);

                if ( profileLoggedinTemp.hasOwnProperty('company_display_name') ){
                    const companyDomainRequest = Utilities.isEmpty(profileLoggedinTemp.company_display_name) ? profileLoggedinTemp.company_name : profileLoggedinTemp.company_display_name;
                    let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                    domainNameTemp = domainNameTemp.toString().toLowerCase();

                    setDomainName(domainNameTemp);
            
                    setAccountID(profileLoggedinTemp.account_id);
                    setCompanyAccount(profileLoggedinTemp);

                    let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                    if ( accountsList!==null ){
                        accountsList = JSON.parse(accountsList);
            
                        if ( Object.keys(accountsList).length>0 ){
                            
                            const personalAccountTemp = Object.values(accountsList)[0];
                            let formValuesTemp = { 
                                                ...formValues,
                                                ...{
                                                    team_first_name : personalAccountTemp.first_name,
                                                    team_last_name : personalAccountTemp.last_name
                                                }
                                            } 

                            setFormValues(formValuesTemp);
                            setPersonalAccount(personalAccountTemp);

                            getCountries(companyDomainRequest.country_id);
                        }
                    }
                }
             }
         }

         getCountries(225);

      
    }, [] );

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title="Team Member" />
                {/* <header className="header mb-4 text-center">
                    <div className="logo-wrap">
                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                        <div className="logo-type-text mt-2">Team Member</div>
                    </div>
                </header> */}

                <form className="col-8 mx-auto pt-1 form-theme"  autoComplete="off">

                        <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension } readOnly onChange={ (e) => previewImage(e) } ></input>

                        <div className="avatar-wrap avatar-md-wrap">
                            <div>
                                <div className="avatar-preview rounded-circle mx-auto position-relative overflow-visible" style={{ backgroundImage:  `url(${logoProfileValue})` }} ></div>
                                <button type="button" className="btn-select-file text-lowercase mx-auto position-relative" onClick={ () => selectImage() }>select image</button>
                            </div>
                        </div>
                        <div className="mt-3 text-center">{Utilities.labelUploadSpecs}</div>


                        <div className="form-col-2 mt-5">
                            <div>

                                <FieldInput inputName="team_first_name" inputLabel="First Name" inputValue={ formValues.team_first_name } pushValue={pushValue} checkerType="general"/>

                                <FieldInput inputName="team_last_name" inputLabel="Last Name" inputValue={ formValues.team_last_name } pushValue={pushValue} checkerType="general"/>


                                <div className="form-group optional">
                                    <input type="text" name="team_email" className="form-control" placeholder=" " autoComplete="new-password" value={ formValues.team_email }  onChange={ (e) => pushValue( e, 'team_email') } ></input>
                                    <label className="form-label">Email</label>
                                    <div className="optional-label">@{ domainName }</div>
                                    
                                </div>

                                {/* <PasswordInput inputName="password" inputLabel="Password"  inputValue={ formValues.password } pushValue={pushValue} showChecker={true}/>

                                <PasswordInput inputName="password_confirm" inputLabel="Confirm Password"  inputValue={ formValues.password_confirm } pushValue={pushValue} /> */}

                                <FieldInput inputName="team_department" inputLabel="Company Division" inputValue={ formValues.team_department } pushValue={pushValue} checkerType="general"/>

                            </div>

                            <div>

                                <FieldInput inputName="team_position" inputLabel="Company Position" inputValue={ formValues.team_position } pushValue={pushValue} checkerType="general"/>

                                <div>
                                    <div className="form-group active has-off-on optional">
                                        <div className="form-group-mobile">
                                            <Dropdown className="dropdown-country-selection hide-arrow">
                                                <Dropdown.Toggle>
                                                    <div className="flag-code" onClick={ () => focusDropdown('mobile-input') }>
                                                        <span className={`flag-icon flag-icon-${defaultFlagMobile} mr-2`}></span>
                                                        <span>{ !Utilities.isEmpty(defaultCountryCodeMobile) ? '+'+defaultCountryCodeMobile: ''}</span>
                                                    </div>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu >
                                                <div className="px-4 mb-1">
                                                    <input type="text" id="mobile-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'mobile-input-list', 'search-keyword') }></input>
                                                </div>
                                                <div id="mobile-input-list" className="country-code-list">
                                                {
                                                    Object.entries(countries).map( ([key, row] ) => 
                                                        <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeMobile(row) } >
                                                            <div className="flag-code">
                                                                <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                                <span>{ row.country_name }</span>
                                                                <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                </div>  
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        
                                            <input type="number" name="team_contact_number" className="form-control" placeholder=" " autoComplete="off" value={ formValues.team_contact_number }  onChange={ (e) => pushValue( e, 'team_contact_number') }  ></input>
                                        </div>
                                        <label className="form-label">Mobile Number</label>
                                        <div className="optional-label position-placeholder">(Optional)</div>

                                        <input type="checkbox" className="checkbox-onoff" value={ formValues.team_contact_number_status }  checked={formValues.team_contact_number_status===1 }   onChange={ (e) => pushValue(e, 'team_contact_number_status') } ></input>
                                    </div>

                                    {  ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(formValues.team_contact_number) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }
                                    
                                </div>
                                

                                <div className="form-group active border-bottom-0  mt-4">
                                    <div className="form-control">
                                        <div className="d-flex mt-2 checkbox-radio-default align-items-center">
                                            <div className="align-items-center d-flex"><input type="checkbox" selected name="listing" value={ formValues.team_internal_chat_status } onChange={ () => pushValueVisiblity('team_internal_chat_status') } checked={formValues.team_internal_chat_status===1 } ></input><span className="ml-2">Internal</span></div>
                                            <div className="ml-4 align-items-center d-flex"><input type="checkbox" name="external" value={ formValues.team_is_listing } onChange={ () => pushValueVisiblity('team_is_listing') } checked={formValues.team_is_listing===1 } ></input><span className="ml-2">External</span></div>
                                        </div>
                                    </div>
                                    <label className="form-label">Allow Visibility</label>
                                </div>

                            </div>

                        </div>  

                        <div className="col-8 mx-auto button-wrap text-lowercase text-center mt-5">
                            <button type="button" id="btn-submit-member" className="btn-theme-black text-lowercase button-has-loader"  onClick={ (e) => submitTeam(e) }>Submit</button>
                        </div>

                </form>
                
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_companyLogo}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
    </div>
    )
}

export default CompanyTeamMember;