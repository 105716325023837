import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ChatUtilities from '../../helpers/ChatUtilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

const ChatBroadCast = (props) => {

    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const minMemberRequired = 2;

    const [ memberList, setMemberList ] = useState([]);
    const [ memberAcctIDs, setMemberAcctIDs ] = useState([]);
    const [ acctIDConversID, setAcctIDConversID ] = useState({});
    const [ memberIDs, setMemberIDs ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ toggleAll, setToggleAll ] = useState(false);

    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ companyDetail, setCompanyDetail ] = useState([]);
    
    const defaultValues = { title : '', message : '' };
    const [ formValues, setFormValues ] = useState(defaultValues);
    
    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else{

            let formValuesTemp = formValues;
            if ( fieldName==='title' ){
                formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            }else if ( fieldName==='message' ){
                formValuesTemp = { ...formValuesTemp, ...{ message : value } };
            }

            setFormValues(formValuesTemp);
        }
    }

    const handleClosePopup = () => {
        setFormValues(defaultValues);
        setMemberIDs([]);
        setToggleAll(false);
        setDefaultSearchValue('');
        props.handleCloseChatBroadcast();
    }
    
    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleMemberInput = (e, row )  => {
        let memberIDsTemp = memberIDs;
        let id = row.account_id.toString();

        if ( ! memberIDsTemp.includes(id) ){
          memberIDsTemp.push(id);
        }else{
          memberIDsTemp =  Utilities.removeValueInArray(memberIDsTemp, id);
        } 

        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
        setMemberIDs(memberIDsTemp);
        setToggleAll(false);
    } 


    const toggleMemberAll = () => {;
        if ( toggleAll===true ){
          setMemberIDs([]);
        }else{
          setMemberIDs([...memberAcctIDs]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const sendMessage = () => {
        
        let members_list = [];
        memberIDs.map( id => {
          members_list.push( { account_id : id.toString(), convers_id : acctIDConversID[id.toString()].toString() } );
        });
        
        if ( Utilities.isEmpty(formValues.title) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.titleIsMissing );
            return false;
        }

        if ( Utilities.isEmpty(formValues.message) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.messageIsMissing );
            return false;
        }

        if ( parseInt(memberIDs.length)>=minMemberRequired ){

            const passValues = {
                api_token : TOKEN.toString(),
                account_id : profileDetail.account_id.toString(),
                company_id : companyDetail.account_id.toString(),
                title : formValues.title.toString(),
                message : formValues.message.toString(),
                members_list : members_list,
            }
            
            const button_name = 'btn-sent-broadcast-message';
            Utilities.elementDisabledStatus(button_name, true );

            axios
            .post( ApiUrls.bizchatCreateBroadcastDetails , passValues)
            .then( response => {
                Utilities.elementDisabledStatus(button_name, false );
                
                if ( parseInt(response.status)===201 ){
                  if ( parseInt(response.data.success)===1 ){

                        handleClosePopup();
                        
                        /* props.handleCloseChatBroadcast();
                        setMemberIDs([]);
                        setFormValues(defaultValues); */
                        
                        const conversation_list = response.data.result.bizchat_conversation.conversation_list;
                        const passValues = ChatUtilities.encryptChatDetail(conversation_list, 1, profileDetail ); 

                        ChatUtilities.saveAccountsConversationDetail(conversation_list);
                        history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(profileDetail.account_id) )}/${passValues}`);

                  }else{
                      Utilities.messagePopup('error', response.data.errorMessage );
                  }
                }else{
                  Utilities.messagePopup('error', errorMsg );
                }

            })
            .catch( (err) => {
                Utilities.elementDisabledStatus(button_name, false );
                Utilities.messagePopup('error', errorMsg );
            }); 

        }else if ( parseInt(memberIDs.length)<minMemberRequired ){
            Utilities.messagePopup('error', `mininum ${minMemberRequired} members is required!` );

        }else{
            Utilities.messagePopup('error', TitlesLabels.alertMessages.noMemberSelected );
        }
    }

    useEffect( () => {

      const memberListTemp = props.memberList;

      let acctIDs = [];
      let acctIDConversID = {};
      let memberListUpdated = [];

      Object.entries(memberListTemp).map( ([key, row] ) => {
            //acctIDs.push(row.account_id.toString());

            let conversid = 0;
            if  ( row.hasOwnProperty('conversa_list') && parseInt(Object.keys(row.conversa_list).length)>0 ){
                conversid = row.conversa_list.convers_id ?? 0;

                if ( parseInt(row.conversa_list.flag)!==2 ){
                    memberListUpdated.push(row);
                    acctIDs.push(row.account_id.toString());
                }
            }

            if  ( row.hasOwnProperty('conversa_list') && parseInt(Object.keys(row.conversa_list).length)===0 ){
                memberListUpdated.push(row);
                acctIDs.push(row.account_id.toString());
            }

            acctIDConversID[row.account_id.toString()] = conversid.toString();
      });

      setMemberList(memberListUpdated);

      setMemberAcctIDs(acctIDs);
      setAcctIDConversID(acctIDConversID);

      setProfileDetail(props.profileDetail);
      setCompanyDetail(props.companyDetail);

    }, [props.memberList] );
    


    return (

      <Modal id="chat-broadcast-popup"  show={ props.showChatBroadcast } onHide={ handleClosePopup } animation={false} className="broadcast-popup-content" aria-labelledby="contained-modal-title-vcenter" centered   size="xl" >
              <Modal.Body className=" p-0">
                    
                    <div className="broadcast-col text-lowercase checkbox-radio-default checkbox-radio-check-icon form-theme">

                        <div className="member-col pt-4">

                            <div className="form-group">
                                <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                                <label className="form-label">Search</label>
                            </div>
                        
                            <div className="select-all-wrap">
                                <div>select all</div>
                                <div className="mr-n1"><input type="checkbox"  checked={ `${ toggleAll ? 'checked' : '' }` }  onChange={ (e) => toggleMemberAll() }   /></div>
                            </div>

                            <ul id="listing-profiles-member" className="list-members mt-2">
                              {
                                    Object.entries(memberList).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.name.toLowerCase() } >
                                            <div><div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                            <div>
                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                <div className="profile-label">{row.title}</div>
                                            </div>
                                            <div>
                                                <input type="checkbox"  checked={ `${ memberIDs.includes(row.account_id.toString())  ? 'checked' : ''  }` }  onChange={ (e) => toggleMemberInput(e, row ) } />
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>

                        <div className="message-col px-5 pt-4">
                                
                              <div className="col-8 mx-auto mt-3">
                                  <h2 className="text-center mb-4 pb-3 text-size-large ">Broadcast</h2>
                                  
                                  <div className="form-group">
                                      <input type="text"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ formValues.title }  onChange={ (e) => pushValue( e, 'title') } ></input>
                                      <label className="form-label">Title</label>
                                  </div>

                                  <div className="form-group  border-0">
                                      <textarea className="form-control text-lowercase" placeholder=" " rows="10" autoComplete="off" value={ formValues.message }  onChange={ (e) => pushValue( e, 'message') } ></textarea>
                                      <label className="form-label">type here</label>
                                  </div>
                              </div>
                                
                        </div>

                    </div>

              </Modal.Body>

              <Modal.Footer className="p-0 border-0 text-lowercase">

                    <div className="broadcast-col m-0 text-center">
                        <div className="member-col pt-0 pb-5">
                                <div className="team-selected-counter mt-3"> { memberIDs.length } Selected</div>
                        </div>
                        <div className="message-col px-5 mb-4 pb-3">
                            <button type="button" id="btn-sent-broadcast-message" className="btn-theme-black col-8 mx-auto button-has-loader" onClick={ () => sendMessage() }>send</button>
                        </div>
                    </div>

             </Modal.Footer>
            
          </Modal>
    )
}

export default ChatBroadCast;