import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; 
import axios from 'axios';
import moment from 'moment';
import * as RM from 'ramda';
import { Dropdown } from 'react-bootstrap';


import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';
import { uploadProfileLogo } from '../../helpers/AWSUpload';

import FieldInput from '../../components/FieldInput';
//import PasswordInput from '../../components/PasswordInput';

import {  showHeader, showFooter, pageTitle, showLoader, reloadProfile, reloadAccountsDB, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import QueryUtilities from '../../helpers/QueryUtilities';

const FormTeam = ({match}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_PLUS = process.env.REACT_APP_chat_api_token_bizcom;
    const limitCharacters = Utilities.companyAboutWordsLimit;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    
    let globalAccountDetail = {};
    let defaultFormValues = {
        team_id : 0,
        team_first_name : '',
        team_last_name : '',
        team_email : '',
        //password : '',
        //password_confirm : '',
        
        team_department : '',
        team_position : '',

        team_department_assign : '',

        team_internal_chat_status : 0,
        team_is_listing : 0,
        team_external_availability : 0,
        team_is_admin : 0,

        team_contact_number_code_id : 0,
        team_contact_number : '',
        team_contact_number_status : 0,

        team_about : ''
    }
    //const defaultLocation = Utilities.defaultLocation();

    let globalcompanyID = 0;
    let globalCompanyAccount = {};

    const [ formValues, setFormValues] = useState(defaultFormValues);
    const [ formValuesOld, setFormValuesOld] = useState(defaultFormValues);

    const [ formAction , setFormAction ] = useState('add');
    const [ domainName, setDomainName ] = useState('');
    const [ companyAccount, setCompanyAccount ] = useState({});
    const [ companyDepartments, setCompanyDepartments ] = useState({});
    
    const [ logoProfileOld, setLogoProfileOld ] = useState('');
    const [ logoProfile , setLogoProfile] = useState('');
    const [ profileLogoValue, setProfileLogoValue ] = useState('');

    const [ companyID , setCompanyID] = useState(0);
    const [ personalDetail , setPersonalDetail ] = useState({});
    const [ profileDetail , setProfileDetail ] = useState({});
    const [ accountDetail , setAccountDetail ] = useState({});
    const [ allowDelete , setAllowDelete ] = useState(true);
    const [ showDepartmentList , setShowDepartmentList ] = useState(false);
    const [ isTeamAdmin , setIsTeamAdmin ] = useState(false);

    const [ departmentNames, setDepartmentNames ] = useState('');
    const [ selectedDepartmentsID , setSelectedDepartmentsID ] = useState([]);

    const [ departmentNamesOld, setDepartmentNamesOld ] = useState('');
    const [ selectedDepartmentsIDOld , setSelectedDepartmentsIDOld ] = useState([]);

    const [ showButtonOption, setShowButtonOption ] = useState(false);
    const [ allowFormContent, setAllowFormContent ] = useState(false);

    const [ showAboutSection, setShowAboutSection ] = useState(false);
    const [ aboutContentPrev, setAboutContentPrev ] = useState('');

    const [ defaultFlagMobile, setDefaultFlagMobile ] = useState(''); //process.env.REACT_APP_country_iso);
    const [ defaultCountryCodeMobile, setDefaultCountryCodeMobile ] = useState(''); //process.env.REACT_APP_country_ext);

    const [ countries, setCountries] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showMobileMsg, setShowMobileMsg] = useState(false);

    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfile(e.target.result);
                    //jQuery('.avatar-preview').css('background-image', 'url(' +  e.target.result + ')');
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();

        let value = e.target.value;
        let errorCounter = 0, formValuesTemp = formValues;

        if ( fieldName==='team_first_name'){
            formValuesTemp = { ...formValuesTemp, ...{ team_first_name : value } };
        }else if ( fieldName==='team_last_name'){
            formValuesTemp = { ...formValuesTemp, ...{ team_last_name : value } };
        }else if ( fieldName==='team_email'){
            formValuesTemp = { ...formValuesTemp, ...{ team_email : value } };
        }else if ( fieldName==='password'){
            formValuesTemp = { ...formValuesTemp, ...{ password : value } };

        }else if ( fieldName==='password_confirm'){
            formValuesTemp = { ...formValuesTemp, ...{ password_confirm : value } };

        }else if ( fieldName==='team_department'){
            formValuesTemp = { ...formValuesTemp, ...{ team_department : value } };
        }else if ( fieldName==='team_position'){
            formValuesTemp = { ...formValuesTemp, ...{ team_position : value } };

        }else if ( fieldName==='team_department_assign'){
            formValuesTemp = { ...formValuesTemp, ...{ team_department_assign : value } };

        }else if ( fieldName==='team_is_admin'){
            value = parseInt(formValuesTemp.team_is_admin)===2 ? 0 : 2;
            formValuesTemp = { ...formValuesTemp, ...{ team_is_admin : value } };

        }else if ( fieldName==='team_contact_number'){

            let team_contact_number = value.replace(' ','');
            setShowMobileMsg('');
            if ( ! Utilities.isEmpty(team_contact_number) && ! Utilities.contactNumberValidate( team_contact_number ) ){
                setShowMobileMsg( Utilities.contactNumberValidate( team_contact_number, 1 ).msg);
                jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number : team_contact_number } };

        }else if ( fieldName==='team_contact_number_status'){
            value = parseInt(formValuesTemp.team_contact_number_status)===1 ? 0 : 1;
            
            if ( value===1 && Utilities.isEmpty(formValues.team_contact_number || '') ){
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                //jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                value = 0;
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status : value } };
        }
        
        if ( errorCounter===0 ){
            jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        }

        setFormValues(formValuesTemp);

        if ( ! Utilities.isEmpty(fieldName) && ['team_contact_number_status'] && value===1  ){
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileTeamActivationWarningMessage ,
                overlayClassName : 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue',  onClick: () => confirmContactNumber(fieldName, 1)  }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            }); 
        }

        if ( ! Utilities.isEmpty(fieldName) && ['team_is_admin'].includes(fieldName) && value===2  ){
            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishGrantManagementAccess ,
                overlayClassName : 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmManaementAccess(0) },
                    { label: 'continue',  onClick: () => confirmManaementAccess(2)  }
                ],
                onClickOutside: () => confirmManaementAccess(0),
                onKeypressEscape: () => confirmManaementAccess(0),
            }); 
        }

    }

    const confirmContactNumber = ( fieldName = '', value = 0 ) => {

        if ( ! Utilities.isEmpty(fieldName) && ['team_contact_number_status'] ){
            let formValuesTemp = formValues;

            if ( fieldName==='team_contact_number_status'){
                formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status : parseInt(value) } };

            }
            setFormValues(formValuesTemp);
        }
    }

    const confirmManaementAccess = (  status = 0  ) => {
        setFormValues({...formValues, team_is_admin : status });
    }

    const pushValueVisiblity = ( fieldName ) =>{
        
        if ( !Utilities.isEmpty(fieldName) ){

            let formValuesTemp = formValues;
            if ( fieldName==='team_is_listing'){
                let valueTemp = ( parseInt(formValuesTemp.team_is_listing)===0) ? 1 : 0 ;
                formValuesTemp = { ...formValuesTemp, ...{ team_is_listing : valueTemp } };
                
            }else if ( fieldName==='team_internal_chat_status'){
                let valueTemp = ( parseInt(formValuesTemp.team_internal_chat_status)===0) ? 1 : 0 ;
                formValuesTemp = { ...formValuesTemp, ...{ team_internal_chat_status : valueTemp } };


            }else if ( fieldName==='team_external_availability'){
                let valueTemp = ( parseInt(formValuesTemp.team_external_availability)===0) ? 1 : 0 ;
                formValuesTemp = { ...formValuesTemp, ...{ team_external_availability : valueTemp } };
            }

            setFormValues(formValuesTemp);
        }
       
    }


    const submitTeam = async (e) => {
        e.preventDefault();
        
        const button_name = 'btn-submit-member';
        Utilities.elementDisabledStatus(button_name, false );

        if ( companyID===0 ){ return false;   }

        const formData = new FormData();
        formData.append('team_id', formValues.team_id);
        formData.append('company_id', companyID);
        formData.append('domain', domainName);
        

        let errorCounter = 0, responseChecker = false;
        const fieldsChecker = ['team_first_name', 'team_last_name','team_email','team_department', 'team_position', 'team_is_admin'];
        
        Object.entries(formValues).map( ([ key, value] ) => { 
            if ( fieldsChecker.includes(key) ){

                if ( Utilities.isEmpty(value) ){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;
                    
                }else{

                    if (key==='team_email'){
                        if ( Utilities.checkSpecialChar(formValues.team_email)){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }else{
                            jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }

                    }else if ( ['team_first_name', 'team_last_name'].includes(key) ){
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if ( responseChecker===false ){
                            jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            errorCounter++;
                        }else{
                            jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            formData.append(key, value);
                        }
                    }else{
                        jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                        formData.append(key, value);
                    }
                }
            }
        });

        if ( !Utilities.isEmpty(formValues.password) ){

           /*  if ( formValues.password.length<=5){
                Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                return false;
            } */

            const passwordValidation = Utilities.passwordValidation(formValues.password);
            if ( passwordValidation.response===false ){
                jQuery('[name="password"]').closest('.form-group').addClass('error');
                return false;
            }

            if ( formValues.password!==formValues.password_confirm ){
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame )
                return false;
            }

            formData.append('password', formValues.password);
        }

        let team_contact_number = formValues.team_contact_number || '';

        setShowMobileMsg('');
        if ( ! Utilities.isEmpty(team_contact_number) ){
            if ( ! Utilities.contactNumberValidate(team_contact_number) ){
                setShowMobileMsg( Utilities.contactNumberValidate(team_contact_number, 1 ).msg);
                jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                errorCounter++;

            }else{
                jQuery('[name="team_contact_number"]').closest('.form-group').removeClass('error');
            }
        }else{
            if ( parseInt(formValues.team_contact_number_status)===1 ){
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                errorCounter++;
            }
        }
        
        if ( errorCounter>0 ){  return false; }
        
        formData.append('team_contact_number', formValues.team_contact_number || '');
        formData.append('team_contact_number_code_id', formValues.team_contact_number_code_id || 0);
        formData.append('team_contact_number_status', formValues.team_contact_number_status || 0);

        formData.append('handling_account_id', accountDetail.account_id);
        formData.append('company_category_id', companyAccount.category_id);
        
        formData.append('team_internal_chat_status', formValues.team_internal_chat_status );
        formData.append('team_is_listing', formValues.team_is_listing );
        formData.append('team_external_availability', formValues.team_external_availability );
        formData.append('team_about', formValues.team_about ?? '' );

        formData.append('country_id', companyAccount.country_id);
        formData.append('city_id', companyAccount.city_id);
        formData.append('area_id', companyAccount.area_id);
        formData.append('state_id', companyAccount.state_id);

        formData.append('departments_id', selectedDepartmentsID);
        
        
        if ( Object.keys(personalDetail).length>0 ){
            formData.append('personal_id', parseInt(personalDetail.account_id) );
            formData.append('profile_logo', personalDetail.profile_logo );
        }

        const utcTime = Utilities.currentUTCTime();
        const curretTime =  moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());
        formData.append('create_date', curretTime );
   
        Utilities.elementDisabledStatus(button_name, true );

        axios
        .post( ApiUrls.teamAddUpdateDetail ,  formData, {  headers : { 'Content-Type': 'multipart/form-data' } } )
        .then( async (response) => {

            Utilities.elementDisabledStatus(button_name, false );

            if ( response.status===200 ){
                const dataResponse = response.data;

                if ( parseInt(dataResponse.status)===-1 ){
                    Utilities.messagePopup('error', Utilities.labelEmailExist  );

                }else{


                    if ( dataResponse.hasOwnProperty('detail') ){
                        const logo_value = document.getElementById('input_select_file').value;
                        if ( !Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue) ){
                            Utilities.elementDisabledStatus(button_name, true );
                
                            const formLogoData = new FormData();
                            formLogoData.append('account_id', dataResponse.detail.team_id);
                            formLogoData.append('file', profileLogoValue);
                            
                            await uploadProfileLogo( formLogoData );
                            Utilities.elementDisabledStatus(button_name, false );
                        }
                    }

                    if ( parseInt(formValues.team_id)===0 ){
                        const passValue = {
                                    api_token : TOKEN.toString(),
                                    personal_account_id : personalDetail.account_id.toString(),
                                    company_id : companyAccount.account_id.toString(),
                                    company_team_admin_account_id : accountDetail.account_id.toString(),
                                    team_master_group_id : dataResponse.detail.team_master_group_id.toString()
                                }

                        sendChatTeamRequest(passValue);

                    }else{

                        if ( isTeamAdmin===true ){
                            dispatch( reloadProfile(Date.now()) );
                            dispatch( reloadAccountsDB(2) );
                        }
   
                        history.push(`/${UrlSlugs.settings_manageTeam}/${btoa(accountDetail.account_id)}`);
                    }
                    
                }    

            }else{
                Utilities.messagePopup('error', errorMsg );
            } 
        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg );
            Utilities.elementDisabledStatus(button_name, false );
        }); 

    }


    const sendChatTeamRequest = ( passValue ) => {
        
        const button_name = 'btn-submit-member';
        Utilities.elementDisabledStatus(button_name, true );

        axios
        .post( ApiUrls.bizchatSendTeamInvitation ,  passValue)
        .then( response => {
            
            Utilities.elementDisabledStatus(button_name, false );

            if ( parseInt(response.status)===201 ){

                if ( parseInt(response.data.success)===1 ){
                    Utilities.messagePopup('success', TitlesLabels.alertMessages.invitationSuccessfullySent );
                    history.push(`/${UrlSlugs.settings_manageTeam}/${btoa(accountDetail.account_id)}`);

                }else{
                    Utilities.messagePopup('error', errorMsg );
                }

            }else{
                Utilities.messagePopup('error', errorMsg ); 
            }
            
        })
        .catch( (err) => {
            Utilities.messagePopup('error', errorMsg); 
            Utilities.elementDisabledStatus(button_name, false );
        });

    }


    const confirmDeletion = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wishDeleteTeamMember,
            buttons: [
                { label: 'No',  onClick: () => {} },
                { label: 'Yes',  onClick: () => { deleteTeam() } }
            ]
        }); 
    }

    const deleteTeam = () => {
        const id = formValues.team_id;
        if ( parseInt(id)>0 ){

            const dataValues = {
                api_token: TOKEN_PLUS.toString(),
                account_id: accountDetail.account_id.toString(),
                company_id: profileDetail.team_company_id.toString(),
                team_account_id: id.toString()
            }

            dispatch( showLoader(1) );

            axios
            .post( ApiUrls.teamProfileRemove , dataValues )
            .then( response => {
                dispatch( showLoader(0) );

                if ( response.status===201 ){
                    const dataResponse = response.data;
                    if ( parseInt(dataResponse.success)===1 ){
                       history.push(`/${UrlSlugs.settings_manageTeam}/${btoa(accountDetail.account_id)}`);

                    }else{
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotDeleteMember );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                }
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                dispatch( showLoader(0) );
            }); 
        }
    }


    const cancelSelection = () => {
        setShowDepartmentList(false);

        setDepartmentNames(departmentNamesOld);
        setSelectedDepartmentsID(selectedDepartmentsIDOld);
    }
    
    const toggleActiveClass = (e, department )  => {
        let departmentIDsTemp = selectedDepartmentsID;
        let id = department.group_id.toString();

        ! ( departmentIDsTemp.includes(id) ) ? departmentIDsTemp.push(id) : ( departmentIDsTemp = [...Utilities.removeValueInArray(departmentIDsTemp, id)] ) ;
        departmentIDsTemp = Utilities.arrayUnique(departmentIDsTemp);
        
        setSelectedDepartmentsID(departmentIDsTemp);

        let department_names = [];
        Object.entries(companyDepartments).map( ([ key, row ]) => {
            if ( departmentIDsTemp.includes(row.group_id.toString()) ){
                department_names.push(row.department_name.toString());
            }
        });
        
        const selectedCounter = parseInt(department_names.length);
        department_names = department_names.join(',');

        department_names = ( department_names.length>30) ? department_names.substring(0, 20)+'...' : department_names;

        if ( selectedCounter>2 ){
            department_names = department_names +' +'+ ( selectedCounter - 2 );
        } 
        
        setDepartmentNames(department_names);
        
    }

    const submitTeamAbout = () => {
        const charCounter = Utilities.wordsCounter(formValues.team_about);
        if ( parseInt(charCounter)>limitCharacters ){
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!` );
            return false;
        }
        setShowAboutSection(false);
    }

    const getTeamDetail = ( id, action = 'add' ) => {

        if ( parseInt(id)>0 && parseInt(globalcompanyID)>0 ){
            setCompanyID(globalcompanyID);

            axios
            .post( ApiUrls.departmentListByAccountID , { id : globalcompanyID  } )
            .then( response => {
                if ( response.status===200 ){
                    const dataResponse = response.data;

                    if ( parseInt(dataResponse.status)===1 ){
                        let departmentsDB = dataResponse.departments;

                        if ( departmentsDB.length>0 ){
                            if ( 'department_name' in departmentsDB[0] ){
                                //const byName = RM.ascend(RM.prop('company_name')); key senstive
                                const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('department_name')));
                                departmentsDB = RM.sort(byName, departmentsDB);
                            }
                        }

                        setCompanyDepartments(departmentsDB);
                     
                            axios
                            .post( ApiUrls.teamDetail , { id : id, company_id : globalcompanyID, show_departments : 1 } )
                            .then( response => {
                            
                                if ( response.status===200 ){
                                    const dataResponse = response.data;
                                    if ( parseInt(dataResponse.status)===1 ){
                
                                        const dataDetail = dataResponse.detail;
                                        const departments_assigned = dataResponse.departments;

                                        let formValuesTemp = formValues,
                                            team_contact_number = dataDetail.hasOwnProperty('team_contact_number') ? parseInt(dataDetail.team_contact_number || '') : '',
                                            team_contact_number_code_id = dataDetail.hasOwnProperty('team_contact_number_code_id') ? parseInt(dataDetail.team_contact_number_code_id || 0) : 0 ;
                                        
                                            team_contact_number_code_id = team_contact_number_code_id || (globalCompanyAccount.country_id || 0 );

                                        if ( action==='edit'){
                                            formValuesTemp = {
                                                ...formValues,
                                                team_id : dataDetail.account_id,
                                                team_first_name : dataDetail.team_first_name,
                                                team_last_name : dataDetail.team_last_name,
                                                team_department : (typeof dataDetail.team_department==='undefined') ? '' : dataDetail.team_department ,
                                                team_position : dataDetail.team_position,
                                                team_email : dataDetail.team_email.toString().split('@')[0],
                                                team_internal_chat_status : parseInt(dataDetail.team_internal_chat_status || 0),
                                                team_is_listing : parseInt(dataDetail.team_is_listing || 0),
                                                team_is_admin : dataDetail.hasOwnProperty('team_is_admin') ? parseInt(dataDetail.team_is_admin || 0) : 0,
                                                team_about : dataDetail.team_about ?? '',

                                                team_contact_number_code_id : team_contact_number_code_id ,
                                                team_contact_number :  team_contact_number,
                                                team_contact_number_status : dataDetail.hasOwnProperty('team_contact_number_status') ? parseInt(dataDetail.team_contact_number_status || 0) : 0,
                                            }

                                            
                                            
                                            let team_external_availability = dataDetail.hasOwnProperty('team_external_availability') ? parseInt(dataDetail.team_external_availability || 0) : 0;
                                            if ( parseInt(dataDetail.team_is_admin || 0)===1 ){
                                                team_external_availability = 0;
                                            }
                                            formValuesTemp = { ...formValuesTemp, team_external_availability : team_external_availability };

                                            const selectedCounter = Object.keys(departments_assigned).length;

                                            if ( selectedCounter>0 ){
                                                setSelectedDepartmentsID(Object.keys(departments_assigned));
                                                setSelectedDepartmentsIDOld(Object.keys(departments_assigned));

                                                let department_names = [];
                                                Object.entries(departments_assigned).map( ([ key, row ]) => {
                                                    department_names.push(row.department_name.toString());
                                                });

                                                department_names = department_names.join(',');
                                                department_names = ( department_names.length>30) ? department_names.substring(0, 20)+'...' : department_names;
 
                                                if ( selectedCounter>2 ){
                                                    department_names = department_names +' +'+ ( selectedCounter - 2 );
                                                } 
                                                
                                                setDepartmentNames(department_names);
                                                setDepartmentNamesOld(department_names);
                                            }
                                            
                                            let allowDeletion = parseInt(dataDetail.team_is_admin || 0)===1 ? false : true;
                                            if ( parseInt(globalAccountDetail.account_id)===parseInt(dataDetail.account_id) ){
                                                allowDeletion = false;
                                            }
                                            setAllowDelete(allowDeletion);

                                            let teamAdmin = parseInt(dataDetail.team_is_admin || 0)===1 ? true : false;
                                            if ( parseInt(globalAccountDetail.team_is_admin || 0)===2 ){
                                                teamAdmin = true;
                                            }
                                            setIsTeamAdmin(teamAdmin);
                                            
                                            getCountries(team_contact_number_code_id);
                                            
                                        }else{

                                            formValuesTemp = {
                                                ...formValues,
                                                team_id : 0,
                                                team_first_name : dataDetail.first_name,
                                                team_last_name : dataDetail.last_name,
                                                team_department : (typeof dataDetail.team_department==='undefined') ? '' : dataDetail.team_department ,
                                                team_is_admin : 0,
                                                team_about : dataDetail.team_about ?? '',

                                                team_contact_number_code_id : team_contact_number_code_id,
                                                team_contact_number :  '',
                                                team_contact_number_status : 0,

                                            }
                                        
                                            setPersonalDetail(dataDetail);
                                            setAllowFormContent(true); 

                                            getCountries(team_contact_number_code_id);
                                        }

                                        
                                        setFormValues(formValuesTemp);
                                        setFormValuesOld(formValuesTemp);
                                        setProfileDetail(dataDetail);
                                        
                                        let profileDetail = Utilities.formatProfileDetail(dataDetail);
                                        
                                        const profileLogo = (profileDetail.profile_logo ?? '')==='' ? DEFAUL_AVATAR : profileDetail['logo_url'];

                                        setLogoProfile( profileLogo );
                                        setLogoProfileOld(profileLogo );

                                    }
                                }
                            })
                            .catch( (err) => {
                            
                            }); 
                    }
                }
            })
            .catch( (err) => {
            
            }); 

        }
    }   

    const setUpAboutContent = () => {
        setAboutContentPrev( formValues.team_about );
        setShowAboutSection(true);
        setAllowDelete(false);
    }

    const cancelAboutContent = () => {
        setFormValues({...formValues, team_about : aboutContentPrev});
        setShowAboutSection(false);
        
        if ( formValues.team_is_admin===0 ){
            setAllowDelete(true);
        }
    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }



    const handleOnclickEditOption = ( action = true ) => {
        setShowButtonOption(action);
        setAllowFormContent(action);

    
        setFormValues({...formValues, ...formValuesOld, ...{ password : '', password_confirm : '' } });
        jQuery('[name="password"], [name="password_confirm"]').closest('.form-group').removeClass('error');

        setDepartmentNames(departmentNamesOld);
        setSelectedDepartmentsID(selectedDepartmentsIDOld);

        setShowMobileMsg('');
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const getCountries = async ( providedMobileCode = 0 ) => {
        providedMobileCode = parseInt(providedMobileCode);

        const countriesResponse = await QueryUtilities.countryList();
        if ( countriesResponse.length>0 ){

            let optionList = [];
            Object.entries(countriesResponse).map( ([key, row] ) => {
                optionList = [...optionList , { value: row.id , label : row.country_name} ];
                
                if ( providedMobileCode>0 ){

                    if ( parseInt(providedMobileCode)===parseInt(row.id) ) {
                        setDefaultFlagMobile(row.country_short_code.toLowerCase());
                        setDefaultCountryCodeMobile(row.phonecode);
                    }
                }

            });
            
            setCountries(countriesResponse);
        }

    }

    const focusDropdown = ( input_id) => {
        
        setDefaultSearchValue('');
        jQuery('.dropdown-country-selection .dropdown-item').show();
        
        if ( !Utilities.isEmpty(input_id) ){
            let timer = setTimeout( () => {
                jQuery('#'+input_id)[0].focus();
                clearTimeout(timer);
            }, 100);
        }
    }

    const changeCountryCodeMobile = ( passValues ) => {
        let defaultFlagTemp = ( ! Utilities.isEmpty(passValues.country_short_code) && passValues.country_short_code!==null ) ? passValues.country_short_code.toLowerCase() : ''
        setDefaultFlagMobile(defaultFlagTemp);
        setDefaultCountryCodeMobile(passValues.phonecode.toString().trim());

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id : parseInt(passValues.id) } };
        setFormValues(formValuesTemp);
    }

    const pushValueCode = (e, input_id, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(input_id, value);
        }
    }

    const searchFilter = ( id = '', keyword = '' ) => {
        if ( Utilities.isEmpty(id) ){ return false; }
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#'+id+' > .dropdown-item').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-name').toLowerCase().indexOf(keyword) > -1);
        });
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    

    useEffect( async () => {
        if( allowFormContent===false ){
            setLogoProfile(logoProfileOld);
        }
    }, [allowFormContent] );


    useEffect( () => {

        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.teamMember }`) );

        dispatch( showHeader(1) );
        dispatch( showFooter(1) );
        
        const pathname = window.location.pathname; 
        const segment = pathname.split('/');
        const actionName = segment[segment.length-3];


        let reqAccountID = match.params.mid;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if ( reqAccountID>0 ){
                
                 // ASSOCIATED ACCOUNTS
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if ( accountsList!==null ){
                    accountsList = JSON.parse(accountsList);

                    if ( Object.keys(accountsList).length>0 ){
                        Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                            
                            const account_id = parseInt(acctdetail.account_id);
                            if (account_id===reqAccountID){

                                let accountProfile = Utilities.formatProfileDetail(acctdetail);
                                let team_external_availability = accountProfile.hasOwnProperty('team_external_availability') ? parseInt(accountProfile.team_external_availability  || 0 ) : 0;
                                if ( parseInt(accountProfile.team_is_admin)===1 ){
                                    team_external_availability = 0;
                                }

                                accountProfile = { ...accountProfile, team_external_availability : team_external_availability.toString()  };
                                setAccountDetail(accountProfile);
                                globalAccountDetail = accountProfile;

                                let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                                setCompanyAccount(profileCompanyTemp);
                                globalCompanyAccount = profileCompanyTemp;

                                setCompanyID(profileCompanyTemp.account_id);
                                globalcompanyID = profileCompanyTemp.account_id;
                                
                                const companyDomainRequest = Utilities.isEmpty(profileCompanyTemp.company_display_name) ? profileCompanyTemp.company_name : profileCompanyTemp.company_display_name;
                                let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                                domainNameTemp = domainNameTemp.toString().toLowerCase();

                                setDomainName(domainNameTemp);

                            }
                        });
                        
                    }

                }
            }
        }

        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);
            
            if ( parseInt(reqID)>0 && actionName==='edit'){
                setFormAction('edit');
            }

            getTeamDetail(reqID, actionName );
           
        }

    }, [] );

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div>{ TitlesLabels.settings }</div>
                            <div>{ companyAccount.hasOwnProperty('name') ? companyAccount.name : '' }</div>
                            <Link to={`/${ UrlSlugs.settings_manageTeam }/${ btoa(accountDetail.account_id) }`}>{ TitlesLabels.navigations.teamMembers }</Link>
                            <div className="current">{ formAction } { TitlesLabels.navigations.team }</div>
                         </li>
                         <li className="btn-option">
                             <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            { ( formAction==='edit' && allowDelete ) && <div className="btn-icon btn-trash" onClick={ () => confirmDeletion() }></div> }
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() }></div>
                            
                         </li>
                     </ul>
                </div>
                
            </div>

            <div className="main-content">
                <div className="container">
                        
                    {
                        showAboutSection===true ?
                       <div className="col-12 col-md-8 col-lg-6 mx-auto pt-1 form-theme text-lowercase">
                            <div className="form-group">
                                <textarea id="team_about" name="team_about" className="form-control" rows="7" placeholder=" " value={formValues.team_about} onChange={ (e) => setFormValues({ ...formValues, ...{ team_about : e.target.value } }) }></textarea>
                                <label className="form-label">about</label>
                            </div>
    
                            <div className="d-flex align-items-center justify-content-between text-color-gray">
                                <div className="text-color-blue cursor-pointer" onClick={ () => setFormValues({...formValues, team_about : '' }) }>clear</div>
                                <div>{ Utilities.number_format(limitCharacters.toString()) } words</div>
                            </div>
                            
                            <div className="mt-5 pt-5 button-group-boolean">
                                <button type="button" className="btn-theme-black" onClick={ () => cancelAboutContent() }>cancel</button>
                                <button type="button" className="btn-theme-black" onClick={ () => submitTeamAbout() }>submit</button>
                            </div>
                        </div>
                    :
                        <form className={`col-8 mx-auto pt-1 form-theme text-lowercase ${ ( showDepartmentList ? 'd-none' : '')}`}  autoComplete="off">

                            <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension } readOnly onChange={ (e) => previewImage(e) } ></input>
                            
                            <div className={`form-team-wrapper ${ allowFormContent===false ? 'content-disabled' : '' }`}>
                                
                                <div>
                                    <div className="avatar-wrap avatar-md-wrap">
                                        <div>
                                            <div className={`avatar-preview rounded-circle mx-auto position-relative ${allowFormContent===true ? 'overflow-visible' : '' }`} style={{ backgroundImage:  `url(${logoProfile})` }} >
                                        </div>
                                            { allowFormContent===true && <button type="button" className="btn-select-file text-lowercase mx-auto position-relative" onClick={ () => selectImage() }>select image</button> }
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">{Utilities.labelUploadSpecs}</div>
                                </div>

                                <div>   

                                        <FieldInput inputName="team_first_name" inputLabel="First Name" inputValue={ formValues.team_first_name } pushValue={pushValue} checkerType="general"/>

                                        <FieldInput inputName="team_last_name" inputLabel="Last Name" inputValue={ formValues.team_last_name } pushValue={pushValue} checkerType="general"/>

                                        <div className="form-group optional">
                                            <input type="text" name="team_email" className="form-control" placeholder=" " autoComplete="new-password" value={ formValues.team_email }  onChange={ (e) => pushValue( e, 'team_email') } ></input>
                                            <label className="form-label">Email</label>
                                            <div className="optional-label">@{ domainName }</div>
                                            
                                        </div>

                                        {/* <PasswordInput inputName="password" inputLabel="Password"  inputValue={ formValues.password } pushValue={pushValue} showChecker={true}/>

                                        <PasswordInput inputName="password_confirm" inputLabel="Confirm Password"  inputValue={ formValues.password_confirm } pushValue={pushValue}/> */}

                                        
                                        <div className="form-group">
                                            <input type="text" name="team_department" className="form-control" placeholder=" " autoComplete="off" value={ formValues.team_department  }  onChange={ (e) => pushValue( e, 'team_department') } ></input>
                                            <label className="form-label">Company Division</label>
                                        </div>

                                        <div className="form-group ">
                                            <input type="text" name="team_position" className="form-control" placeholder=" " autoComplete="off" value={ formValues.team_position }  onChange={ (e) => pushValue( e, 'team_position') } ></input>
                                            <label className="form-label">Company Position</label>
                                        </div>
                                        

                                        <div>
                                            <div className="form-group active has-off-on optional">
                                                <div className="form-group-mobile">
                                                    <Dropdown className="dropdown-country-selection hide-arrow">
                                                        <Dropdown.Toggle>
                                                            <div className="flag-code" onClick={ () => focusDropdown('mobile-input') }>
                                                                <span className={`flag-icon flag-icon-${defaultFlagMobile} mr-2`}></span>
                                                                <span>{ !Utilities.isEmpty(defaultCountryCodeMobile) ? '+'+defaultCountryCodeMobile: ''}</span>
                                                            </div>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu >
                                                        <div className="px-4 mb-1">
                                                            <input type="text" id="mobile-input" className="dropdown-search-input" placeholder="country name" autoComplete="new-password" value={ defaultSearchValue }  onChange={ (e) => pushValueCode( e, 'mobile-input-list', 'search-keyword') }></input>
                                                        </div>
                                                        <div id="mobile-input-list" className="country-code-list">
                                                        {
                                                            Object.entries(countries).map( ([key, row] ) => 
                                                                <Dropdown.Item  key={key} data-name={ row.country_name.toLowerCase() } onClick={ () => changeCountryCodeMobile(row) } >
                                                                    <div className="flag-code">
                                                                        <span className={`flag-icon flag-icon-${ ( ! Utilities.isEmpty(row.country_short_code) && row.country_short_code!==null ) ? row.country_short_code.toLowerCase() : '' } mr-2` }></span>
                                                                        <span>{ row.country_name }</span>
                                                                        <span className="text-color-gray ml-3">+{ row.phonecode  }</span>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            )
                                                        }
                                                        </div>  
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                
                                                    <input type="number" name="team_contact_number" className="form-control" placeholder=" " autoComplete="off" value={ formValues.team_contact_number || '' }  onChange={ (e) => pushValue( e, 'team_contact_number') }  ></input>
                                                </div>
                                                <label className="form-label">Mobile Number</label>
                                                <div className="optional-label position-placeholder">(Optional)</div>

                                                <input type="checkbox" className="checkbox-onoff" value={ formValues.team_contact_number_status }  checked={formValues.team_contact_number_status===1 }   onChange={ (e) => pushValue(e, 'team_contact_number_status') } ></input>
                                            </div>

                                            {  ( ! Utilities.isEmpty(showMobileMsg) && ! Utilities.isEmpty(formValues.team_contact_number) ) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{ showMobileMsg }</div> }
                                            
                                        </div>
                                        

                                        <div className="form-group optional" onClick={ () => setShowDepartmentList(true) }>
                                            <input type="text" name="team_department_assign" className="form-control cursor-pointer" disabled placeholder=" " value={ departmentNames }  onChange={ (e) => pushValue( e, 'team_department_assign') }  autoComplete="off" ></input>
                                            <label className="form-label">Assigned Chat</label>
                                            <div className="optional-label cursor-pointer">Add/Delete</div>
                                        </div>

                                        <div className="form-group optional active">
                                            <div className="form-control" onClick={ () => setUpAboutContent() }>view</div>
                                            <label className="form-label">profile info</label>
                                            <div className="optional-label">Optional</div>
                                        </div>

                                        <div className="form-group active pb-3  mt-4">
                                            <div className="form-control">
                                                <div className="d-flex mt-2 checkbox-radio-default align-items-center">
                                                    <div className="align-items-center d-flex"><input type="checkbox" selected name="listing" value={ formValues.team_internal_chat_status } onChange={ () => pushValueVisiblity('team_internal_chat_status') } checked={formValues.team_internal_chat_status===1 } ></input><span className="ml-2">Internal</span></div>
                                                    <div className="ml-4 align-items-center d-flex"><input type="checkbox" name="external" value={ formValues.team_is_listing } onChange={ () => pushValueVisiblity('team_is_listing') } checked={formValues.team_is_listing===1 } ></input><span className="ml-2">External</span></div>

                                                    { 
                                                        [1,2].includes(parseInt(accountDetail.team_is_admin)) &&
                                                            <div className="ml-4 align-items-center d-flex"><input type="checkbox"  name="external" value={ formValues.team_external_availability } onChange={ () => pushValueVisiblity('team_external_availability') } checked={formValues.team_external_availability===1 } ></input><span className="ml-2">Disable work chat</span></div>
                                                    }

                                                </div>
                                            </div>
                                            <label className="form-label">Allow Visibility</label>
                                        </div>

                                        { 
                                            ( parseInt(accountDetail.team_is_admin)===1 &&  parseInt(accountDetail.account_id)!==parseInt(formValues.team_id) ) &&
                                                <div className="form-group  has-off-on border-0">
                                                    <div className="form-control text-blue-placehoder">management access</div>
                                                    <input type="checkbox" className="checkbox-onoff" name="team_is_admin"  value={ formValues.team_is_admin }  checked={formValues.team_is_admin===2}   onChange={ (e) => pushValue(e, 'team_is_admin') }></input>
                                                </div>
                                        }

                                </div>
                            </div>

                            <div className="button-wrap text-lowercase text-center my-5 pt-4">

                                {
                                    formAction==='edit' ?
                                        <div>
                                            {
                                                showButtonOption===false ?
                                                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => handleOnclickEditOption(true) } >edit</button>
                                                :
                                                    <div className="button-group-boolean">
                                                        <button type="button" className="btn-theme-black text-lowercase" onClick={ () => handleOnclickEditOption(false) } >Cancel</button>
                                                        <button type="button" id="btn-submit-member" className="btn-theme-black text-lowercase button-has-loader"  onClick={ (e) => submitTeam(e) }>Update</button>
                                                    </div>
                                            }

                                        </div>
                                    :
                                        <button type="button" id="btn-submit-member" className="btn-theme-black text-lowercase button-has-loader"  onClick={ (e) => submitTeam(e) }>Submit</button>
                                }
                                
                                
                            </div>

                        </form>

                    }


                    {
                        showDepartmentList===true && 
                        <div className="department-list-wrap">
                            <ul id="listing-departments" className="listing-profiles mt-4">
                                {
                                    Object.entries(companyDepartments).map( ([key, row] ) =>
                                        <li key={key} data-keyword={row.department_name} className={ `cursor-pointer ${ ( ( selectedDepartmentsID.includes(row.group_id.toString()) ) ? 'selected' : '' ) }` }  onClick={ (e) => toggleActiveClass(e, row ) } >
                                            <div className="profile-wrap">
                                                <div className="profile-image-wrap">
                                                    <div className={`profile-image`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(companyAccount.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg( companyAccount.logo_url )  }}>{ Utilities.showHideInitial(companyAccount.logo_url, companyAccount.company_name) }</div>
                                                </div>
                                                <div className="profile-name">{row.department_name}</div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                            <div className="col-9 mx-auto  pt-5 mt-5">

                                <div className="button-group-boolean">
                                    <button type="button" className="btn-theme-black text-lowercase" onClick={ () => cancelSelection() } >Cancel</button>
                                    <button type="button" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => setShowDepartmentList(false) } >Save</button>
                                </div>
                                
                            </div>

                        </div>
                    }
                    
                </div>
            </div>
        </div>
        
    )
}

export default FormTeam;