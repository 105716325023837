import React, {  useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';
import UrlSlugs from '../../helpers/UrlSlugs';

//import QueryUtilities from '../../helpers/QueryUtilities';
import QueryChatUtilities from '../../helpers/QueryChatUtilities';
import ChatUtilities from '../../helpers/ChatUtilities';

import { passPushNotificationDetail as passPushNotificationDetailReducer, refershDashboadConversation as refershDashboadConversationReducer, passConversDetail as passConversDetailReducer  } from '../../actions';



const ChatCommunicationRequest = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const passPushNotificationDetail = useSelector( state => state.passPushNotificationDetail);
    
    const [ showPopup, setShowPopup ] = useState(false);
    const [ communicationPopup, setCommunicationPopup ] = useState({ title : '', type : 0, icon : '',  class : ''});
    const [ senderProfileDetail, setSenderProfileDetail ] = useState({});
    const [ receiverProfileDetail, setReceiverProfileDetail ] = useState({});


    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( passPushNotificationDetailReducer({}) );
    }

    const showCommunication = () => {
        handleClosePopup();

        // DIRECTED TO CHAT PAGE
    }

    
    const getSenderDetail = async ( account_id = 0 ) => {
        //account_id = parseInt(account_id);

       /*  const responseDB = await QueryUtilities.getProfileDetail(account_id, 1);
        console.log(responseDB);

        if ( Object.entries(responseDB).length===0 ){ 
            return false;
        } */
            

        const profileDetail = {
            logo_url : passPushNotificationDetail.sender_logo.toString(),
            name : passPushNotificationDetail.sender_name.toString(),
            account_title : passPushNotificationDetail.sender_account_title.toString()
        }

        setShowPopup(true);
        setSenderProfileDetail(profileDetail);

    }

    const getReceiverDetail = async ( account_id = 0 ) => {

        const profileDetail = {
            logo_url : passPushNotificationDetail.receiver_logo.toString(),
            name : passPushNotificationDetail.receiver_name.toString(),
            account_title : passPushNotificationDetail.receiver_account_title.toString()
        }

        setReceiverProfileDetail(profileDetail);
    }

    
    const requestAcceptReject = async ( status = 0 ) => {
        status = parseInt(status);

        if ( status>0 ){

            const button_name = status===1 ? 'btn-submit-accept' : 'btn-submit-reject';
            Utilities.elementDisabledStatus(button_name, true );
            
            const passval = {
                api_token : TOKEN.toString(),
                account_id : passPushNotificationDetail.receiver_account_id.toString(),
                conversa_id : passPushNotificationDetail.group_id.toString(),
                flag : status.toString(),
                connect_reason : ''
            }

            //console.log(passval);
            const responseDB = await QueryChatUtilities.acceptRejectAccessCommunication(passval);

            Utilities.elementDisabledStatus(button_name, false );
            
            handleClosePopup();


            // UPDATE CONVERSATION DETAIL
            //if ( status===1 ){
                let segment = Utilities.getUrlSegments();

                let urlSlug = segment.shift();
                urlSlug = typeof urlSlug!=='undefined' ? urlSlug.toString().toLowerCase() : '';

                if ( urlSlug!=='chat-conversation' && ! [ UrlSlugs.dashboard, UrlSlugs.departmentDashboard ].includes( urlSlug ) ){
                    const passValues = { 
                        api_token: TOKEN.toString(),
                        account_id: passPushNotificationDetail.receiver_account_id.toString(),
                        convers_id : passPushNotificationDetail.group_id.toString(),
                        flag : "1" 
                    };

                    const conversDetail = await QueryChatUtilities.getConversationDetail(passValues);
                    if ( ! RM.isEmpty(conversDetail) ){
                        if ( parseInt(conversDetail.success)===1 ){
                                const currentConversationDetailTemp = conversDetail.result.bizchat_conversation.conversation_list ?? {};
                                if ( Object.entries(currentConversationDetailTemp).length>0 ){
                                    ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);

                                    dispatch( passConversDetailReducer(currentConversationDetailTemp) );
                                }
                        }
                    }

                }else if ( [ UrlSlugs.dashboard, UrlSlugs.departmentDashboard ].includes( urlSlug ) ){
                    dispatch( refershDashboadConversationReducer( new Date() ) );
                }
            //}

        }
    }

    
    useEffect( () => {


        if ( typeof passPushNotificationDetail!=='undefined' && Object.entries(passPushNotificationDetail).length>0 ){
           
            if ( passPushNotificationDetail.hasOwnProperty('type') ){

                const typeName = passPushNotificationDetail.type;
                const typeNameArray = ChatUtilities.communicationTypeNameArray;

                if ( typeNameArray.includes(typeName) ){

                    let commPopup = { type : 0, title : '', icon : '',  class : ''};

                    if ( typeName==='request_user_communication' ){
                        commPopup = { ...commPopup, type : 1, title : 'communication request', icon : 'circle-box-with-arrow',  class : 'text-color-pink'};

                    }else if ( typeName==='request_user_communication_accepted' ){
                        commPopup = { ...commPopup, type : 2, title : 'communication accepted', icon : 'circle-guard-check',  class : 'text-color-green'};

                    }else if ( typeName==='request_user_communication_rejected' ){
                        commPopup = { ...commPopup, type : 3, title : 'communication rejected', icon : 'circle-guard-cross',  class : 'text-color-pink'};

                    }
                    
                    //console.log(commPopup);
                    setCommunicationPopup(commPopup);

                    getSenderDetail( passPushNotificationDetail.sender_account_id ) ;
                    getReceiverDetail( passPushNotificationDetail.receiver_account_id ) ;

                }
            }
        }

    }, [passPushNotificationDetail] );

    return (

            <Modal id="popup-chat-communication-request"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme popup-chat-communication-request" backdrop="static" keyboard={ false }>
                <Modal.Header closeButton className="border-bottom-0 zindex-1"></Modal.Header>
                <Modal.Body className="px-5">

                    <div className="profile-selected-assoc text-lowercase mb-4 form-theme col-10 mx-auto">
                        
                        <div className="d-flex justify-content-center line-height-1-2 d-flex align-items-center">
                            <div className={`logo-size-55 profile-team-logo fs-22-important ${  Utilities.classHideInitialBg( receiverProfileDetail.logo_url ?? DEFAUL_AVATAR ) }`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(receiverProfileDetail.logo_url ?? DEFAUL_AVATAR)  })` }}>{ Utilities.showHideInitial( (receiverProfileDetail.logo_url ?? DEFAUL_AVATAR) , receiverProfileDetail.name ) }</div>
                            <div className="ml-3">
                                <div className="font-gotham-medium-14">{receiverProfileDetail.name}</div>
                                <div className="profile-label fs-12">{ receiverProfileDetail.hasOwnProperty('company_detail') ? ( receiverProfileDetail.company_detail.name ?? receiverProfileDetail.title) :  ( receiverProfileDetail.title ?? receiverProfileDetail.account_title ) }</div>
                            </div>
                        </div>

                        {
                            ! Utilities.isEmpty(communicationPopup.title) &&
                            <div className={`font-gotham-medium-18 text-center d-flex align-item-center justify-content-center pt-5 ${communicationPopup.class}`}>
                                <img src={`${process.env.REACT_APP_img_folder}/icons/black/${communicationPopup.icon}.svg`} className="mr-2" width={30} />
                                { communicationPopup.title }
                            </div>
                        }
                        

                        <div className="pb-5 pt-4 mt-1 text-center mx-auto col-8 line-height-1-2">
                            <div className="logo-size-75 profile-team-logo mx-auto" style={{ backgroundImage:  `url(${ senderProfileDetail.logo_url ?? DEFAUL_AVATAR })` }}></div>

                            <div className="font-gotham-medium-16 notranslate mt-2">{senderProfileDetail.name}</div>
                            <div className="profile-label">{ senderProfileDetail.hasOwnProperty('company_detail') ? ( senderProfileDetail.company_detail.name ?? senderProfileDetail.title) :  ( senderProfileDetail.title ?? senderProfileDetail.account_title ) }</div>
                        </div>

                        {
                             communicationPopup.type===1 &&
                                <div className="button-group-boolean">
                                    <button type="button" id="btn-submit-accept" className="btn-theme-black button-has-loader" onClick={ () => requestAcceptReject(1) }>accept</button>
                                    <button type="button" id="btn-submit-reject" className="btn-theme-black button-has-loader" onClick={ () => requestAcceptReject(2) }>reject</button>
                                </div>
                        }

                        {
                             communicationPopup.type===2 &&
                                <div>
                                    <button type="button" id="btn-submit-continue" className="btn-theme-black button-has-loader" onClick={ () => showCommunication() }>continue</button>
                                </div>
                        }
                        
                        
                    </div>
                    
                </Modal.Body>
            </Modal>
    )
}

export default ChatCommunicationRequest;
