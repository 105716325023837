import ApiUrls from './ApiUrls';
import axios from 'axios';

class SessionUtilities {
    
    getDashboadLoadedStatus  = () => {
        return parseInt(sessionStorage.getItem(process.env.REACT_APP_sesion_dashboard_loaded)||0);
    }

    setDashboadLoadedStatus  = ( status = 0 ) => {
        status = parseInt(status);
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboard_loaded,
            JSON.stringify(status)
        );
    }

    getDashboadLoadedListTemp  = () => {
        let returns = [];

        let listProvided = sessionStorage.getItem(process.env.REACT_APP_sesion_dashboard_templist);
        if ( listProvided!==null ){
            listProvided = JSON.parse(listProvided);

            if ( listProvided.length>0 ){
                returns = listProvided;
            }
        }

        return returns;
    }

    setDashboadLoadedListTemp  = ( list = [] ) => {
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboard_templist,
            JSON.stringify(list)
        );
    }



    getDashboadInternalLoadedStatus  = () => {
        return parseInt(sessionStorage.getItem(process.env.REACT_APP_sesion_dashboardinternal_loaded)||0);
    }

    setDashboadInternalLoadedStatus  = ( status = 0 ) => {
        status = parseInt(status);
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboardinternal_loaded,
            JSON.stringify(status)
        );
    }

    getDashboadLoadedInternalListTemp  = () => {
        let returns = [];

        let listProvided = sessionStorage.getItem(process.env.REACT_APP_sesion_dashboardinternal_templist);
        if ( listProvided!==null ){
            listProvided = JSON.parse(listProvided);

            if ( listProvided.length>0 ){
                returns = listProvided;
            }
        }

        return returns;
    }

    setDashboadLoadedInternalListTemp  = ( list = [] ) => {
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboardinternal_templist,
            JSON.stringify(list)
        );
    }


    getDashboadInternalListSelectedProfile  = () => {
        let returns = {};

        let listProvided = sessionStorage.getItem(process.env.REACT_APP_sesion_dashboardinternal_profile_selected);
        
        if ( listProvided!==null ){
            listProvided = JSON.parse(listProvided);
            
            if ( Object.keys(listProvided).length>0 ){
                returns = listProvided;
            }
        }
        return returns;
    }

    setDashboadInternalListSelectedProfile  = ( list = {} ) => {
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboardinternal_profile_selected,
            JSON.stringify(list)
        );
    }



    sessionUpdateAccountsFromDB = ( overwrite_login = 0 ) => {

        overwrite_login = parseInt(overwrite_login);

        //const API_URL = process.env.REACT_APP_api_url;

        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
            accountsList = JSON.parse(accountsList);

            if ( Object.keys(accountsList).length>0 ){
                
                const account_id = Object.values(accountsList)[0].account_id;

                axios
                .post( ApiUrls.userGetByCredentail, { account_id : account_id }  )
                .then( response => {
                    const resStatus = response.status;

                    if ( resStatus===200 ){
                        const resData = response.data;
                        const resStatus = parseInt(resData.status);
                        const resLoginType = parseInt(resData.logintype);

                        if ( resStatus===1){

                            // SO IT WILL NOT OVERWRITE CURRENT
                            if ( overwrite_login===1 ){
                                localStorage.setItem(
                                    process.env.REACT_APP_session_login,
                                    JSON.stringify(resData.detail)
                                );
                            }

                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_internal,
                                JSON.stringify(resData.internal)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );

                            if ( resLoginType===1 ){

                                localStorage.setItem(
                                    process.env.REACT_APP_session_accounts,
                                    JSON.stringify(resData.accounts)
                                );

                            }else if ( resLoginType===2 ){

                                localStorage.setItem(
                                    process.env.REACT_APP_session_department_active,
                                    JSON.stringify(resData.detail)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_company_detail,
                                    JSON.stringify(resData.company_detail)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_company_departments,
                                    JSON.stringify(resData.departments)
                                );

                            }

                        }
                    }

                })
                .catch( (err) => {

                }); 
            }
        }
    }

    getProfileDetail = ( reqProfileID = 0, departmentId = -1 ) => {
        
        let profileDetail = {};

        if ( reqProfileID> 0 ){
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);

            if ( accountsList!==null ){
                accountsList = JSON.parse(accountsList);

                if ( Object.keys(accountsList).length>0 ){
                    
                    if ( reqProfileID.toString() in accountsList ){
                        profileDetail = accountsList[reqProfileID];
                   
                                // if have department
                                let profileDepartment = {};

                                if ( departmentId>-1 ) {
                                    
                                    if ( profileDetail.hasOwnProperty('departments') ){

                                        if ( departmentId===0 ){
                                            profileDepartment = profileDetail.departments[0];

                                        }else{

                                            for( const departmentKey in  profileDetail.departments ){
                                                const department = profileDetail.departments[departmentKey];
                                                
                                                if ( department.group_id && department.hasOwnProperty('group_id') &&  parseInt(department.group_id)===parseInt(departmentId) ){
                                                    profileDepartment = department;
                                                }
                                            }

                                        }
                                    }

                                    profileDetail = profileDepartment;
                                }

                    }
                    
                }
            }
        }

        return profileDetail;
    }


    updateCompanyDetail = ( companyDetailRequest = {} ) => {
        if ( Object.keys(companyDetailRequest).length>0 ){
            console.log(companyDetailRequest);
            const companyAccountId = parseInt(companyDetailRequest.account_id);
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);

            if ( accountsList!==null ){
                accountsList = JSON.parse(accountsList);

                let companyDetailTemp = {}, accountsListLoop = {}, accountTypeLoop = '', companyIdLoop = 0;
                Object.entries( accountsList ).map( ([key, row]) => {
                    accountTypeLoop = parseInt(row.account_type);

                    if ( accountTypeLoop!==4 ){
                        accountsListLoop[key] = row;
                    }else{
                        if ( row.hasOwnProperty('company_detail') ){
                            companyDetailTemp = row.company_detail;
                            companyIdLoop = parseInt(companyDetailTemp.account_id);

                            if ( companyAccountId===companyIdLoop ){
                                row = { ...row , company_detail : {...companyDetailTemp.company_detail, ...companyDetailRequest } };
                                accountsListLoop[key] = row;
                            }else{
                                accountsListLoop[key] = row;
                            }
                        }
                    }
                });

                localStorage.setItem(
                    process.env.REACT_APP_session_accounts,
                    JSON.stringify(accountsListLoop)
                );

            }
        }
    }
}

export default new SessionUtilities();

