import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import jQuery from 'jquery';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';

import { toggleDepartmentDefaultMessage as toggleDepartmentDefaultMessageReducer, passConversList } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';


const DepartmentDefaultMessage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const toggleDepartmentDefaultMessage = useSelector( state => state.toggleDepartmentDefaultMessage);
    
    const maxWords = 200;
    const defaultFormValues = {  message : '', status : 0 };

    const [ showPopup, setShowPopup ] = useState(false);
    const [ isEdit, setIsEdit ] = useState(false);
    const [ formOriginalValues, setFormOriginalValues ] = useState(defaultFormValues);
    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ accountDetail, setAccountDetail ] = useState({});
    const [ showEditButton, setShowEditButton ] = useState(false);

    const [ typeHereLabel , setTypeHereLabel ] = useState('Type here');

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        
        let formValuesTemp = formValues;

        if ( fieldName==='message'){

            const wordsCounter = Utilities.wordsCounter(value);
            if ( parseInt(wordsCounter)>maxWords ){
                Utilities.messagePopup('error', `Only ${maxWords} words allowed!` );
                return false;
            }

            formValuesTemp = { ...formValuesTemp, ...{ message : value } };

        }else if ( fieldName==='status'){
            value = parseInt(formValuesTemp.status)===1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ status : value } };

        }

        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
    }


    const clearMessage = () => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ message : '' } };
        setFormValues(formValuesTemp);
        setTypeHereLabel('Type here');
    }
    

    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( toggleDepartmentDefaultMessageReducer('') );
    }

    const handleCancel = () => {
        setIsEdit(false);
        setShowEditButton(true);

        setFormValues(formOriginalValues);
        setTypeHereLabel( Utilities.isEmpty(formOriginalValues.message) ? 'Type here' : 'Message');
    }

    const getDetail = () => {

        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
        if ( divertReplyInfo!==null ){
            divertReplyInfo = JSON.parse(divertReplyInfo);

            let formValuesTemp = {
                                    ...formValues,
                                    message : divertReplyInfo.hasOwnProperty('depart_message') ? divertReplyInfo.depart_message.depart_message : '',
                                    status : parseInt( divertReplyInfo.hasOwnProperty('depart_message') ? ( divertReplyInfo.depart_message.depart_message_status || 0 ) : 0),
                                };
            setFormValues(formValuesTemp);
            setFormOriginalValues(formValuesTemp);

            setTypeHereLabel( Utilities.isEmpty(formValuesTemp.message) ? 'Type here' : 'Message');

        }
    }


    const submitInfo = ( formValuesTemp = formValues ) => {

        if ( parseInt(accountDetail.account_id)>0 ){

            const wordsCounter = Utilities.wordsCounter(formValues.message.toString());
            if ( parseInt(wordsCounter)>maxWords ){
                Utilities.messagePopup('error', `Only ${maxWords} words allowed!` );
                return false;
            }


            if ( Utilities.isEmpty(formValues.message) && parseInt(formValuesTemp.status)===1 ){
                Utilities.messagePopup('error', TitlesLabels.alertMessages.messageIsRequired );
                return false;
            }

            const departmentId = accountDetail.hasOwnProperty('group_id') ? accountDetail.group_id.toString() : '0';
            const passValues = {
                api_token : TOKEN.toString(),
                account_id : accountDetail.account_id.toString(),
                dep_id : departmentId.toString(),
                default_message : formValuesTemp.message.toString(),
                default_msg_status : formValuesTemp.status.toString(),
            }

            const button_name = 'btn-submit-department-message';
            Utilities.elementDisabledStatus(button_name, true );
            
            axios
            .post( ApiUrls.profileUpdateDepartmentDefaultMessage , passValues)
            .then( response => {
                
                Utilities.elementDisabledStatus(button_name, false );

                if ( parseInt(response.status)===201 ){
                    if ( parseInt(response.data.success)===1 ){
                        
                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if ( divertReplyInfo!==null ){

                            divertReplyInfo = JSON.parse(divertReplyInfo);
                            divertReplyInfo = { 
                                                ...divertReplyInfo,
                                                depart_message : { 
                                                                    ...divertReplyInfo.depart_message,
                                                                    depart_message : formValuesTemp.message.toString(),
                                                                    depart_message_status : formValuesTemp.status.toString()
                                                                }
                                            };

                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(divertReplyInfo)
                            );
                                
                            dispatch( passConversList(divertReplyInfo) );

                            Utilities.messagePopup('success', response.data.successMessage);

                        }

                        setShowPopup(false);

                        history.push(`/${UrlSlugs.dashboard}`);
                        
                    }else{
                        Utilities.messagePopup('error', response.data.errorMessage );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                }
            })
            .catch( (err) => {
                Utilities.elementDisabledStatus(button_name, false );
                Utilities.messagePopup('error', errorMsg );
            }); 

        }

    }


    const changeTypeHereLabel = ( action = '' ) => {

        if ( action==='click' ){
            setTypeHereLabel('Message');
            
        }else if ( action==='blur' ){
           
            let requestLabel = 'Type here';
            const message = jQuery('textarea#default_message').val();
            if ( ! Utilities.isEmpty(message) ){
                requestLabel = 'Message';
            }
            setTypeHereLabel(requestLabel);
        }
    }

    useEffect( () => {

        setShowEditButton(false);
        
        if ( ! Utilities.isEmpty(toggleDepartmentDefaultMessage) ){

            let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginBasicInfo!==null ){
                loginBasicInfo = JSON.parse(loginBasicInfo);

                getDetail();
                setShowPopup(true);
                setAccountDetail(loginBasicInfo);

                if ( loginBasicInfo.hasOwnProperty('team_is_admin') && parseInt(loginBasicInfo.team_is_admin || 0)===1 ){
                    setShowEditButton(true);
                }
                
            }

        }else{
            setShowPopup(false);
        }
        
    }, [toggleDepartmentDefaultMessage] );


    return (

       <Modal id="popup-department-default-message"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  keyboard={ true }>
            <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline border-bottom-0 pt-4">{ TitlesLabels.general.defaultMessage }</Modal.Header>
            <Modal.Body className="pt-2 pb-5">

                    <div className="col-11 px-3 mx-auto  form-theme">

                        <div className={`pb-4 ${ ( isEdit===true && showEditButton===false ) ? '' : 'content-disabled' } `}>

                            <div className={`font-gotham-medium-16`}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="mt-n1">activate</div>
                                    <input type="checkbox" className="checkbox-onoff ml-2"  value={ formValues.status }  checked={formValues.status===1 }   onChange={ (e) => pushValue(e, 'status') } ></input>
                                </div>
                            </div>
                            
                            <div className="form-group mt-3">
                                <textarea id="default_message" name="message" className="form-control  px-0" rows="8" placeholder=" " value={ formValues.message } onChange={ (e) => pushValue(e, 'message') } onClick={ () => changeTypeHereLabel('click') } onBlur={ () => changeTypeHereLabel('blur') } ></textarea>
                                <label className="form-label px-0">{typeHereLabel}</label>
                            </div>

                            {
                                ( isEdit===true && showEditButton===false ) && 
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><span className="cursor-pointer text-color-blue" onClick={ () => clearMessage() }>clear</span></div>
                                    <div className="text-color-gray text-right mt-n2">max {maxWords} words</div>
                                </div>
                            }
                            
                        </div>

                        {
                            ( isEdit===true && showEditButton===false ) &&
                                <div className="button-group-boolean">
                                    <button type="button" className=" btn-theme-black" onClick={ () => handleCancel() }>cancel</button>
                                    <button type="button" id="btn-submit-department-message"  className=" btn-theme-black button-has-loader" onClick={ () => submitInfo() }>save</button>
                                </div>
                                
                        }

                        {
                            showEditButton===true &&
                                <button type="button"  className=" btn-theme-black button-has-loader" onClick={ () => { setIsEdit(true); setShowEditButton(false); } }>edit</button>
                        }
                    
                    </div>
                    
                
            </Modal.Body>
        </Modal>
    )
}

export default DepartmentDefaultMessage;
