import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';
import { getProfilesByCityId } from '../helpers/QueryContacts';

import CitySearchPopup from './popups/CitySearch';
import MainSearchProfile from './popups/MainSearchProfile';
import QueryMasterSearchUtlities from '../helpers/QueryMasterSearchUtlities';
import PagesAuthentication from '../helpers/PagesAuthentication';

import { showPopupContactSearch as showPopupContactSearchReducer  } from '../actions';


const ContactSearch = ( props ) => {

    const dispatch = useDispatch();

    const showPopupContactSearch = useSelector( state => state.showPopupContactSearch);

    const letters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','#'];
    let lettersAdded = [];
    let counterRow = 0;
    
    const defaultLocation = Utilities.defaultLocation();

    const [ selectedLetter, setSelectedLetter] = useState(letters[0]);
    const [ enableSearch, setEnableSearch] = useState(false);

    const [ profileShowStatus, setProfileShowStatus ] = useState(1);

    const [ show, setShow ] = useState(false);
    const [ showCityPopup, setShowCityPopup ] = useState(false);
    
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ currentLocation, setCurrentLocation ] = useState(defaultLocation);
    const [ gettingProfilesProcessing, setGettingProfilesProcessing ] = useState(true);
    const [ listProfiles, setListProfiles ] = useState([]);
    
    const [ profileSelected, setProfileSelected ] = useState({});

    const handleClosePopUp = () => setShowCityPopup(false);
    const handleShowPopUp = () => setShowCityPopup(true);


    const handleClose = () => {
        setProfileSelected({});
        setShow(false);
    }

    const handleShow = async ( profile ) => {
        setProfileSelected(profile);
        setShow(true);
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }


    const searchFilter = ( keyword = '') => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#profile-listing-contact > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);

         
        });

        jQuery('#profile-listing-contact > li').filter(function (){
            if ( jQuery(this).is(":visible") ){
                jQuery(`#profile-listing-contact > li.letter-row[data-letter="${jQuery(this).attr('data-letter')}"]`).show();
            }
        });


    }
    
    
    const switchLetter = ( requestLetter = '' ) => {
        if ( ! Utilities.isEmpty(requestLetter) ){
            requestLetter = requestLetter==='#' ? 'numeric' : requestLetter;

            let targetElem = jQuery('li#letter-contact-'+requestLetter), scrollElem = jQuery('#contact-listing');
            if ( targetElem.length>0 ){
                const topScroll = scrollElem.scrollTop() - scrollElem.offset().top + targetElem.offset().top;
                //scrollElem.scrollTop(topScroll);

                scrollElem.stop().animate({
                    scrollTop : topScroll

                }, 500, '', function () {
                    setSelectedLetter(requestLetter);
                });
            }
        }
    }

    const handleEnableSearch = () => {
        setEnableSearch(true);
    }

    const handleCloseSearch = () => {
        setEnableSearch(false);
        setDefaultSearchValue('');
        searchFilter('');
    }

    const passCityDetail = ( cityDetail = {} ) => {
        if ( Object.entries(cityDetail).length>0 ){

            let defaultLocationTemp = defaultLocation;
            defaultLocationTemp = { ...defaultLocationTemp,
                                    country_name : cityDetail.country_name,
                                    state_name : cityDetail.state_name,
                                    city_name : cityDetail.city_name,

                                    country_id : cityDetail.country_id,
                                    state_id : cityDetail.state_id,
                                    city_id : cityDetail.city_id
                            };
            setCurrentLocation(defaultLocationTemp);
            
            gettingProfiles(defaultLocationTemp.city_id);
            setSelectedLetter(letters[0]);
            jQuery('#contact-listing').scrollTop(0);

        }
        handleClosePopUp();
    }

    const gettingProfiles = async ( city_id = 0) => {

        if ( parseInt(city_id)>0 ){

            setGettingProfilesProcessing(true);
            const listPorifles = await getProfilesByCityId( city_id );
            setGettingProfilesProcessing(false);

            if ( Object.keys(listPorifles).length>0 ){
                setListProfiles(listPorifles.list ?? []);
            }else{
                setListProfiles([]);
            }
        }
    }


    const profileListingHtml = ( key, row ) => {

        counterRow++;

        let letter = row.name[0].toLowerCase();
        let letterId = key;
        let letterLabelRow = letter;

        if ( Utilities.isDigit(letter) ){
            letter = 'numeric';
            letterId = 'numeric';
            letterLabelRow = '#';
        }

        let letterRow = '';

        if ( ! lettersAdded.includes(letter) ){
            lettersAdded.push(letter);
            letterId = letter;

            letterRow = <li key={counterRow} id={`letter-contact-${letterId}`} data-letter={letter} data-inc={counterRow} className="letter-row" data-keyword="">
                            <span>{ letterLabelRow }</span>
                        </li>

            counterRow++;
        }

        const locationAddressFormatted = QueryMasterSearchUtlities.formatAdressByLocationType( 1 , row);
        const name = parseInt(row.account_type)===3 ? row.company_name : row.name;

        //const scrollAttr = 56.8175 * (parseInt(key)+1);

        const listRow = <li key={counterRow}  data-letter={letter} data-inc={counterRow} data-keyword={name.toLowerCase()} onClick={ () => handleShow( row ) } >
                            <div className="profile-wrap">
                                <div className="profile-image-wrap"><div className={`profile-image fs-14-important`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, name) }</div></div>
                                <div>
                                    <div className="profile-name notranslate" data-name={name}>{name}</div>
                                    { ( row.hasOwnProperty('category') && row.category!=='' ) && <div className="profile-label">{row.category}</div> }
                                </div>
                            
                                <div className="d-flex">
                                    { ( row.hasOwnProperty('team_company_name') && ! Utilities.isEmpty(row.team_company_name) ) && <div className="profile-work-label mr-2 notranslate">{row.team_company_name}</div> }
                                    <div className="profile-address">{locationAddressFormatted}</div>
                                </div>
                        
                                <div className="hidden-detail d-none">
                                    <div className="name notranslate">{name}</div>
                                    <div className="logo">{row.logo_url}</div>
                                    <div className="address">{locationAddressFormatted}</div>
                                </div>
                        
                            </div>
                        </li>

        return <>{ letterRow } { listRow }</>;
    }

    const loaderProfileHtml = () => {
        return (
                <ul className="dashboard-list-preloader">
                    {
                        [...Array( 6 + (Math.floor(Math.random() * 3)  ) ).keys()].map( ( key ) =>
                            <li key={key}>
                                <div className="profile-image"></div>
                                <div></div>
                                <div></div>
                            </li>
                        )
                    }
                    
                </ul>
        )
    }


    const closeSearchBox = () => {
        setShow(false);
    }

    const onscrollContacts = (e) => {
        const scrollElem = jQuery('#contact-listing');//, lettersElem = jQuery('.letter-list-selection');
        const topScroll = scrollElem.scrollTop();// - scrollElem.offset().top;

        const rowNumber = parseInt(parseInt(topScroll)/56.75) + 1;
        const rowLetter = scrollElem.find(`ul > li[data-inc="${rowNumber}"]`).attr('data-letter');

        //lettersElem.find('li').removeClass('current');
        if ( letters.includes(rowLetter) ){
            setSelectedLetter(rowLetter);
            //jQuery(`.letter-list-selection > li#letter-${rowLetter}`).addClass('current').siblings().removeClass('current');
           
        }
        
        //setSelectedLetter(  letters.includes(rowLetter) ? rowLetter : 'a' );
        /*
        console.log('a-'+topScroll, rowNumber, rowLetter);
        console.log(jQuery('#letter-contact-b').height());
        console.log('b-'+jQuery('#letter-contact-b').scrollTop()); */
    }

    useEffect( () => {

        const { showPopup, type } = showPopupContactSearch;
        setProfileShowStatus( PagesAuthentication.getDirectorySearchType() );
        //setProfileShowStatus( type ?? 1);

        if ( showPopup===true ){

            let defaultLocationTemp = defaultLocation;
           /*  let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if ( userLocation!==null ){
                defaultLocationTemp = JSON.parse(userLocation);
            } */
            
            const mainSearchElem = jQuery('.main-search-component');
            
            defaultLocationTemp = { ...defaultLocationTemp,
                                    country_name : mainSearchElem.find('.location-option > li:nth-child(1) > div:nth-child(2)').text(),
                                    state_name : mainSearchElem.find('.location-option > li:nth-child(2) > div:nth-child(2)').text(),
                                    city_name : mainSearchElem.find('.location-option > li:nth-child(3) > div:nth-child(2)').text(),

                                    country_id : mainSearchElem.find('.search-header #country-id').text(),
                                    state_id : mainSearchElem.find('.search-header #state-id').text(),
                                    city_id : mainSearchElem.find('.search-header #city-id').text()
                            };

            setCurrentLocation(defaultLocationTemp);
            
            gettingProfiles(defaultLocationTemp.city_id);
            setCurrentLocation(defaultLocationTemp);
            
            gettingProfiles(defaultLocationTemp.city_id);
            setSelectedLetter(letters[0]);
            //console.log('getting profiles...');
        }

    }, [props.showPopupContactSearch] );


    return (
        <div className="main-contact-search text-lowercase">
            <button type="button" className="btn-close-search" onClick={ () => dispatch( showPopupContactSearchReducer({ showPopup : false }) )} ><span className="ion-ios7-close-empty"></span></button>
            <div className="header-contact-search">
                <div className="container-contact-search">
                    <div className="navigation-location d-flex align-items-center pb-3">
                         <div className="container">
                             <ul className="location-option">
                                <li>
                                    <div>Country</div>
                                    <div className="cursor-pointer" onClick={ () => handleShowPopUp() }>{ currentLocation.country_name }</div>
                                </li>
                                <li>
                                    <div>State</div>
                                    <div className="cursor-pointer" onClick={ () => handleShowPopUp() }>{ currentLocation.state_name }</div>
                                </li>
                                <li>
                                    <div>City</div>
                                    <div className="cursor-pointer" onClick={ () => handleShowPopUp() }>{ currentLocation.city_name }</div>
                                </li>
                            </ul>
                         </div>
                    </div>
                    <div className="breadcrumbs-navigation d-flex align-items-center">
                        <div className="container">
                            <ul className="ul-contact-head btn-option">
                                <li>contacts</li>
                                <li>{ enableSearch===false && <div className="btn-icon btn-magnify"  title="search by name" onClick={ () => handleEnableSearch() }></div>} </li>
                                <li className="pl-4">
                                {
                                    enableSearch===false ?
                                        <ul className="letter-list-selection d-flex align-items-center justify-content-between fs-12">
                                            { 
                                                letters.map( letter =>
                                                    <li key={letter} id={`letter-${letter}`}className={`${selectedLetter===letter ? 'current' : ''}`} onClick={ () =>switchLetter(letter) }>{letter}</li>
                                                ) }
                                        </ul>
                                    :
                                        <div className="d-flex aign-items-center">
                                            <input type="text" placeholder="search contacts" autoFocus  value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  />
                                            <div className="btn-icon btn-remove"  title="close search" onClick={ () => handleCloseSearch() }></div>
                                        </div>
                                }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            
            <div>
                <div className="container py-5">
                    
                    {  
                        gettingProfilesProcessing ?
                            loaderProfileHtml()
                        :
                        <>
                            {
                                 Object.entries(listProfiles).length===0 ?
                                     <div className="text-center text-lowercase no-record-label  no-chats-label " >{ Utilities.labelNoFindResults }</div>
                                 :
                                    <div id="contact-listing" className="contact-listing search-result-list" onScroll={ (e) => onscrollContacts(e) }>
                                        <ul id="profile-listing-contact" className="profile-listing">
                                            {  Object.entries(listProfiles).map( ([key, row] ) => profileListingHtml( key, row) ) }
                                        </ul>
                                    </div>
                            }
                        </>     
                    } 

                </div>
            </div>

            <MainSearchProfile  handleShowProfilePopup={show} handleCloseProfilePopup={handleClose} profileSelected={profileSelected} showStatus={profileShowStatus} closeSearchBox={closeSearchBox}  />

            <CitySearchPopup  showCityPopup={ showCityPopup } handleClosePopUp={ handleClosePopUp } passCityDetail={ passCityDetail } />

        </div>
    )
}

export default ContactSearch;
