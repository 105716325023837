import React, { useEffect, useState } from 'react';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';
import { sendContact } from '../helpers/SendingEmails';
import TitlesLabels from '../helpers/TitlesLabels';

const ContactForm = (props) => {

    let defaultFormValues = {
        name : '',
        email : '',
        message : '',
    }

    const button_name = props.buttonName ?? 'btn-submit-contact';

    const fieldsChecker = [ 'name', 'email', 'message' ];

    const [ formValues, setFormValues ] = useState(defaultFormValues);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if ( fieldName==='name'){
            formValuesTemp = { ...formValuesTemp, ...{ name : value } };
        }else if ( fieldName==='email'){
            formValuesTemp = { ...formValuesTemp, ...{ email : value } };
        }else if ( fieldName==='message'){
            formValuesTemp = { ...formValuesTemp, ...{ message : value } };
        }

        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
        
    }

    const handleSubmitSendContact = async () => {

        Utilities.elementDisabledStatus(button_name, false );

        let errorCounter = 0;
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( fieldsChecker.includes(key) ){
                if ( Utilities.isEmpty(value )){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;

                }else if (key=='email'){
                    if ( !Utilities.isEmail(value )){
                        jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                        errorCounter++;
                    }
                }
            }
        });
    
        if ( errorCounter>0 ){  return false; }

        const emailContentMessageHtml = `
                                        <p>Name: ${formValues.name}</p>
                                        <p>Email: ${formValues.email}</p>
                                        <p>Message: </p>
                                        <p>${formValues.message}</p>
                                    `;

        const passVal = {
            subject : "web - contact us",
            name : formValues.name,
            to_email : "support@beonchat.com",
            message : emailContentMessageHtml
        }
        

        Utilities.elementDisabledStatus(button_name, true );
        
        const responseDetail = await sendContact( passVal );
        console.log(responseDetail);
        
        Utilities.elementDisabledStatus(button_name, false );
        if ( Object.keys(responseDetail).length===0 ){
            Utilities.messagePopup('error', TitlesLabels.general.title_unableToSendDetail)

            return false;
        }

        if ( parseInt(responseDetail.status)===0 ){
            Utilities.messagePopup('error', TitlesLabels.general.title_unableToSendDetail)

        }else{
            Utilities.messagePopup('success', TitlesLabels.general.title_detailHasBeenSent)
            setFormValues(defaultFormValues);
        }

    }


    useEffect(() => {

    }, []);

    return (
        <form className=" text-lowercase form-theme form-theme-dark" autoComplete="off">

            <div className="form-group">
                <input type="text" name="name" className="form-control" placeholder=" " autoComplete="off" required value={ formValues.name }  onChange={ (e) => pushValue( e, 'name') }/>
                <label className="form-label  ">name</label>
            </div>
            <div className="form-group">
                <input type="email" name="email" className="form-control" placeholder=" " autoComplete="off"  required value={ formValues.email }  onChange={ (e) => pushValue( e, 'email') } />
                <label className="form-label  ">email address</label>
            </div>
            <div className="form-group border-0">
                <textarea  name="message" className="form-control textarea-contact-message" placeholder=" " rows={ props.messageInputRows ?? 7} required value={ formValues.message }  onChange={ (e) => pushValue( e, 'message')}></textarea>
                <label className="form-label  ">message</label>
            </div>


            <div className="mt-5 row-">
                <div className="col-lg-6- mx-auto">
                    <button type="button" id={button_name} className="btn-theme-black text-lowercase button-has-loader size-md btn-border-radius" onClick={ () => handleSubmitSendContact() } >submit</button>
                </div>
            </div>

        </form>
    )
}

export default ContactForm;