import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import { Link , useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery';
import CryptoJS from 'crypto-js';
import { isMobile } from 'react-device-detect';

import { showHeader, showFooter, pageTitle, globalNotificationCounter as globalNotificationCounterReducer } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import { sendEmailGeneral } from '../helpers/SendingEmails';
import { loginDetailTemplate } from '../helpers/EmailTemplates';

import ForgotPassword from '../components/popups/ForgotPassword';
import PasswordInput from '../components/PasswordInput';
import { video_links } from '../helpers/Constants';
import LoginInputSelection from './LoginInputSelection';
import LoginConnect from './LoginConnect';
import Footer from '../layout/login/Footer';


const Login = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const siteTitle = process.env.REACT_APP_site_title;
    const marginDeduct = 75;

    const initialMessage = Utilities.labelUsernamePasswordNotMatched;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const NODE_ENV = process.env.NODE_ENV;

    const loginUserDetail = PagesAuthentication.getUserLoggedinDetail()??{};
    
    const defaultLocation = Utilities.defaultLocation();

    const [ formValues, setFormValues ] = useState({ account_id : '0', email : '', password : '', fcm_token : '', utc_date : '' , brower_id : '', phone_code : defaultLocation.country_id.toString(), phone_number : '' });

    const [ defaultResMessage, setDefaultResMessage ] = useState({ status : false, msg : initialMessage });
    const [ showVideoPopup, setShowVideoPopup ] = useState(false);
    const [ loginType, setLoginType ] = useState(0); // 0: selection, 1: email, 2: mobile

    const [ showLoginPopup, setShowLoginPopup ] =  useState(false);
    const [ forgotPasswordPopup, setForgotPasswordPopup] = useState(false);

    const handleShowLoginPopUp = () => {

        if ( Object.keys(loginUserDetail).length>0 ){
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        }

        setFormValues({
            ...formValues,
            email : '',
            password : '',
            phone_number : ''
        });
        setLoginType(0);
        setShowLoginPopup(true);
        Utilities.rootFilterBlur(true);
    }

    const handleCloseLoginPopUp = () => {
        setShowLoginPopup(false);
        Utilities.rootFilterBlur(false);
    }

    const handleForgotPasswordClosePopUp = () => {
        setForgotPasswordPopup(false);
        Utilities.rootFilterBlur(false);
    }
    
    const handleForgotPasswordShowPopUp = () =>{
        handleCloseLoginPopUp();

        setForgotPasswordPopup(true);
        Utilities.rootFilterBlur(true);
    }

 
    const handleShowVideoPopUp = () => {
        setShowVideoPopup(true);
        Utilities.rootFilterBlur(true);
    }

    const handleCloseVideoPopUp = () => {
        setShowVideoPopup(false);
        Utilities.rootFilterBlur(false);
    }


  
    
    const headlineHtml = ( textContent = '' ) => {
        return <h1 className="heading-title heading-title-label font-montserrat-bold mt-5 mb-0 color-theme-green fs-42 text-center">{textContent}</h1>
    }


    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='email'){
            setFormValues({...formValues, ...{ email : value } });
        }else  if ( fieldName==='password'){
            setFormValues({...formValues, ...{ password : value } });
        }else  if ( fieldName==='phone_number'){
            setFormValues({...formValues, ...{ phone_number : value } });
        }
    }


    const showResMessageWarning = () => {
        let timer = setTimeout( () => { 
            setDefaultResMessage({ status : false , msg : '' });
            clearTimeout(timer);
        }, 2000);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        const btn_name = 'btn-login-user';
        Utilities.elementDisabledStatus(btn_name, false );

        setDefaultResMessage( { status : 0, msg : errorMsg} );
        
        let usernameTemp = parseInt(loginType)===2 ? formValues.phone_number : formValues.email;
        const userAccountId = parseInt(formValues.account_id ?? 0);

    
        if ( userAccountId===0 ){
            if ( Utilities.isEmpty(usernameTemp) || Utilities.isEmpty(formValues.password) ){
                setDefaultResMessage({ status :true, msg : Utilities.labelUsernamePasswordMandatory });
                showResMessageWarning();
                Utilities.elementDisabledStatus(btn_name, false );

                return false;
            }
        }


                Utilities.elementDisabledStatus(btn_name, true );
                
                const windowNavigator = window.navigator;
                await axios
                    .post( ApiUrls.userGetByCredentail , {
                                                            ...formValues,
                                                            login_choose : loginType.toString(),
                                                            browser_name: windowNavigator.appVersion.toLowerCase()
                                                    })
                    .then( async (response) => {
                        const resStatus = response.status;

                        Utilities.elementDisabledStatus(btn_name, false );

                        if ( resStatus===200 ){
                            const resData = response.data;
                            const resStatus = parseInt(resData.status);
                            const resLoginType = parseInt(resData.logintype);

                            if ( resStatus===1){

                                if ( parseInt(resData.detail.status)===1 ){
                                    
                            
                                    // REMOVE WELCOME MESSAGE
                                    localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_logintype,
                                        JSON.stringify(resLoginType)
                                    );

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_login,
                                        JSON.stringify(resData.detail)
                                    );
                                    
                                    localStorage.setItem(
                                        process.env.REACT_APP_session_login_started,
                                        Date.now()
                                    );
                                    
                                    Utilities.rootFilterBlur(false);
                                    jQuery('body').removeClass('mb-0');

                                    if ( resLoginType===1 ){
                                        
                                        localStorage.setItem(
                                            process.env.REACT_APP_session_accounts,
                                            JSON.stringify(resData.accounts)
                                        );

                                        // SENDENG EMAIl LOGIN SECURITY
                                        if ( parseInt(resData.new_device)===1 ){
                                            let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
                                            if ( sessionLocation!==null ){
                                                sessionLocation = JSON.parse(sessionLocation);
                                            }

                                            let emailData = { ...resData.detail, location : { ...sessionLocation } };
                
                                            Utilities.elementDisabledStatus(btn_name, true );
                                            await sendEmailGeneral({
                                                api_token : API_TOKEN.toString(),
                                                to_email : formValues.email.toString(),
                                                subject : "Security alert",
                                                message : (await loginDetailTemplate( emailData )).toString(),
                                                name : ""
                                            });
                                            Utilities.elementDisabledStatus(btn_name, false );
                                        }

                                        history.push(`/${UrlSlugs.dashboard}`);

                                    }else if ( resLoginType===2 ){

                                        localStorage.setItem(
                                            process.env.REACT_APP_session_department_active,
                                            JSON.stringify(resData.detail)
                                        );
                                        

                                        localStorage.setItem(
                                            process.env.REACT_APP_session_company_detail,
                                            JSON.stringify(resData.company_detail)
                                        );

                                        localStorage.setItem(
                                            process.env.REACT_APP_session_company_departments,
                                            JSON.stringify(resData.departments)
                                        );

                                        history.push(`/${UrlSlugs.departmentDashboard}`);

                                    }

                                }else{
                                    setDefaultResMessage({ status : true , msg : Utilities.labelAccountNotActive }) ;
                                    showResMessageWarning();
                                }
                            
                            }else if ( resStatus===2 ) {
                                setDefaultResMessage({ status : true, msg : Utilities.labelCouldNotLoginCompanyAccount });
                                showResMessageWarning();

                            }else{
                                setDefaultResMessage({ status : true, msg : initialMessage });
                                showResMessageWarning();

                            }

                        }else{
                            setDefaultResMessage({ status : true, msg : initialMessage});
                            showResMessageWarning();
                        } 
            
                    })
                    .catch( (err) => {
                        setDefaultResMessage({ status : true, msg : initialMessage});
                        showResMessageWarning();

                        Utilities.elementDisabledStatus(btn_name, false );
                    });

       
    }
    

    const udpateFormValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = formValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString(); 
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);
  
        if ( Utilities.isEmpty(fcmToken) ){
            const fcmTokenTemp = localStorage.getItem( process.env.REACT_APP_fs_fcm_token );
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        }else{
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {...formValuesTemp,
                            ...{ 
                                fcm_token : fcmToken.toString(),
                                utc_date : dateFormatted.toString(),
                                brower_id : browserUniqueID.toString(),
                            }
                        };         
        setFormValues(formValuesTemp);
    }


    const handleClick_loginView = ( type = 0 ) => {
        setFormValues({...formValues, ...{ email : '', phone_number : '' } });
        setLoginType(type);
    }

    const pushValuePhonecode = ( providedValue = '') =>{
        setFormValues({...formValues, ...{ phone_code : providedValue } });
    }

    const switchLoginView = () => {
        let htmlView = '';

        if ( loginType===1 ){
            htmlView = <span className="text-blue cursor-pointer" onClick={ () => handleClick_loginView(2) }>login with phone number</span>

        }else if ( loginType===2 ){
            htmlView = <span className="text-blue cursor-pointer" onClick={ () => handleClick_loginView(1) }>login with email</span>
        }

        return htmlView;
    }

    const loginFormHtml = () => {
        return <div className="mt-n4 col-lg-10 mx-auto">
                <div className="mb-4 text-center">
                    <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} className="logo-icon" alt="logo"  width="100"/></Link>
                </div>
                <form className="login-form mx-auto  text-lowercase form-theme" autoComplete="off">
                    
                    <div className="mt-4">
                        <LoginInputSelection loginType={loginType} formValues={formValues}  pushValue={pushValue} pushValuePhonecode={pushValuePhonecode} setLoginView={setLoginType}/>
                    </div>

                    <PasswordInput inputName="password" inputLabel="Password" inputClass="text-center" inputLabelClass="text-center" inputValue={ formValues.password } pushValue={pushValue} />

                    <div className="mt-n2 text-left fs-14">{ switchLoginView() }</div>
                    
                    <div className={ `process-message pt-1 text-center fs-14 text-danger ${ defaultResMessage.status===true ? '' : 'invisible' }` }>{ defaultResMessage.status===true ? defaultResMessage.msg : initialMessage }</div>
                    

                    <div className="d-flex justify-content-center">
                        <LoginConnect loginSocialDetail={loginSocialDetail}/>
                    </div>

                    <div className="button-wrap text-lowercase text-center mt-4">

                        <div className="mb-3"><button type="submit" id="btn-login-user" className="btn-theme-black text-lowercase button-has-loader" onClick={ (e) => submitForm(e) }>Login</button></div>
                        <div className="mb-2"><Link to="#" className="btn-theme-black-text" onClick={ () => handleForgotPasswordShowPopUp() }>forgot id or password?</Link></div>
                        <div className="fs-16 font-gotham-medium">new user? <Link to={`/${ UrlSlugs.signup_accountType}`}><span  className="text-pink fs-16 font-gotham-book">Sign Up</span></Link></div>
                    
                    </div>

                </form>
            </div>
    }

    const loginSocialDetail = ( dataProvided = {}, status = 0 ) => {
        
        if ( Object.keys(dataProvided).length===0 ){
            setDefaultResMessage( { status : true, msg : TitlesLabels.alertMessages.loginInvalidCredential } );
            hideLoginWarningMessage();
            return false;
        }

        // IF NO SOCIAL BUT HAS CREDENTIAL
        if ( parseInt(status)===2 ){
            setDefaultResMessage( { status : true, msg : TitlesLabels.alertMessages.loginAlreadyWithPassword } );

            setFormValues({...formValues, ...{ account_id : dataProvided.account_id ?? '', email : dataProvided.email ?? '' } });
            setLoginType(1);
            hideLoginWarningMessage();

            return false;
        }

        // IF HAVE SOCIAL CREDENTIAL
        setFormValues({...formValues, ...{ account_id : dataProvided.account_id ?? '', email : dataProvided.app_access_token ?? '' } });
        jQuery('#btn-login-user').trigger('click');
    }

   const hideLoginWarningMessage = () => {
        setTimeout( () => {
            setDefaultResMessage({ ...defaultResMessage, status : false });
        }, 5000);
    }

    const scrollToSection = ( hashName = '' ) => {

        if ( hashName==='' ){ return false; }
        hashName = hashName.replace('#','');

        let target = jQuery('#section-'+hashName);
        jQuery('html, body').stop().animate({
            'scrollTop': target.offset().top-marginDeduct
        }, 1000, '', function () {
            //window.location.hash = target;
            return false;  
        });
    }

    useEffect( async () => {

        // REDIRECT to BEONCHAT SITE / TEMPORARY
        if ( NODE_ENV==='production' ){
            window.location.href = UrlSlugs.loginExternal;
            return false;
        }

        dispatch( pageTitle( TitlesLabels.siteTitles.login ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        //PagesAuthentication.checkLoginStatus();

        udpateFormValues();

        dispatch( globalNotificationCounterReducer(0) );

        const windowBrowser = jQuery(window);
        const btnScrollTopElem = jQuery('.btn-arrow-toTop');

        btnScrollTopElem.click( () => {
            jQuery('html, body').stop().animate({
                'scrollTop': 0
            }, 1000);
        });

        windowBrowser.on('scroll', function () {
            let currentScroll  = window.pageYOffset || document.documentElement.scrollTop;
            let bodyHeight = parseInt(parseInt(document.body.scrollHeight)/2)-300;

            if ( currentScroll>bodyHeight ){
                btnScrollTopElem.removeClass('d-none');
            }else{
                btnScrollTopElem.addClass('d-none');
            }

        });


        jQuery('a.scroll-navigation').on('click',function (e) {
            let hashName = this.hash;
            hashName = hashName.replace('#','');
           
            if ( hashName===''){ return false; }

            scrollToSection(hashName);
		});

        const hashProvided = window.location.hash;
        if ( hashProvided!=='' ){
            scrollToSection(hashProvided);
        }
        
    }, []);


    useEffect( async () => {

        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);
            
            if ( parseInt(reqID)>0 ){
                sessionStorage.setItem( process.env.REACT_APP_session_jazenet_request_profileid, reqID );
            }
        }

        udpateFormValues();

    }, [match.params.id] );

    return (
        <>
            <div className="login-page">

                    <div className="login-content">
                        <header className="header-login px-4 px-sm-5 d-flex align-items-center justify-content-between">
                            <div className="logo-wrap">
                                <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-light.svg`} alt="logo"  width="180"/></Link>
                            </div>
                            <div className=" d-none d-lg-block"  style={{ width : 200}}>
                                <div className="btn-theme-green"  onClick={ () => handleShowLoginPopUp() }>
                                    <span className="font-montserrat-bold">{ Object.keys(loginUserDetail).length>0 ? 'dashboard' : 'sign in / up' }</span>
                                </div>
                            </div>
                        </header>
                        <HomeLogin handleShowVideoPopUp={handleShowVideoPopUp} />
                    </div>

                    <section id="section-background" className="py-5 section-bg bgcolor-dark" style={{ backgroundImage: `url(${process.env.REACT_APP_img_folder}/bg-images/landing-background.png)` }}>
                        <div></div>
                        <div className="login-container container py-lg-5 color-theme-white">
                            <div className="px-3 px-sm-0">
                                <Sections title="background">

                                    <p className="line-height-1-3 pb-2 pb-sm-4">People are more connected to each other than they have ever been before. This is all because of the internet and the many social media sites that are out there. Businesses are aware of the benefits of this level of connectivity and the chance it gives them to reach a larger audience and a broader market.</p>

                                    <p className="line-height-1-3 pb-2 pb-sm-4">Given how many deals we make daily, connecting with businesses and service providers should be easier and smoother. But unfortunately, it's not, and it's frustrating and hard to reach online businesses, and it's almost impossible to reach small businesses that aren't online.</p>

                                    <p className="line-height-1-3 pb-2">These difficulties and challenges inspired the establishment of <span className="text-lowercase font-gotham-bold">{siteTitle}</span>. They served as the foundation for our goal of integrating and organising businesses, professionals, and customers on a worldwide platform that is fully inclusive, regulated, consolidated, and integrated.</p>

                                    { headlineHtml(`all business for all people`) }

                                </Sections>
                            </div>
                        </div>
                    </section>

                    <section  id="section-why"  className="py-5">
                        <div className="login-container container py-lg-5">
                            <div className="px-3 px-sm-0">
                                <Sections title="about">

                                <p className="line-height-1-3 pb-2 pb-sm-4"><span className="text-lowercase font-gotham-bold">{siteTitle}</span> is the first part of an integrated and consolidated transactional ecosystem that makes it easy for buyers and sellers to search for, find, and talk to each other.</p>

                                    <p className="line-height-1-3 pb-2 pb-sm-4">It's clear that the ways people talk to each other are changing, and instant messaging is now the most popular way to talk. It is easy to use, and even the smallest and most basic businesses can start using it in a matter of minutes.</p>

                                    <p className="line-height-1-3 pb-2 pb-sm-4">There is nothing else like it on a global scale, and the ease with which it connects people who want to do business is revolutionary. It includes all professionals, all sizes of businesses, and all kinds of professional services, whether local, regional, national, or international.</p>

                                    <p className="line-height-1-3 pb-2">As an SMME, it is amazing how easy it is to go digital and be reachable. For bigger businesses, it gives them more ways to connect that are more specific.</p>

                                    { headlineHtml(`local in emphasis, global in reach`) }

                                </Sections>
                            </div>
                        </div>
                    </section>

                    <section  id="section-business"  className="py-5 section-bg bgcolor-dark" style={{ backgroundImage: `url(${process.env.REACT_APP_img_folder}/bg-images/landing-business.png)` }}>
                        <div className="login-container container py-lg-5 color-theme-white">
                            <div className="px-3 px-sm-0">
                                <Sections title="business / professionals">

                                    <p className="line-height-1-3 pb-2 pb-sm-4 pt-3"><span className="text-lowercase font-gotham-bold">{siteTitle}</span> permits any company, whether a home-based business or a large corporation, to customise its profile based on its needs.</p>
                                    <p className="line-height-1-3 pb-2 pb-sm-4">No specialised technology is needed to establish, maintain, and manage profiles.</p>
                                    <p className="line-height-1-3 pb-2 pb-sm-4">Customers can search for businesses by name, category, or keyword.</p>
                                    <p className="line-height-1-3 pb-2 pb-sm-4">Large businesses can establish direct communication for consumers to reach a specific employee or department.</p>
                                    <p className="line-height-1-3 pb-2 pb-sm-4">Customised system forms like reservation, appointment, complaint, etc</p> 
                                    <p className="line-height-1-3 pb-2 pb-sm-4">Advertising to targeted customers</p>
                                    <p className="line-height-1-3 pb-2 pb-sm-4">It gives any company international reach.</p>
                                    <p className="line-height-1-3 pb-2">A distinct internal chat platform for team members.</p>

                                { headlineHtml(`business communication simplified`) }

                                </Sections>
                            </div>
                        </div>
                    </section>
                

                    <section  id="section-consumer"  className="py-5">
                        <div className="login-container container py-lg-5">
                            <div className="px-3 px-sm-0">
                                <Sections title="consumers / end users">
                                    
                                    <p className="line-height-1-3 pb-2 pb-sm-4">Existing or future customers share a fundamental need: a fast, hassle-free connection with a seller to ask questions, place orders, make reservations, or voice complaints. That is possible using <span className="text-lowercase font-gotham-bold">{siteTitle}</span>.</p>

                                    <p className="line-height-1-3 pb-2 pb-sm-4">Business communication should be accepted as a part of modern life and done "on the move," much like personal instant messaging. So it shouldn't take forever to get a call operator or a BOTS.</p>

                                    <p className="line-height-1-3 pb-2 pb-sm-4">Protecting end users from spam and unwanted advertisements is <span className="text-lowercase font-gotham-bold">{siteTitle}</span>'s top priority. Receiving promotional materials is only allowed if a customer follows a firm, as the consumer has the exclusive right to connect to a business. They hold the right to stop following a business at any moment.</p>

                                    <p className="line-height-1-3 pb-2">Although <span className="text-lowercase font-gotham-bold">{siteTitle}</span> lets you have both your personal and business profiles in the same app, it keeps chats distinct and separate and lets you switch between them without any noticeable lag.</p>

                                    {/* { headlineHtml(`local in emphasis, global in reach`) } */}
                                    { headlineHtml(`the world just became smaller`) }

                                </Sections>
                            </div>
                        </div>
                    </section>


                    <ForgotPassword forgotPasswordPopup={forgotPasswordPopup}  handleForgotPasswordClosePopUp={handleForgotPasswordClosePopUp}/>

                    <Modal id="popup-watch-video"  show={ showVideoPopup } onHide={ handleCloseVideoPopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" contentClassName="bg-transparent">
                        <Modal.Header closeButton className="border-0 popup-watch-video-header"></Modal.Header>
                        <Modal.Body className="p-0 bg-black font-gotham-medium text-center fs-20  popup-watch-video-body">
                                
                            <iframe width="100%" height="450" class="popup-watch-video-iframe" src={`${video_links.beonchat_youtube}?autoplay=1`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </Modal.Body>
                    </Modal>

                    <Modal id="popup-app-login"  show={ showLoginPopup } onHide={ handleCloseLoginPopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered >
                        <Modal.Header closeButton className="border-0"></Modal.Header>
                        <Modal.Body className="pb-5 pt-3 px-md-5 bg-white text-center fs-20 line-height-1-2 ">
                            
                               { loginFormHtml() }

                        </Modal.Body>
                    </Modal>
                    
            </div>

            <Footer />

            <div className="btn-arrow-toTop d-none"></div>
       </>
    )
}

export default Login;


const Sections = ( props ) => {
    return (
        <div className="font-gotham-book fs-18">
            <h2 className="heading-title font-montserrat-bold mb-4 color-theme-green fs-35">{props.title??''}</h2>
            {props.children??''}
        </div>
    )
}

const AppLinkHtml = () => {
    return (
        <>
            <a href={ UrlSlugs.link_appStore } target="_blank"><img src={`${process.env.REACT_APP_img_folder}/icons/buttons/appstore-icon.svg`} width="140" className="my-2 m-lg-0"/></a>
            <a href={ UrlSlugs.link_playStore } target="_blank" className="ml-lg-3"><img src={`${process.env.REACT_APP_img_folder}/icons/buttons/playstore-icon.svg`} width="140" className="my-2 m-lg-0"/></a>
            {/* <a href={ UrlSlugs.link_appGallery } target="_blank"><img src={`${process.env.REACT_APP_img_folder}/icons/buttons/appgallery-icon.svg`} width="140"/></a> */}
        </>
    )
}

const HomeLogin = ( props ) => {
    return (
            <div className="login-container-wrapper d-lg-flex align-items-center  overflow-hidden- position-relative ">

                <div className="mobile-handling"><img src={`${process.env.REACT_APP_img_folder}/hand-mobile-app.png`}  /></div>
                <div  className="logo-head-middle"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} alt="logo" width="120"/></div>

                <div className="login-container container d-lg-flex align-items-center pt-4 pt-sm-5 pt-lg-3 py-lg-5 px-4">
                    <div className="row  w-100 mx-0 ">

                        {/* ---------------------Desktop--------------------------*/}
                        <div className="d-lg-block col-lg-6 left-content mx-0">
                            
                            <div className="headline-title-wrap text-white position-relative">
                               
                                <div className="headline-title font-montserrat-extrabold fs-85" style={{ letterSpacing : '-5px'}}>
                                    <div className="line-height-1-1">live the</div>
                                    <div className="line-height-1-1">r<span className="color-theme-green">evolution</span></div>
                                </div>

                                <div className="font-montserrat-medium fs-30 line-height-1-1 mt-2 col-lg-10 px-0 color-theme-gray" style={{ letterSpacing : '-0.8x'}}>the new frontier of <span className="d-none d-lg-inline"><br /></span>business communication</div>
                            </div>

                            <div className={`mt-5 pt-5 btn-front-options d-none d-lg-flex  px-0 col-lg-${isMobile===true ? '10' : '7'}`}>
                                <div className="btn-theme-green"  onClick={ () => props.handleShowVideoPopUp()  }>watch now</div>
                                <a  href="#background" className="btn-theme-outline-white scroll-navigation">more</a>
                            </div>

                            <div className="download-links pt-5 mt-5 pb-4  color-theme-gray d-none d-lg-block">
                                <div className="mb-2">available in</div>
                                <AppLinkHtml />
                            </div>

                        </div>

                        <div className="col-lg-6 row mx-0 pt-2 pt-sm-5 pt-lg-0 px-0">
                            <div className="col-3 d-lg-none position-relative">

                                <div className="btn-device-watch"  onClick={ () => props.handleShowVideoPopUp()  }><span className="fs-22">watch<br />now</span></div>
                                
                                <div className="position-absolute tap-connect">
                                    <div className="mb-2 text-theme-gray">tap to connect</div>
                                    <AppLinkHtml />
                                </div>
                                
                            </div>
                            
                            <div className="mx-auto col-9 px-0 px-lg-4">

                                <div className="d-lg-none pt-5 mt-3 pb-sm-0 pt-sm-0 pt-md-4 mt-sm-4">
                                    <div className="text-center">
                                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/hand-mobile-app.png`}  className="mobile-hand" alt="logo"  width="100%"/></Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}