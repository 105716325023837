import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import jQuery from 'jquery';
import firebase from 'firebase';

import Utilities from '../../helpers/Utilities';
import QueryUtilities from '../../helpers/QueryUtilities';
import DocumentUtilities from '../../helpers/DocumentUtilities';
import ChatUtilities from '../../helpers/ChatUtilities';

import { passDocumentDetail as passDocumentDetailReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';

const DocumentSend = () => {

    const dispatch = useDispatch();
    const maxCharacters = 200;

    const firebaseConfig = ChatUtilities.firebaseConfig();
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
    const FIRESTORE = firebase.firestore(); 

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const passDocumentDetail = useSelector( state => state.passDocumentDetail);

    const defaultFormValues = {  message : '' };

    const [ showPopup, setShowPopup ] = useState(false);
    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ isProcessing, setIsProcessing ] = useState(false);
    const [ receiverProfileDetail, setReceiverProfileDetail ] = useState({});
    const [ accountDetail, setAccountDetail ] = useState({});
    const [ attachmentDetail, setAttachmentDetail ] = useState({});

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        
        let formValuesTemp = formValues;

        if ( fieldName==='message'){
            formValuesTemp = { ...formValuesTemp, ...{ message : value } };

        }
        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
    }

    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( passDocumentDetailReducer('') );
    }


    const getDocumentDetail = async ( documentPassValue = {} ) => {

        let convers_detail = documentPassValue.convers_detail;
        let profileDetail = {};

        if ( Object.entries(convers_detail).length>0 ){
            profileDetail = {
                                account_id : convers_detail.account_id.toString() ,
                                account_type : convers_detail.account_type.toString(),
                                logo_url : convers_detail.logo.toString(),
                                name : convers_detail.name.toString(),
                                title : convers_detail.title.toString(),
                            };
        }else{
            
            if ( !( documentPassValue.hasOwnProperty('department_id') ) ){
                const profileDetailRes = await QueryUtilities.getProfileDetail( documentPassValue.account_id );
                
                let team_company_id = 0;
                if ( profileDetailRes.hasOwnProperty('team_company_id') ){
                    team_company_id = profileDetailRes.team_company_id;
                }

                profileDetail = {
                    account_id : profileDetailRes.account_id.toString() ,
                    account_type : profileDetailRes.account_type.toString(),
                    logo_url : profileDetailRes.logo_url.toString(),
                    name : profileDetailRes.name.toString(),
                    title : profileDetailRes.title.toString(),
                    team_company_id : team_company_id.toString(),
                    department_id : '0'
                };

            }else{

                const departmentDetailRes = await QueryUtilities.getDerpartmentDetail( documentPassValue.account_id,  documentPassValue.department_id );
                profileDetail = {
                    account_id : departmentDetailRes.detail.account_id.toString() ,
                    account_type :  departmentDetailRes.company.account_type.toString(),
                    logo_url :  departmentDetailRes.company.logo_url.toString(),
                    name : departmentDetailRes.company.name.toString(),
                    title : departmentDetailRes.detail.department_name.toString(),
                    team_company_id : '0',
                    department_id : documentPassValue.department_id.toString()
                };
            }

        }

        setReceiverProfileDetail(profileDetail);

        const documentList = await QueryUtilities.getDocumentInfo( documentPassValue.document_id );
        
        let documentDetailTemp = {};

        if ( Object.entries(documentList).length>0 ){
            const documentDetail = documentList[documentPassValue.document_id];

            const attachmentDetail = documentDetail.attachment;
            documentDetailTemp = attachmentDetail;
            
            /* let fileExtension = attachmentDetail.file_name.toString().split('.').slice(-1).pop();
            fileExtension = fileExtension.toLowerCase(); */
        }
       
        setAttachmentDetail(documentDetailTemp);
        setIsProcessing(false);
    }


    const sendDocument = async () => {

        if ( Utilities.isEmpty(formValues.message) ){

            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.messageShouldNotEmpty,
                overlayClassName : 'error',
                buttons: [
                    { 
                        label: 'ok', 
                        onClick: () => {
                            jQuery('#default_message').focus();
                        }
                    }
                ]
            });
            
            return false;

        }else{
            
            const charactersCounter = Utilities.charactersCounter(formValues.message);
            if ( parseInt(charactersCounter)>parseInt(maxCharacters) ){
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!` );
                return false;
            }

        }

        const button_name = 'btn-submit-document';
        Utilities.elementDisabledStatus(button_name, true );

        let conversation = {};
        if ( passDocumentDetail.hasOwnProperty('convers_detail') &&  Object.entries(passDocumentDetail.convers_detail).length>0 ){
            conversation = passDocumentDetail.convers_detail;

        }else{
            
            const contactRequestValues = ChatUtilities.contactRequest( accountDetail, receiverProfileDetail, parseInt(receiverProfileDetail.department_id || 0) , 0 );
            const conversationDetailRes = await QueryUtilities.getConversationDetail( contactRequestValues.url, contactRequestValues.values  );
            
            conversation = conversationDetailRes.result.bizchat_conversation.conversation_list;
        }
    
        ChatUtilities.saveAccountsConversationDetail(conversation );

        //  COPY DOCUMENT TO CONVERSATION FOLDER

        let mainAccountId = accountDetail.account_id;

        let bizcomplusAccountId = sessionStorage.getItem(process.env.REACT_APP_session_plus_account_id);
        if ( bizcomplusAccountId!==null ){
            bizcomplusAccountId = JSON.parse(bizcomplusAccountId);
            mainAccountId = bizcomplusAccountId;
        }

        const passValues = {
            account_id : mainAccountId.toString(),
            convers_id : conversation.convers_id.toString(),
            attachment : attachmentDetail
        }

        const documentRes = await DocumentUtilities.copyFileToConversFolder(passValues);

        if ( parseInt(documentRes.status)===1 ){

            // SEND FILE
            ChatUtilities.sendMessage( FIRESTORE, conversation,  accountDetail, receiverProfileDetail , documentRes.message_type.toString(), documentRes.file_url.toString(), {} );

            // SEND MESSAGE
            ChatUtilities.sendMessage( FIRESTORE, conversation,  accountDetail, receiverProfileDetail , 1, formValues.message.toString(), {} );

            setTimeout( () => {
                
                setFormValues(defaultFormValues);
                Utilities.elementDisabledStatus(button_name, false );

                handleClosePopup();

                const goBackUrl = process.env.REACT_APP_bizcomplus_url+'/jazedocument/archives';

                confirmAlert({
                    title: '',
                    message: TitlesLabels.alertMessages.documentSentSuccessfully,
                    overlayClassName : 'normal-font-style',
                    onClickOutside : () => {
                        window.location.href = goBackUrl;
                    },
                    onKeypressEscape: () => {
                        window.location.href = goBackUrl;
                    },
                    buttons: [
                        {
                            label: 'ok',
                            onClick: () => { window.location.href = goBackUrl; }
                        }
                    ]
                }); 


            }, 500 );

        }else{
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotSendFileTryAgain );
            Utilities.elementDisabledStatus(button_name, false );

        }

    }
    
    useEffect( () => {

        if (  parseInt( Object.keys(passDocumentDetail).length )>0 ){

            let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loginBasicInfo!==null ){
                loginBasicInfo = JSON.parse(loginBasicInfo);

                setIsProcessing(true);
                setShowPopup(true);

                setAccountDetail(loginBasicInfo);
                getDocumentDetail(passDocumentDetail);
            }

        }else{
            setShowPopup(false);
            setIsProcessing(false);
        }
        
    }, [passDocumentDetail] );

    return (

       <Modal id="popup-document-send"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme modal-dialog-document-send"  keyboard={ true }>
            <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline border-bottom-0 pt-4">send document</Modal.Header>
            <Modal.Body className="pt-2 pb-5">
                    {
                        isProcessing===true ?
                            <div className="content-loader animate"></div>
                        :
                            <div className="col-10 px-3 mx-auto  form-theme text-lowercase">

                                <div className="pb-4">

                                    <div className="d-flex align-items-center profile-receiver pb-3">
                                        <div className="logo-size-40" style={{ backgroundImage : `url(${receiverProfileDetail.logo_url ?? DEFAUL_AVATAR})`}}></div>
                                        <div className="ml-3">
                                            <div className="font-gotham-medium-14 ellipsis-1">{receiverProfileDetail.name}</div>
                                            <div className="font-gotham-light-12 ellipsis-1">{receiverProfileDetail.title}</div>
                                        </div>
                                    </div>

                                    <div className="my-4 preview-document" style={{ backgroundImage : `url(${ parseInt(attachmentDetail.file_type)===2 ? attachmentDetail.file_thumb.toString() : '' })`}}>
                                        { 
                                            parseInt(attachmentDetail.file_type)!==2 && <div className="document-file-icon"></div>
                                        }
                                    </div>
                                    
                                    <div className="form-group mt-3">
                                        <textarea id="default_message" name="message" className="form-control  px-0" rows="3" placeholder=" " value={ formValues.message } onChange={ (e) => pushValue(e, 'message') }></textarea>
                                        <label className="form-label px-0">type here</label>
                                    </div>
                                
                                     <div className="text-color-gray text-right mt-n2">{maxCharacters} characters</div>
                                    
                                </div>

                                <div className="button-group-boolean mt-3">
                                    <button type="button" className="btn-theme-black" onClick={ () => handleClosePopup() }>cancel send</button>
                                    <button type="button" id="btn-submit-document"  className=" btn-theme-black button-has-loader" onClick={ () => sendDocument() }>send</button>
                                </div>

                            </div>
                    }
                    
                
            </Modal.Body>
        </Modal>
    )
    
}

export default DocumentSend;
