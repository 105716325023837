import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import ApiUrls from '../helpers/ApiUrls';
import Utilities from '../helpers/Utilities';
import TitlesLabels from '../helpers/TitlesLabels';
import UrlSlugs from '../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle } from '../actions';


const SwitchFromPlus = ({match}) => {

    const dispatch = useDispatch();

    const goBack = ( message = '' ) => {

        const goBackurl = process.env.REACT_APP_bizcomplus_url;

        confirmAlert({
            title: '',
            message: message.toString(),
            overlayClassName : 'normal-font-style',
            onClickOutside : () => {
                window.location.href = goBackurl;
            },
            onKeypressEscape: () => {
                window.location.href = goBackurl;
            },
            buttons: [
                {
                    label: 'go back',
                    onClick: () => {  window.location.href = goBackurl; }
                }
            ]
        }); 
    }

    const getAccountLoginDetail = ( passValues = {} ) => {

        if ( Object.keys(passValues).length>0 ){

            const errorMsg = process.env.REACT_APP_message_error_process;
            const formValues = { 
                account_id : passValues.account_id.toString(),
                department_id : passValues.department_id.toString(),
                fcm_token : passValues.fcm_token.toString(),
                utc_date : passValues.utc_date.toString(),
                brower_id : passValues.brower_id.toString(),
                allow_access : 1 
            };

            axios
            .post( ApiUrls.userGetByCredentail , formValues )
            .then( response => {
                const resStatus = response.status;
                
                if ( resStatus===200 ){
                    const resData = response.data;
                    const resStatus = parseInt(resData.status);
                    const resLoginType = parseInt(resData.logintype);

                    if ( resStatus===1){
                        
                        if ( parseInt(resData.detail.status)===1 ){
                
                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );


                            let docID = match.params.docid;
                            let acctID = match.params.acctid;
                            
                            if ( typeof docID!=='undefined' && typeof acctID!=='undefined' ){
                                window.location.href = `/${UrlSlugs.sendDocument}/${docID}/${acctID}`;

                                sessionStorage.setItem(
                                    process.env.REACT_APP_session_plus_account_id,
                                    JSON.stringify(atob(acctID))
                                );

                            }else{
                                window.location.href = `/${ UrlSlugs.dashboard }`;
                            }

                        }else{
                            goBack( Utilities.labelAccountNotActive );
                        }

                    }else if ( resStatus===2 ) {
                        goBack( Utilities.labelCouldNotLoginCompanyAccount );

                    }else{
                        goBack( Utilities.labelUsernamePasswordNotMatched );

                    }

                }else{
                    goBack(errorMsg);
                } 

            })
            .catch( (err) => {
                goBack('Could not get profile detail!');

            });

        }
        
    }
    
    useEffect( async () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.switchAccount ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        //PagesAuthentication.checkLoginStatus();

        const accounIDRequest = parseInt(match.params.id);
        const deptIDRequest = parseInt(match.params.deptid);
        //const cityIDRequest = parseInt(match.params.cityid);
        
        const timeUTC = moment.utc();

        const fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString(); 
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);
        
        const formValuesTemp = {
                            account_id : accounIDRequest,
                            department_id : deptIDRequest,
                            fcm_token : fcmToken.toString(),
                            utc_date : dateFormatted.toString(),
                            brower_id : browserUniqueID.toString(),
                        };

        
        if ( accounIDRequest>0 ){
            getAccountLoginDetail(formValuesTemp);
        }

        /* if ( deptIDRequest>0 ){
            setDeptID(deptIDRequest);
        }

        if ( cityIDRequest>0 ){
            setCityID(cityIDRequest);
        } */

    }, []);


    return (
       <div className="switch-account-loader">

           <div>
               <div>
                   <div className="icon-loader"></div>
                   <div>{ Utilities.labelPleaseWait }</div>
                </div>
           </div>

       </div>
    )
}

export default SwitchFromPlus;