import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChatUtilities from '../helpers/ChatUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { getMembersGroups, joinInvitationResponse } from '../helpers/QueryChatGroups';
import Utilities from '../helpers/Utilities';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';
import { booleanChatReInitializeConversation as booleanChatReInitializeConversationReducer, chatSidebarBlock as chatSidebarBlockReducer , passConversDetail as passConversDetailReducer } from '../actions';


const ChatSettingsCompanyGroupsPendingMembers = (props) => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;

    const conversId = parseInt(props.conversID ?? 0);
    const conversationDetail = ChatUtilities.getAccountConversationDetail(conversId);
    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
    
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ showEmpty, setShowEmpty ] = useState(false);


    const defaultValues = { title : '' };
    const [ formValues, setFormValues ] = useState(defaultValues);
    const [ memberSelected, setMemberSelected ] = useState({});
    const [ showPopup, setShowPopup ] = useState(false);
    
    const handleClosePopUp = () => {
        setShowPopup(false);
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else if ( fieldName==='title' ){

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';
        
        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const handelViewMemberInvitation = ( profileProvided = {} ) => {
        if ( Object.keys(profileProvided).length>0 ){
            setMemberSelected(profileProvided);
            setShowPopup(true);
        }
    }


    const acceptRejectJoinInvitationCompanyGroups = async ( actionName = '' ) =>{
        if ( Object.keys(memberSelected).length>0 && ! Utilities.isEmpty(actionName) ){

            const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();

            // flag  : 1 : accept, 2 : reject
            // result_flag  : 0 : conversation screen, 1 : pending list

            const paramInvitation = {
                api_token : TOKEN.toString(),
                account_id : (userLoginDetail.account_id ?? 0).toString(),
                group_id : (memberSelected.group_id ?? '0').toString(),
                conversa_id : (memberSelected.convers_id ?? '0').toString(),
                profile_id : (memberSelected.account_id ?? '0').toString(),
                flag : actionName==='accept' ? '1' : '2',
                result_flag : "1"
            }


            const button_name = `btn-submit-invitation-${actionName}`;

            Utilities.elementDisabledStatus(button_name, true );
            const responseDB = await joinInvitationResponse(paramInvitation);
            Utilities.elementDisabledStatus(button_name, false );

            handleClosePopUp();

            if ( Object.entries(responseDB).length===0 ){ 
                Utilities.messagePopup('error', errorMsg );
                return false; 
            }

                if ( parseInt(responseDB.success)===0 ){ 
                    Utilities.messagePopup('error', responseDB.errorMessage );
                    return false; 
                }

                Utilities.messagePopup('success', responseDB.successMessage );

                //RELOAD MEMBER LIST
                getMembers();

                // UPDATE CHAT CONVERSATIONS
                let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( parseInt(paramInvitation.conversa_id) );

                if ( actionName==='accept' ){
                    const groupMembers_count = parseInt(currentConversationDetailTemp.group_members_count ?? 0) + 1;
                    let groupMmebers = [];

                    if ( ! Utilities.isEmpty(currentConversationDetailTemp.group_members) ){
                        groupMmebers = [...currentConversationDetailTemp.group_members.split(",")];
                        groupMmebers.push(memberSelected.account_id.toString());
                    }

                    currentConversationDetailTemp = { 
                                                    ...currentConversationDetailTemp,
                                                    group_members : groupMmebers.join(","),
                                                    group_members_count : groupMembers_count.toString()
                                                };
                }

                    let groupPendingMembers_count = parseInt(currentConversationDetailTemp.pending_group_members_count ?? 0) - 1;
                    groupPendingMembers_count = groupPendingMembers_count<0 ? 0 : groupPendingMembers_count;

                    currentConversationDetailTemp = { 
                                        ...currentConversationDetailTemp,
                                        pending_group_members_count : groupPendingMembers_count.toString()
                                    };

                    ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);

                    dispatch( passConversDetailReducer(currentConversationDetailTemp) );
                    dispatch( booleanChatReInitializeConversationReducer( new Date().toString() ) ) ;
                    
        }
    }

    const getMembers = async () => {

        // flag :  0: all, 1: active, 2: suspend, 3: pending
        const paramMembers = {
            api_token : TOKEN.toString(),
            account_id : (userLoginDetail.account_id ?? 0).toString(),
            group_id : (conversationDetail.id ?? 0).toString(),
            flag : "3"
        }

        const responseMembers = await getMembersGroups(paramMembers);
        if ( Object.entries(responseMembers).length===0 ){ 
            setShowEmpty(true);
            return false; 
        }

            if ( parseInt(responseMembers.success)===0 ){ 
                setShowEmpty(true);
                return false; 
            }

            const memberListRes = responseMembers.result.conversation_members_list.members_list ?? [];
            setListProfiles(memberListRes);

            if ( memberListRes.length===0 ){ 
                setShowEmpty(true);
            }
           
    }   

    useEffect( () => {
        getMembers();
    }, [] );

      
    return (
        <>
            <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
                {
                    Object.keys(listProfiles).length>0 ? 
                        <>
                            <div className="form-group">
                                <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                                <label className="form-label">Search</label>
                            </div>
                            

                            <ul id="listing-profiles-member" className="list-members company-groups-member-list mt-2 cursor-pointer">
                                    {
                                        Object.entries(listProfiles).map( ([key, row] ) =>
                                            <li key={key} data-keyword={row.name.toLowerCase() } onClick={ () => handelViewMemberInvitation(row) }>
                                                <div><div className="profile-image" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo ) }}>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                                <div>
                                                    <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                    <div className="profile-label">{row.title}</div>
                                                </div>
                                                <div>
                                                { Utilities.formatDateList(row.message_date) }
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                        </>
                    :
                        showEmpty===false ? <div className="content-loader animate"></div> : <div className="text-center text-lowercase no-record-label ">{ Utilities.labelProfileAvailable }</div> 
                } 
            </div>

            <Modal id="popup-company-groups-members-pending" show={ showPopup } onHide={ handleClosePopUp } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md" >
                <Modal.Header closeButton className="border-0 pt-4">
                    <div className="d-flex align-items-center pt-2 px-4">
                        <div className="logo-size-65" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(memberSelected.logo) })`, backgroundColor: Utilities.bgcolorHideInitialBg(memberSelected.logo ) }}>{ Utilities.showHideInitial(memberSelected.logo, memberSelected.name) }</div>
                        <div className="ml-3 font-gotham-book fs-16">
                            <div>{ memberSelected.name }</div>
                            <div className="text-gray fs-14">{ memberSelected.title }</div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-5">
                        
                        <div>
                            <div className="text-blue-placehoder">message</div>
                            <div>{ memberSelected.reason }</div>

                            <div className="button-group-boolean pt-5 pb-4">
                                <button type="button" id="btn-submit-invitation-reject" className="btn-theme-black button-has-loader" onClick={ () => acceptRejectJoinInvitationCompanyGroups('reject') }>reject</button>
                                <button type="button" id="btn-submit-invitation-accept"  className=" btn-theme-black button-has-loader" onClick={ () => acceptRejectJoinInvitationCompanyGroups('accept') } >accept</button>
                            </div>
                        </div>

                </Modal.Body>   
            </Modal>

        </>

    )
}

export default ChatSettingsCompanyGroupsPendingMembers;
