
class UrlSlugs {
    
    login = `login`
    logout = `logout`
    loginExternal = `https://beonchat.com`

    uploadLogo = `upload-logo`
    personalInfo = `personal-info`
    personalLogin = `personal-login`
    socialConnect = `social-connect`
    companyInformation = `company-information`

    link_appStore = `https://apps.apple.com/us/app/beonchat/id1626232880`
    link_playStore = `https://play.google.com/store/apps/details?id=com.app.beonchat`
    link_appGallery = `https://appgallery.huawei.com/app/C106473397`

    page_termsConditions = `https://beonchat.com/help/terms-of-use.php` //`page/terms-conditions`
    page_termsConditionsClean = `https://beonchat.com/help/terms-of-use.php` 
    page_privacyPolicy = `https://beonchat.com/help/privacy-policy.php` //`page/privacy-policy`
    page_disclaimer = `https://beonchat.com/help/disclaimer.php`
    page_enduserLicense = `https://beonchat.com/help/enduser-licenseagreement.php`
    page_siteMap = `https://bizcomplus.com/page/site-map` //`page/site-map`

    page_licenses = `https://beonchat.com/help/terms-of-use.php` 
    page_licensesClean = `https://beonchat.com/help/terms-of-use.php` 
    
    
    page_helpCenter = `https://beonchat.com/help/center.php` //`https://beonchat.com/help-center`
    page_bizcom_about = `https://beonchat.com/#why`
    page_bizcom_careers = `https://bizcomplus.com/page/careers`
    page_bizcom_getInTouch = `https://beonchat.com/#contact`

    
    page_beonchat = `https://beonchat.com`
    page_talkToUsUrl = `https://beonchat.com`
   
    pagePassword_new = `password/new`

    signup_accountType = `signup/account-type`
    signup_persoanlInfo = `signup/personal-info`
    signup_personalCondition = `signup/personal-condition`
    signup_personalLogin = `signup/personal-login`
    signup_verification = `signup/verification`
    signup_uploadLogo = `signup/upload-logo`
    signup_socialConnect = `signup/social-connect`

    signup_companyInformation = `signup/company-information`
    signup_companyKeywords = `signup/company-keywords`
    signup_companyCondition = `signup/company-condition`
    signup_companyAddress = `signup/company-address`
    signup_companyLogo = `signup/company-logo`
    signup_teamMember = `signup/team-member`

    settings = `settings`
    settings_shareQrCode = `settings/share-qrcode`
    settings_specialMessages = `settings/special-messages`
    settings_importantInformation = `settings/important-information`
    settings_linkedDepartments = `settings/linked-departments`
    settings_manageProfile = `settings/manage-profile`
    settings_manageProfileSecurity = `settings/manage-profile-security`
    settings_profileDeactivate = `settings/profile-deactivate`
    settings_linkedProfiles = `settings/linked-profiles`
    settings_linkedProfiles_assign = `settings/linked-profiles/assign`
    settings_divertProfiles_assign = `settings/divert-profiles/assign`
    settings_manageDepartments = `settings/manage-departments`
    settings_department_add = `settings/department/add`
    settings_department_edit = `settings/department/edit`
    settings_manageTeam = `settings/manage-team`
    settings_team_add = `settings/team/add`
    settings_team_edit = `settings/team/edit`
    settings_manageProfileCompany = `settings/manage-profile-company`
    settings_manageWorkProfile = `settings/manage-work-profile`
    settings_manageCompanyGroups = `settings/manage-company-groups`

    settings_bizcomAssist = `settings/bizcom-assist`
    settings_bizcomAssist_termsPrivacy = `settings/bizcom-assist/terms-privacy`
    settings_bizcomAssist_licenses = `settings/bizcom-assist/licenses`

    settings_specialPermissions = `settings/profile-special-permissions`

    settings_followersNotices = `settings/followers-notices`
    settings_notificationManagement = `settings/notification-management`
    settings_notificationSendNotification = `settings/notification-form`
    
    settings_notificationFollowers = `settings/notification-followers`
    settings_notificationFollowersSuspend = `settings/notification-followers-suspend`
    settings_notificationFollowersRemove = `settings/notification-followers-remove`

    settings_notifications = `settings/notifications`
    settings_notificationsFollowSettings = `settings/notifications-follow-settings`

    dashboard = `dashboard`
    dashboardDirectConnect = `dashboard/direct-connect`
    departmentDashboard = `departmentdashboard`
    departmentDashboardDirectConnect = `departmentdashboard/direct-connect`

    chat = `chat`
    chatConversation = `chat-conversation`
    chatGroupsConversation = `chatgroups-conversation`
    switchFromPlus = `switch-from-plus`
    departments = `departments`
    departments_chatViewOnly = `departments/chat-view-only`
    teamMembers = `team-members`
    companyGroups = `company-groups`
    internalChat = `internal-chat`
    documents = `documents`
    sendDocument = `send-document`
    
    calendarDiary = `calendar-diary`
    directAccess = `direct-access`
}


export default new UrlSlugs();

