import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
//import jQuery from 'jquery';
//import AppleLogin from 'react-apple-login';
import { FacebookProvider, LoginButton } from "react-facebook";
//import InstagramLogin from 'react-instagram-login';

//import Utilities from '../helpers/Utilities';
import {  showHeader, showFooter, pageTitle } from '../actions';

import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';

const SocialConnect = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const siteTitle = process.env.REACT_APP_site_title; 
    const imgFolder = process.env.REACT_APP_img_folder; 

    const social_gogle_client_id = process.env.REACT_APP_social_google_client_id; 
    const social_gogle_plugin_name = process.env.REACT_APP_social_google_plugin_name; 

    //const social_apple_plugin_name = process.env.REACT_APP_social_apple_plugin_name; 
    /* const social_apple_client_id = process.env.REACT_APP_social_apple_client_id; 
    const social_apple_redirect_url = process.env.REACT_APP_social_apple_redirect_url;  */

    const social_facebook_app_id = process.env.REACT_APP_social_facebook_app_id; 
    //const social_facebook_redirect_url = process.env.REACT_APP_social_facebook_redirect_url; 

    /* const social_instagram_app_id = '577509640826258'; //process.env.REACT_APP_social_instagram_app_id; 
    const social_instagram_redirect_url = '';//process.env.REACT_APP_social_instagram_redirect_url;  */

    
    const localstorage_social_signup_connect = process.env.REACT_APP_session_signup_social_connect; 

    const classSocialWrap = `mb-3 cursor-pointer position-relative overflow-hidden`;
    const defaultProfileDetail = {type : '0', name : '', first_name : '', last_name : '', email : '', imageUrl : '', token : '', app_id : ''};
    let useDetailTemp = {};

    const [ useDetail, setUserDetail ] = useState(defaultProfileDetail);

    const redirectToInfoPage = ( dataProvied = {} ) => {
        setUserDetail(dataProvied);
        localStorage.setItem(  localstorage_social_signup_connect, JSON.stringify(dataProvied) );

        history.push(UrlSlugs.personalInfo);
    }
    
    // 1: GOOGLE, 2: APPLE, 3 : FACEBOOK, 4 : INSTAGRAM
    /// GOOGLE 1 ///////////////////////////////////////////
    const google_onSuccess = (response) => {
        //console.log('Success:', response);

        useDetailTemp = {
            ...useDetail,
            type : '1',
            name: response.profileObj.name ?? '',
            first_name: response.profileObj.givenName ?? '',
            last_name: response.profileObj.familyName ?? '',
            email: response.profileObj.email ?? '',
            imageUrl: response.profileObj.imageUrl ?? '',
            token : response.profileObj.email ?? '', //response.accessToken ?? '',
            app_id : response.googleId ?? ''
        };

        redirectToInfoPage(useDetailTemp);
    };

    const google_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };


    /// APPLE 2 //////////////////////////////////////////////////////////////////////////////////////
    const apple_onSuccess = (response) => {
        //console.log('Success:', response);
    }


    /// INSTAGRAM 4 //////////////////////////////////////////////////////////////////////////////////////
    const instagram_onSuccess = (response) => {
        //console.log('Success:', response);
    }

    const instagram_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };


    /// FACEBOOK 3 //////////////////////////////////////////////////////////////////////////////////////
    const facebook_onSuccess = (response) => {
        //console.log('Success:', response);

        useDetailTemp = {
            ...useDetail,
            type : '3',
            name: response.profile.name ?? '',
            first_name: response.profile.first_name ?? '',
            last_name: response.profile.last_name ?? '',
            email: response.profile.email ?? '',
            imageUrl: response.profile.picture.data.url ?? '',
            token : response.profile.email ?? '', //response.tokenDetail.accessToken ?? '',
            app_id : response.profile.id ?? ''
        };
        //console.log(useDetailTemp, response.tokenDetail.accessToken ?? '');
        redirectToInfoPage(useDetailTemp);
    }

    const facebook_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };



    /// BEONCHAt //////////////////////////////////////////////////////////////////////////////////////
    const handleClick_beonchat = () => {
        history.push(UrlSlugs.personalInfo);
        localStorage.setItem(  localstorage_social_signup_connect, JSON.stringify(defaultProfileDetail) );
    }
   

    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.personalInformation ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: social_gogle_client_id,
                plugin_name: social_gogle_plugin_name
            })
        });

    }, [] );
    

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title="Personal Account" />
    
                <div className="col-sm-3 mx-auto form-theme">
                    <div className="text-blue mb-4 pb-2">choose one</div>
                    
                    <div className="fs-16 font-gotham-book">
                        <GoogleLogin
                            clientId={social_gogle_client_id}
                            buttonText="Login"
                            render={renderProps => (
                                <div className={classSocialWrap} onClick={renderProps.onClick} disabled={renderProps.disabled} ><img src={`${imgFolder}/icons/social/outline-google.svg`} className="mr-3" />google</div>
                            )}
                            onSuccess={google_onSuccess}
                            onFailure={google_onFailure}
                            cookiePolicy={"single_host_origin"}
                        />

                        <FacebookProvider appId={social_facebook_app_id}>
                            <LoginButton
                                className="bg-transparent border-0 px-0"
                                scope="email"
                                onCompleted={facebook_onSuccess}
                                onError={facebook_onFailure}
                                >
                                <div className={classSocialWrap}><img src={`${imgFolder}/icons/social/outline-facebook.svg`} className="mr-3" />facebook</div>
                            </LoginButton>
                        </FacebookProvider>
                        
                        {/* <div className={classSocialWrap}>
                            <img src={`${imgFolder}/icons/social/outline-apple.svg`} className="mr-3" />apple
                            <AppleLogin  
                                clientId={social_apple_client_id} 
                                redirectURI={social_apple_redirect_url}   
                                responseType={"code"} 
                                responseMode={"query"}  
                                usePopup={true}
                                callback={apple_onSuccess}
                                designProp={
                                    {
                                        height: 30,
                                        width: 140,
                                        color: "white",
                                        border: false,
                                        type: "sign-in",
                                        border_radius: 0,
                                        scale: 1,
                                        locale: "en_US", 
                                    }
                                }
                            />
                        </div> */}

                        {/* <InstagramLogin
                            cssClass="bg-transparent border-0 p-0"
                            clientId={social_instagram_app_id}
                            buttonText="Login"
                            response_type="code"
                            scope="user_profile,user_media"
                            onSuccess={instagram_onSuccess}
                            onFailure={instagram_onFailure}
                        >
                            <div className={classSocialWrap}><img src={`${imgFolder}/icons/social/outline-instagram.svg`} className="mr-3" />instagram</div>
                        </InstagramLogin> */}
                       
                        <div className={classSocialWrap} onClick={ () => handleClick_beonchat() }><img src={`${imgFolder}/icons/social/outline-beonchat.svg`} className="mr-3" width={40} />{ siteTitle.toLowerCase() }</div>
                            
                    </div>
                </div>
                
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_accountType}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
        
    </div>
    )
}

export default SocialConnect;