import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jQuery from 'jquery';
import * as RM from 'ramda';

import ChatUtilities from '../helpers/ChatUtilities';
import QueryChatGroupUtilities from '../helpers/QueryChatGroupUtilities';
import Utilities from '../helpers/Utilities';
import SessionUtilities from '../helpers/SessionUtilities';

import { chatSidebarBlock, passImageUrl } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';


const ChatGroupDetail = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const passConversID = useSelector( state => state.passConversID);

    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ profileDetail, setProfileDetail ] = useState({});
    const [ showLoading, setShowLoading ] = useState(false);
    const [ conversationDetail , setConversationDetail] = useState({});
    const [ groupDetail , setGroupDetail] = useState({});
    const [ toggleAll, setToggleAll ] = useState(false);

    const [ memberIDs, setMemberIDs ] = useState([]);
    const [ memberCurrentIDs, setMemberCurrentIDs ] = useState([]);
    const [ memberAcctIDs, setMemberAcctIDs ] = useState([]);
    const [ profileLogoValue, setProfileLogoValue ] = useState('');

    const defaultValues = { title : '' };
    const [ formValues, setFormValues ] = useState(defaultValues);

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword' ){
            setDefaultSearchValue(value);
            searchFilter(value);

        }else if ( fieldName==='title' ){

            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title : value } };
            setFormValues(formValuesTemp);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleMemberInput = (e, row )  => {
        let memberIDsTemp = [...memberIDs];
        let id = row.account_id.toString();

        ! ( memberIDsTemp.includes(id) ) ? memberIDsTemp.push(id) : ( memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)] ) ;
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);
        
        setMemberIDs(memberIDsTemp);
        setToggleAll(false);
    } 

    const toggleMemberAll = () => {
        
        if ( toggleAll===true ){
          setMemberIDs([]);
        }else{
          setMemberIDs([...memberAcctIDs]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }


    const getMembers = async ( conversDetail = {} ) => {
        
        if ( Object.entries(conversDetail).length>0 ){
            if ( conversDetail.hasOwnProperty('flag') && parseInt(conversDetail.flag || 0)===2 ){

                const segment = Utilities.getUrlSegments();
                const teamId = atob(segment[1]);

                setShowLoading(true);

                const responseGetMembers = await QueryChatGroupUtilities.getDetailGroup({
                    api_token : TOKEN.toString(),
                    account_id : teamId.toString(),
                    group_id : conversDetail.id.toString()
                });

                setShowLoading(false);

                if ( Object.entries(responseGetMembers).length===0 ){ 
                    return false; 
                }

                if ( parseInt(responseGetMembers.success)===0 ){ 
                    Utilities.messagePopup('error', responseGetMembers.errorMessage );
                    return false; 
                }

                let groupDetailTemp = responseGetMembers.result.bizchat_group_details;
                if ( Object.entries(responseGetMembers.result.bizchat_group_details.members_list ?? {}).length>0 ){
                    
                    let allList = [];

                    let memberList = responseGetMembers.result.bizchat_group_details.members_list;

                    let selectedList = memberList.filter( member => ( parseInt(member.active_status || 0) === 1 && parseInt(member.account_id) !== parseInt(teamId) ))  ?? [];
                    let unselectedList = memberList.filter( member => ( parseInt(member.active_status || 0) === 0 && parseInt(member.account_id) !== parseInt(teamId) ))  ?? [];

                    if ( selectedList.length>0 ){
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                        selectedList = RM.sort(byName, selectedList);
                        allList = [...allList, ...selectedList];
                    }

                    if ( unselectedList.length>0 ){
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                        unselectedList = RM.sort(byName, unselectedList);
                        allList = [...allList, ...unselectedList];
                    }

                    groupDetailTemp = {...groupDetailTemp, members_list : allList };

                    let acctIDs = [];
                    let includedIDs = [];

                    Object.entries(memberList).map( ([key, row] ) => {
                        includedIDs.push(row.account_id.toString());

                        if ( parseInt(row.active_status)===1 ) {
                            acctIDs.push(row.account_id.toString());
                        }
                    });

                    setMemberAcctIDs(includedIDs);
                    setMemberIDs(acctIDs);
                    setMemberCurrentIDs(acctIDs);
                }

                setGroupDetail(groupDetailTemp);
                setProfileLogoValue(groupDetailTemp.logo);
                setFormValues({ ...formValues, ...{ title : groupDetailTemp.name.toString() } })
            }
        }
    }

    const selectImage = () =>{
        jQuery('input#input_select_file_group').trigger('click');
    }

    const previewImageGroup = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;

        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    jQuery('.chat-group-logo').css('background-image', `url('${e.target.result}')`);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            setProfileLogoValue(groupDetail.logo);
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }
    
    const updateChatGroupDetail = async () => {
        const button_name = 'btn-update-groupchat-detail';

        if ( Utilities.isEmpty(formValues.title) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.titleIsRequired );
            return false;
        }

        Utilities.elementDisabledStatus(button_name, true );

        // CHECKING GROUP NAME EXISTANCE
        const responseGroupName = await QueryChatGroupUtilities.checkGroupNameExistance({
            api_token : TOKEN.toString(),
            account_id : profileDetail.account_id.toString(),
            title : formValues.title.toString(),
            group_id : conversationDetail.id.toString()
        });

        Utilities.elementDisabledStatus(button_name, false );

        if ( Object.entries(responseGroupName).length===0 ){  return false;  }
        if ( parseInt(responseGroupName.success)===0 ){ 
            Utilities.messagePopup('error', responseGroupName.errorMessage );
            return false; 
        }

        let file_name = '';

        // UPLOAD LOGO
        if ( ! Utilities.isEmpty(profileLogoValue) && profileLogoValue!==groupDetail.logo){

            const formData = new FormData();
            formData.append('account_id', profileDetail.account_id.toString());
            formData.append('api_token', TOKEN.toString());
            formData.append('file', profileLogoValue);

            Utilities.elementDisabledStatus(button_name, true );

            const responseUploadLogo = await QueryChatGroupUtilities.uploadGroupLogo(formData);
            Utilities.elementDisabledStatus(button_name, false );
            if ( Object.entries(responseUploadLogo).length>0){
                if ( parseInt(responseUploadLogo.success)===1){
                    file_name = responseUploadLogo.result.file_name.toString();
                }
            }
        }

         // UPDATED DETAILS
        let selectedMemberIds = [...memberIDs];
        selectedMemberIds.push(profileDetail.account_id.toString());

        let memberCurrentIDsTemp = [...memberCurrentIDs];
        memberCurrentIDsTemp.push(profileDetail.account_id.toString());

        selectedMemberIds = Utilities.arrayUnique(selectedMemberIds);
        memberCurrentIDsTemp = Utilities.arrayUnique(memberCurrentIDsTemp);
        
        // should be only in the prev active members
        let removedFromCurrent = memberCurrentIDsTemp.filter( id => ! ( selectedMemberIds.includes(id.toString()) ) );

        const requestValues = {
            api_token : TOKEN.toString(),
            account_id : profileDetail.account_id.toString(),
            group_id : conversationDetail.id.toString(),
            title : formValues.title.toString(),
            file_name : file_name,
            convers_id : passConversID.toString(),
            members_list : {
                active_list : memberIDs.join(',').toString(),
                remove_list : removedFromCurrent.join(',').toString()
            }
        }

            Utilities.elementDisabledStatus(button_name, true );

            const responseUpdateGroup = await QueryChatGroupUtilities.updateGroup(requestValues);
            Utilities.elementDisabledStatus(button_name, false );
            
            if ( Object.entries(responseUpdateGroup).length===0 ){  return false;  }
            if ( parseInt(responseUpdateGroup.success)===0 ){ 
                Utilities.messagePopup('error', responseUpdateGroup.errorMessage );
                return false; 
            }

            if ( typeof responseUpdateGroup.result.bizchat_conversation.conversation_list !=='undefined' ){
                const conversation_list = responseUpdateGroup.result.bizchat_conversation.conversation_list;
                ChatUtilities.saveAccountsConversationDetail(conversation_list, 'group update detail');
                
                const groupLogoUpdated = conversation_list.logo.toString();

                const headerElem =  jQuery('.main-header-chat .profile-header');
                headerElem.find('.profile-image').css({ backgroundImage : `url(${groupLogoUpdated})`});
                headerElem.find('.profile-name').text(conversation_list.name.toString());
                headerElem.find('.profile-label').text(conversation_list.title.toString());
            }
            
            dispatch( chatSidebarBlock('groupdetail') );
            Utilities.messagePopup('sucess', TitlesLabels.alertMessages.successfullyUpdated );
    }

    const previewProfileImage = (logoUrl ) => {
        if ( ! Utilities.isEmpty(logoUrl) ){
             dispatch( passImageUrl(logoUrl) );
        }
    }

    useEffect(() => {
        if ( parseInt(passConversID)>0 ){
            const currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( passConversID );
            setConversationDetail( currentConversationDetailTemp );

            getMembers( currentConversationDetailTemp ) ;

            const segment = Utilities.getUrlSegments();
            const teamId = atob(segment[1]);

            let profileDetailTemp = SessionUtilities.getProfileDetail(teamId);
            setProfileDetail(profileDetailTemp);
        }

    }, [passConversID]);

    return (

        <div className="grouchatdetail-popup-content broadcast-popup-content  form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">
    
            { 
                showLoading===true ?
                    <div className="content-loader animate"></div>

                :
                    <>
                        <input type="file" id="input_select_file_group" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension }  readOnly onChange={ (e) => previewImageGroup(e) } ></input>

                        <div className="upload-avatar avatar-wrap avatar-md-wrap mt-n4">
                            <div>
                                <div className="chat-group-logo rounded-circle mx-auto logo-size-130" style={{ backgroundImage:  `url(${profileLogoValue})`  }}></div>
                                <button type="button" className="btn-select-file text-lowercase mx-auto"  onClick={ () => selectImage() }>select image</button>
                            </div>
                        </div>
                        <div className="my-3 text-center">{Utilities.labelUploadSpecs}</div>
                        
            
                        <div className="form-group">
                            <input type="text"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ formValues.title }  onChange={ (e) => pushValue( e, 'title') } ></input>
                            <label className="form-label">Group name</label>
                        </div>

                        <div className="form-group">
                            <input type="text" id="input_search_team"  className="form-control text-lowercase" placeholder=" " autoComplete="off" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') } ></input>
                            <label className="form-label">Search</label>
                        </div>

                        <div className="select-all-wrap">
                            <div>select all</div>
                            <div className="mr-n1"><input type="checkbox"  checked={ `${ toggleAll ? 'checked' : '' }` }  onChange={ (e) => toggleMemberAll() }   /></div>
                        </div>
                        
                        <ul id="listing-profiles-member" className="list-members mt-2">
                        {
                            typeof groupDetail.members_list!=='undefined' &&
                                Object.entries(groupDetail.members_list).map( ([key, row] ) =>
                                    parseInt(row.account_id)!==parseInt(profileDetail.account_id)  &&
                                        <li key={key} data-keyword={row.name.toLowerCase() } >
                                            <div><div className={`profile-image ${ Utilities.classHideInitialBg(row.logo) }`}  style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`  }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div></div>
                                            <div>
                                                <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                                <div className="profile-label">{row.title}</div>
                                            </div>
                                            <div>
                                                <input type="checkbox"  checked={ `${ memberIDs.includes(row.account_id.toString()) ? 'checked' : ''  }` }  onChange={ (e) => toggleMemberInput(e, row ) } />
                                            </div>
                                            
                                        </li>
                            )
                        }
                        </ul>


                        {
                            parseInt(groupDetail.master_admin_status || 0)===1 &&
                                <div className="py-4 button-group-boolean">
                                    <button type="button" id="btn-delete-groupchat-detail" className="btn-theme-black" onClick={ () => dispatch( chatSidebarBlock('groupdetail') ) }>cancel</button>
                                    <button type="button" id="btn-update-groupchat-detail" className="btn-theme-black button-has-loader" onClick={ () => updateChatGroupDetail() }>save</button>
                                </div>
                        }
                    </>
            }
            
        </div>
    )
}

export default ChatGroupDetail;