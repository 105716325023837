import React, { useEffect, useState  } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios'

import { showHeader, showFooter, pageTitle, showLoader } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import ApiUrls from '../helpers/ApiUrls';
import { sendVerificationCode } from '../helpers/SendingEmails';
import SignupHeader from '../layout/SignupHeader';
import Utilities from '../helpers/Utilities';

const PersonalCondition = () => {

    const dispatch = useDispatch();
    const history = useHistory(); 

    const siteTitle = process.env.REACT_APP_site_title; 
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const msgSendError = TitlesLabels.alertMessages.couldNptSendEmailTryAgain;

    const [ acceptCondition, setAcceptCondition] = useState(false);

    const pushCondition = () => {
        setAcceptCondition(acceptCondition  ? false : true );
    }

    const submitAcceptance = () => {

        let signupPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_signup_personalinfo);
        if ( signupPersonalInfo!==null ){
            signupPersonalInfo = JSON.parse(signupPersonalInfo);
            if ( Object.keys(signupPersonalInfo).length>0 ){

                    const socialSignupConnect = PagesAuthentication.getSignupSocialDetail();

                    signupPersonalInfo = {...signupPersonalInfo, signin_type : '0' };

                    if ( Object.keys(socialSignupConnect).length>0 ){
                        signupPersonalInfo = {...signupPersonalInfo, signin_type : socialSignupConnect.type.toString(), signin_connect : socialSignupConnect };
                    }

                    //console.log(socialSignupConnect, signupPersonalInfo);
                    //console.log(ApiUrls.profile);

                    //return false;
                    dispatch( showLoader(1) );
  
                    axios
                    .post( ApiUrls.profile , signupPersonalInfo )
                    .then( async (response) => {
                        dispatch( showLoader(0) );

                        if ( response.status===200 ){
                            const createdID = parseInt(response.data);
                            

                            signupPersonalInfo = { ...signupPersonalInfo, ...{ account_id : createdID } };
                            localStorage.setItem(
                                process.env.REACT_APP_session_signup_personalinfo,
                                JSON.stringify(signupPersonalInfo)
                            );

                            const passvalVerification = {
                                api_token: API_TOKEN.toString(),
                                flag: "1",
                                account_id : createdID.toString(),
                                name : signupPersonalInfo.first_name+' '+signupPersonalInfo.last_name,
                                email : signupPersonalInfo.email,
                                phone_number : '+'+signupPersonalInfo.mobile_phonecode.toString()+signupPersonalInfo.mobile_number.toString(),
                                code : signupPersonalInfo.pincode.toUpperCase()
                            };

                            dispatch( showLoader(1) );
                            const responSendingVeification = await sendVerificationCode( passvalVerification );
                            dispatch( showLoader(0) );

                            if ( Object.keys(responSendingVeification).length===0 ){
                                Utilities.messagePopup('error', msgSendError );
                                return false;
                            }

                            if ( parseInt(responSendingVeification.success)===1 ){
                                history.push(`/${UrlSlugs.signup_verification}`);

                            }else{
                                Utilities.messagePopup('Error', responSendingVeification.errorMessage );
                            }
                            
                        }else{
                            Utilities.messagePopup('error', errorMsg );
                        }

                    
                    })
                    .catch( (err) => {
                        Utilities.messagePopup('error', errorMsg );
                        dispatch( showLoader(0) );

                    });
                    
            }
        }
    }

    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.importantInformation ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        PagesAuthentication.checkLoginStatus();

    }, [] );

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title={ TitlesLabels.general.signup_importantInformation } />
             {/* <header className="header mb-5 text-center">
                 <div className="logo-wrap">
                     <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                     <div className="logo-type-text mt-2 text-pink-3">{ TitlesLabels.general.signup_importantInformation }</div>
                 </div>
             </header> */}

             <div className="col-6 mx-auto pt-1">

                    <ul className="ul-listing-bullet font-gotham-book text-gray-3">
                        <li className="mb-3">{siteTitle} is an open business communication platform allowing all businesses to onboard. we cannot verify and authenticate the credibility of any enterprise or professional enlisting. please insure you are vigilant when transacting with any party.</li>
                        <li className="mb-3">{siteTitle} does not allow connectivity between two personal end users and allows for connectivity only between end users and businesses and/or other persons in their professional capacity.</li>
                        <li className="mb-3">your acceptance as a user is conditional on you undertaking to use {siteTitle} as a commercial transacting tool and not to use it as a platform to espouse any other political, religious, social or personal views.</li>
                        <li>we do not allow for your profile to be found by businesses in order to protect you from spamming and unsolicited adverts. as a consumer we value your privacy and empower you to make initial contact with a business.</li>
                    </ul>

                    <div className="text-size-semi-medium py-4 checkbox-radio-default checkbox-radio-check-icon d-flex">
                        <input type="checkbox" name="acceptance"  checked={ acceptCondition }  onChange={ () => pushCondition() } ></input>
                        <span className="ml-2 text-black font-gotham-book-16">do you agree?</span>
                    </div>

                    <div className="button-wrap text-lowercase text-center pt-5">
                        <div className="mb-3">
                            <button type="button" id="btn-submit-personal-condition" className="btn-theme-black button-has-loader" onClick={ (e) => submitAcceptance() } disabled={ acceptCondition ? false : true}>continue</button>
                        </div>
                    </div>

             </div>
             
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_persoanlInfo}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
    </div>
    )
}

export default PersonalCondition;