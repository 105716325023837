import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const WelcomeMessage = ( props ) => {

    const siteTitle = process.env.REACT_APP_site_title;
    const [ profileLoggedin, setProfileLoggedin ] = useState({});

    useEffect( () => {
        setProfileLoggedin(props.profileLoggedin);

    }, [props] );

    return (

       <Modal show={ props.popupWelcomeMessage } onHide={ props.handleCloseWelcomeMessage } animation={false} className="modal-dialog-welcome" aria-labelledby="contained-modal-title-vcenter" centered   size="lg">
            <Modal.Header className="border-bottom-0"></Modal.Header>
            <Modal.Body className="px-5 pb-5">

                <div className="col-9 mx-auto text-lowercase px-4 welcome-message-section">

                    <div className="welcome-logo">
                        <div><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.png`} className="logo-icon" alt="logo"  width="50"/></div>
                        <div className="ml-3"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`} alt="logo"  width="150"/></div>
                    </div>

                    <div className="welcome-message-text text-lowercase my-5">
                        <p>hi {profileLoggedin.name} !</p>
                        
                        <p>welcome to { siteTitle }.</p>

                        <p>we have designed { siteTitle } as a chat app to facilitate greater ease of communication between customers/end users and businesses/service providers.</p>
                        
                        <p>Please use this chat to communicate with us at anytime and provide any constructive suggestions you have. It is this feedback that would enable us to enhance the quality of our service to you and ensure an ongoing improved service.</p>
                        
                        <p>Thank you and welcome again<br /><span className="text-uppercase" >{ siteTitle }</span> team</p>

                    </div>

                    <div className="btn-theme-black text-lowercase" onClick={ () => props.handleCloseWelcomeMessage() }>Close</div>

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default WelcomeMessage;
