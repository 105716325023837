import React, {  useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';

const ChatTeamRestrictionResend = (props) => {
   
    const limitCharacters = Utilities.teamRestrictionReasonLimit;
    
    const [ showPopup, setShowPopup ] = useState(false);
    const [ initialMessage, setInitialMessage ] = useState('');

    const handleClosePopup = () => {
        props.handleCloseRestrictReasonPopUp();
    }

    const teamRestrictionStatusProper = () => {

        const charCounter = Utilities.charactersCounter(initialMessage);
        if ( charCounter>limitCharacters ){
            Utilities.messagePopup('error', `Only ${limitCharacters} characters are allowed!` );
            return false;
        }

        props.handleTeamRestrictionResendProper( initialMessage.toString() );
    }

    useEffect(() => {
        setShowPopup( props.showShowRestrictReasonPopUp );
        return () => {
            setInitialMessage('');
        }

    }, [props]);


    return (

       <Modal id="popup-chat-team-profile-restriction-resend"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme" backdrop="static" keyboard={ false }>
            <Modal.Header closeButton className="font-gotham-light-22 text-center d-inline border-bottom-0 pt-4">{ Utilities.labelReasonForResend }</Modal.Header>
            <Modal.Body>

                    <div className="col-10 mx-auto form-theme mt-2 mb-4">
 
                        <div className="form-group">
                            <textarea  name="initial_message" className="form-control" autoFocus rows="8" placeholder=" " value={initialMessage} onChange={ (e) => setInitialMessage(e.target.value) }></textarea>
                            <label className="form-label bg-transparent">type here</label>
                        </div>

                        <div className="d-flex align-items-center justify-content-between text-color-gray">
                            <div className="text-color-blue cursor-pointer" onClick={ () => setInitialMessage('') }>clear</div>
                            <div>{ Utilities.number_format(limitCharacters.toString()) } characters</div>
                        </div>
                        
                        <div className="pt-4 mt-2 mx-auto">
                            <button id="btn-confirm-team-request-resend" type="button" className="btn-theme-black button-has-loader" onClick={ () => teamRestrictionStatusProper() }>submit</button>
                        </div>

                    </div>
                    
            </Modal.Body>
        </Modal>
    )
}

export default ChatTeamRestrictionResend;
