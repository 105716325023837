import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios'

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';

import { changeCityPopup as changeCityPopupAction, applyChangeLocation } from '../../actions';


const CityChange = () => {

    const dispatch = useDispatch();

    const changeCityPopup = useSelector( state => state.changeCityPopup);

    const errorMsg = process.env.REACT_APP_message_error_process;
    const defaultLocation = Utilities.defaultLocation();

    const [ showPopup, setShowPopup ] = useState(false);
    const [ loginDetail, setLoginDetail ] = useState({});
    const [ currentLocation, setCurrentLocation ] = useState(defaultLocation);

    const [ defaultValue, setDefaultValue ] = useState('');
    const [ viewName, setViewName ] = useState('new-location');

    const [ selectedCity , setSelectedCity ] = useState({});
    const [ isDefault , setIsDefault ] = useState(0);

    const [ keywordValue, setKeywordValue ] = useState('');
    const [ cityList, setCityList ] = useState([]);
    const [ savedLocation, setSavedLocation ] = useState({});

    const setupUserLocation = () => {

        if ( Object.keys(savedLocation).length===0  ){

            const passValues = {
                city_id : defaultLocation.city_id.toString(),
                account_id : loginDetail.account_id.toString(),
                current_location : defaultLocation.city_name.toString(),
                permanent_status : '0',
            }
            axios
            .post( ApiUrls.userLocationSaveLocation , passValues )
            .then( response => {

                localStorage.setItem(
                    process.env.REACT_APP_user_location,
                    JSON.stringify(defaultLocation)
                );

                dispatch( applyChangeLocation(new Date) );
            })
            .catch( (err) => {});

        }else{

            localStorage.setItem(
                process.env.REACT_APP_user_location,
                JSON.stringify(savedLocation)
            );

            dispatch( applyChangeLocation(new Date) );
        }

        handleClosePopup();
    }
    
    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch( changeCityPopupAction('') );
    }

    const saveRequestLocation = ( action = 1 ) => {
        action = parseInt(action);

        let passValues = {};
        if ( action===0 ){
            passValues = {
                city_id : defaultLocation.city_id.toString(),
                account_id : loginDetail.account_id.toString(),
                current_location : defaultLocation.city_name.toString(),
                permanent_status : '0',
            }

        }else{
            
            if ( Object.keys(selectedCity).length>0 ){
                passValues = {
                    city_id : selectedCity.id.toString(),
                    account_id : loginDetail.account_id.toString(),
                    current_location : selectedCity.city_name.toString(),
                    permanent_status : isDefault.toString(),
                }
            }
        }

            if ( Object.keys(passValues).length>0  ){
 
                const btn_name = 'btn-submit-location-change';
                Utilities.elementDisabledStatus(btn_name, true );
                
                axios
                .post( ApiUrls.userLocationSaveLocation , passValues )
                .then( response => {
    
                    Utilities.elementDisabledStatus(btn_name, false );
    
                    if ( parseInt(response.status)===200 ){
                        const responseStatus = parseInt(response.data.status);
                        if ( responseStatus===1 ){
                            
                            if ( action===0 ){
                                localStorage.setItem(
                                    process.env.REACT_APP_user_location,
                                    JSON.stringify(defaultLocation)
                                );
                            }else{
                                localStorage.setItem(
                                    process.env.REACT_APP_user_location,
                                    JSON.stringify(selectedCity)
                                );
                            }

                            dispatch( applyChangeLocation(new Date) );

                            Utilities.messagePopup('success', response.data.message );
                            handleClosePopup();
    
                        }else{
                            Utilities.messagePopup('error', response.data.message );
                        }
                    }
                })
                .catch( (err) => {
                    Utilities.messagePopup('error', errorMsg );
                    Utilities.elementDisabledStatus(btn_name, false );
                });
                
            }
        
    }

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='city-name'){
            setDefaultValue(value);
        }
    }

    const formCityNames = ( cityName = '' ) => {
        let returns = '';
        if ( ! Utilities.isEmpty(cityName) ){
            cityName = cityName.toLowerCase();
            returns = cityName.replace( defaultValue.toLowerCase(), `<span>${defaultValue.toLowerCase()}</span>` );
        }
        return returns;  
    }
   
    const searchCity = () => {
        
        Utilities.elementDisabledStatus('from-group-city', false );
        
        let keyword = document.getElementById('input_city_change_keyword').value ?? '';

        if ( typeof keyword!=='undefined' && parseInt(keyword.length)>2 ){
 
                Utilities.elementDisabledStatus('from-group-city', true );

                setSelectedCity({});
                
                axios
                .post( ApiUrls.citySearhByName , { keyword : keyword } )
                .then( response => {
                    if ( response.status===200 ){
                        setCityList(response.data);
                    }

                    Utilities.elementDisabledStatus('from-group-city', false );
                })
                .catch( (err) => {
                    Utilities.elementDisabledStatus('from-group-city', false );
                });
        }
    }


    const getCityLocation = ( account_id = 0, is_setting = 0 ) => {
        account_id = parseInt(account_id);
        is_setting = parseInt(is_setting);

        if ( account_id>0 ){
            axios
            .post( ApiUrls.userLocationGetUserLocationById , { account_id : account_id }  )
            .then( response => {
    
                if ( parseInt(response.status)===200 ){
                  const responseStatus = parseInt(response.data.status);
                  if ( responseStatus===1 ){
                      const userLocationDetail = response.data.detail;

                            axios
                            .post( ApiUrls.citySearchByCityId , { id : userLocationDetail.city_id }  )
                            .then( response => {
                    
                                if ( parseInt(response.status)===200 ){
                                    const location_detail = response.data.detail;
                                    
                                    if ( parseInt(response.data.status)===1 ){

                                        if ( is_setting===1 ){
                                            setCurrentLocation(location_detail);
                                        }else{
                                            setSavedLocation(location_detail);
                                        }
                                        
                                    }
                                }
                                
                            })
                            .catch( (err) => { });
                
                           
                  }else{
                    setCurrentLocation(defaultLocation);
                    setSavedLocation({});
                  }
    
                }
            })
            .catch( (err) => {
    
            });
        }

    }

    const viewHtml = () => {

        let returnHtml = '';

        if ( viewName==='new-location' ){
            returnHtml = <div className="city-change-settings px-5 flex-column d-flex justify-content-between text-center">

                            <div className="font-gotham-light-22">a new location has been detected</div>
                            
                            <div className="text-center">
                                <div className="font-gotham-book-30 city-name">{currentLocation.city_name}</div>
                                <div className="font-gotham-light-22">{currentLocation.country_name}</div>
                            </div>

                            <div className="button-group-boolean mt-3">
                                <button type="button" className="btn-theme-black" onClick={ () => setupUserLocation() }>ignore</button>
                                <button type="button" id="btn-submit-location-change" className="btn-theme-black button-has-loader"  onClick={ () => saveRequestLocation(0) }>change</button>
                            </div>

                        </div>

        }else if ( viewName==='location' ){

            returnHtml = <div className="city-change-settings px-5 flex-column d-flex justify-content-between">
                            
                            {
                                Object.keys(currentLocation).length===0 ?
                                    <div className="content-loader animate"></div>
                                :
                                    <Fragment>
                                        <div className="text-center">
                                            <div className="font-gotham-book-30 city-name">{currentLocation.city_name}</div>
                                            <div className="font-gotham-light-22">{currentLocation.country_name}</div>
                                        </div>

                                        <div className="mt-5">
                                            <button type="button" className="btn-theme-black" onClick={ () => setViewName('change') }>change location</button>
                                        </div>
                                    </Fragment>
                                }
                            
                        </div>


        }else if ( viewName==='change' ){

            returnHtml = <div className="city-change-settings px-5">
                                <div className="font-gotham-light-22 text-center mb-4">Change Location</div>
                                
                                <div id="from-group-city" className="form-group button-has-loader">
                                    <input type="text" id="input_city_change_keyword"  className="form-control text-lowercase" placeholder=" " value={ defaultValue }  onChange={ (e) => { setKeywordValue(e.target.value); pushValue( e, 'city-name'); }}    autoComplete="new-password" autoFocus ></input>
                                    <label className="form-label">Search</label>
                                </div>

                                <ul className="city-list-results font-gotham-light-16">
                                {
                                    Object.entries(cityList).map( ([key, row] ) => 
                                        <li key={key} className={ parseInt(selectedCity.id || 0)===parseInt(row.id || 0) ? 'selected' : '' } onClick={ () => setSelectedCity(row) }>
                                            <div dangerouslySetInnerHTML={{ __html: formCityNames(row.city_name) }}></div>
                                            <div>{ row.country_name }</div>
                                        </li>
                                    )
                                }
                                </ul>
                            
                                {
                                    Object.keys(cityList).length>0 &&
                                        <div className=" font-gotham-book-14 checkbox-radio-default d-flex align-items-center mt-4">
                                            <input type="checkbox" name="isdefault" checked={ isDefault }  onChange={ () => setIsDefault( isDefault===0 ? 1 : 0 ) } ></input>
                                            <span className="ml-2">use this location permanently</span>
                                        </div>
                                } 
                            
                                <div className="button-group-boolean pt-5">
                                    <button type="button" className="btn-theme-black" onClick={ () => setViewName('location') }>cancel</button>
                                    <button type="button" id="btn-submit-location-change" className="btn-theme-black button-has-loader" disabled={ Object.keys(selectedCity).length>0 ? false : true } onClick={ () => saveRequestLocation(1) }>save</button>
                                </div>

                        </div>
        }

        return returnHtml;
    }


    useEffect( () => {

        if ( ! Utilities.isEmpty(changeCityPopup) ){
            setShowPopup(true);

            let sesstionLoginType = 0;
            let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
            loginType = JSON.parse(loginType);

            if ( loginType !== null ){
                sesstionLoginType = parseInt(loginType);

                let personalAccount = {}
                if ( sesstionLoginType===1 ){
                    
                    let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                    if ( loginType !== null ){
                        accountsList = JSON.parse(accountsList);
                        accountsList = Utilities.toNormalArrayObject(accountsList);
                        
                        personalAccount = accountsList.find(Boolean);
                        setLoginDetail(personalAccount);
                    }

                }else if ( sesstionLoginType===2 ){

                    let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
                    if ( companyDetail!==null ){
                        companyDetail = JSON.parse(companyDetail);

                        personalAccount = companyDetail;
                        setLoginDetail(companyDetail);
                    }
                }


                if ( changeCityPopup.toString().toLowerCase()==='location' ){
                    setCurrentLocation({});
                    getCityLocation( personalAccount.account_id, 1 );
                    
                }else{
                    setCurrentLocation(defaultLocation);
                    getCityLocation( personalAccount.account_id, 0 );
                }
                

                setViewName(changeCityPopup);
                setDefaultValue('');
                setDefaultValue('');
                setCityList([]);
                setSelectedCity({});
            }

        }
    }, [changeCityPopup] );


    useEffect(() => {
        const delayDebounceFn = setTimeout( () => {
            // Send Axios request here
            if ( ! Utilities.isEmpty(keywordValue) ){
                searchCity();
            }
            
          }, 1000);

        return () => {
            clearTimeout(delayDebounceFn);
        };
        
    }, [keywordValue]);


    useEffect(() => {
        return () => {
            setKeywordValue('');
        };
    }, []);

    return (

       <Modal id="popup-city-change"  show={ showPopup } onHide={ handleClosePopup } animation={false} aria-labelledby="contained-modal-title-vcenter" centered  size="md"  dialogClassName="modal-dialog-theme" contentClassName=" main-location-content" backdrop="static" keyboard={ false }>
            { viewName!=='new-location' && <Modal.Header closeButton className="border-bottom-0 zindex-1"></Modal.Header> }
            <Modal.Body className={`p-5 pt-0 text-lowercase ${ viewName!=='new-location' ? 'mt-n5 ' : ''} form-theme`}>

                {  viewHtml() }
                
            </Modal.Body>
        </Modal>
    )
}

export default CityChange;
