import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const HeaderDepartmentsViewChat = ( props ) => {
    
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [ listDepartments, setListDepartments ] = useState([]);
    const [ profileDetail, setProfileDetail ] = useState([]);

    useEffect( () => {
        const profile = props.profileSelected;
        setProfileDetail(profile);

        if ( profile.hasOwnProperty('departments') ){
            if ( Object.entries(profile.departments).length>0 ){

                let listDepartmentsTemp = [];
                Object.entries(profile.departments).map( ([key, row] ) => {
                    if ( parseInt(row.flag || 0)===5 && parseInt(row.flag_handle_status || 0)===0 ){
                        listDepartmentsTemp.push(row);
                    }
                });

                setListDepartments(listDepartmentsTemp);
            }
        }

    }, [props] );

    return (
        <Modal id="popup-departments-viewchat"  show={ props.popupDepartmentsViewChat } onHide={ props.handleCloseDepartmentsViewChat } animation={false} aria-labelledby="contained-modal-title-vcenter" centered   size="md" dialogClassName="modal-dialog-theme" >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body className="px-4">
                
                <div className="mt-n4 department-section">

                    <div className="upload-avatar avatar-wrap">
                        <div>
                            <div className="avatar-logo rounded-circle mx-auto" style={{ backgroundImage:  `url(${ profileDetail.hasOwnProperty('company_detail') ? profileDetail.company_detail.logo_url : DEFAUL_AVATAR }) ` }}></div>
                        </div>
                    </div>

                    <ul className="department-list-blocks my-4">
                        { 
                            Object.entries(listDepartments).map( ([key, row] ) => 
                                <li key={key} onClick={ () => props.switchProfile( row ) }>
                                    <div className="department-name">{row.department_name}</div>
                                </li>
                            )
                        }
                    </ul>

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default HeaderDepartmentsViewChat;
