import axios from 'axios';
import ApiUrls from './ApiUrls';


export const leaveFromGroups = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastLeaveGroup, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const companyGroupsList = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyGroupsList, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const companyGroupsListBizchat = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyGroupsListBizchat, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const conversationTeamMembersList = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastConversationsTeamMembersList, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const getMembersGroups = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastConversationMembersList, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const joinInvitationResponse = async ( passval = {}  ) =>{
    let responseDetail = {};

    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastAcceptRejectGroupRequest, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const suspendRemoveMembers = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastUpdateConversationMembers, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const getTeamMembersByCompanyId = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastAdminMembersList, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const createSubmitGroup = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyCreateGroups, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const updateSubmitGroup = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyUpdateGroups, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const deleteGroup = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyDeleteGroups, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const joinOpenGroup = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastJoinOpenGroup, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const requestToJoin = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyGroupsSendRequest, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const joinWithPasscode = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyGroupsJoinWithPasscode, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const removeJoinRequest = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.companyBroadcastCompanyGroupsRemoveJoinRequest, passval);
            responseDetail = responseURL.data;
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}