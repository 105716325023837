import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios'

import Utilities from '../helpers/Utilities';
import { uploadProfileLogo } from '../helpers/AWSUpload';
//import PagesAuthentication from '../helpers/PagesAuthentication';

import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import FollowConfirmation from '../components/popups/FollowConfirmation';
import SignupHeader from '../layout/SignupHeader';

const CompanyLogo = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;

    const [ emailAddress, setEmailAddress ] = useState('');
    //const [ passwordUser, setPasswordUser ] = useState('');
    const [ logoProfile , setLogoProfile] = useState('');
    const [ logoProfileValue , setLogoProfileValue] = useState('');
    const [ accountID , setAccountID] = useState(0);

    const [ showPopup, setShowPopup ] = useState(false);

    const selectImage = () =>{
        jQuery('input#input_select_file').trigger('click');
    }
    

    const previewImage = ( e ) => {
        let value = e.target.value;
        let elem =  e.target;
        
        if ( Utilities.validate_image_ext( value ) ){

            var reader = new FileReader();
            reader.onload = function (e) {
               
                var img = new Image();      
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width<Utilities.minImageResolution.height || this.height<Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width>Utilities.minImageResolution.width || this.height>Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfileValue(e.target.result);
                    //setLogoProfile(e.target.result);
                    
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setLogoProfile(e.target.files[0]);

        }else{
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const loginUser = ( acctId = 0 ) => {

        //if ( !Utilities.isEmpty(emailAddress) && !Utilities.isEmpty(passwordUser) ){
        if ( !Utilities.isEmpty(emailAddress) && parseInt(acctId)>0 ){
            dispatch( showLoader(1) );

            axios
            .post( ApiUrls.userGetByCredentail , { email: emailAddress, account_id : acctId, is_company : 1 }  )
            .then( response => {

                dispatch( showLoader(0) );
                const resStatus = response.status;
                if ( resStatus===200 ){
                    const resData = response.data;
                    const resLoginType = parseInt(resData.logintype);
                    
                    if ( parseInt(resData.status)===1){

                        //if ( resData.detail.status==1 ){
                            
                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );
                            
                            history.push(`/${UrlSlugs.signup_teamMember}`);

                    /*  }else{
                            Utilities.messagePopup('error', 'Account is not active!'  );
                        } */

                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }
                }else{
                    Utilities.messagePopup('error', errorMsg );
                } 
               
            })
            .catch( (err) => {
                Utilities.messagePopup('error', errorMsg );
                dispatch( showLoader(0) );
            });
        }

    }


    const submitLogoConfirm = () => {
        const logo_value = document.getElementById('input_select_file').value;
        if ( Utilities.isEmpty(logo_value) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory );
            return false;
        }
        
        setShowPopup(true);
    }

    const handleClosePopUp = ( followStatus = 0 ) => {
        setShowPopup(false);
        submitLogo(followStatus);
    }


    const submitLogo = async ( followStatus = 0  ) => {
        
        const logo_value = document.getElementById('input_select_file').value;
        
        if ( Utilities.isEmpty(logo_value) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory );
            return false;
        }

        if ( accountID===0 ){ return false; }

        const formData = new FormData();
        formData.append('personal_id', accountID);

        let signupCompanyInfo = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo);
        if ( signupCompanyInfo!==null ){

            signupCompanyInfo = JSON.parse(signupCompanyInfo);
            if ( Object.keys(signupCompanyInfo).length>0 ){
                Object.entries(signupCompanyInfo).map( ([key, row]) => {
                    formData.append( key , row);
                });
            }

        }else{
            return false;
        }


        
        let signupCompanyAddress = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyaddress);
        if ( signupCompanyAddress!==null ){

            signupCompanyAddress = JSON.parse(signupCompanyAddress);
            if ( Object.keys(signupCompanyAddress).length>0 ){
                Object.entries(signupCompanyAddress).map( ([key, row]) => {
                    formData.append( key , row);
                });
            }

        }else{
            return false;
        }


        let signupCompanyInfoAbout = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo_about);
        if ( signupCompanyInfoAbout!==null ){

            signupCompanyInfoAbout = JSON.parse(signupCompanyInfoAbout);
            if ( Object.keys(signupCompanyInfoAbout).length>0 ){
                Object.entries(signupCompanyInfoAbout).map( ([key, row]) => {
                    const vall = key==='company_keywords' ? row.join('|') : row;
                    formData.append( key , vall);
                });
            }
        }

        let signupCompanyInfoSocial = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo_social);
        if ( signupCompanyInfoSocial!==null ){

            signupCompanyInfoSocial = JSON.parse(signupCompanyInfoSocial);
            if ( Object.keys(signupCompanyInfoSocial).length>0 ){

                let socialFieldValues = Object.entries(signupCompanyInfoSocial).filter( val => val ).join('|');
                formData.append('company_social', socialFieldValues);
            }
        }

        if ( parseInt(followStatus)===1 ){
            formData.append('follow_status', followStatus.toString());
        }

       dispatch( showLoader(1) );

            axios
                .post( ApiUrls.companySubmitCompanyDetail ,  formData, {  headers : { 'Content-Type': 'multipart/form-data' } } )
                .then( async (response) => {
                    dispatch( showLoader(0) );

                    const resStatus = response.status;
                    if ( resStatus===200 ){
                        const resData = response.data;
                        const accountIdGenerated = parseInt(resData.status);

                        if ( accountIdGenerated>0){ // account_id
                            
                            dispatch( showLoader(1) );

                            const formLogoData = new FormData();
                            formLogoData.append('account_id', resData.status );
                            formLogoData.append('file', logoProfile);
                            
                            await uploadProfileLogo( formLogoData );
                            dispatch( showLoader(0) );

                            loginUser( accountIdGenerated );

                        }else{
                            Utilities.messagePopup('error', errorMsg );
                        }
                    }else{
                        Utilities.messagePopup('error', errorMsg );
                    }

                })
                .catch( (err) => {
                    Utilities.messagePopup('error', errorMsg );
                    dispatch( showLoader(0) );
                });
    }

    
    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.companyLogo ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        //PagesAuthentication.getUserLoggedin();

         // ASSOCIATED ACCOUNTS // this means creating in from dashbaord
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if ( accountsList!==null ){
             accountsList = JSON.parse(accountsList);

             if ( Object.keys(accountsList).length>0 ){
                const personalAccount = Object.values(accountsList)[0];
                setAccountID(personalAccount.account_id);
             }
             
        }else{

            let loggedinDetail = localStorage.getItem(process.env.REACT_APP_session_login);
            if ( loggedinDetail!==null ){
                loggedinDetail = JSON.parse(loggedinDetail);
                if ( Object.keys(loggedinDetail).length>0 ){
                    setAccountID(loggedinDetail.account_id);
                }
            }else{

                let loggedinDetail = localStorage.getItem(process.env.REACT_APP_session_login_signup);
                if ( loggedinDetail!==null ){
                    loggedinDetail = JSON.parse(loggedinDetail);
                    if ( Object.keys(loggedinDetail).length>0 ){
                        setAccountID(loggedinDetail.account_id);
                    }
                }
            }
        }


        let signupCompanyInfo = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo);
        if ( signupCompanyInfo!==null ){

            signupCompanyInfo = JSON.parse(signupCompanyInfo);
            if ( Object.keys(signupCompanyInfo).length>0 ){
                setEmailAddress(signupCompanyInfo.company_email);
                //setPasswordUser(signupCompanyInfo.password);
            }
        }

    }, [] );
    

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title="Company Logo" />
                {/* <header className="header mb-5 text-center">
                    <div className="logo-wrap">
                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                        <div className="logo-type-text mt-2">Company Logo</div>
                    </div>
                </header> */}

                <form className="col-6 mx-auto pt-1 text-center" autoComplete="off">

                    <input type="file" id="input_select_file" name="file" className="invisible" accept={ Utilities.attributeImageAcceptExtension } readOnly onChange={ (e) => previewImage(e) } ></input>

                    <div className="avatar-wrap">
                        <div>
                            <div className="avatar-preview rounded-circle mx-auto position-relative overflow-visible" style={{ backgroundImage:  `url(${logoProfileValue})` }} >
                            </div>
                            <button type="button" className="btn-select-file text-lowercase mx-auto position-relative" onClick={ () => selectImage() }>select image</button>
                        </div>
                    </div>
                    <div className="mt-3">{Utilities.labelUploadSpecs}</div>


                    <div className="button-wrap text-lowercase text-center mt-5 pt-5">
                        <button type="button" className="btn-theme-black text-lowercase" onClick={ (e) => submitLogoConfirm(e) }>Next</button>
                    </div>
                </form>
                
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_companyAddress}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
         <FollowConfirmation showPopup={showPopup} handleClosePopUp={handleClosePopUp}/>
        
    </div>
    )
}

export default CompanyLogo;