import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';
import {  showHeader, showFooter, pageTitle } from '../actions';

import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';

const CompanyKeywords = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
    }

    const submitKeywords = () => {
        let keywordLoop = '', keywordJoined = [], keywordError = 0;
        jQuery('#form-company-keywords input').each( function () {
            keywordLoop = jQuery(this).val();
            if ( ! Utilities.isEmpty(keywordLoop) ){

                if  ( ! Utilities.checkSpecialChar(keywordLoop) ){
                    keywordJoined.push(keywordLoop.toLowerCase());
                    jQuery(this).closest('.form-group').removeClass('error');
                }else{
                    jQuery(this).closest('.form-group').addClass('error');
                    keywordError++;
                }
                
            }
            
        }); 
        

        if ( keywordError>0 ){
            Utilities.messagePopup('Error', TitlesLabels.general.specialCharactersNotAllowed );
            return false;
        }

        if ( keywordJoined.length===0 ){
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired );
            return false;
        }

  
        let signupCompanyAbout = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo_about);
        if ( signupCompanyAbout!==null ){
            signupCompanyAbout = JSON.parse(signupCompanyAbout);
        
            if ( Object.keys(signupCompanyAbout).length>0 ){
                signupCompanyAbout = { ...signupCompanyAbout, company_keywords : keywordJoined };

            }

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo_about,
                JSON.stringify(signupCompanyAbout)
            );

        }else{
            return false;
        }


        history.push(`/${ UrlSlugs.signup_companyCondition}`);

    }

    useEffect( () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.keywordInformation ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        
    }, [] );
    

    return (
        <div className="main-content signup-page position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
            <div className="container py-5">
                <SignupHeader title="Keyword Info" />
                {/* <header className="header mb-5 text-center">
                    <div className="logo-wrap">
                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                        <div className="logo-type-text mt-2">Keyword Info</div>
                    </div>
                </header> */}

                <div className="col-sm-8 mx-auto form-theme">
                    <form id="form-company-keywords" className="col-6 mx-auto pt-1" autoComplete="off">

                        {
                            [1,2,3,4,5].map( key => 
                                <div key={key} className="form-group ">
                                    <input type="text" name={`keyword_${key.toString()}`} className="form-control" placeholder=" " autoComplete="off" onChange={ (e) => pushValue( e, 'keyword_'+key.toString() ) }  ></input>
                                    <label className="form-label">keyword</label>
                                </div>
                            )
                        }
                        
                        <div className="button-wrap text-lowercase text-center pt-5">
                            <button type="button" className="btn-theme-black text-lowercase" onClick={ () => submitKeywords() } >Next</button>
                        </div>
                        
                    </form>
                </div>
                
         </div>

         <div className="footer-navigation text-center">
             <Link to={`/${ UrlSlugs.signup_companyAddress}`}>{ TitlesLabels.general.exitWithoutSaving }</Link>
         </div>
         
        
    </div>
    )
}

export default CompanyKeywords;