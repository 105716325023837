import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { showHeader, showFooter, pageTitle, showPopupTermsConditions } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import Utilities from '../helpers/Utilities';

const AccountType = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const redirectType = ( type ) =>{
        history.push(type);
    }

    useEffect( () =>{
        dispatch( pageTitle( TitlesLabels.siteTitles.selectType ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );

        Utilities.rootFilterBlur(false);

        // CLOSE TERMS POPUP WHEN IN CREATING COMPANY
        dispatch( showPopupTermsConditions(false) );

    }, [] );
      
    return (
       <div className="main-content signup-page signup-page-account-type position-fixed d-flex justify-content-center align-items-center w-100  h-100 text-lowercase">
           <div className="container py-5">
                <SignupHeader title="Account Type" />
                {/* <header className="header mb-5 text-center">
                    <div className="logo-wrap">
                        <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`}  width="200"alt="logo"  /></Link>
                        <div className="logo-type-text mt-2">Account Type</div>
                    </div>
                </header> */}

                <div className="col-3 mx-auto pt-1">
                    
                    <div className="mb-4 mt-5  text-center text-blue">Choose One</div>
                    <ul className="list-account-option checkbox-radio-default">
                        <li className="cursor-pointer" onClick={ () => redirectType( UrlSlugs.socialConnect ) }>
                            <input type="radio" name="account-type"></input>
                            <span>Personal Account</span>
                        </li>
                        <li className="cursor-pointer" onClick={ () => redirectType( UrlSlugs.personalLogin ) }>
                            <input type="radio"  name="account-type"></input>
                            <span>Company Account</span>
                        </li>
                    </ul>
                    
                </div>
                
            </div>

            <div className="footer-navigation text-center">
                <Link to="/">{ TitlesLabels.general.exitWithoutSaving }</Link>
            </div>
            
       </div>
    )
}

export default AccountType;